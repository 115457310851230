import React from 'react';
import {renderHotelImage} from '../../utils/hotel-func'
// import {IconStarHotel} from "../../const/icon-const";
import StarRatings from 'react-star-ratings';

function ReviewComboHotel({dataHotel}) {
    // const renderStart = (start) => {
    //     const result = []
    //     for (let i = 0; i < start; i++) {
    //         result.push(i)
    //     }
    //     return result
    // }
    return (
        <div className="comboHotel">
            <div className="title1">
                <h4>Thông tin khách sạn</h4>
            </div>
            {dataHotel && <div className="hotelItem">
                <div className="hotelItem__img">
                    {renderHotelImage({
                        hotel_id: dataHotel[0].id,
                        hotel_name: dataHotel[0].name,
                        thumb_image: dataHotel[0].thumb_image,
                        isDomestic: 'isDomestic'
                    })}
                </div>
                <div className="hotelItem__cont">
                    <p className="hotelItem__name">{dataHotel[0].name}</p>
                    {
                        dataHotel[0].star_rate >= 3 &&
                        <div className="rateStar">
                            <StarRatings
                                rating={Number(dataHotel[0].star_rate)}
                                starRatedColor="#ff8916"
                                numberOfStars={Math.ceil(dataHotel[0].star_rate)}
                                name='rating'
                            />
                        </div>
                    }
                    {/*<div className="rateStar">*/}
                    {/*    {*/}
                    {/*        renderStart(dataHotel[0].star_rate).map((res, index) => {*/}
                    {/*            return (*/}
                    {/*                <svg key={index} width={14} height={14} viewBox="0 0 96 91" fill="none"*/}
                    {/*                     xmlns="http://www.w3.org/2000/svg">*/}
                    {/*                    <path*/}
                    {/*                        d="M48 3.90594L59.8402 32.3732L60.192 33.2191L61.1053 33.2923L91.8381 35.7561L68.4229 55.8137L67.7271 56.4097L67.9397 57.3009L75.0934 87.2909L48.7819 71.2199L48 70.7423L47.2181 71.2199L20.9066 87.2909L28.0603 57.3009L28.2729 56.4097L27.5771 55.8137L4.16194 35.7561L34.8947 33.2923L35.808 33.2191L36.1598 32.3732L48 3.90594Z"*/}
                    {/*                        fill="#FA8C16" stroke="#FA8C16" strokeWidth={3}/>*/}
                    {/*                </svg>*/}
                    {/*            )*/}
                    {/*        })*/}
                    {/*    }*/}
                    {/*</div>*/}
                    <p className="hotelItem__add">{dataHotel[0].full_address}</p>
                </div>
            </div>}
        </div>
    );

}

export default ReviewComboHotel;
