import React from 'react';

function HomeArticles() {
    return (
        <div className="promotion">
            <div className="container">
                <div className="promotion_hot">
                    <div className="promotion_hot__title">
                        <h2> TRUYỀN THÔNG NÓI VỀ CHÚNG TÔI</h2>
                    </div>
                </div>
                <div className="articles articles__bg">
                    <div className="articles__item"><a
                        href="http://vtv.vn/kinh-te/doanh-nghiep-viet-gianh-thi-phan-du-lich-truc-tuyen-20170506195725496.htm"
                        target="_blank" rel="nofollow">
                        <div className="sprite-logo sprite-logo_vtv"/>
                    </a></div>
                    <div className="articles__item"><a
                        href="https://kinhdoanh.vnexpress.net/tin-tuc/startup/ung-dung-dat-phong-khach-san-viet-duoc-tap-doan-thuy-sy-rot-von-3795054.html"
                        target="_blank" rel="nofollow">
                        <div className="sprite-logo sprite-logo_vnexpress"/>
                    </a></div>
                    <div className="articles__item"><a
                        href="http://cafebiz.vn/vntripvn-goi-von-thanh-cong-lan-3-duoc-tap-doan-thuy-sy-dinh-gia-1000-ty-dong-20180820111940602.chn"
                        target="_blank" rel="nofollow">
                        <div className="sprite-logo sprite-logo_cafebiz"/>
                    </a></div>
                    <div className="articles__item"><a
                        href="https://www.dealstreetasia.com/stories/vietnams-online-travel-app-vntrip-raises-third-round-from-swiss-ihag-holding-105055/"
                        target="_blank" rel="nofollow">
                        <div className="sprite-logo sprite-logo_deal"/>
                    </a></div>
                    <div className="articles__item"><a
                        href="http://vneconomictimes.com/article/business/vntrip-vn-secures-funding-from-swiss-investor"
                        target="_blank" rel="nofollow">
                        <div className="sprite-logo sprite-logo_times"/>
                    </a></div>
                    <div className="articles__item"><a
                        href="http://thoibaonganhang.vn/ihag-holding-rot-45-trieu-usd-vao-vntripvn-79083.html"
                        target="_blank" rel="nofollow">
                        <div className="sprite-logo sprite-logo_banks"/>
                    </a></div>
                </div>
            </div>
        </div>
    );

}

export default HomeArticles;