import React from 'react';
import moment from 'moment';
import "moment/min/locales.min";
import DisplayPrice from "../common/DisplayPrice";
import {IconHotelReview} from "../../const/icon-const";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {toggleShowHotelInfo} from "../../redux/actions/hotel-list";
import {PRICE_INFANT} from "../../const/common-const";
function ReviewComboInfo(
    {
        dataBooking,
        handleContinue,
        dispatch,
        isShowButton,
        priceComboForPerson,
        textBtn,
        personSurchargePrice,
        priceInfantSurcharge,
        priceCoupon,
        couponWebview,
        isCoupon
    }) {
    const dataCombo = dataBooking ? dataBooking.combo : {};
    const dataFlight = dataBooking ? dataBooking.flight : {};
    const _priceInfantSurcharge = priceInfantSurcharge ? priceInfantSurcharge : PRICE_INFANT
    return (
        <div>
            {
                dataCombo && <div className="hotelReview__info__content">
                    <div className="hotelReview__info">
                        <div className="title-hotel">
                            <h2 className="title">Thông tin đặt combo</h2>
                            <div onClick={() => dispatch(toggleShowHotelInfo(false))}
                                 className="hotelReview__info__close">
                                <button>x</button>
                            </div>
                        </div>
                        {/*<img src={dataCombo.deal_data.image_deal[0].image} className="image-hotel"/>*/}
                        <p className="semibold">{dataCombo.deal_data.name}</p>
                        <div className="hotelReview__info__group">
                            <div className="hotelReview__info__item">
                                <p>
                                    <IconHotelReview/>
                                    <span>Nơi khởi hành:</span>
                                </p>
                                <p>
                                    <span>{dataFlight && dataFlight[0] && !dataFlight[0].is_return ? dataFlight[0].origin_name : dataFlight && dataFlight[1] && dataFlight[1].origin_name}</span>
                                </p>
                            </div>
                            <div className="hotelReview__info__item">
                                <p>
                                    <IconHotelReview/>
                                    <span>Điểm đến:</span>
                                </p>
                                <p>
                                    <span>{dataFlight?.[0] && dataFlight?.[0]?.is_return === true ? dataFlight?.[0]?.origin_name : dataFlight?.[1] && dataFlight?.[1]?.origin_name}</span>
                                </p>
                            </div>
                            <div className="hotelReview__info__item">
                                <p>
                                    <svg width={15} height={15} viewBox="0 0 443.294 443.294"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="m221.647 0c-122.214 0-221.647 99.433-221.647 221.647s99.433 221.647 221.647 221.647 221.647-99.433 221.647-221.647-99.433-221.647-221.647-221.647zm0 415.588c-106.941 0-193.941-87-193.941-193.941s87-193.941 193.941-193.941 193.941 87 193.941 193.941-87 193.941-193.941 193.941z"/>
                                        <path
                                            d="m235.5 83.118h-27.706v144.265l87.176 87.176 19.589-19.589-79.059-79.059z"/>
                                    </svg>
                                    <span>Thời gian combo:</span>
                                </p>
                                <p>
                                    <span>{dataCombo?.deal_data?.days} ngày {dataCombo?.deal_data?.nights} đêm</span>
                                </p>
                            </div>
                            <div className="hotelReview__info__item">
                                <p>
                                    <svg width={15} height={15} viewBox="0 0 512 512"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="m446 40h-46v-24c0-8.836-7.163-16-16-16s-16 7.164-16 16v24h-224v-24c0-8.836-7.163-16-16-16s-16 7.164-16 16v24h-46c-36.393 0-66 29.607-66 66v340c0 36.393 29.607 66 66 66h380c36.393 0 66-29.607 66-66v-340c0-36.393-29.607-66-66-66zm-380 32h46v16c0 8.836 7.163 16 16 16s16-7.164 16-16v-16h224v16c0 8.836 7.163 16 16 16s16-7.164 16-16v-16h46c18.748 0 34 15.252 34 34v38h-448v-38c0-18.748 15.252-34 34-34zm380 408h-380c-18.748 0-34-15.252-34-34v-270h448v270c0 18.748-15.252 34-34 34z"/>
                                    </svg>
                                    <span>Ngày khởi hành:</span>
                                </p>
                                <p>
                                    <span>{dataFlight?.[0] && !dataFlight?.[0].is_return ? moment(dataFlight?.[0]?.departure_date).format('DD/MM/YYYY') : dataFlight?.[1] && moment(dataFlight?.[1]?.departure_date).format('DD/MM/YYYY')}</span>
                                </p>
                            </div>
                            <div className="hotelReview__info__border"/>
                            <div className="hotelReview__info__price">
                                {
                                    priceComboForPerson ?
                                        <div className="hotelReview__price__item">
                                            <p>Giá
                                                Combo {dataCombo?.deal_data?.days} ngày {dataCombo.deal_data && dataCombo.deal_data.nights} đêm: </p>
                                            <p className="price"><span>{dataFlight?.[0] && (dataFlight?.[0]?.adult_count + dataFlight?.[0]?.child_count)} x <DisplayPrice
                                                price={priceComboForPerson}/></span>
                                            </p>
                                        </div> :
                                        <div className="hotelReview__price__item">
                                            <p>Giá
                                                Combo {dataCombo.deal_data && dataCombo.deal_data.days} ngày {dataCombo.deal_data && dataCombo.deal_data.nights} đêm: </p>
                                            <p className="price"><span>{dataFlight?.[0] && (dataFlight?.[0]?.adult_count + dataFlight?.[0]?.child_count)} x <DisplayPrice
                                                price={dataCombo.unit_price}/></span>
                                            </p>
                                        </div>
                                }
                                {
                                    couponWebview && isCoupon &&
                                    <div className="hotelReview__price__item">
                                        <p>Mã giảm giá:
                                            <span
                                                style={{background:'#fdf7e9', padding:'2px 5px', fontSize:13, marginLeft:5, fontWeight:600}}
                                            >
                                            {couponWebview}</span>
                                        </p>
                                        <p className="price">
                                            <span style={{color:'#91bb15'}}> - <DisplayPrice price={priceCoupon}/></span>
                                        </p>
                                    </div>
                                }
                                {
                                    dataFlight?.[0] && dataFlight?.[0].infant_count > 0 &&
                                    <div className="hotelReview__price__item">
                                        <p>Trẻ sơ sinh: </p>
                                        <p className="price"><span>{dataFlight?.[0] && (dataFlight?.[0]?.infant_count)} x <DisplayPrice
                                            price={_priceInfantSurcharge}/></span>
                                        </p>
                                    </div>
                                }
                                {
                                    dataCombo && dataCombo.extra_price && dataCombo.extra_price.totalOddPersonSurchargePrice >0 &&
                                    <div className="hotelReview__price__item">
                                        <p>Phụ thu khách lẻ: </p>
                                        <p className="price">
                                            <DisplayPrice
                                            price={personSurchargePrice ? personSurchargePrice : dataCombo.extra_price.totalOddPersonSurchargePrice}/>
                                        </p>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="hotelReview__info__border"/>
                        <div className="hotelReview__info__totalPrice">
                            <div className="d-flex">
                                <p>Tổng cộng</p>
                                {
                                    priceComboForPerson ?
                                        <p className="price"><span> <DisplayPrice
                                            price={dataFlight && ((dataFlight?.[0].adult_count + dataFlight?.[0]?.child_count)* priceComboForPerson + (dataCombo.extra_price.totalOddPersonSurchargePrice >0 ? personSurchargePrice : 0) + (dataFlight?.[0]?.infant_count ? dataFlight?.[0]?.infant_count * _priceInfantSurcharge : 0))}/></span>
                                        </p> :
                                        <p className="price"><span> <DisplayPrice price={dataBooking.final_price}/></span></p>
                                }

                            </div>
                            <div className="hotelReview__info__fee">Đây là giá cuối, không thêm phí phụ thu</div>
                            <div onClick={handleContinue} className="btnGroup btnGroupMobile w100">
                                <button className={`btn btn_orange ${isShowButton ? '' : 'btn_disable'}`}>
                                    <span>{textBtn ? textBtn : 'Hoàn Tất và Thanh Toán!'}</span></button>
                            </div>
                        </div>
                    </div>
                    <div className="btnGroup w100">
                        <button onClick={handleContinue}
                                className={`btn btn_orange ${isShowButton ? '' : 'btn_disable'}`}><span>{textBtn ? textBtn : 'Hoàn Tất và Thanh Toán!'}</span>
                        </button>
                    </div>
                </div>
            }

        </div>

    );

}

const mapStateToProps = function (state) {
    return {
        isShowHotelInfo: state.hotelListReducer.isShowHotelInfo
    }
}

export default (withRouter(connect(mapStateToProps)(ReviewComboInfo)))
