import React from 'react';
import Slider from "react-slick/lib";
const settings = {
    infinite: true,
    speed: 2000,
    autoplay:true,
    slidesToShow: 6,
    autoplaySpeed:3000,
    slidesToScroll: 1,
    className: "achievement",
    responsive: [
        {
            breakpoint: 768,
            settings:'unslick'

        },
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 5
            }
        }
    ]
};
function HomeAchievement() {
    return (
        <div className="promotion bg-w">
            <div className="container">
                <div className="promotion_hot">
                    <div className="promotion_hot__title">
                        <h2> VNTRIP.VN - THÀNH TỰU &amp; GIẢI THƯỞNG</h2>
                    </div>
                    <Slider {...settings}>
                        <div className="achievement__item">
                            <div className="achievement__img">
                                <div className="sprite-logo sprite-logo_awards" />
                            </div>
                            <p className="title">GIẢI THƯỞNG</p>
                            <p className="vi">The Guide Awards</p>
                        </div>
                        <div className="achievement__item">
                            <div className="achievement__img">
                                <div className="sprite-logo sprite-logo_top10" />
                            </div>
                            <p className="title">TOP 10</p>
                            <p className="vi">Dịch vụ xuất sắc vì người tiêu dùng</p>
                        </div>
                        <div className="achievement__item">
                            <div className="achievement__img">
                                <div className="sprite-logo sprite-logo_gold" />
                            </div>
                            <p className="title">Bảng vàng</p>
                            <p className="vi">Doanh nghiệp uy tín, phát triển thịnh vượng</p>
                        </div>
                        <div className="achievement__item">
                            <div className="achievement__img">
                                <div className="sprite-logo sprite-logo_appell" />
                            </div>
                            <p className="title">Danh hiệu</p>
                            <p className="vi">Doanh nghiệp tiêu biểu của năm</p>
                        </div>
                        <div className="achievement__item">
                            <div className="achievement__img">
                                <div className="sprite-logo sprite-logo_prize1" />
                            </div>
                            <p className="title">Giải thưởng</p>
                            <p className="vi">Dịch vụ chất lượng cao ASEAN 2018</p>
                        </div>
                        <div className="achievement__item">
                            <div className="achievement__img">
                                <div className="sprite-logo sprite-logo_prize2" />
                            </div>
                            <p className="title">Giải thưởng</p>
                            <p className="vi">Doanh nghiệp văn hóa Việt Nam tiêu biểu</p>
                        </div>
                        <div className="achievement__item">
                            <div className="achievement__img">
                                <div className="sprite-logo sprite-logo_honors" />
                            </div>
                            <p className="title">Tuyên dương</p>
                            <p className="vi">Gương thanh niên thủ đô. Khởi nghiệp tiêu biểu</p>
                        </div>
                    </Slider>
                    <div className="achievement">
                    </div>
                </div>
            </div>
        </div>
    );

}

export default HomeAchievement;