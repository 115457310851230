import {notification, message} from 'antd'
import {isMobileScreen} from './hotel-func'
import {history} from '../index'
import {ga} from "react-ga";

const ALLOW_TYPE = ['success', 'info', 'warning', 'error']

export const showNotification = (type, text, title) => {
    if (ALLOW_TYPE.includes(type) && typeof text === 'string') {
        notification[type]({
            message: title || 'Vntrip',
            description: text,
            duration: 0,
            style: {
                borderColor: '#f5222d'
            }
        })
    }
    return false
}

export const showMessage = (type, text) => {
    if (ALLOW_TYPE.includes(type) && typeof text === 'string') {
        message[type](text, 4.5)
    }
}
export const ORDER_TYPE = {
    HOTEL: 'hotel',
    FLIGHT: 'flight',
    OTHER: 'other'
}
export const PATH_PAY_ZALO_WALLET = '/thanh-toan/zalo-wallet'
export const PATH_PAY_FAIL = '/thanh-toan/that-bai'
export const PATH_PAY_WAITING_CONFIRM = '/thanh-toan/cho-xac-nhan'
export const PATH_PAY_BANK_TRANSFER = '/thanh-toan/chuyen-khoan'
export const PATH_PAY_WAITING_APPROVAL = '/thanh-toan/cho-duyet'
export const PATH_PAY_SUCCESS = '/thanh-toan/thanh-cong'
export const handleResultAddOrder = ({data, booking_request_id, orderCode, gtmDataLayer, paymentMethod, dataCheckout, check_session}) => {
    const {redirect_url, redirect_url_mobile, transaction_id, order_data} = data
    const {id, payment_method, order_status, order_code} = order_data
    if (payment_method === 'payment_method_zalopay_wallet') {
        // pay online wallet
        if (isMobileScreen() && redirect_url_mobile) {
            window.location.href = redirect_url_mobile
        } else if (transaction_id) {
            history.push(`${PATH_PAY_ZALO_WALLET}?transaction_id=${transaction_id}&code=${uToA(redirect_url)}`)
        }
    } else if (['payment_method_bank_transfer', 'payment_method_bank_transfer_new'].includes(payment_method)) {
        // pay bank transfer
        history.push(`${PATH_PAY_BANK_TRANSFER}?transaction_id=${transaction_id}`)
    }
    else if (redirect_url) {
        // pay online
        if(check_session) {
            const postData = {
                redirect_url: redirect_url,
                transaction_id: transaction_id,
                order_code: order_code,
                order_id: id,
                order_status: order_status,
                payment_method: payment_method
            }
            window.parent.postMessage(postData, '*');
        }
        window.location.href = redirect_url

    } else if (payment_method === 'payment_method_debit') {
        // pay debit
        history.push(PATH_PAY_WAITING_APPROVAL + '?br=' + booking_request_id + '&order_id=' + id)
    } else {
        history.push(`${PATH_PAY_SUCCESS}?transaction_id=${transaction_id}&order_id=${id}&payment_method=${payment_method}&order_code=${order_code}`)
    }
    if (!orderCode) {
        let ga_url = PATH_PAY_SUCCESS + paymentMethod;
        ga('send', 'pageview', ga_url);
        // update datalayer
        gtmDataLayer.orderId = data.order_code;
        updateGtmDataLayer(gtmDataLayer);
        // send dataLayer for E-Commerce
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'ec.purchase',
            'ecommerce': {
                'purchase': {
                    'actionField': {
                        'id': data.order_code,
                        'affiliation': dataCheckout ? dataCheckout.hotel[0].token_data.rate_info.provider : '',
                        'revenue': dataCheckout ? dataCheckout.final_price : '',
                        'tax': dataCheckout && dataCheckout.hotel[0].token_data.rate_info.show_prices ? (dataCheckout.hotel[0].token_data.rate_info.show_prices.final_price.incl_vat_fee_price - dataCheckout.hotel[0].token_data.rate_info.show_prices.final_price.excl_vat_fee_price) : '',
                        'shipping': 0,
                        'coupon': dataCheckout && dataCheckout.coupon_code ? dataCheckout.coupon_code : null
                    },
                    'products': [{                            // List of productFieldObjects.
                        'name': dataCheckout ? dataCheckout.hotel[0].name : '',
                        'id': dataCheckout ? dataCheckout.hotel[0].id : '',
                        'price': dataCheckout ? dataCheckout.hotel[0].token_data.rate_info.pretty_prices.total_price_per_night_per_room : '',
                        'category': 'Khách sạn' + ' ' + dataCheckout.hotel[0].name,
                        'quantity': dataCheckout ? dataCheckout.hotel[0].token_data.rate_info.rooms * dataCheckout.hotel[0].token_data.rate_info.nights : ''
                    }]
                }
            }
        });
    }
}

export const uToA = str => {
    return window.btoa(unescape(encodeURIComponent(str)))
};

export const aToU = str => {
    return decodeURIComponent(escape(window.atob(str)))
};

const isNotObject = (val) => {
    return val == null || val === undefined || val === '';

};


export const updateGtmDataLayer = function (data) {
    // gtm
    setTimeout(function () {
        let email = "";
        let obj = {
            'event': 'VNT_PageView',
            'PageType': window.location.pathname,
            'Email': email,
        };
        if (isNotObject(data))
            data = {};
        obj = Object.assign(data, obj);
        if (typeof dataLayer === "object") window.dataLayer.push(obj);
    }, 3600)

};

