import React, {useEffect, useState} from 'react';
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
import * as queryString from 'query-string'
import { message } from 'antd';
import CheckOutBankTransfer from "../../component/checkoutResult/CheckOutBankTransfer";
import {toggleShowLoading} from "../../redux/actions/home-actions";
import {getOrderByTransactionId, getBankAccount} from "../../api-services/payment-method-service";
import {copyToClipboard} from "../../utils/common-func";

function CheckOutBankTransferContainer({location, dispatch}) {
    const [transactionId, setTransactionId] = useState('')
    const [listBank, setListBank] = useState([])
    const [selectedBank, setSelectedBank] = useState(null)
    const [orderData, setOrderData] = useState(null)
    const [orderTranData, setOrderTranData] = useState(null)
    const [orderType, setOrderType] = useState([])
    useEffect(() => {
        async function fetchData() {
            dispatch(toggleShowLoading(true))
            const query = queryString.parse(location.search)
            const transaction_id = query.transaction_id
            const [res_banks, res_order] = await Promise.all([
                getBankAccount(),
                getOrderByTransactionId(transaction_id)
            ])
            dispatch(toggleShowLoading(false))
            setTransactionId(String(transaction_id))
            let orderData = res_order.data
            if (res_banks.status === 'success' && orderData?.payment_method === 'payment_method_bank_transfer') {
                setSelectedBank(Array.isArray(res_banks.data) && res_banks.data.length > 0 ? res_banks.data[0] : null)
                setListBank(res_banks.data)
            }

            if (orderData.payment_method === 'payment_method_bank_transfer_new') {
                let {order_transaction_data} = orderData
                console.log('---order_transaction_data----', order_transaction_data)

                const tranData = order_transaction_data.find((item) => item.transaction_id === transaction_id)
                let { transaction_info } = tranData
                let transactionInfo = JSON.parse(transaction_info)
                console.log('---transactionInfo----', transactionInfo)
                let _selectedBank = {
                    owner_name: transactionInfo?.response?.account_name,
                    bank_display_name: transactionInfo?.response?.bank_name,
                    account_number: transactionInfo?.response?.account_number,
                    bank_short_name: transactionInfo?.response?.bank_short_name,
                }
                setSelectedBank(_selectedBank)
            }

            if (res_order.status === 'success') {
                setOrderData(res_order.data)
                const {order_transaction_data, order_type} = res_order.data
                setOrderType(order_type)
                setOrderTranData(
                    Array.isArray(order_transaction_data) && order_transaction_data.length > 0
                        ? order_transaction_data[0]
                        : null
                )

            }
        }

        fetchData()
    }, [dispatch, location])

    const handleSelectBank = bank => () => {
        setSelectedBank(bank)
    }

    const handleClickCopy = text => {
        copyToClipboard(text)
        message.success( 'Copied to clipboard')
    }

    return <CheckOutBankTransfer
                orderType={orderType}
                orderData={orderData}
                listBank={listBank}
                selectedBank={selectedBank}
                orderTranData={orderTranData}
                handleSelectBank={handleSelectBank}
                handleClickCopy={handleClickCopy}
                transactionId={transactionId}
             />

}

export default connect()(withRouter((CheckOutBankTransferContainer)));