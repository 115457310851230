import React, {useEffect} from 'react';
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {setLoadingMessage, toggleShowLoading} from "../../redux/actions/home-actions";

function Payment({ dispatch }) {
    useEffect(()=>{
        dispatch(toggleShowLoading(true))
        dispatch(setLoadingMessage('Đang chờ thanh toán, vui lòng chờ trong giây lát...'))
    },[])
    return (
        <div>

        </div>
    );
}

export default withRouter(connect(state => {
    return {
        showLoading: state.homeReducer.showLoading,
    }
})(Payment));