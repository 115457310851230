import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux'
import moment from 'moment';
import "moment/min/locales.min";
import {withRouter} from 'react-router-dom'
import HomeReview from "../../component/hotel-review/HomeReview";
import {getDataDetail} from "../../api-services/payment-method-service";
import {toggleShowLoading} from "../../redux/actions/home-actions";
import {getSurchargeLevels, updatePassenger, updateReservation} from "../../api-services/hotel-review";
import {change_alias, cutLastName, cutFirstName, saveListPassenger, getListPassenger} from "../../utils/review-func";
import {setDataTip} from "../../utils/hotel-func";
import {getDataHotel} from '../../api-services/select-hotel-service'
import {updateGtmDataLayer} from "../../utils/payment-func";
import {toggleClassNoScroll} from "../../utils/common-func";

function HomeReviewContainer({dispatch, location, history}) {
    const [dataBooking, setDataBooking] = useState({});
    const [bookingRequestId, setBookingRequestId] = useState('');
    const [listPassenger, setListPassenger] = useState('');
    const [dataHotel, setDataHotel] = useState('');
    const [priceInfantSurcharge, setPriceInfantSurcharge] = useState(null);
    useEffect(() => {
        const pathname = location.pathname;
        const token = pathname.split("/").slice(-1).pop();
        setBookingRequestId(token);
        fetchDataBooking(token)

    }, []);

    useEffect(() => {
        const pathname = location.pathname;
        const _bookingRequestId = pathname.split("/").slice(-1).pop();
        if (_bookingRequestId) {
            const gtmDataLayer = {
                'Content': [{
                    'id': _bookingRequestId
                }]
            };
            updateGtmDataLayer(gtmDataLayer)
        } else {
            updateGtmDataLayer()
        }
    }, []);

    const fetchDataBooking = async (br_id) => {
        try {
            dispatch(toggleShowLoading(true));
            const data = await getDataDetail(br_id);
            setDataBooking(data.data);
            dispatch(toggleShowLoading(false));
            if (data && data.data && data.data.flight) {
                let _listPassenger = [],
                    adult = data.data.flight[0].adult_count,
                    child = data.data.flight[0].child_count,
                    infant = data.data.flight[0].infant_count,
                    total = adult + child + infant;
                if (total > 0) {
                    for (let i = 0; i < total; i++) {
                        _listPassenger.push(
                            {
                                index: i,
                                isOpenDp:false,
                                type: '',
                                gender: 0,
                                lastName: "",
                                firstName: "",
                                listBaggage: []
                            }
                        );
                        if (i < adult) _listPassenger[i].type = 'ADT';
                        if (i < (adult + child) && i >= adult) {
                            _listPassenger[i].type = 'CHD';
                            _listPassenger[i].birthday = '';
                        }
                        if (i >= (adult + child)) {
                            _listPassenger[i].type = 'INF';
                            _listPassenger[i].birthday = '';
                        }

                    }
                }
                if (getListPassenger()) {
                    _listPassenger.map(res => {
                        getListPassenger().map(req => {
                            if (res.type === req.type && res.index === req.index) {
                                _listPassenger[res.index] = req
                            }
                        })
                    })
                }

                setListPassenger(_listPassenger)
            }
            if (data && data.data && data.data.hotel) {
                const hotel_id = data.data.hotel[0].token_data.hotel_id
                const dataHotel = await getDataHotel(hotel_id)
                if (dataHotel && dataHotel.status === 'success' && dataHotel.data) {
                    setDataHotel(dataHotel.data.results)
                }
            }
            if (data && data.data) {
                getSurchargeLevel(data.data)
            }
        } catch (e) {
            throw e
        }
    };

    const getSurchargeLevel = async (dataBooking) => {
        const dataCombo = dataBooking ? dataBooking.combo : {};
        const dataFlight = dataBooking ? dataBooking.flight : {};

        const data = await getSurchargeLevels(dataCombo.deal_id)
        if (data && data.status === 'success') {
            data.data && data.data.forEach(res => {
                if (dataFlight[0] && dataFlight[0].infant_count > 0 && dataCombo &&  dataCombo.upgrade_data && res.code === dataCombo.upgrade_data.to) {
                    setPriceInfantSurcharge(res.infant_surcharge)
                }
                else {
                    if(res.code === 'STANDARD') {
                        setPriceInfantSurcharge(res.infant_surcharge)
                    }
                }
            })
        }


    }

    const ValidateListPassenger = (listPassenger) => {

        for (let i = 0; i < listPassenger.length; i++) {
            if (listPassenger[i].gender === 0) {
                setDataTip(document.getElementsByClassName(`genderId_${i}`), 'Bạn chưa chọn giới tính')
                return false;
            }
            if (!listPassenger[i].firstName && !listPassenger[i].lastName) {
                setDataTip(document.getElementsByClassName(`passengerId_${i}`), 'Bạn chưa nhập đầy đủ họ tên')
                return false;
            }
            if (listPassenger[i].type !== 'ADT' && !listPassenger[i].birthday) {
                setDataTip(document.getElementsByClassName(`birthdayId_${i}`), 'Bạn chưa chọn ngày sinh')
                return false;
            }
        }
        return true
    };

    const handleChangeBirthDay = (date, index, type) => {
        let newListPassenger = [...listPassenger];
        newListPassenger[index].birthday = moment(date).format('DDMMYYYY');
        setListPassenger(newListPassenger)
    };

    const handleOpenDp = (open,index) => {
        let newListPassenger = [...listPassenger];
        newListPassenger[index].isOpenDp = open;
        setListPassenger(newListPassenger)
        toggleClassNoScroll()
    }

    const handleCloseDp = (index) => {
        let newListPassenger = [...listPassenger];
        newListPassenger[index].isOpenDp = false;
        setListPassenger(newListPassenger)
        toggleClassNoScroll()
    }

    const handleContinue = async () => {
        try {
            if (ValidateListPassenger(listPassenger)) {
                const dataPassenger = {
                    listPassenger: listPassenger
                };
                saveListPassenger(listPassenger)
                dispatch(toggleShowLoading(true))
                const data = await updatePassenger(dataPassenger, dataBooking.flight[0].suggestion_id)

                if (data && data.status === 200) {
                    dispatch(toggleShowLoading(false))
                    history.push(`/payment-method/${bookingRequestId}`)
                    await updateReservation({
                        bookingRequestId: bookingRequestId,
                        suggestionId: dataBooking.flight[0].suggestion_id
                    });
                }
            }

        } catch (e) {
            dispatch(toggleShowLoading(false))

            throw e
        }
    };

    const changeGender = (e, index) => {
        let newListPassenger = [...listPassenger];
        let gender = JSON.parse(e.target.value);

        newListPassenger[index].gender = gender;

        setListPassenger(newListPassenger)
    };

    const handOnChange = (e, index) => {
        let str = e.target.value
        document.getElementById(`passengerId_${index}`).value = e.target.value
        str.toUpperCase()
        str = str.replace(/[àáạảãâầấậẩẫăằắặẳẵ]/g, "a");
        str = str.replace(/[èéẹẻẽêềếệểễ]/g, "e");
        str = str.replace(/[ìíịỉĩ]/g, "i");
        str = str.replace(/[òóọỏõôồốộổỗơờớợởỡ]/g, "o");
        str = str.replace(/[ùúụủũưừứựửữ]/g, "u");
        str = str.replace(/[ỳýỵỷỹ]/g, "y");
        str = str.replace(/đ/g, "d");
        str = str.replace(/!|@|%|\^|\*|\(|\)|\+|=|<|>|\?|\/|,|\.|:|;|'|"|&|#|\[|]|~|$|_/g, "");
        document.getElementById(`passengerId_${index}`).value = str
        let newListPassenger = [...listPassenger],
            lastName = change_alias(cutLastName(str)),
            firstName = change_alias(cutFirstName(str));

        newListPassenger[index].lastName = lastName.toUpperCase();
        newListPassenger[index].firstName = firstName.toUpperCase();

        setListPassenger(newListPassenger)
    };

    return (
        <HomeReview
            dataBooking={dataBooking}
            listPassenger={listPassenger}
            priceInfantSurcharge={priceInfantSurcharge}
            dataHotel={dataHotel}
            handleChangeBirthDay={handleChangeBirthDay}
            handleContinue={handleContinue}
            changeGender={changeGender}
            handOnChange={handOnChange}
            handleOpenDp={handleOpenDp}
            handleCloseDp={handleCloseDp}
        />
    );

}

export default withRouter(connect(state => {
    return {
        showLoading: state.homeReducer.showLoading
    }
})(HomeReviewContainer));
