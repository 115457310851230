import { removeDiacritics } from '../utils/common-func'

export const PASSENGER_CONST = {
    ADT_ASC: 'ADULT_ASC',
    ADT_DESC: 'ADULT_DESC',
    CHD_ASC: 'CHILD_ASC',
    CHD_DESC: 'CHILD_DESC',
    INF_ASC: 'INFANT_ASC',
    INF_DESC: 'INFANT_DESC'
}
export const DEFAULT_OBJECT_FLIGHT = {
    startPoint: '',
    endPoint: '',
    departDate: '',
    airline: '',
    startData: null,
    endData: null
}

export const RETURN_FLIGHT = {
    SGN: [
        {
            rank: 2,
            code: 'DAD',
            distance: '800km',
            duration: '1h',
            position: 'top'
        },
        {
            code: 'HPH',
            distance: '600km',
            duration: '45m',
            position: 'bottom',
            rank: 1
        },
        {
            distance: '600km',
            duration: '45m',
            position: 'bottom',
            rank: 1,
            code: 'CXR'
        },
        {
            distance: '600km',
            duration: '45m',
            position: 'bottom',
            rank: 1,
            code: 'HAN'
        },
        {
            duration: '1h15m',
            position: 'top',
            rank: 1,
            code: 'HUI',
            distance: '1100km'
        },
        {
            duration: '1h15m',
            position: 'top',
            rank: 1,
            code: 'PQC',
            distance: '1100km'
        },
        {
            rank: 1,
            code: 'DLI',
            distance: '1.700km',
            duration: '1h45m',
            position: 'bottom'
        },
        {
            duration: '1h45m',
            position: 'top',
            rank: 2,
            code: 'VDH',
            distance: '1.700km'
        },
        {
            duration: '1h45m',
            position: 'top',
            rank: 2,
            code: 'UIH',
            distance: '1.700km'
        }
    ],
    HAN: [
        {
            code: 'SGN',
            distance: '1.700km',
            duration: '1h45m',
            position: 'top',
            rank: 2
        },
        {
            code: 'DAD',
            distance: '1000km',
            duration: '1h15m',
            position: 'top',
            rank: 2
        },
        {
            duration: '1h',
            position: 'bottom',
            rank: 1,
            code: 'CXR',
            distance: '700km'
        },
        {
            rank: 1,
            code: 'VDH',
            distance: '700km',
            duration: '1h',
            position: 'bottom'
        },
        {
            code: 'UIH',
            distance: '700km',
            duration: '1h',
            position: 'bottom',
            rank: 1
        },
        {
            duration: '1h15m',
            position: 'top',
            rank: 1,
            code: 'PQC',
            distance: '1100km'
        },
        {
            rank: 1,
            code: 'DLI',
            distance: '1.700km',
            duration: '1h45m',
            position: 'bottom'
        }
    ],
    DAD: [
        {
            distance: '1.700km',
            duration: '1h45m',
            position: 'top',
            rank: 2,
            code: 'HAN'
        },
        {
            code: 'SGN',
            distance: '1.700km',
            duration: '1h45m',
            position: 'bottom',
            rank: 2
        },
        {
            code: 'HPH',
            distance: '800km',
            duration: '1h45m',
            position: 'bottom',
            rank: 2
        },
        {
            code: 'CXR',
            distance: '800km',
            duration: '1h45m',
            position: 'bottom',
            rank: 2
        },
        {
            code: 'BMV',
            distance: '1.700km',
            duration: '1h45m',
            position: 'bottom',
            rank: 1
        },
        {
            duration: '1h45m',
            position: 'bottom',
            rank: 1,
            code: 'VII',
            distance: '1.700km'
        },
        {
            position: 'bottom',
            rank: 1,
            code: 'VCA',
            distance: '1.700km',
            duration: '1h45m'
        }
    ],
    CXR: [
        {
            code: 'SGN',
            distance: '1.700km',
            duration: '1h45m',
            position: 'bottom',
            rank: 2
        },
        {
            distance: '1.700km',
            duration: '1h45m',
            position: 'top',
            rank: 2,
            code: 'HAN'
        },
        {
            code: 'DAD',
            distance: '1.700km',
            duration: '1h45m',
            position: 'bottom',
            rank: 1
        }
    ],
    HPH: [
        {
            code: 'SGN',
            distance: '1.100km',
            duration: '1h15m',
            position: 'bottom',
            rank: 2
        },
        {
            code: 'DAD',
            distance: '800km',
            duration: '1h',
            position: 'top',
            rank: 2
        }
    ]
}

export const ACTIVE_ROUTE_CONF = [
    'SGN_HAN',
    'SGN_DAD',
    'SGN_HPH',
    'SGN_CXR',
    'SGN_PQC',
    'SGN_DLI',
    'SGN_VDH',
    'SGN_UIH',
    'HAN_DAD',
    'HAN_CXR',
    'HAN_PQC',
    'HAN_DLI',
    'HAN_VDH',
    'HAN_UIH',
    'DAD_HPH',
    'DAD_CXR',
    'DAD_PQC',
    'CXR_HPH'
]

export const domesticAirport = [
    {
        name: 'Sân bay quốc tế Nội Bài',
        code: 'HAN',
        province_id: 66,
        created_at: '2017-05-18T02:59:35.061Z',
        updated_at: '2018-05-11T13:36:29.089Z',
        type: 'domestic',
        region: 1,
        province_name: 'Hà Nội',
        has_in_atadi: true,
        region_name: 'North VN',
        region_name_vi: 'Miền Bắc'
    },
    {
        name: 'Sân bay quốc tế Cát Bi',
        code: 'HPH',
        province_id: 70,
        created_at: '2017-05-18T02:59:35.061Z',
        updated_at: '2018-05-11T13:36:29.097Z',
        type: 'domestic',
        region: 1,
        province_name: 'Hải Phòng',
        has_in_atadi: true,
        region_name: 'North VN',
        region_name_vi: 'Miền Bắc'
    },
    {
        name: 'Sân bay Điện Biên Phủ',
        code: 'DIN',
        province_id: 75,
        created_at: '2017-05-18T02:59:35.061Z',
        updated_at: '2018-05-11T13:36:29.098Z',
        type: 'domestic',
        region: 1,
        province_name: 'Điện Biên',
        has_in_atadi: true,
        region_name: 'North VN',
        region_name_vi: 'Miền Bắc'
    },
    {
        name: 'Sân bay quốc tế Cam Ranh',
        code: 'CXR',
        province_id: 86,
        created_at: '2017-05-18T02:59:35.061Z',
        updated_at: '2018-05-11T13:36:29.096Z',
        type: 'domestic',
        region: 2,
        province_name: 'Nha Trang',
        has_in_atadi: true,
        region_name: 'Central VN',
        region_name_vi: 'Miền Trung'
    },
    {
        name: 'Sân bay quốc tế Liên Khương',
        code: 'DLI',
        province_id: 90,
        created_at: '2017-05-18T02:59:35.061Z',
        updated_at: '2018-05-11T13:36:29.097Z',
        type: 'domestic',
        region: 2,
        province_name: 'Đà Lạt',
        has_in_atadi: true,
        region_name: 'Central VN',
        region_name_vi: 'Miền Trung'
    },
    {
        name: 'Sân bay quốc tế Đà Nẵng',
        code: 'DAD',
        province_id: 68,
        created_at: '2017-05-18T02:59:35.061Z',
        updated_at: '2018-05-11T13:36:29.096Z',
        type: 'domestic',
        region: 2,
        province_name: 'Đà Nẵng',
        has_in_atadi: true,
        region_name: 'Central VN',
        region_name_vi: 'Miền Trung'
    },
    {
        name: 'Sân bay Đồng Hới',
        code: 'VDH',
        province_id: 99,
        created_at: '2017-05-18T02:59:35.061Z',
        updated_at: '2018-05-11T13:36:29.113Z',
        type: 'domestic',
        region: 2,
        province_name: 'Quảng Bình',
        has_in_atadi: true,
        region_name: 'Central VN',
        region_name_vi: 'Miền Trung'
    },
    {
        name: 'Sân bay quốc tế Chu Lai',
        code: 'VCL',
        province_id: 100,
        created_at: '2017-05-18T02:59:35.061Z',
        updated_at: '2018-05-11T13:36:29.111Z',
        type: 'domestic',
        region: 2,
        province_name: 'Quảng Nam',
        has_in_atadi: true,
        region_name: 'Central VN',
        region_name_vi: 'Miền Trung'
    },
    {
        name: 'Sân bay Buôn Ma Thuột',
        code: 'BMV',
        province_id: 73,
        created_at: '2017-05-18T02:59:35.061Z',
        updated_at: '2018-05-11T13:36:29.104Z',
        type: 'domestic',
        region: 2,
        province_name: 'Đắk Lắk',
        has_in_atadi: true,
        region_name: 'Central VN',
        region_name_vi: 'Miền Trung'
    },
    {
        name: 'Sân bay Pleiku',
        code: 'PXU',
        province_id: 78,
        created_at: '2017-05-18T02:59:35.061Z',
        updated_at: '2018-05-11T13:36:29.107Z',
        type: 'domestic',
        region: 2,
        province_name: 'Gia Lai',
        has_in_atadi: true,
        region_name: 'Central VN',
        region_name_vi: 'Miền Trung'
    },
    {
        name: 'Sân bay quốc tế Vinh',
        code: 'VII',
        province_id: 95,
        created_at: '2017-05-18T02:59:35.061Z',
        updated_at: '2018-05-11T13:36:29.114Z',
        type: 'domestic',
        region: 2,
        province_name: 'Nghệ An',
        has_in_atadi: true,
        region_name: 'Central VN',
        region_name_vi: 'Miền Trung'
    },
    {
        name: 'Sân bay Thọ Xuân',
        code: 'THD',
        province_id: 109,
        created_at: '2017-05-18T02:59:35.061Z',
        updated_at: '2018-05-11T13:36:29.110Z',
        type: 'domestic',
        region: 2,
        province_name: 'Thanh Hóa',
        has_in_atadi: true,
        region_name: 'Central VN',
        region_name_vi: 'Miền Trung'
    },
    {
        name: 'Sân bay quốc tế Phú Bài',
        code: 'HUI',
        province_id: 110,
        created_at: '2017-05-18T02:59:35.061Z',
        updated_at: '2018-05-11T13:36:29.097Z',
        type: 'domestic',
        region: 2,
        province_name: 'Thừa Thiên Huế',
        has_in_atadi: true,
        region_name: 'Central VN',
        region_name_vi: 'Miền Trung'
    },
    {
        name: 'Sân bay Tuy Hòa',
        code: 'TBB',
        province_id: 117,
        created_at: '2017-05-18T02:59:35.061Z',
        updated_at: '2018-05-11T13:36:29.109Z',
        type: 'domestic',
        region: 2,
        province_name: 'Phú Yên',
        has_in_atadi: true,
        region_name: 'Central VN',
        region_name_vi: 'Miền Trung'
    },
    {
        name: 'Sân bay Phù Cát',
        code: 'UIH',
        province_id: 125,
        created_at: '2017-05-18T02:59:35.061Z',
        updated_at: '2018-05-11T13:36:29.110Z',
        type: 'domestic',
        region: 2,
        province_name: 'Quy Nhơn',
        has_in_atadi: true,
        region_name: 'Central VN',
        region_name_vi: 'Miền Trung'
    },
    {
        name: 'Sân bay quốc tế Tân Sơn Nhất',
        code: 'SGN',
        province_id: 67,
        created_at: '2017-05-18T02:59:35.061Z',
        updated_at: '2018-05-11T13:36:29.089Z',
        type: 'domestic',
        region: 3,
        province_name: 'TP Hồ Chí Minh',
        has_in_atadi: true,
        region_name: 'Southern VN',
        region_name_vi: 'Miền Nam'
    },
    {
        name: 'Sân bay Rạch Giá',
        code: 'VKG',
        province_id: 87,
        created_at: '2017-05-18T02:59:35.061Z',
        updated_at: '2018-05-11T13:36:29.116Z',
        type: 'domestic',
        region: 3,
        province_name: 'Kiên Giang',
        has_in_atadi: true,
        region_name: 'Southern VN',
        region_name_vi: 'Miền Nam'
    },
    {
        name: 'Sân bay quốc tế Phú Quốc',
        code: 'PQC',
        province_id: 87,
        created_at: '2017-05-18T02:59:35.061Z',
        updated_at: '2018-05-11T13:36:29.098Z',
        type: 'domestic',
        region: 3,
        province_name: 'Phú Quốc',
        has_in_atadi: true,
        region_name: 'Southern VN',
        region_name_vi: 'Miền Nam'
    },
    {
        name: 'Sân bay quốc tế Cần Thơ',
        code: 'VCA',
        province_id: 69,
        created_at: '2017-05-18T02:59:35.061Z',
        updated_at: '2018-05-11T13:36:29.111Z',
        type: 'domestic',
        region: 3,
        province_name: 'Cần Thơ',
        has_in_atadi: true,
        region_name: 'Southern VN',
        region_name_vi: 'Miền Nam'
    },
    {
        name: 'Sân bay Cà Mau',
        code: 'CAH',
        province_id: 71,
        created_at: '2017-05-18T02:59:35.061Z',
        updated_at: '2018-05-11T13:36:29.107Z',
        type: 'domestic',
        region: 3,
        province_name: 'Cà Mau',
        has_in_atadi: true,
        region_name: 'Southern VN',
        region_name_vi: 'Miền Nam'
    },
    {
        name: 'Sân bay Côn Đảo',
        code: 'VCS',
        province_id: 119,
        created_at: '2017-05-18T02:59:35.061Z',
        updated_at: '2018-05-11T13:36:29.112Z',
        type: 'domestic',
        region: 3,
        province_name: 'Bà Rịa - Vũng Tàu',
        has_in_atadi: true,
        region_name: 'Southern VN',
        region_name_vi: 'Miền Nam'
    }
]

const activeFlight = {}
for (const route of ACTIVE_ROUTE_CONF) {
    const routeItem = route.split('_')
    if (!activeFlight[routeItem[0]]) {
        activeFlight[routeItem[0]] = []
    }
    if (activeFlight[routeItem[0]].indexOf(routeItem[1]) === -1) {
        activeFlight[routeItem[0]].push(routeItem[1])
    }
    if (!activeFlight[routeItem[1]]) {
        activeFlight[routeItem[1]] = []
    }
    if (activeFlight[routeItem[1]].indexOf(routeItem[0]) === -1) {
        activeFlight[routeItem[1]].push(routeItem[0])
    }
}
export const ORIGIN_AIRPORT = Object.keys(activeFlight)
export const ACTIVE_ROUTE = activeFlight

for (const item of domesticAirport) {
    item.key = (item.province_name + '_' + item.name + '_' + item.code).toLowerCase()
    item.key = item.key + '_' + removeDiacritics(item.key)
}
export const DOMESTIC_AIRPORT = domesticAirport

export const getNamePointFromCode = (code) => {
    const airPort = DOMESTIC_AIRPORT.filter((air) => air?.code === code)
    return airPort?.[0]
}

//
// export const INTERNATIONAL_AIRPORT = [
//     {
//         name: 'Taiwan',
//         code: 'TPE',
//         province_id: null,
//         created_at: '2018-05-11T13:36:29.136Z',
//         updated_at: null,
//         type: 'international',
//         region: null,
//         province_name: 'Taiwan',
//         has_in_atadi: true,
//         region_name: null,
//         region_name_vi: null
//     },
//     {
//         name: 'Singapore',
//         code: 'SIN',
//         province_id: null,
//         created_at: '2018-05-11T13:36:29.117Z',
//         updated_at: null,
//         type: 'international',
//         region: null,
//         province_name: 'Singapore',
//         has_in_atadi: true,
//         region_name: null,
//         region_name_vi: null
//     },
//     {
//         name: 'Bangkok',
//         code: 'BKK',
//         province_id: null,
//         created_at: '2018-05-11T13:36:29.120Z',
//         updated_at: null,
//         type: 'international',
//         region: null,
//         province_name: 'Bangkok',
//         has_in_atadi: true,
//         region_name: null,
//         region_name_vi: null
//     },
//     {
//         name: 'Kuala Lumpur',
//         code: 'KUL',
//         province_id: null,
//         created_at: '2018-05-11T13:36:29.120Z',
//         updated_at: null,
//         type: 'international',
//         region: null,
//         province_name: 'Kuala Lumpur',
//         has_in_atadi: true,
//         region_name: null,
//         region_name_vi: null
//     },
//     {
//         name: 'Cao Hùng',
//         code: 'KHH',
//         province_id: null,
//         created_at: '2018-05-11T13:36:29.139Z',
//         updated_at: null,
//         type: 'international',
//         region: null,
//         province_name: 'Cao Hùng',
//         has_in_atadi: true,
//         region_name: null,
//         region_name_vi: null
//     },
//     {
//         name: 'Hong Kong',
//         code: 'HKG',
//         province_id: null,
//         created_at: '2018-05-11T13:36:29.123Z',
//         updated_at: null,
//         type: 'international',
//         region: null,
//         province_name: 'Hong Kong',
//         has_in_atadi: true,
//         region_name: null,
//         region_name_vi: null
//     },
//     {
//         name: 'Siem Reap',
//         code: 'REP',
//         province_id: null,
//         created_at: '2018-05-11T13:36:29.120Z',
//         updated_at: null,
//         type: 'international',
//         region: null,
//         province_name: 'Siem Reap',
//         has_in_atadi: true,
//         region_name: null,
//         region_name_vi: null
//     },
//     {
//         name: 'Busan',
//         code: 'PUS',
//         province_id: null,
//         created_at: '2018-05-11T13:36:29.138Z',
//         updated_at: null,
//         type: 'international',
//         region: null,
//         province_name: 'Busan',
//         has_in_atadi: true,
//         region_name: null,
//         region_name_vi: null
//     },
//     {
//         name: 'Yangon',
//         code: 'RGN',
//         province_id: null,
//         created_at: '2018-05-11T13:36:29.121Z',
//         updated_at: null,
//         type: 'international',
//         region: null,
//         province_name: 'Yangon',
//         has_in_atadi: true,
//         region_name: null,
//         region_name_vi: null
//     },
//     {
//         name: 'Seoul Incheon',
//         code: 'ICN',
//         province_id: null,
//         created_at: '2018-05-11T13:36:29.138Z',
//         updated_at: null,
//         type: 'international',
//         region: null,
//         province_name: 'Seoul Incheon',
//         has_in_atadi: true,
//         region_name: null,
//         region_name_vi: null
//     }
// ]
