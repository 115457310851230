import React from 'react';
import DisplayPrice from "../common/DisplayPrice";
import {PATH_PAY_WAITING_CONFIRM} from "../../utils/payment-func";
import {Link} from "react-router-dom";

function CheckOutBankTransfer(
    {
        orderData,
        listBank,
        selectedBank,
        orderTranData,
        transactionId,
        handleSelectBank,
        handleClickCopy,
    }) {
    return (
        <section className="vntContent comboCheckout">
            <div className="container">
                <div className="vntCheckout">
                    <div className="vntCheckout__left">
                        <h2 className="heading2">Hướng dẫn chuyển khoản</h2>
                        <div className="vntCheckout__box">
                            {
                                orderData?.payment_method === 'payment_method_bank_transfer' && <>
                                    <p className="pTitle">Chọn ngân hàng</p>
                                    <ul className="listBank">
                                        {listBank.map(bank => {
                                            return (
                                                <li
                                                    key={bank.id}
                                                    className={`listBank__item ${
                                                        selectedBank && selectedBank.id === bank.id ? 'active' : ''
                                                        }`}
                                                >
                                                    <button
                                                        type="button"
                                                        className="listBank__btn"
                                                        onClick={handleSelectBank(bank)}
                                                    >
                                                        <img src={bank.bank_icon} alt={bank.bank_short_name} />
                                                        <p>{bank.bank_short_name}</p>
                                                        <svg
                                                            width={24}
                                                            height={24}
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M12 1.5C6.20156 1.5 1.5 6.20156 1.5 12C1.5 17.7984 6.20156 22.5 12 22.5C17.7984 22.5 22.5 17.7984 22.5 12C22.5 6.20156 17.7984 1.5 12 1.5Z"
                                                                fill="#FA8C16"
                                                            />
                                                            <path
                                                                d="M16.3831 8.27344H15.2839C15.0449 8.27344 14.8175 8.38828 14.6769 8.58516L10.9925 13.6945L9.32374 11.3789C9.18311 11.1844 8.95811 11.0672 8.71671 11.0672H7.61748C7.46514 11.0672 7.37608 11.2406 7.46514 11.3648L10.3855 15.4148C10.4545 15.5111 10.5454 15.5896 10.6508 15.6437C10.7561 15.6978 10.8729 15.7261 10.9913 15.7261C11.1098 15.7261 11.2265 15.6978 11.3319 15.6437C11.4373 15.5896 11.5282 15.5111 11.5972 15.4148L16.5331 8.57109C16.6245 8.44688 16.5355 8.27344 16.3831 8.27344Z"
                                                                fill="white"
                                                            />
                                                        </svg>
                                                    </button>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </>
                            }
                            <p className="pTitle">Vui lòng chuyển khoản theo thông tin</p>
                            <div className="bankInfo">
                                {selectedBank && (
                                    <ul className="bankInfo__list">
                                        <li>
                                            <p className="p1">Số tài khoản:</p>
                                            <p className="p2">
                                                {selectedBank.account_number}
                                                <button
                                                    onClick={() => {
                                                        handleClickCopy(selectedBank.account_number)
                                                    }}
                                                    className="btnCopy"
                                                >
                                                    <svg
                                                        width={12}
                                                        height={14}
                                                        viewBox="0 0 12 14"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M11 0H2.625C2.55625 0 2.5 0.05625 2.5 0.125V1C2.5 1.06875 2.55625 1.125 2.625 1.125H10.375V11.875C10.375 11.9438 10.4312 12 10.5 12H11.375C11.4438 12 11.5 11.9438 11.5 11.875V0.5C11.5 0.223437 11.2766 0 11 0ZM9 2H1C0.723437 2 0.5 2.22344 0.5 2.5V10.7922C0.5 10.925 0.553125 11.0516 0.646875 11.1453L3.35469 13.8531C3.38906 13.8875 3.42813 13.9156 3.47031 13.9391V13.9688H3.53594C3.59062 13.9891 3.64844 14 3.70781 14H9C9.27656 14 9.5 13.7766 9.5 13.5V2.5C9.5 2.22344 9.27656 2 9 2ZM3.46875 12.3781L2.12344 11.0312H3.46875V12.3781ZM8.375 12.875H4.46875V10.6562C4.46875 10.3109 4.18906 10.0312 3.84375 10.0312H1.625V3.125H8.375V12.875Z"
                                                            fill="#595959"
                                                        />
                                                    </svg>
                                                </button>
                                            </p>
                                        </li>
                                        <li>
                                            <p className="p1">Ngân hàng:</p>
                                            <p className="p2">{selectedBank.bank_display_name}</p>
                                        </li>
                                        {
                                            orderData?.payment_method === 'payment_method_bank_transfer' && <li>
                                                <p className="p1">Chi nhánh:</p>
                                                <p className="p2">{selectedBank.branch_name}</p>
                                            </li>
                                        }
                                        <li>
                                            <p className="p1">Tên chủ tài khoản:</p>
                                            <p className="p2">{selectedBank.owner_name}</p>
                                        </li>
                                        <li>
                                            <p className="p1">Nội dung chuyển khoản:</p>
                                            {orderData && (
                                                <p className="p2">
                                                    {orderData.order_code}
                                                    <button
                                                        onClick={() => {
                                                            handleClickCopy(orderData.order_code)
                                                        }}
                                                        className="btnCopy"
                                                    >
                                                        <svg
                                                            width={12}
                                                            height={14}
                                                            viewBox="0 0 12 14"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M11 0H2.625C2.55625 0 2.5 0.05625 2.5 0.125V1C2.5 1.06875 2.55625 1.125 2.625 1.125H10.375V11.875C10.375 11.9438 10.4312 12 10.5 12H11.375C11.4438 12 11.5 11.9438 11.5 11.875V0.5C11.5 0.223437 11.2766 0 11 0ZM9 2H1C0.723437 2 0.5 2.22344 0.5 2.5V10.7922C0.5 10.925 0.553125 11.0516 0.646875 11.1453L3.35469 13.8531C3.38906 13.8875 3.42813 13.9156 3.47031 13.9391V13.9688H3.53594C3.59062 13.9891 3.64844 14 3.70781 14H9C9.27656 14 9.5 13.7766 9.5 13.5V2.5C9.5 2.22344 9.27656 2 9 2ZM3.46875 12.3781L2.12344 11.0312H3.46875V12.3781ZM8.375 12.875H4.46875V10.6562C4.46875 10.3109 4.18906 10.0312 3.84375 10.0312H1.625V3.125H8.375V12.875Z"
                                                                fill="#595959"
                                                            />
                                                        </svg>
                                                    </button>
                                                </p>
                                            )}
                                        </li>
                                    </ul>
                                )}

                                <div className="bankInfo__flex">
                                    <div className="bankInfo__price">
                                        <span className="span1">Số tiền bạn cần phải chuyển khoản</span>
                                        <span className="span2">
                                        {orderTranData && <DisplayPrice price={orderTranData.gw_transaction_id} />}
                                    </span>
                                    </div>
                                    <div className="bankInfo__btn">
                                        {transactionId && (
                                            <Link
                                                to={PATH_PAY_WAITING_CONFIRM + '?transaction_id=' + transactionId}
                                                className="btn btn_lg"
                                            >
                                                Tôi đã thanh toán xong
                                            </Link>
                                        )}
                                    </div>
                                </div>
                                <p className="bankInfo__note">
                                    <strong>LƯU Ý!</strong>Chuyển số tiền chính xác{' '}
                                    {orderTranData && <DisplayPrice price={orderTranData.gw_transaction_id} />} trong mọi
                                    trường hợp
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="vntCheckout__right">
                        {/*{orderType === ORDER_TYPE.FLIGHT && (*/}
                        {/*    <FlightCheckoutInfoContainer*/}
                        {/*        departRateData={departData ? departData.calculated_price : null}*/}
                        {/*        returnRateData={returnData ? returnData.calculated_price : null}*/}
                        {/*        viewFrom={'BANK_TRANSFER'}*/}
                        {/*    />*/}
                        {/*)}*/}
                        {/*{orderType === ORDER_TYPE.HOTEL && (*/}
                        {/*    <HotelCheckoutInfoContainer*/}
                        {/*        hotels={orderItemHotelData}*/}
                        {/*        totalPrice={totalPriceHotel}*/}
                        {/*        viewFrom={'BANK_TRANSFER'}*/}
                        {/*    />*/}
                        {/*)}*/}
                    </div>
                </div>
            </div>
        </section>
    );

}

export default CheckOutBankTransfer;