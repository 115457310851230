import React from 'react';
import ReviewComboInfoContainer from "../../containers/hotel-review/ReviewComboInfoContainer";
import ReviewComboFlightContainer from "../../containers/hotel-review/ReviewComboFlightContainer";
import ReviewComboHotelContainer from "../../containers/hotel-review/ReviewComboHotelContainer";
import PassengerInfoContainer from "../../containers/hotel-review/PassengerInfoContainer";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {toggleShowHotelInfo} from "../../redux/actions/hotel-list";
import DisplayPrice from "../common/DisplayPrice";

function HomeReview(
    {
        dataBooking,
        handleContinue,
        listPassenger,
        changeGender,
        handOnChange,
        handleChangeBirthDay,
        dataHotel,
        isShowHotelInfo,
        dispatch,
        priceInfantSurcharge,
        handleOpenDp,
        handleCloseDp
    }) {
    return (
        <section className="step5">
            <div className="container">
                <h2 className="text-center mb15">Bạn đã gần xong rồi!</h2>
                <div className={`page-content ${isShowHotelInfo ? 'open' : ''}`}>
                    <div className="page-right">
                        <PassengerInfoContainer
                            dataBooking={dataBooking}
                            handleChangeBirthDay={handleChangeBirthDay}
                            listPassenger={listPassenger}
                            changeGender={changeGender}
                            handOnChange={handOnChange}
                            handleCloseDp={handleCloseDp}
                            handleOpenDp={handleOpenDp}
                        />
                        <ReviewComboFlightContainer
                            dataBooking={dataBooking}
                        />
                        <ReviewComboHotelContainer
                            dataBooking={dataBooking}
                            dataHotel={dataHotel}

                        />
                    </div>
                    <ReviewComboInfoContainer
                        dataBooking={dataBooking}
                        handleContinue={handleContinue}
                        isShowButton={true}
                        priceInfantSurcharge={priceInfantSurcharge}
                    />
                    <div className="menu-mobile__backdrop"></div>
                    <div className="btnGroup w100 btn-mobile">
                        <div className="hotelReview__info__totalPrice" onClick={()=> dispatch(toggleShowHotelInfo(true))}>
                            <div className="d-flex">
                                <p>Tổng cộng</p>
                                <p className="price"><span> <DisplayPrice price={dataBooking && dataBooking.combo && dataBooking.combo.price}/></span></p>
                            </div>
                            <div className="hotelReview__info__fee d-flex">
                                <span>Đây là giá cuối, không thêm phí phụ thu</span>
                                <span className="detail">Chi tiết
                                <svg width={12} height={12} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path
                                      d="M6 0.75C3.101 0.75 0.75 3.101 0.75 6C0.75 8.899 3.101 11.25 6 11.25C8.899 11.25 11.25 8.899 11.25 6C11.25 3.101 8.899 0.75 6 0.75ZM6 10.3595C3.593 10.3595 1.6405 8.407 1.6405 6C1.6405 3.593 3.593 1.6405 6 1.6405C8.407 1.6405 10.3595 3.593 10.3595 6C10.3595 8.407 8.407 10.3595 6 10.3595Z"
                                      fill="#1890FF"/>
                                  <path
                                      d="M5.4375 3.9375C5.4375 4.0865 5.4965 4.23 5.6025 4.3355C5.7075 4.4405 5.851 4.5 6 4.5C6.149 4.5 6.2925 4.4405 6.398 4.335C6.503 4.23 6.5625 4.0865 6.5625 3.9375C6.5625 3.7885 6.5035 3.645 6.3975 3.54C6.2925 3.4345 6.149 3.375 6 3.375C5.851 3.375 5.7075 3.4345 5.602 3.54C5.497 3.645 5.4375 3.7885 5.4375 3.9375ZM6.2815 5.25H5.7185C5.667 5.25 5.625 5.292 5.625 5.344V8.531C5.625 8.583 5.667 8.625 5.719 8.625H6.281C6.333 8.625 6.375 8.583 6.375 8.531V5.3435C6.375 5.292 6.333 5.25 6.281 5.25H6.2815Z"
                                      fill="#1890FF"/>
                                </svg>
                            </span>
                            </div>
                        </div>
                        <button onClick={handleContinue} className="btn btn_orange"><span>Hoàn Tất và Thanh Toán!</span></button>
                    </div>

                </div>
            </div>

        </section>

    );

}
const mapStateToProps = function (state) {
    return {
        isShowHotelInfo: state.hotelListReducer.isShowHotelInfo
    }
}

export default (withRouter(connect(mapStateToProps)(HomeReview)))
