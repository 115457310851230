import React from 'react';

function FinishPassengerInfo() {

    return (
        <div className="passengerInfo">
            <div className="title1">
                <h4>Thông tin hành khách</h4>
            </div>
            <ul className="passengerInfo__list">
                <li>
                    <p><span>Tên hành khách 1</span></p>
                    <p><span>Nguyen Van A</span></p>
                </li>
                <li>
                    <p><span>Tên hành khách 2</span></p>
                    <p><span>Nguyen Van B</span></p>
                </li>
                <li>
                    <p><span>Số điện thoại</span></p>
                    <p><span>0989556688</span></p>
                </li>
                <li>
                    <p><span>Địa chỉ email</span></p>
                    <p><span>nguyenvana@gmail.com</span></p>
                </li>
            </ul>
            <p className="size-14">Nếu bạn cần thêm thông tin hoặc trọ giúp, vui lòng gọi tổng đài 096-326-6688 để được hỗ trợ.</p>
        </div>
    );

}

export default FinishPassengerInfo;