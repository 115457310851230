import React, {useEffect, useRef, useState} from 'react';
import SearchPassengers from "../../component/home/SearchPassengers";

function SearchPassengersContainer({
  handleChoosePassengers,
  adultCount,
  childCount,
  infantCount
}) {
  const [openPassenger, setOpenPassenger] = useState(false);
  const totalPerson = adultCount + childCount + infantCount;
  const defaultPassenger = useRef(null);

  const handClickOpen = boolean => {
    setOpenPassenger(boolean);
  };

  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClickOutSidePasenger);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClickOutSidePasenger);
    };
  }, []);

  const handleClickOutSidePasenger = event => {
    if (
      defaultPassenger &&
      defaultPassenger &&
      defaultPassenger.current.contains(event.target)
    ) {
      // inside click
      return;
    }
    // outside click
    setOpenPassenger(false); // close dropdown
  };

  return (
    <SearchPassengers
      openPassenger={openPassenger}
      adultCount={adultCount}
      childCount={childCount}
      defaultPassenger={defaultPassenger}
      infantCount={infantCount}
      totalPerson={totalPerson}
      handClickOpen={handClickOpen}
      handleChoosePassengers={handleChoosePassengers}
    />
  );
}

export default SearchPassengersContainer;