import React from 'react'
import { Button } from 'antd'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { HOT_LINE } from '../../const/common-const'
import { useUserInfo } from './ProvideAuth'
import UserInfo from './UserInfo'
import HeaderMobile from './HeaderMobile'
import MenuMobile from './MenuMobile'
import { convertPartnerPhone } from '../../utils/common-func'
import { useLocation } from 'react-router'
function Header({ dataWebview }) {
    const { pathname } = useLocation()
    const user = useUserInfo()
    const openPopupSignIn = () => {
        window.location.href = `${process.env.REACT_APP_SSO_URL}/login?callbackUrl=${encodeURIComponent(
            window.location.href
        )}`
    }
    const openPopupSignUp = () => {
        window.location.href = `${process.env.REACT_APP_SSO_URL}/register?callbackUrl=${encodeURIComponent(
            window.location.href
        )}`
    }
    if (!dataWebview.hideHeader) {
        return (
            <>
                <header className={`header ${!dataWebview.hideHeader ? 'header__desktop' : ''}`}>
                    <div className="container">
                        <Link to="/" className="header__logo">
                            <img src={dataWebview.logoUrl} alt="" />
                        </Link>
                        <div className="header__right d-flex">
                            <a
                                href={`tel:${
                                    dataWebview.whitelabel && dataWebview.partnerPhone
                                        ? convertPartnerPhone(dataWebview.partnerPhone)
                                        : HOT_LINE
                                }`}
                                className="header__hotline"
                            >
                                <svg
                                    width={18}
                                    height={18}
                                    version="1.1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    x="0px"
                                    y="0px"
                                    viewBox="0 0 384 384"
                                    xmlSpace="preserve"
                                >
                                    <path d="M353.188,252.052c-23.51,0-46.594-3.677-68.469-10.906c-10.719-3.656-23.896-0.302-30.438,6.417l-43.177,32.594                  c-50.073-26.729-80.917-57.563-107.281-107.26l31.635-42.052c8.219-8.208,11.167-20.198,7.635-31.448                  c-7.26-21.99-10.948-45.063-10.948-68.583C132.146,13.823,118.323,0,101.333,0H30.813C13.823,0,0,13.823,0,30.813                  C0,225.563,158.438,384,353.188,384c16.99,0,30.813-13.823,30.813-30.813v-70.323C384,265.875,370.177,252.052,353.188,252.052z" />
                                </svg>
                                <span>
                                    {dataWebview.whitelabel && dataWebview.partnerPhone
                                        ? dataWebview.partnerPhone
                                        : HOT_LINE}
                                </span>
                            </a>
                            {!dataWebview.hideHeader && (
                                <>
                                    {!user && ['/'].includes(pathname) ? (
                                        <>
                                            <Button
                                                type="button"
                                                className="btn btn_outlineOrange btn_sm btn_login mr15 ml15"
                                                onClick={openPopupSignIn}
                                            >
                                                Đăng nhập
                                            </Button>
                                            <Button
                                                type="button"
                                                className="btn btn_orange btn_sm btn_login"
                                                onClick={openPopupSignUp}
                                            >
                                                <span>Đăng ký</span>
                                            </Button>
                                        </>
                                    ) : (
                                        <UserInfo />
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </header>
                {!dataWebview.hideHeader && (
                    <>
                        <HeaderMobile dataWebview={dataWebview} />
                        <MenuMobile />
                    </>
                )}
            </>
        )
    }
    return null
}

export default withRouter(
    connect(state => {
        return {
            dataWebview: state.homeReducer.dataWebview || {}
        }
    })(Header)
)
