import {HOME_TYPE} from "../../const/Home";

const homeReducer = (state = {}, action) => {
    switch (action.type) {
        case HOME_TYPE.SHOW_LOADING:
            return {...state, showLoading: action.showLoading};
        case HOME_TYPE.SET_LOADING_MESSAGE:
            return { ...state, loadingMessage: action.loadingMessage }
        case HOME_TYPE.SET_LOADING_MESSAGE_SLIDE:
            return { ...state, loadingMessageSlide: action.loadingMessageSlide }
        case HOME_TYPE.SHOW_LOADING_GIU_CHO:
            return { ...state, showLoadingComBo: action.showLoadingComBo }
        case HOME_TYPE.SET_VALUE_PROGRESS:
            return { ...state, valueProgress: action.valueProgress }
        case HOME_TYPE.SHOW_LOADING_COMPONENT:
            return {...state, showLoadingComponent: action.showLoadingComponent};
        case HOME_TYPE.LOGO_FOR_PARTNER:
            return {...state, logoPartner: action.logoPartner};
        case HOME_TYPE.HEADER_PARTNER:
            return {...state, hideHeader: action.hideHeader};
        case HOME_TYPE.DATA_WEBVIEW_CONFIG:
            return {...state, dataWebview: action.dataWebview};
        case HOME_TYPE.HOTEL_SELECTED:
            return {...state, selectedHotel: action.selectedHotel};
        default:
            return {...state}

    }
}
export default homeReducer;