import React from 'react';
import {Link} from "react-router-dom";
import FinishComboInfoContainer from "../../containers/finish/FinishComboInfoContainer";
import FinishComboFlightContainer from "../../containers/finish/FinishComboFlightContainer";
import FinishComboHotelContainer from "../../containers/finish/FinishComboHotelContainer";
import FinishPassengerInfoContainer from "../../containers/finish/FinishPassengerInfoContainer";

function Finish({ status,bookingRequestId }) {
    if (status === 'CS_SUCCESS') {
        return (
            <section className="step7">
                <div className="container-combo text-center">
                    <div className="modal-deal__done " style={{ marginTop: 30, marginBottom: 30 }}>
                        <h1>Bạn đã gửi yêu cầu đặt Combo thành công</h1>
                        <div className="checkoutStatus__code">
                            <div className="bookingCode">
                                <div className="bookingCode__title">Booking request</div>
                                <div className="bookingCode__code">{bookingRequestId}</div>
                            </div>
                        </div>
                        <img src="https://statics.vntrip.vn/checkout-success.png" alt=""/>
                        <p>Vntrip.vn đã nhận được yêu cầu cho chuyến đi của bạn. </p>
                        <p>Chúng tôi sẽ liên hệ lại để xác nhận thông tin trong thời gian sớm nhất!</p>
                        <div className="btnGroup w100"><Link to="/" className="btn btn_orange"><span>Về Trang Chủ</span></Link>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
    return (
        <section className="step7">
            <div className="container">
                <h2 className="text-center mb15">Đơn hàng THÀNH CÔNG!</h2>
                <FinishComboInfoContainer/>
                <FinishComboFlightContainer/>
                <FinishComboHotelContainer/>
                <FinishPassengerInfoContainer/>
                <div className="btnGroup w100"><Link to="/" className="btn btn_orange"><span>Về Trang Chủ</span></Link></div>
            </div>
        </section>

    );
}

export default Finish;
