
const commonReducer = (state = {}, action) => {
    switch (action.type) {
        case "SHOW_LOADING":
            return {...state, showLoading: action.showLoading};
        case 'TOGGLE_SIDE_BAR_MOBILE':
            return { ...state, isOpenSideBar: action.payload }
        default:
            return {...state}

    }
}
export default commonReducer;