import React from 'react'
import { withRouter } from 'react-router-dom'
import {HOT_LINE} from "../../const/common-const";

function CheckoutFail( ) {
    return (
        <section className="vnt-checkout text-center" style={{ paddingTop: 30, paddingBottom: 30 }}>
            <div className="checkoutStatus__cont container">
                <h1>Yêu cầu đặt Combo thất bại</h1>
                <img src="https://statics.vntrip.vn/checkout-success.png" alt=""/>
                <p>Rất tiếc! Yêu cầu thanh combo của Quý khách trên hệ thống Vntrip.vn đã thất bại.</p>
                <p>Mọi thắc mắc vui lòng liên hệ hotline: <strong>{HOT_LINE}</strong> để được hỗ trợ 24/7</p>
            </div>

        </section>

    )
}

export default withRouter((CheckoutFail))
