import React from 'react';
import moment from 'moment';
import "moment/min/locales.min";

function DepartureTime({ title, time, handleDepartureTime, type, listTimeDepartReturn, titleDate }) {
    let _listTimeDepartReturn = [...new Set(listTimeDepartReturn)];
    return (
        <div className={`listBtn ${type === 'return' ? 'listTimeReturn' : 'listTimeDeparture'} mb15`}>
            <p>{title} ({moment(titleDate).format('DD/MM/YYYY')})</p>

            <ul>
                {
                    _listTimeDepartReturn && _listTimeDepartReturn.length > 0 && _listTimeDepartReturn.map((res, index) => {
                        return (
                            <li className={time.flightTime === res.flightTime && time.token === res.token ? 'active' : ''}
                                key={index}  onClick={(e) => handleDepartureTime(e, type, res)}
                            >
                                <button type="button"><span>{res && moment(res.flightTime).format('HH:mm')}</span></button>
                            </li>
                        )
                    })
                }

            </ul>

        </div>
    );

}

export default DepartureTime;
