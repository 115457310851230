import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Cookies from "js-cookie";
import { withRouter } from "react-router-dom";
import { message } from "antd";
import moment from "moment";
import "moment/min/locales.min";
import PaymentMethod from "../../component/payment-method/PaymentMethod";
import {
  addOrder,
  applyCouponForBR,
  checkStatus,
  getDataDetail,
  getListCouponCode,
  getListPaymentMethod, updateBookingRequest,
  updatePaymentMethod
} from '../../api-services/payment-method-service'
import {
  saveValueProgress,
  setLoadingMessage,
  toggleShowLoading,
  toggleShowLoadingComBo
} from "../../redux/actions/home-actions";
import {
  setAlertMessage,
  setLinkPopup,
  setPaymentMethod,
  setRefreshPage
} from "../../redux/actions/payment-method-action";
import {
  handleResultAddOrder,
  updateGtmDataLayer
} from "../../utils/payment-func";
import { getSession, setSession } from "../../utils/home-util";
import giftbox from "../../assets/images/giftbox.png";
import { getSurchargeLevels } from "../../api-services/hotel-review";
import { PAYMENT_METHODS, USER_IDENTIFIER } from "../../const/checkout-const";
import { getIdentifiers, updateBookerData } from "../../api-services/user";
import { getPhoneNotMember, removePhoneNotMember } from "../../const/user";
import { useUserInfo } from "../../component/layout/ProvideAuth";
import * as queryString from 'query-string'

let valueProgress = 10;

function PaymentMethodContainer({
  location,
  dispatch,
  refreshPage,
  paymentMethod
}) {
  const userInfo = useUserInfo();
  const [bookingRequestId, setBookingRequestId] = useState("");
  const [dataBooking, setDataBooking] = useState({});
  const [expiredDate, setExpiredDate] = useState("");
  const [expiredHour, setExpiredHour] = useState("");
  const [expiredMinute, setExpiredMinute] = useState("");
  const [isShowText, setIsShowText] = useState(false);
  const [listPaymentMethod, setListPaymentMethod] = useState([]);
  const [couponInfo, setCouponInfo] = useState(null);
  const [couponCode, setCouponCode] = useState("");
  const [dataPaymentMethodForCoupon, setDataPaymentMethodForCoupon] = useState(
    ""
  );
  const [couponWebview, setCouponWebview] = useState("");
  const [validateCoupon, setValidateCoupon] = useState({
    status: "success",
    text: ""
  });
  const [priceInfantSurcharge, setPriceInfantSurcharge] = useState(0);
  const [isCoupon, setIsCoupon] = useState(false);
  const [isAffiliate, setIsAffiliate] = useState(false);
  const [priceCoupon, setPriceCoupon] = useState(false);
  const [showVerifyPhone, setShowVerifyPhone] = useState(false);
  const [presetPhone, setPresetPhone] = useState("");
  const [dataReviewCombo, setDataReviewCombo] = useState({
    from: "",
    to: "",
    departDate: "",
    nights: 0,
    days: 0,
    countAdult: 0,
    countChild: 0,
    countInfant: 0,
    upgradeDeparture: 0,
    upgradeReturn: 0,
    upgradeHotel: 0,
    unitPrice: 0,
    couponWebview: "",
    priceCoupon: 0,
    priceInfantSurcharge: 0,
    isCoupon: false,
    totalPrice: 0
  });
  const [dataPersonSurcharge, setDataPersonSurcharge] = useState({
    PRICE_DELUXE: 0,
    PRICE_STANDARD: 0,
    PRICE_LUXURY: 0,
    PRICE_INFANT_LUXURY: 0,
    PRICE_INFANT_STANDARD: 0,
    PRICE_INFANT_DELUXE: 0
  });
  const [typeHotel, setTypeHotel] = useState("STANDARD");
  // const [valueProgress, setValueProgress] = useState(10);
  const accessToken = Cookies.get("accessToken");

  useEffect(() => {
    const pathname = location.pathname;
    const token = pathname
      .split("/")
      .slice(-1)
      .pop();
    setBookingRequestId(token);
    dispatch(toggleShowLoadingComBo(true));
    dispatch(saveValueProgress(valueProgress));
    checkStatusOrder(token);
  }, [location, refreshPage, dispatch]);

  useEffect(() => {
    const pathname = location.pathname;
    const token = pathname
      .split("/")
      .slice(-1)
      .pop();
    if (Object.keys(dataBooking).length > 0) fetchPaymentMethod(token);
  }, [location, refreshPage, dataBooking]);

  useEffect(() => {
    if (isAffiliate) {
      handleContinue(true);
    }
  }, [isAffiliate]);

  /* xử lý coupon */
  useEffect(() => {
    if (couponInfo && listPaymentMethod) {
      let checkPayment = listPaymentMethod.every(
        element =>
          couponInfo.condition_bank?.indexOf(element.payment_method) > -1
      );
      if (checkPayment) {
        if (couponInfo.status === "SUCCESS") {
          setValidateCoupon({ status: "success", text: "" });
        }
        // coupon ko chap nhan redeem & co diem redeem
        // if (
        //     !data?.bonus_cashback?.allowRedeem &&
        //     data?.loyalty_redeem_info &&
        //     data?.loyalty_redeem_info.additional_settings.actual_redeem_point &&
        //     data?.loyalty_redeem_info.status == 'DRAFT'
        // ) {
        //     setValidateCoupon({
        //         status: 'warning',
        //         text: t(`payment:Mã giảm giá không áp dụng đồng thời với tiêu tiền Cashback cho đơn hàng`),
        //     })
        // }
        if (couponInfo.status === "TEMPORARY") {
          setValidateCoupon({
            status: "warning",
            text: couponInfo?.temporary_reason?.message
          });
        }
      } else {
        let condition_bank_i18n = [];

        for (let item of couponInfo?.condition_bank) {
          condition_bank_i18n.push(`${item}`);
        }
        if (
          couponInfo?.temporary_reason &&
          couponInfo?.temporary_reason?.error_code
        ) {
          if (paymentMethod) {
            setIsCoupon(false);
            setValidateCoupon({
              status: "warning",
              text: `Mã coupon không áp dụng cho hình thức thanh toán này. Bạn vui lòng kiểm tra lại chính sách của chương trình.`
            });
          } else {
            setValidateCoupon({
              status: "warning",
              text: `Thanh toán bằng ${condition_bank_i18n} để hưởng mã giảm giá này`
            });
          }
        } else {
          if (couponInfo?.status === "SUCCESS") {
            setValidateCoupon({ status: "success", text: "" });
          }
        }
        let dataValidation = dataBooking?.validations?.coupon_info;
        if (
          dataValidation &&
          dataValidation?.temporary_reason?.error_code == "E4012"
        ) {
          setIsCoupon(false);

          setValidateCoupon({
            status: "error",
            text: `Mã giảm giá không được áp dụng chung với số điểm cashback đã tiêu`
          });
        }
        if (
          dataValidation &&
          dataValidation?.temporary_reason?.error_code == "E1423"
        ) {
          setIsCoupon(false);
          setValidateCoupon({
            status: "error",
            text: `payment:Email không hợp lệ`
          });
        }
      }
    }
  }, [couponInfo, paymentMethod, dataBooking, listPaymentMethod]);

  useEffect(() => {
    const pathname = location.pathname;
    const _bookingRequestId = pathname
      .split("/")
      .slice(-1)
      .pop();
    if (_bookingRequestId) {
      const gtmDataLayer = {
        Content: [
          {
            id: _bookingRequestId
          }
        ]
      };
      updateGtmDataLayer(gtmDataLayer);
    } else {
      updateGtmDataLayer();
    }
  }, []);

  const checkStatusOrder = async bookingRequestId => {
    const query = queryString.parse(location.search);

    const data = await checkStatus({
      booking_request_id: bookingRequestId
    });
    if (data && data.status === "success") {
      if (valueProgress > 10 && valueProgress < 85) {
        document.getElementsByClassName("loadingBox__step1")[0] &&
          document
            .getElementsByClassName("loadingBox__step1")[0]
            .classList.add("hidden");
        document.getElementsByClassName("loadingBox__step2")[0] &&
          document
            .getElementsByClassName("loadingBox__step2")[0]
            .classList.remove("hidden");
        document.getElementsByClassName(
          "loadingBox__text .p2:first-child"
        )[0] &&
          document
            .getElementsByClassName("loadingBox__text .p2:first-child")[0]
            .classList.remove("animation1");
        document.getElementsByClassName("loadingBox__text .p2:last-child")[0] &&
          document
            .getElementsByClassName("loadingBox__text .p2:last-child")[0]
            .classList.remove("animation2");

        if (valueProgress > 50) {
          document.getElementsByClassName("loadingBox__group1")[0] &&
            document
              .getElementsByClassName("loadingBox__group1")[0]
              .classList.add("hidden");
          document.getElementsByClassName("loadingBox__group2")[0] &&
            document
              .getElementsByClassName("loadingBox__group2")[0]
              .classList.remove("hidden");
        }
      }
      if (data.data && data.data.status === "PROCESSING") {
        valueProgress += 5;
        dispatch(saveValueProgress(valueProgress));
        if (valueProgress <= 15) {
          setTimeout(async () => {
            await checkStatusOrder(bookingRequestId);
          }, 3000);
        } else {
          setTimeout(async () => {
            await checkStatusOrder(bookingRequestId);
          }, 5000);
        }
      } else if (data.data && data.data.status === "FAILED") {
        dispatch(toggleShowLoadingComBo(false));
        dispatch(
          setAlertMessage("Giữ chỗ không thành công, vui lòng thử lại! ")
        );
        if (query?.token){
          dispatch(setLinkPopup("/check-out/step1/" + query.token));
        } else {
          dispatch(setLinkPopup("/"));
        }
      } else if (data.data && data.data.status === "CANCELLED") {
        dispatch(toggleShowLoadingComBo(false));
        dispatch(
          setAlertMessage(
            " Hết hạn thanh toán, vui lòng đặt lại đơn hàng khác! "
          )
        );
      } else if (data.data && data.data.status === "BOOKED") {
        valueProgress = 100;
        dispatch(saveValueProgress(100));
        dispatch(toggleShowLoadingComBo(false));
        setIsShowText(true);
        const data = await getDataDetail(bookingRequestId);
        if (data?.status === "success" && data?.data?.combo) {
          let dataCombo = data?.data?.combo;
          setDataBooking(data.data);
          dispatch(setPaymentMethod(data.data.payment_method || ""));
          setExpiredDate(
            moment(data.data.combo.hold_expired_at).format("DD/MM/YYYY")
          );
          setExpiredHour(moment(data.data.combo.hold_expired_at).format("HH"));
          setExpiredMinute(
            moment(data.data.combo.hold_expired_at).format("mm")
          );
          // set data review
          let _dataReviewCombo = { ...dataReviewCombo };
          _dataReviewCombo.countAdult =
            dataCombo?.customer_request?.count_adult;
          _dataReviewCombo.countChild =
            dataCombo?.customer_request?.count_child;
          _dataReviewCombo.countInfant =
            dataCombo?.customer_request?.count_infant;
          _dataReviewCombo.from = dataCombo?.customer_request?.from;
          _dataReviewCombo.to = dataCombo?.customer_request?.to;
          _dataReviewCombo.nights = dataCombo?.deal_data?.nights;
          _dataReviewCombo.days = dataCombo?.deal_data?.days;
          _dataReviewCombo.departDate =
            data?.data?.flight?.[0]?.leg === 0
              ? data?.data?.flight?.[0]?.departure_date
              : data?.data?.flight?.[1]?.departure_date;
          _dataReviewCombo.upgradeDeparture =
            dataCombo?.upgrade_data?.upgrade_flight?.upgrade_departure;
          _dataReviewCombo.upgradeReturn =
            dataCombo?.upgrade_data?.upgrade_flight?.upgrade_return;
          _dataReviewCombo.upgradeReturn =
            dataCombo?.upgrade_data?.upgrade_flight?.upgrade_return;
          _dataReviewCombo.unitPrice = dataCombo?.unit_price;
          _dataReviewCombo.totalPrice = data?.data?.final_price;
          // _dataReviewCombo.upgradeHotel = dataCombo?.upgrade_data?.hotel?.unit_price
          setTypeHotel(dataCombo?.upgrade_data?.hotel?.to || "STANDARD");
          if (data && data.data && data.data.coupon_info) {
            _dataReviewCombo.couponWebview =
              data?.data?.coupon_info?.coupon_code;
            _dataReviewCombo.priceCoupon = data?.data?.coupon_price;
            setCouponWebview(data?.data?.coupon_info?.coupon_code);
            setValidateCoupon({
              status: "success",
              text: "Áp dụng thành công"
            });
            setIsCoupon(true);
            setPriceCoupon(data.data?.coupon_price);
          }

          setCouponInfo(data?.data?.coupon_info);
          setDataReviewCombo(_dataReviewCombo);

          if (data && data.data) {
            getSurchargeLevel(data.data);
          }
        }
      } else {
        dispatch(toggleShowLoadingComBo(false));
      }
    } else {
      dispatch(setAlertMessage(data.message));
      dispatch(setLinkPopup(false));
    }
  };

  useEffect(() => {
    if (priceInfantSurcharge) {
      let _dataReviewCombo = { ...dataReviewCombo };
      _dataReviewCombo.priceInfantSurcharge = setDataReviewCombo(
        _dataReviewCombo
      );
    }
  }, [priceInfantSurcharge]);

  const getSurchargeLevel = async dataBooking => {
    const dataCombo = dataBooking ? dataBooking.combo : {};
    const dataFlight = dataBooking ? dataBooking.flight : {};
    const _dataPersonSurcharge = { ...dataPersonSurcharge };
    const data = await getSurchargeLevels(dataCombo.deal_id);
    if (data && data.status === "success") {
      Array.isArray(data?.data) &&
        data.data.map(res => {
          if (
            dataFlight?.[0]?.adult_count % 2 === 1 &&
            dataFlight?.[0]?.child_count === 0
          ) {
            _dataPersonSurcharge[`PRICE_${res.code}`] =
              res.odd_person_surcharge;
          }
          if (dataFlight?.[0]?.infant_count > 0) {
            _dataPersonSurcharge[`PRICE_INFANT_${res.code}`] =
              res?.infant_surcharge;
          }
        });
      setDataPersonSurcharge(_dataPersonSurcharge);
    }
  };

  const fetchPaymentMethod = async bookingRequestId => {
    try {
      const params = {
        request_from: "WEBVIEW"
      };
      let webviewSessionInfo = JSON.parse(
        localStorage.getItem("webviewSessionInfo")
      );
      if (webviewSessionInfo) {
        const userSession = webviewSessionInfo.user_session;
        if (userSession) params["user_session"] = userSession;
      }
      const data = await getListPaymentMethod(params);
      if (data && data.status === "success") {
        if (webviewSessionInfo) {
          if (data && data.data.length === 1) {
            dispatch(setPaymentMethod(data.data[0].payment_method));
            const dataUpdatePayment = {
              booking_request_id: bookingRequestId,
              payment_method: data.data[0].payment_method,
              user_type: "person"
            };
            const check = await updatePaymentMethod(
              dataUpdatePayment,
              bookingRequestId
            );
            if (check && check.status === "success") {
              setIsAffiliate(true);
            }
          }
        }
        setListPaymentMethod(data.data);
        const _listPaymentMethod = [...data.data];
        const getListCoupon = async () => {
          const dataCoupon = await getListCouponCode();
          if (dataCoupon && dataCoupon.status === "success") {
            const listPaymentFromCoupon = [];
            if (dataCoupon.data.length > 0) {
              dataCoupon.data.forEach(res => {
                res.condition_bank.forEach((payment, index) => {
                  _listPaymentMethod.push({
                    id: index + 1,
                    payment_method: payment + "_coupon",
                    coupons: res.coupons,
                    name_vi: res.name,
                    short_desc_vi: res.content,
                    logo: giftbox
                  });
                });
              });
            }
            setDataPaymentMethodForCoupon(listPaymentFromCoupon);
          }
          setListPaymentMethod(_listPaymentMethod);
        };
        const uudai = getSession("uudailotte");
        if (uudai) {
          getListCoupon();
        }
      }
    } catch (e) {
      throw e;
    }
  };

  const handleChangePaymentMethod = async (paymentMethod, coupon) => {
    try {
      if (coupon) {
        setCouponCode(coupon);
        localStorage.setItem(
          "paymentMethodCoupon",
          JSON.stringify({ code: coupon, bookingRequestId })
        );
        paymentMethod = paymentMethod.substr(0, paymentMethod.length - 7);
      } else {
        setCouponCode("");
        window.localStorage.removeItem("paymentMethodCoupon");
      }
      const data = {
        booking_request_id: bookingRequestId,
        payment_method: paymentMethod,
        user_type: "person"
      };
      dispatch(toggleShowLoading(true));
      await updatePaymentMethod(data, bookingRequestId);
      dispatch(setPaymentMethod(paymentMethod));
      if (couponWebview) {
        await handleCoupon();
      }
      dispatch(toggleShowLoading(false));
    } catch (e) {
      throw e;
    }
  };

  const openPopupSignIn = () => {
    window.location.href = `${
      process.env.REACT_APP_SSO_URL
    }/login?callbackUrl=${encodeURIComponent(window.location.href)}`;
  };


  const handleContinue = async check_session => {
    try {
      if (!paymentMethod && check_session !== true) {
        message.error("Vui lòng chọn 1 hình thức thanh toán");
      } else {
        if (dataBooking) {
          let data = {
            redirect_url: window.location.origin + "/thank-you"
          };
          if (couponCode) {
            data.comboCouponCode = couponCode;
          }
          if (paymentMethod === PAYMENT_METHODS.PAYMENT_METHOD_PAY_LATER_LFVN) {
            if (!accessToken) {
              openPopupSignIn();
            } else {
              let booker = {};
              if (userInfo?.email) {
                booker.email = userInfo?.email
              }
              booker.userId = userInfo?.user_id

              await updateBookerData({
                bookingRequest: bookingRequestId,
                ...booker
              });
              const phoneIdentifier = await getPhoneIdentifier();
              if (!phoneIdentifier) {
                if (!getPhoneNotMember()) {
                  setShowVerifyPhone(true);
                  return;
                }
              } else if (
                phoneIdentifier.status !== USER_IDENTIFIER.STATUS.VERIFIED
              ) {
                setShowVerifyPhone(true);
                setPresetPhone(phoneIdentifier.identifier_value);
                return;
              }
            }

            // if (!accessToken) {
            //   if (!getPhoneNotMember()) {
            //     setShowVerifyPhone(true);
            //     return;
            //   }
            //   await updateBookerData({
            //     bookingRequest: bookingRequestId,
            //     phone: getPhoneNotMember()
            //   });
            // }
          }
          const gtmDataLayer = {
            PageType: "Checkout2",
            ProductBasketProducts: [
              {
                id: dataBooking.combo.id,
                price: dataBooking.prices.total_price,
                quantity: dataBooking.combo.quantity
              }
            ],
            ProductID: dataBooking.combo.id,
            ProductName: dataBooking.combo.deal_data.name,
            ProductType: "ComboCheckOut",
            CheckInDate: dataBooking.combo.departure_date,
            CheckOutDate: moment(dataBooking.combo.departure_date)
              .add(dataBooking.combo.deal_data.days, "days")
              .format("YYYY-MM-DD"),
            Content: [
              {
                id: dataBooking.combo.id,
                quantity: dataBooking.combo.quantity
              }
            ],
            dynx_itemid: dataBooking.combo.id,
            dynx_pagetype: "conversionintent",
            dynx_totalvalue: dataBooking.final_price,
            finalPrice: dataBooking.final_price,
            couponCode: dataBooking.coupon_code ? dataBooking.coupon_code : null
          };
          if (dataBooking && dataBooking.order_code) {
            data.update_order = true;
          }
          dispatch(toggleShowLoading(true));
          dispatch(setLoadingMessage("Đang xử lý đơn hàng của bạn..."));
          const results = await addOrder(bookingRequestId, data);
          // window.localStorage.removeItem('paymentMethodCoupon')
          dispatch(toggleShowLoading(false));
          removePhoneNotMember();
          if (results.status === "success") {
            handleResultAddOrder({
              data: results.data,
              booking_request_id: bookingRequestId,
              orderCode: dataBooking.order_code,
              gtmDataLayer: gtmDataLayer,
              paymentMethod: paymentMethod,
              dataCheckout: dataBooking,
              check_session: check_session
            });
          } else if (results.status === "error") {
            if (results.error_code === "OH334") {
              dispatch(
                setAlertMessage(
                  "Đã hết hạn giữ chỗ, Vui lòng chọn lại hành trình"
                )
              );
              dispatch(setLinkPopup("/"));
            } else {
              message.error(results.message);
            }
            // showMessage('error', results.message + `${results.error_code ? ` (${results.error_code})` : ''}`)
          }
          dispatch(setRefreshPage(new Date().getTime())); // load booking request again, to get orderCode
        }
      }
    } catch (e) {
      dispatch(toggleShowLoading(false));
      removePhoneNotMember();
      throw e;
    }
  };

  const getPhoneIdentifier = async () => {
    if (accessToken) {
      const res = await getIdentifiers();
      if (res.status === "success") {
        const data = res.data;
        return data.find(
          item => item.identifier_source === USER_IDENTIFIER.SOURCE.PHONE
        );
      }
    } else {
      return null;
    }
  };

  const handleCoupon = async () => {
    try {
      if (couponWebview && bookingRequestId) {
        setValidateCoupon({ status: "status", text: "" });
        dispatch(toggleShowLoading(true));
        const data = await applyCouponForBR(bookingRequestId, {
          coupon_code: couponWebview
        });
        dispatch(toggleShowLoading(false));
        if (data && data.status === "success") {
          // setIsCoupon(true)
          let _dataReviewCombo = { ...dataReviewCombo };
          _dataReviewCombo.isCoupon = true;
          setDataReviewCombo(_dataReviewCombo);
          setCouponInfo(data?.data);
          dispatch(setRefreshPage(new Date().getTime())); // load booking request again, to get orderCode
          setValidateCoupon({ status: "success", text: "Áp dụng thành công" });
        } else {
          if (data.error_code === "E1312") {
            setValidateCoupon({
              status: "error",
              text: "Mã coupon không  đúng"
            });
          } else if (data?.error_code === "E1309") {
            setValidateCoupon({ status: "error", text: "Email không hợp lệ" });
          } else {
            setValidateCoupon({ status: "error", text: data.message });
          }
        }
      } else {
        setValidateCoupon({ status: "error", text: "Vui lòng nhập mã coupon" });
      }
    } catch (e) {
      throw e;
    }
  };

  const handleCancelCoupon = async () => {
    dispatch(toggleShowLoading(true));
    await applyCouponForBR(bookingRequestId, { coupon_code: "" });
    dispatch(setRefreshPage(new Date().getTime())); // load booking request again, to get orderCode
    setValidateCoupon({ status: "success", text: "" });
    setIsCoupon(false);
    let _dataReviewCombo = { ...dataReviewCombo };
    _dataReviewCombo.isCoupon = false;
    setDataReviewCombo(_dataReviewCombo);
    setCouponWebview("");
    dispatch(toggleShowLoading(false));
  };

  return (
    <PaymentMethod
      listPaymentMethod={listPaymentMethod}
      expiredDate={expiredDate}
      expiredHour={expiredHour}
      expiredMinute={expiredMinute}
      isShowText={isShowText}
      couponCode={couponCode}
      dataBooking={dataBooking}
      paymentMethod={paymentMethod}
      dataPaymentMethodForCoupon={dataPaymentMethodForCoupon}
      bookingRequestId={bookingRequestId}
      validateCoupon={validateCoupon}
      couponWebview={couponWebview}
      priceCoupon={priceCoupon}
      setCouponWebview={setCouponWebview}
      priceInfantSurcharge={priceInfantSurcharge}
      isCoupon={isCoupon}
      showVerifyPhone={showVerifyPhone}
      dataReviewCombo={dataReviewCombo}
      dataPersonSurcharge={dataPersonSurcharge}
      typeHotel={typeHotel}
      // function
      setShowVerifyPhone={setShowVerifyPhone}
      presetPhone={presetPhone}
      handleContinue={handleContinue}
      handleChangePaymentMethod={handleChangePaymentMethod}
      handleCoupon={handleCoupon}
      handleCancelCoupon={handleCancelCoupon}
    />
  );
}

export default withRouter(
  connect(state => {
    return {
      showLoading: state.homeReducer.showLoading,
      refreshPage: state.paymentMethodReducer.refreshPage,
      paymentMethod: state.paymentMethodReducer.paymentMethod
    };
  })(PaymentMethodContainer)
);
