import React from 'react';
import Modal from "react-bootstrap/Modal";
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {setAlertMessage, setTextAlertButton} from '../../redux/actions/payment-method-action'

function PopupNotification({ dispatch, alertMessage, location, linkRoute, history, textButton }) {
    const [isShow, setIsShow] = React.useState(false)
    React.useEffect(() => {
        setIsShow(!!alertMessage)
    }, [alertMessage])

    React.useEffect(() => {
        dispatch(setAlertMessage(null))
    }, [location])

    const handleClose = () => {
        if (linkRoute) {
            history.push(`${linkRoute}`)
        }
        dispatch(setAlertMessage(false))
        dispatch(setTextAlertButton(false))
        // setIsShow(false);
    }
    return (
        <Modal
            show={isShow}
            dialogClassName="modal_lg modal-noti"
            onHide={handleClose}
        >
            <Modal.Header>
                <button data-dismiss="modal" className="modal-close" onClick={handleClose}>
                    <svg width={38} height={38} viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19 36.5C28.665 36.5 36.5 28.665 36.5 19C36.5 9.33502 28.665 1.5 19 1.5C9.33502 1.5 1.5 9.33502 1.5 19C1.5 28.665 9.33502 36.5 19 36.5Z" fill="#F8F8F8" stroke="white" strokeWidth={3}/>
                        <path d="M14.4434 14.4434L22.9102 22.9102" stroke="#878787" strokeWidth={2} strokeLinecap="round"/>
                        <path d="M23.1464 14.4434L14.6797 22.9102" stroke="#878787" strokeWidth={2} strokeLinecap="round"/>
                    </svg>
                </button>
            </Modal.Header>
            <Modal.Body>
                <p className="semibold">{alertMessage}</p>
            </Modal.Body>
            <Modal.Footer>

                <button
                    data-dismiss="modal"
                    type="button"
                    className=" btn_round"
                    onClick={handleClose}
                >{ textButton ? textButton : 'Ok'}
                </button>
            </Modal.Footer>
        </Modal>
    );

}

export default withRouter(connect(state => {
    return {
        alertMessage: state.paymentMethodReducer.alertMessage || '',
        linkRoute: state.paymentMethodReducer.linkRoute || false,
        textButton: state.paymentMethodReducer.textButton || false,
    }
})(PopupNotification))
