import React, {useEffect, useState} from 'react';
import Home from "../../component/home/Home";
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {getListComBo} from "../../api-services/search-combo";
import {updateGtmDataLayer} from "../../utils/payment-func";
import {setSession} from "../../utils/home-util";

function HomeContainer({location}) {
    // const [dataListCombo,setDataListCombo] = useState([]);
    useEffect(()=>{
        if(location.pathname === '/uudailotte') {
            setSession('uudailotte', {code:'uudailotte'}, 60)
        }
        updateGtmDataLayer()
    },[]);

    // const fetchListCombo  =async () =>{
    //     try {
    //         const data = await getListComBo();
    //         if(data && data.status === 'success'){
    //             setDataListCombo(data.data)
    //         }
    //     }catch (e) {
    //         throw e
    //     }
    // };
    const priceForOnePeople = (item) => {
        const adultNumber = item.flights[0].adult_number;
        const sellPrice = item.price_deal[0].sell_price;
        return Number(sellPrice / adultNumber ).toFixed(0)
    };
    return (
        <div>
            <Home
                priceForOnePeople={priceForOnePeople}
            />
        </div>
    );

}
const mapStateToProps = function(state) {
    return {
        isShowLoading: state.homeReducer.showLoading,
    }
};

export default withRouter(connect(mapStateToProps)(HomeContainer));
