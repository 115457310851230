import React from 'react'
import { Collapse } from 'antd'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { HOT_LINE } from '../../const/common-const'

const { Panel } = Collapse

function HomeQuestion({ dataWebview }) {
    return (
        <div className="home__item question__combo">
            <div className="container">
                <div className="title">
                    <h2> Các câu hỏi thường gặp</h2>
                </div>
                <div className="question__combo__content">
                    <Collapse expandIconPosition="right">
                        <Panel
                            header="Tôi có được lựa chọn điểm đến,  ngày - giờ bay và khách sạn theo ý muốn không?"
                            key="1"
                        >
                            <p>
                                Khách hàng sẽ được chủ động lựa chọn điểm đến, ngày - giờ bay và khách sạn theo danh
                                sách {dataWebview?.partnerName || 'VNTRIP'} cung cấp ban đầu.
                            </p>
                        </Panel>
                    </Collapse>
                    <Collapse expandIconPosition="right">
                        <Panel header="Vé máy bay trong combo là của hãng nào?" key="1">
                            <p>
                                Vé máy bay trong combo sẽ được lựa chọn trong các hãng Vietnam Airlines, VietJet Air,
                                Bamboo Airways tùy thuộc vào chặng bay, ngày, giờ bay của khách hàng
                            </p>
                        </Panel>
                    </Collapse>
                    <Collapse expandIconPosition="right">
                        <Panel
                            header="Khách sạn trong combo là khách sạn mấy sao? Tôi có được nâng cấp khách sạn không?"
                            key="1"
                        >
                            <p>Khách sạn áp dụng trong combo thường là khách sạn 3 sao.</p>
                            <p>
                                Khách hàng được nâng cấp khách sạn 4 - 5 sao tùy theo nhu cầu và thanh toán thêm phụ phí
                                tương ứng
                            </p>
                        </Panel>
                    </Collapse>
                    <Collapse expandIconPosition="right">
                        <Panel header="Combo trên bao gồm những gì? Đã bao gồm phụ phí hay chưa?" key="1">
                            <p>
                                Combo bao gồm vé máy bay khứ hồi và 3 ngày 2 đêm lưu trú tại khách sạn. Không bao gồm
                                chi phí ăn uống, di chuyển, tham quan.
                            </p>
                            <p>Giá của combo là giá cuối, đã bao gồm thuế, phí.</p>
                        </Panel>
                    </Collapse>

                    <Collapse expandIconPosition="right">
                        <Panel header="Combo dành cho bao nhiêu người? Nếu đi 01 người thì tôi cần làm gì?" key="1">
                            <p>Combo áp dụng từ 02 người trở lên.</p>
                            <p>
                                Nếu đặt combo dành cho 01 người, khách hàng liên hệ hotline{' '}
                                {dataWebview.whitelabel && dataWebview.partnerPhone
                                    ? dataWebview.partnerPhone
                                    : HOT_LINE}{' '}
                                để được hỗ trợ
                            </p>
                        </Panel>
                    </Collapse>
                </div>
            </div>
        </div>
    )
}

export default withRouter(
    connect(state => {
        return {
            dataWebview: state.homeReducer.dataWebview || {}
        }
    })(HomeQuestion)
)
