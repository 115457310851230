import React, {useState, useEffect, createContext, useContext} from 'react';
import Cookies from "js-cookie";
import {getUserProfile} from "../../api-services/home-service";
import {clearUserSession} from "../../utils/common-func";
const authContext = createContext(0)

export const useAuth = () => {
    return useContext(authContext)
}

export const useUserInfo = () => {
    const auth = useAuth()
    return auth.user
}

const ProvideAuth = (props) => {
    const [user, setUser] = useState(null)
    const [isAuthenticated, setIsAuthenticated] = useState(false)
    useEffect(()=>{
        const accessToken = Cookies.get('accessToken')
        const fetchUserProfile = async () => {
            try {
                const userInfo = await getUserProfile()
                if(userInfo?.status === 'success') {
                    login(userInfo.data)
                } else {
                    clearUserSession()
                    logout()
                }
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    clearUserSession()
                    logout()
                }
                throw error
            }
        }
        if(accessToken) {
            fetchUserProfile()
        }
    }, [])

    const login = (user) => {
        setIsAuthenticated(true)
        setUser(user)
    }

    const logout = () => {
        setIsAuthenticated(false)
        setUser(null)
    }

    return (
        <authContext.Provider value={{ user, isAuthenticated, login, logout }}>
            {props.children}
        </authContext.Provider>
    )

}

export default ProvideAuth;