import {combineReducers} from 'redux';
import homeReducer from './reducers/home-reducer'
import hotelListReducer from "./reducers/hotel-list";
import comboSearchReducer from "./reducers/search-combo";
import commonReducer from "./reducers/common-reducer";
import paymentMethodReducer from './reducers/payment-method-reducer'
const appReducers=combineReducers({
    homeReducer:homeReducer,
    hotelListReducer: hotelListReducer,
    comboSearchReducer: comboSearchReducer,
    commonReducer: commonReducer,
    paymentMethodReducer: paymentMethodReducer

});
export default appReducers;
