import React from 'react';
import FinishComboInfo from "../../component/finish/FinishComboInfo";

function FinishComboInfoContainer() {

    return (
       <FinishComboInfo/>
    );

}

export default FinishComboInfoContainer;