import React from 'react'
import DayPicker from 'react-day-picker'
import moment from 'moment'
import 'moment/min/locales.min'
import 'react-day-picker/lib/style.css'
import 'moment/min/locales.min'
import { isMobileScreen } from '../../utils/hotel-func'
import DepartureTimeContainer from '../../containers/select-flight/DepartureTimeContainer'
import PopupNotification from '../common/PopupNotification'

function SelectFlight({
    handleChangeDepartureDate,
    departureDate,
    listTimeReturn,
    listTimeDepart,
    handleContinue,
    handleDepartureTime,
    showLoadingComponent,
    Navbar,
    dataTimeDisable,
    timeReturn,
    timeDeparture,
    returnDate,
    departDate,
    textMonth,
    dataListDate,
    getNamePoint,
    endPoint,
    startPoint,
    nights,
    days,
    listDate
}) {
    const modifiers = {
        1999: listDate.active,
        2499: listDate.active2499,
        2999: listDate.active2999
    }
    const modifiersStyles = {
        1999: {
            background: '#D9F7BE'
        },
        2499: {
            background: '#FFE7BA'
        },
        2999: {
            background: '#FFCCC7'
        }
    }
    return (
        <section className="step3">
            <div className="container-combo">
                <h2 className="text-center m0">Chọn ngày khởi hành</h2>
                <p className="text-center mb15 size-16 info-flight">
                    Chặng: {getNamePoint(startPoint)} - {getNamePoint(endPoint)}, {days} ngày {nights} đêm
                </p>
                {dataListDate?.length === 0 ? (
                    <p className="text-center mb15 size-14" style={{ color: '#Ee0033' }}>
                        Không có ngày bay phù hợp cho chuyến bay {getNamePoint(startPoint)} - {getNamePoint(endPoint)}{' '}
                    </p>
                ) : (
                    <p className="text-center mb15 size-14">
                        Đây là những ngày khởi hành gần nhất còn chỗ.
                        <br />
                        Bạn có thể thay đổi tháng để tìm ngày phù hợp.
                    </p>
                )}

                <div className="note">
                    <div className="note__item">
                        <div className="icon"></div>
                        <div className="text"> {nights === 2 ? 'Combo 1.999k' : 'Combo 2.199k'}</div>
                    </div>
                    <div className="note__item">
                        <div className="icon orange"></div>
                        <div className="text">{nights === 2 ? 'Combo 2.499k' : 'Combo 2.699k'}</div>
                    </div>
                    <div className="note__item">
                        <div className="icon pink"></div>
                        <div className="text">{nights === 2 ? 'Combo 2.999k' : 'Combo 3.199k'}</div>
                    </div>
                </div>

                <div className="calendar mb15">
                    <DayPicker
                        numberOfMonths={isMobileScreen() ? 1 : 2}
                        selectedDays={departureDate}
                        modifiers={modifiers}
                        modifiersStyles={modifiersStyles}
                        disabledDays={dataTimeDisable}
                        fromMonth={textMonth}
                        toMonth={moment(dataListDate[dataListDate.length - 1])
                            .add(1, 'months')
                            .toDate()}
                        month={textMonth}
                        locale="vi"
                        navbarElement={<Navbar />}
                        onDayClick={handleChangeDepartureDate}
                    />
                </div>
                {showLoadingComponent && (
                    <DepartureTimeContainer
                        type="departure"
                        title={'Chọn giờ khởi hành'}
                        titleDate={departDate}
                        time={timeDeparture}
                        listTimeDepartReturn={listTimeDepart}
                        handleDepartureTime={handleDepartureTime}
                    />
                )}

                {showLoadingComponent && (
                    <DepartureTimeContainer
                        type="return"
                        title={'Chọn giờ về'}
                        time={timeReturn}
                        titleDate={returnDate}
                        listTimeDepartReturn={listTimeReturn}
                        handleDepartureTime={handleDepartureTime}
                    />
                )}

                <div className="btnGroup">
                    <button
                        onClick={handleContinue}
                        className={`btn btn_orange ${
                            Object.keys(timeDeparture).length === 0 || Object.keys(timeReturn).length === 0
                                ? 'btn_disable'
                                : ''
                        }`}
                    >
                        <span>Tiếp Tục</span>
                    </button>
                </div>
                <p className="size-14 mb15">
                    Lưu ý: Vì lý do bảo mật giá, nên chúng tôi sẽ hiển thị thông tin hãng hàng không và chuyến bay sau
                    khi đơn hàng được xác nhận thành công.
                </p>
                <PopupNotification />
            </div>
        </section>
    )
}

export default SelectFlight
