export const savePhoneNotMember = (data) => {
    return window.sessionStorage.setItem('phone_not_member', data)
}

export const removePhoneNotMember = () => {
    return window.sessionStorage.removeItem('phone_not_member')
}

export const getPhoneNotMember = () => {
    const data = window.sessionStorage.getItem('phone_not_member')
    return data ? data : ''
}