import ApiMicroService from "./api-request-micro";

export const apiTest = async () => {
    try {
        const results = await ApiMicroService.post('/order-hotel/order/booking-request-fe/');
        return results.data
    } catch (e) {
        throw e
    }
};

export const getListDate = async (data) => {
    try {
        const results = await ApiMicroService.post('/flight-service/v1/combo-search-month-v2',data);
        return  results.data

    }catch (e) {
        throw e
    }
};

export const getListDepartureDate = async (data) => {
    try {
        const results = await ApiMicroService.post('/flight-service/v1/combo-search',data);
        return  results.data

    }catch (e) {
        throw e
    }
};

export const createFlightRate = async (data)=>{
    try {
        const results = await ApiMicroService.post('/flight-service/v1/booking-request/suggestion/flight-rates/combo-fe',data);
        return  results.data

    }catch (e) {

        throw e
    }
};


export const getFlightPolicy = async (airline, fareClass, groupClass) => {
    try {
        const results = await ApiMicroService.get(`/v1-flight/flight/policy?airline=${airline}&fare_class=${fareClass}&group_class=${groupClass}`)
        return results.data
    } catch (e) {
        throw e
    }
}

export const getDealFlights = async (dealId) => {
    try {
        const results = await ApiMicroService.get(`/flight-service/v1/deals/${dealId}/deal-flights/`)
        return results.data
    } catch (e) {
        throw e
    }
}

