import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {message} from 'antd'
import * as queryString from "query-string";
import {getNamePointFromCode, PASSENGER_CONST} from "../../const/search-combo";
import {getBookerData, isEmptyObject} from "../../utils/common-func";
import {updateGtmDataLayer} from "../../utils/payment-func";
import {ValidateStep2} from "../../utils/hotel-func";
import {getSession} from "../../utils/home-util";
import {getDataUTMInfo} from "../../utils/search-combo-func";
import {saveRedis} from "../../api-services/search-combo";
import {withRouter} from "react-router";
import HomeBoxSearch from "../../component/home/HomeBoxSearch";
import {useUserInfo} from "../../component/layout/ProvideAuth";
import { getDealFlights } from '../../api-services/search-flight'

function HomeBoxSearchContainer({ location }) {
    const [adultCount, setAdultCount] = useState(2);
    const [childCount, setChildCount] = useState(0);
    const [infantCount, setInfantCount] = useState(0);
    const [departure, setDeparture] = useState({});
    const [destination, setDestination] = useState({});
    const [itemPrice, setItemPrice] = useState(null);
    const [inputValueDepart, setInputValueDepart] = useState('')
    const [inputValueReturn, setInputValueReturn] = useState('')
    const [isSetEndPoint, setIsSetEndPoint] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isModal, setIsModal] = useState(false)
    const [redisToken, setRedisToken] = useState('')
    const [dataBr, setDataBr] = useState(null)
    const [dealFlightConfig, setDealFlightConfig] = useState(null);
    const user =  useUserInfo()

    useEffect(() => {
        const bookerData = getBookerData()
        const query = queryString.parse(location.search)
        const email = query.email
        const phone = query.phone
        const name = query.name
        const adultCount = query.adultCount
        const childCount = query.childCount
        const infantCount = query.infantCount
        const startPoint = query.startPoint
        const endPoint = query.endPoint
        if(email) {
            bookerData.email = email
        }
        if(phone) {
            bookerData.phone = phone
        }
        if(name) {
            bookerData.last_name = convertFullNameToFirstLastName(name, 'last_name') || ''
            bookerData.first_name = convertFullNameToFirstLastName(name, 'first_name') || ''
        }
        if(adultCount) {
            bookerData.adultCount = adultCount
        }
        if(childCount) {
            bookerData.childCount = childCount
        }
        if(infantCount) {
            bookerData.infantCount = infantCount
        }
        if(startPoint) {
            bookerData.departure = getNamePointFromCode(startPoint)
        }
        if(endPoint) {
            bookerData.destination = getNamePointFromCode(endPoint)
        }
        if (bookerData && !isEmptyObject(bookerData)) {
            setDeparture(bookerData.departure || {})
            setDestination(bookerData.destination || {})
            if (bookerData.destination) setInputValueReturn(bookerData.destination.province_name)
            if (bookerData.departure) {
                setInputValueDepart(bookerData.departure.province_name)
            }
            if (bookerData.adultCount) setAdultCount(Number(bookerData.adultCount))
            if (bookerData.childCount) setChildCount(Number(bookerData.childCount))
            if (bookerData.infantCount) setInfantCount(Number(bookerData.infantCount))
            if (bookerData.itemPrice) setItemPrice(bookerData.itemPrice)

        }
        window.localStorage.setItem('bookerData', JSON.stringify(bookerData))
        updateGtmDataLayer()
    }, []);

    // get deal flight config
    useEffect(() => {
        const fetchDealFlight = async dealId => {
            try {
                const result = await getDealFlights(dealId);
                if (result?.status === 200) {
                    setDealFlightConfig(result?.data);
                }
            } catch (e) {
                throw e;
            }
        };
        if (itemPrice) {
            fetchDealFlight(itemPrice?.id);
        }
    }, [itemPrice]);

    const handleChoosePassengers = (type) => {
        /*
        1. Tổng số người lớn + trẻ em max là 6, min là 2
        2. Số em bé max là 6, min là 0, 1 người lớn chỉ được kèm thêm 1 em bé
        3. Em bé max là 6, 1 người lớn chỉ được kèm thêm 1 em bé
        */
        let newAdultCount = adultCount;
        let newChildCount = childCount;
        let newInfantCount = infantCount;
        switch (type) {
            case PASSENGER_CONST.ADT_ASC:               // tang nguoi lon
                if (adultCount < 6) {
                    newAdultCount = adultCount + 1;
                }
                setAdultCount(newAdultCount);
                break;
            case PASSENGER_CONST.ADT_DESC:              // giam nguoi lon
                newAdultCount = adultCount > 2 ? adultCount - 1 : adultCount;
                if (newAdultCount < infantCount) {
                    setInfantCount(newAdultCount)
                }
                if (newAdultCount < childCount) {
                    setChildCount(newAdultCount)
                }
                setAdultCount(newAdultCount);
                break;
            case PASSENGER_CONST.CHD_ASC:               // tang tre em
                if (childCount <= adultCount && childCount < 6) {
                    newChildCount = childCount + 1;
                }
                setChildCount(newChildCount);
                break;
            case PASSENGER_CONST.CHD_DESC:               // giam tre em
                newChildCount = childCount >= 1 ? childCount - 1 : childCount;
                setChildCount(newChildCount);
                break;
            case PASSENGER_CONST.INF_ASC:               // tang em be
                if (infantCount <= adultCount && infantCount < 6) {
                    newInfantCount = infantCount + 1;
                }
                setInfantCount(newInfantCount);
                break;
            case PASSENGER_CONST.INF_DESC:               // giam em be
                newInfantCount = infantCount >= 1 ? infantCount - 1 : infantCount;
                setInfantCount(newInfantCount);
                break;
            default:
                break;
        }
    };

    const handleChoosePrice = (e, value) => {
        const listPrice = document.querySelectorAll('ul.listPrice li');
        for (let i = 0; i < listPrice.length; i++) {
            listPrice[i].classList.remove('active')
        }
        e.currentTarget.classList.add('active');
        setItemPrice(value)
    };
    // console.log("===itemPrice===", itemPrice)
    // console.log("===destination===", destination)
    const handleContinue = async () => {
        try {
            let dataObj = {};
            dataObj.departure = departure.code || '';
            dataObj.id_departure = 'formBox_departure';
            dataObj.destination = destination.code || '';
            dataObj.id_destination = 'formBox_destination';
            if (ValidateStep2(dataObj)) {
                const bookerData = {
                    departure: departure,
                    destination: destination,
                    adultCount: adultCount,
                    childCount: childCount,
                    infantCount: infantCount,
                    itemPrice: itemPrice
                }
                window.localStorage.setItem('bookerData', JSON.stringify(bookerData))
                let publisher_data = getSession('publisher');
                const _publisher_data = getDataUTMInfo(publisher_data, 'combo');
                const dataBr = {
                    "count_child": childCount,
                    "request_from": "WEBSITE",
                    "count_infant": infantCount,
                    "count_adult": adultCount,
                    "deal_id": itemPrice?.id,
                    "nights": itemPrice?.nights,
                    "days": itemPrice?.days,
                    "is_combo": true,
                    "type": "dynamic_combo",
                    "from": departure.code,
                    "booker_data": {
                        'user_id': user?.user_id || 0,
                        'is_receiver': true
                    },
                    "to": destination?.code,
                    "combo_type": "combo-donggia",
                    "dealData": itemPrice,
                    "province_id": destination?.province_id,
                };
                if (_publisher_data) {
                    dataBr.publisher_data = _publisher_data
                }
                let webviewSessionInfo = JSON.parse(localStorage.getItem('webviewSessionInfo'));
                if(webviewSessionInfo && webviewSessionInfo.user_info) {
                    dataBr.business_account_id = webviewSessionInfo.user_info.account_id
                    dataBr.webview = webviewSessionInfo.user_info.account_id
                }

                setIsLoading(true)
                const result = await saveRedis(dataBr);
                setIsLoading(false)
                if (result?.status === "success" && result?.data) {
                    setIsModal(true)
                    setRedisToken(result?.data)
                    setDataBr(dataBr)
                }

                // if (data && data.data && data.status === 'success') {
                //     localStorage.setItem('data_booking_request', JSON.stringify(data.data))
                //     const query = queryString.parse(location.search)
                //     const queryNew = {...query,
                //         startPoint: departure.code,
                //         endPoint:destination.code,
                //         nights:itemPrice.nights,
                //         provinceId: desProvinceId,
                //         days: itemPrice.days,
                //         adultCount,
                //         childCount,
                //         infantCount,
                //         bookingRequestId: data.data.booking_request_id,
                //         suggestionId: data.data.suggestion_id
                //     }
                //     const stringify = queryString.stringify(queryNew);
                //     history.push(`/select-flight?${stringify}`)
                // } else if (data && data.status === 'error' && data.error_code === "400") {
                //     message.error('Vui lòng điền đúng thông tin của bạn')
                // }
                if (result?.status === 'error' && result?.error_code === "BK1065") {
                    message.error(result?.message)
                }
            }
        }catch (e) {
            message.error(e?.response?.data?.message)
            throw e
        }
    };

    const convertFullNameToFirstLastName = (fullName, type) => {
        let arr_name = fullName.split(' ')
        if (arr_name.length >= 1) {
            if (type === 'last_name') return arr_name[0]
            else if (type === 'first_name') {
                return arr_name.slice(1).join(' ') ? arr_name.slice(1).join(' ') : arr_name[0]
            }
        }
    };
    return <HomeBoxSearch
        departure={departure}
        destination={destination}
        itemPrice={itemPrice}
        inputValueDepart={inputValueDepart}
        inputValueReturn={inputValueReturn}
        adultCount={adultCount}
        childCount={childCount}
        infantCount={infantCount}
        isSetEndPoint={isSetEndPoint}
        isLoading={isLoading}
        isModal={isModal}
        redisToken={redisToken}
        dataBr={dataBr}
        dealFlightConfig={dealFlightConfig}
        // function
        setIsModal={setIsModal}
        setIsSetEndPoint={setIsSetEndPoint}
        setInputValueReturn={setInputValueReturn}
        setInputValueDepart={setInputValueDepart}
        setDeparture={setDeparture}
        setDesProvinceId={() => {}}
        setDepartProvinceId={() => {}}
        setItemPrice={setItemPrice}
        setDestination={setDestination}
        handleChoosePassengers={handleChoosePassengers}
        handleContinue={handleContinue}
        handleChoosePrice={handleChoosePrice}
    />

}


export default withRouter(connect(state => {
    return {}
})(HomeBoxSearchContainer));
