import React, {useEffect} from 'react';
import {withRouter} from 'react-router-dom'
import moment from 'moment';
import "moment/min/locales.min"
import App from "../../component/layout/App";
import 'antd/dist/antd.css';
import '../../assets/scss/style.scss'
import '../../assets/style/app.css'
import '../../assets/style/style.css'
import '../../assets/style/checkout-bank-transfer.css'
import '../../assets/pages/home.css'
import '../../assets/style/common.css'
import '../../assets/style/config.css'
import * as queryString from "query-string";
import {setSession} from "../../utils/home-util";
import {addAltStyle, removeSession} from "../../utils/common-func";
import {
    setDataWebviewConfig,
    toggleShowLoading
} from "../../redux/actions/home-actions";
import {connect} from "react-redux";
import {Helmet} from "react-helmet";

import {getInfoPartner} from "../../api-services/home-service";
import {setAlertMessage, setLinkPopup, setTextAlertButton} from "../../redux/actions/payment-method-action";

function AppContainer({location, dispatch, dataWebview}) {
    useEffect(() => {
        const query = queryString.parse(location.search);
        let userSession = query.user_session;
        let merchant_code = query.merchant_code;
        const webviewCoupon = query.webview;
        if(merchant_code && !userSession) {
            userSession = merchant_code
        }
        const addAltStyleLogo = async () => {
            document.documentElement.style.setProperty("--color-primary", '#ffffff');
            let accountId = false

            if (userSession) {
                const data = {
                    user_session: userSession,
                };
                dispatch(toggleShowLoading(true))
                const infoPartner = await getInfoPartner(data)
                dispatch(toggleShowLoading(false))
                if (infoPartner && infoPartner.data && infoPartner.status === 'success') {
                    accountId = infoPartner.data.user_info.account_id
                    localStorage.setItem('webviewSessionInfo', JSON.stringify(infoPartner.data));
                }else {
                    dispatch(setAlertMessage('Phiên làm việc đã hết hạn'))
                    dispatch(setLinkPopup('/'))
                    dispatch(setTextAlertButton('Về trang chủ'))
                    removeSession('webviewSessionInfo')
                }
            }else {
                let webviewSessionInfo = localStorage.getItem('webviewSessionInfo');
                if (webviewSessionInfo) {
                    webviewSessionInfo = JSON.parse(webviewSessionInfo);
                    accountId = webviewSessionInfo.user_info.account_id;
                }else {
                    if(webviewCoupon){
                        localStorage.setItem('webviewCoupon', JSON.stringify({
                            webview_coupon: webviewCoupon
                        }));
                    }
                }
            }
            const dataWebview = await addAltStyle(accountId)
            if(dataWebview) {
                dispatch(setDataWebviewConfig(dataWebview))
            }
        }
        addAltStyleLogo()

    }, [])

    useEffect(() => {
        const query = queryString.parse(location.search);
        if (query && query.utm_source) {
            let utm_source = query.utm_source.toLowerCase(),
                traffic_id = query.traffic_id,
                session_id = query.session_id,
                publisher_id = query.publisher_id,
                utm_campaign = query.utm_campaign || "",
                utm_content = query.utm_content || "",
                utm_agent = query.utm_agent || "",
                utm_team = query.utm_team || "",
                utm_medium = query.utm_medium || "",
                click_id = query.click_id,
                // hotel_id = parseInt(Router.current().params._id);
                publisher = {
                    utm_agent,
                    utm_team,
                    utm_source,
                    utm_campaign,
                    utm_content,
                    utm_medium,
                    publisherid: publisher_id,
                    click_id,
                    click_time: moment().format('DD/MM/YYYY h:mm:ss')
                };
            if (utm_source === "masoffer") {
                publisher.publisherid = traffic_id || publisher.publisherid;
            } else if (utm_source === "accesstrade") {
                publisher.publisherid = session_id || publisher.publisherid;
            } else if (utm_source === "trivago") {
                publisher.publisherid = 1760;
            } else if (utm_source === "cityads") {
                if (query.utm_campaign && query.click_id && query.utm_medium) {
                    publisher.utm_medium = publisher.utm_medium || "cpa";
                    publisher.publisherid = 29152;
                }
            }
            setSession('publisher', {publisher}, 30)
        }

    }, [location]);
    return (
        <div>
            <Helmet>
                <link rel="icon" sizes="16x16" href={dataWebview?.logoFavicon || 'https://statics.vntrip.vn/images/vntrip_favicon.png'} />
                <title>{dataWebview?.whitelabel  && dataWebview?.title}</title>
            </Helmet>
            <App/>
        </div>
    );

}

export default withRouter(connect(state => {
    return {
        dataWebview: state.homeReducer.dataWebview,
    }
})(AppContainer));
