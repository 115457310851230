import React from 'react';
import {withRouter} from "react-router";
import {connect} from "react-redux";

function LoadingCombo({isShowLoading, valueProgress}) {

    if(isShowLoading) {
        return (
            <section className="loadingBox">
                <div className="loadingBox__item loadingBox__step1">
                    <div className="loadingBox__dot">
                        <div className="v-loading-page__dots">
                            <div className="v-loading-page__dot v-loading-page__dot--first" />
                            <div className="v-loading-page__dot v-loading-page__dot--second" />
                            <div className="v-loading-page__dot v-loading-page__dot--third" />
                        </div>
                        <p>Hệ thống đang xử lý</p>
                    </div>
                </div>
                <div className="loadingBox__item loadingBox__step2 hidden">
                    <div className="loadingBox__progress">
                        <p>Hệ thống đang xử lý</p>
                        <progress value={valueProgress} max={100} />
                    </div>
                    {/*//{`${process.env.REACT_APP_URL_STATIC_IMAGE}/donggia/combo-01.png`}*/}

                    <div className="loadingBox__group1">
                        <div className="loadingBox__icon">
                            <div className="loadingBox__icon__row">
                                <div className="loadingBox__icon__item"><img src={`${process.env.REACT_APP_URL_STATIC_IMAGE}/images/loading/icon-1.png`} alt="" /></div>
                                <div className="loadingBox__icon__item"><img src={`${process.env.REACT_APP_URL_STATIC_IMAGE}/images/loading/icon-2.png`} alt="" /></div>
                                <div className="loadingBox__icon__item"><img src={`${process.env.REACT_APP_URL_STATIC_IMAGE}/images/loading/icon-3.png`} alt="" /></div>
                                <div className="loadingBox__icon__item"><img src={`${process.env.REACT_APP_URL_STATIC_IMAGE}/images/loading/icon-4.png`} alt="" /></div>
                                <div className="loadingBox__icon__item"><img src={`${process.env.REACT_APP_URL_STATIC_IMAGE}/images/loading/icon-5.png`} alt="" /></div>
                            </div>
                        </div>
                        <div className="loadingBox__wait">
                            <p className="p1">Xin vui lòng chờ trong giây lát!</p>
                            <div className="loadingBox__text">
                                <p className="p2">Chúng tôi đã tiếp nhận và đang xử lý yêu cầu của bạn</p>
                            </div>
                        </div>
                    </div>
                    <div className="loadingBox__group2 hidden">
                        <div className="loadingBox__slide__title">Có thể bạn chưa biết?</div>
                        <div className="loadingBox__slide">
                            <div className="loadingBox__slide__item item-1">
                                <div className="loadingBox__slide__img"><img src={`${process.env.REACT_APP_URL_STATIC_IMAGE}/images/loading/slide-1.png`} alt="" /></div>
                                <div className="loadingBox__slide__text">
                                    <p className="p2">Các thiết bị điện tử bị hạn chế mang theo dưới dạng hành lý ký gửi. Bao gồm: điện thoại thông minh, pin lithium, laptop,… Bạn cũng không nên mang các vật có giá trị cao như trang sức đắt tiền, tiền mặt,… vào hành lý ký gửi.</p>
                                </div>
                            </div>
                            <div className="loadingBox__slide__item item-2">
                                <div className="loadingBox__slide__img"><img src={`${process.env.REACT_APP_URL_STATIC_IMAGE}/images/loading/slide-2.png`} alt="" /></div>
                                <div className="loadingBox__slide__text">
                                    <p className="p2">Những đồ ăn nặng mùi như sầu riêng, mắm tôm hay tương tự là một trong những đồ vật bị cấm mang lên máy bay. Tốt nhất, bạn nên đựng trong 1 hộp nhựa rồi bỏ vào 1 hộp xốp hoặc hộp giấy là hoàn toàn có thể yên tâm cầm theo. Các loại đồ ăn bao gồm món khô, không ngâm nước hoàn toàn có thể mang lên máy bay.</p>
                                </div>
                            </div>
                            <div className="loadingBox__slide__item item-3">
                                <div className="loadingBox__slide__img"><img src={`${process.env.REACT_APP_URL_STATIC_IMAGE}/images/loading/slide-3.png`} alt="" /></div>
                                <div className="loadingBox__slide__text">
                                    <p className="p2">Bất kỳ vật dụng nào sắc nhọn có thể gây nguy hiểm cho chuyến bay như: dao, kéo, bật lửa,… đều bị cấm tuyệt đối. Tuy nhiên một số đồ vật quen thuộc khác như cái bấm móng tay, các loại đầu cắm, sạc điện, chìa khoá… lại được thoải mái cầm lên máy bay.</p>
                                </div>
                            </div>
                            <div className="loadingBox__slide__item item-4">
                                <div className="loadingBox__slide__img"><img src={`${process.env.REACT_APP_URL_STATIC_IMAGE}/images/loading/slide-4.png`} alt="" /></div>
                                <div className="loadingBox__slide__text">
                                    <p className="p2">Smartphone Samsung Galaxy Note 7 từ sự cố phát nổ trước đây cũng bị nhiều quốc gia khác cấm mang theo khi đi máy bay dưới bất kỳ hình thức nào.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
    return null
}

const mapStateToProps = function(state) {
    return {
        isShowLoading: state.homeReducer.showLoadingComBo,
        valueProgress: state.homeReducer.valueProgress || 10,
    }
}

export default withRouter(connect(mapStateToProps)(LoadingCombo));