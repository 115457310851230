import { HOME_TYPE } from '../../const/Home'

export const setFlightListSearchBox = flightList => ({
    type: 'SET_FLIGHT_LIST_SEARCH_BOX',
    flightList: flightList
});

export const setListAirPortReturn = listAirPortReturn => ({
    type: 'SET_LIST_AIRPORT_RETURN',
    listAirPortReturn: listAirPortReturn
});


export const setSelectedHotel = (data) => {
    return {
        type: 'HOTEL_SELECTED',
        selectedHotel: data
    }
};

export const setSelectedDeparture = (data) => {
    return {
        type: 'SELECTED_DEPARTURE',
        selectedDeparture: data
    }
};

export const setSelectedReturn = (data) => {
    return {
        type: 'SELECTED_RETURN',
        selectedReturn: data
    }
};