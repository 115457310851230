import {HOME_TYPE} from "../../const/Home";

export const toggleShowLoading = (boolean) => {
    return {
        type: HOME_TYPE.SHOW_LOADING,
        showLoading: boolean
    }
};

export const toggleShowLoadingComBo = boolean => {
    return {
        type: HOME_TYPE.SHOW_LOADING_GIU_CHO,
        showLoadingComBo: boolean
    }
};

export const saveValueProgress = value => {
    return {
        type: HOME_TYPE.SET_VALUE_PROGRESS,
        valueProgress: value
    }
};


export const toggleShowLoadingComponent = (boolean) => {
    return {
        type: HOME_TYPE.SHOW_LOADING_COMPONENT,
        showLoadingComponent: boolean
    }
};

export const setLoadingMessage = message => {
    return {
        type: HOME_TYPE.SET_LOADING_MESSAGE,
        loadingMessage: message
    }
};

export const setLoadingMessageSlide = message => {
    return {
        type: HOME_TYPE.SET_LOADING_MESSAGE_SLIDE,
        loadingMessageSlide: message
    }
};

export const setUrlLogoPartner = url => {
    return {
        type: HOME_TYPE.LOGO_FOR_PARTNER,
        logoPartner: url
    }
};

export const setHideHeaderPartner = boolean => {
    return {
        type: HOME_TYPE.HEADER_PARTNER,
        hideHeader: boolean
    }
};

export const setDataWebviewConfig = (dataWebview) => {
    return {
        type: HOME_TYPE.DATA_WEBVIEW_CONFIG,
        dataWebview: dataWebview
    }
};


