import React, {  useEffect, useState } from 'react'
import * as queryString from 'query-string'
import { withRouter } from 'react-router-dom'
import Finish from "../../component/finish/Finish";

function FinishContainer({ location }) {
    const [status, setStatus] = useState('');
    const [bookingRequestId, setBookingRequestId] = useState('');
    useEffect(() => {
        const query = queryString.parse(location.search);
        const _status = query.status;
        const _bookingRequestId = query.bookingRequestId;
        setBookingRequestId(_bookingRequestId);
        setStatus(_status)
    }, []);
    return (
        <Finish
            status={status}
            bookingRequestId={bookingRequestId}
        />
    );

}

export default withRouter((FinishContainer))
