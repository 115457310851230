import React from 'react';
import FinishPassengerInfo from "../../component/finish/FinishPassengerInfo";

function FinishPassengerInfoContainer() {

    return (
       <FinishPassengerInfo/>
    );

}

export default FinishPassengerInfoContainer;