import React from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { Modal } from 'antd'
import {toggleGoogleMapHotelList} from "../../../redux/actions/hotel-list";

function PopupGoogleMap({ dispatch, isShow, latitude, longitude, width, height }) {
    const handleClose = () => dispatch(toggleGoogleMapHotelList(false, null, null))

    return (
        <Modal
            title="Bản đồ khách sạn"
            maskClosable={false}
            visible={isShow}
            footer={null}
            onCancel={handleClose}
            className={'modal-map'}
        >
            <iframe
                src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_API_KEY_GMAP}&q=${latitude},${longitude}`}
                width={width || 1250 - 24 * 2} // 24 là padding 1 phía
                height={height || 650}
                frameBorder={0}
                style={{ border: 0 }}
                allowFullScreen
                title={'Popup google map'}
            />
        </Modal>
    )
}

const mapStateToProps = function(state) {
    return {
        isShow: state.hotelListReducer.isShow || false,
        latitude: state.hotelListReducer.latitude,
        longitude: state.hotelListReducer.longitude
    }
}
export default withRouter(connect(mapStateToProps)(PopupGoogleMap))
