import { HOTEL_TYPE } from "../../const/hotel-const";

const hotelListReducer = (state = {}, action) => {
  switch (action.type) {
    case HOTEL_TYPE.TOGGLE_POPUP_GOOGLE_MAP_HOTEL_LIST:
      return {
        ...state,
        isShow: action.isShow,
        latitude: action.latitude,
        longitude: action.longitude
      };
    case HOTEL_TYPE.TOGGLE_POPUP_HOTEL_INFO:
      return {
        ...state,
        isShowHotelInfo: action.isShowHotelInfo
      };
    case HOTEL_TYPE.DATA_HOTEL_DETAIL:
      return {
        ...state,
        hotelDetail: action.hotelDetail
      };
    default:
      return state;
  }
};

export default hotelListReducer;
