import React, {Component} from 'react';
import loadingFlight from "../../assets/images/bao-tri.png";

const Maintenance = () => {
    return (
        <div className={"pageMaintenance"}>
            <div className="container">
                <div className="pageMaintenanceContent">
                    <div className="pageMaintenanceBody">
                        <img src={loadingFlight} alt=""/>
                        <p className={"semibold size-18"}>Hệ thống đang bảo trì, vui lòng quay lại sau. Mong bạn thông cảm!</p>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default Maintenance;