import React from 'react';
import ReviewComboHotel from "../../component/hotel-review/ReviewComboHotel";

function ReviewComboHotelContainer({ dataBooking, dataHotel }) {

    return (
        <ReviewComboHotel
            dataBooking={dataBooking}
            dataHotel={dataHotel}
        />
    );

}

export default ReviewComboHotelContainer;
