import React from 'react';
import "moment/min/locales.min";
import PopupGoogleMap from "../common/hotel-list-map-view/PopupGoogleMap";
import HotelListLoading from "../common/HotelListLoading";
import HotelGallery from "./HotelGallery";
import HotelFail from "./HotelFail";
import DisplayPrice from "../common/DisplayPrice";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {toggleShowHotelInfo} from "../../redux/actions/hotel-list";
import ReviewComboInfoContainer from "../../containers/hotel-review/ReviewComboInfoContainer";
import {toUpperCaseFirst} from "../../utils/common-func";
import HotelItem from "./HotelItem";

function SelectHotel(
    {
        dataListHotel,
        isShowButton,
        handleClickMap,
        handleSelect,
        isShow,
        handleHotelGallery,
        dataHotelDetail,
        isShowImage,
        setIsShowImage,
        handleContinue,
        handleFourStart,
        handleBack,
        isFourStar,
        showComponent,
        nights,
        checkInDate,
        endPoint,
        dataListPriceHotel,
        dispatch,
        isShowHotelInfo,
        dataBooking,
        itemPriceHotel,
        handClickItemPrice,
        priceComboForPerson,
        personSurchargePrice,
        priceInfantSurcharge
    }) {
    const dataFlight = dataBooking ? dataBooking.flight : {};

    const formatPrice = (price1, price2) => {
        const price = (price2 - price1)*nights/2
        if (price < 0) {
            return (
                <div>
                    + <DisplayPrice price={Math.abs(price)}/>
                </div>
            )

        } else {
            return (
                <div>
                    - <DisplayPrice price={Math.abs(price)}/>
                </div>
            )
        }
    }

    return (
        <section className="step4">
            <div className="container">

                {
                    showComponent !== 'HOTEL_ERROR' && isFourStar &&
                    <div onClick={() => handleBack(dataListHotel)} style={{top: 0}} className="btn_black_mobile">
                        <svg width={16} height={16} viewBox="0 0 16 16" fill="none"
                             xmlns="http://www.w3.org/2000/svg"
                             className="regLog_icon">
                            <path
                                d="M6.7 14.6998L8.1 13.2998L3.8 8.9998H16V6.9998H3.8L8.1 2.6998L6.7 1.2998L0 7.9998L6.7 14.6998Z"
                                fill="#595959"/>
                        </svg>
                        <span> Quay lại</span>
                    </div>

                }

                <div className={`page-content ${isShowHotelInfo ? 'open' : ''}`}>
                    <div className="page-right">
                        {showComponent !== 'HOTEL_ERROR' && <h2 className="text-center  mb15">{isFourStar ? 'Danh sách khách sạn 4 sao' : 'Chọn khách sạn'}</h2>}
                        {showComponent !== 'HOTEL_ERROR' && <p style={{color: "#595959"}} className="text-center mb15 size-14 tip-content">
                            Những khách sạn dưới đây đã được chúng tôi lựa chọn để đảm bảo chất lượng cho bạn.
                        </p>}
                        <div className="listHotel__option">
                            {dataListPriceHotel && dataListPriceHotel.map((res, index) => {
                                return (
                                    <div onClick={() => handClickItemPrice(res)} key={index}
                                         className={`listHotel__option_item ${res.code === itemPriceHotel.code ? 'active' : ''}`}>
                                        <p className="hotel_name">Khách sạn {toUpperCaseFirst(res.code.toLowerCase())}</p>
                                        <p className="hotel_name_mobile">Gói {toUpperCaseFirst(res.code.toLowerCase())}</p>
                                        {itemPriceHotel.code === res.code ?
                                            <span> <DisplayPrice price={priceComboForPerson}/></span> :
                                            <span>{formatPrice(res.price, itemPriceHotel.price)}</span>
                                        }
                                    </div>
                                )
                            })}
                        </div>

                        {showComponent === 'HOTEL_ERROR' ?
                            <HotelFail
                                handleFourStart={handleFourStart}
                                checkInDate={checkInDate}
                                endPoint={endPoint}
                            />
                            :
                            <div className="content-hotel">
                                {
                                    isShow ? <HotelListLoading/> :
                                    <HotelItem
                                        handleSelect={handleSelect}
                                        dataListHotel={dataListHotel}
                                        handleClickMap={handleClickMap}
                                        itemPriceHotel={itemPriceHotel}
                                        handleHotelGallery={handleHotelGallery}
                                    />
                                }
                            </div>
                        }
                    </div>
                    <ReviewComboInfoContainer
                        dataBooking={dataBooking}
                        textBtn={'Hoàn Tất'}
                        handleContinue={handleContinue}
                        personSurchargePrice={personSurchargePrice}
                        priceInfantSurcharge={priceInfantSurcharge}
                        isShowButton={isShowButton}
                        priceComboForPerson={priceComboForPerson}
                    />
                    <div className="btnGroup w100 btn-mobile">
                        <div className="hotelReview__info__totalPrice" onClick={() => dispatch(toggleShowHotelInfo(true))}>
                            <div className="d-flex">
                                <p>Tổng cộng</p>
                                <p className="price"><span> <DisplayPrice
                                    price={dataBooking && dataBooking.combo && dataFlight &&  ((dataFlight[0].adult_count + dataFlight[0].child_count) * priceComboForPerson)}/></span></p>
                            </div>
                            <div className="hotelReview__info__fee d-flex">
                                <span>Đây là giá cuối, không thêm phí phụ thu</span>
                                <span  className="detail">Chi tiết
                                    <svg width={12} height={12} viewBox="0 0 12 12" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                      <path
                                          d="M6 0.75C3.101 0.75 0.75 3.101 0.75 6C0.75 8.899 3.101 11.25 6 11.25C8.899 11.25 11.25 8.899 11.25 6C11.25 3.101 8.899 0.75 6 0.75ZM6 10.3595C3.593 10.3595 1.6405 8.407 1.6405 6C1.6405 3.593 3.593 1.6405 6 1.6405C8.407 1.6405 10.3595 3.593 10.3595 6C10.3595 8.407 8.407 10.3595 6 10.3595Z"
                                          fill="#1890FF"/>
                                      <path
                                          d="M5.4375 3.9375C5.4375 4.0865 5.4965 4.23 5.6025 4.3355C5.7075 4.4405 5.851 4.5 6 4.5C6.149 4.5 6.2925 4.4405 6.398 4.335C6.503 4.23 6.5625 4.0865 6.5625 3.9375C6.5625 3.7885 6.5035 3.645 6.3975 3.54C6.2925 3.4345 6.149 3.375 6 3.375C5.851 3.375 5.7075 3.4345 5.602 3.54C5.497 3.645 5.4375 3.7885 5.4375 3.9375ZM6.2815 5.25H5.7185C5.667 5.25 5.625 5.292 5.625 5.344V8.531C5.625 8.583 5.667 8.625 5.719 8.625H6.281C6.333 8.625 6.375 8.583 6.375 8.531V5.3435C6.375 5.292 6.333 5.25 6.281 5.25H6.2815Z"
                                          fill="#1890FF"/>
                                    </svg>
                                </span>
                            </div>
                        </div>
                        <button onClick={handleContinue}
                                className={`btn btn_orange ${isShowButton ? '' : 'btn_disable'}`}><span>Hoàn Tất</span>
                        </button>
                    </div>
                    <div className="menu-mobile__backdrop"></div>


                    <PopupGoogleMap/>
                    <HotelGallery
                        images={dataHotelDetail.images}
                        hotelId={dataHotelDetail.id}
                        isShowImage={isShowImage}
                        setIsShowImage={setIsShowImage}
                        hotelName={dataHotelDetail.name}
                    />
                </div>
            </div>
        </section>

    );

}

const mapStateToProps = function (state) {
    return {
        isShowHotelInfo: state.hotelListReducer.isShowHotelInfo
    }
}

export default (withRouter(connect(mapStateToProps)(SelectHotel)))
