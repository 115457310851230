import React from 'react';
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
import {setLoadingMessage} from "../../redux/actions/home-actions";

function Loading({isShowLoading, loadingMessage, dispatch, location, loadingMessageSlide}) {
    React.useEffect(() => {
        dispatch(setLoadingMessage(null))
    }, [location])

    React.useEffect(() => {
        if (!isShowLoading) {
            dispatch(setLoadingMessage(null))
        }
    }, [isShowLoading])

    if (isShowLoading) {
        return (
            <div className="vntLoading">
                <div className={`vntLoading__cont ${loadingMessage || loadingMessageSlide ? 'background-white' : ''}`}>
                    <div className="v-loading-page__dots">
                        <div className="v-loading-page__dot v-loading-page__dot--first"></div>
                        <div className="v-loading-page__dot v-loading-page__dot--second"></div>
                        <div className="v-loading-page__dot v-loading-page__dot--third"></div>
                    </div>


                    {loadingMessageSlide ?
                        <div className="v-loading-page__text v-loading-page__text__animation">
                            {loadingMessageSlide.map((res, index) => {
                                return <p key={index} className={`item${index + 1}`}>{res}</p>
                            })}
                        </div> :
                            loadingMessage &&
                                <div className="v-loading-page__text default">
                                    <p>{loadingMessage}</p>
                                </div>
                    }


                </div>
            </div>

        );

    }
    return null

}

const mapStateToProps = function (state) {
    return {
        isShowLoading: state.homeReducer.showLoading,
        loadingMessage: state.homeReducer.loadingMessage,
        loadingMessageSlide: state.homeReducer.loadingMessageSlide,
    }
}

export default withRouter(connect(mapStateToProps)(Loading));