import React from 'react';
function HomeTutorial() {
    return (
        <div className="home__item tutorial__combo">
            <div className="container">
                <div className="title">
                    <h2> Hướng dẫn săn combo</h2><span>Nhanh gọn, chỉ 3 bước</span>
                </div>
                <div className="list__step">
                    <div className="item__tutorial">
                        <div className="image"><img src={`${process.env.REACT_APP_URL_STATIC_IMAGE}/images/donggia/combo-01.jpg`} alt="" /></div>
                        <div className="text">
                            <p className="step">Bước 1:</p>
                            <p className="title__text">Săn combo đồng giá</p>
                            <p> Tìm kiếm bằng cách điền đủ điểm đến, ngày đi, nơi khởi hành, thông tin liên hệ, đồ dài kỳ nghỉ.</p>
                        </div>
                    </div>
                    <div className="item__tutorial">
                        <div className="image"><img src={`${process.env.REACT_APP_URL_STATIC_IMAGE}/images/donggia/combo-02.jpg`} alt="" /></div>
                        <div className="text">
                            <p className="step">Bước 2:</p>
                            <p className="title__text">Chọn ngày khởi hành</p>
                            <p> - Phụ thu trẻ em dưới 2 tuổi: 249,000đ.</p>
                            <p>- Trẻ em trên 2 tuổi: mua combo như người lớn.</p>
                        </div>
                    </div>
                    <div className="item__tutorial">
                        <div className="image"><img src={`${process.env.REACT_APP_URL_STATIC_IMAGE}/images/donggia/combo-03.jpg`} alt="" /></div>
                        <div className="text">
                            <p className="step">Bước 3:</p>
                            <p className="title__text">Thanh toán</p>
                            <p> Điền thông tin hành khách trong chuyến đi, sau đó lựa chọn các phương thức thanh toán mong muốn</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );

}

export default HomeTutorial;