import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import moment from 'moment';
import "moment/min/locales.min";
import * as queryString from "query-string";
import SelectHotel from "../../component/select-hotel/SelectHotel";
import {
    createSuggestion,
    getHotelDetail,
    getListDealLevel,
    getListHotel
} from "../../api-services/select-hotel-service";
import {toggleGoogleMapHotelList} from "../../redux/actions/hotel-list";
import HotelGallery from "../../component/select-hotel/HotelGallery";
import {checkStatus, getDataDetail} from '../../api-services/payment-method-service'
import {setAlertMessage, setLinkPopup} from '../../redux/actions/payment-method-action'
import {setLoadingMessage, toggleShowLoading} from "../../redux/actions/home-actions";
import {updateGtmDataLayer} from "../../utils/payment-func";
import {getSurchargeLevels} from "../../api-services/hotel-review";

function SelectHotelContainer({dispatch, location, history}) {
    const [dataListHotel, setDataListHotel] = useState({});
    const [isShowImage, setIsShowImage] = useState(false);
    const [isShowButton, setIsShowButton] = useState(false);
    const [dataHotelDetail, setDataHotelDetail] = useState([]);
    const [availToken, setAvailToken] = useState('');
    const [suggestionId, setSuggestionId] = useState('');
    const [bookingRequestId, setBookingRequestId] = useState('');
    const [checkInDate, setCheckInDate] = useState(moment().format('DD/MM/YYYY'));
    const [isFourStar, setIsFourStar] = useState('');
    const [showComponent, setShowComponent] = useState('HOTEL');
    const [backStep, setBackStep] = useState(false);
    const [statusStar, setStatusStar] = useState('');
    const [isShowFourStar, setIsShowFourStar] = useState(false)
    // const [priceFourStar, setPriceFourStar] = useState(500000)
    const [endPoint, setEndPoint] = useState(false)
    const [dataBooking, setDataBooking] = useState({});
    const [dataListPriceHotel, setDataListPriceHotel] = useState([]);
    const [itemPriceHotel, setItemPriceHotel] = useState({});
    const [nights, setNights] = useState(2);
    const [priceStandard, setPriceStandard] = useState(300000);
    const [personSurchargePrice, setPersonSurchargePrice] = useState(350000);
    const [priceInfantSurcharge, setPriceInfantSurcharge] = useState(250000);
    const [priceComboForPerson, setPriceComboForPerson] = useState(0);
    const [isShow, setIsShow] = useState(true);
    const [dataPersonSurchar, setDataPersonSurchar] = useState({
        PRICE_DELUXE: 0,
        PRICE_STANDARD: 0,
        PRICE_LUXURY: 0,
        PRICE_INFANT_LUXURY: 0,
        PRICE_INFANT_STANDARD: 0,
        PRICE_INFANT_DELUXE: 0,
    })
    useEffect(() => {
        const query = queryString.parse(location.search);
        const _checkInDate = query.checkInDate;
        const _nights = query.nights;
        const _provinceId = query.provinceId;
        const _suggestionId = query.suggestionId;
        const _endPoint = query.endPoint;
        const _bookingRequestId = query.bookingRequestId;
        const _adults = query.adults;
        const _optionHotel = query.optionHotel;
        const _childs = query.childs;
        setSuggestionId(_suggestionId);
        setEndPoint(_endPoint)
        setBookingRequestId(_bookingRequestId);
        setCheckInDate(_checkInDate)
        setAvailToken('');
        fetchListHotel(_checkInDate, _nights, _provinceId, _adults, _childs, _bookingRequestId, _optionHotel, 'downtown_distance')
    }, [location]);

    useEffect(() => {
        const fetchDataBooking = async (bookingRequestId, nights) => {
            dispatch(toggleShowLoading(true));
            const data = await getDataDetail(bookingRequestId);
            if (data && data.status === 'success') {
                setDataBooking(data.data);
                const dataSurchargeLevel = await getSurchargeLevel(data.data)
                getListDealPrice(_optionHotel, data.data, nights, dataSurchargeLevel)
            }
            dispatch(toggleShowLoading(false));
        }
        const query = queryString.parse(location.search);
        const _bookingRequestId = query.bookingRequestId;
        const _nights = query.nights;
        const _optionHotel = query.optionHotel;
        setBookingRequestId(_bookingRequestId);
        setNights(parseInt(_nights))
        fetchDataBooking(_bookingRequestId, parseInt(_nights))
    }, [])

    const getSurchargeLevel = async (dataBooking) => {
        const dataCombo = dataBooking ? dataBooking.combo : {};
        const dataFlight = dataBooking ? dataBooking.flight : {};
        const data = await getSurchargeLevels(dataCombo.deal_id)
        const _dataPersonSurchar = {...dataPersonSurchar}
            if (data && data.status === 'success') {
            data.data && data.data.map(res => {
                if (dataCombo && dataCombo.extra_price && dataCombo.extra_price.totalOddPersonSurchargePrice > 0) {
                    _dataPersonSurchar[`PRICE_${res.code}`] = res.odd_person_surcharge
                }
                if (dataFlight?.[0] && dataFlight?.[0]?.infant_count > 0) {
                    _dataPersonSurchar[`PRICE_INFANT_${res.code}`] = res.infant_surcharge
                }
            })
            setDataPersonSurchar(_dataPersonSurchar)
        }
        return _dataPersonSurchar

    }
    const getListDealPrice = async (optionHotel, dataBooking, nights, dataSurchargeLevel) => {
        const data = await getListDealLevel()
        if (data && data.status === 'success') {
            const dataCombo = dataBooking ? dataBooking.combo : {};
            const dataFlight = dataBooking ? dataBooking.flight : {};
            const item = data.data.filter(res => res.code === `${optionHotel ? optionHotel : 'STANDARD'}`) /// item theo select
            const itemStandard = data.data.filter(res => res.code === 'STANDARD') /// item default cua combo
            let priceCombo;
            let _priceInfantSurcharge = priceInfantSurcharge
            if (dataCombo && dataCombo.deal_data && dataCombo.deal_data.price[0] && dataCombo.deal_data.flights[0]) {
                priceCombo = (dataCombo?.deal_data.price[0]?.sell_price / dataCombo?.deal_data?.flights?.[0]?.adult_number) + parseInt(dataCombo?.upgrade_data?.flight?.diffPrice || 0)
            }
            if (dataCombo && dataCombo.extra_price) {
                let pricePersonSurcharge = dataCombo.extra_price.totalOddPersonSurchargePrice
                if (pricePersonSurcharge > 0) {
                    if (optionHotel === 'DELUXE') {
                        pricePersonSurcharge = dataSurchargeLevel.PRICE_DELUXE
                    }
                    if (optionHotel === 'LUXURY') {
                        pricePersonSurcharge = dataSurchargeLevel.PRICE_LUXURY
                    }
                    if (optionHotel === 'STANDARD') {
                        pricePersonSurcharge = dataSurchargeLevel.PRICE_STANDARD
                    }
                    setPersonSurchargePrice(pricePersonSurcharge)
                }

            }
            if (dataFlight?.[0]?.infant_count > 0) {
                if (item.code === 'DELUXE') {
                    _priceInfantSurcharge = dataSurchargeLevel.PRICE_INFANT_DELUXE
                }
                if (item.code === 'LUXURY') {
                    _priceInfantSurcharge = dataSurchargeLevel.PRICE_INFANT_LUXURY

                }
                if (item.code === 'STANDARD') {
                    _priceInfantSurcharge = dataSurchargeLevel.PRICE_INFANT_STANDARD

                }
                setPriceInfantSurcharge(_priceInfantSurcharge)
            }

            setItemPriceHotel(item[0])
            setPriceStandard(itemStandard[0].price)
            setDataListPriceHotel(data.data)
            setPriceComboForPerson(parseInt(priceCombo) + ((item[0].price - itemStandard[0].price) * nights / 2))
        }
    }
    useEffect(() => {
        const query = queryString.parse(location.search);
        const _bookingRequestId = query.bookingRequestId;
        if (_bookingRequestId) {
            const gtmDataLayer = {
                'Content': [{
                    'id': _bookingRequestId
                }]
            };
            updateGtmDataLayer(gtmDataLayer)
        } else {
            updateGtmDataLayer()
        }
    }, []);

    const fetchListHotel = async (checkInDate, nights, provinceId, adults, childs, bookingRequestId, optionHotel, sortBy) => {
        dispatch(toggleShowLoading(true))
        setIsShow(true)
        dispatch(setLoadingMessage('Đang tải danh sách khách sạn...'))
        const data = await getListHotel(sortBy, checkInDate, nights, provinceId, adults, childs, bookingRequestId, optionHotel);
        dispatch(toggleShowLoading(false))
        setIsShow(false);
        if (data && data.data && data.data.length === 0) {
            setDataListHotel(data)
            if (isFourStar && !backStep) {
                if (statusStar === 'error_update') {
                    dispatch(setAlertMessage('Phòng khách sạn đã bị người khác đặt mất, vui lòng chọn ngày khác hoặc hành trình khác'))
                    dispatch(setLinkPopup('/'))
                }
            } else if (backStep && !isFourStar) {
                dispatch(setAlertMessage('Phòng khách sạn đã bị người khác đặt mất, vui lòng chọn ngày khác hoặc hành trình khác'))
                dispatch(setLinkPopup('/'))
            } else {
                setShowComponent('HOTEL_ERROR')
            }
            // dispatch(setAlertMessage('Phòng khách sạn đã bị người khác đặt mất, vui lòng chọn ngày khác hoặc hành trình khác'))
            // dispatch(setLinkPopup('/'))
        } else if (data && data.status === 'error') {
            setDataListHotel(data)
            setShowComponent('HOTEL_ERROR')
        } else {
            setShowComponent('HOTEL')
            setDataListHotel(data)
        }
    };


    const handleClickMap = (lat, lon) => {
        dispatch(toggleGoogleMapHotelList(true, lat, lon))
    };

    const handleHotelGallery = async (id) => {
        try {
            const data = await getHotelDetail(id);
            setDataHotelDetail(data.data.results[0]);
            setIsShowImage(true);
            return (
                <HotelGallery
                    images={data.data.results[0].images}
                    hotelId={data.data.results[0].id}
                    isShowImage={isShowImage}
                    setIsShowImage={setIsShowImage}
                    hotelName={data.data.results[0].name}
                />
            )
        } catch (e) {
            throw e
        }
    };
    const handleSelect = (e, hotel) => {
        setAvailToken(hotel.avail_token)
        setIsShowButton(true)
    };

    const handleContinue = async () => {
        try {
            const dataSuggestion = {
                token: availToken,
                extra_bed: 0,
                bed_type: 0,
                type: "normal"
            };
            dispatch(toggleShowLoading(true))
            await checkStatus({
                booking_request_id: bookingRequestId
            })
            const data = await createSuggestion(dataSuggestion, suggestionId);
            dispatch(toggleShowLoading(false))

            if (data && data.status === 'success') {
                const resultCheck = await checkStatus({
                    booking_request_id: bookingRequestId
                })
                if (resultCheck.data && resultCheck.data.enableInstantPay === false) {
                    history.push(`/finish?status=CS_SUCCESS`)
                } else {
                    history.push(`review/${bookingRequestId}`)
                }
            } else {
                if (data.error_code === 'BK1037' || data.error_code === 'BK1020' || data.error_code === 'BK1048') {
                    dispatch(setAlertMessage('Vui lòng chọn lại hành trình của bạn'))
                    dispatch(setLinkPopup('/'))
                }
            }
        } catch (e) {
            throw e
        }
    };

    const handleFourStart = (type) => {
        setShowComponent('HOTEL')
        setStatusStar(type)
        setBackStep(false)
        // setIsShow(true);
        const query = queryString.parse(location.search)
        const queryNew = {...query, comboStart: 4}
        const stringify = queryString.stringify(queryNew);
        history.push(`${location.pathname}?${stringify}`)
    }

    const handleBack = (dataListHotel) => {
        if (dataListHotel.length === 0) {
            setBackStep(true)
        }
        const query = queryString.parse(location.search)
        delete query.comboStart
        const queryNew = {...query}
        const stringify = queryString.stringify(queryNew);
        history.push(`${location.pathname}?${stringify}`)
    };

    const handClickItemPrice = (item) => {
        setIsShowButton(false)
        const dataCombo = dataBooking ? dataBooking.combo : {};
        const dataFlight = dataBooking ? dataBooking.flight : {};
        let priceCombo;
        if (dataCombo?.deal_data?.price?.[0] && dataCombo?.deal_data?.flights?.[0]) {
            priceCombo = dataCombo.deal_data.price[0].sell_price / dataCombo?.deal_data?.flights?.[0]?.adult_number + parseInt(dataCombo?.upgrade_data?.flight?.diffPrice || 0)
        }
        let _priceInfantSurcharge = priceInfantSurcharge
        if (dataCombo?.extra_price) {
            let pricePersonSurcharge = dataCombo?.extra_price?.totalOddPersonSurchargePrice;
            if (pricePersonSurcharge > 0) {
                if (item.code === 'DELUXE') {
                    pricePersonSurcharge = dataPersonSurchar.PRICE_DELUXE
                }
                if (item.code === 'LUXURY') {
                    pricePersonSurcharge = dataPersonSurchar.PRICE_LUXURY

                }
                if (item.code === 'STANDARD') {
                    pricePersonSurcharge = dataPersonSurchar.PRICE_STANDARD

                }
                setPersonSurchargePrice(pricePersonSurcharge)
            }
        }
        if (dataFlight[0] && dataFlight[0].infant_count > 0) {
            if (item.code === 'DELUXE') {
                _priceInfantSurcharge = dataPersonSurchar.PRICE_INFANT_DELUXE
            }
            if (item.code === 'LUXURY') {
                _priceInfantSurcharge = dataPersonSurchar.PRICE_INFANT_LUXURY

            }
            if (item.code === 'STANDARD') {
                _priceInfantSurcharge = dataPersonSurchar.PRICE_INFANT_STANDARD

            }
            setPriceInfantSurcharge(_priceInfantSurcharge)
        }
        setItemPriceHotel(item)
        setPriceComboForPerson(parseInt(priceCombo) + ((item.price - priceStandard) * nights / 2))
        const query = queryString.parse(location.search)
        const queryNew = {...query, optionHotel: item.code}
        const stringify = queryString.stringify(queryNew);
        history.push(`${location.pathname}?${stringify}`)
    }
    return (
        <div>
            <SelectHotel
                dataListHotel={dataListHotel && dataListHotel.data}
                setIsShowImage={setIsShowImage}
                isShowImage={isShowImage}
                isShow={isShow}
                statusStar={statusStar}
                personSurchargePrice={personSurchargePrice}
                priceInfantSurcharge={priceInfantSurcharge}
                dataListPriceHotel={dataListPriceHotel}
                itemPriceHotel={itemPriceHotel}
                isFourStar={isFourStar}
                endPoint={endPoint}
                isShowFourStar={isShowFourStar}
                isShowButton={isShowButton}
                dataHotelDetail={dataHotelDetail}
                showComponent={showComponent}
                priceStandard={priceStandard}
                dataBooking={dataBooking}
                checkInDate={checkInDate}
                nights={nights}
                priceComboForPerson={priceComboForPerson}
                handleHotelGallery={handleHotelGallery}
                handleSelect={handleSelect}
                handClickItemPrice={handClickItemPrice}
                handleContinue={handleContinue}
                handleBack={handleBack}
                handleClickMap={handleClickMap}
                handleFourStart={handleFourStart}

            />
        </div>
    );

}

const mapStateToProps = function (state) {
    return {
        isShow: state.hotelListReducer.isShow || false,
        latitude: state.hotelListReducer.latitude,
        longitude: state.hotelListReducer.longitude
    }
}

export default (withRouter(connect(mapStateToProps)(SelectHotelContainer)))
