import React from 'react';
import PassengerInfo from "../../component/hotel-review/PassengerInfo";

function PassengerInfoContainer(
    {
        dataBooking,
        listPassenger,
        changeGender,
        handOnChange,
        handleChangeBirthDay,
        handleOpenDp,
        handleCloseDp
    }) {

    return (
        <PassengerInfo
            dataBooking={dataBooking}
            listPassenger={listPassenger}
            changeGender={changeGender}
            handleChangeBirthDay={handleChangeBirthDay}
            handOnChange={handOnChange}
            handleCloseDp={handleCloseDp}
            handleOpenDp={handleOpenDp}
        />
    );

}

export default PassengerInfoContainer;