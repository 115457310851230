import {TYPE_PAYMENT_METHOD} from "../../const/payment-method-const";

const paymentMethodReducer = (state = {}, action) => {
    switch (action.type) {
        case TYPE_PAYMENT_METHOD.SET_ALERT_MESSAGE:
            return { ...state, alertMessage: action.alertMessage }
        case TYPE_PAYMENT_METHOD.SET_ALERT_LINK:
            return {
                ...state,
                linkRoute: action.linkRoute
            }
        case TYPE_PAYMENT_METHOD.SET_TEXT_ALERT_BUTTON:
            return {
                ...state,
                textButton: action.textButton
            }
        case TYPE_PAYMENT_METHOD.SET_REFRESH_PAGE:
            return {
                ...state,
                refreshPage: action.refreshPage
            }
        case TYPE_PAYMENT_METHOD.SET_PAYMENT_METHOD:
            return {
                ...state,
                paymentMethod: action.paymentMethod
            }
        default:
            return { ...state }

    }
}
export default paymentMethodReducer
