import React, { useState, useEffect } from 'react'
import * as queryString from 'query-string'
import { withRouter } from 'react-router-dom'
import FinishComBoFlight from "../../component/finish/FinishComBoFlight";

function FinishComboFlightContainer({ location }) {
    const [status, setStatus] = useState('')
    useEffect(() => {
        const query = queryString.parse(location.search)
        const _status = query.status
        setStatus(_status)
    }, [])
    return (
        <FinishComBoFlight
            status={status}
        />
    );

}

export default withRouter((FinishComboFlightContainer))
