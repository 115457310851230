import React from 'react';
import DepartureTime from "../../component/select-flight/DepartureTime";

function DepartureTimeContainer({dataTime, time, title, type, handleDepartureTime, listTimeDepartReturn, titleDate}) {
    return (
        <DepartureTime
            dataTime={dataTime}
            titleDate={titleDate}
            handleDepartureTime={handleDepartureTime}
            listTimeDepartReturn={listTimeDepartReturn}
            title={title}
            time={time}
            type={type}
        />
    );
}

export default DepartureTimeContainer;
