import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import routes from "../../routes/Routers";
import HeaderContainer from "../../containers/layout/HeaderContainer";
import Footer from "./Footer";
import Loading from "./Loading";
import PopupNotification from "../common/PopupNotification";
import LoadingCombo from "./LoadingCombo";
import ProvideAuth from "./ProvideAuth";
import ScrollToTop from "./ScrollToTop";

const App = () => {
  return (
    <ProvideAuth>
      <div className="App">
        <HeaderContainer />
        <Loading />
        <LoadingCombo />
        <Switch>
          {routes.map(({ component: Component, name, exact, path }) => {
            return (
              <Route
                key={name}
                path={path}
                exact={exact}
                render={routeProps => {
                  return <Component {...routeProps} />;
                }}
              />
            );
          })}
        </Switch>
        <Footer />
        <PopupNotification />
        <ScrollToTop />
      </div>
    </ProvideAuth>
  );
};

export default App;
