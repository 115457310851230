import React, { useEffect, useState } from 'react'
import moment from 'moment'
import 'moment/min/locales.min'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import * as queryString from 'query-string'
import SelectFlight from '../../component/select-flight/SelectFlight'
import { createFlightRate, getListDate, getListDepartureDate } from '../../api-services/search-flight'
import { setLoadingMessage, setLoadingMessageSlide, toggleShowLoading } from '../../redux/actions/home-actions'
import { setAlertMessage, setLinkPopup } from '../../redux/actions/payment-method-action'
import { getAirRouterSession } from '../../utils/home-util'
import { updateGtmDataLayer } from '../../utils/payment-func'
import { isMobileScreen } from '../../utils/hotel-func'
import { LIST_MESSAGE_DAY } from '../../const/common-const'

function SelectFlightContainer({ history, location, dispatch }) {
    const [departureDate, setDepartureDate] = useState(
        moment()
            .add(-1, 'years')
            .toDate()
    )
    const [minDate] = useState(
        moment()
            .add(0, 'days')
            .toDate()
    )
    const [maxDate] = useState(
        moment()
            .add(6, 'months')
            .toDate()
    )
    const [timeDeparture, setTimeDeparture] = useState({})
    const [timeReturn, setTimeReturn] = useState({})
    const [nights, setNights] = useState('')
    const [days, setDays] = useState('')
    const [adultCount, setAdultCount] = useState(2)
    const [childCount, setChildCount] = useState(0)
    const [provinceId, setProvinceId] = useState(null)
    const [departDate, setDepartDate] = useState('')
    const [returnDate, setReturnDate] = useState('')
    const [bookingRequestId, setBookingRequestId] = useState('')
    const [suggestionId, setSuggestionId] = useState('')
    const [months, setMonths] = useState(moment().month() + 1)
    const [textMonth, setTextMonth] = useState(moment().toDate())
    const [years, setYears] = useState([moment().year(), moment().year()])
    const [listTimeDepart, setListTimeDepart] = useState([])
    const [listTimeReturn, setListTimeReturn] = useState([])
    const [dataListDate, setDataListDate] = useState([])
    const [listDate, setListDate] = useState({})
    const [dataTimeDisable, setDataTimeDisable] = useState([])
    const [monthsDisable, setMonthsDisable] = useState([moment().month() + 1, moment().month() + 2])
    const [minMaxDate, setMinMaxDate] = useState({
        before: minDate,
        after: maxDate
    })
    const [isShowDate, setIsShowDate] = useState(false)
    const [showLoadingComponent, setShowLoadingComponent] = useState(false)
    const [endPoint, setEndPoint] = useState(false)
    const [startPoint, setStartPoint] = useState(false)

    useEffect(() => {
        const getDaysInMonth = (month, year) => {
            // Since no month has fewer than 28 days
            let date = new Date(year[0], month[0] - 1, 1)
            let date2 = new Date(year[1], month[1] - 1, 1)
            const days = []
            while (date.getMonth() === month[0] - 1) {
                days.push(moment(new Date(date)).format('YYYY-MM-DD'))
                date.setDate(date.getDate() + 1)
            }
            if (month[1]) {
                while (date2.getMonth() === month[1] - 1) {
                    days.push(moment(new Date(date2)).format('YYYY-MM-DD'))
                    date2.setDate(date2.getDate() + 1)
                }
            }
            return days
        }
        const disabledMonth = getDaysInMonth(monthsDisable, years)
        const list = disabledMonth.filter(date => !dataListDate.includes(date))

        let dataDisable = list.map(res => {
            return moment(res).toDate()
        })
        dataDisable.push(minMaxDate)
        setDataTimeDisable(dataDisable)
    }, [dataListDate, monthsDisable, minMaxDate])
    // get list airport

    useEffect(() => {
        const query = queryString.parse(location.search)
        const _bookingRequestId = query.bookingRequestId
        const _suggestionId = query.suggestionId
        setBookingRequestId(_bookingRequestId)
        setSuggestionId(_suggestionId)
    }, [])

    useEffect(() => {
        const query = queryString.parse(location.search)
        const _bookingRequestId = query.bookingRequestId
        if (_bookingRequestId) {
            const gtmDataLayer = {
                Content: [
                    {
                        id: _bookingRequestId
                    }
                ]
            }
            updateGtmDataLayer(gtmDataLayer)
        } else {
            updateGtmDataLayer()
        }
    }, [])

    useEffect(() => {
        const query = queryString.parse(location.search)
        const _endPoint = query.endPoint
        const _startPoint = query.startPoint
        const _nights = query.nights
        const _days = query.days
        setEndPoint(_endPoint)
        setStartPoint(_startPoint)
        setNights(_nights)
        setDays(_days)
        const _bookingRequestId = query.bookingRequestId
        const _dataListDate = {
            bookingRequestId: _bookingRequestId,
            numOfMonth: isMobileScreen() ? 1 : 2,
            endPoint: _endPoint,
            month: months,
            comboNights: _nights,
            year: years[0],
            startPoint: _startPoint
        }

        fetchListDate(_dataListDate)
    }, [location])

    const callApiGetDepartTime = async day => {
        const query = queryString.parse(location.search)
        const _endPoint = query.endPoint
        const _startPoint = query.startPoint
        const _nights = query.nights
        const _adultCount = query.adultCount
        const _childCount = query.childCount
        const _infantCount = query.infantCount
        const _provinceId = query.provinceId
        const _departDate = moment(day).format('YYYYMMDD')
        const _bookingRequestId = query.bookingRequestId
        setDepartDate(_departDate)
        setChildCount(_childCount)
        setProvinceId(_provinceId)
        setAdultCount(_adultCount)
        setReturnDate(
            moment(_departDate)
                .add(_nights, 'days')
                .format('YYYYMMDD')
        )
        let isMaxPrice = null
        listDate.active2499.forEach(res => {
            if(moment(res).format('YYYYMMDD') === _departDate) {
                isMaxPrice = 'unitPrice2499'
            }
        })
        listDate.active2999.forEach(res => {
            if(moment(res).format('YYYYMMDD') === _departDate) {
                isMaxPrice = 'unitPrice2999'
            }
        })
        const _dataTimeDeparture = {
            listFlight: [
                {
                    departDate: _departDate,
                    startPoint: _startPoint,
                    endPoint: _endPoint
                },
                {
                    departDate: moment(_departDate)
                        .add(_nights, 'days')
                        .format('YYYYMMDD'),
                    startPoint: _endPoint,
                    endPoint: _startPoint
                }
            ],
            bookingRequestId: _bookingRequestId,
            childCount: Number(_childCount),
            infantCount: Number(_infantCount),
            adultCount: Number(_adultCount),
            viewMode: ''
        }

            if(isMaxPrice){
                _dataTimeDeparture.isMaxPrice = isMaxPrice
            }
        await fetchDataTimeDeparture(_dataTimeDeparture, day)
    }

    const fetchDataTimeDeparture = async (data, day) => {
        try {
            dispatch(toggleShowLoading(true))
            // Đang tải dữ liệu các chuyến bay...
            dispatch(setLoadingMessageSlide(LIST_MESSAGE_DAY))
            const result = await getListDepartureDate(data)
            dispatch(toggleShowLoading(false))
            dispatch(setLoadingMessageSlide(false))
            if (result) {
                if (
                    (result.departureFlights && result.departureFlights.length === 0) ||
                    (result.returnFlights && result.returnFlights.length === 0)
                ) {
                    dispatch(setAlertMessage('Chỗ bạn chọn vừa bị người khác đặt mất, vui lòng chọn ngày khác'))
                    dispatch(setLinkPopup(false))
                    setShowLoadingComponent(false)
                } else {
                    setListTimeDepart(result.departureFlights)
                    setListTimeReturn(result.returnFlights)
                    setTimeReturn({})
                    setTimeDeparture({})
                    setShowLoadingComponent(true)
                }
            }
        } catch (e) {
            dispatch(toggleShowLoading(false))
            throw e
        }
    }

    const fetchListDate = async data => {
        try {
            dispatch(toggleShowLoading(true))
            dispatch(setLoadingMessage('Đang tiến hành săn vé, vui lòng đợi trong giây lát...'))
            const dataDate = await getListDate(data)
            dispatch(toggleShowLoading(false))
            if (dataDate?.status === 'success') {
                let active  = dataDate?.data?.arrayDayCombo.map((res) => {
                    return moment(res).toDate()
                })
                let active2499  = dataDate?.data?.arrayDayCombo2499.map((res) => {
                    return moment(res).toDate()
                })
                let active2999  = dataDate?.data?.arrayDayCombo2999.map((res) => {
                    return moment(res).toDate()
                })
                setListDate({
                    active,

                    active2499,
                    active2999,
                })
                let _listDateAll = dataDate?.data?.arrayDayCombo
                    .concat(dataDate?.data?.arrayDayCombo2499)
                    .concat(dataDate?.data?.arrayDayCombo2999)
                // sắp xếp từ nhỏ đến lớn
                _listDateAll.sort(
                    (a, b) => moment(a, 'YYYY-MM-DD').format('YYYYMMDD') - moment(b, 'YYYY-MM-DD').format('YYYYMMDD')
                )
                setDataListDate(_listDateAll)
                setMinMaxDate({
                    before: moment(_listDateAll[0]).toDate(),
                    after: moment(_listDateAll[_listDateAll.length - 1]).toDate()
                })
                let month1 = moment(_listDateAll[0]).month() + 1
                let month2 = moment(_listDateAll[0]).month() + 2
                let year1 = moment(_listDateAll[0]).year()
                let year2 = moment(_listDateAll[0]).year()
                if (month2 > 12) {
                    year2 += 1
                    month2 -= 12
                }
                if (month1 > 12) {
                    year1 += 1
                    month1 -= 12
                }
                setYears([year1, year2])
                setTextMonth(moment(_listDateAll[0]).toDate())
                isMobileScreen() ? setMonthsDisable([month1]) : setMonthsDisable([month1, month2])
            }
        } catch (e) {
            dispatch(toggleShowLoading(false))
            throw e
        }
    }

    const handleChangeDepartureDate = async (day, modifiers = {}) => {
        if (modifiers.disabled) {
            return
        }
        setDepartureDate(day)
        setIsShowDate(true)
        await callApiGetDepartTime(day)
    }

    const handleContinue = async () => {
        try {
            if (!isShowDate) {
                dispatch(setAlertMessage('Vui lòng chọn ngày khởi hành'))
                return
            }
            if (Object.keys(timeDeparture).length === 0 || Object.keys(timeReturn).length === 0) {
                dispatch(setAlertMessage('Vui lòng chọn ngày khởi hành'))
                return
            }
            const itemDepart = { ...timeDeparture }
            const itemReturn = { ...timeReturn }
            let isMaxPrice = null
            const dataFlight = {
                suggestionId: suggestionId,
                flightItems: [itemDepart.token, itemReturn.token]
            }
            if(isMaxPrice){
                dataFlight.isMaxPrice = isMaxPrice
            }
            dispatch(toggleShowLoading(true))
            const data = await createFlightRate(dataFlight)
            dispatch(toggleShowLoading(false))
            if (data && (data.status === 'success' || data.status === 201)) {
                history.push(
                    `/select-hotel?checkInDate=${departDate}&startPoint=${startPoint}&endPoint=${endPoint}&nights=${nights}&days=${days}&provinceId=${provinceId}&adults=${adultCount}&childs=${childCount}&suggestionId=${suggestionId}&bookingRequestId=${bookingRequestId}`
                )
            }
        } catch (e) {
            dispatch(toggleShowLoading(false))
            if (e.response && e.response.data && e.response.data.code === 'FL00015') {
                dispatch(setAlertMessage('Vui lòng chọn lại hành trình của bạn'))
                dispatch(setLinkPopup('/'))
            }
            if (e.response && e.response.data && e.response.data.code === 'FL00016') {
                dispatch(setAlertMessage('Phiên làm việc đã hết hạn, vui lòng thử lại'))
                dispatch(setLinkPopup('/'))
            } else {
                dispatch(setAlertMessage('Đã có lỗi xảy ra, vui lòng thử lại'))
                dispatch(setLinkPopup('/'))
            }
            throw e
        }
    }

    const handleDepartureTime = (e, type, value) => {
        if (type === 'return') {
            setTimeReturn(value)
        } else {
            setTimeDeparture(value)
        }
    }

    const Navbar = ({ nextMonth, previousMonth, onPreviousClick, onNextClick, className, localeUtils }) => {
        const prev = previousMonth.getMonth()
        const next = nextMonth.getMonth()
        const styleLeft = {
            marginLeft: 15,
            fontSize: 25,
            marginTop: 20,
            position: 'absolute'
        }
        const styleRight = {
            marginRight: 15,
            fontSize: 25,
            marginTop: 20,
            position: 'absolute',
            right: 0
        }
        const onPreviousClickDate = () => {
            onPreviousClick()
            const year = [...years]
            let month1 = prev + 1
            let month2 = prev + 2
            if (prev === 11) {
                year[0] = year[0] - 1
                month2 = 1
                setYears(1)
            } else if (prev < 11) {
                year[1] = year[0]
            }
            setYears(year)
            setMonths(month1)
            isMobileScreen() ? setMonthsDisable([month1]) : setMonthsDisable([month1, month2])
        }

        const onNextClickDate = () => {
            const year = [...years]
            let month1 = next
            let month2 = next + 1
            if (next === 0) {
                year[1] = year[1] + 1
                month1 = 12
                setYears(1)
            } else if (next > 0) {
                year[0] = year[1]
            }
            setMonths(month1)
            setYears(year)
            onNextClick()
            isMobileScreen() ? setMonthsDisable([next + 1]) : setMonthsDisable([month1, month2])
        }
        return (
            <div className={className}>
                <button
                    disabled={months === moment().month() + 1}
                    style={styleLeft}
                    onClick={() => onPreviousClickDate()}
                >
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M11.3123 3.41061V2.2028C11.3123 2.09811 11.192 2.0403 11.1107 2.10436L4.06697 7.60592C4.00712 7.65246 3.95869 7.71206 3.92538 7.78016C3.89207 7.84827 3.87476 7.92308 3.87476 7.99889C3.87476 8.07471 3.89207 8.14952 3.92538 8.21762C3.95869 8.28572 4.00712 8.34532 4.06697 8.39186L11.1107 13.8934C11.1935 13.9575 11.3123 13.8997 11.3123 13.795V12.5872C11.3123 12.5106 11.2763 12.4372 11.217 12.3903L5.59197 7.99967L11.217 3.60749C11.2763 3.56061 11.3123 3.48717 11.3123 3.41061Z"
                            fill="#8C8C8C"
                        />
                    </svg>
                </button>
                <button disabled={dataListDate.length === 0} style={styleRight} onClick={() => onNextClickDate()}>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M11.964 7.60628L4.92031 2.10472C4.90191 2.09023 4.87979 2.08122 4.8565 2.07874C4.8332 2.07625 4.80968 2.08039 4.78863 2.09066C4.76758 2.10094 4.74986 2.11695 4.7375 2.13685C4.72514 2.15675 4.71864 2.17973 4.71875 2.20316V3.41097C4.71875 3.48753 4.75469 3.56097 4.81406 3.60784L10.439 8.00003L4.81406 12.3922C4.75313 12.4391 4.71875 12.5125 4.71875 12.5891V13.7969C4.71875 13.9016 4.83906 13.9594 4.92031 13.8953L11.964 8.39378C12.0239 8.34708 12.0723 8.28735 12.1057 8.21912C12.139 8.15089 12.1563 8.07596 12.1563 8.00003C12.1563 7.9241 12.139 7.84918 12.1057 7.78095C12.0723 7.71272 12.0239 7.65298 11.964 7.60628Z"
                            fill="#8C8C8C"
                        />
                    </svg>
                </button>
            </div>
        )
    }
    const getNamePoint = code => {
        let cityName = ''
        let dataDomestic = []
        const airportDefaul = getAirRouterSession()
        if (airportDefaul && Array.isArray(airportDefaul.data)) {
            const data = airportDefaul.data.find(it => it.key === 'vi')
            dataDomestic = data.datas[0]
        }
        if (dataDomestic) {
            const dataConvertAirPort =
                dataDomestic.dataGeoCode && dataDomestic.dataGeoCode.length > 0
                    ? dataDomestic.dataGeoCode
                    : dataDomestic.subData
            dataConvertAirPort &&
                dataConvertAirPort.forEach(item => {
                    if (item.code === code) {
                        cityName = item.cityName
                    }
                })
        }
        return cityName
    }

    return (
        <SelectFlight
            departureDate={departureDate}
            getNamePoint={getNamePoint}
            setDepartureDate={setDepartureDate}
            Navbar={Navbar}
            nights={nights}
            textMonth={textMonth}
            handleDepartureTime={handleDepartureTime}
            handleChangeDepartureDate={handleChangeDepartureDate}
            minDate={minDate}
            startPoint={startPoint}
            endPoint={endPoint}
            maxDate={maxDate}
            months={months}
            days={days}
            dataTimeDisable={dataTimeDisable}
            timeDeparture={timeDeparture}
            timeReturn={timeReturn}
            showLoadingComponent={showLoadingComponent}
            dataListDate={dataListDate}
            isShowDate={isShowDate}
            departDate={departDate}
            returnDate={returnDate}
            handleContinue={handleContinue}
            listTimeDepart={listTimeDepart}
            listTimeReturn={listTimeReturn}
            listDate={listDate}
        />
    )
}

export default withRouter(
    connect(state => {
        return {
            showLoading: state.homeReducer.showLoading
        }
    })(SelectFlightContainer)
)
