export const change_alias = (alias) =>{
    let str = alias;
    str = str.toLowerCase();
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/đ/g, "d");
    str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\\=|\\<|\\>|\?|\/|,|\.|\\:|\\;|\\'|\\"|\\&|\\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g, " ");
    str = str.replace(/ + /g, " ");
    str = str.trim().toUpperCase();
    return str;
}
export const cutFirstName = (fullName) => {
    fullName = fullName.trim();
    const arraySplit = fullName.split(' ');
    return arraySplit.slice(1).join(' ') ? arraySplit.slice(1).join(' ') : arraySplit[0]
};

export const cutLastName = (fullName) => {
    fullName = fullName.trim();
    const arraySplit = fullName.split(' ');
    return arraySplit[0];
};

export const getListPassenger = () => {
    const data = window.localStorage.getItem('listPassenger')
    return data ? JSON.parse(data) : null
}

export const saveListPassenger = data => {
    return window.localStorage.setItem('listPassenger', JSON.stringify(data))
}
