import React from 'react';
import moment from 'moment';
import "moment/min/locales.min";
import {Link} from "react-router-dom";

function HotelFail({ checkInDate }) {
    return (
        <div className="hotel-error text-center">
            {/*<h2 className="text-center mb15"> Chọn khách sạn</h2>*/}
            <div className="hotel-image"><img src={`${process.env.REACT_APP_URL_STATIC_IMAGE}/images/donggia/hotel-emty.png`} alt=""/>
            </div>
            <h2>Rất tiếc, chúng tôi đã hết phòng</h2>
            <p className="size-14">Rất tiếc, chúng tôi đã hết phòng trong ngày bạn chọn: <span style={{fontWeight:600}}>{moment(checkInDate).format('DD/MM/YYYY')}.</span><br/> Bạn vui lòng chọn
                lại ngày khác để thực hiện việc đặt phòng</p>
            <button className="btn btn_orange font-weight-5"><Link style={{color: "#fff"}} to='/'>
                Chọn lại ngày khởi hành khác </Link>
            </button>
        </div>

    );

}

export default HotelFail;