import React, { Fragment, useState, useEffect } from "react";
import { Modal } from "antd";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

import { getImgUrlByHotelId, isMobileScreen } from "../../utils/hotel-func";

function HotelGallery({
  hotelId,
  hotelName,
  images,
  isShowImage,
  setIsShowImage
}) {
  const [convertImages, setConvertImages] = useState([]);
  const [startIndex] = useState(0);
  useEffect(() => {
    const sizeImage = isMobileScreen()
      ? `${window.innerWidth}x${window.innerHeight}`
      : "1213x500";
    if (images && images.length > 0) {
      setConvertImages(
        images.map(img => {
          const converted_img = getImgUrlByHotelId(
            hotelId,
            img.slug ? img.slug : "",
            sizeImage
          );
          const converted_img_thub = getImgUrlByHotelId(
            hotelId,
            img.slug ? img.slug : "",
            "65x65"
          );
          return {
            original: converted_img,
            thumbnail: converted_img_thub
          };
        })
      );
    }
  }, [hotelId, hotelName, images]);
  return (
    <div className={"hotelGallery"}>
      <Fragment>
        <ImageGallery items={convertImages} startIndex={startIndex} />
      </Fragment>
    </div>
  );
}

export default HotelGallery;
