import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router'
import { Tooltip } from 'antd'
import { connect } from 'react-redux'
import { PAYMENT_METHODS } from '../../const/checkout-const'
import { useUserInfo } from '../layout/ProvideAuth'
import { getPLToken, getUserLotteLoanLimit, updateToken } from '../../api-services/user'
import Cookies from 'js-cookie'
import DisplayPrice from '../common/DisplayPrice'
import { displayPrice } from '../../utils/common-func'
function PaymentMethodItem({
    name,
    logo,
    description,
    handleChangePaymentMethod,
    paymentMethod,
    id,
    paymentMethodItem,
    coupons,
    isShowLimit,
    minLotte,
    totalPrice
}) {
    const check = JSON.parse(window.localStorage.getItem('paymentMethodCoupon'))
    const [lotteLoanLimit, setLotteLoanLimit] = useState({})
    const [lotteEnable, setLotteEnable] = useState(false)
    const [maxLotte, setMaxLotte] = useState(null)
    const [textLotteError, setTextLotteError] = useState('')
    const user = useUserInfo()
    const accessToken = Cookies.get('accessToken')
    useEffect(() => {
        const getDataLF = async () => {
            if (user && accessToken) {
                const user_id = user?.user_id || ''
                const phone = user?.phone || ''
                const email = user?.email || ''
                const result = await getPLToken({ userId: user_id, phone, email })
                if (result?.status === 'success') {
                    const { PLallowed, token } = result.data
                    await updateToken(token, { phone: phone })
                    if (PLallowed) {
                        const limitLotte = await getUserLotteLoanLimit()
                        if (limitLotte?.contractStatus === 'approved') {
                            setMaxLotte(limitLotte.limitAmount)
                            if (totalPrice > limitLotte.limitAmount) {
                                setLotteEnable(true)
                            }
                        }
                        setLotteLoanLimit(limitLotte)
                    }
                }
            } else {
                setLotteLoanLimit(null)
            }
        }
        if (paymentMethodItem === PAYMENT_METHODS.PAYMENT_METHOD_PAY_LATER_LFVN) {
            getDataLF()
        }
    }, [paymentMethodItem])

    useEffect(() => {
        let textAlert = ''
        if (lotteEnable) {
            if (totalPrice < minLotte) {
                setLotteEnable(true)
                textAlert += 'tối thiểu: ' + displayPrice(minLotte, 'VND')
            } else if (maxLotte) {
                if (totalPrice > maxLotte) {
                    textAlert += 'tối đa: ' + displayPrice(maxLotte, 'VND')
                }
            }
        }
        setTextLotteError(textAlert)
    }, [minLotte, maxLotte, lotteEnable])

    return (
        <div className="formPay__option__group">
            <div className="radio">
                <input
                    onChange={() =>
                        handleChangePaymentMethod(
                            paymentMethodItem,
                            coupons && coupons[0] ? coupons[0].coupon_code : null
                        )
                    }
                    checked={
                        check
                            ? check && `${paymentMethod}_coupon` === paymentMethodItem
                            : paymentMethod === paymentMethodItem
                    }
                    value={paymentMethodItem}
                    type="radio"
                    id={`payment_${id}`}
                    name="radio"
                    disabled={isShowLimit && lotteEnable}
                />
                {isShowLimit && lotteEnable ? (
                    <Tooltip
                        overlayClassName="lotte_tooltip"
                        placement="bottomLeft"
                        title={'Giá trị đơn hàng không hợp lệ (' + textLotteError + ')'}
                        color="orange"
                    >
                        <label htmlFor={`payment_${id}`}>
                            <div className={`radio__icon ${coupons ? 'image-coupon' : ''}`}>
                                <img src={logo} alt="" />
                            </div>
                            <div className="radio__text">
                                <p className="semibold">{name}</p>
                                {isShowLimit && (
                                    <>
                                        {lotteLoanLimit?.limitAmount ? (
                                            <p>
                                                {' '}
                                                Hạn mức: <DisplayPrice price={lotteLoanLimit.availLimitAmount} />
                                            </p>
                                        ) : (
                                            <p>Hạn mức chưa được thiết lập</p>
                                        )}
                                    </>
                                )}
                                <span>{description}</span>
                            </div>
                        </label>
                    </Tooltip>
                ) : (
                    <label htmlFor={`payment_${id}`}>
                        <div className={`radio__icon ${coupons ? 'image-coupon' : ''}`}>
                            <img src={logo} alt="" />
                        </div>
                        <div className="radio__text">
                            <p className="semibold">{name}</p>
                            {isShowLimit && (
                                <>
                                    {lotteLoanLimit?.limitAmount ? (
                                        <p>
                                            {' '}
                                            Hạn mức: <DisplayPrice price={lotteLoanLimit.availLimitAmount} />
                                        </p>
                                    ) : (
                                        <p>Hạn mức chưa được thiết lập</p>
                                    )}
                                </>
                            )}
                            <span>{description}</span>
                        </div>
                    </label>
                )}
            </div>
        </div>
    )
}

export default withRouter(
    connect(state => {
        return {
            paymentMethod: state.paymentMethodReducer.paymentMethod
        }
    })(PaymentMethodItem)
)
