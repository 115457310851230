import {HOTEL_TYPE} from "../../const/hotel-const";

export const toggleGoogleMapHotelList = (isShow, latitude, longitude) => ({
    type: HOTEL_TYPE.TOGGLE_POPUP_GOOGLE_MAP_HOTEL_LIST,
    isShow: isShow,
    latitude: latitude,
    longitude: longitude
})
export const toggleShowHotelInfo = boolean => {
    return{
        type: HOTEL_TYPE.TOGGLE_POPUP_HOTEL_INFO,
        isShowHotelInfo: boolean,
    }
}

export const setHotelDetail = (hotelDetail) => ({
    type: HOTEL_TYPE.DATA_HOTEL_DETAIL,
    hotelDetail: hotelDetail
})