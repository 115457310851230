
export const air_provider = {
    VN: { code: 'VN', name: 'Vietnam Airlines' },
    VJ: { code: 'VJ', name: 'Vietjet Air' },
    BL: { code: 'BL', name: 'Jetstar Pacific' },
    QH: { code: 'QH', name: 'Bamboo Airways' },
    '3K': { code: '3K', name: 'Jetstar Asia' },
    JT: { code: 'JT', name: 'Lion Airlines' },
    TR: { code: 'TR', name: 'Tiger Airways' },
    CX: { code: 'CX', name: 'Cathay Pacific Airways' },
    BR: { code: 'BR', name: 'EVA Airways' },
    CI: { code: 'CI', name: 'China Airlines' },
    TG: { code: 'TG', name: 'Thai Airways' },
    AK: { code: 'AK', name: 'AirAsia' },
    TK: { code: 'TK', name: 'Turkish Airlines' },
    QR: { code: 'QR', name: 'Qatar Airways' },
    SQ: { code: 'SQ', name: 'Singapore Airlines' },
    '0C': { code: '0C', name: 'Ibl Aviation' },
    '0J': { code: '0J', name: 'Jetclub Ag' },
    '0W': { code: '0W', name: 'West Caribbean Costa Rica' },
    '0B': { code: '0B', name: '0B' },
    '1A': { code: '1A', name: 'Amadeus It Group Sa' },
    '1B': { code: '1B', name: 'Abacus International' },
    '1F': { code: '1F', name: 'Infini Travel Information' },
    '1J': { code: '1J', name: 'Axess International Network' },
    '1N': { code: '1N', name: 'Navitaire' },
    '1P': { code: '1P', name: 'Worldspan' },
    '1V': { code: '1V', name: 'Galileo Intl Partnership (apollo)' },
    '2F': { code: '2F', name: 'Frontier Flying Service' },
    '2J': { code: '2J', name: 'Air Burkina' },
    '2M': { code: '2M', name: 'Moldavian Airlines' },
    '2P': { code: '2P', name: 'Air Philippines' },
    '2R': { code: '2R', name: 'Via Rail Canada' },
    '2L': { code: '2L', name: '2L' },
    '2N': { code: '2N', name: '2N' },
    '2A': { code: '2A', name: '2A' },
    '2C': { code: '2C', name: '2C' },
    '2D': { code: '2D', name: '2D' },
    '3A': { code: '3A', name: 'Kenosha Aero' },
    '3H': { code: '3H', name: 'Air Inuit' },
    '3I': { code: '3I', name: 'Aerolineas Austral Chile' },
    '3M': { code: '3M', name: 'Gulfstream Intl Airlines' },
    '3N': { code: '3N', name: 'Air Urga' },
    '3T': { code: '3T', name: 'Turan Air Airline' },
    '3U': { code: '3U', name: 'Sichuan Airlines' },
    '3V': { code: '3V', name: 'Tnt Airways' },
    '3Z': { code: '3Z', name: 'Tatonduk Outfitters' },
    '3E': { code: '3E', name: '3E' },
    '3S': { code: '3S', name: '3S' },
    '4C': { code: '4C', name: 'Aires' },
    '4D': { code: '4D', name: 'Air Sinai' },
    '4N': { code: '4N', name: 'Air North Charter And Training' },
    '4V': { code: '4V', name: 'Lignes Aeriennes Congolaises' },
    '4Z': { code: '4Z', name: 'Sa Airlink' },
    '4R': { code: '4R', name: '4R' },
    '4T': { code: '4T', name: '4T' },
    '4U': { code: '4U', name: '4U' },
    '4M': { code: '4M', name: '4M' },
    '4B': { code: '4B', name: '4B' },
    '5I': { code: '5I', name: 'Air G' },
    '5L': { code: '5L', name: 'Aerosur S A' },
    '5T': { code: '5T', name: 'Canadian North' },
    '5U': { code: '5U', name: 'Lineas Aereas Del Estado' },
    '5W': { code: '5W', name: 'Astraeus' },
    '5X': { code: '5X', name: 'Ups' },
    '5O': { code: '5O', name: '5O' },
    '5H': { code: '5H', name: '5H' },
    '5Z': { code: '5Z', name: 'Bismillah Airlines' },
    '6B': { code: '6B', name: 'Britannia Airways' },
    '6G': { code: '6G', name: 'Air Wales' },
    '6H': { code: '6H', name: 'Israir Airlines' },
    '6J': { code: '6J', name: 'Skynet Asia Airways' },
    '6L': { code: '6L', name: 'Aklak' },
    '6U': { code: '6U', name: 'Air Ukraine' },
    '6W': { code: '6W', name: 'Saratov Airlines' },
    '7C': { code: '7C', name: 'Jeju Air' },
    '7F': { code: '7F', name: 'First Air' },
    '7H': { code: '7H', name: 'Era Aviation' },
    '7K': { code: '7K', name: 'Kogalymavia Airlines' },
    '7Q': { code: '7Q', name: 'Pan Am World Airways' },
    '7Y': { code: '7Y', name: '7Y' },
    '7M': { code: '7M', name: '7M' },
    '7I': { code: '7I', name: '7I' },
    '7G': { code: '7G', name: '7G' },
    '7T': { code: '7T', name: 'Aero Express' },
    '7U': { code: '7U', name: 'Aviaenergo' },
    '8A': { code: '8A', name: 'Atlas Blue' },
    '8L': { code: '8L', name: 'Lucky Air' },
    '8M': { code: '8M', name: 'Myanmar Airways' },
    '8P': { code: '8P', name: 'Pacific Coastal Airlines' },
    '8Q': { code: '8Q', name: '8Q' },
    '8I': { code: '8I', name: '8I' },
    '8T': { code: '8T', name: 'Air Tindi' },
    '8U': { code: '8U', name: 'Afriqiyah Airways' },
    '9B': { code: '9B', name: 'Accesrail' },
    '9D': { code: '9D', name: 'Toumai Air Tchad' },
    '9E': { code: '9E', name: 'Pinnacle Airlines' },
    '9F': { code: '9F', name: 'Eurostar' },
    '9I': { code: '9I', name: '9I' },
    '9K': { code: '9K', name: '9K' },
    '9L': { code: '9L', name: 'Colgan Air' },
    '9M': { code: '9M', name: 'Central Mountain Air' },
    '9S': { code: '9S', name: 'Spring Airlines ' },
    '9U': { code: '9U', name: 'Air Moldova' },
    '9W': { code: '9W', name: 'Jet Airways' },
    A1: { code: 'A1', name: 'G2 Switch Works' },
    A3: { code: 'A3', name: 'Aegean Airline' },
    A5: { code: 'A5', name: 'Airlinair' },
    A6: { code: 'A6', name: 'Aaa - Air Alps Aviation' },
    A9: { code: 'A9', name: 'Georgian Airways' },
    AA: { code: 'AA', name: 'American Airlines' },
    AB: { code: 'AB', name: 'Air Berlin' },
    AC: { code: 'AC', name: 'Air Canada' },
    AD: { code: 'AD', name: 'AD' },
    AE: { code: 'AE', name: 'Mandarin Airlines' },
    AF: { code: 'AF', name: 'Air France' },
    AG: { code: 'AG', name: 'Air Contractors' },
    AH: { code: 'AH', name: 'Air Algerie' },
    AI: { code: 'AI', name: 'AI' },
    AM: { code: 'AM', name: 'Aeromexico' },
    AN: { code: 'AN', name: 'AN' },
    AR: { code: 'AR', name: 'Aerolineas Argentinas' },
    AS: { code: 'AS', name: 'Alaska Airlines' },
    AT: { code: 'AT', name: 'Royal Air Maroc' },
    AU: { code: 'AU', name: 'Austral Lineas Aereas' },
    AV: { code: 'AV', name: 'Avianca - Aerovias ' },
    AX: { code: 'AX', name: 'Trans States Airlines' },
    AY: { code: 'AY', name: 'Finnair' },
    AZ: { code: 'AZ', name: 'Alitalia' },
    B0: { code: 'B0', name: 'B0' },
    B1: { code: 'B1', name: 'Cions Software' },
    B2: { code: 'B2', name: 'Belavia' },
    B5: { code: 'B5', name: 'B5' },
    B6: { code: 'B6', name: 'Jetblue Airways' },
    B7: { code: 'B7', name: 'Uni Airways Corporation' },
    B8: { code: 'B8', name: 'Eritrean Airlines' },
    B9: { code: 'B9', name: 'Iran Air Tours' },
    BA: { code: 'BA', name: 'British Airways' },
    BB: { code: 'BB', name: 'BB' },
    BD: { code: 'BD', name: 'BMI' },
    BE: { code: 'BE', name: 'BE' },
    BG: { code: 'BG', name: 'Biman Bangladesh Airlines' },
    BH: { code: 'BH', name: 'Hawkair Aviation' },
    BI: { code: 'BI', name: 'Royal Brunei Airlines' },
    BK: { code: 'BK', name: 'Okay Airways' },
    BM: { code: 'BM', name: 'BM' },
    BP: { code: 'BP', name: 'Air Botswana' },
    BT: { code: 'BT', name: 'Air Baltic' },
    BV: { code: 'BV', name: 'Blue Panorama Airlines Spa' },
    BW: { code: 'BW', name: 'Caribbean Airlines' },
    BX: { code: 'BX', name: 'BX' },
    BY: { code: 'BY', name: 'Thomsonfly' },
    C5: { code: 'C5', name: 'Champlain Enterprises' },
    CA: { code: 'CA', name: 'Air China' },
    CC: { code: 'CC', name: 'Macair Airlines' },
    CD: { code: 'CD', name: 'Alliance Air' },
    CF: { code: 'CF', name: 'City Airline Ab' },
    CG: { code: 'CG', name: 'Airlines Of Papua New Guinea' },
    CH: { code: 'CH', name: 'Bemidji Aviation Services' },
    CJ: { code: 'CJ', name: 'CJ' },
    CK: { code: 'CK', name: 'China Cargo Airlines' },
    CL: { code: 'CL', name: 'Lufthansa' },
    CM: { code: 'CM', name: 'Copa Airlines' },
    CN: { code: 'CN', name: 'CN' },
    CO: { code: 'CO', name: 'Continental Airlines' },
    CP: { code: 'CP', name: 'CP' },
    CS: { code: 'CS', name: 'Continental Micronesia' },
    CT: { code: 'CT', name: 'CT' },
    CU: { code: 'CU', name: 'Cubana De Aviacion' },
    CW: { code: 'CW', name: 'Air Marshall' },
    CY: { code: 'CY', name: 'Cyprus Airways' },
    CZ: { code: 'CZ', name: 'China Southern Airlines' },
    D2: { code: 'D2', name: 'Severstal Aircompany' },
    D3: { code: 'D3', name: 'Daallo Airlines' },
    D6: { code: 'D6', name: 'Inter-aviation Services' },
    D8: { code: 'D8', name: 'D8' },
    D9: { code: 'D9', name: 'D9' },
    DB: { code: 'DB', name: 'Brit Air' },
    DC: { code: 'DC', name: 'DC' },
    DE: { code: 'DE', name: 'Condor Flugdienst' },
    DH: { code: 'DH', name: 'Independence Air' },
    DL: { code: 'DL', name: 'Delta Air Lines' },
    DM: { code: 'DM', name: 'Sterling Blue' },
    DP: { code: 'DP', name: 'First Choice Airways' },
    DT: { code: 'DT', name: 'DT' },
    DV: { code: 'DV', name: 'Jsc Aircompany Scat' },
    DW: { code: 'DW', name: 'Aero-charter' },
    DX: { code: 'DX', name: 'Danish Air Transport' },
    DY: { code: 'DY', name: 'Norwegian Air Shuttle' },
    E2: { code: 'E2', name: 'E2' },
    E6: { code: 'E6', name: 'Bringer Air Cargo Taxi Aereo' },
    EB: { code: 'EB', name: 'Pullmantur Air' },
    EC: { code: 'EC', name: 'EC' },
    ED: { code: 'ED', name: 'ED' },
    EE: { code: 'EE', name: 'EE' },
    EH: { code: 'EH', name: 'EH' },
    EI: { code: 'EI', name: 'Aer Lingus' },
    EK: { code: 'EK', name: 'Emirates' },
    EL: { code: 'EL', name: 'Air Nippon' },
    EM: { code: 'EM', name: 'Aero Benin' },
    EN: { code: 'EN', name: 'Air Dolomiti' },
    EO: { code: 'EO', name: 'Hewa Bora Airways' },
    EP: { code: 'EP', name: 'Aseman Airlines' },
    EQ: { code: 'EQ', name: 'Tame Linea Aerea Del Ecuador' },
    ER: { code: 'ER', name: 'Astar Air Cargo' },
    ES: { code: 'ES', name: 'Dhl International' },
    ET: { code: 'ET', name: 'Ethiopian Airlines' },
    EV: { code: 'EV', name: 'Atlantic Southeast Airlines' },
    EW: { code: 'EW', name: 'Eurowings' },
    EY: { code: 'EY', name: 'Etihad Airways' },
    EZ: { code: 'EZ', name: 'EZ' },
    F1: { code: 'F1', name: 'Farelogix' },
    F7: { code: 'F7', name: 'F7' },
    F9: { code: 'F9', name: 'Frontier Airlines' },
    FB: { code: 'FB', name: 'Bulgaria Air' },
    FC: { code: 'FC', name: 'Finnish Commuter Airlines' },
    FD: { code: 'FD', name: 'Thai Airasia' },
    FG: { code: 'FG', name: 'Ariana Afghan Airlines' },
    FI: { code: 'FI', name: 'Icelandair' },
    FJ: { code: 'FJ', name: 'Air Pacific' },
    FL: { code: 'FL', name: 'Airtran Airways' },
    FM: { code: 'FM', name: 'Shanghai Airlines' },
    FN: { code: 'FN', name: 'Regional Air Lines' },
    FR: { code: 'FR', name: 'Ryanair Ltd' },
    FS: { code: 'FS', name: 'FS' },
    FT: { code: 'FT', name: 'Siem Reap Airways ' },
    FV: { code: 'FV', name: 'Rossiya-Russian Airlines' },
    FW: { code: 'FW', name: 'FW' },
    FX: { code: 'FX', name: 'Fedex' },
    FY: { code: 'FY', name: 'FY' },
    FZ: { code: 'FZ', name: 'FZ' },
    G0: { code: 'G0', name: 'Ghana International Airlines' },
    G2: { code: 'G2', name: 'Avirex Gabon' },
    G3: { code: 'G3', name: 'G3' },
    G4: { code: 'G4', name: 'Allegiant Air' },
    G5: { code: 'G5', name: 'Joint Stock Company Enkor' },
    G6: { code: 'G6', name: 'Angkor Airways Corporation' },
    GA: { code: 'GA', name: 'Garuda Indonesia' },
    GC: { code: 'GC', name: 'Gambia Intl Airlines' },
    GE: { code: 'GE', name: 'Transasia Airways' },
    GF: { code: 'GF', name: 'Gulf Air Company' },
    GH: { code: 'GH', name: 'GH' },
    GI: { code: 'GI', name: 'Air Company Itek-air' },
    GK: { code: 'GK', name: 'GK' },
    GL: { code: 'GL', name: 'Air Greenland' },
    GN: { code: 'GN', name: 'Air Gabon' },
    GO: { code: 'GO', name: 'Kuzu Airways Cargo Transport' },
    GR: { code: 'GR', name: 'Aurigny Air Services' },
    GS: { code: 'GS', name: 'GS' },
    GU: { code: 'GU', name: 'Aviateca S.a.' },
    GP: { code: 'GP', name: 'GP' },
    GW: { code: 'GW', name: 'Air Lines Of Kuban' },
    GZ: { code: 'GZ', name: 'Air Rarotonga' },
    H1: { code: 'H1', name: 'Hahn Air' },
    H2: { code: 'H2', name: 'Sky Service' },
    H9: { code: 'H9', name: 'H9' },
    HA: { code: 'HA', name: 'Hawaiian Airlines' },
    HD: { code: 'HD', name: 'HD' },
    HE: { code: 'HE', name: 'HE' },
    HF: { code: 'HF', name: 'HF' },
    HG: { code: 'HG', name: 'HG' },
    HI: { code: 'HI', name: 'Papillon Airways' },
    HM: { code: 'HM', name: 'Air Seychelles' },
    HO: { code: 'HO', name: 'HO' },
    HQ: { code: 'HQ', name: 'Harmony Airways' },
    HR: { code: 'HR', name: 'Hahn Air' },
    HU: { code: 'HU', name: 'HU' },
    HV: { code: 'HV', name: 'Transavia Airlines' },
    HX: { code: 'HX', name: 'Hong Kong Airlines' },
    HY: { code: 'HY', name: 'Uzbekistan Airways' },
    HZ: { code: 'HZ', name: 'Sakhalinskie Aviatrassy' },
    I2: { code: 'I2', name: 'I2' },
    I9: { code: 'I9', name: 'I9' },
    IA: { code: 'IA', name: 'Iraqi Airways' },
    IB: { code: 'IB', name: 'Iberi' },
    ID: { code: 'ID', name: 'ID' },
    IE: { code: 'IE', name: 'Solomon Airlines' },
    IF: { code: 'IF', name: 'Islas Airways' },
    IG: { code: 'IG', name: 'IG' },
    IJ: { code: 'IJ', name: 'Great Wall Airlines' },
    IQ: { code: 'IQ', name: 'Augsburg Airways' },
    IR: { code: 'IR', name: 'Iran Air' },
    IT: { code: 'IT', name: 'Kingfisher Airlines' },
    IX: { code: 'IX', name: 'IX' },
    IY: { code: 'IY', name: 'Yemenia Yemen Airways' },
    IZ: { code: 'IZ', name: 'Arkia-israeli Airlines' },
    J0: { code: 'J0', name: 'Jet Link Express' },
    J2: { code: 'J2', name: 'Azerbaijan Hava Yollary' },
    J3: { code: 'J3', name: 'Northwestern Air Lease' },
    J7: { code: 'J7', name: 'Joint Stock Centre-avia' },
    J8: { code: 'J8', name: 'Berjaya Air' },
    JC: { code: 'JC', name: 'JC' },
    JD: { code: 'JD', name: 'JD' },
    JE: { code: 'JE', name: 'JE' },
    JF: { code: 'JF', name: 'JF' },
    JJ: { code: 'JJ', name: 'Tam - Linhas Aereas' },
    JK: { code: 'JK', name: 'Spanair' },
    JL: { code: 'JL', name: 'Japan Airlines' },
    JM: { code: 'JM', name: 'Air Jamaica' },
    JN: { code: 'JN', name: 'JN' },
    JO: { code: 'JO', name: 'Jalways' },
    JP: { code: 'JP', name: 'Adria Airways' },
    JQ: { code: 'JQ', name: 'Jetstar Airways' },
    JS: { code: 'JS', name: 'Air Koryo' },
    JU: { code: 'JU', name: 'Jat Airways' },
    JV: { code: 'JV', name: 'Bearskin Lake Air Services' },
    JZ: { code: 'JZ', name: 'Skyways Ab' },
    K1: { code: 'K1', name: 'Topas' },
    K6: { code: 'K6', name: 'K6' },
    K7: { code: 'K7', name: 'K7' },
    KA: { code: 'KA', name: 'Dragon Airlines' },
    KB: { code: 'KB', name: 'Druk Air ' },
    KC: { code: 'KC', name: 'KC' },
    KE: { code: 'KE', name: 'Korean Air' },
    KF: { code: 'KF', name: 'KF' },
    KK: { code: 'KK', name: 'Atlasjet Airlines' },
    KL: { code: 'KL', name: 'Klm Royal Dutch Airlines' },
    KM: { code: 'KM', name: 'Air Malta' },
    KN: { code: 'KN', name: 'China United Airlines' },
    KP: { code: 'KP', name: 'KP' },
    KQ: { code: 'KQ', name: 'Kenya Airways' },
    KS: { code: 'KS', name: 'Penair' },
    KU: { code: 'KU', name: 'Kuwait Airways' },
    KV: { code: 'KV', name: 'State United Venture Kavminvodyavia' },
    KX: { code: 'KX', name: 'Cayman Airways' },
    KY: { code: 'KY', name: 'KY' },
    L2: { code: 'L2', name: 'Lynden Air Cargo' },
    L6: { code: 'L6', name: 'Tbilaviamsheni' },
    LA: { code: 'LA', name: 'Lan Airlines' },
    LC: { code: 'LC', name: 'LC' },
    LG: { code: 'LG', name: 'Luxair' },
    LH: { code: 'LH', name: 'Deutsche Lufthansa' },
    LI: { code: 'LI', name: 'Liat ' },
    LJ: { code: 'LJ', name: 'LJ' },
    LK: { code: 'LK', name: 'Air Luxor' },
    LL: { code: 'LL', name: 'Lineas Aereas Allegro' },
    LM: { code: 'LM', name: 'Livingston' },
    LN: { code: 'LN', name: 'Libyan Arab Airlines' },
    LO: { code: 'LO', name: 'Lot Polish Airlines' },
    LP: { code: 'LP', name: 'Lan Peru' },
    LR: { code: 'LR', name: 'Lineas Aereas Costarricenses' },
    LV: { code: 'LV', name: 'Albanian Airlines' },
    LW: { code: 'LW', name: 'Pacific Wings' },
    LX: { code: 'LX', name: 'Swiss International Airlines' },
    LY: { code: 'LY', name: 'El Al Israel Airlines' },
    LZ: { code: 'LZ', name: 'LZ' },
    M5: { code: 'M5', name: 'Kenmore Air' },
    M6: { code: 'M6', name: 'Amerijet International' },
    M8: { code: 'M8', name: 'Mekong Airlines' },
    M9: { code: 'M9', name: 'Motor-sich Jsc' },
    MA: { code: 'MA', name: 'Malev ' },
    MD: { code: 'MD', name: 'Air Madagascar' },
    ME: { code: 'ME', name: 'Middle East Airlines Airliban' },
    MF: { code: 'MF', name: 'Xiamen Airlines' },
    MH: { code: 'MH', name: 'Malaysia Airline' },
    MI: { code: 'MI', name: 'Silkair' },
    MJ: { code: 'MJ', name: 'MJ' },
    MK: { code: 'MK', name: 'Air Mauritius' },
    MM: { code: 'MM', name: 'Sociedad Aeronautica' },
    MN: { code: 'MN', name: 'MN' },
    MO: { code: 'MO', name: 'Calm Air International' },
    MP: { code: 'MP', name: 'Martinair Holland' },
    MQ: { code: 'MQ', name: 'MQ' },
    MS: { code: 'MS', name: 'Egyptair' },
    MT: { code: 'MT', name: 'MT' },
    MU: { code: 'MU', name: 'China Eastern Airlines' },
    MV: { code: 'MV', name: 'Armenian International Airways' },
    MW: { code: 'MW', name: 'MW' },
    MX: { code: 'MX', name: 'Mexicana De Aviacion' },
    N4: { code: 'N4', name: 'N4' },
    N7: { code: 'N7', name: 'N7' },
    ND: { code: 'ND', name: 'ND' },
    NF: { code: 'NF', name: 'Air Vanuatu' },
    NG: { code: 'NG', name: 'Lauda Air' },
    NH: { code: 'NH', name: 'All Nippon Airways' },
    NI: { code: 'NI', name: 'Portugalia Airlines' },
    NK: { code: 'NK', name: 'Spirit Airlines' },
    NN: { code: 'NN', name: 'NN' },
    NP: { code: 'NP', name: 'NP' },
    NQ: { code: 'NQ', name: 'Air Japan Company' },
    NT: { code: 'NT', name: 'Binter Canarias' },
    NU: { code: 'NU', name: 'Japan Transocean' },
    NX: { code: 'NX', name: 'Air Macau' },
    NY: { code: 'NY', name: 'Air Iceland' },
    NZ: { code: 'NZ', name: 'Air New Zealand' },
    O6: { code: 'O6', name: 'Oceanair Linhas Aereas' },
    O8: { code: 'O8', name: 'O8' },
    OA: { code: 'OA', name: 'OA' },
    OB: { code: 'OB', name: 'OB' },
    OC: { code: 'OC', name: 'OC' },
    OD: { code: 'OD', name: 'Malindo Air' },
    OE: { code: 'OE', name: 'Asia Overnight Express' },
    OF: { code: 'OF', name: 'Air Finland' },
    OG: { code: 'OG', name: 'One Two Go ' },
    OH: { code: 'OH', name: 'Comair' },
    OK: { code: 'OK', name: 'Czech Airlines' },
    OL: { code: 'OL', name: 'Olt-osfriesische' },
    OM: { code: 'OM', name: 'Mongolian Airlines' },
    ON: { code: 'ON', name: 'Our Airline' },
    OO: { code: 'OO', name: 'Skywest Airlines' },
    OQ: { code: 'OQ', name: 'OQ' },
    OS: { code: 'OS', name: 'Austrian' },
    OU: { code: 'OU', name: 'Croatia Airlines' },
    OV: { code: 'OV', name: 'Estonian Air' },
    OX: { code: 'OX', name: 'Orient Thai Airlines' },
    OZ: { code: 'OZ', name: 'Asiana Airlines' },
    P0: { code: 'P0', name: 'P0' },
    P1: { code: 'P1', name: 'P1' },
    P5: { code: 'P5', name: 'Aero Republica' },
    P9: { code: 'P9', name: 'P9' },
    PB: { code: 'PB', name: 'Provincial Airlines' },
    PC: { code: 'PC', name: 'PC' },
    PD: { code: 'PD', name: 'PD' },
    PG: { code: 'PG', name: 'Bangkok Airways' },
    PJ: { code: 'PJ', name: 'Air Saint-pierre' },
    PK: { code: 'PK', name: 'Pakistan Intl Airlines' },
    PO: { code: 'PO', name: 'Polar Air Cargo' },
    PR: { code: 'PR', name: 'Philippine Airlines' },
    PS: { code: 'PS', name: 'Ukraine International Airlines' },
    PT: { code: 'PT', name: 'West Air Sweden' },
    PU: { code: 'PU', name: 'Pluna Lineas' },
    PW: { code: 'PW', name: 'Precision Air Services' },
    PX: { code: 'PX', name: 'Air Niugini' },
    PY: { code: 'PY', name: 'Surinam Airways' },
    PZ: { code: 'PZ', name: 'Tam-transportes Aereos Del Mercosur' },
    Q8: { code: 'Q8', name: 'Trans Air Congo' },
    QA: { code: 'QA', name: 'QA' },
    QC: { code: 'QC', name: 'QC' },
    QD: { code: 'QD', name: 'QD' },
    QE: { code: 'QE', name: 'Europe Continental Airways' },
    QF: { code: 'QF', name: 'Qantas Airways' },
    QG: { code: 'QG', name: 'QG' },
    QJ: { code: 'QJ', name: 'Latpass Airlines' },
    QK: { code: 'QK', name: 'Air Canada Jazz' },
    QM: { code: 'QM', name: 'Air Malawi' },
    QN: { code: 'QN', name: 'Air Armenia' },
    QP: { code: 'QP', name: 'Airkenya Aviation' },
    QQ: { code: 'QQ', name: 'Alliance Airlines' },
    QS: { code: 'QS', name: 'QS' },
    QV: { code: 'QV', name: 'Lao Airlines' },
    QX: { code: 'QX', name: 'Horizon Air' },
    QY: { code: 'QY', name: 'European Air Transport' },
    QZ: { code: 'QZ', name: 'Pt Indonesia Airasia' },
    R0: { code: 'R0', name: 'M/s Royal Airlines' },
    R2: { code: 'R2', name: 'R2' },
    R3: { code: 'R3', name: 'Yakutia Jsc Aircompany' },
    R7: { code: 'R7', name: 'Aserca Airlines' },
    R8: { code: 'R8', name: 'Kyrghyzstan Airlines' },
    RA: { code: 'RA', name: 'RA' },
    RB: { code: 'RB', name: 'Syrian Arab Airlines' },
    RD: { code: 'RD', name: 'Ryan Intl Airlines' },
    RE: { code: 'RE', name: 'Aer Arann' },
    RF: { code: 'RF', name: 'Florida West Intl Airways' },
    RJ: { code: 'RJ', name: 'Royal Jordanian' },
    RO: { code: 'RO', name: 'Tarom-romanian Air Transport' },
    RP: { code: 'RP', name: 'Chautauqua Airlines' },
    RQ: { code: 'RQ', name: 'Kam Air' },
    RS: { code: 'RS', name: 'RS' },
    RU: { code: 'RU', name: 'Tci Skyking' },
    RZ: { code: 'RZ', name: 'Servicios Aereos' },
    S3: { code: 'S3', name: 'Santa Barbara Airlines' },
    S4: { code: 'S4', name: 'Sata Internacional' },
    S5: { code: 'S5', name: 'Shuttle America' },
    S7: { code: 'S7', name: 'Siberia Airlines' },
    SA: { code: 'SA', name: 'South African Airways' },
    SB: { code: 'SB', name: 'Aircalin' },
    SC: { code: 'SC', name: 'Shandong Airlines' },
    SD: { code: 'SD', name: 'Sudan Airways' },
    SE: { code: 'SE', name: 'Xl Airways' },
    SF: { code: 'SF', name: 'Tassili Airlines' },
    SG: { code: 'SG', name: 'SG' },
    SK: { code: 'SK', name: 'Scandinavian ' },
    SL: { code: 'SL', name: 'SL' },
    SN: { code: 'SN', name: 'SN' },
    SP: { code: 'SP', name: 'Sata Air Acores' },
    SS: { code: 'SS', name: 'Corsair' },
    ST: { code: 'ST', name: 'ST' },
    SU: { code: 'SU', name: 'Aeroflot ' },
    SV: { code: 'SV', name: 'Saudi Arabian Airlines' },
    SW: { code: 'SW', name: 'Air Namibia' },
    SX: { code: 'SX', name: 'SX' },
    SY: { code: 'SY', name: 'Sun Country Airlines' },
    T3: { code: 'T3', name: 'Eastern Airways' },
    T5: { code: 'T5', name: 'Turkmenistan Airlines' },
    T7: { code: 'T7', name: 'Twin Jet' },
    TA: { code: 'TA', name: 'Taca International Airlines ' },
    TB: { code: 'TB', name: 'TB' },
    TC: { code: 'TC', name: 'Air Tanzania' },
    TD: { code: 'TD', name: 'Atlantis European Airways' },
    TF: { code: 'TF', name: 'Malmo Aviation' },
    TJ: { code: 'TJ', name: 'Transportes Aereos Nacionales De Selva' },
    TL: { code: 'TL', name: 'Airnorth Regional' },
    TM: { code: 'TM', name: 'Lam ' },
    TN: { code: 'TN', name: 'Air Tahiti Nui' },
    TP: { code: 'TP', name: 'Tap Air Portugal' },
    TQ: { code: 'TQ', name: 'Tandem Aero' },
    TS: { code: 'TS', name: 'Air Transat' },
    TU: { code: 'TU', name: 'Tunis Air' },
    TW: { code: 'TW', name: 'TW' },
    TX: { code: 'TX', name: 'Air Caraibes' },
    TY: { code: 'TY', name: 'Air Caledonie' },
    TZ: { code: 'TZ', name: 'Scoot' },
    U2: { code: 'U2', name: 'U2' },
    U6: { code: 'U6', name: 'Ural Airlines' },
    U8: { code: 'U8', name: 'Armavia' },
    U9: { code: 'U9', name: 'Tatarstan State Enterprise Aircompany' },
    UA: { code: 'UA', name: 'United Airlines' },
    UD: { code: 'UD', name: 'Hex Air' },
    UF: { code: 'UF', name: 'Ukrainian Mediterranean Airlines' },
    UK: { code: 'UK', name: 'UK' },
    UL: { code: 'UL', name: 'Srilankan Airlines' },
    UM: { code: 'UM', name: 'Air Zimbabwe' },
    UN: { code: 'UN', name: 'Transaero Airlines' },
    UO: { code: 'UO', name: 'Hong Kong Express Airways' },
    UP: { code: 'UP', name: 'Bahamasair' },
    UR: { code: 'UR', name: 'UR' },
    US: { code: 'US', name: 'Us Airways' },
    UT: { code: 'UT', name: 'UT' },
    UU: { code: 'UU', name: 'Air Austral' },
    UV: { code: 'UV', name: 'Helicopteros Del Sureste S.a.' },
    UW: { code: 'UW', name: 'Universal Airlines' },
    UX: { code: 'UX', name: 'Air Europa Lineas' },
    UY: { code: 'UY', name: 'Cameroon Airlines' },
    UZ: { code: 'UZ', name: 'Buraq Air Transport' },
    V0: { code: 'V0', name: 'Conviasa' },
    V3: { code: 'V3', name: 'Carpatair' },
    V6: { code: 'V6', name: 'Vip' },
    VA: { code: 'VA', name: 'VA' },
    VC: { code: 'VC', name: 'VC' },
    VF: { code: 'VF', name: 'Valuair' },
    VH: { code: 'VH', name: 'Aeropostal' },
    VL: { code: 'VL', name: 'VL' },
    VO: { code: 'VO', name: 'VO' },
    VR: { code: 'VR', name: 'Tranportes Aereos De Cabo Verde' },
    VS: { code: 'VS', name: 'Virgin Atlantic Airways' },
    VT: { code: 'VT', name: 'Air Tahiti' },
    VU: { code: 'VU', name: 'Air Ivoire' },
    VV: { code: 'VV', name: 'Aerosvit Airlines' },
    VW: { code: 'VW', name: 'Transportes Aeromar' },
    VX: { code: 'VX', name: 'Virgin America' },
    VY: { code: 'VY', name: 'VY' },
    VZ: { code: 'VZ', name: 'Mytravel Airways' },
    W2: { code: 'W2', name: 'W2' },
    W3: { code: 'W3', name: 'W3' },
    W4: { code: 'W4', name: 'W4' },
    W5: { code: 'W5', name: 'Mahan Airlines' },
    WB: { code: 'WB', name: 'Rwandair Express' },
    WC: { code: 'WC', name: 'Islena De Inversiones' },
    WE: { code: 'WE', name: 'WE' },
    WF: { code: 'WF', name: 'Wideroes Flyveselskap' },
    WI: { code: 'WI', name: 'WI' },
    WK: { code: 'WK', name: 'WK' },
    WL: { code: 'WL', name: 'Aeroperlas' },
    WM: { code: 'WM', name: 'Windward Island Airways' },
    WN: { code: 'WN', name: 'Southwest Airlines ' },
    WD: { code: 'WO', name: 'World Airways' },
    WP: { code: 'WP', name: 'Island Air' },
    WQ: { code: 'WQ', name: 'Romanian Aviation' },
    WR: { code: 'WR', name: 'WR' },
    WS: { code: 'WS', name: 'Westjet' },
    WW: { code: 'WW', name: 'WW' },
    WX: { code: 'WX', name: 'Cityjet' },
    WY: { code: 'WY', name: 'WY' },
    X3: { code: 'X3', name: 'X3' },
    X8: { code: 'X8', name: 'Icaro' },
    X9: { code: 'X9', name: 'City Star Airlines' },
    XB: { code: 'XB', name: 'Iata Bsp' },
    XF: { code: 'XF', name: 'Vladivostok Air' },
    XG: { code: 'XG', name: 'XG' },
    XJ: { code: 'XJ', name: 'Mesaba Aviation' },
    XK: { code: 'XK', name: 'Ccm Airlines' },
    XL: { code: 'XL', name: 'Lanecuador Aerolane' },
    XM: { code: 'XM', name: 'XM' },
    XO: { code: 'XO', name: 'China Xinjiang Airlines' },
    XP: { code: 'XP', name: 'T.e.m. Enterprises' },
    XQ: { code: 'XQ', name: 'Sunexpress' },
    XR: { code: 'XR', name: 'Skywest Airlines' },
    XS: { code: 'XS', name: 'Sita-airlines Worldwide' },
    XW: { code: 'XW', name: 'XW' },
    XY: { code: 'XY', name: 'XY' },
    XZ: { code: 'XZ', name: 'XZ' },
    YC: { code: 'YC', name: 'YC' },
    YG: { code: 'YG', name: 'South Airlines' },
    YI: { code: 'YI', name: 'Air Sunshine' },
    YM: { code: 'YM', name: 'Montenegro Airlines' },
    YN: { code: 'YN', name: 'Air Creebe' },
    YO: { code: 'YO', name: 'Heli Air Monaco' },
    YP: { code: 'YP', name: 'Aero Lloyd Flugreisen' },
    YQ: { code: 'YQ', name: 'Aircompany Polet' },
    YS: { code: 'YS', name: 'Regional Compagnie Aerienne Europeene' },
    YU: { code: 'YU', name: 'YU' },
    YV: { code: 'YV', name: 'Mesa Airlines' },
    YW: { code: 'YW', name: 'Air Nostrum' },
    YX: { code: 'YX', name: 'YX' },
    Z5: { code: 'Z5', name: 'Gmg Airlines' },
    Z6: { code: 'Z6', name: 'Dnieproavia' },
    Z7: { code: 'Z7', name: 'Z7' },
    Z8: { code: 'Z8', name: 'Amaszonas' },
    ZA: { code: 'ZA', name: 'ZA' },
    ZB: { code: 'ZB', name: 'Monarch Airlines' },
    ZH: { code: 'ZH', name: 'Shenzhen Airlines' },
    ZI: { code: 'ZI', name: 'Aigle Azur' },
    ZK: { code: 'ZK', name: 'Great Lakes Aviation' },
    ZL: { code: 'ZL', name: 'Regional Express' },
    ZP: { code: 'ZP', name: 'Silk Way Airlines' },
    ZT: { code: 'ZT', name: 'ZT' },
    ZW: { code: 'ZW', name: 'Air Wisconsin' },
    ZX: { code: 'ZX', name: 'ZX' },
    ZZ: { code: 'ZZ', name: 'ZZ' }
}

export const domesticAirport = [
    {
        name: 'Sân bay quốc tế Nội Bài',
        code: 'HAN',
        province_id: 66,
        created_at: '2017-05-18T02:59:35.061Z',
        updated_at: '2018-05-11T13:36:29.089Z',
        type: 'domestic',
        region: 1,
        province_name: 'Hà Nội',
        has_in_atadi: true,
        region_name: 'North VN',
        region_name_vi: 'Miền Bắc'
    },
    {
        name: 'Sân bay quốc tế Cát Bi',
        code: 'HPH',
        province_id: 70,
        created_at: '2017-05-18T02:59:35.061Z',
        updated_at: '2018-05-11T13:36:29.097Z',
        type: 'domestic',
        region: 1,
        province_name: 'Hải Phòng',
        has_in_atadi: true,
        region_name: 'North VN',
        region_name_vi: 'Miền Bắc'
    },

    {
        name: 'Sân bay quốc tế Cam Ranh',
        code: 'CXR',
        province_id: 86,
        created_at: '2017-05-18T02:59:35.061Z',
        updated_at: '2018-05-11T13:36:29.096Z',
        type: 'domestic',
        region: 2,
        province_name: 'Nha Trang',
        has_in_atadi: true,
        region_name: 'Central VN',
        region_name_vi: 'Miền Trung'
    },

    {
        name: 'Sân bay quốc tế Đà Nẵng',
        code: 'DAD',
        province_id: 68,
        created_at: '2017-05-18T02:59:35.061Z',
        updated_at: '2018-05-11T13:36:29.096Z',
        type: 'domestic',
        region: 2,
        province_name: 'Đà Nẵng',
        has_in_atadi: true,
        region_name: 'Central VN',
        region_name_vi: 'Miền Trung'
    },

    {
        name: 'Sân bay quốc tế Vinh',
        code: 'VII',
        province_id: 95,
        created_at: '2017-05-18T02:59:35.061Z',
        updated_at: '2018-05-11T13:36:29.114Z',
        type: 'domestic',
        region: 2,
        province_name: 'Nghệ An',
        has_in_atadi: true,
        region_name: 'Central VN',
        region_name_vi: 'Miền Trung'
    },
    {
        name: 'Sân bay Thọ Xuân',
        code: 'THD',
        province_id: 109,
        created_at: '2017-05-18T02:59:35.061Z',
        updated_at: '2018-05-11T13:36:29.110Z',
        type: 'domestic',
        region: 2,
        province_name: 'Thanh Hóa',
        has_in_atadi: true,
        region_name: 'Central VN',
        region_name_vi: 'Miền Trung'
    },

    {
        name: 'Sân bay quốc tế Tân Sơn Nhất',
        code: 'SGN',
        province_id: 67,
        created_at: '2017-05-18T02:59:35.061Z',
        updated_at: '2018-05-11T13:36:29.089Z',
        type: 'domestic',
        region: 3,
        province_name: 'TP Hồ Chí Minh',
        has_in_atadi: true,
        region_name: 'Southern VN',
        region_name_vi: 'Miền Nam'
    },

    {
        name: 'Sân bay quốc tế Phú Quốc',
        code: 'PQC',
        province_id: 87,
        created_at: '2017-05-18T02:59:35.061Z',
        updated_at: '2018-05-11T13:36:29.098Z',
        type: 'domestic',
        region: 3,
        province_name: 'Phú Quốc',
        has_in_atadi: true,
        region_name: 'Southern VN',
        region_name_vi: 'Miền Nam'
    },
]


// export const DOMESTIC_AIRPORT = [
//     {
//         name: 'Sân bay quốc tế Nội Bài',
//         code: 'HAN',
//         province_id: 66,
//         created_at: '2017-05-18T02:59:35.061Z',
//         updated_at: '2018-05-11T13:36:29.089Z',
//         type: 'domestic',
//         region: 1,
//         province_name: 'Hà Nội',
//         has_in_atadi: true,
//         region_name: 'North VN',
//         region_name_vi: 'Miền Bắc'
//     },
//     {
//         name: 'Sân bay quốc tế Tân Sơn Nhất',
//         code: 'SGN',
//         province_id: 67,
//         created_at: '2017-05-18T02:59:35.061Z',
//         updated_at: '2018-05-11T13:36:29.089Z',
//         type: 'domestic',
//         region: 3,
//         province_name: 'TP Hồ Chí Minh',
//         has_in_atadi: true,
//         region_name: 'Southern VN',
//         region_name_vi: 'Miền Nam'
//     },
//     {
//         name: 'Sân bay quốc tế Đà Nẵng',
//         code: 'DAD',
//         province_id: 68,
//         created_at: '2017-05-18T02:59:35.061Z',
//         updated_at: '2018-05-11T13:36:29.096Z',
//         type: 'domestic',
//         region: 2,
//         province_name: 'Đà Nẵng',
//         has_in_atadi: true,
//         region_name: 'Central VN',
//         region_name_vi: 'Miền Trung'
//     },
//     {
//         name: 'Sân bay Điện Biên Phủ',
//         code: 'DIN',
//         province_id: 75,
//         created_at: '2017-05-18T02:59:35.061Z',
//         updated_at: '2018-05-11T13:36:29.098Z',
//         type: 'domestic',
//         region: 1,
//         province_name: 'Điện Biên',
//         has_in_atadi: true,
//         region_name: 'North VN',
//         region_name_vi: 'Miền Bắc'
//     },
//     {
//         name: 'Sân bay quốc tế Cát Bi',
//         code: 'HPH',
//         province_id: 70,
//         created_at: '2017-05-18T02:59:35.061Z',
//         updated_at: '2018-05-11T13:36:29.097Z',
//         type: 'domestic',
//         region: 1,
//         province_name: 'Hải Phòng',
//         has_in_atadi: true,
//         region_name: 'North VN',
//         region_name_vi: 'Miền Bắc'
//     },
//     {
//         name: 'Sân bay Thọ Xuân',
//         code: 'THD',
//         province_id: 109,
//         created_at: '2017-05-18T02:59:35.061Z',
//         updated_at: '2018-05-11T13:36:29.110Z',
//         type: 'domestic',
//         region: 2,
//         province_name: 'Thanh Hóa',
//         has_in_atadi: true,
//         region_name: 'Central VN',
//         region_name_vi: 'Miền Trung'
//     },
//     {
//         name: 'Sân bay quốc tế Vinh',
//         code: 'VII',
//         province_id: 95,
//         created_at: '2017-05-18T02:59:35.061Z',
//         updated_at: '2018-05-11T13:36:29.114Z',
//         type: 'domestic',
//         region: 2,
//         province_name: 'Nghệ An',
//         has_in_atadi: true,
//         region_name: 'Central VN',
//         region_name_vi: 'Miền Trung'
//     },
//     {
//         name: 'Sân bay Đồng Hới',
//         code: 'VDH',
//         province_id: 99,
//         created_at: '2017-05-18T02:59:35.061Z',
//         updated_at: '2018-05-11T13:36:29.113Z',
//         type: 'domestic',
//         region: 2,
//         province_name: 'Quảng Bình',
//         has_in_atadi: true,
//         region_name: 'Central VN',
//         region_name_vi: 'Miền Trung'
//     },
//     {
//         name: 'Sân bay quốc tế Chu Lai',
//         code: 'VCL',
//         province_id: 100,
//         created_at: '2017-05-18T02:59:35.061Z',
//         updated_at: '2018-05-11T13:36:29.111Z',
//         type: 'domestic',
//         region: 2,
//         province_name: 'Quảng Nam',
//         has_in_atadi: true,
//         region_name: 'Central VN',
//         region_name_vi: 'Miền Trung'
//     },
//     {
//         name: 'Sân bay quốc tế Phú Bài',
//         code: 'HUI',
//         province_id: 110,
//         created_at: '2017-05-18T02:59:35.061Z',
//         updated_at: '2018-05-11T13:36:29.097Z',
//         type: 'domestic',
//         region: 2,
//         province_name: ' Huế',
//         has_in_atadi: true,
//         region_name: 'Central VN',
//         region_name_vi: 'Miền Trung'
//     },
//     //
//     {
//         name: 'Sân bay quốc tế Cam Ranh',
//         code: 'CXR',
//         province_id: 86,
//         created_at: '2017-05-18T02:59:35.061Z',
//         updated_at: '2018-05-11T13:36:29.096Z',
//         type: 'domestic',
//         region: 2,
//         province_name: 'Nha Trang',
//         has_in_atadi: true,
//         region_name: 'Central VN',
//         region_name_vi: 'Miền Trung'
//     },
//     {
//         name: 'Sân bay quốc tế Liên Khương',
//         code: 'DLI',
//         province_id: 90,
//         created_at: '2017-05-18T02:59:35.061Z',
//         updated_at: '2018-05-11T13:36:29.097Z',
//         type: 'domestic',
//         region: 2,
//         province_name: 'Đà Lạt',
//         has_in_atadi: true,
//         region_name: 'Central VN',
//         region_name_vi: 'Miền Trung'
//     },
//     {
//         name: 'Sân bay Buôn Ma Thuột',
//         code: 'BMV',
//         province_id: 73,
//         created_at: '2017-05-18T02:59:35.061Z',
//         updated_at: '2018-05-11T13:36:29.104Z',
//         type: 'domestic',
//         region: 2,
//         province_name: 'Đắk Lắk',
//         has_in_atadi: true,
//         region_name: 'Central VN',
//         region_name_vi: 'Miền Trung'
//     },
//     {
//         name: 'Sân bay Pleiku',
//         code: 'PXU',
//         province_id: 78,
//         created_at: '2017-05-18T02:59:35.061Z',
//         updated_at: '2018-05-11T13:36:29.107Z',
//         type: 'domestic',
//         region: 2,
//         province_name: 'Gia Lai',
//         has_in_atadi: true,
//         region_name: 'Central VN',
//         region_name_vi: 'Miền Trung'
//     },
//     {
//         name: 'Sân bay quốc tế Vinh',
//         code: 'VII',
//         province_id: 95,
//         created_at: '2017-05-18T02:59:35.061Z',
//         updated_at: '2018-05-11T13:36:29.114Z',
//         type: 'domestic',
//         region: 2,
//         province_name: 'Nghệ An',
//         has_in_atadi: true,
//         region_name: 'Central VN',
//         region_name_vi: 'Miền Trung'
//     },
//
//     {
//         name: 'Sân bay Tuy Hòa',
//         code: 'TBB',
//         province_id: 117,
//         created_at: '2017-05-18T02:59:35.061Z',
//         updated_at: '2018-05-11T13:36:29.109Z',
//         type: 'domestic',
//         region: 2,
//         province_name: 'Phú Yên',
//         has_in_atadi: true,
//         region_name: 'Central VN',
//         region_name_vi: 'Miền Trung'
//     },
//     {
//         name: 'Sân bay Phù Cát',
//         code: 'UIH',
//         province_id: 125,
//         created_at: '2017-05-18T02:59:35.061Z',
//         updated_at: '2018-05-11T13:36:29.110Z',
//         type: 'domestic',
//         region: 2,
//         province_name: 'Quy Nhơn',
//         has_in_atadi: true,
//         region_name: 'Central VN',
//         region_name_vi: 'Miền Trung'
//     },
//     {
//         name: 'Sân bay quốc tế Tân Sơn Nhất',
//         code: 'SGN',
//         province_id: 67,
//         created_at: '2017-05-18T02:59:35.061Z',
//         updated_at: '2018-05-11T13:36:29.089Z',
//         type: 'domestic',
//         region: 3,
//         province_name: 'TP Hồ Chí Minh',
//         has_in_atadi: true,
//         region_name: 'Southern VN',
//         region_name_vi: 'Miền Nam'
//     },
//     // {
//     //     name: 'Sân bay Rạch Giá',
//     //     code: 'VKG',
//     //     province_id: 87,
//     //     created_at: '2017-05-18T02:59:35.061Z',
//     //     updated_at: '2018-05-11T13:36:29.116Z',
//     //     type: 'domestic',
//     //     region: 3,
//     //     province_name: 'Kiên Giang',
//     //     has_in_atadi: true,
//     //     region_name: 'Southern VN',
//     //     region_name_vi: 'Miền Nam'
//     // },
//     {
//         name: 'Sân bay quốc tế Phú Quốc',
//         code: 'PQC',
//         province_id: 87,
//         created_at: '2017-05-18T02:59:35.061Z',
//         updated_at: '2018-05-11T13:36:29.098Z',
//         type: 'domestic',
//         region: 3,
//         province_name: 'Phú Quốc',
//         has_in_atadi: true,
//         region_name: 'Southern VN',
//         region_name_vi: 'Miền Nam'
//     },
//     {
//         name: 'Sân bay quốc tế Cần Thơ',
//         code: 'VCA',
//         province_id: 69,
//         created_at: '2017-05-18T02:59:35.061Z',
//         updated_at: '2018-05-11T13:36:29.111Z',
//         type: 'domestic',
//         region: 3,
//         province_name: 'Cần Thơ',
//         has_in_atadi: true,
//         region_name: 'Southern VN',
//         region_name_vi: 'Miền Nam'
//     },
//     {
//         name: 'Sân bay Cà Mau',
//         code: 'CAH',
//         province_id: 71,
//         created_at: '2017-05-18T02:59:35.061Z',
//         updated_at: '2018-05-11T13:36:29.107Z',
//         type: 'domestic',
//         region: 3,
//         province_name: 'Cà Mau',
//         has_in_atadi: true,
//         region_name: 'Southern VN',
//         region_name_vi: 'Miền Nam'
//     },
//     {
//         name: 'Sân bay Côn Đảo',
//         code: 'VCS',
//         province_id: 119,
//         created_at: '2017-05-18T02:59:35.061Z',
//         updated_at: '2018-05-11T13:36:29.112Z',
//         type: 'domestic',
//         region: 3,
//         province_name: 'Bà Rịa - Vũng Tàu',
//         has_in_atadi: true,
//         region_name: 'Southern VN',
//         region_name_vi: 'Miền Nam'
//     }
// ]

export const json_airport = {
    HOU: {
        id: 3397,
        airportCode: 'HOU',
        cityCode: 'AAP',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Houston',
        airportNameEn: 'Houston Hobby Airport',
        cityNameVi: 'Houston',
        cityNameEn: 'Houston',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    SYD: {
        id: 3396,
        airportCode: 'SYD',
        cityCode: 'SYD',
        countryCode: 'AU',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Kingsford Smith',
        airportNameEn: 'Kingsford Smith International Airport',
        cityNameVi: 'Sydney',
        cityNameEn: 'Sydney',
        countryNameVi: 'Úc',
        countryNameEn: 'Australia'
    },
    KUL: {
        id: 3395,
        airportCode: 'KUL',
        cityCode: 'SZB',
        countryCode: 'MY',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Kuala Lumpur',
        airportNameEn: 'Kuala Lumpur Airport',
        cityNameVi: 'Kuala Lumpur',
        cityNameEn: 'Kuala Lumpur',
        countryNameVi: 'Malaysia',
        countryNameEn: 'Malaysia'
    },
    TPE: {
        id: 3394,
        airportCode: 'TPE',
        cityCode: 'TPE',
        countryCode: 'TW',
        continentCode: 'AS',
        airportNameVi: 'Tất cả các sân bay',
        airportNameEn: 'All airports',
        cityNameVi: 'Đài Bắc',
        cityNameEn: 'Taipei',
        countryNameVi: 'Đài Loan',
        countryNameEn: 'Taiwan'
    },
    VFA: {
        id: 3393,
        airportCode: 'VFA',
        cityCode: 'VFA',
        countryCode: 'ZW',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Victoria Falls',
        airportNameEn: 'Victoria Falls Airport',
        cityNameVi: 'Victoria Falls',
        cityNameEn: 'Victoria Falls',
        countryNameVi: 'Dim ba buê',
        countryNameEn: 'Zimbabwe'
    },
    HRE: {
        id: 3392,
        airportCode: 'HRE',
        cityCode: 'HRE',
        countryCode: 'ZW',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Harare',
        airportNameEn: 'Harare International Airport',
        cityNameVi: 'Harare',
        cityNameEn: 'Harare',
        countryNameVi: 'Dim ba buê',
        countryNameEn: 'Zimbabwe'
    },
    BUQ: {
        id: 3391,
        airportCode: 'BUQ',
        cityCode: 'BUQ',
        countryCode: 'ZW',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Joshua Mqabuko Nkomo',
        airportNameEn: 'Joshua Mqabuko Nkomo International Airport',
        cityNameVi: 'Bulawayo',
        cityNameEn: 'Bulawayo',
        countryNameVi: 'Dim ba buê',
        countryNameEn: 'Zimbabwe'
    },
    NLA: {
        id: 3390,
        airportCode: 'NLA',
        cityCode: 'NLA',
        countryCode: 'ZM',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Ndola',
        airportNameEn: 'Ndola Airport',
        cityNameVi: 'N Dola',
        cityNameEn: 'N Dola',
        countryNameVi: 'Zambia',
        countryNameEn: 'Zambia'
    },
    LUN: {
        id: 3389,
        airportCode: 'LUN',
        cityCode: 'LUN',
        countryCode: 'ZM',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Lusaka',
        airportNameEn: 'Lusaka International Airport',
        cityNameVi: 'Lusaka',
        cityNameEn: 'Lusaka',
        countryNameVi: 'Zambia',
        countryNameEn: 'Zambia'
    },
    LVI: {
        id: 3388,
        airportCode: 'LVI',
        cityCode: 'LVI',
        countryCode: 'ZM',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Livingstone',
        airportNameEn: 'Livingstone Airport',
        cityNameVi: 'Livingstone',
        cityNameEn: 'Livingstone',
        countryNameVi: 'Zambia',
        countryNameEn: 'Zambia'
    },
    SAH: {
        id: 3387,
        airportCode: 'SAH',
        cityCode: 'SAH',
        countryCode: 'YE',
        continentCode: 'AS',
        airportNameVi: 'Sân bay El Rahaba',
        airportNameEn: 'El Rahaba Airport ',
        cityNameVi: 'Sanaa',
        cityNameEn: 'Sanaa',
        countryNameVi: 'Yemen',
        countryNameEn: 'Yemen'
    },
    HOD: {
        id: 3386,
        airportCode: 'HOD',
        cityCode: 'HOD',
        countryCode: 'YE',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Hodeida',
        airportNameEn: 'Hodeida International Airport',
        cityNameVi: 'Hodeidah',
        cityNameEn: 'Hodeidah',
        countryNameVi: 'Yemen',
        countryNameEn: 'Yemen'
    },
    ADE: {
        id: 3385,
        airportCode: 'ADE',
        cityCode: 'ADE',
        countryCode: 'YE',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Aden',
        airportNameEn: 'Aden International Airport',
        cityNameVi: 'Aden',
        cityNameEn: 'Aden',
        countryNameVi: 'Yemen',
        countryNameEn: 'Yemen'
    },
    STT: {
        id: 3384,
        airportCode: 'STT',
        cityCode: 'STT',
        countryCode: 'VI',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Cyril E. King',
        airportNameEn: 'Cyril E. King Airport',
        cityNameVi: 'St Thomas',
        cityNameEn: 'St Thomas',
        countryNameVi: 'Virgin Islands',
        countryNameEn: 'Virgin Islands'
    },
    STX: {
        id: 3383,
        airportCode: 'STX',
        cityCode: 'STX',
        countryCode: 'VI',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Henry E. Rohlsen',
        airportNameEn: 'Henry E. Rohlsen Airport',
        cityNameVi: 'St Croix',
        cityNameEn: 'St Croix',
        countryNameVi: 'Virgin Islands',
        countryNameEn: 'Virgin Islands'
    },
    VII: {
        id: 3382,
        airportCode: 'VII',
        cityCode: 'VII',
        countryCode: 'VN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Vinh',
        airportNameEn: 'Vinh Airport',
        cityNameVi: 'Vinh',
        cityNameEn: 'Vinh',
        countryNameVi: 'Việt nam',
        countryNameEn: 'Vietnam'
    },
    THD: {
        id: 3381,
        airportCode: 'THD',
        cityCode: 'THD',
        countryCode: 'VN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Thọ Xuân',
        airportNameEn: 'Tho Xuan Airport',
        cityNameVi: 'Thanh Hóa',
        cityNameEn: 'Thanh Hoa',
        countryNameVi: 'Việt nam',
        countryNameEn: 'Vietnam'
    },
    VDO: {
        id: 1,
        airportCode: 'VDO',
        cityCode: 'VDO',
        countryCode: 'VN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Vân Đồn',
        airportNameEn: 'Van Don Airport',
        cityNameVi: 'Quảng Ninh',
        cityNameEn: 'Quảng Ninh',
        countryNameVi: 'Việt nam',
        countryNameEn: 'Vietnam'
    },
    UIH: {
        id: 3380,
        airportCode: 'UIH',
        cityCode: 'UIH',
        countryCode: 'VN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Phù Cát',
        airportNameEn: 'Phu Cat Airport',
        cityNameVi: 'Qui Nhơn',
        cityNameEn: 'Qui Nhon',
        countryNameVi: 'Việt nam',
        countryNameEn: 'Vietnam'
    },
    VCL: {
        id: 3379,
        airportCode: 'VCL',
        cityCode: 'VCL',
        countryCode: 'VN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Chu Lai',
        airportNameEn: 'Chu Lai Airport',
        cityNameVi: 'Quảng Nam',
        cityNameEn: 'Quang Nam',
        countryNameVi: 'Việt nam',
        countryNameEn: 'Vietnam'
    },
    VDH: {
        id: 3378,
        airportCode: 'VDH',
        cityCode: 'VDH',
        countryCode: 'VN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Đồng Hới',
        airportNameEn: 'Dong Hoi Airport',
        cityNameVi: 'Quảng Bình',
        cityNameEn: 'Quang Binh',
        countryNameVi: 'Việt nam',
        countryNameEn: 'Vietnam'
    },
    PXU: {
        id: 3377,
        airportCode: 'PXU',
        cityCode: 'PXU',
        countryCode: 'VN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Pleiku',
        airportNameEn: 'Pleiku Airport',
        cityNameVi: 'PleiKu',
        cityNameEn: 'Pleiku',
        countryNameVi: 'Việt nam',
        countryNameEn: 'Vietnam'
    },
    TBB: {
        id: 3376,
        airportCode: 'TBB',
        cityCode: 'TBB',
        countryCode: 'VN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Tuy Hòa',
        airportNameEn: 'Tuy Hoa Airport',
        cityNameVi: 'Phú Yên',
        cityNameEn: 'Tuy Hoa',
        countryNameVi: 'Việt nam',
        countryNameEn: 'Vietnam'
    },
    PQC: {
        id: 3375,
        airportCode: 'PQC',
        cityCode: 'PQC',
        countryCode: 'VN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Phú Quốc',
        airportNameEn: 'Phu Quoc Airport',
        cityNameVi: 'Phú Quốc',
        cityNameEn: 'Phu Quoc',
        countryNameVi: 'Việt nam',
        countryNameEn: 'Vietnam'
    },
    NHA: {
        id: 3374,
        airportCode: 'NHA',
        cityCode: 'NHA',
        countryCode: 'VN',
        continentCode: 'AS',
        airportNameVi: 'Tất cả các sân bay',
        airportNameEn: 'All Airports',
        cityNameVi: 'Nha Trang',
        cityNameEn: 'Nha Trang',
        countryNameVi: 'Việt nam',
        countryNameEn: 'Vietnam'
    },
    CXR: {
        id: 3373,
        airportCode: 'CXR',
        cityCode: 'NHA',
        countryCode: 'VN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Cam Ranh',
        airportNameEn: 'Cam Ranh Airport',
        cityNameVi: 'Nha Trang',
        cityNameEn: 'Nha Trang',
        countryNameVi: 'Việt nam',
        countryNameEn: 'Vietnam'
    },
    VKG: {
        id: 3372,
        airportCode: 'VKG',
        cityCode: 'VKG',
        countryCode: 'VN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Rạch Giá',
        airportNameEn: 'Rach Gia Airport',
        cityNameVi: 'Kiên Giang',
        cityNameEn: 'Rach Gia',
        countryNameVi: 'Việt nam',
        countryNameEn: 'Vietnam'
    },
    HUI: {
        id: 3371,
        airportCode: 'HUI',
        cityCode: 'HUI',
        countryCode: 'VN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Phú Bài',
        airportNameEn: 'Phu Bai International Airport',
        cityNameVi: 'Huế',
        cityNameEn: 'Hue',
        countryNameVi: 'Việt nam',
        countryNameEn: 'Vietnam'
    },
    SGN: {
        id: 3370,
        airportCode: 'SGN',
        cityCode: 'SGN',
        countryCode: 'VN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Tân Sơn Nhất',
        airportNameEn: 'Tan Son Nhat International Airport',
        cityNameVi: 'Hồ Chí Minh',
        cityNameEn: 'Ho Chi Minh City',
        countryNameVi: 'Việt nam',
        countryNameEn: 'Vietnam'
    },
    HPH: {
        id: 3369,
        airportCode: 'HPH',
        cityCode: 'HPH',
        countryCode: 'VN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Cát Bi',
        airportNameEn: 'Cat Bi International Airport ',
        cityNameVi: 'Hải Phòng',
        cityNameEn: 'Haiphong',
        countryNameVi: 'Việt nam',
        countryNameEn: 'Vietnam'
    },
    HAN: {
        id: 3368,
        airportCode: 'HAN',
        cityCode: 'HAN',
        countryCode: 'VN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Nội Bài',
        airportNameEn: 'Noi Bai International Airport',
        cityNameVi: 'Hà Nội',
        cityNameEn: 'Hanoi',
        countryNameVi: 'Việt nam',
        countryNameEn: 'Vietnam'
    },
    DIN: {
        id: 3367,
        airportCode: 'DIN',
        cityCode: 'DIN',
        countryCode: 'VN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Điện Biên Phủ',
        airportNameEn: 'Dien Bien Phu Airport',
        cityNameVi: 'Điện Biên Phủ',
        cityNameEn: 'Dien Bien Phu',
        countryNameVi: 'Việt nam',
        countryNameEn: 'Vietnam'
    },
    DAD: {
        id: 3366,
        airportCode: 'DAD',
        cityCode: 'DAD',
        countryCode: 'VN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Đà Nẵng',
        airportNameEn: 'Da Nang International Airport',
        cityNameVi: 'Đà Nẵng',
        cityNameEn: 'Da Nang',
        countryNameVi: 'Việt nam',
        countryNameEn: 'Vietnam'
    },
    DLI: {
        id: 3365,
        airportCode: 'DLI',
        cityCode: 'DLI',
        countryCode: 'VN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Liên Khương',
        airportNameEn: 'Lien Khuong Airport',
        cityNameVi: 'Đà Lạt',
        cityNameEn: 'Dalat',
        countryNameVi: 'Việt nam',
        countryNameEn: 'Vietnam'
    },
    VCS: {
        id: 3364,
        airportCode: 'VCS',
        cityCode: 'VCS',
        countryCode: 'VN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Cỏ Ống',
        airportNameEn: 'Co Ong Airport',
        cityNameVi: 'Côn Đảo',
        cityNameEn: 'Con Dao',
        countryNameVi: 'Việt nam',
        countryNameEn: 'Vietnam'
    },
    VCA: {
        id: 3363,
        airportCode: 'VCA',
        cityCode: 'VCA',
        countryCode: 'VN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Trà Nóc',
        airportNameEn: 'Tra Noc International Airport',
        cityNameVi: 'Cần Thơ',
        cityNameEn: 'Can Tho',
        countryNameVi: 'Việt nam',
        countryNameEn: 'Vietnam'
    },
    CAH: {
        id: 3362,
        airportCode: 'CAH',
        cityCode: 'CAH',
        countryCode: 'VN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Cà Mau',
        airportNameEn: 'Ca Mau Airport',
        cityNameVi: 'Ca Mau',
        cityNameEn: 'Ca Mau',
        countryNameVi: 'Việt nam',
        countryNameEn: 'Vietnam'
    },
    BMV: {
        id: 3361,
        airportCode: 'BMV',
        cityCode: 'BMV',
        countryCode: 'VN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Ban Mê Thuột',
        airportNameEn: 'Ban Me Thuot Airport',
        cityNameVi: 'Ban Mê Thuột',
        cityNameEn: 'Ban Me Thuot',
        countryNameVi: 'Việt nam',
        countryNameEn: 'Vietnam'
    },
    LSP: {
        id: 3360,
        airportCode: 'LSP',
        cityCode: 'LSP',
        countryCode: 'VE',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Josefa Camejo',
        airportNameEn: 'Josefa Camejo International Airport',
        cityNameVi: 'Las Piedras',
        cityNameEn: 'Las Piedras',
        countryNameVi: 'Vê nê du ê la',
        countryNameEn: 'Venezuela'
    },
    BRM: {
        id: 3359,
        airportCode: 'BRM',
        cityCode: 'BRM',
        countryCode: 'VE',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Jacinto Lara',
        airportNameEn: 'Jacinto Lara International Airport',
        cityNameVi: 'Barquisimeto',
        cityNameEn: 'Barquisimeto',
        countryNameVi: 'Vê nê du ê la',
        countryNameEn: 'Venezuela'
    },
    BLA: {
        id: 3358,
        airportCode: 'BLA',
        cityCode: 'BLA',
        countryCode: 'VE',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Generál José Antonio Anzoátegui',
        airportNameEn: 'Generál José Antonio Anzoátegui International Airport',
        cityNameVi: 'Barcelona',
        cityNameEn: 'Barcelona',
        countryNameVi: 'Vê nê du ê la',
        countryNameEn: 'Venezuela'
    },
    VLV: {
        id: 3357,
        airportCode: 'VLV',
        cityCode: 'VLV',
        countryCode: 'VE',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Dr. Antonio Nicolás Briceno',
        airportNameEn: 'Dr. Antonio Nicolás Briceno Airport',
        cityNameVi: 'Valera',
        cityNameEn: 'Valera',
        countryNameVi: 'Vê nê du ê la',
        countryNameEn: 'Venezuela'
    },
    PMV: {
        id: 3356,
        airportCode: 'PMV',
        cityCode: 'PMV',
        countryCode: 'VE',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Del Caribe International General Santiago Marino',
        airportNameEn: 'Del Caribe International General Santiago Marino Airport',
        cityNameVi: 'Porlamar',
        cityNameEn: 'Porlamar',
        countryNameVi: 'Vê nê du ê la',
        countryNameEn: 'Venezuela'
    },
    VLN: {
        id: 3355,
        airportCode: 'VLN',
        cityCode: 'VLN',
        countryCode: 'VE',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Arturo Michelena',
        airportNameEn: 'Arturo Michelena International Airport',
        cityNameVi: 'Valencia',
        cityNameEn: 'Valencia',
        countryNameVi: 'Vê nê du ê la',
        countryNameEn: 'Venezuela'
    },
    STD: {
        id: 3354,
        airportCode: 'STD',
        cityCode: 'STD',
        countryCode: 'VE',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Mayor Buenaventura Vivas',
        airportNameEn: 'Mayor Buenaventura Vivas Airport',
        cityNameVi: 'Santo Domingo',
        cityNameEn: 'Santo Domingo',
        countryNameVi: 'Vê nê du ê la',
        countryNameEn: 'Venezuela'
    },
    PZO: {
        id: 3353,
        airportCode: 'PZO',
        cityCode: 'PZO',
        countryCode: 'VE',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Manuel Carlos Piar Guayana',
        airportNameEn: 'Manuel Carlos Piar Guayana Airport',
        cityNameVi: 'Puerto Ordaz',
        cityNameEn: 'Puerto Ordaz',
        countryNameVi: 'Vê nê du ê la',
        countryNameEn: 'Venezuela'
    },
    MRD: {
        id: 3352,
        airportCode: 'MRD',
        cityCode: 'MRD',
        countryCode: 'VE',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Alberto Carnevalli',
        airportNameEn: 'Alberto Carnevalli Airport',
        cityNameVi: 'Merida',
        cityNameEn: 'Merida',
        countryNameVi: 'Vê nê du ê la',
        countryNameEn: 'Venezuela'
    },
    MUN: {
        id: 3351,
        airportCode: 'MUN',
        cityCode: 'MUN',
        countryCode: 'VE',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Maturín',
        airportNameEn: 'Maturín Airport',
        cityNameVi: 'Maturin',
        cityNameEn: 'Maturin',
        countryNameVi: 'Vê nê du ê la',
        countryNameEn: 'Venezuela'
    },
    MAR: {
        id: 3350,
        airportCode: 'MAR',
        cityCode: 'MAR',
        countryCode: 'VE',
        continentCode: 'SA',
        airportNameVi: 'Sân bay La Chinita',
        airportNameEn: 'La Chinita International Airport',
        cityNameVi: 'Maracaibo',
        cityNameEn: 'Maracaibo',
        countryNameVi: 'Vê nê du ê la',
        countryNameEn: 'Venezuela'
    },
    CCS: {
        id: 3349,
        airportCode: 'CCS',
        cityCode: 'CCS',
        countryCode: 'VE',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Simón Bolívar',
        airportNameEn: 'Simón Bolívar International Airport',
        cityNameVi: 'Caracas',
        cityNameEn: 'Caracas',
        countryNameVi: 'Vê nê du ê la',
        countryNameEn: 'Venezuela'
    },
    CCV: {
        id: 3348,
        airportCode: 'CCV',
        cityCode: 'CCV',
        countryCode: 'VU',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Craig Cove',
        airportNameEn: 'Craig Cove Airport',
        cityNameVi: 'Craig Cove',
        cityNameEn: 'Craig Cove',
        countryNameVi: 'Vanuatu',
        countryNameEn: 'Vanuatu'
    },
    VLI: {
        id: 3347,
        airportCode: 'VLI',
        cityCode: 'VLI',
        countryCode: 'VU',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Bauerfield',
        airportNameEn: 'Bauerfield International Airport',
        cityNameVi: 'Port Vila',
        cityNameEn: 'Port Vila',
        countryNameVi: 'Vanuatu',
        countryNameEn: 'Vanuatu'
    },
    BHK: {
        id: 3346,
        airportCode: 'BHK',
        cityCode: 'BHK',
        countryCode: 'UZ',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Bukhara',
        airportNameEn: 'Bukhara Airport',
        cityNameVi: 'Bukhara',
        cityNameEn: 'Bukhara',
        countryNameVi: 'U dơ bê kis tan',
        countryNameEn: 'Uzbekistan'
    },
    TAS: {
        id: 3345,
        airportCode: 'TAS',
        cityCode: 'TAS',
        countryCode: 'UZ',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Tashkent',
        airportNameEn: 'Tashkent International Airport',
        cityNameVi: 'Tashkent',
        cityNameEn: 'Tashkent',
        countryNameVi: 'U dơ bê kis tan',
        countryNameEn: 'Uzbekistan'
    },
    PDP: {
        id: 3344,
        airportCode: 'PDP',
        cityCode: 'PDP',
        countryCode: 'UY',
        continentCode: 'SA',
        airportNameVi: 'Sân bay C/C Carlos A. Curbelo de Laguna del Sauce',
        airportNameEn: 'C/C Carlos A. Curbelo de Laguna del Sauce International Airport',
        cityNameVi: 'Punta Del Este',
        cityNameEn: 'Punta Del Este',
        countryNameVi: 'Uruguay',
        countryNameEn: 'Uruguay'
    },
    MVD: {
        id: 3343,
        airportCode: 'MVD',
        cityCode: 'MVD',
        countryCode: 'UY',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Carrasco Gral. Cesáreo L. Berisso',
        airportNameEn: 'Carrasco Gral. Cesáreo L. Berisso International Airport',
        cityNameVi: 'Montevideo',
        cityNameEn: 'Montevideo',
        countryNameVi: 'Uruguay',
        countryNameEn: 'Uruguay'
    },
    CDV: {
        id: 3342,
        airportCode: 'CDV',
        cityCode: 'CDV',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Merle K.',
        airportNameEn: 'Merle K.',
        cityNameVi: 'Cordova',
        cityNameEn: 'Cordova',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    BFL: {
        id: 3341,
        airportCode: 'BFL',
        cityCode: 'BFL',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Meadows Field',
        airportNameEn: 'Meadows Field',
        cityNameVi: 'Bakersfield',
        cityNameEn: 'Bakersfield',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    MEI: {
        id: 3340,
        airportCode: 'MEI',
        cityCode: 'MEI',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Key Field',
        airportNameEn: 'Key Field',
        cityNameVi: 'Meridian',
        cityNameEn: 'Meridian',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    SEE: {
        id: 3339,
        airportCode: 'SEE',
        cityCode: 'SEE',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Gillespie Field',
        airportNameEn: 'Gillespie Field',
        cityNameVi: 'San Diego',
        cityNameEn: 'San Diego',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    FOE: {
        id: 3338,
        airportCode: 'FOE',
        cityCode: 'TOP',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Forbes Field',
        airportNameEn: 'Forbes Field',
        cityNameVi: 'Topeka',
        cityNameEn: 'Topeka',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    LPS: {
        id: 3337,
        airportCode: 'LPS',
        cityCode: 'LPS',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Fishermans Bay/LPS Seaplane Base',
        airportNameEn: 'Fishermans Bay/LPS Seaplane Base',
        cityNameVi: 'Lopez Island',
        cityNameEn: 'Lopez Island',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    EFD: {
        id: 3336,
        airportCode: 'EFD',
        cityCode: 'HOU',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Ellington Field',
        airportNameEn: 'Ellington Field',
        cityNameVi: 'Houston',
        cityNameEn: 'Houston',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    JRE: {
        id: 3335,
        airportCode: 'JRE',
        cityCode: 'JRE',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'East 60th Street Heliport',
        airportNameEn: 'East 60th Street Heliport',
        cityNameVi: 'New York',
        cityNameEn: 'New York',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    TSS: {
        id: 3334,
        airportCode: 'TSS',
        cityCode: 'TSS',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'East 34th Street Heliport ',
        airportNameEn: 'East 34th Street Heliport ',
        cityNameVi: 'New York',
        cityNameEn: 'New York',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    DNL: {
        id: 3333,
        airportCode: 'DNL',
        cityCode: 'AGS',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Daniel Field',
        airportNameEn: 'Daniel Field',
        cityNameVi: 'Augusta',
        cityNameEn: 'Augusta',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    CGA: {
        id: 3332,
        airportCode: 'CGA',
        cityCode: 'CGA',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Craig Seaplane Base',
        airportNameEn: 'Craig Seaplane Base',
        cityNameVi: 'Craig',
        cityNameEn: 'Craig',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    JBP: {
        id: 3331,
        airportCode: 'JBP',
        cityCode: 'JBP',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Commerce Business Park Heliport ',
        airportNameEn: 'Commerce Business Park Heliport ',
        cityNameVi: 'Los Angeles',
        cityNameEn: 'Los Angeles',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    CNM: {
        id: 3330,
        airportCode: 'CNM',
        cityCode: 'CNM',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Cavern City Air Terminal',
        airportNameEn: 'Cavern City Air Terminal',
        cityNameVi: 'Carlsbad',
        cityNameEn: 'Carlsbad',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    CNY: {
        id: 3329,
        airportCode: 'CNY',
        cityCode: 'CNY',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Canyonlands Field',
        airportNameEn: 'Canyonlands Field',
        cityNameVi: 'Moab',
        cityNameEn: 'Moab',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    LOU: {
        id: 3328,
        airportCode: 'LOU',
        cityCode: 'LOU',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Bowman Field',
        airportNameEn: 'Bowman Field',
        cityNameVi: 'Louisville',
        cityNameEn: 'Louisville',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    BOI: {
        id: 3327,
        airportCode: 'BOI',
        cityCode: 'BOI',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Boise Air Terminal ',
        airportNameEn: 'Boise Air Terminal ',
        cityNameVi: 'Boise',
        cityNameEn: 'Boise',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    BAD: {
        id: 3326,
        airportCode: 'BAD',
        cityCode: 'BAD',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Barksdale Air Force Base',
        airportNameEn: 'Barksdale Air Force Base',
        cityNameVi: 'Shreveport',
        cityNameEn: 'Shreveport',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    AGN: {
        id: 3325,
        airportCode: 'AGN',
        cityCode: 'AGN',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Angoon Seaplane Base',
        airportNameEn: 'Angoon Seaplane Base',
        cityNameVi: 'Angoon',
        cityNameEn: 'Angoon',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    AAP: {
        id: 3324,
        airportCode: 'AAP',
        cityCode: 'AAP',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Andrau Airpark',
        airportNameEn: 'Andrau Airpark',
        cityNameVi: 'Houston',
        cityNameEn: 'Houston',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    ZZV: {
        id: 3323,
        airportCode: 'ZZV',
        cityCode: 'ZZV',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Zanesville Municipal',
        airportNameEn: 'Zanesville Municipal Airport',
        cityNameVi: 'Zanesville',
        cityNameEn: 'Zanesville',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    CRW: {
        id: 3322,
        airportCode: 'CRW',
        cityCode: 'CRW',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Yeager',
        airportNameEn: 'Yeager Airport',
        cityNameVi: 'Charleston',
        cityNameEn: 'Charleston',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    HDN: {
        id: 3321,
        airportCode: 'HDN',
        cityCode: 'HDN',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Yampa Valley',
        airportNameEn: 'Yampa Valley Airport',
        cityNameVi: 'Hayden',
        cityNameEn: 'Hayden',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    YAK: {
        id: 3320,
        airportCode: 'YAK',
        cityCode: 'YAK',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Yakutat',
        airportNameEn: 'Yakutat Airport',
        cityNameVi: 'Yakutat',
        cityNameEn: 'Yakutat',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    WRG: {
        id: 3319,
        airportCode: 'WRG',
        cityCode: 'WRG',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Wrangell',
        airportNameEn: 'Wrangell Airport',
        cityNameVi: 'Wrangell',
        cityNameEn: 'Wrangell',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    WRL: {
        id: 3318,
        airportCode: 'WRL',
        cityCode: 'WRL',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Worland Municipal',
        airportNameEn: 'Worland Municipal Airport',
        cityNameVi: 'Worland',
        cityNameEn: 'Worland',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    ORH: {
        id: 3317,
        airportCode: 'ORH',
        cityCode: 'ORH',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Worcester Regional',
        airportNameEn: 'Worcester Regional Airport',
        cityNameVi: 'Worcester',
        cityNameEn: 'Worcester',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    MWA: {
        id: 3316,
        airportCode: 'MWA',
        cityCode: 'MWA',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Williamson County Regional',
        airportNameEn: 'Williamson County Regional Airport',
        cityNameVi: 'Marion',
        cityNameEn: 'Marion',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    CLM: {
        id: 3315,
        airportCode: 'CLM',
        cityCode: 'CLM',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay William R. Fairchild',
        airportNameEn: 'William R. Fairchild International Airport',
        cityNameVi: 'Port Angeles',
        cityNameEn: 'Port Angeles',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    BRW: {
        id: 3314,
        airportCode: 'BRW',
        cityCode: 'BRW',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Wiley Post–Will Rogers Memorial',
        airportNameEn: 'Wiley Post–Will Rogers Memorial Airport',
        cityNameVi: 'Barrow',
        cityNameEn: 'Barrow',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    IWS: {
        id: 3313,
        airportCode: 'IWS',
        cityCode: 'HOU',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay West Houston',
        airportNameEn: 'West Houston Airport',
        cityNameVi: 'Houston',
        cityNameEn: 'Houston',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    ALW: {
        id: 3312,
        airportCode: 'ALW',
        cityCode: 'ALW',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Walla Walla Regional',
        airportNameEn: 'Walla Walla Regional Airport',
        cityNameVi: 'Walla Walla',
        cityNameEn: 'Walla Walla',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    AIN: {
        id: 3311,
        airportCode: 'AIN',
        cityCode: 'AIN',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Wainwright',
        airportNameEn: 'Wainwright Airport ',
        cityNameVi: 'Wainwright',
        cityNameEn: 'Wainwright',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    MUE: {
        id: 3310,
        airportCode: 'MUE',
        cityCode: 'MUE',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Waimea-Kohala',
        airportNameEn: 'Waimea-Kohala Airport',
        cityNameVi: 'Kamuela',
        cityNameEn: 'Kamuela',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    ACT: {
        id: 3309,
        airportCode: 'ACT',
        cityCode: 'ACT',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Waco Regional',
        airportNameEn: 'Waco Regional Airport',
        cityNameVi: 'Waco',
        cityNameEn: 'Waco',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    VIS: {
        id: 3308,
        airportCode: 'VIS',
        cityCode: 'VIS',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Visalia Municipal',
        airportNameEn: 'Visalia Municipal Airport',
        cityNameVi: 'Visalia',
        cityNameEn: 'Visalia',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    VEL: {
        id: 3307,
        airportCode: 'VEL',
        cityCode: 'VEL',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Vernal',
        airportNameEn: 'Vernal Airport',
        cityNameVi: 'Vernal',
        cityNameEn: 'Vernal',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    HRL: {
        id: 3306,
        airportCode: 'HRL',
        cityCode: 'HRL',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Valley',
        airportNameEn: 'Valley International Airport',
        cityNameVi: 'Harlingen',
        cityNameEn: 'Harlingen',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    VDZ: {
        id: 3305,
        airportCode: 'VDZ',
        cityCode: 'VDZ',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Valdez',
        airportNameEn: 'Valdez Airport ',
        cityNameVi: 'Valdez',
        cityNameEn: 'Valdez',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    CMI: {
        id: 3304,
        airportCode: 'CMI',
        cityCode: 'CMI',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay University of Illinois Willard',
        airportNameEn: 'University of Illinois Willard Airport',
        cityNameVi: 'Champaign',
        cityNameEn: 'Champaign',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    UNK: {
        id: 3303,
        airportCode: 'UNK',
        cityCode: 'UNK',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Unalakleet',
        airportNameEn: 'Unalakleet Airport',
        cityNameVi: 'Unalakleet',
        cityNameEn: 'Unalakleet',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    HVN: {
        id: 3302,
        airportCode: 'HVN',
        cityCode: 'HVN',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Tweed-New Haven',
        airportNameEn: 'Tweed-New Haven Airport',
        cityNameVi: 'New Haven',
        cityNameEn: 'New Haven',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    TUP: {
        id: 3301,
        airportCode: 'TUP',
        cityCode: 'TUP',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Tupelo Regional',
        airportNameEn: 'Tupelo Regional Airport',
        cityNameVi: 'Tupelo',
        cityNameEn: 'Tupelo',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    CID: {
        id: 3300,
        airportCode: 'CID',
        cityCode: 'CID',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay The Eastern Iowa',
        airportNameEn: 'The Eastern Iowa Airport',
        cityNameVi: 'Cedar Rapids',
        cityNameEn: 'Cedar Rapids',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    TEB: {
        id: 3299,
        airportCode: 'TEB',
        cityCode: 'TEB',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Teterboro',
        airportNameEn: 'Teterboro Airport',
        cityNameVi: 'Teterboro',
        cityNameEn: 'Teterboro',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    TEX: {
        id: 3298,
        airportCode: 'TEX',
        cityCode: 'TEX',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Telluride Regional',
        airportNameEn: 'Telluride Regional Airport',
        cityNameVi: 'Telluride',
        cityNameEn: 'Telluride',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    TLH: {
        id: 3297,
        airportCode: 'TLH',
        cityCode: 'TLH',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Tallahassee Regional',
        airportNameEn: 'Tallahassee Regional Airport',
        cityNameVi: 'Tallahassee',
        cityNameEn: 'Tallahassee',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    SGR: {
        id: 3296,
        airportCode: 'SGR',
        cityCode: 'SGR',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Sugar Land Regional',
        airportNameEn: 'Sugar Land Regional Airport',
        cityNameVi: 'Sugar Land',
        cityNameEn: 'Sugar Land',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    SWF: {
        id: 3295,
        airportCode: 'SWF',
        cityCode: 'SWF',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Stewart',
        airportNameEn: 'Stewart International Airport',
        cityNameVi: 'Newburgh',
        cityNameEn: 'Newburgh',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    STE: {
        id: 3294,
        airportCode: 'STE',
        cityCode: 'STE',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Stevens Point Municipal',
        airportNameEn: 'Stevens Point Municipal Airport',
        cityNameVi: 'Stevens Point',
        cityNameEn: 'Stevens Point',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    STP: {
        id: 3293,
        airportCode: 'STP',
        cityCode: 'STP',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay St. Paul Downtown',
        airportNameEn: 'St. Paul Downtown Airport ',
        cityNameVi: 'St Paul',
        cityNameEn: 'St Paul',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    BRL: {
        id: 3292,
        airportCode: 'BRL',
        cityCode: 'BRL',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Southeast Iowa Regional',
        airportNameEn: 'Southeast Iowa Regional Airport',
        cityNameVi: 'Burlington',
        cityNameEn: 'Burlington',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    SBN: {
        id: 3291,
        airportCode: 'SBN',
        cityCode: 'SBN',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay South Bend Regional',
        airportNameEn: 'South Bend Regional Airport',
        cityNameVi: 'South Bend',
        cityNameEn: 'South Bend',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    PAE: {
        id: 3290,
        airportCode: 'PAE',
        cityCode: 'PAE',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Snohomish County',
        airportNameEn: 'Snohomish County Airport ',
        cityNameVi: 'Everett',
        cityNameEn: 'Everett',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    SGY: {
        id: 3289,
        airportCode: 'SGY',
        cityCode: 'SGY',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Skagway',
        airportNameEn: 'Skagway Airport',
        cityNameVi: 'Skagway',
        cityNameEn: 'Skagway',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    SIT: {
        id: 3288,
        airportCode: 'SIT',
        cityCode: 'SIT',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Sitka Rocky Gutierrez',
        airportNameEn: 'Sitka Rocky Gutierrez Airport',
        cityNameVi: 'Sitka',
        cityNameEn: 'Sitka',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    SOW: {
        id: 3287,
        airportCode: 'SOW',
        cityCode: 'SOW',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Show Low Regional',
        airportNameEn: 'Show Low Regional Airport',
        cityNameVi: 'Show Low',
        cityNameEn: 'Show Low',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    SHD: {
        id: 3286,
        airportCode: 'SHD',
        cityCode: 'SHD',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Shenandoah Valley Regional',
        airportNameEn: 'Shenandoah Valley Regional Airport',
        cityNameVi: 'Staunton',
        cityNameEn: 'Staunton',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    WLK: {
        id: 3285,
        airportCode: 'WLK',
        cityCode: 'WLK',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Selawik',
        airportNameEn: 'Selawik Airport',
        cityNameVi: 'Selawik',
        cityNameEn: 'Selawik',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    SCF: {
        id: 3284,
        airportCode: 'SCF',
        cityCode: 'SCF',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Scottsdale',
        airportNameEn: 'Scottsdale Airport ',
        cityNameVi: 'Scottsdale',
        cityNameEn: 'Scottsdale',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    MQT: {
        id: 3283,
        airportCode: 'MQT',
        cityCode: 'MQT',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Sawyer',
        airportNameEn: 'Sawyer International Airport ',
        cityNameVi: 'Marquette',
        cityNameEn: 'Marquette',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    SRQ: {
        id: 3282,
        airportCode: 'SRQ',
        cityCode: 'SRQ',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Sarasota-Bradenton',
        airportNameEn: 'Sarasota-Bradenton International Airport',
        cityNameVi: 'Sarasota',
        cityNameEn: 'Sarasota',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    SBA: {
        id: 3281,
        airportCode: 'SBA',
        cityCode: 'SBA',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Santa Barbara Municipal',
        airportNameEn: 'Santa Barbara Municipal Airport',
        cityNameVi: 'Santa Barbara',
        cityNameEn: 'Santa Barbara',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    SDP: {
        id: 3280,
        airportCode: 'SDP',
        cityCode: 'SDP',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Sand Point',
        airportNameEn: 'Sand Point Airport',
        cityNameVi: 'Sand Point',
        cityNameEn: 'Sand Point',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    SBP: {
        id: 3279,
        airportCode: 'SBP',
        cityCode: 'CSL',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay San Luis Obispo County Regional',
        airportNameEn: 'San Luis Obispo County Regional Airport',
        cityNameVi: 'San Luis Obispo',
        cityNameEn: 'San Luis Obispo',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    SBY: {
        id: 3278,
        airportCode: 'SBY',
        cityCode: 'SBY',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Salisbury-Ocean City Wicomico Regional',
        airportNameEn: 'Salisbury-Ocean City Wicomico Regional Airport',
        cityNameVi: 'Salisbury Ocean City',
        cityNameEn: 'Salisbury Ocean City',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    SLN: {
        id: 3277,
        airportCode: 'SLN',
        cityCode: 'SLN',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Salina Municipal',
        airportNameEn: 'Salina Municipal Airport',
        cityNameVi: 'Salina',
        cityNameEn: 'Salina',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    RSH: {
        id: 3276,
        airportCode: 'RSH',
        cityCode: 'RSH',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Russian Mission',
        airportNameEn: 'Russian Mission Airport',
        cityNameVi: 'Russian Mission',
        cityNameEn: 'Russian Mission',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    MFR: {
        id: 3275,
        airportCode: 'MFR',
        cityCode: 'MFR',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Rogue Valley International-Medford',
        airportNameEn: 'Rogue Valley International-Medford Airport',
        cityNameVi: 'Medford',
        cityNameEn: 'Medford',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    RKS: {
        id: 3274,
        airportCode: 'RKS',
        cityCode: 'RKS',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Rock Springs – Sweetwater County',
        airportNameEn: 'Rock Springs – Sweetwater County Airport',
        cityNameVi: 'Rock Springs',
        cityNameEn: 'Rock Springs',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    ROA: {
        id: 3273,
        airportCode: 'ROA',
        cityCode: 'ROA',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Roanoke Regional',
        airportNameEn: 'Roanoke Regional Airport ',
        cityNameVi: 'Roanoke',
        cityNameEn: 'Roanoke',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    RIW: {
        id: 3272,
        airportCode: 'RIW',
        cityCode: 'RIW',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Riverton Regional',
        airportNameEn: 'Riverton Regional Airport',
        cityNameVi: 'Riverton',
        cityNameEn: 'Riverton',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    LCK: {
        id: 3271,
        airportCode: 'LCK',
        cityCode: 'CMH',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Rickenbacker',
        airportNameEn: 'Rickenbacker International Airport',
        cityNameVi: 'Columbus',
        cityNameEn: 'Columbus',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    RAP: {
        id: 3270,
        airportCode: 'RAP',
        cityCode: 'RAP',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Rapid City Regional',
        airportNameEn: 'Rapid City Regional Airport',
        cityNameVi: 'Rapid City',
        cityNameEn: 'Rapid City',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    OTZ: {
        id: 3269,
        airportCode: 'OTZ',
        cityCode: 'OTZ',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Ralph Wien Memorial',
        airportNameEn: 'Ralph Wien Memorial Airport',
        cityNameVi: 'Kotzebue',
        cityNameEn: 'Kotzebue',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    TAL: {
        id: 3268,
        airportCode: 'TAL',
        cityCode: 'TAL',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Ralph M. Calhoun Memorial',
        airportNameEn: 'Ralph M. Calhoun Memorial Airport',
        cityNameVi: 'Tanana',
        cityNameEn: 'Tanana',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    RDU: {
        id: 3267,
        airportCode: 'RDU',
        cityCode: 'RDU',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Raleigh–Durham',
        airportNameEn: 'Raleigh–Durham International Airport',
        cityNameVi: 'Raleigh',
        cityNameEn: 'Raleigh',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    UIN: {
        id: 3266,
        airportCode: 'UIN',
        cityCode: 'UIN',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Quincy Regional',
        airportNameEn: 'Quincy Regional Airport ',
        cityNameVi: 'Quincy',
        cityNameEn: 'Quincy',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    MLI: {
        id: 3265,
        airportCode: 'MLI',
        cityCode: 'MLI',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Quad City',
        airportNameEn: 'Quad City International Airport',
        cityNameVi: 'Moline',
        cityNameEn: 'Moline',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    PIZ: {
        id: 3264,
        airportCode: 'PIZ',
        cityCode: 'PIZ',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Point Lay LRRS',
        airportNameEn: 'Point Lay LRRS Airport',
        cityNameVi: 'Point Lay',
        cityNameEn: 'Point Lay',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    PIH: {
        id: 3263,
        airportCode: 'PIH',
        cityCode: 'PIH',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Pocatello Regional',
        airportNameEn: 'Pocatello Regional Airport',
        cityNameVi: 'Pocatello',
        cityNameEn: 'Pocatello',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    PBG: {
        id: 3262,
        airportCode: 'PBG',
        cityCode: 'PBG',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Plattsburgh',
        airportNameEn: 'Plattsburgh International Airport',
        cityNameVi: 'Plattsburgh',
        cityNameEn: 'Plattsburgh',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    PIR: {
        id: 3261,
        airportCode: 'PIR',
        cityCode: 'PIR',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Pierre Regional',
        airportNameEn: 'Pierre Regional Airport',
        cityNameVi: 'Pierre',
        cityNameEn: 'Pierre',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    GSO: {
        id: 3260,
        airportCode: 'GSO',
        cityCode: 'GSO',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Piedmont Triad',
        airportNameEn: 'Piedmont Triad International Airport',
        cityNameVi: 'Greensboro',
        cityNameEn: 'Greensboro',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    AZA: {
        id: 3259,
        airportCode: 'AZA',
        cityCode: 'AZA',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Phoenix-Mesa Gateway',
        airportNameEn: 'Phoenix-Mesa Gateway Airport ',
        cityNameVi: 'Phoenix',
        cityNameEn: 'Phoenix',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    DVT: {
        id: 3258,
        airportCode: 'DVT',
        cityCode: 'DVT',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Phoenix Deer Valley',
        airportNameEn: 'Phoenix Deer Valley Airport',
        cityNameVi: 'Phoenix',
        cityNameEn: 'Phoenix',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    PSG: {
        id: 3257,
        airportCode: 'PSG',
        cityCode: 'PSG',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Petersburg James A. Johnson',
        airportNameEn: 'Petersburg James A. Johnson Airport',
        cityNameVi: 'Petersburg',
        cityNameEn: 'Petersburg',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    SFB: {
        id: 3256,
        airportCode: 'SFB',
        cityCode: 'SFB',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Orlando Sanford',
        airportNameEn: 'Orlando Sanford International Airport',
        cityNameVi: 'Sanford',
        cityNameEn: 'Sanford',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    OSU: {
        id: 3255,
        airportCode: 'OSU',
        cityCode: 'CMH',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Ohio State University',
        airportNameEn: 'Ohio State University Airport',
        cityNameVi: 'Columbus',
        cityNameEn: 'Columbus',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    OGS: {
        id: 3254,
        airportCode: 'OGS',
        cityCode: 'OGS',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Ogdensburg',
        airportNameEn: 'Ogdensburg International Airport',
        cityNameVi: 'Ogdensburg',
        cityNameEn: 'Ogdensburg',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    NUL: {
        id: 3253,
        airportCode: 'NUL',
        cityCode: 'NUL',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Nulato',
        airportNameEn: 'Nulato Airport',
        cityNameVi: 'Nulato',
        cityNameEn: 'Nulato',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    MSL: {
        id: 3252,
        airportCode: 'MSL',
        cityCode: 'MSL',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Northwest Alabama Regional',
        airportNameEn: 'Northwest Alabama Regional Airport',
        cityNameVi: 'Muscle Shoals',
        cityNameEn: 'Muscle Shoals',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    PQI: {
        id: 3251,
        airportCode: 'PQI',
        cityCode: 'PQI',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Northern Maine Regional',
        airportNameEn: 'Northern Maine Regional Airport at Presque Isle',
        cityNameVi: 'Presque Isle',
        cityNameEn: 'Presque Isle',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    CKB: {
        id: 3250,
        airportCode: 'CKB',
        cityCode: 'CKB',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay North Central West Virginia',
        airportNameEn: 'North Central West Virginia Airport',
        cityNameVi: 'Clarksburg',
        cityNameEn: 'Clarksburg',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    ORF: {
        id: 3249,
        airportCode: 'ORF',
        cityCode: 'ORF',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Norfolk',
        airportNameEn: 'Norfolk International Airport',
        cityNameVi: 'Norfolk Newport News',
        cityNameEn: 'Norfolk Newport News',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    OME: {
        id: 3248,
        airportCode: 'OME',
        cityCode: 'OME',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Nome',
        airportNameEn: 'Nome Airport',
        cityNameVi: 'Nome',
        cityNameEn: 'Nome',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    PHF: {
        id: 3247,
        airportCode: 'PHF',
        cityCode: 'PHF',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Newport News/Williamsburg',
        airportNameEn: 'Newport News/Williamsburg International Airport',
        cityNameVi: 'Newport News',
        cityNameEn: 'Newport News',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    EWB: {
        id: 3246,
        airportCode: 'EWB',
        cityCode: 'EWB',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay New Bedford Regional',
        airportNameEn: 'New Bedford Regional Airport',
        cityNameVi: 'New Bedford',
        cityNameEn: 'New Bedford',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    CPR: {
        id: 3245,
        airportCode: 'CPR',
        cityCode: 'CPR',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Natrona County',
        airportNameEn: 'Natrona County International Airport',
        cityNameVi: 'Casper',
        cityNameEn: 'Casper',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    APF: {
        id: 3244,
        airportCode: 'APF',
        cityCode: 'APF',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Naples Municipal',
        airportNameEn: 'Naples Municipal Airport',
        cityNameVi: 'Naples',
        cityNameEn: 'Naples',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    ACK: {
        id: 3243,
        airportCode: 'ACK',
        cityCode: 'ACK',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Nantucket Memorial',
        airportNameEn: 'Nantucket Memorial Airport',
        cityNameVi: 'Nantucket',
        cityNameEn: 'Nantucket',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    MKG: {
        id: 3242,
        airportCode: 'MKG',
        cityCode: 'MKG',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Muskegon County',
        airportNameEn: 'Muskegon County Airport',
        cityNameVi: 'Muskegon',
        cityNameEn: 'Muskegon',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    MOU: {
        id: 3241,
        airportCode: 'MOU',
        cityCode: 'MOU',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Mountain Village',
        airportNameEn: 'Mountain Village Airport',
        cityNameVi: 'Mountain Village',
        cityNameEn: 'Mountain Village',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    MMU: {
        id: 3240,
        airportCode: 'MMU',
        cityCode: 'MMU',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Morristown Municipal',
        airportNameEn: 'Morristown Municipal Airport',
        cityNameVi: 'Morristown',
        cityNameEn: 'Morristown',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    MGW: {
        id: 3239,
        airportCode: 'MGW',
        cityCode: 'MGW',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Morgantown Municipal',
        airportNameEn: 'Morgantown Municipal Airport ',
        cityNameVi: 'Morgantown',
        cityNameEn: 'Morgantown',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    MTJ: {
        id: 3238,
        airportCode: 'MTJ',
        cityCode: 'MTJ',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Montrose Regional',
        airportNameEn: 'Montrose Regional Airport',
        cityNameVi: 'Montrose',
        cityNameEn: 'Montrose',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    MGM: {
        id: 3237,
        airportCode: 'MGM',
        cityCode: 'MGM',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Montgomery Regional',
        airportNameEn: 'Montgomery Regional Airport ',
        cityNameVi: 'Montgomery',
        cityNameEn: 'Montgomery',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    MRY: {
        id: 3236,
        airportCode: 'MRY',
        cityCode: 'MRY',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Monterey Regional',
        airportNameEn: 'Monterey Regional Airport',
        cityNameVi: 'Monterey',
        cityNameEn: 'Monterey',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    MLU: {
        id: 3235,
        airportCode: 'MLU',
        cityCode: 'MLU',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Monroe Regional',
        airportNameEn: 'Monroe Regional Airport',
        cityNameVi: 'Monroe',
        cityNameEn: 'Monroe',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    MKK: {
        id: 3234,
        airportCode: 'MKK',
        cityCode: 'MKK',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Molokai',
        airportNameEn: 'Molokai Airport',
        cityNameVi: 'Hoolehua',
        cityNameEn: 'Hoolehua',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    MOD: {
        id: 3233,
        airportCode: 'MOD',
        cityCode: 'MOD',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Modesto City-County',
        airportNameEn: 'Modesto City-County Airport ',
        cityNameVi: 'Modesto',
        cityNameEn: 'Modesto',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    MOB: {
        id: 3232,
        airportCode: 'MOB',
        cityCode: 'MOB',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Mobile Regional',
        airportNameEn: 'Mobile Regional Airport',
        cityNameVi: 'Mobile',
        cityNameEn: 'Mobile',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    MSO: {
        id: 3231,
        airportCode: 'MSO',
        cityCode: 'MSO',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Missoula',
        airportNameEn: 'Missoula International Airport',
        cityNameVi: 'Missoula',
        cityNameEn: 'Missoula',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    MOT: {
        id: 3230,
        airportCode: 'MOT',
        cityCode: 'MOT',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Minot',
        airportNameEn: 'Minot International Airport',
        cityNameVi: 'Minot',
        cityNameEn: 'Minot',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    MCN: {
        id: 3229,
        airportCode: 'MCN',
        cityCode: 'MCN',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Middle Georgia Regional',
        airportNameEn: 'Middle Georgia Regional Airport',
        cityNameVi: 'Macon',
        cityNameEn: 'Macon',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    MCE: {
        id: 3228,
        airportCode: 'MCE',
        cityCode: 'MCE',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Merced Municipal',
        airportNameEn: 'Merced Municipal Airport ',
        cityNameVi: 'Merced',
        cityNameEn: 'Merced',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    MCK: {
        id: 3227,
        airportCode: 'MCK',
        cityCode: 'MCK',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay McCook Regional',
        airportNameEn: 'McCook Regional Airport',
        cityNameVi: 'McCook',
        cityNameEn: 'McCook',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    CLD: {
        id: 3226,
        airportCode: 'CLD',
        cityCode: 'CLD',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay McClellan-Palomar',
        airportNameEn: 'McClellan-Palomar Airport ',
        cityNameVi: 'Carlsbad',
        cityNameEn: 'Carlsbad',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    MFE: {
        id: 3225,
        airportCode: 'MFE',
        cityCode: 'MFE',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay McAllen-Miller',
        airportNameEn: 'McAllen-Miller International Airport',
        cityNameVi: 'McAllen',
        cityNameEn: 'McAllen',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    MVY: {
        id: 3224,
        airportCode: 'MVY',
        cityCode: 'MVY',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: "Sân bay Martha's Vineyard",
        airportNameEn: "Martha's Vineyard Airport",
        cityNameVi: 'Martha S Vineyard',
        cityNameEn: 'Martha S Vineyard',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    NZC: {
        id: 3223,
        airportCode: 'NZC',
        cityCode: 'JAX',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Maria Reiche Neuman',
        airportNameEn: 'Maria Reiche Neuman Airport',
        cityNameVi: 'Jacksonville',
        cityNameEn: 'Jacksonville',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    MBL: {
        id: 3222,
        airportCode: 'MBL',
        cityCode: 'MBL',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Manistee County-Blacker',
        airportNameEn: 'Manistee County-Blacker Airport',
        cityNameVi: 'Manistee',
        cityNameEn: 'Manistee',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    MHK: {
        id: 3221,
        airportCode: 'MHK',
        cityCode: 'MHK',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Manhattan Regional',
        airportNameEn: 'Manhattan Regional Airport',
        cityNameVi: 'Manhattan',
        cityNameEn: 'Manhattan',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    MHT: {
        id: 3220,
        airportCode: 'MHT',
        cityCode: 'MHT',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Manchester-Boston Regional',
        airportNameEn: 'Manchester-Boston Regional Airport',
        cityNameVi: 'Manchester',
        cityNameEn: 'Manchester',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    MMH: {
        id: 3219,
        airportCode: 'MMH',
        cityCode: 'MMH',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Mammoth Yosemite',
        airportNameEn: 'Mammoth Yosemite Airport',
        cityNameVi: 'Mammoth Lakes',
        cityNameEn: 'Mammoth Lakes',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    SSI: {
        id: 3218,
        airportCode: 'SSI',
        cityCode: 'SSI',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Malcolm McKinnon',
        airportNameEn: 'Malcolm McKinnon Airport',
        cityNameVi: 'St Simons Is',
        cityNameEn: 'St Simons Is',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    LYH: {
        id: 3217,
        airportCode: 'LYH',
        cityCode: 'LYH',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Lynchburg Regional',
        airportNameEn: 'Lynchburg Regional Airport ',
        cityNameVi: 'Lynchburg',
        cityNameEn: 'Lynchburg',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    LBB: {
        id: 3216,
        airportCode: 'LBB',
        cityCode: 'LBB',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Lubbock Preston Smith',
        airportNameEn: 'Lubbock Preston Smith International Airport',
        cityNameVi: 'Lubbock',
        cityNameEn: 'Lubbock',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    ISP: {
        id: 3215,
        airportCode: 'ISP',
        cityCode: 'ISP',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Long Island MacArthur',
        airportNameEn: 'Long Island MacArthur Airport',
        cityNameVi: 'Islip',
        cityNameEn: 'Islip',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    LGB: {
        id: 3214,
        airportCode: 'LGB',
        cityCode: 'LGB',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Long Beach',
        airportNameEn: 'Long Beach Airport',
        cityNameVi: 'Long Beach',
        cityNameEn: 'Long Beach',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    LIT: {
        id: 3213,
        airportCode: 'LIT',
        cityCode: 'LIT',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Little Rock National',
        airportNameEn: 'Little Rock National Airport ',
        cityNameVi: 'Little Rock',
        cityNameEn: 'Little Rock',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    LNK: {
        id: 3212,
        airportCode: 'LNK',
        cityCode: 'LNK',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Lincoln',
        airportNameEn: 'Lincoln Airport',
        cityNameVi: 'Lincoln',
        cityNameEn: 'Lincoln',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    LBL: {
        id: 3211,
        airportCode: 'LBL',
        cityCode: 'LBL',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Liberal Municipal',
        airportNameEn: 'Liberal Municipal Airport',
        cityNameVi: 'Liberal',
        cityNameEn: 'Liberal',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    LWS: {
        id: 3210,
        airportCode: 'LWS',
        cityCode: 'LWS',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Lewiston-Nez Perce County',
        airportNameEn: 'Lewiston-Nez Perce County Airport',
        cityNameVi: 'Lewiston',
        cityNameEn: 'Lewiston',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    LAW: {
        id: 3209,
        airportCode: 'LAW',
        cityCode: 'LAW',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Lawton-Fort Sill Regional',
        airportNameEn: 'Lawton-Fort Sill Regional Airport',
        cityNameVi: 'Lawton',
        cityNameEn: 'Lawton',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    IFP: {
        id: 3208,
        airportCode: 'IFP',
        cityCode: 'IFP',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Laughlin/Bullhead',
        airportNameEn: 'Laughlin/Bullhead International Airport',
        cityNameVi: 'Bullhead City',
        cityNameEn: 'Bullhead City',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    LRD: {
        id: 3207,
        airportCode: 'LRD',
        cityCode: 'LRD',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Laredo',
        airportNameEn: 'Laredo International Airport',
        cityNameVi: 'Laredo',
        cityNameEn: 'Laredo',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    LNS: {
        id: 3206,
        airportCode: 'LNS',
        cityCode: 'LNS',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Lancaster',
        airportNameEn: 'Lancaster Airport',
        cityNameVi: 'Lancaster',
        cityNameEn: 'Lancaster',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    LNY: {
        id: 3205,
        airportCode: 'LNY',
        cityCode: 'LNY',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Lanai',
        airportNameEn: 'Lanai Airport',
        cityNameVi: 'Lanai',
        cityNameEn: 'Lanai',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    TVL: {
        id: 3204,
        airportCode: 'TVL',
        cityCode: 'TVL',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Lake Tahoe',
        airportNameEn: 'Lake Tahoe Airport',
        cityNameVi: 'Lake Tahoe',
        cityNameEn: 'Lake Tahoe',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    LCH: {
        id: 3203,
        airportCode: 'LCH',
        cityCode: 'LCH',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Lake Charles Regional',
        airportNameEn: 'Lake Charles Regional Airport',
        cityNameVi: 'Lake Charles',
        cityNameEn: 'Lake Charles',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    LSE: {
        id: 3202,
        airportCode: 'LSE',
        cityCode: 'LSE',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay La Crosse Regional',
        airportNameEn: 'La Crosse Regional Airport',
        cityNameVi: 'La Crosse',
        cityNameEn: 'La Crosse',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    KOA: {
        id: 3201,
        airportCode: 'KOA',
        cityCode: 'KOA',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Kona',
        airportNameEn: 'Kona International Airport',
        cityNameVi: 'Kona',
        cityNameEn: 'Kona',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    KLW: {
        id: 3200,
        airportCode: 'KLW',
        cityCode: 'KLW',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Klawock',
        airportNameEn: 'Klawock Airport ',
        cityNameVi: 'Klawock',
        cityNameEn: 'Klawock',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    LMT: {
        id: 3199,
        airportCode: 'LMT',
        cityCode: 'LMT',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Klamath Falls',
        airportNameEn: 'Klamath Falls Airport',
        cityNameVi: 'Klamath Falls',
        cityNameEn: 'Klamath Falls',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    IRK: {
        id: 3198,
        airportCode: 'IRK',
        cityCode: 'IRK',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Kirksville Regional',
        airportNameEn: 'Kirksville Regional Airport',
        cityNameVi: 'Kirksville',
        cityNameEn: 'Kirksville',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    IGM: {
        id: 3197,
        airportCode: 'IGM',
        cityCode: 'IGM',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Kingman',
        airportNameEn: 'Kingman Airport',
        cityNameVi: 'Kingman',
        cityNameEn: 'Kingman',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    GRK: {
        id: 3196,
        airportCode: 'GRK',
        cityCode: 'GRK',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Killeen-Fort Hood Regional',
        airportNameEn: 'Killeen-Fort Hood Regional Airport ',
        cityNameVi: 'Killeen',
        cityNameEn: 'Killeen',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    ENA: {
        id: 3195,
        airportCode: 'ENA',
        cityCode: 'ENA',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Kenai Municipal',
        airportNameEn: 'Kenai Municipal Airport',
        cityNameVi: 'Kenai',
        cityNameEn: 'Kenai',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    EAR: {
        id: 3194,
        airportCode: 'EAR',
        cityCode: 'EAR',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Kearney Regional',
        airportNameEn: 'Kearney Regional Airport',
        cityNameVi: 'Kearney',
        cityNameEn: 'Kearney',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    JHM: {
        id: 3193,
        airportCode: 'JHM',
        cityCode: 'JHM',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Kapalua',
        airportNameEn: 'Kapalua Airport',
        cityNameVi: 'Kapalua',
        cityNameEn: 'Kapalua',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    KLG: {
        id: 3192,
        airportCode: 'KLG',
        cityCode: 'KLG',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Kalskag',
        airportNameEn: 'Kalskag Airport',
        cityNameVi: 'Kalskag',
        cityNameEn: 'Kalskag',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    AZO: {
        id: 3191,
        airportCode: 'AZO',
        cityCode: 'AZO',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Kalamazoo/Battle Creek',
        airportNameEn: 'Kalamazoo/Battle Creek International Airport',
        cityNameVi: 'Kalamazoo',
        cityNameEn: 'Kalamazoo',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    KAE: {
        id: 3190,
        airportCode: 'KAE',
        cityCode: 'KAE',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Kake',
        airportNameEn: 'Kake Airport ',
        cityNameVi: 'Kake',
        cityNameEn: 'Kake',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    JNU: {
        id: 3189,
        airportCode: 'JNU',
        cityCode: 'JNU',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Juneau',
        airportNameEn: 'Juneau International Airport',
        cityNameVi: 'Juneau',
        cityNameEn: 'Juneau',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    JLN: {
        id: 3188,
        airportCode: 'JLN',
        cityCode: 'JLN',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Joplin Regional',
        airportNameEn: 'Joplin Regional Airport',
        cityNameVi: 'Joplin',
        cityNameEn: 'Joplin',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    JBR: {
        id: 3187,
        airportCode: 'JBR',
        cityCode: 'JBR',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Jonesboro Municipal',
        airportNameEn: 'Jonesboro Municipal Airport',
        cityNameVi: 'Jonesboro',
        cityNameEn: 'Jonesboro',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    SNA: {
        id: 3186,
        airportCode: 'SNA',
        cityCode: 'SNA',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay John Wayne',
        airportNameEn: 'John Wayne Airport ',
        cityNameVi: 'Santa Ana',
        cityNameEn: 'Santa Ana',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    JST: {
        id: 3185,
        airportCode: 'JST',
        cityCode: 'JST',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay John Murtha Johnstown-Cambria County',
        airportNameEn: 'John Murtha Johnstown-Cambria County Airport',
        cityNameVi: 'Johnstown',
        cityNameEn: 'Johnstown',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    JEF: {
        id: 3184,
        airportCode: 'JEF',
        cityCode: 'JEF',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Jefferson City Memorial',
        airportNameEn: 'Jefferson City Memorial Airport',
        cityNameVi: 'Jefferson City',
        cityNameEn: 'Jefferson City',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    JMS: {
        id: 3183,
        airportCode: 'JMS',
        cityCode: 'JMS',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Jamestown Regional',
        airportNameEn: 'Jamestown Regional Airport',
        cityNameVi: 'Jamestown',
        cityNameEn: 'Jamestown',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    JAC: {
        id: 3182,
        airportCode: 'JAC',
        cityCode: 'JAC',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Jackson Hole',
        airportNameEn: 'Jackson Hole Airport',
        cityNameVi: 'Jackson',
        cityNameEn: 'Jackson',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    JXN: {
        id: 3181,
        airportCode: 'JXN',
        cityCode: 'JXN',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Jackson County',
        airportNameEn: 'Jackson County Airport ',
        cityNameVi: 'Jackson',
        cityNameEn: 'Jackson',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    ITH: {
        id: 3180,
        airportCode: 'ITH',
        cityCode: 'ITH',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Ithaca Tompkins Regional',
        airportNameEn: 'Ithaca Tompkins Regional Airport',
        cityNameVi: 'Ithaca',
        cityNameEn: 'Ithaca',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    IYK: {
        id: 3179,
        airportCode: 'IYK',
        cityCode: 'IYK',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Inyokern',
        airportNameEn: 'Inyokern Airport',
        cityNameVi: 'Inyokern',
        cityNameEn: 'Inyokern',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    IPL: {
        id: 3178,
        airportCode: 'IPL',
        cityCode: 'IPL',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Imperial County',
        airportNameEn: 'Imperial County Airport',
        cityNameVi: 'El Centro',
        cityNameEn: 'El Centro',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    HSL: {
        id: 3177,
        airportCode: 'HSL',
        cityCode: 'HSL',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Huslia',
        airportNameEn: 'Huslia Airport ',
        cityNameVi: 'Huslia',
        cityNameEn: 'Huslia',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    HON: {
        id: 3176,
        airportCode: 'HON',
        cityCode: 'HON',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Huron Regional',
        airportNameEn: 'Huron Regional Airport',
        cityNameVi: 'Huron',
        cityNameEn: 'Huron',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    HSV: {
        id: 3175,
        airportCode: 'HSV',
        cityCode: 'HSV',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Huntsville',
        airportNameEn: 'Huntsville International Airport ',
        cityNameVi: 'Huntsville',
        cityNameEn: 'Huntsville',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    HUS: {
        id: 3174,
        airportCode: 'HUS',
        cityCode: 'HUS',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Hughes',
        airportNameEn: 'Hughes Airport',
        cityNameVi: 'Hughes',
        cityNameEn: 'Hughes',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    CMX: {
        id: 3173,
        airportCode: 'CMX',
        cityCode: 'CMX',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Houghton County Memorial',
        airportNameEn: 'Houghton County Memorial Airport',
        cityNameVi: 'Houghton',
        cityNameEn: 'Houghton',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    HNH: {
        id: 3172,
        airportCode: 'HNH',
        cityCode: 'HNH',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Hoonah',
        airportNameEn: 'Hoonah Airport',
        cityNameVi: 'Hoonah',
        cityNameEn: 'Hoonah',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    HOM: {
        id: 3171,
        airportCode: 'HOM',
        cityCode: 'HOM',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Homer',
        airportNameEn: 'Homer Airport',
        cityNameVi: 'Homer',
        cityNameEn: 'Homer',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    HHH: {
        id: 3170,
        airportCode: 'HHH',
        cityCode: 'HHH',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Hilton Head',
        airportNameEn: 'Hilton Head Airport ',
        cityNameVi: 'Hilton Head',
        cityNameEn: 'Hilton Head',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    ITO: {
        id: 3169,
        airportCode: 'ITO',
        cityCode: 'ITO',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Hilo',
        airportNameEn: 'Hilo International Airport',
        cityNameVi: 'Hilo',
        cityNameEn: 'Hilo',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    HSH: {
        id: 3168,
        airportCode: 'HSH',
        cityCode: 'HSH',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Henderson Executive',
        airportNameEn: 'Henderson Executive Airport ',
        cityNameVi: 'Las Vegas',
        cityNameEn: 'Las Vegas',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    FAR: {
        id: 3167,
        airportCode: 'FAR',
        cityCode: 'FAR',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Hector',
        airportNameEn: 'Hector International Airport',
        cityNameVi: 'Fargo',
        cityNameEn: 'Fargo',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    HYS: {
        id: 3166,
        airportCode: 'HYS',
        cityCode: 'HYS',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Hays Regional',
        airportNameEn: 'Hays Regional Airport',
        cityNameVi: 'Hays',
        cityNameEn: 'Hays',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    HVR: {
        id: 3165,
        airportCode: 'HVR',
        cityCode: 'HVR',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Havre City-County',
        airportNameEn: 'Havre City-County Airport',
        cityNameVi: 'Havre',
        cityNameEn: 'Havre',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    PIB: {
        id: 3164,
        airportCode: 'PIB',
        cityCode: 'LUL',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Hattiesburg-Laurel Regional',
        airportNameEn: 'Hattiesburg-Laurel Regional Airport',
        cityNameVi: 'Laurel',
        cityNameEn: 'Laurel',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    BHB: {
        id: 3163,
        airportCode: 'BHB',
        cityCode: 'BHB',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Hancock County-Bar Harbor',
        airportNameEn: 'Hancock County-Bar Harbor Airport',
        cityNameVi: 'Bar Harbor',
        cityNameEn: 'Bar Harbor',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    HNM: {
        id: 3162,
        airportCode: 'HNM',
        cityCode: 'HNM',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Hana',
        airportNameEn: 'Hana Airport',
        cityNameVi: 'Hana',
        cityNameEn: 'Hana',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    HNS: {
        id: 3161,
        airportCode: 'HNS',
        cityCode: 'HNS',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Haines',
        airportNameEn: 'Haines Airport',
        cityNameVi: 'Haines',
        cityNameEn: 'Haines',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    HGR: {
        id: 3160,
        airportCode: 'HGR',
        cityCode: 'HGR',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Hagerstown Regional',
        airportNameEn: 'Hagerstown Regional Airport ',
        cityNameVi: 'Hagerstown',
        cityNameEn: 'Hagerstown',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    GST: {
        id: 3159,
        airportCode: 'GST',
        cityCode: 'GST',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Gustavus',
        airportNameEn: 'Gustavus Airport',
        cityNameVi: 'Glacier Bay',
        cityNameEn: 'Glacier Bay',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    GUC: {
        id: 3158,
        airportCode: 'GUC',
        cityCode: 'GUC',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Gunnison-Crested Butte Regional',
        airportNameEn: 'Gunnison-Crested Butte Regional Airport',
        cityNameVi: 'Gunnison',
        cityNameEn: 'Gunnison',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    GPT: {
        id: 3157,
        airportCode: 'GPT',
        cityCode: 'GPT',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Gulfport-Biloxi',
        airportNameEn: 'Gulfport-Biloxi International Airport',
        cityNameVi: 'Gulfport',
        cityNameEn: 'Gulfport',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    LWB: {
        id: 3156,
        airportCode: 'LWB',
        cityCode: 'LWB',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Greenbrier Valley',
        airportNameEn: 'Greenbrier Valley Airport',
        cityNameVi: 'Greenbrier',
        cityNameEn: 'Greenbrier',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    ROC: {
        id: 3155,
        airportCode: 'ROC',
        cityCode: 'ROC',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Greater Rochester',
        airportNameEn: 'Greater Rochester International Airport',
        cityNameVi: 'Rochester',
        cityNameEn: 'Rochester',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    BGM: {
        id: 3154,
        airportCode: 'BGM',
        cityCode: 'BGM',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Greater Binghamton',
        airportNameEn: 'Greater Binghamton Airport ',
        cityNameVi: 'Binghamton',
        cityNameEn: 'Binghamton',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    CRE: {
        id: 3153,
        airportCode: 'CRE',
        cityCode: 'MYR',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Grand Strand',
        airportNameEn: 'Grand Strand Airport',
        cityNameVi: 'Myrtle Beach',
        cityNameEn: 'Myrtle Beach',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    GJT: {
        id: 3152,
        airportCode: 'GJT',
        cityCode: 'GJT',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Grand Junction Regional',
        airportNameEn: 'Grand Junction Regional Airport ',
        cityNameVi: 'Grand Junction',
        cityNameEn: 'Grand Junction',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    GFK: {
        id: 3151,
        airportCode: 'GFK',
        cityCode: 'GFK',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Grand Forks',
        airportNameEn: 'Grand Forks International Airport',
        cityNameVi: 'Grand Forks',
        cityNameEn: 'Grand Forks',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    GCN: {
        id: 3150,
        airportCode: 'GCN',
        cityCode: 'GCN',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Grand Canyon National Park',
        airportNameEn: 'Grand Canyon National Park Airport',
        cityNameVi: 'Grand Canyon',
        cityNameEn: 'Grand Canyon',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    IWD: {
        id: 3149,
        airportCode: 'IWD',
        cityCode: 'IWD',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Gogebic-Iron County',
        airportNameEn: 'Gogebic-Iron County Airport',
        cityNameVi: 'Ironwood',
        cityNameEn: 'Ironwood',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    FCA: {
        id: 3148,
        airportCode: 'FCA',
        cityCode: 'FCA',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Glacier Park',
        airportNameEn: 'Glacier Park International Airport ',
        cityNameVi: 'Kalispell',
        cityNameEn: 'Kalispell',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    GCC: {
        id: 3147,
        airportCode: 'GCC',
        cityCode: 'GCC',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Gillette-Campbell County',
        airportNameEn: 'Gillette-Campbell County Airport',
        cityNameVi: 'Gillette',
        cityNameEn: 'Gillette',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    GRR: {
        id: 3146,
        airportCode: 'GRR',
        cityCode: 'GRR',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Gerald R. Ford',
        airportNameEn: 'Gerald R. Ford International Airport',
        cityNameVi: 'Grand Rapids',
        cityNameEn: 'Grand Rapids',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    IAH: {
        id: 3145,
        airportCode: 'IAH',
        cityCode: 'HOU',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay George Bush Intercontinental',
        airportNameEn: 'George Bush Intercontinental Airport',
        cityNameVi: 'Houston',
        cityNameEn: 'Houston',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    GYY: {
        id: 3144,
        airportCode: 'GYY',
        cityCode: 'GYY',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Gary/Chicago',
        airportNameEn: 'Gary/Chicago International Airport',
        cityNameVi: 'Gary',
        cityNameEn: 'Gary',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    RIL: {
        id: 3143,
        airportCode: 'RIL',
        cityCode: 'RIL',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Garfield County Regional',
        airportNameEn: 'Garfield County Regional Airport',
        cityNameVi: 'Rifle',
        cityNameEn: 'Rifle',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    GCK: {
        id: 3142,
        airportCode: 'GCK',
        cityCode: 'GCK',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Garden City Regional',
        airportNameEn: 'Garden City Regional Airport',
        cityNameVi: 'Garden City',
        cityNameEn: 'Garden City',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    BZN: {
        id: 3141,
        airportCode: 'BZN',
        cityCode: 'BZN',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Gallatin Field',
        airportNameEn: 'Gallatin Field Airport',
        cityNameVi: 'Bozeman',
        cityNameEn: 'Bozeman',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    GNV: {
        id: 3140,
        airportCode: 'GNV',
        cityCode: 'GNV',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Gainesville Regional',
        airportNameEn: 'Gainesville Regional Airport',
        cityNameVi: 'Gainesville',
        cityNameEn: 'Gainesville',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    FRD: {
        id: 3139,
        airportCode: 'FRD',
        cityCode: 'FRD',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Friday Harbor',
        airportNameEn: 'Friday Harbor Airport ',
        cityNameVi: 'Friday Harbor',
        cityNameEn: 'Friday Harbor',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    FMN: {
        id: 3138,
        airportCode: 'FMN',
        cityCode: 'FMN',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Four Corners Regional',
        airportNameEn: 'Four Corners Regional Airport',
        cityNameVi: 'Farmington',
        cityNameEn: 'Farmington',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    AFW: {
        id: 3137,
        airportCode: 'AFW',
        cityCode: 'AFW',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Fort Worth Alliance',
        airportNameEn: 'Fort Worth Alliance Airport',
        cityNameVi: 'Dallas',
        cityNameEn: 'Dallas',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    IMT: {
        id: 3136,
        airportCode: 'IMT',
        cityCode: 'IMT',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Ford',
        airportNameEn: 'Ford Airport',
        cityNameVi: 'Iron Mountain',
        cityNameEn: 'Iron Mountain',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    EUG: {
        id: 3135,
        airportCode: 'EUG',
        cityCode: 'EUG',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Eugene',
        airportNameEn: 'Eugene Airport / Mahlon Sweet Field',
        cityNameVi: 'Eugene',
        cityNameEn: 'Eugene',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    CDW: {
        id: 3134,
        airportCode: 'CDW',
        cityCode: 'CDW',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Essex County',
        airportNameEn: 'Essex County Airport',
        cityNameVi: 'Caldwell',
        cityNameEn: 'Caldwell',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    ERI: {
        id: 3133,
        airportCode: 'ERI',
        cityCode: 'ERI',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Erie',
        airportNameEn: 'Erie International Airport ',
        cityNameVi: 'Erie',
        cityNameEn: 'Erie',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    ELM: {
        id: 3132,
        airportCode: 'ELM',
        cityCode: 'ELM',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Elmira/Corning Regional',
        airportNameEn: 'Elmira/Corning Regional Airport',
        cityNameVi: 'Elmira',
        cityNameEn: 'Elmira',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    EKO: {
        id: 3131,
        airportCode: 'EKO',
        cityCode: 'EKO',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Elko Regional',
        airportNameEn: 'Elko Regional Airport',
        cityNameVi: 'Elko',
        cityNameEn: 'Elko',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    GAL: {
        id: 3130,
        airportCode: 'GAL',
        cityCode: 'GAL',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Edward G. Pitka Sr.',
        airportNameEn: 'Edward G. Pitka Sr. Airport',
        cityNameVi: 'Galena',
        cityNameEn: 'Galena',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    CLL: {
        id: 3129,
        airportCode: 'CLL',
        cityCode: 'CLL',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Easterwood',
        airportNameEn: 'Easterwood Airport ',
        cityNameVi: 'College Station',
        cityNameEn: 'College Station',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    PDT: {
        id: 3128,
        airportCode: 'PDT',
        cityCode: 'PDT',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Eastern Oregon Regional',
        airportNameEn: 'Eastern Oregon Regional Airport',
        cityNameVi: 'Pendleton',
        cityNameEn: 'Pendleton',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    GGG: {
        id: 3127,
        airportCode: 'GGG',
        cityCode: 'GGG',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay East Texas Regional',
        airportNameEn: 'East Texas Regional Airport',
        cityNameVi: 'Longview',
        cityNameEn: 'Longview',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    EAA: {
        id: 3126,
        airportCode: 'EAA',
        cityCode: 'EAA',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Eagle',
        airportNameEn: 'Eagle Airport',
        cityNameVi: 'Eagle',
        cityNameEn: 'Eagle',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    DLH: {
        id: 3125,
        airportCode: 'DLH',
        cityCode: 'DLH',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Duluth',
        airportNameEn: 'Duluth International Airport',
        cityNameVi: 'Duluth',
        cityNameEn: 'Duluth',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    DBQ: {
        id: 3124,
        airportCode: 'DBQ',
        cityCode: 'DBQ',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Dubuque Regional',
        airportNameEn: 'Dubuque Regional Airport',
        cityNameVi: 'Dubuque',
        cityNameEn: 'Dubuque',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    DHN: {
        id: 3123,
        airportCode: 'DHN',
        cityCode: 'DHN',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Dothan Regional',
        airportNameEn: 'Dothan Regional Airport',
        cityNameVi: 'Dothan',
        cityNameEn: 'Dothan',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    DDC: {
        id: 3122,
        airportCode: 'DDC',
        cityCode: 'DDC',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Dodge City Regional',
        airportNameEn: 'Dodge City Regional Airport',
        cityNameVi: 'Dodge City',
        cityNameEn: 'Dodge City',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    DSM: {
        id: 3121,
        airportCode: 'DSM',
        cityCode: 'DSM',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Des Moines',
        airportNameEn: 'Des Moines International Airport',
        cityNameVi: 'Des Moines',
        cityNameEn: 'Des Moines',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    ESC: {
        id: 3120,
        airportCode: 'ESC',
        cityCode: 'ESC',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Delta County',
        airportNameEn: 'Delta County Airport',
        cityNameVi: 'Escanaba',
        cityNameEn: 'Escanaba',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    DEC: {
        id: 3119,
        airportCode: 'DEC',
        cityCode: 'DEC',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Decatur',
        airportNameEn: 'Decatur Airport',
        cityNameVi: 'Decatur',
        cityNameEn: 'Decatur',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    SCC: {
        id: 3118,
        airportCode: 'SCC',
        cityCode: 'SCC',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Deadhorse',
        airportNameEn: 'Deadhorse Airport',
        cityNameVi: 'Prudhoe Bay Deadhorse',
        cityNameEn: 'Prudhoe Bay Deadhorse',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    DWH: {
        id: 3117,
        airportCode: 'DWH',
        cityCode: 'HOU',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay David Wayne Hooks Memorial',
        airportNameEn: 'David Wayne Hooks Memorial Airport',
        cityNameVi: 'Houston',
        cityNameEn: 'Houston',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    MSN: {
        id: 3116,
        airportCode: 'MSN',
        cityCode: 'MSN',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Dane County Regional',
        airportNameEn: 'Dane County Regional Airport ',
        cityNameVi: 'Madison',
        cityNameEn: 'Madison',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    EWN: {
        id: 3115,
        airportCode: 'EWN',
        cityCode: 'EWN',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Craven County Regional',
        airportNameEn: 'Craven County Regional Airport',
        cityNameVi: 'New Bern',
        cityNameEn: 'New Bern',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    CRG: {
        id: 3114,
        airportCode: 'CRG',
        cityCode: 'JAX',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Craig Municipal',
        airportNameEn: 'Craig Municipal Airport',
        cityNameVi: 'Jacksonville',
        cityNameEn: 'Jacksonville',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    CEZ: {
        id: 3113,
        airportCode: 'CEZ',
        cityCode: 'CEZ',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Cortez Municipal',
        airportNameEn: 'Cortez Municipal Airport',
        cityNameVi: 'Cortez',
        cityNameEn: 'Cortez',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    CRP: {
        id: 3112,
        airportCode: 'CRP',
        cityCode: 'CRP',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Corpus Christi',
        airportNameEn: 'Corpus Christi International Airport',
        cityNameVi: 'Corpus Christi',
        cityNameEn: 'Corpus Christi',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    CVN: {
        id: 3111,
        airportCode: 'CVN',
        cityCode: 'CVN',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Clovis Municipal',
        airportNameEn: 'Clovis Municipal Airport',
        cityNameVi: 'Clovis',
        cityNameEn: 'Clovis',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    HIB: {
        id: 3110,
        airportCode: 'HIB',
        cityCode: 'HIB',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Chisholm-Hibbing',
        airportNameEn: 'Chisholm-Hibbing Airport',
        cityNameVi: 'Hibbing',
        cityNameEn: 'Hibbing',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    EAU: {
        id: 3109,
        airportCode: 'EAU',
        cityCode: 'EAU',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Chippewa Valley Regional',
        airportNameEn: 'Chippewa Valley Regional Airport',
        cityNameVi: 'Eau Claire',
        cityNameEn: 'Eau Claire',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    TVC: {
        id: 3108,
        airportCode: 'TVC',
        cityCode: 'TVC',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Cherry Capital',
        airportNameEn: 'Cherry Capital Airport',
        cityNameVi: 'Traverse City',
        cityNameEn: 'Traverse City',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    JHW: {
        id: 3107,
        airportCode: 'JHW',
        cityCode: 'JHW',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Chautauqua County-Jamestown',
        airportNameEn: 'Chautauqua County-Jamestown Airport',
        cityNameVi: 'Jamestown',
        cityNameEn: 'Jamestown',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    CHA: {
        id: 3106,
        airportCode: 'CHA',
        cityCode: 'CHA',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Chattanooga Metropolitan',
        airportNameEn: 'Chattanooga Metropolitan Airport ',
        cityNameVi: 'Chattanooga',
        cityNameEn: 'Chattanooga',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    CDR: {
        id: 3105,
        airportCode: 'CDR',
        cityCode: 'CDR',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Chadron Municipal',
        airportNameEn: 'Chadron Municipal Airport',
        cityNameVi: 'Chadron',
        cityNameEn: 'Chadron',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    CWA: {
        id: 3104,
        airportCode: 'CWA',
        cityCode: 'AUW',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Central Wisconsin',
        airportNameEn: 'Central Wisconsin Airport',
        cityNameVi: 'Wausau',
        cityNameEn: 'Wausau',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    GRI: {
        id: 3103,
        airportCode: 'GRI',
        cityCode: 'GRI',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Central Nebraska Regional',
        airportNameEn: 'Central Nebraska Regional Airport',
        cityNameVi: 'Grand Island',
        cityNameEn: 'Grand Island',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    BMI: {
        id: 3102,
        airportCode: 'BMI',
        cityCode: 'BMI',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Central Illinois Regional',
        airportNameEn: 'Central Illinois Regional Airport',
        cityNameVi: 'Bloomington',
        cityNameEn: 'Bloomington',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    CDC: {
        id: 3101,
        airportCode: 'CDC',
        cityCode: 'CDC',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Cedar City Regional',
        airportNameEn: 'Cedar City Regional Airport',
        cityNameVi: 'Cedar City',
        cityNameEn: 'Cedar City',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    LAN: {
        id: 3100,
        airportCode: 'LAN',
        cityCode: 'LAN',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Capital Region',
        airportNameEn: 'Capital Region International Airport',
        cityNameVi: 'Lansing',
        cityNameEn: 'Lansing',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    CGI: {
        id: 3099,
        airportCode: 'CGI',
        cityCode: 'CGI',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Cape Girardeau Regional',
        airportNameEn: 'Cape Girardeau Regional Airport',
        cityNameVi: 'Cape Girardeau',
        cityNameEn: 'Cape Girardeau',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    BTV: {
        id: 3098,
        airportCode: 'BTV',
        cityCode: 'BTV',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Burlington',
        airportNameEn: 'Burlington International Airport',
        cityNameVi: 'Burlington',
        cityNameEn: 'Burlington',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    BUF: {
        id: 3097,
        airportCode: 'BUF',
        cityCode: 'BUF',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Buffalo Niagara',
        airportNameEn: 'Buffalo Niagara International Airport',
        cityNameVi: 'Buffalo',
        cityNameEn: 'Buffalo',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    BQK: {
        id: 3096,
        airportCode: 'BQK',
        cityCode: 'BQK',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Brunswick Golden Isles',
        airportNameEn: 'Brunswick Golden Isles Airport',
        cityNameVi: 'Brunswick',
        cityNameEn: 'Brunswick',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    KTS: {
        id: 3095,
        airportCode: 'KTS',
        cityCode: 'KTS',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Brevig Mission',
        airportNameEn: 'Brevig Mission Airport',
        cityNameVi: 'Brevig Mission',
        cityNameEn: 'Brevig Mission',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    BRD: {
        id: 3094,
        airportCode: 'BRD',
        cityCode: 'BRD',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Brainerd Lakes Regional',
        airportNameEn: 'Brainerd Lakes Regional Airport',
        cityNameVi: 'Brainerd',
        cityNameEn: 'Brainerd',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    BDL: {
        id: 3093,
        airportCode: 'BDL',
        cityCode: 'HFD',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Bradley',
        airportNameEn: 'Bradley International Airport',
        cityNameVi: 'Hartford',
        cityNameEn: 'Hartford',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    HRO: {
        id: 3092,
        airportCode: 'HRO',
        cityCode: 'HRO',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Boone County',
        airportNameEn: 'Boone County Airport',
        cityNameVi: 'Harrison',
        cityNameEn: 'Harrison',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    BIS: {
        id: 3091,
        airportCode: 'BIS',
        cityCode: 'BIS',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Bismarck Municipal',
        airportNameEn: 'Bismarck Municipal Airport',
        cityNameVi: 'Bismarck',
        cityNameEn: 'Bismarck',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    FNT: {
        id: 3090,
        airportCode: 'FNT',
        cityCode: 'FNT',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Bishop',
        airportNameEn: 'Bishop International Airport',
        cityNameVi: 'Flint',
        cityNameEn: 'Flint',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    BIL: {
        id: 3089,
        airportCode: 'BIL',
        cityCode: 'BIL',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Billings Logan',
        airportNameEn: 'Billings Logan International Airport',
        cityNameVi: 'Billings',
        cityNameEn: 'Billings',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    BET: {
        id: 3088,
        airportCode: 'BET',
        cityCode: 'BET',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Bethel',
        airportNameEn: 'Bethel Airport',
        cityNameVi: 'Bethel',
        cityNameEn: 'Bethel',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    BTM: {
        id: 3087,
        airportCode: 'BTM',
        cityCode: 'BTM',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Bert Mooney',
        airportNameEn: 'Bert Mooney Airport',
        cityNameVi: 'Butte',
        cityNameEn: 'Butte',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    BJI: {
        id: 3086,
        airportCode: 'BJI',
        cityCode: 'BJI',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Bemidji Regional',
        airportNameEn: 'Bemidji Regional Airport',
        cityNameVi: 'Bemidji',
        cityNameEn: 'Bemidji',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    BLI: {
        id: 3085,
        airportCode: 'BLI',
        cityCode: 'BLI',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Bellingham',
        airportNameEn: 'Bellingham International Airport',
        cityNameVi: 'Bellingham',
        cityNameEn: 'Bellingham',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    HYA: {
        id: 3084,
        airportCode: 'HYA',
        cityCode: 'HYA',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Barnstable Municipal',
        airportNameEn: 'Barnstable Municipal Airport ',
        cityNameVi: 'Hyannis',
        cityNameEn: 'Hyannis',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    PAH: {
        id: 3083,
        airportCode: 'PAH',
        cityCode: 'PAH',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Barkley Regional',
        airportNameEn: 'Barkley Regional Airport',
        cityNameVi: 'Paducah',
        cityNameEn: 'Paducah',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    BGR: {
        id: 3082,
        airportCode: 'BGR',
        cityCode: 'BGR',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Bangor',
        airportNameEn: 'Bangor International Airport',
        cityNameVi: 'Bangor',
        cityNameEn: 'Bangor',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    AUS: {
        id: 3081,
        airportCode: 'AUS',
        cityCode: 'AUS',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Austin-Bergstrom',
        airportNameEn: 'Austin-Bergstrom International Airport',
        cityNameVi: 'Austin',
        cityNameEn: 'Austin',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    AUG: {
        id: 3080,
        airportCode: 'AUG',
        cityCode: 'AUG',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Augusta State',
        airportNameEn: 'Augusta State Airport',
        cityNameVi: 'Augusta',
        cityNameEn: 'Augusta',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    AHN: {
        id: 3079,
        airportCode: 'AHN',
        cityCode: 'AHN',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Athens-Ben Epps',
        airportNameEn: 'Athens-Ben Epps Airport',
        cityNameVi: 'Athens',
        cityNameEn: 'Athens',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    LBE: {
        id: 3078,
        airportCode: 'LBE',
        cityCode: 'LBE',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Arnold Palmer Regional',
        airportNameEn: 'Arnold Palmer Regional Airport',
        cityNameVi: 'Latrobe',
        cityNameEn: 'Latrobe',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    ACV: {
        id: 3077,
        airportCode: 'ACV',
        cityCode: 'ACV',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Arcata-Eureka',
        airportNameEn: 'Arcata-Eureka Airport',
        cityNameVi: 'Eureka',
        cityNameEn: 'Eureka',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    ANI: {
        id: 3076,
        airportCode: 'ANI',
        cityCode: 'ANI',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Aniak',
        airportNameEn: 'Aniak Airport',
        cityNameVi: 'Aniak',
        cityNameEn: 'Aniak',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    ABL: {
        id: 3075,
        airportCode: 'ABL',
        cityCode: 'ABL',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Ambler',
        airportNameEn: 'Ambler Airport ',
        cityNameVi: 'Ambler',
        cityNameEn: 'Ambler',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    AOO: {
        id: 3074,
        airportCode: 'AOO',
        cityCode: 'AOO',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Altoona-Blair County',
        airportNameEn: 'Altoona-Blair County Airport',
        cityNameVi: 'Altoona',
        cityNameEn: 'Altoona',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    APN: {
        id: 3073,
        airportCode: 'APN',
        cityCode: 'APN',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Alpena County Regional',
        airportNameEn: 'Alpena County Regional Airport',
        cityNameVi: 'Alpena',
        cityNameEn: 'Alpena',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    ALB: {
        id: 3072,
        airportCode: 'ALB',
        cityCode: 'ALB',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Albany',
        airportNameEn: 'Albany International Airport',
        cityNameVi: 'Albany',
        cityNameEn: 'Albany',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    CAK: {
        id: 3071,
        airportCode: 'CAK',
        cityCode: 'CAK',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Akron-Canton Regional',
        airportNameEn: 'Akron-Canton Regional Airport',
        cityNameVi: 'Akron',
        cityNameEn: 'Akron',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    SLK: {
        id: 3070,
        airportCode: 'SLK',
        cityCode: 'SLK',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Adirondack Regional',
        airportNameEn: 'Adirondack Regional Airport',
        cityNameVi: 'Saranac Lake',
        cityNameEn: 'Saranac Lake',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    ADS: {
        id: 3069,
        airportCode: 'ADS',
        cityCode: 'ADS',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Addison',
        airportNameEn: 'Addison Airport',
        cityNameVi: 'Dallas',
        cityNameEn: 'Dallas',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    ADK: {
        id: 3068,
        airportCode: 'ADK',
        cityCode: 'ADK',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Adak Island',
        airportNameEn: 'Adak Island Airport',
        cityNameVi: 'Adak Island',
        cityNameEn: 'Adak Island',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    YUM: {
        id: 3067,
        airportCode: 'YUM',
        cityCode: 'YUM',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Yuma',
        airportNameEn: 'Yuma International Airport / MCAS Yuma ',
        cityNameVi: 'Yuma',
        cityNameEn: 'Yuma',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    YKM: {
        id: 3066,
        airportCode: 'YKM',
        cityCode: 'YKM',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Yakima Air Terminal ',
        airportNameEn: 'Yakima Air Terminal ',
        cityNameVi: 'Yakima',
        cityNameEn: 'Yakima',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    INT: {
        id: 3065,
        airportCode: 'INT',
        cityCode: 'INT',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Smith Reynolds',
        airportNameEn: 'Smith Reynolds Airport',
        cityNameVi: 'Winston-Salem',
        cityNameEn: 'Winston-Salem',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    ILM: {
        id: 3064,
        airportCode: 'ILM',
        cityCode: 'ILM',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Wilmington',
        airportNameEn: 'Wilmington International Airport',
        cityNameVi: 'Wilmington',
        cityNameEn: 'Wilmington',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    ILG: {
        id: 3063,
        airportCode: 'ILG',
        cityCode: 'ILG',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay New Castle',
        airportNameEn: 'New Castle Airport',
        cityNameVi: 'Wilmington',
        cityNameEn: 'Wilmington',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    ISN: {
        id: 3062,
        airportCode: 'ISN',
        cityCode: 'ISN',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Sloulin Field',
        airportNameEn: 'Sloulin Field International Airport',
        cityNameVi: 'Williston',
        cityNameEn: 'Williston',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    IPT: {
        id: 3061,
        airportCode: 'IPT',
        cityCode: 'IPT',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Williamsport Regional',
        airportNameEn: 'Williamsport Regional Airport',
        cityNameVi: 'Williamsport',
        cityNameEn: 'Williamsport',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    SPS: {
        id: 3060,
        airportCode: 'SPS',
        cityCode: 'SPS',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Sheppard AFB / Wichita Falls Municipal',
        airportNameEn: 'Sheppard AFB / Wichita Falls Municipal Airport',
        cityNameVi: 'Wichita Falls',
        cityNameEn: 'Wichita Falls',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    ICT: {
        id: 3059,
        airportCode: 'ICT',
        cityCode: 'ICT',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Wichita Mid-Continent',
        airportNameEn: 'Wichita Mid-Continent Airport',
        cityNameVi: 'Wichita',
        cityNameEn: 'Wichita',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    HPN: {
        id: 3058,
        airportCode: 'HPN',
        cityCode: 'HPN',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Westchester County',
        airportNameEn: 'Westchester County Airport',
        cityNameVi: 'White Plains',
        cityNameEn: 'White Plains',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    WYS: {
        id: 3057,
        airportCode: 'WYS',
        cityCode: 'WYS',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Yellowstone',
        airportNameEn: 'Yellowstone Airport',
        cityNameVi: 'West Yellowstone',
        cityNameEn: 'West Yellowstone',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    LNA: {
        id: 3056,
        airportCode: 'LNA',
        cityCode: 'PBI',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Palm Beach County Park',
        airportNameEn: 'Palm Beach County Park Airport',
        cityNameVi: 'West Palm Beach',
        cityNameEn: 'West Palm Beach',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    PBI: {
        id: 3055,
        airportCode: 'PBI',
        cityCode: 'PBI',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Palm Beach',
        airportNameEn: 'Palm Beach International Airport',
        cityNameVi: 'West Palm Beach',
        cityNameEn: 'West Palm Beach',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    EAT: {
        id: 3054,
        airportCode: 'EAT',
        cityCode: 'EAT',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Pangborn Memorial',
        airportNameEn: 'Pangborn Memorial Airport',
        cityNameVi: 'Wenatchee',
        cityNameEn: 'Wenatchee',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    ATY: {
        id: 3053,
        airportCode: 'ATY',
        cityCode: 'ATY',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Watertown Regional',
        airportNameEn: 'Watertown Regional Airport',
        cityNameVi: 'Watertown',
        cityNameEn: 'Watertown',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    ART: {
        id: 3052,
        airportCode: 'ART',
        cityCode: 'ART',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Watertown',
        airportNameEn: 'Watertown International Airport',
        cityNameVi: 'Watertown',
        cityNameEn: 'Watertown',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    ALO: {
        id: 3051,
        airportCode: 'ALO',
        cityCode: 'ALO',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Waterloo Regional',
        airportNameEn: 'Waterloo Regional Airport',
        cityNameVi: 'Waterloo',
        cityNameEn: 'Waterloo',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    WAS: {
        id: 3050,
        airportCode: 'WAS',
        cityCode: 'WAS',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Tất cả các sân bay',
        airportNameEn: 'All Airports',
        cityNameVi: 'Washington',
        cityNameEn: 'Washington',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    IAD: {
        id: 3049,
        airportCode: 'IAD',
        cityCode: 'WAS',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Washington Dulles',
        airportNameEn: 'Washington Dulles International Airport',
        cityNameVi: 'Washington',
        cityNameEn: 'Washington',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    DCA: {
        id: 3048,
        airportCode: 'DCA',
        cityCode: 'WAS',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Ronald Reagan Washington National',
        airportNameEn: 'Ronald Reagan Washington National Airport',
        cityNameVi: 'Washington',
        cityNameEn: 'Washington',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    VCT: {
        id: 3047,
        airportCode: 'VCT',
        cityCode: 'VCT',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Victoria Regional',
        airportNameEn: 'Victoria Regional Airport',
        cityNameVi: 'Victoria',
        cityNameEn: 'Victoria',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    VLD: {
        id: 3046,
        airportCode: 'VLD',
        cityCode: 'VLD',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Valdosta Regional',
        airportNameEn: 'Valdosta Regional Airport',
        cityNameVi: 'Valdosta',
        cityNameEn: 'Valdosta',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    TYR: {
        id: 3045,
        airportCode: 'TYR',
        cityCode: 'TYR',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Tyler Pounds Regional',
        airportNameEn: 'Tyler Pounds Regional Airport',
        cityNameVi: 'Tyler',
        cityNameEn: 'Tyler',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    TWF: {
        id: 3044,
        airportCode: 'TWF',
        cityCode: 'TWF',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Magic Valley Regional',
        airportNameEn: 'Magic Valley Regional Airport ',
        cityNameVi: 'Twin Falls',
        cityNameEn: 'Twin Falls',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    TUL: {
        id: 3043,
        airportCode: 'TUL',
        cityCode: 'TUL',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Tulsa',
        airportNameEn: 'Tulsa International Airport',
        cityNameVi: 'Tulsa',
        cityNameEn: 'Tulsa',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    TUS: {
        id: 3042,
        airportCode: 'TUS',
        cityCode: 'TUS',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Tucson',
        airportNameEn: 'Tucson International Airport',
        cityNameVi: 'Tucson',
        cityNameEn: 'Tucson',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    TTN: {
        id: 3041,
        airportCode: 'TTN',
        cityCode: 'TTN',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Trenton-Mercer',
        airportNameEn: 'Trenton-Mercer Airport',
        cityNameVi: 'Trenton',
        cityNameEn: 'Trenton',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    TOL: {
        id: 3040,
        airportCode: 'TOL',
        cityCode: 'TOL',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Toledo Express',
        airportNameEn: 'Toledo Express Airport',
        cityNameVi: 'Toledo',
        cityNameEn: 'Toledo',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    TVF: {
        id: 3039,
        airportCode: 'TVF',
        cityCode: 'TVF',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Thief River Falls Regional',
        airportNameEn: 'Thief River Falls Regional Airport',
        cityNameVi: 'Thief River Falls',
        cityNameEn: 'Thief River Falls',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    TXK: {
        id: 3038,
        airportCode: 'TXK',
        cityCode: 'TXK',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Texarkana Regional',
        airportNameEn: 'Texarkana Regional Airport ',
        cityNameVi: 'Texarkana',
        cityNameEn: 'Texarkana',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    TPF: {
        id: 3037,
        airportCode: 'TPF',
        cityCode: 'TPA',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Peter O. Knight',
        airportNameEn: 'Peter O. Knight Airport',
        cityNameVi: 'Tampa',
        cityNameEn: 'Tampa',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    SYR: {
        id: 3036,
        airportCode: 'SYR',
        cityCode: 'SYR',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Syracuse Hancock',
        airportNameEn: 'Syracuse Hancock International Airport',
        cityNameVi: 'Syracuse',
        cityNameEn: 'Syracuse',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    SUN: {
        id: 3035,
        airportCode: 'SUN',
        cityCode: 'SUN',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Friedman Memorial',
        airportNameEn: 'Friedman Memorial Airport',
        cityNameVi: 'Sun Valley',
        cityNameEn: 'Sun Valley',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    SCK: {
        id: 3034,
        airportCode: 'SCK',
        cityCode: 'SCK',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Stockton Metropolitan',
        airportNameEn: 'Stockton Metropolitan Airport',
        cityNameVi: 'Stockton',
        cityNameEn: 'Stockton',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    SCE: {
        id: 3033,
        airportCode: 'SCE',
        cityCode: 'SCE',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay University Park',
        airportNameEn: 'University Park Airport ',
        cityNameVi: 'State College',
        cityNameEn: 'State College',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    PIE: {
        id: 3032,
        airportCode: 'PIE',
        cityCode: 'PIE',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay St. Petersburg-Clearwater',
        airportNameEn: 'St. Petersburg-Clearwater International Airport',
        cityNameVi: 'St Petersburg',
        cityNameEn: 'St Petersburg',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    CPS: {
        id: 3031,
        airportCode: 'CPS',
        cityCode: 'STL',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay St. Louis Downtown',
        airportNameEn: 'St. Louis Downtown Airport',
        cityNameVi: 'St Louis',
        cityNameEn: 'St Louis',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    SUS: {
        id: 3030,
        airportCode: 'SUS',
        cityCode: 'STL',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Spirit of St. Louis',
        airportNameEn: 'Spirit of St. Louis Airport',
        cityNameVi: 'St Louis',
        cityNameEn: 'St Louis',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    SGU: {
        id: 3029,
        airportCode: 'SGU',
        cityCode: 'SGU',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay St. George Municipal',
        airportNameEn: 'St. George Municipal Airport',
        cityNameVi: 'St George',
        cityNameEn: 'St George',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    SPI: {
        id: 3028,
        airportCode: 'SPI',
        cityCode: 'SPI',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Abraham Lincoln Capital',
        airportNameEn: 'Abraham Lincoln Capital Airport',
        cityNameVi: 'Springfield',
        cityNameEn: 'Springfield',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    SFF: {
        id: 3027,
        airportCode: 'SFF',
        cityCode: 'GEG',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Felts Field',
        airportNameEn: 'Felts Field',
        cityNameVi: 'Spokane',
        cityNameEn: 'Spokane',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    GEG: {
        id: 3026,
        airportCode: 'GEG',
        cityCode: 'GEG',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Spokane',
        airportNameEn: 'Spokane International Airport',
        cityNameVi: 'Spokane',
        cityNameEn: 'Spokane',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    FSD: {
        id: 3025,
        airportCode: 'FSD',
        cityCode: 'FSD',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Sioux Falls Regional',
        airportNameEn: 'Sioux Falls Regional Airport ',
        cityNameVi: 'Sioux Falls',
        cityNameEn: 'Sioux Falls',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    SUX: {
        id: 3024,
        airportCode: 'SUX',
        cityCode: 'SUX',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Sioux Gateway',
        airportNameEn: 'Sioux Gateway Airport ',
        cityNameVi: 'Sioux City',
        cityNameEn: 'Sioux City',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    SVC: {
        id: 3023,
        airportCode: 'SVC',
        cityCode: 'SVC',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Grant County',
        airportNameEn: 'Grant County Airport',
        cityNameVi: 'Silver City',
        cityNameEn: 'Silver City',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    SDY: {
        id: 3022,
        airportCode: 'SDY',
        cityCode: 'SDY',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Sidney-Richland Municipal',
        airportNameEn: 'Sidney-Richland Municipal Airport',
        cityNameVi: 'Sidney',
        cityNameEn: 'Sidney',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    SHV: {
        id: 3021,
        airportCode: 'SHV',
        cityCode: 'SHV',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Shreveport Regional',
        airportNameEn: 'Shreveport Regional Airport',
        cityNameVi: 'Shreveport',
        cityNameEn: 'Shreveport',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    SHR: {
        id: 3020,
        airportCode: 'SHR',
        cityCode: 'SHR',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Sheridan County',
        airportNameEn: 'Sheridan County Airport',
        cityNameVi: 'Sheridan',
        cityNameEn: 'Sheridan',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    KEH: {
        id: 3019,
        airportCode: 'KEH',
        cityCode: 'SEA',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Kenmore Air Harbor ',
        airportNameEn: 'Kenmore Air Harbor ',
        cityNameVi: 'Seattle',
        cityNameEn: 'Seattle',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    LKE: {
        id: 3018,
        airportCode: 'LKE',
        cityCode: 'SEA',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Kenmore Air Harbor Seaplane Base ',
        airportNameEn: 'Kenmore Air Harbor Seaplane Base ',
        cityNameVi: 'Seattle',
        cityNameEn: 'Seattle',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    SEA: {
        id: 3017,
        airportCode: 'SEA',
        cityCode: 'SEA',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Seattle–Tacoma',
        airportNameEn: 'Seattle–Tacoma International Airport',
        cityNameVi: 'Seattle',
        cityNameEn: 'Seattle',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    BFI: {
        id: 3016,
        airportCode: 'BFI',
        cityCode: 'SEA',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Boeing Field/King County',
        airportNameEn: 'Boeing Field/King County International Airport',
        cityNameVi: 'Seattle',
        cityNameEn: 'Seattle',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    AVP: {
        id: 3015,
        airportCode: 'AVP',
        cityCode: 'AVP',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Wilkes-Barre/Scranton',
        airportNameEn: 'Wilkes-Barre/Scranton International Airport',
        cityNameVi: 'Scranton',
        cityNameEn: 'Scranton',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    BFF: {
        id: 3014,
        airportCode: 'BFF',
        cityCode: 'BFF',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Western Nebraska Regional',
        airportNameEn: 'Western Nebraska Regional Airport ',
        cityNameVi: 'Scottsbluff',
        cityNameEn: 'Scottsbluff',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    SAV: {
        id: 3013,
        airportCode: 'SAV',
        cityCode: 'SAV',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Savannah/Hilton Head',
        airportNameEn: 'Savannah/Hilton Head International Airport',
        cityNameVi: 'Savannah',
        cityNameEn: 'Savannah',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    CIU: {
        id: 3012,
        airportCode: 'CIU',
        cityCode: 'SSM',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Chippewa County',
        airportNameEn: 'Chippewa County International Airport',
        cityNameVi: 'Sault Ste Marie',
        cityNameEn: 'Sault Ste Marie',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    STS: {
        id: 3011,
        airportCode: 'STS',
        cityCode: 'STS',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Charles M. Schulz Sonoma County',
        airportNameEn: 'Charles M. Schulz Sonoma County Airport',
        cityNameVi: 'Santa Rosa',
        cityNameEn: 'Santa Rosa',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    SAF: {
        id: 3010,
        airportCode: 'SAF',
        cityCode: 'SAF',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Santa Fe Municipal',
        airportNameEn: 'Santa Fe Municipal Airport',
        cityNameVi: 'Santa Fe',
        cityNameEn: 'Santa Fe',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    CSL: {
        id: 3009,
        airportCode: 'CSL',
        cityCode: 'CSL',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: "O'Sullivan Army Heliport",
        airportNameEn: "O'Sullivan Army Heliport",
        cityNameVi: 'San Luis Obispo',
        cityNameEn: 'San Luis Obispo',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    RHV: {
        id: 3008,
        airportCode: 'RHV',
        cityCode: 'SJC',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Reid-Hillview',
        airportNameEn: 'Reid-Hillview Airport of Santa Clara County',
        cityNameVi: 'San Jose',
        cityNameEn: 'San Jose',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    SFO: {
        id: 3007,
        airportCode: 'SFO',
        cityCode: 'SFO',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay San Francisco',
        airportNameEn: 'San Francisco International Airport',
        cityNameVi: 'San Francisco',
        cityNameEn: 'San Francisco',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    MYF: {
        id: 3006,
        airportCode: 'MYF',
        cityCode: 'SAN',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Montgomery Field',
        airportNameEn: 'Montgomery Field',
        cityNameVi: 'San Diego',
        cityNameEn: 'San Diego',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    SDM: {
        id: 3005,
        airportCode: 'SDM',
        cityCode: 'SAN',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Brown Field Municipal',
        airportNameEn: 'Brown Field Municipal Airport',
        cityNameVi: 'San Diego',
        cityNameEn: 'San Diego',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    SSF: {
        id: 3004,
        airportCode: 'SSF',
        cityCode: 'SSF',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Stinson Municipal',
        airportNameEn: 'Stinson Municipal Airport',
        cityNameVi: 'San Antonio',
        cityNameEn: 'San Antonio',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    SAT: {
        id: 3003,
        airportCode: 'SAT',
        cityCode: 'SAT',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay San Antonio',
        airportNameEn: 'San Antonio International Airport',
        cityNameVi: 'San Antonio',
        cityNameEn: 'San Antonio',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    SJT: {
        id: 3002,
        airportCode: 'SJT',
        cityCode: 'SJT',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay San Angelo Regional',
        airportNameEn: 'San Angelo Regional Airport ',
        cityNameVi: 'San Angelo',
        cityNameEn: 'San Angelo',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    SLC: {
        id: 3001,
        airportCode: 'SLC',
        cityCode: 'SLC',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Salt Lake City',
        airportNameEn: 'Salt Lake City International Airport',
        cityNameVi: 'Salt Lake City',
        cityNameEn: 'Salt Lake City',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    STC: {
        id: 3000,
        airportCode: 'STC',
        cityCode: 'STC',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay St. Cloud Regional',
        airportNameEn: 'St. Cloud Regional Airport',
        cityNameVi: 'Saint Cloud',
        cityNameEn: 'Saint Cloud',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    MBS: {
        id: 2999,
        airportCode: 'MBS',
        cityCode: 'MBS',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay MBS',
        airportNameEn: 'MBS International Airport',
        cityNameVi: 'Saginaw',
        cityNameEn: 'Saginaw',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    MHR: {
        id: 2998,
        airportCode: 'MHR',
        cityCode: 'SAC',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Sacramento Mather',
        airportNameEn: 'Sacramento Mather Airport',
        cityNameVi: 'Sacramento',
        cityNameEn: 'Sacramento',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    SMF: {
        id: 2997,
        airportCode: 'SMF',
        cityCode: 'SAC',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Sacramento',
        airportNameEn: 'Sacramento International Airport',
        cityNameVi: 'Sacramento',
        cityNameEn: 'Sacramento',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    RUT: {
        id: 2996,
        airportCode: 'RUT',
        cityCode: 'RUT',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Rutland State',
        airportNameEn: 'Rutland State Airport',
        cityNameVi: 'Rutland',
        cityNameEn: 'Rutland',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    ROW: {
        id: 2995,
        airportCode: 'ROW',
        cityCode: 'ROW',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Roswell International Air Center',
        airportNameEn: 'Roswell International Air Center',
        cityNameVi: 'Roswell',
        cityNameEn: 'Roswell',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    RKD: {
        id: 2994,
        airportCode: 'RKD',
        cityCode: 'RKD',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Knox County Regional',
        airportNameEn: 'Knox County Regional Airport',
        cityNameVi: 'Rockland',
        cityNameEn: 'Rockland',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    RFD: {
        id: 2993,
        airportCode: 'RFD',
        cityCode: 'RFD',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Chicago Rockford',
        airportNameEn: 'Chicago Rockford International Airport ',
        cityNameVi: 'Rockford',
        cityNameEn: 'Rockford',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    RCE: {
        id: 2992,
        airportCode: 'RCE',
        cityCode: 'RCE',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Roche Harbor Seaplane Base',
        airportNameEn: 'Roche Harbor Seaplane Base',
        cityNameVi: 'Roche Harbor',
        cityNameEn: 'Roche Harbor',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    RIC: {
        id: 2991,
        airportCode: 'RIC',
        cityCode: 'RIC',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Richmond',
        airportNameEn: 'Richmond International Airport',
        cityNameVi: 'Richmond',
        cityNameEn: 'Richmond',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    RHI: {
        id: 2990,
        airportCode: 'RHI',
        cityCode: 'RHI',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Rhinelander-Oneida County',
        airportNameEn: 'Rhinelander-Oneida County Airport',
        cityNameVi: 'Rhinelander',
        cityNameEn: 'Rhinelander',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    RDM: {
        id: 2989,
        airportCode: 'RDM',
        cityCode: 'RDM',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Roberts Field',
        airportNameEn: 'Roberts Field',
        cityNameVi: 'Redmond Bend',
        cityNameEn: 'Redmond Bend',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    PUW: {
        id: 2988,
        airportCode: 'PUW',
        cityCode: 'PUW',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Pullman-Moscow Regional',
        airportNameEn: 'Pullman-Moscow Regional Airport',
        cityNameVi: 'Pullman',
        cityNameEn: 'Pullman',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    PVC: {
        id: 2987,
        airportCode: 'PVC',
        cityCode: 'PVC',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Provincetown Municipal',
        airportNameEn: 'Provincetown Municipal Airport',
        cityNameVi: 'Provincetown',
        cityNameEn: 'Provincetown',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    PVD: {
        id: 2986,
        airportCode: 'PVD',
        cityCode: 'PVD',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Theodore Francis Green State',
        airportNameEn: 'Theodore Francis Green State Airport',
        cityNameVi: 'Providence',
        cityNameEn: 'Providence',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    HPV: {
        id: 2985,
        airportCode: 'HPV',
        cityCode: 'HPV',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Princeville',
        airportNameEn: 'Princeville Airport ',
        cityNameVi: 'Princeville',
        cityNameEn: 'Princeville',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    PRC: {
        id: 2984,
        airportCode: 'PRC',
        cityCode: 'PRC',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Ernest A. Love Field',
        airportNameEn: 'Ernest A. Love Field',
        cityNameVi: 'Prescott',
        cityNameEn: 'Prescott',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    PSM: {
        id: 2983,
        airportCode: 'PSM',
        cityCode: 'PSM',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Pease International Tradeport',
        airportNameEn: 'Pease International Tradeport',
        cityNameVi: 'Portsmouth',
        cityNameEn: 'Portsmouth',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    PDX: {
        id: 2982,
        airportCode: 'PDX',
        cityCode: 'PDX',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Portland',
        airportNameEn: 'Portland International Airport',
        cityNameVi: 'Portland (Oregon)',
        cityNameEn: 'Portland (Oregon)',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    PWM: {
        id: 2981,
        airportCode: 'PWM',
        cityCode: 'PWM',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Portland International Jetport',
        airportNameEn: 'Portland International Jetport',
        cityNameVi: 'Portland (Maine)',
        cityNameEn: 'Portland (Maine)',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    AGC: {
        id: 2980,
        airportCode: 'AGC',
        cityCode: 'PIT',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Allegheny County',
        airportNameEn: 'Allegheny County Airport',
        cityNameVi: 'Pittsburgh',
        cityNameEn: 'Pittsburgh',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    PHX: {
        id: 2979,
        airportCode: 'PHX',
        cityCode: 'PHX',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Phoenix Sky Harbor',
        airportNameEn: 'Phoenix Sky Harbor International Airport',
        cityNameVi: 'Phoenix',
        cityNameEn: 'Phoenix',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    PHL: {
        id: 2978,
        airportCode: 'PHL',
        cityCode: 'PHL',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Philadelphia',
        airportNameEn: 'Philadelphia International Airport',
        cityNameVi: 'Philadelphia',
        cityNameEn: 'Philadelphia',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    PNE: {
        id: 2977,
        airportCode: 'PNE',
        cityCode: 'PHL',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Northeast Philadelphia',
        airportNameEn: 'Northeast Philadelphia Airport',
        cityNameVi: 'Philadelphia',
        cityNameEn: 'Philadelphia',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    PIA: {
        id: 2976,
        airportCode: 'PIA',
        cityCode: 'PIA',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Greater Peoria Regional',
        airportNameEn: 'Greater Peoria Regional Airport',
        cityNameVi: 'Peoria',
        cityNameEn: 'Peoria',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    PNS: {
        id: 2975,
        airportCode: 'PNS',
        cityCode: 'PNS',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Pensacola',
        airportNameEn: 'Pensacola International Airport',
        cityNameVi: 'Pensacola',
        cityNameEn: 'Pensacola',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    PLN: {
        id: 2974,
        airportCode: 'PLN',
        cityCode: 'PLN',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Pellston Regional',
        airportNameEn: 'Pellston Regional Airport',
        cityNameVi: 'Pellston',
        cityNameEn: 'Pellston',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    PSC: {
        id: 2973,
        airportCode: 'PSC',
        cityCode: 'PSC',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Tri-Cities',
        airportNameEn: 'Tri-Cities Airport',
        cityNameVi: 'Pasco',
        cityNameEn: 'Pasco',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    PKB: {
        id: 2972,
        airportCode: 'PKB',
        cityCode: 'PKB',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Mid-Ohio Valley Regional',
        airportNameEn: 'Mid-Ohio Valley Regional Airport',
        cityNameVi: 'Parkersburg',
        cityNameEn: 'Parkersburg',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    PFN: {
        id: 2971,
        airportCode: 'PFN',
        cityCode: 'PFN',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Panama City-Bay County',
        airportNameEn: 'Panama City-Bay County International Airport',
        cityNameVi: 'Panama City',
        cityNameEn: 'Panama City',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    PSP: {
        id: 2970,
        airportCode: 'PSP',
        cityCode: 'PSP',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Palm Springs',
        airportNameEn: 'Palm Springs International Airport',
        cityNameVi: 'Palm Springs',
        cityNameEn: 'Palm Springs',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    UDD: {
        id: 2969,
        airportCode: 'UDD',
        cityCode: 'UDD',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Bermuda Dunes',
        airportNameEn: 'Bermuda Dunes Airport',
        cityNameVi: 'Palm Desert',
        cityNameEn: 'Palm Desert',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    PGA: {
        id: 2968,
        airportCode: 'PGA',
        cityCode: 'PGA',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Page Municipal',
        airportNameEn: 'Page Municipal Airport',
        cityNameVi: 'Page',
        cityNameEn: 'Page',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    OWB: {
        id: 2967,
        airportCode: 'OWB',
        cityCode: 'OWB',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Owensboro-Daviess County',
        airportNameEn: 'Owensboro-Daviess County Airport',
        cityNameVi: 'Owensboro',
        cityNameEn: 'Owensboro',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    MCO: {
        id: 2966,
        airportCode: 'MCO',
        cityCode: 'ORL',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Orlando',
        airportNameEn: 'Orlando International Airport',
        cityNameVi: 'Orlando',
        cityNameEn: 'Orlando',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    ONT: {
        id: 2965,
        airportCode: 'ONT',
        cityCode: 'ONT',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Ontario',
        airportNameEn: 'Ontario International Airport',
        cityNameVi: 'Ontario',
        cityNameEn: 'Ontario',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    MIQ: {
        id: 2964,
        airportCode: 'MIQ',
        cityCode: 'OMA',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Millard',
        airportNameEn: 'Millard Airport ',
        cityNameVi: 'Omaha',
        cityNameEn: 'Omaha',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    DWN: {
        id: 2963,
        airportCode: 'DWN',
        cityCode: 'OKC',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Downtown Airpark ',
        airportNameEn: 'Downtown Airpark ',
        cityNameVi: 'Oklahoma City',
        cityNameEn: 'Oklahoma City',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    PWA: {
        id: 2962,
        airportCode: 'PWA',
        cityCode: 'OKC',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Wiley Post',
        airportNameEn: 'Wiley Post Airport',
        cityNameVi: 'Oklahoma City',
        cityNameEn: 'Oklahoma City',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    JCE: {
        id: 2961,
        airportCode: 'JCE',
        cityCode: 'JCE',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Oakland Convention Center Heliport ',
        airportNameEn: 'Oakland Convention Center Heliport ',
        cityNameVi: 'Oakland',
        cityNameEn: 'Oakland',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    OAK: {
        id: 2960,
        airportCode: 'OAK',
        cityCode: 'OAK',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Oakland',
        airportNameEn: 'Oakland International Airport',
        cityNameVi: 'Oakland',
        cityNameEn: 'Oakland',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    LBF: {
        id: 2959,
        airportCode: 'LBF',
        cityCode: 'LBF',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay North Platte Regional',
        airportNameEn: 'North Platte Regional Airport ',
        cityNameVi: 'North Platte',
        cityNameEn: 'North Platte',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    OTH: {
        id: 2958,
        airportCode: 'OTH',
        cityCode: 'OTH',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay North Bend Municipal',
        airportNameEn: 'North Bend Municipal Airport',
        cityNameVi: 'North Bend',
        cityNameEn: 'North Bend',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    IAG: {
        id: 2957,
        airportCode: 'IAG',
        cityCode: 'IAG',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Niagara Falls',
        airportNameEn: 'Niagara Falls International Airport',
        cityNameVi: 'Niagara Falls',
        cityNameEn: 'Niagara Falls',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    NYC: {
        id: 2956,
        airportCode: 'NYC',
        cityCode: 'NYC',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Tất cả các sân bay',
        airportNameEn: 'All Airports',
        cityNameVi: 'New York City',
        cityNameEn: 'New York City',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    EWR: {
        id: 2955,
        airportCode: 'EWR',
        cityCode: 'NYC',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Newark Liberty',
        airportNameEn: 'Newark Liberty International Airport',
        cityNameVi: 'New York',
        cityNameEn: 'New York',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    LGA: {
        id: 2954,
        airportCode: 'LGA',
        cityCode: 'NYC',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay LaGuardia',
        airportNameEn: 'LaGuardia Airport',
        cityNameVi: 'New York',
        cityNameEn: 'New York',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    JFK: {
        id: 2953,
        airportCode: 'JFK',
        cityCode: 'NYC',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay John F. Kennedy',
        airportNameEn: 'John F. Kennedy International Airport',
        cityNameVi: 'New York',
        cityNameEn: 'New York',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    NEW: {
        id: 2952,
        airportCode: 'NEW',
        cityCode: 'MSY',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay New Orleans Lakefront',
        airportNameEn: 'New Orleans Lakefront Airport',
        cityNameVi: 'New Orleans',
        cityNameEn: 'New Orleans',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    MSY: {
        id: 2951,
        airportCode: 'MSY',
        cityCode: 'MSY',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Louis Armstrong New Orleans',
        airportNameEn: 'Louis Armstrong New Orleans International Airport ',
        cityNameVi: 'New Orleans',
        cityNameEn: 'New Orleans',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    BNA: {
        id: 2950,
        airportCode: 'BNA',
        cityCode: 'BNA',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Nashville',
        airportNameEn: 'Nashville International Airport',
        cityNameVi: 'Nashville',
        cityNameEn: 'Nashville',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    MSP: {
        id: 2949,
        airportCode: 'MSP',
        cityCode: 'MSP',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Minneapolis–Saint Paul',
        airportNameEn: 'Minneapolis–Saint Paul International Airport ',
        cityNameVi: 'Minneapolis',
        cityNameEn: 'Minneapolis',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    FCM: {
        id: 2948,
        airportCode: 'FCM',
        cityCode: 'FCM',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Flying Cloud',
        airportNameEn: 'Flying Cloud Airport',
        cityNameVi: 'Minneapolis',
        cityNameEn: 'Minneapolis',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    MIC: {
        id: 2947,
        airportCode: 'MIC',
        cityCode: 'MIC',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Crystal',
        airportNameEn: 'Crystal Airport',
        cityNameVi: 'Minneapolis',
        cityNameEn: 'Minneapolis',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    MAF: {
        id: 2946,
        airportCode: 'MAF',
        cityCode: 'MAF',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Midland',
        airportNameEn: 'Midland International Airport',
        cityNameVi: 'Midland',
        cityNameEn: 'Midland',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    MPB: {
        id: 2945,
        airportCode: 'MPB',
        cityCode: 'MIA',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Miami Seaplane Base ',
        airportNameEn: 'Miami Seaplane Base ',
        cityNameVi: 'Miami',
        cityNameEn: 'Miami',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    OPF: {
        id: 2944,
        airportCode: 'OPF',
        cityCode: 'MIA',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Opa-locka',
        airportNameEn: 'Opa-locka Airport',
        cityNameVi: 'Miami',
        cityNameEn: 'Miami',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    TMB: {
        id: 2943,
        airportCode: 'TMB',
        cityCode: 'MIA',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Kendall-Tamiami Executive',
        airportNameEn: 'Kendall-Tamiami Executive Airport',
        cityNameVi: 'Miami',
        cityNameEn: 'Miami',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    NQA: {
        id: 2942,
        airportCode: 'NQA',
        cityCode: 'MEM',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Millington Regional Jetport',
        airportNameEn: 'Millington Regional Jetport',
        cityNameVi: 'Memphis',
        cityNameEn: 'Memphis',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    MEM: {
        id: 2941,
        airportCode: 'MEM',
        cityCode: 'MEM',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Memphis',
        airportNameEn: 'Memphis International Airport',
        cityNameVi: 'Memphis',
        cityNameEn: 'Memphis',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    MLB: {
        id: 2940,
        airportCode: 'MLB',
        cityCode: 'MLB',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Melbourne',
        airportNameEn: 'Melbourne International Airport',
        cityNameVi: 'Melbourne',
        cityNameEn: 'Melbourne',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    OGG: {
        id: 2939,
        airportCode: 'OGG',
        cityCode: 'OGG',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Kahului',
        airportNameEn: 'Kahului Airport',
        cityNameVi: 'Maui',
        cityNameEn: 'Maui',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    MSS: {
        id: 2938,
        airportCode: 'MSS',
        cityCode: 'MSS',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Massena',
        airportNameEn: 'Massena International Airport ',
        cityNameVi: 'Massena',
        cityNameEn: 'Massena',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    MCW: {
        id: 2937,
        airportCode: 'MCW',
        cityCode: 'MCW',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Mason City Municipal',
        airportNameEn: 'Mason City Municipal Airport',
        cityNameVi: 'Mason City',
        cityNameEn: 'Mason City',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    VNY: {
        id: 2936,
        airportCode: 'VNY',
        cityCode: 'LAX',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Van Nuys',
        airportNameEn: 'Van Nuys Airport',
        cityNameVi: 'Los Angeles',
        cityNameEn: 'Los Angeles',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    LAM: {
        id: 2935,
        airportCode: 'LAM',
        cityCode: 'LAM',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Los Alamos County',
        airportNameEn: 'Los Alamos County Airport',
        cityNameVi: 'Los Alamos',
        cityNameEn: 'Los Alamos',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    LIH: {
        id: 2934,
        airportCode: 'LIH',
        cityCode: 'LIH',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Lihue',
        airportNameEn: 'Lihue Airport',
        cityNameVi: 'Lihue',
        cityNameEn: 'Lihue',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    VGT: {
        id: 2933,
        airportCode: 'VGT',
        cityCode: 'LAS',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay North Las Vegas',
        airportNameEn: 'North Las Vegas Airport',
        cityNameVi: 'Las Vegas',
        cityNameEn: 'Las Vegas',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    BLD: {
        id: 2932,
        airportCode: 'BLD',
        cityCode: 'BLD',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Boulder City Municipal',
        airportNameEn: 'Boulder City Municipal Airport ',
        cityNameVi: 'Las Vegas',
        cityNameEn: 'Las Vegas',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    KDK: {
        id: 2931,
        airportCode: 'KDK',
        cityCode: 'ADQ',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Kodiak Municipal',
        airportNameEn: 'Kodiak Municipal Airport',
        cityNameVi: 'Kodiak',
        cityNameEn: 'Kodiak',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    ADQ: {
        id: 2930,
        airportCode: 'ADQ',
        cityCode: 'ADQ',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Kodiak',
        airportNameEn: 'Kodiak Airport',
        cityNameVi: 'Kodiak',
        cityNameEn: 'Kodiak',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    TYS: {
        id: 2929,
        airportCode: 'TYS',
        cityCode: 'TYS',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay McGhee Tyson',
        airportNameEn: 'McGhee Tyson Airport',
        cityNameVi: 'Knoxville',
        cityNameEn: 'Knoxville',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    AKN: {
        id: 2928,
        airportCode: 'AKN',
        cityCode: 'AKN',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay King Salmon',
        airportNameEn: 'King Salmon Airport',
        cityNameVi: 'King Salmon',
        cityNameEn: 'King Salmon',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    ILE: {
        id: 2927,
        airportCode: 'ILE',
        cityCode: 'ILE',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Skylark Field',
        airportNameEn: 'Skylark Field',
        cityNameVi: 'Killeen',
        cityNameEn: 'Killeen',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    EYW: {
        id: 2926,
        airportCode: 'EYW',
        cityCode: 'EYW',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Key West',
        airportNameEn: 'Key West International Airport',
        cityNameVi: 'Key West',
        cityNameEn: 'Key West',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    KTN: {
        id: 2925,
        airportCode: 'KTN',
        cityCode: 'KTN',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Ketchikan',
        airportNameEn: 'Ketchikan International Airport',
        cityNameVi: 'Ketchikan',
        cityNameEn: 'Ketchikan',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    JCI: {
        id: 2924,
        airportCode: 'JCI',
        cityCode: 'JCI',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'New Century AirCenter ',
        airportNameEn: 'New Century AirCenter ',
        cityNameVi: 'Kansas City',
        cityNameEn: 'Kansas City',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    MKC: {
        id: 2923,
        airportCode: 'MKC',
        cityCode: 'MKC',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Kansas City Downtown',
        airportNameEn: 'Kansas City Downtown Airport ',
        cityNameVi: 'Kansas City',
        cityNameEn: 'Kansas City',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    MCI: {
        id: 2922,
        airportCode: 'MCI',
        cityCode: 'MKC',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Kansas City',
        airportNameEn: 'Kansas City International Airport',
        cityNameVi: 'Kansas City',
        cityNameEn: 'Kansas City',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    NIP: {
        id: 2921,
        airportCode: 'NIP',
        cityCode: 'JAX',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'NAS Jacksonville ',
        airportNameEn: 'NAS Jacksonville ',
        cityNameVi: 'Jacksonville',
        cityNameEn: 'Jacksonville',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    INL: {
        id: 2920,
        airportCode: 'INL',
        cityCode: 'INL',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Falls',
        airportNameEn: 'Falls International Airport',
        cityNameVi: 'International Falls',
        cityNameEn: 'International Falls',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    IND: {
        id: 2919,
        airportCode: 'IND',
        cityCode: 'IND',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Indianapolis',
        airportNameEn: 'Indianapolis International Airport',
        cityNameVi: 'Indianapolis',
        cityNameEn: 'Indianapolis',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    IDA: {
        id: 2918,
        airportCode: 'IDA',
        cityCode: 'IDA',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Idaho Falls Regional',
        airportNameEn: 'Idaho Falls Regional Airport',
        cityNameVi: 'Idaho Falls',
        cityNameEn: 'Idaho Falls',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    HIK: {
        id: 2917,
        airportCode: 'HIK',
        cityCode: 'HNL',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Hickam Air Force Base',
        airportNameEn: 'Hickam Air Force Base',
        cityNameVi: 'Honolulu',
        cityNameEn: 'Honolulu',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    HOB: {
        id: 2916,
        airportCode: 'HOB',
        cityCode: 'HOB',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Lea County Regional',
        airportNameEn: 'Lea County Regional Airport',
        cityNameVi: 'Hobbs',
        cityNameEn: 'Hobbs',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    GRB: {
        id: 2915,
        airportCode: 'GRB',
        cityCode: 'GRB',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Austin Straubel',
        airportNameEn: 'Austin Straubel International Airport',
        cityNameVi: 'Green Bay',
        cityNameEn: 'Green Bay',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    GTF: {
        id: 2914,
        airportCode: 'GTF',
        cityCode: 'GTF',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Great Falls',
        airportNameEn: 'Great Falls International Airport',
        cityNameVi: 'Great Falls',
        cityNameEn: 'Great Falls',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    GBD: {
        id: 2913,
        airportCode: 'GBD',
        cityCode: 'GBD',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Great Bend Municipal',
        airportNameEn: 'Great Bend Municipal Airport',
        cityNameVi: 'Great Bend',
        cityNameEn: 'Great Bend',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    FTW: {
        id: 2912,
        airportCode: 'FTW',
        cityCode: 'FTW',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Fort Worth Meacham',
        airportNameEn: 'Fort Worth Meacham International Airport',
        cityNameVi: 'Ft Worth',
        cityNameEn: 'Ft Worth',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    FWA: {
        id: 2911,
        airportCode: 'FWA',
        cityCode: 'FWA',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Fort Wayne',
        airportNameEn: 'Fort Wayne International Airport',
        cityNameVi: 'Ft Wayne',
        cityNameEn: 'Ft Wayne',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    EGI: {
        id: 2910,
        airportCode: 'EGI',
        cityCode: 'EGI',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Duke Field ',
        airportNameEn: 'Duke Field ',
        cityNameVi: 'Ft Walton Beach',
        cityNameEn: 'Ft Walton Beach',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    VPS: {
        id: 2909,
        airportCode: 'VPS',
        cityCode: 'VPS',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Northwest Florida Regional',
        airportNameEn: 'Northwest Florida Regional Airport / Eglin Air Force Base',
        cityNameVi: 'Ft Walton Beach',
        cityNameEn: 'Ft Walton Beach',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    FSM: {
        id: 2908,
        airportCode: 'FSM',
        cityCode: 'FSM',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Fort Smith Regional',
        airportNameEn: 'Fort Smith Regional Airport',
        cityNameVi: 'Ft Smith',
        cityNameEn: 'Ft Smith',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    TBN: {
        id: 2907,
        airportCode: 'TBN',
        cityCode: 'TBN',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Waynesville-St. Robert Regional',
        airportNameEn: 'Waynesville-St. Robert Regional Airport ',
        cityNameVi: 'Ft Leonard Wood',
        cityNameEn: 'Ft Leonard Wood',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    FXE: {
        id: 2906,
        airportCode: 'FXE',
        cityCode: 'FLL',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Fort Lauderdale Executive',
        airportNameEn: 'Fort Lauderdale Executive Airport',
        cityNameVi: 'Ft Lauderdale',
        cityNameEn: 'Ft Lauderdale',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    FNL: {
        id: 2905,
        airportCode: 'FNL',
        cityCode: 'FNL',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Fort Collins-Loveland Municipal',
        airportNameEn: 'Fort Collins-Loveland Municipal Airport',
        cityNameVi: 'Ft Collins',
        cityNameEn: 'Ft Collins',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    FAT: {
        id: 2904,
        airportCode: 'FAT',
        cityCode: 'FAT',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Fresno Yosemite',
        airportNameEn: 'Fresno Yosemite International Airport',
        cityNameVi: 'Fresno',
        cityNameEn: 'Fresno',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    FCH: {
        id: 2903,
        airportCode: 'FCH',
        cityCode: 'FCH',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Fresno Chandler Executive',
        airportNameEn: 'Fresno Chandler Executive Airport',
        cityNameVi: 'Fresno',
        cityNameEn: 'Fresno',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    FYU: {
        id: 2902,
        airportCode: 'FYU',
        cityCode: 'FYU',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Fort Yukon',
        airportNameEn: 'Fort Yukon Airport',
        cityNameVi: 'Fort Yukon',
        cityNameEn: 'Fort Yukon',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    RSW: {
        id: 2901,
        airportCode: 'RSW',
        cityCode: 'FMY',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Southwest Florida',
        airportNameEn: 'Southwest Florida International Airport',
        cityNameVi: 'Fort Myers',
        cityNameEn: 'Fort Myers',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    FOD: {
        id: 2900,
        airportCode: 'FOD',
        cityCode: 'FOD',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Fort Dodge Regional',
        airportNameEn: 'Fort Dodge Regional Airport',
        cityNameVi: 'Fort Dodge',
        cityNameEn: 'Fort Dodge',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    FLG: {
        id: 2899,
        airportCode: 'FLG',
        cityCode: 'FLG',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Flagstaff Pulliam',
        airportNameEn: 'Flagstaff Pulliam Airport',
        cityNameVi: 'Flagstaff',
        cityNameEn: 'Flagstaff',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    FAI: {
        id: 2898,
        airportCode: 'FAI',
        cityCode: 'FAI',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Fairbanks',
        airportNameEn: 'Fairbanks International Airport',
        cityNameVi: 'Fairbanks',
        cityNameEn: 'Fairbanks',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    EVV: {
        id: 2897,
        airportCode: 'EVV',
        cityCode: 'EVV',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Evansville Regional',
        airportNameEn: 'Evansville Regional Airport',
        cityNameVi: 'Evansville',
        cityNameEn: 'Evansville',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    BIF: {
        id: 2896,
        airportCode: 'BIF',
        cityCode: 'ELP',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Biggs Army Airfield',
        airportNameEn: 'Biggs Army Airfield',
        cityNameVi: 'El Paso',
        cityNameEn: 'El Paso',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    ESD: {
        id: 2895,
        airportCode: 'ESD',
        cityCode: 'ESD',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Orcas Island',
        airportNameEn: 'Orcas Island Airport ',
        cityNameVi: 'East Sound',
        cityNameEn: 'East Sound',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    EGE: {
        id: 2894,
        airportCode: 'EGE',
        cityCode: 'EGE',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Eagle County Regional',
        airportNameEn: 'Eagle County Regional Airport',
        cityNameVi: 'Eagle',
        cityNameEn: 'Eagle',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    DUT: {
        id: 2893,
        airportCode: 'DUT',
        cityCode: 'DUT',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Unalaska',
        airportNameEn: 'Unalaska Airport',
        cityNameVi: 'Dutch Harbor',
        cityNameEn: 'Dutch Harbor',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    AMK: {
        id: 2892,
        airportCode: 'AMK',
        cityCode: 'AMK',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Animas Air Park ',
        airportNameEn: 'Animas Air Park ',
        cityNameVi: 'Durango',
        cityNameEn: 'Durango',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    DRO: {
        id: 2891,
        airportCode: 'DRO',
        cityCode: 'DRO',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Durango-La Plata County',
        airportNameEn: 'Durango-La Plata County Airport',
        cityNameVi: 'Durango',
        cityNameEn: 'Durango',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    DLG: {
        id: 2890,
        airportCode: 'DLG',
        cityCode: 'DLG',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Dillingham',
        airportNameEn: 'Dillingham Airport',
        cityNameVi: 'Dillingham',
        cityNameEn: 'Dillingham',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    DIK: {
        id: 2889,
        airportCode: 'DIK',
        cityCode: 'DIK',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Theodore Roosevelt Regional',
        airportNameEn: 'Theodore Roosevelt Regional Airport',
        cityNameVi: 'Dickinson',
        cityNameEn: 'Dickinson',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    DVL: {
        id: 2888,
        airportCode: 'DVL',
        cityCode: 'DVL',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Devils Lake Municipal',
        airportNameEn: 'Devils Lake Municipal Airport',
        cityNameVi: 'Devils Lake',
        cityNameEn: 'Devils Lake',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    DTT: {
        id: 2887,
        airportCode: 'DTT',
        cityCode: 'DTT',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Tất cả các sân bay',
        airportNameEn: 'All Airports',
        cityNameVi: 'Detroit',
        cityNameEn: 'Detroit',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    YIP: {
        id: 2886,
        airportCode: 'YIP',
        cityCode: 'DTT',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Willow Run',
        airportNameEn: 'Willow Run Airport',
        cityNameVi: 'Detroit',
        cityNameEn: 'Detroit',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    DTW: {
        id: 2885,
        airportCode: 'DTW',
        cityCode: 'DTT',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Detroit Metropolitan Wayne County',
        airportNameEn: 'Detroit Metropolitan Wayne County Airport',
        cityNameVi: 'Detroit',
        cityNameEn: 'Detroit',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    DET: {
        id: 2884,
        airportCode: 'DET',
        cityCode: 'DTT',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Coleman A. Young',
        airportNameEn: 'Coleman A. Young International Airport',
        cityNameVi: 'Detroit',
        cityNameEn: 'Detroit',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    APA: {
        id: 2883,
        airportCode: 'APA',
        cityCode: 'DEN',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Centennial',
        airportNameEn: 'Centennial Airport',
        cityNameVi: 'Denver',
        cityNameEn: 'Denver',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    DAB: {
        id: 2882,
        airportCode: 'DAB',
        cityCode: 'DAB',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Daytona Beach',
        airportNameEn: 'Daytona Beach International Airport',
        cityNameVi: 'Daytona Beach',
        cityNameEn: 'Daytona Beach',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    DAY: {
        id: 2881,
        airportCode: 'DAY',
        cityCode: 'DAY',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Dayton',
        airportNameEn: 'Dayton International Airport',
        cityNameVi: 'Dayton',
        cityNameEn: 'Dayton',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    DVN: {
        id: 2880,
        airportCode: 'DVN',
        cityCode: 'DVN',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Davenport Municipal',
        airportNameEn: 'Davenport Municipal Airport',
        cityNameVi: 'Davenport',
        cityNameEn: 'Davenport',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    DAL: {
        id: 2879,
        airportCode: 'DAL',
        cityCode: 'DFW',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Dallas Love Field',
        airportNameEn: 'Dallas Love Field',
        cityNameVi: 'Dallas',
        cityNameEn: 'Dallas',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    DFW: {
        id: 2878,
        airportCode: 'DFW',
        cityCode: 'DFW',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Dallas/Fort Worth',
        airportNameEn: 'Dallas/Fort Worth International Airport',
        cityNameVi: 'Dallas',
        cityNameEn: 'Dallas',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    CEC: {
        id: 2877,
        airportCode: 'CEC',
        cityCode: 'CEC',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Jack McNamara Field',
        airportNameEn: 'Jack McNamara Field',
        cityNameVi: 'Crescent City',
        cityNameEn: 'Crescent City',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    GTR: {
        id: 2876,
        airportCode: 'GTR',
        cityCode: 'GTR',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Golden Triangle Regional',
        airportNameEn: 'Golden Triangle Regional Airport',
        cityNameVi: 'Columbus',
        cityNameEn: 'Columbus',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    UBS: {
        id: 2875,
        airportCode: 'UBS',
        cityCode: 'GTR',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Columbus-Lowndes County',
        airportNameEn: 'Columbus-Lowndes County Airport',
        cityNameVi: 'Columbus',
        cityNameEn: 'Columbus',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    COS: {
        id: 2874,
        airportCode: 'COS',
        cityCode: 'COS',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Colorado Springs',
        airportNameEn: 'Colorado Springs Airport',
        cityNameVi: 'Colorado Springs',
        cityNameEn: 'Colorado Springs',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    COD: {
        id: 2873,
        airportCode: 'COD',
        cityCode: 'COD',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Yellowstone Regional',
        airportNameEn: 'Yellowstone Regional Airport',
        cityNameVi: 'Cody',
        cityNameEn: 'Cody',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    CGF: {
        id: 2872,
        airportCode: 'CGF',
        cityCode: 'CLE',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Cuyahoga County',
        airportNameEn: 'Cuyahoga County Airport',
        cityNameVi: 'Cleveland',
        cityNameEn: 'Cleveland',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    BKL: {
        id: 2871,
        airportCode: 'BKL',
        cityCode: 'CLE',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Burke Lakefront',
        airportNameEn: 'Burke Lakefront Airport',
        cityNameVi: 'Cleveland',
        cityNameEn: 'Cleveland',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    LUK: {
        id: 2870,
        airportCode: 'LUK',
        cityCode: 'CVG',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Cincinnati Municipal',
        airportNameEn: 'Cincinnati Municipal Airport ',
        cityNameVi: 'Cincinnati',
        cityNameEn: 'Cincinnati',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    CIC: {
        id: 2869,
        airportCode: 'CIC',
        cityCode: 'CIC',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Chico Municipal',
        airportNameEn: 'Chico Municipal Airport',
        cityNameVi: 'Chico',
        cityNameEn: 'Chico',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    CHI: {
        id: 2868,
        airportCode: 'CHI',
        cityCode: 'CHI',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Tất cả các sân bay',
        airportNameEn: 'All Airports',
        cityNameVi: 'Chicago',
        cityNameEn: 'Chicago',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    PWK: {
        id: 2867,
        airportCode: 'PWK',
        cityCode: 'CHI',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Palwaukee Municipal',
        airportNameEn: 'Palwaukee Municipal Airport',
        cityNameVi: 'Chicago',
        cityNameEn: 'Chicago',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    ORD: {
        id: 2866,
        airportCode: 'ORD',
        cityCode: 'CHI',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: "Sân bay O'Hare",
        airportNameEn: "O'Hare International Airport ",
        cityNameVi: 'Chicago',
        cityNameEn: 'Chicago',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    MDW: {
        id: 2865,
        airportCode: 'MDW',
        cityCode: 'CHI',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Midway',
        airportNameEn: 'Midway International Airport',
        cityNameVi: 'Chicago',
        cityNameEn: 'Chicago',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    DPA: {
        id: 2864,
        airportCode: 'DPA',
        cityCode: 'CHI',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Dupage',
        airportNameEn: 'Dupage Airport',
        cityNameVi: 'Chicago',
        cityNameEn: 'Chicago',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    CYS: {
        id: 2863,
        airportCode: 'CYS',
        cityCode: 'CYS',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Cheyenne Regional',
        airportNameEn: 'Cheyenne Regional Airport ',
        cityNameVi: 'Cheyenne',
        cityNameEn: 'Cheyenne',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    CHO: {
        id: 2862,
        airportCode: 'CHO',
        cityCode: 'CHO',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Charlottesville-Albemarle',
        airportNameEn: 'Charlottesville-Albemarle Airport',
        cityNameVi: 'Charlottesville',
        cityNameEn: 'Charlottesville',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    CLT: {
        id: 2861,
        airportCode: 'CLT',
        cityCode: 'CLT',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Charlotte Douglas',
        airportNameEn: 'Charlotte Douglas International Airport',
        cityNameVi: 'Charlotte',
        cityNameEn: 'Charlotte',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    CHS: {
        id: 2860,
        airportCode: 'CHS',
        cityCode: 'CHS',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Charleston',
        airportNameEn: 'Charleston International Airport / Charleston AFB',
        cityNameVi: 'Charleston',
        cityNameEn: 'Charleston',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    BUR: {
        id: 2859,
        airportCode: 'BUR',
        cityCode: 'BUR',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Bob Hope',
        airportNameEn: 'Bob Hope Airport',
        cityNameVi: 'Burbank',
        cityNameEn: 'Burbank',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    BRO: {
        id: 2858,
        airportCode: 'BRO',
        cityCode: 'BRO',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Brownsville/South Padre Island',
        airportNameEn: 'Brownsville/South Padre Island International Airport',
        cityNameVi: 'Brownsville',
        cityNameEn: 'Brownsville',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    TRI: {
        id: 2857,
        airportCode: 'TRI',
        cityCode: 'TRI',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Tri-Cities Regional',
        airportNameEn: 'Tri-Cities Regional Airport',
        cityNameVi: 'Bristol',
        cityNameEn: 'Bristol',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    BOS: {
        id: 2856,
        airportCode: 'BOS',
        cityCode: 'BOS',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Logan',
        airportNameEn: 'Logan International Airport',
        cityNameVi: 'Boston',
        cityNameEn: 'Boston',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    BHM: {
        id: 2855,
        airportCode: 'BHM',
        cityCode: 'BHM',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Birmingham–Shuttlesworth',
        airportNameEn: 'Birmingham–Shuttlesworth International Airport',
        cityNameVi: 'Birmingham',
        cityNameEn: 'Birmingham',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    BED: {
        id: 2854,
        airportCode: 'BED',
        cityCode: 'BED',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Laurence G. Hanscom Field',
        airportNameEn: 'Laurence G. Hanscom Field',
        cityNameVi: 'Bedford',
        cityNameEn: 'Bedford',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    BKW: {
        id: 2853,
        airportCode: 'BKW',
        cityCode: 'BKW',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Raleigh County Memorial',
        airportNameEn: 'Raleigh County Memorial Airport',
        cityNameVi: 'Beckley',
        cityNameEn: 'Beckley',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    BPT: {
        id: 2852,
        airportCode: 'BPT',
        cityCode: 'BPT',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Southeast Texas Regional',
        airportNameEn: 'Southeast Texas Regional Airport',
        cityNameVi: 'Beaumont',
        cityNameEn: 'Beaumont',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    BWI: {
        id: 2851,
        airportCode: 'BWI',
        cityCode: 'BWI',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Baltimore/Washington International Thurgood Marshall',
        airportNameEn: 'Baltimore/Washington International Thurgood Marshall Airport',
        cityNameVi: 'Baltimore',
        cityNameEn: 'Baltimore',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    ACY: {
        id: 2850,
        airportCode: 'ACY',
        cityCode: 'AIY',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Atlantic City',
        airportNameEn: 'Atlantic City International Airport',
        cityNameVi: 'Atlantic City',
        cityNameEn: 'Atlantic City',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    FTY: {
        id: 2849,
        airportCode: 'FTY',
        cityCode: 'ATL',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Fulton County',
        airportNameEn: 'Fulton County Airport ',
        cityNameVi: 'Atlanta',
        cityNameEn: 'Atlanta',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    PDK: {
        id: 2848,
        airportCode: 'PDK',
        cityCode: 'ATL',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay DeKalb-Peachtree',
        airportNameEn: 'DeKalb-Peachtree Airport',
        cityNameVi: 'Atlanta',
        cityNameEn: 'Atlanta',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    ASE: {
        id: 2847,
        airportCode: 'ASE',
        cityCode: 'ASE',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Aspen-Pitkin County',
        airportNameEn: 'Aspen-Pitkin County Airport ',
        cityNameVi: 'Aspen',
        cityNameEn: 'Aspen',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    AVL: {
        id: 2846,
        airportCode: 'AVL',
        cityCode: 'AVL',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Asheville Regional',
        airportNameEn: 'Asheville Regional Airport',
        cityNameVi: 'Asheville',
        cityNameEn: 'Asheville',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    ATW: {
        id: 2845,
        airportCode: 'ATW',
        cityCode: 'ATW',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Outagamie County Regional',
        airportNameEn: 'Outagamie County Regional Airport',
        cityNameVi: 'Appleton',
        cityNameEn: 'Appleton',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    ARB: {
        id: 2844,
        airportCode: 'ARB',
        cityCode: 'ARB',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Ann Arbor Municipal',
        airportNameEn: 'Ann Arbor Municipal Airport',
        cityNameVi: 'Ann Arbor',
        cityNameEn: 'Ann Arbor',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    ANC: {
        id: 2843,
        airportCode: 'ANC',
        cityCode: 'ANC',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Ted Stevens Anchorage',
        airportNameEn: 'Ted Stevens Anchorage International Airport',
        cityNameVi: 'Anchorage',
        cityNameEn: 'Anchorage',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    TDW: {
        id: 2842,
        airportCode: 'TDW',
        cityCode: 'AMA',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Tradewind',
        airportNameEn: 'Tradewind Airport',
        cityNameVi: 'Amarillo',
        cityNameEn: 'Amarillo',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    AIA: {
        id: 2841,
        airportCode: 'AIA',
        cityCode: 'AIA',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Alliance Municipal',
        airportNameEn: 'Alliance Municipal Airport',
        cityNameVi: 'Alliance',
        cityNameEn: 'Alliance',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    ABE: {
        id: 2840,
        airportCode: 'ABE',
        cityCode: 'ABE',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Lehigh Valley',
        airportNameEn: 'Lehigh Valley International Airport',
        cityNameVi: 'Allentown',
        cityNameEn: 'Allentown',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    ABQ: {
        id: 2839,
        airportCode: 'ABQ',
        cityCode: 'ABQ',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Albuquerque International Sunport',
        airportNameEn: 'Albuquerque International Sunport',
        cityNameVi: 'Albuquerque',
        cityNameEn: 'Albuquerque',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    ALS: {
        id: 2838,
        airportCode: 'ALS',
        cityCode: 'ALS',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay San Luis Valley Regional',
        airportNameEn: 'San Luis Valley Regional Airport',
        cityNameVi: 'Alamosa',
        cityNameEn: 'Alamosa',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    ABI: {
        id: 2837,
        airportCode: 'ABI',
        cityCode: 'ABI',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Abilene Regional',
        airportNameEn: 'Abilene Regional Airport',
        cityNameVi: 'Abilene',
        cityNameEn: 'Abilene',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    ABR: {
        id: 2836,
        airportCode: 'ABR',
        cityCode: 'ABR',
        countryCode: 'US',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Aberdeen Regional',
        airportNameEn: 'Aberdeen Regional Airport',
        cityNameVi: 'Aberdeen',
        cityNameEn: 'Aberdeen',
        countryNameVi: 'Hoa kỳ',
        countryNameEn: 'United States'
    },
    MVR: {
        id: 2835,
        airportCode: 'MVR',
        cityCode: 'MVR',
        countryCode: 'CM',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Salak',
        airportNameEn: 'Salak Airport',
        cityNameVi: 'Maroua',
        cityNameEn: 'Maroua',
        countryNameVi: 'Cộng hòa Cameroon',
        countryNameEn: 'United Republic Of Cameroon'
    },
    NSI: {
        id: 2834,
        airportCode: 'NSI',
        cityCode: 'YAO',
        countryCode: 'CM',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Yaoundé Nsimalen',
        airportNameEn: 'Yaoundé Nsimalen International Airport',
        cityNameVi: 'Yaounde',
        cityNameEn: 'Yaounde',
        countryNameVi: 'Cộng hòa Cameroon',
        countryNameEn: 'United Republic Of Cameroon'
    },
    YAO: {
        id: 2833,
        airportCode: 'YAO',
        cityCode: 'YAO',
        countryCode: 'CM',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Yaoundé',
        airportNameEn: 'Yaoundé Airport',
        cityNameVi: 'Yaounde',
        cityNameEn: 'Yaounde',
        countryNameVi: 'Cộng hòa Cameroon',
        countryNameEn: 'United Republic Of Cameroon'
    },
    GOU: {
        id: 2832,
        airportCode: 'GOU',
        cityCode: 'GOU',
        countryCode: 'CM',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Garoua',
        airportNameEn: 'Garoua International Airport',
        cityNameVi: 'Garoua',
        cityNameEn: 'Garoua',
        countryNameVi: 'Cộng hòa Cameroon',
        countryNameEn: 'United Republic Of Cameroon'
    },
    DLA: {
        id: 2831,
        airportCode: 'DLA',
        cityCode: 'DLA',
        countryCode: 'CM',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Douala',
        airportNameEn: 'Douala Airport',
        cityNameVi: 'Douala',
        cityNameEn: 'Douala',
        countryNameVi: 'Cộng hòa Cameroon',
        countryNameEn: 'United Republic Of Cameroon'
    },
    WIC: {
        id: 2830,
        airportCode: 'WIC',
        cityCode: 'WIC',
        countryCode: 'GB',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Wick',
        airportNameEn: 'Wick Airport',
        cityNameVi: 'Wick',
        cityNameEn: 'Wick',
        countryNameVi: 'Anh quốc',
        countryNameEn: 'United Kingdom'
    },
    TRE: {
        id: 2829,
        airportCode: 'TRE',
        cityCode: 'TRE',
        countryCode: 'GB',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Tiree',
        airportNameEn: 'Tiree Airport',
        cityNameVi: 'Tiree',
        cityNameEn: 'Tiree',
        countryNameVi: 'Anh quốc',
        countryNameEn: 'United Kingdom'
    },
    LWK: {
        id: 2828,
        airportCode: 'LWK',
        cityCode: 'LSI',
        countryCode: 'GB',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Tingwall',
        airportNameEn: 'Tingwall Airport',
        cityNameVi: 'Lerwick',
        cityNameEn: 'Lerwick',
        countryNameVi: 'Anh quốc',
        countryNameEn: 'United Kingdom'
    },
    LSI: {
        id: 2827,
        airportCode: 'LSI',
        cityCode: 'LSI',
        countryCode: 'GB',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Sumburgh',
        airportNameEn: 'Sumburgh Airport',
        cityNameVi: 'Lerwick',
        cityNameEn: 'Lerwick',
        countryNameVi: 'Anh quốc',
        countryNameEn: 'United Kingdom'
    },
    SCS: {
        id: 2826,
        airportCode: 'SCS',
        cityCode: 'SDZ',
        countryCode: 'GB',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Scatsta',
        airportNameEn: 'Scatsta Airport',
        cityNameVi: 'Shetland Islands Area',
        cityNameEn: 'Shetland Islands Area',
        countryNameVi: 'Anh quốc',
        countryNameEn: 'United Kingdom'
    },
    ILY: {
        id: 2825,
        airportCode: 'ILY',
        cityCode: 'ILY',
        countryCode: 'GB',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Port Ellen',
        airportNameEn: 'Port Ellen Airport ',
        cityNameVi: 'Islay',
        cityNameEn: 'Islay',
        countryNameVi: 'Anh quốc',
        countryNameEn: 'United Kingdom'
    },
    NQY: {
        id: 2824,
        airportCode: 'NQY',
        cityCode: 'NQY',
        countryCode: 'GB',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Newquay Cornwall',
        airportNameEn: 'Newquay Cornwall International Airport',
        cityNameVi: 'Newquay',
        cityNameEn: 'Newquay',
        countryNameVi: 'Anh quốc',
        countryNameEn: 'United Kingdom'
    },
    SEN: {
        id: 2823,
        airportCode: 'SEN',
        cityCode: 'SEN',
        countryCode: 'GB',
        continentCode: 'EU',
        airportNameVi: 'Sân bay London Southend',
        airportNameEn: 'London Southend Airport',
        cityNameVi: 'Southend',
        cityNameEn: 'Southend',
        countryNameVi: 'Anh quốc',
        countryNameEn: 'United Kingdom'
    },
    BQH: {
        id: 2822,
        airportCode: 'BQH',
        cityCode: 'BQH',
        countryCode: 'GB',
        continentCode: 'EU',
        airportNameVi: 'Sân bay London Biggin Hill',
        airportNameEn: 'London Biggin Hill Airport',
        cityNameVi: 'London',
        cityNameEn: 'London',
        countryNameVi: 'Anh quốc',
        countryNameEn: 'United Kingdom'
    },
    MSE: {
        id: 2821,
        airportCode: 'MSE',
        cityCode: 'MSE',
        countryCode: 'GB',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Kent',
        airportNameEn: 'Kent International Airport',
        cityNameVi: 'Manston',
        cityNameEn: 'Manston',
        countryNameVi: 'Anh quốc',
        countryNameEn: 'United Kingdom'
    },
    CEG: {
        id: 2820,
        airportCode: 'CEG',
        cityCode: 'CEG',
        countryCode: 'GB',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Hawarden',
        airportNameEn: 'Hawarden Airport ',
        cityNameVi: 'Chester',
        cityNameEn: 'Chester',
        countryNameVi: 'Anh quốc',
        countryNameEn: 'United Kingdom'
    },
    GLO: {
        id: 2819,
        airportCode: 'GLO',
        cityCode: 'GLO',
        countryCode: 'GB',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Gloucester',
        airportNameEn: 'Gloucester Airport',
        cityNameVi: 'Gloucester',
        cityNameEn: 'Gloucester',
        countryNameVi: 'Anh quốc',
        countryNameEn: 'United Kingdom'
    },
    CAL: {
        id: 2818,
        airportCode: 'CAL',
        cityCode: 'CAL',
        countryCode: 'GB',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Campbeltown',
        airportNameEn: 'Campbeltown Airport ',
        cityNameVi: 'Campbeltown',
        cityNameEn: 'Campbeltown',
        countryNameVi: 'Anh quốc',
        countryNameEn: 'United Kingdom'
    },
    BRR: {
        id: 2817,
        airportCode: 'BRR',
        cityCode: 'BRR',
        countryCode: 'GB',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Barra',
        airportNameEn: 'Barra Airport ',
        cityNameVi: 'Barra',
        cityNameEn: 'Barra',
        countryNameVi: 'Anh quốc',
        countryNameEn: 'United Kingdom'
    },
    ACI: {
        id: 2816,
        airportCode: 'ACI',
        cityCode: 'ACI',
        countryCode: 'GB',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Alderney',
        airportNameEn: 'Alderney Airport',
        cityNameVi: 'Alderney',
        cityNameEn: 'Alderney',
        countryNameVi: 'Anh quốc',
        countryNameEn: 'United Kingdom'
    },
    MME: {
        id: 2815,
        airportCode: 'MME',
        cityCode: 'MME',
        countryCode: 'GB',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Durham Tees Valley',
        airportNameEn: 'Durham Tees Valley Airport',
        cityNameVi: 'Teesside',
        cityNameEn: 'Teesside',
        countryNameVi: 'Anh quốc',
        countryNameEn: 'United Kingdom'
    },
    SYY: {
        id: 2814,
        airportCode: 'SYY',
        cityCode: 'SYY',
        countryCode: 'GB',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Stornoway',
        airportNameEn: 'Stornoway Airport',
        cityNameVi: 'Stornoway',
        cityNameEn: 'Stornoway',
        countryNameVi: 'Anh quốc',
        countryNameEn: 'United Kingdom'
    },
    SOU: {
        id: 2813,
        airportCode: 'SOU',
        cityCode: 'SOU',
        countryCode: 'GB',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Southampton',
        airportNameEn: 'Southampton Airport',
        cityNameVi: 'Southampton',
        cityNameEn: 'Southampton',
        countryNameVi: 'Anh quốc',
        countryNameEn: 'United Kingdom'
    },
    BZZ: {
        id: 2812,
        airportCode: 'BZZ',
        cityCode: 'OXF',
        countryCode: 'GB',
        continentCode: 'EU',
        airportNameVi: 'RAF Brize Norton',
        airportNameEn: 'RAF Brize Norton',
        cityNameVi: 'Oxford',
        cityNameEn: 'Oxford',
        countryNameVi: 'Anh quốc',
        countryNameEn: 'United Kingdom'
    },
    EMA: {
        id: 2811,
        airportCode: 'EMA',
        cityCode: 'NQT',
        countryCode: 'GB',
        continentCode: 'EU',
        airportNameVi: 'Sân bay East Midlands',
        airportNameEn: 'East Midlands Airport',
        cityNameVi: 'Nottingham UK',
        cityNameEn: 'Nottingham UK',
        countryNameVi: 'Anh quốc',
        countryNameEn: 'United Kingdom'
    },
    NWI: {
        id: 2810,
        airportCode: 'NWI',
        cityCode: 'NWI',
        countryCode: 'GB',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Norwich',
        airportNameEn: 'Norwich International Airport',
        cityNameVi: 'Norwich',
        cityNameEn: 'Norwich',
        countryNameVi: 'Anh quốc',
        countryNameEn: 'United Kingdom'
    },
    NCL: {
        id: 2809,
        airportCode: 'NCL',
        cityCode: 'NCL',
        countryCode: 'GB',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Newcastle',
        airportNameEn: 'Newcastle Airport',
        cityNameVi: 'Newcastle',
        cityNameEn: 'Newcastle',
        countryNameVi: 'Anh quốc',
        countryNameEn: 'United Kingdom'
    },
    NEV: {
        id: 2808,
        airportCode: 'NEV',
        cityCode: 'NEV',
        countryCode: 'GB',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Vance W. Amory',
        airportNameEn: 'Vance W. Amory International Airport',
        cityNameVi: 'Nevis',
        cityNameEn: 'Nevis',
        countryNameVi: 'Anh quốc',
        countryNameEn: 'United Kingdom'
    },
    MAN: {
        id: 2807,
        airportCode: 'MAN',
        cityCode: 'MAN',
        countryCode: 'GB',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Manchester',
        airportNameEn: 'Manchester Airport',
        cityNameVi: 'Manchester',
        cityNameEn: 'Manchester',
        countryNameVi: 'Anh quốc',
        countryNameEn: 'United Kingdom'
    },
    LON: {
        id: 2806,
        airportCode: 'LON',
        cityCode: 'LON',
        countryCode: 'GB',
        continentCode: 'EU',
        airportNameVi: 'Tất cả các sân bay',
        airportNameEn: 'All Airports',
        cityNameVi: 'London',
        cityNameEn: 'London',
        countryNameVi: 'Anh quốc',
        countryNameEn: 'United Kingdom'
    },
    STN: {
        id: 2805,
        airportCode: 'STN',
        cityCode: 'LON',
        countryCode: 'GB',
        continentCode: 'EU',
        airportNameVi: 'Sân bay London Stansted',
        airportNameEn: 'London Stansted Airport',
        cityNameVi: 'London',
        cityNameEn: 'London',
        countryNameVi: 'Anh quốc',
        countryNameEn: 'United Kingdom'
    },
    LTN: {
        id: 2804,
        airportCode: 'LTN',
        cityCode: 'LON',
        countryCode: 'GB',
        continentCode: 'EU',
        airportNameVi: 'Sân bay London Luton',
        airportNameEn: 'London Luton Airport',
        cityNameVi: 'London',
        cityNameEn: 'London',
        countryNameVi: 'Anh quốc',
        countryNameEn: 'United Kingdom'
    },
    LHR: {
        id: 2803,
        airportCode: 'LHR',
        cityCode: 'LON',
        countryCode: 'GB',
        continentCode: 'EU',
        airportNameVi: 'Sân bay London Heathrow',
        airportNameEn: 'London Heathrow Airport',
        cityNameVi: 'London',
        cityNameEn: 'London',
        countryNameVi: 'Anh quốc',
        countryNameEn: 'United Kingdom'
    },
    LGW: {
        id: 2802,
        airportCode: 'LGW',
        cityCode: 'LON',
        countryCode: 'GB',
        continentCode: 'EU',
        airportNameVi: 'Sân bay London Gatwick',
        airportNameEn: 'London Gatwick Airport',
        cityNameVi: 'London',
        cityNameEn: 'London',
        countryNameVi: 'Anh quốc',
        countryNameEn: 'United Kingdom'
    },
    LCY: {
        id: 2801,
        airportCode: 'LCY',
        cityCode: 'LON',
        countryCode: 'GB',
        continentCode: 'EU',
        airportNameVi: 'Sân bay London City',
        airportNameEn: 'London City Airport',
        cityNameVi: 'London',
        cityNameEn: 'London',
        countryNameVi: 'Anh quốc',
        countryNameEn: 'United Kingdom'
    },
    LPL: {
        id: 2800,
        airportCode: 'LPL',
        cityCode: 'LPL',
        countryCode: 'GB',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Liverpool John Lennon',
        airportNameEn: 'Liverpool John Lennon Airport',
        cityNameVi: 'Liverpool',
        cityNameEn: 'Liverpool',
        countryNameVi: 'Anh quốc',
        countryNameEn: 'United Kingdom'
    },
    LBA: {
        id: 2799,
        airportCode: 'LBA',
        cityCode: 'LBA',
        countryCode: 'GB',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Leeds Bradford',
        airportNameEn: 'Leeds Bradford International Airport',
        cityNameVi: 'Leeds',
        cityNameEn: 'Leeds',
        countryNameVi: 'Anh quốc',
        countryNameEn: 'United Kingdom'
    },
    NOC: {
        id: 2798,
        airportCode: 'NOC',
        cityCode: 'NOC',
        countryCode: 'GB',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Knock',
        airportNameEn: 'Knock International Airport',
        cityNameVi: 'Knock',
        cityNameEn: 'Knock',
        countryNameVi: 'Anh quốc',
        countryNameEn: 'United Kingdom'
    },
    KOI: {
        id: 2797,
        airportCode: 'KOI',
        cityCode: 'KOI',
        countryCode: 'GB',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Kirkwall',
        airportNameEn: 'Kirkwall Airport',
        cityNameVi: 'Kirkwall',
        cityNameEn: 'Kirkwall',
        countryNameVi: 'Anh quốc',
        countryNameEn: 'United Kingdom'
    },
    JER: {
        id: 2796,
        airportCode: 'JER',
        cityCode: 'JER',
        countryCode: 'GB',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Jersey',
        airportNameEn: 'Jersey Airport',
        cityNameVi: 'Jersey',
        cityNameEn: 'Jersey',
        countryNameVi: 'Anh quốc',
        countryNameEn: 'United Kingdom'
    },
    IOM: {
        id: 2795,
        airportCode: 'IOM',
        cityCode: 'IOM',
        countryCode: 'GB',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Isle of Man',
        airportNameEn: 'Isle of Man Airport',
        cityNameVi: 'Isle Of Man',
        cityNameEn: 'Isle Of Man',
        countryNameVi: 'Anh quốc',
        countryNameEn: 'United Kingdom'
    },
    INV: {
        id: 2794,
        airportCode: 'INV',
        cityCode: 'INV',
        countryCode: 'GB',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Inverness',
        airportNameEn: 'Inverness Airport',
        cityNameVi: 'Inverness',
        cityNameEn: 'Inverness',
        countryNameVi: 'Anh quốc',
        countryNameEn: 'United Kingdom'
    },
    HUY: {
        id: 2793,
        airportCode: 'HUY',
        cityCode: 'HUY',
        countryCode: 'GB',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Humberside',
        airportNameEn: 'Humberside Airport',
        cityNameVi: 'Humberside',
        cityNameEn: 'Humberside',
        countryNameVi: 'Anh quốc',
        countryNameEn: 'United Kingdom'
    },
    GCI: {
        id: 2792,
        airportCode: 'GCI',
        cityCode: 'GCI',
        countryCode: 'GB',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Guernsey',
        airportNameEn: 'Guernsey Airport',
        cityNameVi: 'Guernsey',
        cityNameEn: 'Guernsey',
        countryNameVi: 'Anh quốc',
        countryNameEn: 'United Kingdom'
    },
    PIK: {
        id: 2791,
        airportCode: 'PIK',
        cityCode: 'GLA',
        countryCode: 'GB',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Glasgow Prestwick',
        airportNameEn: 'Glasgow Prestwick International Airport',
        cityNameVi: 'Glasgow',
        cityNameEn: 'Glasgow',
        countryNameVi: 'Anh quốc',
        countryNameEn: 'United Kingdom'
    },
    EXT: {
        id: 2790,
        airportCode: 'EXT',
        cityCode: 'EXT',
        countryCode: 'GB',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Exeter',
        airportNameEn: 'Exeter International Airport',
        cityNameVi: 'Exeter',
        cityNameEn: 'Exeter',
        countryNameVi: 'Anh quốc',
        countryNameEn: 'United Kingdom'
    },
    EDI: {
        id: 2789,
        airportCode: 'EDI',
        cityCode: 'EDI',
        countryCode: 'GB',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Edinburgh',
        airportNameEn: 'Edinburgh Airport',
        cityNameVi: 'Edinburgh',
        cityNameEn: 'Edinburgh',
        countryNameVi: 'Anh quốc',
        countryNameEn: 'United Kingdom'
    },
    DND: {
        id: 2788,
        airportCode: 'DND',
        cityCode: 'DND',
        countryCode: 'GB',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Dundee',
        airportNameEn: 'Dundee Airport',
        cityNameVi: 'Dundee',
        cityNameEn: 'Dundee',
        countryNameVi: 'Anh quốc',
        countryNameEn: 'United Kingdom'
    },
    CWL: {
        id: 2787,
        airportCode: 'CWL',
        cityCode: 'CWL',
        countryCode: 'GB',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Cardiff',
        airportNameEn: 'Cardiff Airport',
        cityNameVi: 'Cardiff',
        cityNameEn: 'Cardiff',
        countryNameVi: 'Anh quốc',
        countryNameEn: 'United Kingdom'
    },
    CBG: {
        id: 2786,
        airportCode: 'CBG',
        cityCode: 'CBG',
        countryCode: 'GB',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Cambridge',
        airportNameEn: 'Cambridge Airport',
        cityNameVi: 'Cambridge',
        cityNameEn: 'Cambridge',
        countryNameVi: 'Anh quốc',
        countryNameEn: 'United Kingdom'
    },
    BRS: {
        id: 2785,
        airportCode: 'BRS',
        cityCode: 'BRS',
        countryCode: 'GB',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Bristol',
        airportNameEn: 'Bristol Airport',
        cityNameVi: 'Bristol',
        cityNameEn: 'Bristol',
        countryNameVi: 'Anh quốc',
        countryNameEn: 'United Kingdom'
    },
    BOH: {
        id: 2784,
        airportCode: 'BOH',
        cityCode: 'BOH',
        countryCode: 'GB',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Bournemouth',
        airportNameEn: 'Bournemouth Airport ',
        cityNameVi: 'Bournemouth',
        cityNameEn: 'Bournemouth',
        countryNameVi: 'Anh quốc',
        countryNameEn: 'United Kingdom'
    },
    BLK: {
        id: 2783,
        airportCode: 'BLK',
        cityCode: 'BLK',
        countryCode: 'GB',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Blackpool',
        airportNameEn: 'Blackpool International Airport',
        cityNameVi: 'Blackpool',
        cityNameEn: 'Blackpool',
        countryNameVi: 'Anh quốc',
        countryNameEn: 'United Kingdom'
    },
    BHX: {
        id: 2782,
        airportCode: 'BHX',
        cityCode: 'BHX',
        countryCode: 'GB',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Birmingham',
        airportNameEn: 'Birmingham Airport',
        cityNameVi: 'Birmingham',
        cityNameEn: 'Birmingham',
        countryNameVi: 'Anh quốc',
        countryNameEn: 'United Kingdom'
    },
    BEB: {
        id: 2781,
        airportCode: 'BEB',
        cityCode: 'BEB',
        countryCode: 'GB',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Benbecula',
        airportNameEn: 'Benbecula Airport',
        cityNameVi: 'Benbecula',
        cityNameEn: 'Benbecula',
        countryNameVi: 'Anh quốc',
        countryNameEn: 'United Kingdom'
    },
    BHD: {
        id: 2780,
        airportCode: 'BHD',
        cityCode: 'BFS',
        countryCode: 'GB',
        continentCode: 'EU',
        airportNameVi: 'Sân bay George Best Belfast City',
        airportNameEn: 'George Best Belfast City Airport',
        cityNameVi: 'Belfast',
        cityNameEn: 'Belfast',
        countryNameVi: 'Anh quốc',
        countryNameEn: 'United Kingdom'
    },
    SKB: {
        id: 2779,
        airportCode: 'SKB',
        cityCode: 'SKB',
        countryCode: 'GB',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Robert L. Bradshaw',
        airportNameEn: 'Robert L. Bradshaw International Airport',
        cityNameVi: 'Basseterre, St. Kitts Island',
        cityNameEn: 'Basseterre, St. Kitts Island',
        countryNameVi: 'Anh quốc',
        countryNameEn: 'United Kingdom'
    },
    ABZ: {
        id: 2778,
        airportCode: 'ABZ',
        cityCode: 'ABZ',
        countryCode: 'GB',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Aberdeen',
        airportNameEn: 'Aberdeen Airport',
        cityNameVi: 'Aberdeen',
        cityNameEn: 'Aberdeen',
        countryNameVi: 'Anh quốc',
        countryNameEn: 'United Kingdom'
    },
    SHJ: {
        id: 2777,
        airportCode: 'SHJ',
        cityCode: 'SHJ',
        countryCode: 'AE',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Sharjah',
        airportNameEn: 'Sharjah International Airport',
        cityNameVi: 'Sharjah',
        cityNameEn: 'Sharjah',
        countryNameVi: 'Các tiểu vương quốc Ả rập thống nhất',
        countryNameEn: 'United Arab Emirates'
    },
    AAN: {
        id: 2776,
        airportCode: 'AAN',
        cityCode: 'AAN',
        countryCode: 'AE',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Al Ain',
        airportNameEn: 'Al Ain International Airport',
        cityNameVi: 'Al Ain',
        cityNameEn: 'Al Ain',
        countryNameVi: 'Các tiểu vương quốc Ả rập thống nhất',
        countryNameEn: 'United Arab Emirates'
    },
    RKT: {
        id: 2775,
        airportCode: 'RKT',
        cityCode: 'RKT',
        countryCode: 'AE',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Ras Al Khaimah',
        airportNameEn: 'Ras Al Khaimah International Airport',
        cityNameVi: 'Ras Al Khaimah',
        cityNameEn: 'Ras Al Khaimah',
        countryNameVi: 'Các tiểu vương quốc Ả rập thống nhất',
        countryNameEn: 'United Arab Emirates'
    },
    DXB: {
        id: 2774,
        airportCode: 'DXB',
        cityCode: 'DXB',
        countryCode: 'AE',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Dubai',
        airportNameEn: 'Dubai International Airport',
        cityNameVi: 'Dubai',
        cityNameEn: 'Dubai',
        countryNameVi: 'Các tiểu vương quốc Ả rập thống nhất',
        countryNameEn: 'United Arab Emirates'
    },
    AUH: {
        id: 2773,
        airportCode: 'AUH',
        cityCode: 'AUH',
        countryCode: 'AE',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Abu Dhabi',
        airportNameEn: 'Abu Dhabi International Airport',
        cityNameVi: 'Abu Dhabi',
        cityNameEn: 'Abu Dhabi',
        countryNameVi: 'Các tiểu vương quốc Ả rập thống nhất',
        countryNameEn: 'United Arab Emirates'
    },
    VIN: {
        id: 2772,
        airportCode: 'VIN',
        cityCode: 'VIN',
        countryCode: 'UA',
        continentCode: 'EU',
        airportNameVi: 'Gavryshivka International',
        airportNameEn: 'Gavryshivka International',
        cityNameVi: 'Vinnitsa',
        cityNameEn: 'Vinnitsa',
        countryNameVi: 'U-krai-na',
        countryNameEn: 'Ukraine'
    },
    OZH: {
        id: 2771,
        airportCode: 'OZH',
        cityCode: 'OZH',
        countryCode: 'UA',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Zaporizhia',
        airportNameEn: 'Zaporizhia International Airport',
        cityNameVi: 'Zaporozhe',
        cityNameEn: 'Zaporozhe',
        countryNameVi: 'U-krai-na',
        countryNameEn: 'Ukraine'
    },
    VSG: {
        id: 2770,
        airportCode: 'VSG',
        cityCode: 'VSG',
        countryCode: 'UA',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Luhansk',
        airportNameEn: 'Luhansk Airport',
        cityNameVi: 'Lugansk',
        cityNameEn: 'Lugansk',
        countryNameVi: 'U-krai-na',
        countryNameEn: 'Ukraine'
    },
    KWG: {
        id: 2769,
        airportCode: 'KWG',
        cityCode: 'KWG',
        countryCode: 'UA',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Lozovatka',
        airportNameEn: 'Lozovatka Airport',
        cityNameVi: 'Krivoy Rog',
        cityNameEn: 'Krivoy Rog',
        countryNameVi: 'U-krai-na',
        countryNameEn: 'Ukraine'
    },
    IFO: {
        id: 2768,
        airportCode: 'IFO',
        cityCode: 'IFO',
        countryCode: 'UA',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Ivano-Frankivsk',
        airportNameEn: 'Ivano-Frankivsk Airport',
        cityNameVi: 'Ivano Frankovsk',
        cityNameEn: 'Ivano Frankovsk',
        countryNameVi: 'U-krai-na',
        countryNameEn: 'Ukraine'
    },
    SIP: {
        id: 2767,
        airportCode: 'SIP',
        cityCode: 'SIP',
        countryCode: 'UA',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Simferopol',
        airportNameEn: 'Simferopol International Airport',
        cityNameVi: 'Simferopol',
        cityNameEn: 'Simferopol',
        countryNameVi: 'U-krai-na',
        countryNameEn: 'Ukraine'
    },
    ODS: {
        id: 2766,
        airportCode: 'ODS',
        cityCode: 'ODS',
        countryCode: 'UA',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Odessa',
        airportNameEn: 'Odessa International Airport',
        cityNameVi: 'Odessa',
        cityNameEn: 'Odessa',
        countryNameVi: 'U-krai-na',
        countryNameEn: 'Ukraine'
    },
    LWO: {
        id: 2765,
        airportCode: 'LWO',
        cityCode: 'LWO',
        countryCode: 'UA',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Lviv Danylo Halytskyi',
        airportNameEn: 'Lviv Danylo Halytskyi International Airport',
        cityNameVi: 'Lvov',
        cityNameEn: 'Lvov',
        countryNameVi: 'U-krai-na',
        countryNameEn: 'Ukraine'
    },
    IEV: {
        id: 2764,
        airportCode: 'IEV',
        cityCode: 'IEV',
        countryCode: 'UA',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Kiev',
        airportNameEn: 'Kiev International Airport',
        cityNameVi: 'Kiev',
        cityNameEn: 'Kiev',
        countryNameVi: 'U-krai-na',
        countryNameEn: 'Ukraine'
    },
    KBP: {
        id: 2763,
        airportCode: 'KBP',
        cityCode: 'IEV',
        countryCode: 'UA',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Boryspil',
        airportNameEn: 'Boryspil International Airport',
        cityNameVi: 'Kiev',
        cityNameEn: 'Kiev',
        countryNameVi: 'U-krai-na',
        countryNameEn: 'Ukraine'
    },
    HRK: {
        id: 2762,
        airportCode: 'HRK',
        cityCode: 'HRK',
        countryCode: 'UA',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Kharkiv',
        airportNameEn: 'Kharkiv International Airport ',
        cityNameVi: 'Kharkov',
        cityNameEn: 'Kharkov',
        countryNameVi: 'U-krai-na',
        countryNameEn: 'Ukraine'
    },
    DOK: {
        id: 2761,
        airportCode: 'DOK',
        cityCode: 'DOK',
        countryCode: 'UA',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Donetsk',
        airportNameEn: 'Donetsk International Airport',
        cityNameVi: 'Donetsk',
        cityNameEn: 'Donetsk',
        countryNameVi: 'U-krai-na',
        countryNameEn: 'Ukraine'
    },
    DNK: {
        id: 2760,
        airportCode: 'DNK',
        cityCode: 'DNK',
        countryCode: 'UA',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Dnipropetrovsk',
        airportNameEn: 'Dnipropetrovsk International Airport',
        cityNameVi: 'Dnepropetrovsk',
        cityNameEn: 'Dnepropetrovsk',
        countryNameVi: 'U-krai-na',
        countryNameEn: 'Ukraine'
    },
    CWC: {
        id: 2759,
        airportCode: 'CWC',
        cityCode: 'CWC',
        countryCode: 'UA',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Chernovtsy',
        airportNameEn: 'Chernovtsy Airport',
        cityNameVi: 'Chernovtsy',
        cityNameEn: 'Chernovtsy',
        countryNameVi: 'U-krai-na',
        countryNameEn: 'Ukraine'
    },
    EBB: {
        id: 2758,
        airportCode: 'EBB',
        cityCode: 'EBB',
        countryCode: 'UG',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Entebbe',
        airportNameEn: 'Entebbe International Airport',
        cityNameVi: 'Entebbe',
        cityNameEn: 'Entebbe',
        countryNameVi: 'Uganda',
        countryNameEn: 'Uganda'
    },
    PLS: {
        id: 2757,
        airportCode: 'PLS',
        cityCode: 'PLS',
        countryCode: 'TC',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Providenciales',
        airportNameEn: 'Providenciales International Airport',
        cityNameVi: 'Providenciales',
        cityNameEn: 'Providenciales',
        countryNameVi: 'Turks Và Caicos Islands',
        countryNameEn: 'Turks and Caicos Islands'
    },
    ASB: {
        id: 2756,
        airportCode: 'ASB',
        cityCode: 'ASB',
        countryCode: 'TM',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Ashgabat',
        airportNameEn: 'Ashgabat Airport',
        cityNameVi: 'Ashkhabad',
        cityNameEn: 'Ashgabat',
        countryNameVi: 'Turkmenistan',
        countryNameEn: 'Turkmenistan'
    },
    MQM: {
        id: 2755,
        airportCode: 'MQM',
        cityCode: 'MQM',
        countryCode: 'TR',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Mardin',
        airportNameEn: 'Mardin Airport',
        cityNameVi: 'Mardin',
        cityNameEn: 'Mardin',
        countryNameVi: 'Thổ nhĩ kỳ',
        countryNameEn: 'Turkey'
    },
    MLX: {
        id: 2754,
        airportCode: 'MLX',
        cityCode: 'MLX',
        countryCode: 'TR',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Erhac',
        airportNameEn: 'Erhac Airport',
        cityNameVi: 'Malatya',
        cityNameEn: 'Malatya',
        countryNameVi: 'Thổ nhĩ kỳ',
        countryNameEn: 'Turkey'
    },
    VAN: {
        id: 2753,
        airportCode: 'VAN',
        cityCode: 'VAN',
        countryCode: 'TR',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Ferit Melen',
        airportNameEn: 'Ferit Melen Airport',
        cityNameVi: 'Van',
        cityNameEn: 'Van',
        countryNameVi: 'Thổ nhĩ kỳ',
        countryNameEn: 'Turkey'
    },
    TZX: {
        id: 2752,
        airportCode: 'TZX',
        cityCode: 'TZX',
        countryCode: 'TR',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Trabzon',
        airportNameEn: 'Trabzon Airport',
        cityNameVi: 'Trabzon',
        cityNameEn: 'Trabzon',
        countryNameVi: 'Thổ nhĩ kỳ',
        countryNameEn: 'Turkey'
    },
    VAS: {
        id: 2751,
        airportCode: 'VAS',
        cityCode: 'VAS',
        countryCode: 'TR',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Sivas',
        airportNameEn: 'Sivas Airport',
        cityNameVi: 'Sivas',
        cityNameEn: 'Sivas',
        countryNameVi: 'Thổ nhĩ kỳ',
        countryNameEn: 'Turkey'
    },
    GNY: {
        id: 2750,
        airportCode: 'GNY',
        cityCode: 'SFQ',
        countryCode: 'TR',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Sanliurfa GAP',
        airportNameEn: 'Şanlıurfa GAP Airport',
        cityNameVi: 'Sanliurfa',
        cityNameEn: 'Sanliurfa',
        countryNameVi: 'Thổ nhĩ kỳ',
        countryNameEn: 'Turkey'
    },
    SZF: {
        id: 2749,
        airportCode: 'SZF',
        cityCode: 'SSX',
        countryCode: 'TR',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Carsamba',
        airportNameEn: 'Carsamba Airport',
        cityNameVi: 'Samsun',
        cityNameEn: 'Samsun',
        countryNameVi: 'Thổ nhĩ kỳ',
        countryNameEn: 'Turkey'
    },
    SAW: {
        id: 2748,
        airportCode: 'SAW',
        cityCode: 'SAW',
        countryCode: 'TR',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Sabiha Gökçen',
        airportNameEn: 'Sabiha Gökçen International Airport',
        cityNameVi: 'Sabiha Gokcen',
        cityNameEn: 'Sabiha Gokcen',
        countryNameVi: 'Thổ nhĩ kỳ',
        countryNameEn: 'Turkey'
    },
    NAV: {
        id: 2747,
        airportCode: 'NAV',
        cityCode: 'NAV',
        countryCode: 'TR',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Kapadokya',
        airportNameEn: 'Kapadokya Airport',
        cityNameVi: 'Nevsehir',
        cityNameEn: 'Nevsehir',
        countryNameVi: 'Thổ nhĩ kỳ',
        countryNameEn: 'Turkey'
    },
    MSR: {
        id: 2746,
        airportCode: 'MSR',
        cityCode: 'MSR',
        countryCode: 'TR',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Mus',
        airportNameEn: 'Muş Airport',
        cityNameVi: 'Mus',
        cityNameEn: 'Mus',
        countryNameVi: 'Thổ nhĩ kỳ',
        countryNameEn: 'Turkey'
    },
    KYA: {
        id: 2745,
        airportCode: 'KYA',
        cityCode: 'KYA',
        countryCode: 'TR',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Konya',
        airportNameEn: 'Konya Airport',
        cityNameVi: 'Konya',
        cityNameEn: 'Konya',
        countryNameVi: 'Thổ nhĩ kỳ',
        countryNameEn: 'Turkey'
    },
    ASR: {
        id: 2744,
        airportCode: 'ASR',
        cityCode: 'ASR',
        countryCode: 'TR',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Erkilet',
        airportNameEn: 'Erkilet International Airport',
        cityNameVi: 'Kayseri',
        cityNameEn: 'Kayseri',
        countryNameVi: 'Thổ nhĩ kỳ',
        countryNameEn: 'Turkey'
    },
    KSY: {
        id: 2743,
        airportCode: 'KSY',
        cityCode: 'KSY',
        countryCode: 'TR',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Kars',
        airportNameEn: 'Kars Airport',
        cityNameVi: 'Kars',
        cityNameEn: 'Kars',
        countryNameVi: 'Thổ nhĩ kỳ',
        countryNameEn: 'Turkey'
    },
    IGL: {
        id: 2742,
        airportCode: 'IGL',
        cityCode: 'IZM',
        countryCode: 'TR',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Çigli',
        airportNameEn: 'Çigli Airport ',
        cityNameVi: 'Izmir',
        cityNameEn: 'Izmir',
        countryNameVi: 'Thổ nhĩ kỳ',
        countryNameEn: 'Turkey'
    },
    ADB: {
        id: 2741,
        airportCode: 'ADB',
        cityCode: 'IZM',
        countryCode: 'TR',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Adnan Menderes',
        airportNameEn: 'Adnan Menderes International Airport',
        cityNameVi: 'Izmir',
        cityNameEn: 'Izmir',
        countryNameVi: 'Thổ nhĩ kỳ',
        countryNameEn: 'Turkey'
    },
    IST: {
        id: 2740,
        airportCode: 'IST',
        cityCode: 'IST',
        countryCode: 'TR',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Istanbul Atatürk',
        airportNameEn: 'Istanbul Atatürk Airport ',
        cityNameVi: 'Istanbul',
        cityNameEn: 'Istanbul',
        countryNameVi: 'Thổ nhĩ kỳ',
        countryNameEn: 'Turkey'
    },
    GZT: {
        id: 2739,
        airportCode: 'GZT',
        cityCode: 'GZT',
        countryCode: 'TR',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Oguzeli',
        airportNameEn: 'Oğuzeli Airport',
        cityNameVi: 'Gaziantep',
        cityNameEn: 'Gaziantep',
        countryNameVi: 'Thổ nhĩ kỳ',
        countryNameEn: 'Turkey'
    },
    ERZ: {
        id: 2738,
        airportCode: 'ERZ',
        cityCode: 'ERZ',
        countryCode: 'TR',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Erzurum',
        airportNameEn: 'Erzurum Airport',
        cityNameVi: 'Erzurum',
        cityNameEn: 'Erzurum',
        countryNameVi: 'Thổ nhĩ kỳ',
        countryNameEn: 'Turkey'
    },
    ERC: {
        id: 2737,
        airportCode: 'ERC',
        cityCode: 'ERC',
        countryCode: 'TR',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Erzincan',
        airportNameEn: 'Erzincan Airport',
        cityNameVi: 'Erzincan',
        cityNameEn: 'Erzincan',
        countryNameVi: 'Thổ nhĩ kỳ',
        countryNameEn: 'Turkey'
    },
    EZS: {
        id: 2736,
        airportCode: 'EZS',
        cityCode: 'EZS',
        countryCode: 'TR',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Elazig',
        airportNameEn: 'Elazığ Airport',
        cityNameVi: 'Elazig',
        cityNameEn: 'Elazig',
        countryNameVi: 'Thổ nhĩ kỳ',
        countryNameEn: 'Turkey'
    },
    DIY: {
        id: 2735,
        airportCode: 'DIY',
        cityCode: 'DIY',
        countryCode: 'TR',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Diyarbakir',
        airportNameEn: 'Diyarbakır Airport',
        cityNameVi: 'Diyarbai',
        cityNameEn: 'Diyarbai',
        countryNameVi: 'Thổ nhĩ kỳ',
        countryNameEn: 'Turkey'
    },
    DNZ: {
        id: 2734,
        airportCode: 'DNZ',
        cityCode: 'DNZ',
        countryCode: 'TR',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Çardak',
        airportNameEn: 'Çardak Airport',
        cityNameVi: 'Denizli',
        cityNameEn: 'Denizli',
        countryNameVi: 'Thổ nhĩ kỳ',
        countryNameEn: 'Turkey'
    },
    DLM: {
        id: 2733,
        airportCode: 'DLM',
        cityCode: 'DLM',
        countryCode: 'TR',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Dalaman',
        airportNameEn: 'Dalaman Airport',
        cityNameVi: 'Dalaman',
        cityNameEn: 'Dalaman',
        countryNameVi: 'Thổ nhĩ kỳ',
        countryNameEn: 'Turkey'
    },
    BJV: {
        id: 2732,
        airportCode: 'BJV',
        cityCode: 'BXN',
        countryCode: 'TR',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Milas-Bodrum',
        airportNameEn: 'Milas-Bodrum Airport',
        cityNameVi: 'Bodrum',
        cityNameEn: 'Bodrum',
        countryNameVi: 'Thổ nhĩ kỳ',
        countryNameEn: 'Turkey'
    },
    BXN: {
        id: 2731,
        airportCode: 'BXN',
        cityCode: 'BXN',
        countryCode: 'TR',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Bodrum-Imsik',
        airportNameEn: 'Bodrum-Imsik Airport',
        cityNameVi: 'Bodrum',
        cityNameEn: 'Bodrum',
        countryNameVi: 'Thổ nhĩ kỳ',
        countryNameEn: 'Turkey'
    },
    BAL: {
        id: 2730,
        airportCode: 'BAL',
        cityCode: 'BAL',
        countryCode: 'TR',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Batman',
        airportNameEn: 'Batman Airport',
        cityNameVi: 'Batman',
        cityNameEn: 'Batman',
        countryNameVi: 'Thổ nhĩ kỳ',
        countryNameEn: 'Turkey'
    },
    AYT: {
        id: 2729,
        airportCode: 'AYT',
        cityCode: 'AYT',
        countryCode: 'TR',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Antalya',
        airportNameEn: 'Antalya Airport',
        cityNameVi: 'Antalya',
        cityNameEn: 'Antalya',
        countryNameVi: 'Thổ nhĩ kỳ',
        countryNameEn: 'Turkey'
    },
    ESB: {
        id: 2728,
        airportCode: 'ESB',
        cityCode: 'ANK',
        countryCode: 'TR',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Esenboga',
        airportNameEn: 'Esenboğa International Airport',
        cityNameVi: 'Ankara',
        cityNameEn: 'Ankara',
        countryNameVi: 'Thổ nhĩ kỳ',
        countryNameEn: 'Turkey'
    },
    ADA: {
        id: 2727,
        airportCode: 'ADA',
        cityCode: 'ADA',
        countryCode: 'TR',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Adana Sakirpasa',
        airportNameEn: 'Adana Şakirpaşa Airport',
        cityNameVi: 'Adana',
        cityNameEn: 'Adana',
        countryNameVi: 'Thổ nhĩ kỳ',
        countryNameEn: 'Turkey'
    },
    TUN: {
        id: 2726,
        airportCode: 'TUN',
        cityCode: 'TUN',
        countryCode: 'TN',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Tunis–Carthage',
        airportNameEn: 'Tunis–Carthage International Airport',
        cityNameVi: 'Tunis',
        cityNameEn: 'Tunis',
        countryNameVi: 'Tuy ni di',
        countryNameEn: 'Tunisia'
    },
    TOE: {
        id: 2725,
        airportCode: 'TOE',
        cityCode: 'TOE',
        countryCode: 'TN',
        continentCode: 'AF',
        airportNameVi: 'Tozeur, Tunisia ',
        airportNameEn: 'Tozeur, Tunisia ',
        cityNameVi: 'Tozeur',
        cityNameEn: 'Tozeur',
        countryNameVi: 'Tuy ni di',
        countryNameEn: 'Tunisia'
    },
    TBJ: {
        id: 2724,
        airportCode: 'TBJ',
        cityCode: 'TBJ',
        countryCode: 'TN',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Tabarka - 7 Novembre',
        airportNameEn: 'Tabarka - 7 Novembre International Airport',
        cityNameVi: 'Tabarka',
        cityNameEn: 'Tabarka',
        countryNameVi: 'Tuy ni di',
        countryNameEn: 'Tunisia'
    },
    SFA: {
        id: 2723,
        airportCode: 'SFA',
        cityCode: 'SFA',
        countryCode: 'TN',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Thyna/El Maou',
        airportNameEn: 'Thyna/El Maou Airport',
        cityNameVi: 'Sfax',
        cityNameEn: 'Sfax',
        countryNameVi: 'Tuy ni di',
        countryNameEn: 'Tunisia'
    },
    MIR: {
        id: 2722,
        airportCode: 'MIR',
        cityCode: 'MIR',
        countryCode: 'TN',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Monastir Habib Bourguiba',
        airportNameEn: 'Monastir Habib Bourguiba International Airport',
        cityNameVi: 'Monastir',
        cityNameEn: 'Monastir',
        countryNameVi: 'Tuy ni di',
        countryNameEn: 'Tunisia'
    },
    MKL: {
        id: 2721,
        airportCode: 'MKL',
        cityCode: 'MKL',
        countryCode: 'TN',
        continentCode: 'AF',
        airportNameVi: 'Sân bay McKellar-Sipes Regional',
        airportNameEn: 'McKellar-Sipes Regional Airport',
        cityNameVi: 'Jackson',
        cityNameEn: 'Jackson',
        countryNameVi: 'Tuy ni di',
        countryNameEn: 'Tunisia'
    },
    DJE: {
        id: 2720,
        airportCode: 'DJE',
        cityCode: 'DJE',
        countryCode: 'TN',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Zarzis',
        airportNameEn: 'Zarzis Airport',
        cityNameVi: 'Djerba',
        cityNameEn: 'Djerba',
        countryNameVi: 'Tuy ni di',
        countryNameEn: 'Tunisia'
    },
    TAB: {
        id: 2719,
        airportCode: 'TAB',
        cityCode: 'TAB',
        countryCode: 'TT',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Crown Point',
        airportNameEn: 'Crown Point International Airport',
        cityNameVi: 'Tobago',
        cityNameEn: 'Tobago',
        countryNameVi: 'Trinidad Và Tobago',
        countryNameEn: 'Trinidad and Tobago'
    },
    POS: {
        id: 2718,
        airportCode: 'POS',
        cityCode: 'POS',
        countryCode: 'TT',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Piarco',
        airportNameEn: 'Piarco International Airport',
        cityNameVi: 'Port Of Spain',
        cityNameEn: 'Port Of Spain',
        countryNameVi: 'Trinidad Và Tobago',
        countryNameEn: 'Trinidad and Tobago'
    },
    TBU: {
        id: 2717,
        airportCode: 'TBU',
        cityCode: 'TBU',
        countryCode: 'TO',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Fua?amotu',
        airportNameEn: 'Fuaʻamotu International Airport',
        cityNameVi: 'Nuku Alofa',
        cityNameEn: 'Nuku Alofa',
        countryNameVi: 'Tonga',
        countryNameEn: 'Tonga'
    },
    TKK: {
        id: 2716,
        airportCode: 'TKK',
        cityCode: 'TKK',
        countryCode: 'TK',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Chuuk',
        airportNameEn: 'Chuuk International Airport',
        cityNameVi: 'Truk',
        cityNameEn: 'Truk',
        countryNameVi: 'Tokelau',
        countryNameEn: 'Tokelau'
    },
    LFW: {
        id: 2715,
        airportCode: 'LFW',
        cityCode: 'LFW',
        countryCode: 'TG',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Lomé-Tokoin',
        airportNameEn: 'Lomé-Tokoin Airport',
        cityNameVi: 'Lome',
        cityNameEn: 'Lome',
        countryNameVi: 'Togo',
        countryNameEn: 'Togo'
    },
    LOE: {
        id: 2714,
        airportCode: 'LOE',
        cityCode: 'LOE',
        countryCode: 'TH',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Loei',
        airportNameEn: 'Loei Airport',
        cityNameVi: 'Loei',
        cityNameEn: 'Loei',
        countryNameVi: 'Thái Lan',
        countryNameEn: 'Thailand'
    },
    KBV: {
        id: 2713,
        airportCode: 'KBV',
        cityCode: 'KBV',
        countryCode: 'TH',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Krabi',
        airportNameEn: 'Krabi Airport',
        cityNameVi: 'Krabi',
        cityNameEn: 'Krabi',
        countryNameVi: 'Thái Lan',
        countryNameEn: 'Thailand'
    },
    DMK: {
        id: 2712,
        airportCode: 'DMK',
        cityCode: 'DMK',
        countryCode: 'TH',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Don Mueang',
        airportNameEn: 'Don Mueang International Airport',
        cityNameVi: 'Bangkok',
        cityNameEn: 'Bangkok',
        countryNameVi: 'Thái Lan',
        countryNameEn: 'Thailand'
    },
    UTH: {
        id: 2711,
        airportCode: 'UTH',
        cityCode: 'UTH',
        countryCode: 'TH',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Udon Thani',
        airportNameEn: 'Udon Thani International Airport',
        cityNameVi: 'Udon Thani',
        cityNameEn: 'Udon Thani',
        countryNameVi: 'Thái Lan',
        countryNameEn: 'Thailand'
    },
    UBP: {
        id: 2710,
        airportCode: 'UBP',
        cityCode: 'UBP',
        countryCode: 'TH',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Ubon Ratchathani',
        airportNameEn: 'Ubon Ratchathani Airport',
        cityNameVi: 'Ubon Ratchath',
        cityNameEn: 'Ubon Ratchath',
        countryNameVi: 'Thái Lan',
        countryNameEn: 'Thailand'
    },
    UTP: {
        id: 2709,
        airportCode: 'UTP',
        cityCode: 'UTP',
        countryCode: 'TH',
        continentCode: 'AS',
        airportNameVi: 'Sân bay U-Tapao',
        airportNameEn: 'U-Tapao International Airport',
        cityNameVi: 'U Tapao',
        cityNameEn: 'U Tapao',
        countryNameVi: 'Thái Lan',
        countryNameEn: 'Thailand'
    },
    TST: {
        id: 2708,
        airportCode: 'TST',
        cityCode: 'TST',
        countryCode: 'TH',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Trang',
        airportNameEn: 'Trang Airport',
        cityNameVi: 'Trang',
        cityNameEn: 'Trang',
        countryNameVi: 'Thái Lan',
        countryNameEn: 'Thailand'
    },
    URT: {
        id: 2707,
        airportCode: 'URT',
        cityCode: 'URT',
        countryCode: 'TH',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Surat Thani',
        airportNameEn: 'Surat Thani Airport',
        cityNameVi: 'Surat Thani',
        cityNameEn: 'Surat Thani',
        countryNameVi: 'Thái Lan',
        countryNameEn: 'Thailand'
    },
    THS: {
        id: 2706,
        airportCode: 'THS',
        cityCode: 'THS',
        countryCode: 'TH',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Sukhothai',
        airportNameEn: 'Sukhothai Airport',
        cityNameVi: 'Sukhothai',
        cityNameEn: 'Sukhothai',
        countryNameVi: 'Thái Lan',
        countryNameEn: 'Thailand'
    },
    UNN: {
        id: 2705,
        airportCode: 'UNN',
        cityCode: 'UNN',
        countryCode: 'TH',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Ranong',
        airportNameEn: 'Ranong Airport',
        cityNameVi: 'Ranong',
        cityNameEn: 'Ranong',
        countryNameVi: 'Thái Lan',
        countryNameEn: 'Thailand'
    },
    HKT: {
        id: 2704,
        airportCode: 'HKT',
        cityCode: 'HKT',
        countryCode: 'TH',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Phuket',
        airportNameEn: 'Phuket International Airport',
        cityNameVi: 'Phuket',
        cityNameEn: 'Phuket',
        countryNameVi: 'Thái Lan',
        countryNameEn: 'Thailand'
    },
    PRH: {
        id: 2703,
        airportCode: 'PRH',
        cityCode: 'PRH',
        countryCode: 'TH',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Phrae',
        airportNameEn: 'Phrae Airport',
        cityNameVi: 'Phrae',
        cityNameEn: 'Phrae',
        countryNameVi: 'Thái Lan',
        countryNameEn: 'Thailand'
    },
    KOP: {
        id: 2702,
        airportCode: 'KOP',
        cityCode: 'KOP',
        countryCode: 'TH',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Nakhon Phanom',
        airportNameEn: 'Nakhon Phanom Airport',
        cityNameVi: 'Nakhon Phanom',
        cityNameEn: 'Nakhon Phanom',
        countryNameVi: 'Thái Lan',
        countryNameEn: 'Thailand'
    },
    MAQ: {
        id: 2701,
        airportCode: 'MAQ',
        cityCode: 'MAQ',
        countryCode: 'TH',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Mae Sot',
        airportNameEn: 'Mae Sot Airport',
        cityNameVi: 'Mae Sot',
        cityNameEn: 'Mae Sot',
        countryNameVi: 'Thái Lan',
        countryNameEn: 'Thailand'
    },
    HGN: {
        id: 2700,
        airportCode: 'HGN',
        cityCode: 'HGN',
        countryCode: 'TH',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Mae Hong Son',
        airportNameEn: 'Mae Hong Son Airport',
        cityNameVi: 'Mae Hongson',
        cityNameEn: 'Mae Hongson',
        countryNameVi: 'Thái Lan',
        countryNameEn: 'Thailand'
    },
    LPT: {
        id: 2699,
        airportCode: 'LPT',
        cityCode: 'LPT',
        countryCode: 'TH',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Lampang',
        airportNameEn: 'Lampang Airport',
        cityNameVi: 'Lampang',
        cityNameEn: 'Lampang',
        countryNameVi: 'Thái Lan',
        countryNameEn: 'Thailand'
    },
    USM: {
        id: 2698,
        airportCode: 'USM',
        cityCode: 'USM',
        countryCode: 'TH',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Samui',
        airportNameEn: 'Samui Airport',
        cityNameVi: 'Koh Samui',
        cityNameEn: 'Koh Samui',
        countryNameVi: 'Thái Lan',
        countryNameEn: 'Thailand'
    },
    KKC: {
        id: 2697,
        airportCode: 'KKC',
        cityCode: 'KKC',
        countryCode: 'TH',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Khon Kaen',
        airportNameEn: 'Khon Kaen Airport',
        cityNameVi: 'Khon Kaen',
        cityNameEn: 'Khon Kaen',
        countryNameVi: 'Thái Lan',
        countryNameEn: 'Thailand'
    },
    HHQ: {
        id: 2696,
        airportCode: 'HHQ',
        cityCode: 'HHQ',
        countryCode: 'TH',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Hua Hin',
        airportNameEn: 'Hua Hin Airport',
        cityNameVi: 'Hua Hin',
        cityNameEn: 'Hua Hin',
        countryNameVi: 'Thái Lan',
        countryNameEn: 'Thailand'
    },
    HDY: {
        id: 2695,
        airportCode: 'HDY',
        cityCode: 'HDY',
        countryCode: 'TH',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Hat Yai',
        airportNameEn: 'Hat Yai International Airport',
        cityNameVi: 'Hat Yai',
        cityNameEn: 'Hat Yai',
        countryNameVi: 'Thái Lan',
        countryNameEn: 'Thailand'
    },
    CNX: {
        id: 2694,
        airportCode: 'CNX',
        cityCode: 'CNX',
        countryCode: 'TH',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Chiang Mai',
        airportNameEn: 'Chiang Mai International Airport',
        cityNameVi: 'Chiềng Mai',
        cityNameEn: 'Chiang Mai',
        countryNameVi: 'Thái Lan',
        countryNameEn: 'Thailand'
    },
    CEI: {
        id: 2693,
        airportCode: 'CEI',
        cityCode: 'CEI',
        countryCode: 'TH',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Chiang Rai',
        airportNameEn: 'Chiang Rai International Airport',
        cityNameVi: 'Chiang Rai',
        cityNameEn: 'Chiang Rai',
        countryNameVi: 'Thái Lan',
        countryNameEn: 'Thailand'
    },
    BFV: {
        id: 2692,
        airportCode: 'BFV',
        cityCode: 'BFV',
        countryCode: 'TH',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Buriram',
        airportNameEn: 'Buriram Airport',
        cityNameVi: 'Buri Ram',
        cityNameEn: 'Buri Ram',
        countryNameVi: 'Thái Lan',
        countryNameEn: 'Thailand'
    },
    BKK: {
        id: 2691,
        airportCode: 'BKK',
        cityCode: 'BKK',
        countryCode: 'TH',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Suvarnabhumi',
        airportNameEn: 'Suvarnabhumi Airport',
        cityNameVi: 'Băng Cốc',
        cityNameEn: 'Bangkok',
        countryNameVi: 'Thái Lan',
        countryNameEn: 'Thailand'
    },
    CJM: {
        id: 2690,
        airportCode: 'CJM',
        cityCode: 'CJM',
        countryCode: 'TH',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Chumphon',
        airportNameEn: 'Chumphon Airport',
        cityNameVi: 'Chumphon',
        cityNameEn: 'Chumphon',
        countryNameVi: 'Thái Lan',
        countryNameEn: 'Thailand'
    },
    MYW: {
        id: 2689,
        airportCode: 'MYW',
        cityCode: 'MYW',
        countryCode: 'TZ',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Mtwara',
        airportNameEn: 'Mtwara Airport',
        cityNameVi: 'Mtwara',
        cityNameEn: 'Mtwara',
        countryNameVi: 'Tanzania',
        countryNameEn: 'Tanzania'
    },
    ARK: {
        id: 2688,
        airportCode: 'ARK',
        cityCode: 'ARK',
        countryCode: 'TZ',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Arusha',
        airportNameEn: 'Arusha Airport',
        cityNameVi: 'Arusha',
        cityNameEn: 'Arusha',
        countryNameVi: 'Tanzania',
        countryNameEn: 'Tanzania'
    },
    ZNZ: {
        id: 2687,
        airportCode: 'ZNZ',
        cityCode: 'ZNZ',
        countryCode: 'TZ',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Zanzibar',
        airportNameEn: 'Zanzibar Airport ',
        cityNameVi: 'Zanzibar',
        cityNameEn: 'Zanzibar',
        countryNameVi: 'Tanzania',
        countryNameEn: 'Tanzania'
    },
    MWZ: {
        id: 2686,
        airportCode: 'MWZ',
        cityCode: 'MWZ',
        countryCode: 'TZ',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Mwanza',
        airportNameEn: 'Mwanza Airport',
        cityNameVi: 'Mwanza',
        cityNameEn: 'Mwanza',
        countryNameVi: 'Tanzania',
        countryNameEn: 'Tanzania'
    },
    JRO: {
        id: 2685,
        airportCode: 'JRO',
        cityCode: 'JRO',
        countryCode: 'TZ',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Kilimanjaro',
        airportNameEn: 'Kilimanjaro International Airport',
        cityNameVi: 'Kilimanjaro',
        cityNameEn: 'Kilimanjaro',
        countryNameVi: 'Tanzania',
        countryNameEn: 'Tanzania'
    },
    DAR: {
        id: 2684,
        airportCode: 'DAR',
        cityCode: 'DAR',
        countryCode: 'TZ',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Julius Nyerere',
        airportNameEn: 'Julius Nyerere International Airport',
        cityNameVi: 'Dar Es Salaam',
        cityNameEn: 'Dar Es Salaam',
        countryNameVi: 'Tanzania',
        countryNameEn: 'Tanzania'
    },
    DYU: {
        id: 2683,
        airportCode: 'DYU',
        cityCode: 'DYU',
        countryCode: 'TJ',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Dushanbe',
        airportNameEn: 'Dushanbe Airport',
        cityNameVi: 'Dushanbe',
        cityNameEn: 'Dushanbe',
        countryNameVi: 'Tajikistan',
        countryNameEn: 'Tajikistan'
    },
    TXG: {
        id: 2682,
        airportCode: 'TXG',
        cityCode: 'TXG',
        countryCode: 'TW',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Taichung Ching Chuang Kang',
        airportNameEn: 'Taichung Ching Chuang Kang Airport',
        cityNameVi: 'Taichung',
        cityNameEn: 'Taichung',
        countryNameVi: 'Ðài Loan',
        countryNameEn: 'Taiwan'
    },
    RMQ: {
        id: 2681,
        airportCode: 'RMQ',
        cityCode: 'RMQ',
        countryCode: 'TW',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Taichung Ching Chuan Kang',
        airportNameEn: 'Taichung Ching Chuan Kang Airport',
        cityNameVi: 'Taichung',
        cityNameEn: 'Taichung',
        countryNameVi: 'Ðài Loan',
        countryNameEn: 'Taiwan'
    },
    HUN: {
        id: 2680,
        airportCode: 'HUN',
        cityCode: 'HUN',
        countryCode: 'TW',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Hualien',
        airportNameEn: 'Hualien Airport',
        cityNameVi: 'Hualien',
        cityNameEn: 'Hualien',
        countryNameVi: 'Ðài Loan',
        countryNameEn: 'Taiwan'
    },
    TTT: {
        id: 2679,
        airportCode: 'TTT',
        cityCode: 'TTT',
        countryCode: 'TW',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Taitung',
        airportNameEn: 'Taitung Airport',
        cityNameVi: 'Taitung',
        cityNameEn: 'Taitung',
        countryNameVi: 'Ðài Loan',
        countryNameEn: 'Taiwan'
    },
    TNN: {
        id: 2678,
        airportCode: 'TNN',
        cityCode: 'TNN',
        countryCode: 'TW',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Tainan',
        airportNameEn: 'Tainan Airport',
        cityNameVi: 'Tainan',
        cityNameEn: 'Tainan',
        countryNameVi: 'Ðài Loan',
        countryNameEn: 'Taiwan'
    },
    KHH: {
        id: 2677,
        airportCode: 'KHH',
        cityCode: 'KHH',
        countryCode: 'TW',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Kaohsiung',
        airportNameEn: 'Kaohsiung International Airport',
        cityNameVi: 'Kaohsiung',
        cityNameEn: 'Kaohsiung',
        countryNameVi: 'Ðài Loan',
        countryNameEn: 'Taiwan'
    },
    TSA: {
        id: 2676,
        airportCode: 'TSA',
        cityCode: 'TPE',
        countryCode: 'TW',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Taipei Songshan',
        airportNameEn: 'Taipei Songshan Airport',
        cityNameVi: 'Đài Bắc',
        cityNameEn: 'Taipei',
        countryNameVi: 'Ðài Loan',
        countryNameEn: 'Taiwan'
    },
    LTK: {
        id: 2675,
        airportCode: 'LTK',
        cityCode: 'LTK',
        countryCode: 'SY',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Bassel Al-Assad',
        airportNameEn: 'Bassel Al-Assad International Airport',
        cityNameVi: 'Latakia',
        cityNameEn: 'Latakia',
        countryNameVi: 'Syria',
        countryNameEn: 'Syria'
    },
    DAM: {
        id: 2674,
        airportCode: 'DAM',
        cityCode: 'DAM',
        countryCode: 'SY',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Damascus',
        airportNameEn: 'Damascus International Airport',
        cityNameVi: 'Damascus',
        cityNameEn: 'Damascus',
        countryNameVi: 'Syria',
        countryNameEn: 'Syria'
    },
    ALP: {
        id: 2673,
        airportCode: 'ALP',
        cityCode: 'ALP',
        countryCode: 'SY',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Aleppo',
        airportNameEn: 'Aleppo International Airport ',
        cityNameVi: 'Aleppo',
        cityNameEn: 'Aleppo',
        countryNameVi: 'Syria',
        countryNameEn: 'Syria'
    },
    ZRH: {
        id: 2672,
        airportCode: 'ZRH',
        cityCode: 'ZRH',
        countryCode: 'CH',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Zurich',
        airportNameEn: 'Zurich Airport',
        cityNameVi: 'Zurich',
        cityNameEn: 'Zurich',
        countryNameVi: 'Thụy sỹ',
        countryNameEn: 'Switzerland'
    },
    EAP: {
        id: 2671,
        airportCode: 'EAP',
        cityCode: 'EAP',
        countryCode: 'CH',
        continentCode: 'EU',
        airportNameVi: 'Tất cả các sân bay',
        airportNameEn: 'All Airports',
        cityNameVi: 'Mulhouse',
        cityNameEn: 'Mulhouse',
        countryNameVi: 'Thụy sỹ',
        countryNameEn: 'Switzerland'
    },
    LUG: {
        id: 2670,
        airportCode: 'LUG',
        cityCode: 'LUG',
        countryCode: 'CH',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Lugano',
        airportNameEn: 'Lugano Airport',
        cityNameVi: 'Lugano',
        cityNameEn: 'Lugano',
        countryNameVi: 'Thụy sỹ',
        countryNameEn: 'Switzerland'
    },
    GVA: {
        id: 2669,
        airportCode: 'GVA',
        cityCode: 'GVA',
        countryCode: 'CH',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Cointrin',
        airportNameEn: 'Cointrin International Airport',
        cityNameVi: 'Geneva',
        cityNameEn: 'Geneva',
        countryNameVi: 'Thụy sỹ',
        countryNameEn: 'Switzerland'
    },
    BRN: {
        id: 2668,
        airportCode: 'BRN',
        cityCode: 'BRN',
        countryCode: 'CH',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Bern',
        airportNameEn: 'Bern Airport',
        cityNameVi: 'Berne',
        cityNameEn: 'Berne',
        countryNameVi: 'Thụy sỹ',
        countryNameEn: 'Switzerland'
    },
    BSL: {
        id: 2667,
        airportCode: 'BSL',
        cityCode: 'BSL',
        countryCode: 'CH',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Euro',
        airportNameEn: 'EuroAirport Basel-Mulhouse-Freiburg',
        cityNameVi: 'Basel',
        cityNameEn: 'Basel',
        countryNameVi: 'Thụy sỹ',
        countryNameEn: 'Switzerland'
    },
    ACH: {
        id: 2666,
        airportCode: 'ACH',
        cityCode: 'ACH',
        countryCode: 'CH',
        continentCode: 'EU',
        airportNameVi: 'Sân bay St. Gallen Altenrhein',
        airportNameEn: 'St. Gallen Altenrhein Airport',
        cityNameVi: 'Altenrhein',
        cityNameEn: 'Altenrhein',
        countryNameVi: 'Thụy sỹ',
        countryNameEn: 'Switzerland'
    },
    JMM: {
        id: 2665,
        airportCode: 'JMM',
        cityCode: 'JMM',
        countryCode: 'SE',
        continentCode: 'EU',
        airportNameVi: 'Malmö Harbour Heliport',
        airportNameEn: 'Malmö Harbour Heliport',
        cityNameVi: 'Malmo',
        cityNameEn: 'Malmo',
        countryNameVi: 'Thụy điển',
        countryNameEn: 'Sweden'
    },
    JLD: {
        id: 2664,
        airportCode: 'JLD',
        cityCode: 'JLD',
        countryCode: 'SE',
        continentCode: 'EU',
        airportNameVi: 'Landskrona Heliport',
        airportNameEn: 'Landskrona Heliport',
        cityNameVi: 'Landskrona',
        cityNameEn: 'Landskrona',
        countryNameVi: 'Thụy điển',
        countryNameEn: 'Sweden'
    },
    JHE: {
        id: 2663,
        airportCode: 'JHE',
        cityCode: 'JHE',
        countryCode: 'SE',
        continentCode: 'EU',
        airportNameVi: 'Hamnen Heliport',
        airportNameEn: 'Hamnen Heliport',
        cityNameVi: 'Angelholm/Helsingborg',
        cityNameEn: 'Angelholm/Helsingborg',
        countryNameVi: 'Thụy điển',
        countryNameEn: 'Sweden'
    },
    VHM: {
        id: 2662,
        airportCode: 'VHM',
        cityCode: 'VHM',
        countryCode: 'SE',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Vilhelmina',
        airportNameEn: 'Vilhelmina Airport',
        cityNameVi: 'Vilhelmina',
        cityNameEn: 'Vilhelmina',
        countryNameVi: 'Thụy điển',
        countryNameEn: 'Sweden'
    },
    THN: {
        id: 2661,
        airportCode: 'THN',
        cityCode: 'THN',
        countryCode: 'SE',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Trollhättan-Vänersborg',
        airportNameEn: 'Trollhättan-Vänersborg Airport',
        cityNameVi: 'Trollhattan',
        cityNameEn: 'Trollhattan',
        countryNameVi: 'Thụy điển',
        countryNameEn: 'Sweden'
    },
    EVG: {
        id: 2660,
        airportCode: 'EVG',
        cityCode: 'EVG',
        countryCode: 'SE',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Sveg',
        airportNameEn: 'Sveg Airport',
        cityNameVi: 'Sveg',
        cityNameEn: 'Sveg',
        countryNameVi: 'Thụy điển',
        countryNameEn: 'Sweden'
    },
    MXX: {
        id: 2659,
        airportCode: 'MXX',
        cityCode: 'MXX',
        countryCode: 'SE',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Mora',
        airportNameEn: 'Mora Airport',
        cityNameVi: 'Mora',
        cityNameEn: 'Mora',
        countryNameVi: 'Thụy điển',
        countryNameEn: 'Sweden'
    },
    HUV: {
        id: 2658,
        airportCode: 'HUV',
        cityCode: 'HUV',
        countryCode: 'SE',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Hudiksvall',
        airportNameEn: 'Hudiksvall Airport',
        cityNameVi: 'Hudiksvall',
        cityNameEn: 'Hudiksvall',
        countryNameVi: 'Thụy điển',
        countryNameEn: 'Sweden'
    },
    GVX: {
        id: 2657,
        airportCode: 'GVX',
        cityCode: 'GVX',
        countryCode: 'SE',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Gävle-Sandviken',
        airportNameEn: 'Gävle-Sandviken Airport',
        cityNameVi: 'Gavle',
        cityNameEn: 'Gavle',
        countryNameVi: 'Thụy điển',
        countryNameEn: 'Sweden'
    },
    BLE: {
        id: 2656,
        airportCode: 'BLE',
        cityCode: 'BLE',
        countryCode: 'SE',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Borlänge',
        airportNameEn: 'Borlänge Airport ',
        cityNameVi: 'Borlange',
        cityNameEn: 'Borlange',
        countryNameVi: 'Thụy điển',
        countryNameEn: 'Sweden'
    },
    AGH: {
        id: 2655,
        airportCode: 'AGH',
        cityCode: 'AGH',
        countryCode: 'SE',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Ängelholm-Helsingborg',
        airportNameEn: 'Ängelholm-Helsingborg Airport ',
        cityNameVi: 'Angelholm',
        cityNameEn: 'Angelholm',
        countryNameVi: 'Thụy điển',
        countryNameEn: 'Sweden'
    },
    NYO: {
        id: 2654,
        airportCode: 'NYO',
        cityCode: 'STO',
        countryCode: 'SE',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Stockholm-Skavsta',
        airportNameEn: 'Stockholm-Skavsta Airport',
        cityNameVi: 'Xtốc-khôm',
        cityNameEn: 'Stockholm',
        countryNameVi: 'Thụy điển',
        countryNameEn: 'Sweden'
    },
    BMA: {
        id: 2653,
        airportCode: 'BMA',
        cityCode: 'STO',
        countryCode: 'SE',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Stockholm-Bromma',
        airportNameEn: 'Stockholm-Bromma Airport',
        cityNameVi: 'Xtốc-khôm',
        cityNameEn: 'Stockholm',
        countryNameVi: 'Thụy điển',
        countryNameEn: 'Sweden'
    },
    ARN: {
        id: 2652,
        airportCode: 'ARN',
        cityCode: 'STO',
        countryCode: 'SE',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Stockholm Arlanda',
        airportNameEn: 'Stockholm Arlanda Airport',
        cityNameVi: 'Xtốc-khôm',
        cityNameEn: 'Stockholm',
        countryNameVi: 'Thụy điển',
        countryNameEn: 'Sweden'
    },
    VBY: {
        id: 2651,
        airportCode: 'VBY',
        cityCode: 'VBY',
        countryCode: 'SE',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Visby',
        airportNameEn: 'Visby Airport',
        cityNameVi: 'Visby',
        cityNameEn: 'Visby',
        countryNameVi: 'Thụy điển',
        countryNameEn: 'Sweden'
    },
    VXO: {
        id: 2650,
        airportCode: 'VXO',
        cityCode: 'VXO',
        countryCode: 'SE',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Växjö',
        airportNameEn: 'Växjö Airport ',
        cityNameVi: 'Vaxjo',
        cityNameEn: 'Vaxjo',
        countryNameVi: 'Thụy điển',
        countryNameEn: 'Sweden'
    },
    VST: {
        id: 2649,
        airportCode: 'VST',
        cityCode: 'VST',
        countryCode: 'SE',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Stockholm-Västerås',
        airportNameEn: 'Stockholm-Västerås Airport',
        cityNameVi: 'Vasteras',
        cityNameEn: 'Vasteras',
        countryNameVi: 'Thụy điển',
        countryNameEn: 'Sweden'
    },
    UME: {
        id: 2648,
        airportCode: 'UME',
        cityCode: 'UME',
        countryCode: 'SE',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Umeå City',
        airportNameEn: 'Umeå City Airport',
        cityNameVi: 'Umea',
        cityNameEn: 'Umea',
        countryNameVi: 'Thụy điển',
        countryNameEn: 'Sweden'
    },
    SDL: {
        id: 2647,
        airportCode: 'SDL',
        cityCode: 'SDL',
        countryCode: 'SE',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Sundsvall-Härnösand',
        airportNameEn: 'Sundsvall-Härnösand Airport ',
        cityNameVi: 'Sundsvall',
        cityNameEn: 'Sundsvall',
        countryNameVi: 'Thụy điển',
        countryNameEn: 'Sweden'
    },
    STO: {
        id: 2646,
        airportCode: 'STO',
        cityCode: 'STO',
        countryCode: 'SE',
        continentCode: 'EU',
        airportNameVi: 'Tất cả các sân bay',
        airportNameEn: 'All Airports',
        cityNameVi: 'Stockholm',
        cityNameEn: 'Stockholm',
        countryNameVi: 'Thụy điển',
        countryNameEn: 'Sweden'
    },
    SFT: {
        id: 2645,
        airportCode: 'SFT',
        cityCode: 'SFT',
        countryCode: 'SE',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Skellefteå',
        airportNameEn: 'Skellefteå Airport',
        cityNameVi: 'Skelleftea',
        cityNameEn: 'Skelleftea',
        countryNameVi: 'Thụy điển',
        countryNameEn: 'Sweden'
    },
    RNB: {
        id: 2644,
        airportCode: 'RNB',
        cityCode: 'RNB',
        countryCode: 'SE',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Ronneby',
        airportNameEn: 'Ronneby Airport',
        cityNameVi: 'Ronneby',
        cityNameEn: 'Ronneby',
        countryNameVi: 'Thụy điển',
        countryNameEn: 'Sweden'
    },
    OSD: {
        id: 2643,
        airportCode: 'OSD',
        cityCode: 'OSD',
        countryCode: 'SE',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Åre Östersund',
        airportNameEn: 'Åre Östersund Airport',
        cityNameVi: 'Ostersund',
        cityNameEn: 'Ostersund',
        countryNameVi: 'Thụy điển',
        countryNameEn: 'Sweden'
    },
    OER: {
        id: 2642,
        airportCode: 'OER',
        cityCode: 'OER',
        countryCode: 'SE',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Örnsköldsvik',
        airportNameEn: 'Örnsköldsvik Airport',
        cityNameVi: 'Ornskoldsvik',
        cityNameEn: 'Ornskoldsvik',
        countryNameVi: 'Thụy điển',
        countryNameEn: 'Sweden'
    },
    NRK: {
        id: 2641,
        airportCode: 'NRK',
        cityCode: 'NRK',
        countryCode: 'SE',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Norrköping',
        airportNameEn: 'Norrköping Airport',
        cityNameVi: 'Norrkoping',
        cityNameEn: 'Norrkoping',
        countryNameVi: 'Thụy điển',
        countryNameEn: 'Sweden'
    },
    MMA: {
        id: 2640,
        airportCode: 'MMA',
        cityCode: 'MMA',
        countryCode: 'SE',
        continentCode: 'EU',
        airportNameVi: 'Tất cả các sân bay',
        airportNameEn: 'All Airports',
        cityNameVi: 'Malmö',
        cityNameEn: 'Malmö',
        countryNameVi: 'Thụy điển',
        countryNameEn: 'Sweden'
    },
    MMX: {
        id: 2639,
        airportCode: 'MMX',
        cityCode: 'MMA',
        countryCode: 'SE',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Malmö-Sturup',
        airportNameEn: 'Malmö-Sturup Airport',
        cityNameVi: 'Malmo',
        cityNameEn: 'Malmo',
        countryNameVi: 'Thụy điển',
        countryNameEn: 'Sweden'
    },
    HMA: {
        id: 2638,
        airportCode: 'HMA',
        cityCode: 'MMA',
        countryCode: 'SE',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Khanty-Mansiysk',
        airportNameEn: 'Khanty-Mansiysk Airport',
        cityNameVi: 'Malmo',
        cityNameEn: 'Malmo',
        countryNameVi: 'Thụy điển',
        countryNameEn: 'Sweden'
    },
    LYC: {
        id: 2637,
        airportCode: 'LYC',
        cityCode: 'LYC',
        countryCode: 'SE',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Lycksele',
        airportNameEn: 'Lycksele Airport',
        cityNameVi: 'Lycksele',
        cityNameEn: 'Lycksele',
        countryNameVi: 'Thụy điển',
        countryNameEn: 'Sweden'
    },
    LLA: {
        id: 2636,
        airportCode: 'LLA',
        cityCode: 'LLA',
        countryCode: 'SE',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Luleå',
        airportNameEn: 'Luleå Airport ',
        cityNameVi: 'Lulea',
        cityNameEn: 'Lulea',
        countryNameVi: 'Thụy điển',
        countryNameEn: 'Sweden'
    },
    LPI: {
        id: 2635,
        airportCode: 'LPI',
        cityCode: 'LPI',
        countryCode: 'SE',
        continentCode: 'EU',
        airportNameVi: 'Sân bay SAAB',
        airportNameEn: 'SAAB Airport',
        cityNameVi: 'Linkoping',
        cityNameEn: 'Linkoping',
        countryNameVi: 'Thụy điển',
        countryNameEn: 'Sweden'
    },
    KID: {
        id: 2634,
        airportCode: 'KID',
        cityCode: 'KID',
        countryCode: 'SE',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Kristianstad',
        airportNameEn: 'Kristianstad Airport',
        cityNameVi: 'Kristianstad',
        cityNameEn: 'Kristianstad',
        countryNameVi: 'Thụy điển',
        countryNameEn: 'Sweden'
    },
    KRN: {
        id: 2633,
        airportCode: 'KRN',
        cityCode: 'KRN',
        countryCode: 'SE',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Kiruna',
        airportNameEn: 'Kiruna Airport',
        cityNameVi: 'Kiruna',
        cityNameEn: 'Kiruna',
        countryNameVi: 'Thụy điển',
        countryNameEn: 'Sweden'
    },
    KSD: {
        id: 2632,
        airportCode: 'KSD',
        cityCode: 'KSD',
        countryCode: 'SE',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Karlstad',
        airportNameEn: 'Karlstad Airport',
        cityNameVi: 'Karlstad',
        cityNameEn: 'Karlstad',
        countryNameVi: 'Thụy điển',
        countryNameEn: 'Sweden'
    },
    KLR: {
        id: 2631,
        airportCode: 'KLR',
        cityCode: 'KLR',
        countryCode: 'SE',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Kalmar',
        airportNameEn: 'Kalmar Airport',
        cityNameVi: 'Kalmar',
        cityNameEn: 'Kalmar',
        countryNameVi: 'Thụy điển',
        countryNameEn: 'Sweden'
    },
    JKG: {
        id: 2630,
        airportCode: 'JKG',
        cityCode: 'JKG',
        countryCode: 'SE',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Jönköping',
        airportNameEn: 'Jönköping Airport',
        cityNameVi: 'Jonkoping',
        cityNameEn: 'Jonkoping',
        countryNameVi: 'Thụy điển',
        countryNameEn: 'Sweden'
    },
    HAD: {
        id: 2629,
        airportCode: 'HAD',
        cityCode: 'HAD',
        countryCode: 'SE',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Halmstad',
        airportNameEn: 'Halmstad Airport',
        cityNameVi: 'Halmstad',
        cityNameEn: 'Halmstad',
        countryNameVi: 'Thụy điển',
        countryNameEn: 'Sweden'
    },
    GSE: {
        id: 2628,
        airportCode: 'GSE',
        cityCode: 'GOT',
        countryCode: 'SE',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Gothenburg City',
        airportNameEn: 'Gothenburg City Airport ',
        cityNameVi: 'Gothenburg',
        cityNameEn: 'Gothenburg',
        countryNameVi: 'Thụy điển',
        countryNameEn: 'Sweden'
    },
    AJR: {
        id: 2627,
        airportCode: 'AJR',
        cityCode: 'AJR',
        countryCode: 'SE',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Arvidsjaur',
        airportNameEn: 'Arvidsjaur Airport',
        cityNameVi: 'Arvidsjaur',
        cityNameEn: 'Arvidsjaur',
        countryNameVi: 'Thụy điển',
        countryNameEn: 'Sweden'
    },
    MTS: {
        id: 2626,
        airportCode: 'MTS',
        cityCode: 'MTS',
        countryCode: 'SZ',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Matsapha',
        airportNameEn: 'Matsapha Airport',
        cityNameVi: 'Manzini',
        cityNameEn: 'Manzini',
        countryNameVi: 'Swaziland',
        countryNameEn: 'Swaziland'
    },
    RGA: {
        id: 2625,
        airportCode: 'RGA',
        cityCode: 'RGA',
        countryCode: 'SR',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Hermes Quijada',
        airportNameEn: 'Hermes Quijada International Airport',
        cityNameVi: 'Rio Grande',
        cityNameEn: 'Rio Grande',
        countryNameVi: 'Suriname',
        countryNameEn: 'Suriname'
    },
    ORG: {
        id: 2624,
        airportCode: 'ORG',
        cityCode: 'PBM',
        countryCode: 'SR',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Zorg en Hoop',
        airportNameEn: 'Zorg en Hoop Airport',
        cityNameVi: 'Paramaribo',
        cityNameEn: 'Paramaribo',
        countryNameVi: 'Suriname',
        countryNameEn: 'Suriname'
    },
    PBM: {
        id: 2623,
        airportCode: 'PBM',
        cityCode: 'PBM',
        countryCode: 'SR',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Johan Adolf Pengel',
        airportNameEn: 'Johan Adolf Pengel International Airport',
        cityNameVi: 'Paramaribo',
        cityNameEn: 'Paramaribo',
        countryNameVi: 'Suriname',
        countryNameEn: 'Suriname'
    },
    KRT: {
        id: 2622,
        airportCode: 'KRT',
        cityCode: 'KRT',
        countryCode: 'SD',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Khartoum',
        airportNameEn: 'Khartoum International Airport',
        cityNameVi: 'Khartoum',
        cityNameEn: 'Khartoum',
        countryNameVi: 'Sudan',
        countryNameEn: 'Sudan'
    },
    CMB: {
        id: 2621,
        airportCode: 'CMB',
        cityCode: 'CMB',
        countryCode: 'LK',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Bandaranaike',
        airportNameEn: 'Bandaranaike International Airport',
        cityNameVi: 'Colombo',
        cityNameEn: 'Colombo',
        countryNameVi: 'Sri Lanka',
        countryNameEn: 'Sri Lanka'
    },
    ZAZ: {
        id: 2620,
        airportCode: 'ZAZ',
        cityCode: 'ZAZ',
        countryCode: 'ES',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Zaragoza',
        airportNameEn: 'Zaragoza Airport',
        cityNameVi: 'Zaragoza',
        cityNameEn: 'Zaragoza',
        countryNameVi: 'Tây Ban Nha',
        countryNameEn: 'Spain'
    },
    VIT: {
        id: 2619,
        airportCode: 'VIT',
        cityCode: 'VIT',
        countryCode: 'ES',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Vitoria Foronda',
        airportNameEn: 'Vitoria Foronda International Airport',
        cityNameVi: 'Vitoria',
        cityNameEn: 'Vitoria',
        countryNameVi: 'Tây Ban Nha',
        countryNameEn: 'Spain'
    },
    VGO: {
        id: 2618,
        airportCode: 'VGO',
        cityCode: 'VGO',
        countryCode: 'ES',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Vigo-Peinador',
        airportNameEn: 'Vigo-Peinador Airport',
        cityNameVi: 'Vigo',
        cityNameEn: 'Vigo',
        countryNameVi: 'Tây Ban Nha',
        countryNameEn: 'Spain'
    },
    VLL: {
        id: 2617,
        airportCode: 'VLL',
        cityCode: 'VLL',
        countryCode: 'ES',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Valladolid',
        airportNameEn: 'Valladolid Airport',
        cityNameVi: 'Valladolid',
        cityNameEn: 'Valladolid',
        countryNameVi: 'Tây Ban Nha',
        countryNameEn: 'Spain'
    },
    VLC: {
        id: 2616,
        airportCode: 'VLC',
        cityCode: 'VLC',
        countryCode: 'ES',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Valencia',
        airportNameEn: 'Valencia Airport',
        cityNameVi: 'Valencia',
        cityNameEn: 'Valencia',
        countryNameVi: 'Tây Ban Nha',
        countryNameEn: 'Spain'
    },
    TCI: {
        id: 2615,
        airportCode: 'TCI',
        cityCode: 'TCI',
        countryCode: 'ES',
        continentCode: 'EU',
        airportNameVi: 'Tất cả các sân bay',
        airportNameEn: 'All Airports',
        cityNameVi: 'Tenerife',
        cityNameEn: 'Tenerife',
        countryNameVi: 'Tây Ban Nha',
        countryNameEn: 'Spain'
    },
    TFS: {
        id: 2614,
        airportCode: 'TFS',
        cityCode: 'TCI',
        countryCode: 'ES',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Tenerife South',
        airportNameEn: 'Tenerife South Airport ',
        cityNameVi: 'Tenerife',
        cityNameEn: 'Tenerife',
        countryNameVi: 'Tây Ban Nha',
        countryNameEn: 'Spain'
    },
    TFN: {
        id: 2613,
        airportCode: 'TFN',
        cityCode: 'TCI',
        countryCode: 'ES',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Tenerife North',
        airportNameEn: 'Tenerife North Airport ',
        cityNameVi: 'Tenerife',
        cityNameEn: 'Tenerife',
        countryNameVi: 'Tây Ban Nha',
        countryNameEn: 'Spain'
    },
    SVQ: {
        id: 2612,
        airportCode: 'SVQ',
        cityCode: 'SVQ',
        countryCode: 'ES',
        continentCode: 'EU',
        airportNameVi: 'Sân bay San Pablo',
        airportNameEn: 'San Pablo Airport',
        cityNameVi: 'Sevilla',
        cityNameEn: 'Sevilla',
        countryNameVi: 'Tây Ban Nha',
        countryNameEn: 'Spain'
    },
    SCQ: {
        id: 2611,
        airportCode: 'SCQ',
        cityCode: 'SCQ',
        countryCode: 'ES',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Santiago de Compostela',
        airportNameEn: 'Santiago de Compostela Airport',
        cityNameVi: 'Santiago De Compostela',
        cityNameEn: 'Santiago De Compostela',
        countryNameVi: 'Tây Ban Nha',
        countryNameEn: 'Spain'
    },
    SDR: {
        id: 2610,
        airportCode: 'SDR',
        cityCode: 'SDR',
        countryCode: 'ES',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Santander - Parayas',
        airportNameEn: 'Santander - Parayas Airport',
        cityNameVi: 'Santander',
        cityNameEn: 'Santander',
        countryNameVi: 'Tây Ban Nha',
        countryNameEn: 'Spain'
    },
    EAS: {
        id: 2609,
        airportCode: 'EAS',
        cityCode: 'EAS',
        countryCode: 'ES',
        continentCode: 'EU',
        airportNameVi: 'Sân bay San Sebastián',
        airportNameEn: 'San Sebastián Airport ',
        cityNameVi: 'San Sebastion',
        cityNameEn: 'San Sebastion',
        countryNameVi: 'Tây Ban Nha',
        countryNameEn: 'Spain'
    },
    GMZ: {
        id: 2608,
        airportCode: 'GMZ',
        cityCode: 'GMZ',
        countryCode: 'ES',
        continentCode: 'AF',
        airportNameVi: 'Sân bay La Gomera',
        airportNameEn: 'La Gomera Airport',
        cityNameVi: 'San Sebastian De La Gomera',
        cityNameEn: 'San Sebastian De La Gomera',
        countryNameVi: 'Tây Ban Nha',
        countryNameEn: 'Spain'
    },
    SLM: {
        id: 2607,
        airportCode: 'SLM',
        cityCode: 'SLM',
        countryCode: 'ES',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Matacán',
        airportNameEn: 'Matacán Airport',
        cityNameVi: 'Salamanca',
        cityNameEn: 'Salamanca',
        countryNameVi: 'Tây Ban Nha',
        countryNameEn: 'Spain'
    },
    REU: {
        id: 2606,
        airportCode: 'REU',
        cityCode: 'REU',
        countryCode: 'ES',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Reus',
        airportNameEn: 'Reus Airport',
        cityNameVi: 'Reus',
        cityNameEn: 'Reus',
        countryNameVi: 'Tây Ban Nha',
        countryNameEn: 'Spain'
    },
    PNA: {
        id: 2605,
        airportCode: 'PNA',
        cityCode: 'PNA',
        countryCode: 'ES',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Pamplona',
        airportNameEn: 'Pamplona Airport',
        cityNameVi: 'Pamplona',
        cityNameEn: 'Pamplona',
        countryNameVi: 'Tây Ban Nha',
        countryNameEn: 'Spain'
    },
    MJV: {
        id: 2604,
        airportCode: 'MJV',
        cityCode: 'MJV',
        countryCode: 'ES',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Murcia-San Javier',
        airportNameEn: 'Murcia-San Javier Airport',
        cityNameVi: 'Murcia',
        cityNameEn: 'Murcia',
        countryNameVi: 'Tây Ban Nha',
        countryNameEn: 'Spain'
    },
    MAH: {
        id: 2603,
        airportCode: 'MAH',
        cityCode: 'MAH',
        countryCode: 'ES',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Mahon',
        airportNameEn: 'Mahon Airport',
        cityNameVi: 'Menorca',
        cityNameEn: 'Menorca',
        countryNameVi: 'Tây Ban Nha',
        countryNameEn: 'Spain'
    },
    MLN: {
        id: 2602,
        airportCode: 'MLN',
        cityCode: 'MLN',
        countryCode: 'ES',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Melilla',
        airportNameEn: 'Melilla Airport',
        cityNameVi: 'Melilla',
        cityNameEn: 'Melilla',
        countryNameVi: 'Tây Ban Nha',
        countryNameEn: 'Spain'
    },
    PMI: {
        id: 2601,
        airportCode: 'PMI',
        cityCode: 'PMI',
        countryCode: 'ES',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Palma de Mallorca',
        airportNameEn: 'Palma de Mallorca Airport ',
        cityNameVi: 'Mallorca',
        cityNameEn: 'Mallorca',
        countryNameVi: 'Tây Ban Nha',
        countryNameEn: 'Spain'
    },
    AGP: {
        id: 2600,
        airportCode: 'AGP',
        cityCode: 'AGP',
        countryCode: 'ES',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Pablo Ruiz Picasso',
        airportNameEn: 'Pablo Ruiz Picasso Airport',
        cityNameVi: 'Malaga',
        cityNameEn: 'Malaga',
        countryNameVi: 'Tây Ban Nha',
        countryNameEn: 'Spain'
    },
    MAD: {
        id: 2599,
        airportCode: 'MAD',
        cityCode: 'MAD',
        countryCode: 'ES',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Madrid–Barajas',
        airportNameEn: 'Madrid–Barajas Airport',
        cityNameVi: 'Madrid',
        cityNameEn: 'Madrid',
        countryNameVi: 'Tây Ban Nha',
        countryNameEn: 'Spain'
    },
    LEN: {
        id: 2598,
        airportCode: 'LEN',
        cityCode: 'LEN',
        countryCode: 'ES',
        continentCode: 'EU',
        airportNameVi: 'Sân bay León',
        airportNameEn: 'León Airport',
        cityNameVi: 'Leon',
        cityNameEn: 'Leon',
        countryNameVi: 'Tây Ban Nha',
        countryNameEn: 'Spain'
    },
    ACE: {
        id: 2597,
        airportCode: 'ACE',
        cityCode: 'ACE',
        countryCode: 'ES',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Lanzarote',
        airportNameEn: 'Lanzarote Airport',
        cityNameVi: 'Lanzarote',
        cityNameEn: 'Lanzarote',
        countryNameVi: 'Tây Ban Nha',
        countryNameEn: 'Spain'
    },
    SPC: {
        id: 2596,
        airportCode: 'SPC',
        cityCode: 'SPC',
        countryCode: 'ES',
        continentCode: 'EU',
        airportNameVi: 'Sân bay La Palma',
        airportNameEn: 'La Palma Airport',
        cityNameVi: 'La Palma',
        cityNameEn: 'La Palma',
        countryNameVi: 'Tây Ban Nha',
        countryNameEn: 'Spain'
    },
    LCG: {
        id: 2595,
        airportCode: 'LCG',
        cityCode: 'LCG',
        countryCode: 'ES',
        continentCode: 'EU',
        airportNameVi: 'Sân bay La Coruña',
        airportNameEn: 'La Coruña Airport',
        cityNameVi: 'La Coruna',
        cityNameEn: 'La Coruna',
        countryNameVi: 'Tây Ban Nha',
        countryNameEn: 'Spain'
    },
    XRY: {
        id: 2594,
        airportCode: 'XRY',
        cityCode: 'XRY',
        countryCode: 'ES',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Jerez',
        airportNameEn: 'Jerez Airport ',
        cityNameVi: 'Jerez De La Frontera',
        cityNameEn: 'Jerez De La Frontera',
        countryNameVi: 'Tây Ban Nha',
        countryNameEn: 'Spain'
    },
    IBZ: {
        id: 2593,
        airportCode: 'IBZ',
        cityCode: 'IBZ',
        countryCode: 'ES',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Ibiza',
        airportNameEn: 'Ibiza Airport',
        cityNameVi: 'Ibiza',
        cityNameEn: 'Ibiza',
        countryNameVi: 'Tây Ban Nha',
        countryNameEn: 'Spain'
    },
    GRX: {
        id: 2592,
        airportCode: 'GRX',
        cityCode: 'GRX',
        countryCode: 'ES',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Granada Jaén',
        airportNameEn: 'Granada Jaén Airport',
        cityNameVi: 'Granada',
        cityNameEn: 'Granada',
        countryNameVi: 'Tây Ban Nha',
        countryNameEn: 'Spain'
    },
    LPA: {
        id: 2591,
        airportCode: 'LPA',
        cityCode: 'LPA',
        countryCode: 'ES',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Gran Canaria',
        airportNameEn: 'Gran Canaria International Airport',
        cityNameVi: 'Gran Canaria',
        cityNameEn: 'Gran Canaria',
        countryNameVi: 'Tây Ban Nha',
        countryNameEn: 'Spain'
    },
    GRO: {
        id: 2590,
        airportCode: 'GRO',
        cityCode: 'GRO',
        countryCode: 'ES',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Girona-Costa Brava',
        airportNameEn: 'Girona-Costa Brava Airport',
        cityNameVi: 'Gerona',
        cityNameEn: 'Gerona',
        countryNameVi: 'Tây Ban Nha',
        countryNameEn: 'Spain'
    },
    FUE: {
        id: 2589,
        airportCode: 'FUE',
        cityCode: 'FUE',
        countryCode: 'ES',
        continentCode: 'EU',
        airportNameVi: 'Sân bay El Matorral',
        airportNameEn: 'El Matorral Airport',
        cityNameVi: 'Fuerteventura',
        cityNameEn: 'Fuerteventura',
        countryNameVi: 'Tây Ban Nha',
        countryNameEn: 'Spain'
    },
    BIO: {
        id: 2588,
        airportCode: 'BIO',
        cityCode: 'BIO',
        countryCode: 'ES',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Bilbao',
        airportNameEn: 'Bilbao Airport',
        cityNameVi: 'Bilbao',
        cityNameEn: 'Bilbao',
        countryNameVi: 'Tây Ban Nha',
        countryNameEn: 'Spain'
    },
    BCN: {
        id: 2587,
        airportCode: 'BCN',
        cityCode: 'BCN',
        countryCode: 'ES',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Barcelona El Prat',
        airportNameEn: 'Barcelona El Prat Airport',
        cityNameVi: 'Barcelona',
        cityNameEn: 'Barcelona',
        countryNameVi: 'Tây Ban Nha',
        countryNameEn: 'Spain'
    },
    BJZ: {
        id: 2586,
        airportCode: 'BJZ',
        cityCode: 'BJZ',
        countryCode: 'ES',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Badajoz',
        airportNameEn: 'Badajoz Airport ',
        cityNameVi: 'Badajoz',
        cityNameEn: 'Badajoz',
        countryNameVi: 'Tây Ban Nha',
        countryNameEn: 'Spain'
    },
    OVD: {
        id: 2585,
        airportCode: 'OVD',
        cityCode: 'OVD',
        countryCode: 'ES',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Asturias',
        airportNameEn: 'Asturias Airport ',
        cityNameVi: 'Asturias',
        cityNameEn: 'Asturias',
        countryNameVi: 'Tây Ban Nha',
        countryNameEn: 'Spain'
    },
    LEI: {
        id: 2584,
        airportCode: 'LEI',
        cityCode: 'LEI',
        countryCode: 'ES',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Almería',
        airportNameEn: 'Almería International Airport',
        cityNameVi: 'Almeria',
        cityNameEn: 'Almeria',
        countryNameVi: 'Tây Ban Nha',
        countryNameEn: 'Spain'
    },
    ALC: {
        id: 2583,
        airportCode: 'ALC',
        cityCode: 'ALC',
        countryCode: 'ES',
        continentCode: 'EU',
        airportNameVi: 'Sân bay El Altet',
        airportNameEn: 'El Altet Airport',
        cityNameVi: 'Alicante',
        cityNameEn: 'Alicante',
        countryNameVi: 'Tây Ban Nha',
        countryNameEn: 'Spain'
    },
    SEL: {
        id: 2071,
        airportCode: 'SEL',
        cityCode: 'SEL',
        countryCode: 'KR',
        continentCode: 'AS',
        airportNameVi: 'Sân bay All',
        airportNameEn: 'All Airports',
        cityNameVi: 'Seoul',
        cityNameEn: 'Seoul',
        countryNameVi: 'Hàn quốc',
        countryNameEn: 'Korea'
    },
    PZB: {
        id: 2581,
        airportCode: 'PZB',
        cityCode: 'PZB',
        countryCode: 'ZA',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Pietermaritzburg',
        airportNameEn: 'Pietermaritzburg Airport',
        cityNameVi: 'Pietermaritzburg',
        cityNameEn: 'Pietermaritzburg',
        countryNameVi: 'Nam Phi',
        countryNameEn: 'South Africa'
    },
    HLA: {
        id: 2580,
        airportCode: 'HLA',
        cityCode: 'HLA',
        countryCode: 'ZA',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Lanseria',
        airportNameEn: 'Lanseria Airport',
        cityNameVi: 'Lanseria',
        cityNameEn: 'Lanseria',
        countryNameVi: 'Nam Phi',
        countryNameEn: 'South Africa'
    },
    MQP: {
        id: 2579,
        airportCode: 'MQP',
        cityCode: 'MQP',
        countryCode: 'ZA',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Kruger Mpumalanga',
        airportNameEn: 'Kruger Mpumalanga International Airport',
        cityNameVi: 'Nelspruit',
        cityNameEn: 'Nelspruit',
        countryNameVi: 'Nam Phi',
        countryNameEn: 'South Africa'
    },
    UTN: {
        id: 2578,
        airportCode: 'UTN',
        cityCode: 'UTN',
        countryCode: 'ZA',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Upington',
        airportNameEn: 'Upington Airport',
        cityNameVi: 'Upington',
        cityNameEn: 'Upington',
        countryNameVi: 'Nam Phi',
        countryNameEn: 'South Africa'
    },
    UTT: {
        id: 2577,
        airportCode: 'UTT',
        cityCode: 'UTT',
        countryCode: 'ZA',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Mthatha',
        airportNameEn: 'Mthatha Airport',
        cityNameVi: 'Umtata',
        cityNameEn: 'Umtata',
        countryNameVi: 'Nam Phi',
        countryNameEn: 'South Africa'
    },
    RCB: {
        id: 2576,
        airportCode: 'RCB',
        cityCode: 'RCB',
        countryCode: 'ZA',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Richards Bay',
        airportNameEn: 'Richards Bay Airport',
        cityNameVi: 'Richards Bay',
        cityNameEn: 'Richards Bay',
        countryNameVi: 'Nam Phi',
        countryNameEn: 'South Africa'
    },
    PLZ: {
        id: 2575,
        airportCode: 'PLZ',
        cityCode: 'PLZ',
        countryCode: 'ZA',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Port Elizabeth',
        airportNameEn: 'Port Elizabeth Airport',
        cityNameVi: 'Port Elizabeth',
        cityNameEn: 'Port Elizabeth',
        countryNameVi: 'Nam Phi',
        countryNameEn: 'South Africa'
    },
    PTG: {
        id: 2574,
        airportCode: 'PTG',
        cityCode: 'PTG',
        countryCode: 'ZA',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Polokwane',
        airportNameEn: 'Polokwane International Airport',
        cityNameVi: 'Pietersburg',
        cityNameEn: 'Pietersburg',
        countryNameVi: 'Nam Phi',
        countryNameEn: 'South Africa'
    },
    PHW: {
        id: 2573,
        airportCode: 'PHW',
        cityCode: 'PHW',
        countryCode: 'ZA',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Hendrik Van Eck',
        airportNameEn: 'Hendrik Van Eck Airport',
        cityNameVi: 'Phalaborwa',
        cityNameEn: 'Phalaborwa',
        countryNameVi: 'Nam Phi',
        countryNameEn: 'South Africa'
    },
    KIM: {
        id: 2572,
        airportCode: 'KIM',
        cityCode: 'KIM',
        countryCode: 'ZA',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Kimberley',
        airportNameEn: 'Kimberley Airport',
        cityNameVi: 'Kimberley',
        cityNameEn: 'Kimberley',
        countryNameVi: 'Nam Phi',
        countryNameEn: 'South Africa'
    },
    QRA: {
        id: 2571,
        airportCode: 'QRA',
        cityCode: 'JNB',
        countryCode: 'ZA',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Rand/Germiston',
        airportNameEn: 'Rand/Germiston Airport',
        cityNameVi: 'Johannesburg',
        cityNameEn: 'Johannesburg',
        countryNameVi: 'Nam Phi',
        countryNameEn: 'South Africa'
    },
    JNB: {
        id: 2570,
        airportCode: 'JNB',
        cityCode: 'JNB',
        countryCode: 'ZA',
        continentCode: 'AF',
        airportNameVi: 'Sân bay OR Tambo',
        airportNameEn: 'OR Tambo International Airport',
        cityNameVi: 'Johannesburg',
        cityNameEn: 'Johannesburg',
        countryNameVi: 'Nam Phi',
        countryNameEn: 'South Africa'
    },
    GCJ: {
        id: 2569,
        airportCode: 'GCJ',
        cityCode: 'JNB',
        countryCode: 'ZA',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Grand Central',
        airportNameEn: 'Grand Central Airport',
        cityNameVi: 'Johannesburg',
        cityNameEn: 'Johannesburg',
        countryNameVi: 'Nam Phi',
        countryNameEn: 'South Africa'
    },
    HDS: {
        id: 2568,
        airportCode: 'HDS',
        cityCode: 'HDS',
        countryCode: 'ZA',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Hoedspruit',
        airportNameEn: 'Hoedspruit Airport',
        cityNameVi: 'Hoedspruit',
        cityNameEn: 'Hoedspruit',
        countryNameVi: 'Nam Phi',
        countryNameEn: 'South Africa'
    },
    GRJ: {
        id: 2567,
        airportCode: 'GRJ',
        cityCode: 'GRJ',
        countryCode: 'ZA',
        continentCode: 'AF',
        airportNameVi: 'Sân bay George',
        airportNameEn: 'George Airport',
        cityNameVi: 'George',
        cityNameEn: 'George',
        countryNameVi: 'Nam Phi',
        countryNameEn: 'South Africa'
    },
    ELS: {
        id: 2566,
        airportCode: 'ELS',
        cityCode: 'ELS',
        countryCode: 'ZA',
        continentCode: 'AF',
        airportNameVi: 'Sân bay East London',
        airportNameEn: 'East London Airport',
        cityNameVi: 'East London',
        cityNameEn: 'East London',
        countryNameVi: 'Nam Phi',
        countryNameEn: 'South Africa'
    },
    CPT: {
        id: 2565,
        airportCode: 'CPT',
        cityCode: 'CPT',
        countryCode: 'ZA',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Cape Town',
        airportNameEn: 'Cape Town International Airport',
        cityNameVi: 'Cape Town',
        cityNameEn: 'Cape Town',
        countryNameVi: 'Nam Phi',
        countryNameEn: 'South Africa'
    },
    BFN: {
        id: 2564,
        airportCode: 'BFN',
        cityCode: 'BFN',
        countryCode: 'ZA',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Bloemfontein',
        airportNameEn: 'Bloemfontein Airport',
        cityNameVi: 'Bloemfontein',
        cityNameEn: 'Bloemfontein',
        countryNameVi: 'Nam Phi',
        countryNameEn: 'South Africa'
    },
    HGA: {
        id: 2563,
        airportCode: 'HGA',
        cityCode: 'HGA',
        countryCode: 'SO',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Egal',
        airportNameEn: 'Egal International Airport',
        cityNameVi: 'Hargeisa',
        cityNameEn: 'Hargeisa',
        countryNameVi: 'Somalia',
        countryNameEn: 'Somalia'
    },
    EGM: {
        id: 2562,
        airportCode: 'EGM',
        cityCode: 'EGM',
        countryCode: 'SB',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Sege',
        airportNameEn: 'Sege Airport',
        cityNameVi: 'Sege',
        cityNameEn: 'Sege',
        countryNameVi: 'Solomon Islands',
        countryNameEn: 'Solomon Islands'
    },
    MUA: {
        id: 2561,
        airportCode: 'MUA',
        cityCode: 'MUA',
        countryCode: 'SB',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Munda',
        airportNameEn: 'Munda Airport',
        cityNameVi: 'Munda',
        cityNameEn: 'Munda',
        countryNameVi: 'Solomon Islands',
        countryNameEn: 'Solomon Islands'
    },
    BAS: {
        id: 2560,
        airportCode: 'BAS',
        cityCode: 'BAS',
        countryCode: 'SB',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Ballalae',
        airportNameEn: 'Ballalae Airport',
        cityNameVi: 'Balalae',
        cityNameEn: 'Balalae',
        countryNameVi: 'Solomon Islands',
        countryNameEn: 'Solomon Islands'
    },
    HIR: {
        id: 2559,
        airportCode: 'HIR',
        cityCode: 'HIR',
        countryCode: 'SB',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Honiara',
        airportNameEn: 'Honiara International Airport',
        cityNameVi: 'Honiara',
        cityNameEn: 'Honiara',
        countryNameVi: 'Solomon Islands',
        countryNameEn: 'Solomon Islands'
    },
    LJU: {
        id: 2558,
        airportCode: 'LJU',
        cityCode: 'LJU',
        countryCode: 'SI',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Ljubljana Jože Pucnik',
        airportNameEn: 'Ljubljana Jože Pucnik Airport ',
        cityNameVi: 'Ljubljana',
        cityNameEn: 'Ljubljana',
        countryNameVi: 'Slovenia',
        countryNameEn: 'Slovenia'
    },
    KSC: {
        id: 2557,
        airportCode: 'KSC',
        cityCode: 'KSC',
        countryCode: 'SK',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Košice',
        airportNameEn: 'Košice Airport ',
        cityNameVi: 'Kosice',
        cityNameEn: 'Kosice',
        countryNameVi: 'Slovakia',
        countryNameEn: 'Slovakia'
    },
    BTS: {
        id: 2556,
        airportCode: 'BTS',
        cityCode: 'BTS',
        countryCode: 'SK',
        continentCode: 'EU',
        airportNameVi: 'Sân bay M. R. Štefánik',
        airportNameEn: 'M. R. Štefánik Airport',
        cityNameVi: 'Bratislava',
        cityNameEn: 'Bratislava',
        countryNameVi: 'Slovakia',
        countryNameEn: 'Slovakia'
    },
    SIN: {
        id: 2555,
        airportCode: 'SIN',
        cityCode: 'SIN',
        countryCode: 'SG',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Singapore Changi',
        airportNameEn: 'Singapore Changi Airport',
        cityNameVi: 'Singapore',
        cityNameEn: 'Singapore',
        countryNameVi: 'Singapore',
        countryNameEn: 'Singapore'
    },
    FNA: {
        id: 2554,
        airportCode: 'FNA',
        cityCode: 'FNA',
        countryCode: 'SL',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Lungi',
        airportNameEn: 'Lungi International Airport',
        cityNameVi: 'Freetown',
        cityNameEn: 'Freetown',
        countryNameVi: 'Sierra Leone',
        countryNameEn: 'Sierra Leone'
    },
    PRI: {
        id: 2553,
        airportCode: 'PRI',
        cityCode: 'PRI',
        countryCode: 'SC',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Praslin Island',
        airportNameEn: 'Praslin Island Airport',
        cityNameVi: 'Praslin Island',
        cityNameEn: 'Praslin Island',
        countryNameVi: 'Seychelles Islands',
        countryNameEn: 'Seychelles Islands'
    },
    SEZ: {
        id: 2552,
        airportCode: 'SEZ',
        cityCode: 'SEZ',
        countryCode: 'SC',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Seychelles',
        airportNameEn: 'Seychelles International Airport',
        cityNameVi: 'Mahe Island',
        cityNameEn: 'Mahe Island',
        countryNameVi: 'Seychelles Islands',
        countryNameEn: 'Seychelles Islands'
    },
    SPA: {
        id: 2551,
        airportCode: 'SPA',
        cityCode: 'GSP',
        countryCode: 'SC',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Spartanburg Downtown Memorial',
        airportNameEn: 'Spartanburg Downtown Memorial Airport',
        cityNameVi: 'Greenville',
        cityNameEn: 'Greenville',
        countryNameVi: 'Seychelles Islands',
        countryNameEn: 'Seychelles Islands'
    },
    GMU: {
        id: 2550,
        airportCode: 'GMU',
        cityCode: 'GSP',
        countryCode: 'SC',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Greenville Downtown',
        airportNameEn: 'Greenville Downtown Airport',
        cityNameVi: 'Greenville',
        cityNameEn: 'Greenville',
        countryNameVi: 'Seychelles Islands',
        countryNameEn: 'Seychelles Islands'
    },
    FLO: {
        id: 2549,
        airportCode: 'FLO',
        cityCode: 'FLO',
        countryCode: 'SC',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Florence Regional',
        airportNameEn: 'Florence Regional Airport',
        cityNameVi: 'Florence',
        cityNameEn: 'Florence',
        countryNameVi: 'Seychelles Islands',
        countryNameEn: 'Seychelles Islands'
    },
    PRN: {
        id: 2548,
        airportCode: 'PRN',
        cityCode: 'PRN',
        countryCode: 'RS',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Pristina',
        airportNameEn: 'Pristina International Airport Adem Jashari',
        cityNameVi: 'Prishtina',
        cityNameEn: 'Prishtina',
        countryNameVi: 'Serbia',
        countryNameEn: 'Serbia'
    },
    INI: {
        id: 2547,
        airportCode: 'INI',
        cityCode: 'INI',
        countryCode: 'RS',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Niš Constantine the Great',
        airportNameEn: 'Niš Constantine the Great Airport',
        cityNameVi: 'Nis',
        cityNameEn: 'Nis',
        countryNameVi: 'Serbia',
        countryNameEn: 'Serbia'
    },
    BJY: {
        id: 2546,
        airportCode: 'BJY',
        cityCode: 'BJY',
        countryCode: 'RS',
        continentCode: 'EU',
        airportNameVi: 'Batajnica Air Base',
        airportNameEn: 'Batajnica Air Base',
        cityNameVi: 'Belgrade',
        cityNameEn: 'Belgrade',
        countryNameVi: 'Serbia',
        countryNameEn: 'Serbia'
    },
    BEG: {
        id: 2545,
        airportCode: 'BEG',
        cityCode: 'BEG',
        countryCode: 'RS',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Belgrade Nikola Tesla',
        airportNameEn: 'Belgrade Nikola Tesla Airport',
        cityNameVi: 'Belgrade',
        cityNameEn: 'Belgrade',
        countryNameVi: 'Serbia',
        countryNameEn: 'Serbia'
    },
    ZIG: {
        id: 2544,
        airportCode: 'ZIG',
        cityCode: 'ZIG',
        countryCode: 'SN',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Ziguinchor',
        airportNameEn: 'Ziguinchor Airport',
        cityNameVi: 'Ziguinchor',
        cityNameEn: 'Ziguinchor',
        countryNameVi: 'Senegal',
        countryNameEn: 'Senegal'
    },
    DKR: {
        id: 2543,
        airportCode: 'DKR',
        cityCode: 'DKR',
        countryCode: 'SN',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Léopold Sédar Senghor',
        airportNameEn: 'Léopold Sédar Senghor International Airport',
        cityNameVi: 'Dakar',
        cityNameEn: 'Dakar',
        countryNameVi: 'Senegal',
        countryNameEn: 'Senegal'
    },
    YNB: {
        id: 2542,
        airportCode: 'YNB',
        cityCode: 'YNB',
        countryCode: 'SA',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Yanbu',
        airportNameEn: 'Yanbu Airport ',
        cityNameVi: 'Yanbo',
        cityNameEn: 'Yanbo',
        countryNameVi: 'Ả rập xê út',
        countryNameEn: 'Saudi Arabia'
    },
    TIF: {
        id: 2541,
        airportCode: 'TIF',
        cityCode: 'TIF',
        countryCode: 'SA',
        continentCode: 'AS',
        airportNameVi: "Sân bay Ta'if",
        airportNameEn: "Ta'if Airport",
        cityNameVi: 'Taif',
        cityNameEn: 'Taif',
        countryNameVi: 'Ả rập xê út',
        countryNameEn: 'Saudi Arabia'
    },
    TUU: {
        id: 2540,
        airportCode: 'TUU',
        cityCode: 'TUU',
        countryCode: 'SA',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Tabuk Regional',
        airportNameEn: 'Tabuk Regional Airport',
        cityNameVi: 'Tabuk',
        cityNameEn: 'Tabuk',
        countryNameVi: 'Ả rập xê út',
        countryNameEn: 'Saudi Arabia'
    },
    AQI: {
        id: 2539,
        airportCode: 'AQI',
        cityCode: 'AQI',
        countryCode: 'SA',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Qaisumah Domestic',
        airportNameEn: 'Qaisumah Domestic Airport',
        cityNameVi: 'Qaisumah',
        cityNameEn: 'Qaisumah',
        countryNameVi: 'Ả rập xê út',
        countryNameEn: 'Saudi Arabia'
    },
    EAM: {
        id: 2538,
        airportCode: 'EAM',
        cityCode: 'EAM',
        countryCode: 'SA',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Najran Domestic',
        airportNameEn: 'Najran Domestic Airport',
        cityNameVi: 'Nejran',
        cityNameEn: 'Nejran',
        countryNameVi: 'Ả rập xê út',
        countryNameEn: 'Saudi Arabia'
    },
    KMC: {
        id: 2537,
        airportCode: 'KMC',
        cityCode: 'KMC',
        countryCode: 'SA',
        continentCode: 'AS',
        airportNameVi: 'Sân bay King Khaled Military City',
        airportNameEn: 'King Khaled Military City Airport',
        cityNameVi: 'King Khalid Military City',
        cityNameEn: 'King Khalid Military City',
        countryNameVi: 'Ả rập xê út',
        countryNameEn: 'Saudi Arabia'
    },
    HAS: {
        id: 2536,
        airportCode: 'HAS',
        cityCode: 'HAS',
        countryCode: 'SA',
        continentCode: 'AS',
        airportNameVi: "Sân bay Ha'il Regional",
        airportNameEn: "Ha'il Regional Airport",
        cityNameVi: 'Hail',
        cityNameEn: 'Hail',
        countryNameVi: 'Ả rập xê út',
        countryNameEn: 'Saudi Arabia'
    },
    ELQ: {
        id: 2535,
        airportCode: 'ELQ',
        cityCode: 'ELQ',
        countryCode: 'SA',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Gassim Regional',
        airportNameEn: 'Gassim Regional Airport',
        cityNameVi: 'Gassim',
        cityNameEn: 'Gassim',
        countryNameVi: 'Ả rập xê út',
        countryNameEn: 'Saudi Arabia'
    },
    ABT: {
        id: 2534,
        airportCode: 'ABT',
        cityCode: 'ABT',
        countryCode: 'SA',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Al-Baha Domestic',
        airportNameEn: 'Al-Baha Domestic Airport',
        cityNameVi: 'Al Baha',
        cityNameEn: 'Al Baha',
        countryNameVi: 'Ả rập xê út',
        countryNameEn: 'Saudi Arabia'
    },
    HOF: {
        id: 2533,
        airportCode: 'HOF',
        cityCode: 'HOF',
        countryCode: 'SA',
        continentCode: 'AS',
        airportNameVi: 'Sân bay al-Ahsa',
        airportNameEn: 'al-Ahsa Airport ',
        cityNameVi: 'Alahsa',
        cityNameEn: 'Alahsa',
        countryNameVi: 'Ả rập xê út',
        countryNameEn: 'Saudi Arabia'
    },
    AHB: {
        id: 2532,
        airportCode: 'AHB',
        cityCode: 'AHB',
        countryCode: 'SA',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Abha Regional',
        airportNameEn: 'Abha Regional Airport',
        cityNameVi: 'Abha',
        cityNameEn: 'Abha',
        countryNameVi: 'Ả rập xê út',
        countryNameEn: 'Saudi Arabia'
    },
    RUH: {
        id: 2531,
        airportCode: 'RUH',
        cityCode: 'RUH',
        countryCode: 'SA',
        continentCode: 'AS',
        airportNameVi: 'Sân bay King Khalid',
        airportNameEn: 'King Khalid International Airport',
        cityNameVi: 'Riyadh',
        cityNameEn: 'Riyadh',
        countryNameVi: 'Ả rập xê út',
        countryNameEn: 'Saudi Arabia'
    },
    MED: {
        id: 2530,
        airportCode: 'MED',
        cityCode: 'MED',
        countryCode: 'SA',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Prince Mohammad Bin Abdulaziz',
        airportNameEn: 'Prince Mohammad Bin Abdulaziz Airport',
        cityNameVi: 'Madinah',
        cityNameEn: 'Madinah',
        countryNameVi: 'Ả rập xê út',
        countryNameEn: 'Saudi Arabia'
    },
    JED: {
        id: 2529,
        airportCode: 'JED',
        cityCode: 'JED',
        countryCode: 'SA',
        continentCode: 'AS',
        airportNameVi: 'Sân bay King Abdulaziz',
        airportNameEn: 'King Abdulaziz International Airport',
        cityNameVi: 'Jeddah',
        cityNameEn: 'Jeddah',
        countryNameVi: 'Ả rập xê út',
        countryNameEn: 'Saudi Arabia'
    },
    GIZ: {
        id: 2528,
        airportCode: 'GIZ',
        cityCode: 'GIZ',
        countryCode: 'SA',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Prince Abdullah Bin Abdulaziz',
        airportNameEn: 'Prince Abdullah Bin Abdulaziz Airport ',
        cityNameVi: 'Gizan',
        cityNameEn: 'Gizan',
        countryNameVi: 'Ả rập xê út',
        countryNameEn: 'Saudi Arabia'
    },
    DHA: {
        id: 2527,
        airportCode: 'DHA',
        cityCode: 'DHA',
        countryCode: 'SA',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Dhahran',
        airportNameEn: 'Dhahran International Airport',
        cityNameVi: 'Dhahran',
        cityNameEn: 'Dhahran',
        countryNameVi: 'Ả rập xê út',
        countryNameEn: 'Saudi Arabia'
    },
    DMM: {
        id: 2526,
        airportCode: 'DMM',
        cityCode: 'DMM',
        countryCode: 'SA',
        continentCode: 'AS',
        airportNameVi: 'Sân bay King Fahd',
        airportNameEn: 'King Fahd International Airport',
        cityNameVi: 'Dammam',
        cityNameEn: 'Dammam',
        countryNameVi: 'Ả rập xê út',
        countryNameEn: 'Saudi Arabia'
    },
    CIW: {
        id: 2525,
        airportCode: 'CIW',
        cityCode: 'CIW',
        countryCode: 'VC',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Canouan Island',
        airportNameEn: 'Canouan Island Airport',
        cityNameVi: 'Canovan Island',
        cityNameEn: 'Canovan Island',
        countryNameVi: 'Saint Vincent Và Grenadines',
        countryNameEn: 'Saint Vincent and the Grenadines'
    },
    SVD: {
        id: 2524,
        airportCode: 'SVD',
        cityCode: 'SVD',
        countryCode: 'VC',
        continentCode: 'SA',
        airportNameVi: 'Sân bay E.T. Joshua',
        airportNameEn: 'E.T. Joshua Airport',
        cityNameVi: 'St Vincent',
        cityNameEn: 'St Vincent',
        countryNameVi: 'Saint Vincent Và Grenadines',
        countryNameEn: 'Saint Vincent and the Grenadines'
    },
    UVF: {
        id: 2523,
        airportCode: 'UVF',
        cityCode: 'SLU',
        countryCode: 'LC',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Hewanorra',
        airportNameEn: 'Hewanorra International Airport',
        cityNameVi: 'St Lucia',
        cityNameEn: 'St Lucia',
        countryNameVi: 'Saint Lucia',
        countryNameEn: 'Saint Lucia'
    },
    CCE: {
        id: 2522,
        airportCode: 'CCE',
        cityCode: 'CCE',
        countryCode: 'LC',
        continentCode: 'SA',
        airportNameVi: "Sân bay L'Espérance",
        airportNameEn: "L'Espérance Airport ",
        cityNameVi: 'Saint Martin',
        cityNameEn: 'Saint Martin',
        countryNameVi: 'Saint Lucia',
        countryNameEn: 'Saint Lucia'
    },
    KGL: {
        id: 2521,
        airportCode: 'KGL',
        cityCode: 'KGL',
        countryCode: 'RW',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Kigali',
        airportNameEn: 'Kigali International Airport ',
        cityNameVi: 'Kigali',
        cityNameEn: 'Kigali',
        countryNameVi: 'Rwanda',
        countryNameEn: 'Rwanda'
    },
    UUS: {
        id: 2520,
        airportCode: 'UUS',
        cityCode: 'UUS',
        countryCode: 'RU',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Yuzhno-Sakhalinsk',
        airportNameEn: 'Yuzhno-Sakhalinsk Airport',
        cityNameVi: 'Yuzhno Sakhalinsk',
        cityNameEn: 'Yuzhno Sakhalinsk',
        countryNameVi: 'Nga',
        countryNameEn: 'Russia'
    },
    VOZ: {
        id: 2519,
        airportCode: 'VOZ',
        cityCode: 'VOZ',
        countryCode: 'RU',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Voronezh',
        airportNameEn: 'Voronezh International Airport',
        cityNameVi: 'Voronezh',
        cityNameEn: 'Voronezh',
        countryNameVi: 'Nga',
        countryNameEn: 'Russia'
    },
    UUD: {
        id: 2518,
        airportCode: 'UUD',
        cityCode: 'UUD',
        countryCode: 'RU',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Ulan-Ude',
        airportNameEn: 'Ulan-Ude Airport',
        cityNameVi: 'Ulan Ude',
        cityNameEn: 'Ulan Ude',
        countryNameVi: 'Nga',
        countryNameEn: 'Russia'
    },
    DYR: {
        id: 2517,
        airportCode: 'DYR',
        cityCode: 'DYR',
        countryCode: 'RU',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Ugolny',
        airportNameEn: 'Ugolny Airport',
        cityNameVi: 'Anadyr',
        cityNameEn: 'Anadyr',
        countryNameVi: 'Nga',
        countryNameEn: 'Russia'
    },
    SCW: {
        id: 2516,
        airportCode: 'SCW',
        cityCode: 'SCW',
        countryCode: 'RU',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Syktyvkar',
        airportNameEn: 'Syktyvkar Airport',
        cityNameVi: 'Syktyvkar',
        cityNameEn: 'Syktyvkar',
        countryNameVi: 'Nga',
        countryNameEn: 'Russia'
    },
    SGC: {
        id: 2515,
        airportCode: 'SGC',
        cityCode: 'SGC',
        countryCode: 'RU',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Surgut',
        airportNameEn: 'Surgut Airport',
        cityNameVi: 'Surgut',
        cityNameEn: 'Surgut',
        countryNameVi: 'Nga',
        countryNameEn: 'Russia'
    },
    RTW: {
        id: 2514,
        airportCode: 'RTW',
        cityCode: 'RTW',
        countryCode: 'RU',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Saratov Tsentralny',
        airportNameEn: 'Saratov Tsentralny Airport',
        cityNameVi: 'Saratov',
        cityNameEn: 'Saratov',
        countryNameVi: 'Nga',
        countryNameEn: 'Russia'
    },
    RVH: {
        id: 2513,
        airportCode: 'RVH',
        cityCode: 'RVH',
        countryCode: 'RU',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Rzhevka',
        airportNameEn: 'Rzhevka Airport',
        cityNameVi: 'St Petersburg',
        cityNameEn: 'St Petersburg',
        countryNameVi: 'Nga',
        countryNameEn: 'Russia'
    },
    PEZ: {
        id: 2512,
        airportCode: 'PEZ',
        cityCode: 'PEZ',
        countryCode: 'RU',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Penza',
        airportNameEn: 'Penza Airport',
        cityNameVi: 'Penza',
        cityNameEn: 'Penza',
        countryNameVi: 'Nga',
        countryNameEn: 'Russia'
    },
    NOZ: {
        id: 2511,
        airportCode: 'NOZ',
        cityCode: 'NOZ',
        countryCode: 'RU',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Novokuznetsk Spichenkovo',
        airportNameEn: 'Novokuznetsk Spichenkovo Airport ',
        cityNameVi: 'Novokuznetsk',
        cityNameEn: 'Novokuznetsk',
        countryNameVi: 'Nga',
        countryNameEn: 'Russia'
    },
    NAL: {
        id: 2510,
        airportCode: 'NAL',
        cityCode: 'NAL',
        countryCode: 'RU',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Nalchik',
        airportNameEn: 'Nalchik Airport',
        cityNameVi: 'Nalchik',
        cityNameEn: 'Nalchik',
        countryNameVi: 'Nga',
        countryNameEn: 'Russia'
    },
    MQF: {
        id: 2509,
        airportCode: 'MQF',
        cityCode: 'MQF',
        countryCode: 'RU',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Magnitogorsk',
        airportNameEn: 'Magnitogorsk Airport',
        cityNameVi: 'Chelyabinsk',
        cityNameEn: 'Chelyabinsk',
        countryNameVi: 'Nga',
        countryNameEn: 'Russia'
    },
    URS: {
        id: 2508,
        airportCode: 'URS',
        cityCode: 'URS',
        countryCode: 'RU',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Kursk Vostochny',
        airportNameEn: 'Kursk Vostochny Airport',
        cityNameVi: 'Kursk',
        cityNameEn: 'Kursk',
        countryNameVi: 'Nga',
        countryNameEn: 'Russia'
    },
    KEJ: {
        id: 2507,
        airportCode: 'KEJ',
        cityCode: 'KEJ',
        countryCode: 'RU',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Kemerovo',
        airportNameEn: 'Kemerovo Airport',
        cityNameVi: 'Kemerovo',
        cityNameEn: 'Kemerovo',
        countryNameVi: 'Nga',
        countryNameEn: 'Russia'
    },
    HTA: {
        id: 2506,
        airportCode: 'HTA',
        cityCode: 'HTA',
        countryCode: 'RU',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Kadala',
        airportNameEn: 'Kadala Airport',
        cityNameVi: 'Chita',
        cityNameEn: 'Chita',
        countryNameVi: 'Nga',
        countryNameEn: 'Russia'
    },
    OJC: {
        id: 2505,
        airportCode: 'OJC',
        cityCode: 'KCK',
        countryCode: 'RU',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Johnson County Executive',
        airportNameEn: 'Johnson County Executive Airport',
        cityNameVi: 'Kansas City',
        cityNameEn: 'Kansas City',
        countryNameVi: 'Nga',
        countryNameEn: 'Russia'
    },
    BQS: {
        id: 2504,
        airportCode: 'BQS',
        cityCode: 'BQS',
        countryCode: 'RU',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Ignatyevo',
        airportNameEn: 'Ignatyevo Airport',
        cityNameVi: 'Blagoveschensk',
        cityNameEn: 'Blagoveschensk',
        countryNameVi: 'Nga',
        countryNameEn: 'Russia'
    },
    CSY: {
        id: 2503,
        airportCode: 'CSY',
        cityCode: 'CSY',
        countryCode: 'RU',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Cheboksary',
        airportNameEn: 'Cheboksary Airport',
        cityNameVi: 'Cheboksary',
        cityNameEn: 'Cheboksary',
        countryNameVi: 'Nga',
        countryNameEn: 'Russia'
    },
    BTK: {
        id: 2502,
        airportCode: 'BTK',
        cityCode: 'BTK',
        countryCode: 'RU',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Bratsk',
        airportNameEn: 'Bratsk Airport',
        cityNameVi: 'Bratsk',
        cityNameEn: 'Bratsk',
        countryNameVi: 'Nga',
        countryNameEn: 'Russia'
    },
    EGO: {
        id: 2501,
        airportCode: 'EGO',
        cityCode: 'EGO',
        countryCode: 'RU',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Belgorod',
        airportNameEn: 'Belgorod Airport',
        cityNameVi: 'Belgorod',
        cityNameEn: 'Belgorod',
        countryNameVi: 'Nga',
        countryNameEn: 'Russia'
    },
    NBC: {
        id: 2500,
        airportCode: 'NBC',
        cityCode: 'NBC',
        countryCode: 'RU',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Begishevo',
        airportNameEn: 'Begishevo Airport',
        cityNameVi: 'Naberevnye Chelny',
        cityNameEn: 'Naberevnye Chelny',
        countryNameVi: 'Nga',
        countryNameEn: 'Russia'
    },
    YKS: {
        id: 2499,
        airportCode: 'YKS',
        cityCode: 'YKS',
        countryCode: 'RU',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Yakutsk',
        airportNameEn: 'Yakutsk Airport',
        cityNameVi: 'Yakutsk',
        cityNameEn: 'Yakutsk',
        countryNameVi: 'Nga',
        countryNameEn: 'Russia'
    },
    LED: {
        id: 2498,
        airportCode: 'LED',
        cityCode: 'LED',
        countryCode: 'RU',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Pulkovo',
        airportNameEn: 'Pulkovo Airport',
        cityNameVi: 'Xanh Pê-téc-bua',
        cityNameEn: 'St Petersburg',
        countryNameVi: 'Nga',
        countryNameEn: 'Russia'
    },
    VOG: {
        id: 2497,
        airportCode: 'VOG',
        cityCode: 'VOG',
        countryCode: 'RU',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Gumrak',
        airportNameEn: 'Gumrak Airport',
        cityNameVi: 'Volgograd',
        cityNameEn: 'Volgograd',
        countryNameVi: 'Nga',
        countryNameEn: 'Russia'
    },
    VVO: {
        id: 2496,
        airportCode: 'VVO',
        cityCode: 'VVO',
        countryCode: 'RU',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Vladivostok',
        airportNameEn: 'Vladivostok International Airport ',
        cityNameVi: 'Vladivostok',
        cityNameEn: 'Vladivostok',
        countryNameVi: 'Nga',
        countryNameEn: 'Russia'
    },
    UFA: {
        id: 2495,
        airportCode: 'UFA',
        cityCode: 'UFA',
        countryCode: 'RU',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Ufa',
        airportNameEn: 'Ufa Airport',
        cityNameVi: 'Ufa',
        cityNameEn: 'Ufa',
        countryNameVi: 'Nga',
        countryNameEn: 'Russia'
    },
    TJM: {
        id: 2494,
        airportCode: 'TJM',
        cityCode: 'TJM',
        countryCode: 'RU',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Roschino',
        airportNameEn: 'Roschino Airport',
        cityNameVi: 'Tyumen',
        cityNameEn: 'Tyumen',
        countryNameVi: 'Nga',
        countryNameEn: 'Russia'
    },
    TOF: {
        id: 2493,
        airportCode: 'TOF',
        cityCode: 'TOF',
        countryCode: 'RU',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Bogashevo',
        airportNameEn: 'Bogashevo Airport',
        cityNameVi: 'Tomsk',
        cityNameEn: 'Tomsk',
        countryNameVi: 'Nga',
        countryNameEn: 'Russia'
    },
    STW: {
        id: 2492,
        airportCode: 'STW',
        cityCode: 'STW',
        countryCode: 'RU',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Stavropol Shpakovskoye',
        airportNameEn: 'Stavropol Shpakovskoye Airport',
        cityNameVi: 'Stavropol',
        cityNameEn: 'Stavropol',
        countryNameVi: 'Nga',
        countryNameEn: 'Russia'
    },
    KUF: {
        id: 2491,
        airportCode: 'KUF',
        cityCode: 'KUF',
        countryCode: 'RU',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Kurumoch',
        airportNameEn: 'Kurumoch Airport ',
        cityNameVi: 'Samara',
        cityNameEn: 'Samara',
        countryNameVi: 'Nga',
        countryNameEn: 'Russia'
    },
    ROV: {
        id: 2490,
        airportCode: 'ROV',
        cityCode: 'ROV',
        countryCode: 'RU',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Rostov-on-Don',
        airportNameEn: 'Rostov-on-Don Airport',
        cityNameVi: 'Rostov',
        cityNameEn: 'Rostov',
        countryNameVi: 'Nga',
        countryNameEn: 'Russia'
    },
    PKC: {
        id: 2489,
        airportCode: 'PKC',
        cityCode: 'PKC',
        countryCode: 'RU',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Yelizovo',
        airportNameEn: 'Yelizovo Airport',
        cityNameVi: 'Petropavlovsk-Kamchats',
        cityNameEn: 'Petropavlovsk-Kamchats',
        countryNameVi: 'Nga',
        countryNameEn: 'Russia'
    },
    PEE: {
        id: 2488,
        airportCode: 'PEE',
        cityCode: 'PEE',
        countryCode: 'RU',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Bolshoye Savino',
        airportNameEn: 'Bolshoye Savino Airport',
        cityNameVi: 'Perm',
        cityNameEn: 'Perm',
        countryNameVi: 'Nga',
        countryNameEn: 'Russia'
    },
    OMS: {
        id: 2487,
        airportCode: 'OMS',
        cityCode: 'OMS',
        countryCode: 'RU',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Omsk',
        airportNameEn: 'Omsk Airport',
        cityNameVi: 'Omsk',
        cityNameEn: 'Omsk',
        countryNameVi: 'Nga',
        countryNameEn: 'Russia'
    },
    OVB: {
        id: 2486,
        airportCode: 'OVB',
        cityCode: 'OVB',
        countryCode: 'RU',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Novosibirsk Tolmachevo',
        airportNameEn: 'Novosibirsk Tolmachevo Airport',
        cityNameVi: 'Novosibirsk',
        cityNameEn: 'Novosibirsk',
        countryNameVi: 'Nga',
        countryNameEn: 'Russia'
    },
    GOJ: {
        id: 2485,
        airportCode: 'GOJ',
        cityCode: 'GOJ',
        countryCode: 'RU',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Strigino',
        airportNameEn: 'Strigino Airport',
        cityNameVi: 'Nizhniy Novgorod',
        cityNameEn: 'Nizhniy Novgorod',
        countryNameVi: 'Nga',
        countryNameEn: 'Russia'
    },
    NJC: {
        id: 2484,
        airportCode: 'NJC',
        cityCode: 'NJC',
        countryCode: 'RU',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Nizhnevartovsk',
        airportNameEn: 'Nizhnevartovsk Airport',
        cityNameVi: 'Nizhnevartovsk',
        cityNameEn: 'Nizhnevartovsk',
        countryNameVi: 'Nga',
        countryNameEn: 'Russia'
    },
    MMK: {
        id: 2483,
        airportCode: 'MMK',
        cityCode: 'MMK',
        countryCode: 'RU',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Murmansk',
        airportNameEn: 'Murmansk Airport',
        cityNameVi: 'Murmansk',
        cityNameEn: 'Murmansk',
        countryNameVi: 'Nga',
        countryNameEn: 'Russia'
    },
    MOW: {
        id: 2482,
        airportCode: 'MOW',
        cityCode: 'MOW',
        countryCode: 'RU',
        continentCode: 'EU',
        airportNameVi: 'Tất cả các sân bay',
        airportNameEn: 'All Airports',
        cityNameVi: 'Moscow',
        cityNameEn: 'Moscow',
        countryNameVi: 'Nga',
        countryNameEn: 'Russia'
    },
    MRV: {
        id: 2481,
        airportCode: 'MRV',
        cityCode: 'MRV',
        countryCode: 'RU',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Mineralnye Vody',
        airportNameEn: 'Mineralnye Vody Airport',
        cityNameVi: 'Mineralnye Vody',
        cityNameEn: 'Mineralnye Vody',
        countryNameVi: 'Nga',
        countryNameEn: 'Russia'
    },
    VKO: {
        id: 2480,
        airportCode: 'VKO',
        cityCode: 'MOW',
        countryCode: 'RU',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Vnukovo',
        airportNameEn: 'Vnukovo International Airport',
        cityNameVi: 'Mát-xờ-cơ-va',
        cityNameEn: 'Moscow',
        countryNameVi: 'Nga',
        countryNameEn: 'Russia'
    },
    SVO: {
        id: 2479,
        airportCode: 'SVO',
        cityCode: 'MOW',
        countryCode: 'RU',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Sheremetyevo',
        airportNameEn: 'Sheremetyevo International Airport',
        cityNameVi: 'Mát-xờ-cơ-va',
        cityNameEn: 'Moscow',
        countryNameVi: 'Nga',
        countryNameEn: 'Russia'
    },
    DME: {
        id: 2478,
        airportCode: 'DME',
        cityCode: 'MOW',
        countryCode: 'RU',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Domodedovo',
        airportNameEn: 'Domodedovo International Airport',
        cityNameVi: 'Mát-xờ-cơ-va',
        cityNameEn: 'Moscow',
        countryNameVi: 'Nga',
        countryNameEn: 'Russia'
    },
    BKA: {
        id: 2477,
        airportCode: 'BKA',
        cityCode: 'MOW',
        countryCode: 'RU',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Bykovo',
        airportNameEn: 'Bykovo Airport',
        cityNameVi: 'Mát-xờ-cơ-va',
        cityNameEn: 'Moscow',
        countryNameVi: 'Nga',
        countryNameEn: 'Russia'
    },
    GDX: {
        id: 2476,
        airportCode: 'GDX',
        cityCode: 'GDX',
        countryCode: 'RU',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Sokol',
        airportNameEn: 'Sokol Airport',
        cityNameVi: 'Magadan',
        cityNameEn: 'Magadan',
        countryNameVi: 'Nga',
        countryNameEn: 'Russia'
    },
    KJA: {
        id: 2475,
        airportCode: 'KJA',
        cityCode: 'KJA',
        countryCode: 'RU',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Krasnoyarsk Yemelyanovo',
        airportNameEn: 'Krasnoyarsk Yemelyanovo Airport',
        cityNameVi: 'Krasnoyarsk',
        cityNameEn: 'Krasnoyarsk',
        countryNameVi: 'Nga',
        countryNameEn: 'Russia'
    },
    KRR: {
        id: 2474,
        airportCode: 'KRR',
        cityCode: 'KRR',
        countryCode: 'RU',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Pashkovsky',
        airportNameEn: 'Pashkovsky Airport',
        cityNameVi: 'Krasnodar',
        cityNameEn: 'Krasnodar',
        countryNameVi: 'Nga',
        countryNameEn: 'Russia'
    },
    KHV: {
        id: 2473,
        airportCode: 'KHV',
        cityCode: 'KHV',
        countryCode: 'RU',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Khabarovsk Novy',
        airportNameEn: 'Khabarovsk Novy Airport',
        cityNameVi: 'Khabarovsk',
        cityNameEn: 'Khabarovsk',
        countryNameVi: 'Nga',
        countryNameEn: 'Russia'
    },
    KZN: {
        id: 2472,
        airportCode: 'KZN',
        cityCode: 'KZN',
        countryCode: 'RU',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Kazan',
        airportNameEn: 'Kazan International Airport',
        cityNameVi: 'Kazan',
        cityNameEn: 'Kazan',
        countryNameVi: 'Nga',
        countryNameEn: 'Russia'
    },
    KGD: {
        id: 2471,
        airportCode: 'KGD',
        cityCode: 'KGD',
        countryCode: 'RU',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Khrabrovo',
        airportNameEn: 'Khrabrovo Airport',
        cityNameVi: 'Kaliningrad',
        cityNameEn: 'Kaliningrad',
        countryNameVi: 'Nga',
        countryNameEn: 'Russia'
    },
    IKT: {
        id: 2470,
        airportCode: 'IKT',
        cityCode: 'IKT',
        countryCode: 'RU',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Irkutsk',
        airportNameEn: 'Irkutsk International Airport',
        cityNameVi: 'Irkutsk',
        cityNameEn: 'Irkutsk',
        countryNameVi: 'Nga',
        countryNameEn: 'Russia'
    },
    SVX: {
        id: 2469,
        airportCode: 'SVX',
        cityCode: 'SVX',
        countryCode: 'RU',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Koltsovo',
        airportNameEn: 'Koltsovo International Airport',
        cityNameVi: 'Ekaterinburg',
        cityNameEn: 'Ekaterinburg',
        countryNameVi: 'Nga',
        countryNameEn: 'Russia'
    },
    CEK: {
        id: 2468,
        airportCode: 'CEK',
        cityCode: 'CEK',
        countryCode: 'RU',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Chelyabinsk Balandino',
        airportNameEn: 'Chelyabinsk Balandino Airport',
        cityNameVi: 'Chelyabinsk',
        cityNameEn: 'Chelyabinsk',
        countryNameVi: 'Nga',
        countryNameEn: 'Russia'
    },
    ASF: {
        id: 2467,
        airportCode: 'ASF',
        cityCode: 'ASF',
        countryCode: 'RU',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Narimanovo',
        airportNameEn: 'Narimanovo Airport',
        cityNameVi: 'Astrakhan',
        cityNameEn: 'Astrakhan',
        countryNameVi: 'Nga',
        countryNameEn: 'Russia'
    },
    ARH: {
        id: 2466,
        airportCode: 'ARH',
        cityCode: 'ARH',
        countryCode: 'RU',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Talagi',
        airportNameEn: 'Talagi Airport',
        cityNameVi: 'Arkhangelsk',
        cityNameEn: 'Arkhangelsk',
        countryNameVi: 'Nga',
        countryNameEn: 'Russia'
    },
    AAQ: {
        id: 2465,
        airportCode: 'AAQ',
        cityCode: 'AAQ',
        countryCode: 'RU',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Anapa',
        airportNameEn: 'Anapa Airport',
        cityNameVi: 'Anapa',
        cityNameEn: 'Anapa',
        countryNameVi: 'Nga',
        countryNameEn: 'Russia'
    },
    AER: {
        id: 2464,
        airportCode: 'AER',
        cityCode: 'AER',
        countryCode: 'RU',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Adler-Sochi',
        airportNameEn: 'Adler-Sochi International Airport',
        cityNameVi: 'Adler Sochi',
        cityNameEn: 'Adler Sochi',
        countryNameVi: 'Nga',
        countryNameEn: 'Russia'
    },
    ABA: {
        id: 2463,
        airportCode: 'ABA',
        cityCode: 'ABA',
        countryCode: 'RU',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Abakan',
        airportNameEn: 'Abakan Airport',
        cityNameVi: 'Abakan',
        cityNameEn: 'Abakan',
        countryNameVi: 'Nga',
        countryNameEn: 'Russia'
    },
    TSR: {
        id: 2462,
        airportCode: 'TSR',
        cityCode: 'TSR',
        countryCode: 'RO',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Traian Vuia',
        airportNameEn: 'Traian Vuia International Airport',
        cityNameVi: 'Timisoara',
        cityNameEn: 'Timisoara',
        countryNameVi: 'Rumani',
        countryNameEn: 'Romania'
    },
    TGM: {
        id: 2461,
        airportCode: 'TGM',
        cityCode: 'TGM',
        countryCode: 'RO',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Târgu Mures',
        airportNameEn: 'Târgu Mureş International Airport',
        cityNameVi: 'Targu Mures',
        cityNameEn: 'Targu Mures',
        countryNameVi: 'Rumani',
        countryNameEn: 'Romania'
    },
    SCV: {
        id: 2460,
        airportCode: 'SCV',
        cityCode: 'SCV',
        countryCode: 'RO',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Suceava',
        airportNameEn: 'Suceava Airport',
        cityNameVi: 'Suceava',
        cityNameEn: 'Suceava',
        countryNameVi: 'Rumani',
        countryNameEn: 'Romania'
    },
    SBZ: {
        id: 2459,
        airportCode: 'SBZ',
        cityCode: 'SBZ',
        countryCode: 'RO',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Sibiu',
        airportNameEn: 'Sibiu International Airport',
        cityNameVi: 'Sibiu',
        cityNameEn: 'Sibiu',
        countryNameVi: 'Rumani',
        countryNameEn: 'Romania'
    },
    SUJ: {
        id: 2458,
        airportCode: 'SUJ',
        cityCode: 'SUJ',
        countryCode: 'RO',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Satu Mare',
        airportNameEn: 'Satu Mare International Airport',
        cityNameVi: 'Satu Mare',
        cityNameEn: 'Satu Mare',
        countryNameVi: 'Rumani',
        countryNameEn: 'Romania'
    },
    OMR: {
        id: 2457,
        airportCode: 'OMR',
        cityCode: 'OMR',
        countryCode: 'RO',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Oradea',
        airportNameEn: 'Oradea International Airport',
        cityNameVi: 'Oradea',
        cityNameEn: 'Oradea',
        countryNameVi: 'Rumani',
        countryNameEn: 'Romania'
    },
    IAS: {
        id: 2456,
        airportCode: 'IAS',
        cityCode: 'IAS',
        countryCode: 'RO',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Ia?i',
        airportNameEn: 'Iași International Airport',
        cityNameVi: 'lasi',
        cityNameEn: 'Iasi',
        countryNameVi: 'Rumani',
        countryNameEn: 'Romania'
    },
    CRA: {
        id: 2455,
        airportCode: 'CRA',
        cityCode: 'CRA',
        countryCode: 'RO',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Craiova',
        airportNameEn: 'Craiova Airport',
        cityNameVi: 'Craiova',
        cityNameEn: 'Craiova',
        countryNameVi: 'Rumani',
        countryNameEn: 'Romania'
    },
    CND: {
        id: 2454,
        airportCode: 'CND',
        cityCode: 'CND',
        countryCode: 'RO',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Mihail Kogalniceanu',
        airportNameEn: 'Mihail Kogalniceanu International Airport',
        cityNameVi: 'Constanta',
        cityNameEn: 'Constanta',
        countryNameVi: 'Rumani',
        countryNameEn: 'Romania'
    },
    CLJ: {
        id: 2453,
        airportCode: 'CLJ',
        cityCode: 'CLJ',
        countryCode: 'RO',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Cluj-Napoca',
        airportNameEn: 'Cluj-Napoca International Airport',
        cityNameVi: 'Cluj-Napoca',
        cityNameEn: 'Cluj-Napoca',
        countryNameVi: 'Rumani',
        countryNameEn: 'Romania'
    },
    OTP: {
        id: 2452,
        airportCode: 'OTP',
        cityCode: 'BUH',
        countryCode: 'RO',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Henri Coanda',
        airportNameEn: 'Henri Coandă International Airport',
        cityNameVi: 'Bu-cha-rét',
        cityNameEn: 'Bucharest',
        countryNameVi: 'Rumani',
        countryNameEn: 'Romania'
    },
    BBU: {
        id: 2451,
        airportCode: 'BBU',
        cityCode: 'BUH',
        countryCode: 'RO',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Bucharest "Aurel Vlaicu"',
        airportNameEn: 'Bucharest "Aurel Vlaicu" Airport ',
        cityNameVi: 'Bu-cha-rét',
        cityNameEn: 'Bucharest',
        countryNameVi: 'Rumani',
        countryNameEn: 'Romania'
    },
    BUH: {
        id: 2450,
        airportCode: 'BUH',
        cityCode: 'BUH',
        countryCode: 'RO',
        continentCode: 'EU',
        airportNameVi: 'Tất cả các sân bay',
        airportNameEn: 'All Airports',
        cityNameVi: 'Bucharest',
        cityNameEn: 'Bucharest',
        countryNameVi: 'Rumani',
        countryNameEn: 'Romania'
    },
    BAY: {
        id: 2449,
        airportCode: 'BAY',
        cityCode: 'BAY',
        countryCode: 'RO',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Baia Mare',
        airportNameEn: 'Baia Mare Airport ',
        cityNameVi: 'Baia Mare',
        cityNameEn: 'Baia Mare',
        countryNameVi: 'Rumani',
        countryNameEn: 'Romania'
    },
    BCM: {
        id: 2448,
        airportCode: 'BCM',
        cityCode: 'BCM',
        countryCode: 'RO',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Bacau',
        airportNameEn: 'Bacău International Airport',
        cityNameVi: 'Bacau',
        cityNameEn: 'Bacau',
        countryNameVi: 'Rumani',
        countryNameEn: 'Romania'
    },
    ZSE: {
        id: 2447,
        airportCode: 'ZSE',
        cityCode: 'ZSE',
        countryCode: 'RE',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Pierrefonds',
        airportNameEn: 'Pierrefonds Airport',
        cityNameVi: 'St Pierre Dela Reunion',
        cityNameEn: 'St Pierre Dela Reunion',
        countryNameVi: 'Reunion',
        countryNameEn: 'Reunion'
    },
    RUN: {
        id: 2446,
        airportCode: 'RUN',
        cityCode: 'RUN',
        countryCode: 'RE',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Roland Garros',
        airportNameEn: 'Roland Garros Airport',
        cityNameVi: 'Reunion Island',
        cityNameEn: 'Reunion Island',
        countryNameVi: 'Reunion',
        countryNameEn: 'Reunion'
    },
    WAT: {
        id: 2445,
        airportCode: 'WAT',
        cityCode: 'WAT',
        countryCode: 'IE',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Waterford',
        airportNameEn: 'Waterford Airport',
        cityNameVi: 'Waterford',
        cityNameEn: 'Waterford',
        countryNameVi: 'Cộng hòa Ai len',
        countryNameEn: 'Republic of Ireland'
    },
    SNN: {
        id: 2444,
        airportCode: 'SNN',
        cityCode: 'SNN',
        countryCode: 'IE',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Shannon',
        airportNameEn: 'Shannon International Airport',
        cityNameVi: 'Shannon',
        cityNameEn: 'Shannon',
        countryNameVi: 'Cộng hòa Ai len',
        countryNameEn: 'Republic of Ireland'
    },
    KIR: {
        id: 2443,
        airportCode: 'KIR',
        cityCode: 'KIR',
        countryCode: 'IE',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Kerry',
        airportNameEn: 'Kerry Airport',
        cityNameVi: 'Kerry County',
        cityNameEn: 'Kerry County',
        countryNameVi: 'Cộng hòa Ai len',
        countryNameEn: 'Republic of Ireland'
    },
    DUB: {
        id: 2442,
        airportCode: 'DUB',
        cityCode: 'DUB',
        countryCode: 'IE',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Dublin',
        airportNameEn: 'Dublin Airport',
        cityNameVi: 'Dublin',
        cityNameEn: 'Dublin',
        countryNameVi: 'Cộng hòa Ai len',
        countryNameEn: 'Republic of Ireland'
    },
    CFN: {
        id: 2441,
        airportCode: 'CFN',
        cityCode: 'CFN',
        countryCode: 'IE',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Donegal',
        airportNameEn: 'Donegal Airport',
        cityNameVi: 'Donegal',
        cityNameEn: 'Donegal',
        countryNameVi: 'Cộng hòa Ai len',
        countryNameEn: 'Republic of Ireland'
    },
    ORK: {
        id: 2440,
        airportCode: 'ORK',
        cityCode: 'ORK',
        countryCode: 'IE',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Cork',
        airportNameEn: 'Cork Airport',
        cityNameVi: 'Cork',
        cityNameEn: 'Cork',
        countryNameVi: 'Cộng hòa Ai len',
        countryNameEn: 'Republic of Ireland'
    },
    VXE: {
        id: 2439,
        airportCode: 'VXE',
        cityCode: 'VXE',
        countryCode: 'CV',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Sao Pedro',
        airportNameEn: 'Sao Pedro Airport',
        cityNameVi: 'Sao Vicente',
        cityNameEn: 'Sao Vicente',
        countryNameVi: 'Republic Of Cape Verde',
        countryNameEn: 'Republic Of Cape Verde'
    },
    SFL: {
        id: 2438,
        airportCode: 'SFL',
        cityCode: 'SFL',
        countryCode: 'CV',
        continentCode: 'AF',
        airportNameVi: 'Sân bay São Filipe',
        airportNameEn: 'São Filipe Airport',
        cityNameVi: 'Sao Filipe',
        cityNameEn: 'Sao Filipe',
        countryNameVi: 'Republic Of Cape Verde',
        countryNameEn: 'Republic Of Cape Verde'
    },
    BVC: {
        id: 2437,
        airportCode: 'BVC',
        cityCode: 'BVC',
        countryCode: 'CV',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Rabil',
        airportNameEn: 'Rabil Airport',
        cityNameVi: 'Boa Vista',
        cityNameEn: 'Boa Vista',
        countryNameVi: 'Republic Of Cape Verde',
        countryNameEn: 'Republic Of Cape Verde'
    },
    SNE: {
        id: 2436,
        airportCode: 'SNE',
        cityCode: 'SNE',
        countryCode: 'CV',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Preguiça',
        airportNameEn: 'Preguiça Airport',
        cityNameVi: 'Sao Nicolau',
        cityNameEn: 'Sao Nicolau',
        countryNameVi: 'Republic Of Cape Verde',
        countryNameEn: 'Republic Of Cape Verde'
    },
    SID: {
        id: 2435,
        airportCode: 'SID',
        cityCode: 'SID',
        countryCode: 'CV',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Amilcar Cabral',
        airportNameEn: 'Amilcar Cabral International Airport',
        cityNameVi: 'Sal Island',
        cityNameEn: 'Sal Island',
        countryNameVi: 'Republic Of Cape Verde',
        countryNameEn: 'Republic Of Cape Verde'
    },
    RAI: {
        id: 2434,
        airportCode: 'RAI',
        cityCode: 'RAI',
        countryCode: 'CV',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Praia',
        airportNameEn: 'Praia International Airport',
        cityNameVi: 'Praia',
        cityNameEn: 'Praia',
        countryNameVi: 'Republic Of Cape Verde',
        countryNameEn: 'Republic Of Cape Verde'
    },
    DOH: {
        id: 2433,
        airportCode: 'DOH',
        cityCode: 'DOH',
        countryCode: 'QA',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Doha',
        airportNameEn: 'Doha International Airport',
        cityNameVi: 'Doha',
        cityNameEn: 'Doha',
        countryNameVi: 'Qatar',
        countryNameEn: 'Qatar'
    },
    CPX: {
        id: 2432,
        airportCode: 'CPX',
        cityCode: 'CPX',
        countryCode: 'PR',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Benjamin Rivera Noriega',
        airportNameEn: 'Benjamin Rivera Noriega Airport',
        cityNameVi: 'Culebra',
        cityNameEn: 'Culebra',
        countryNameVi: 'Puerto Rico',
        countryNameEn: 'PUERTO RICO'
    },
    VQS: {
        id: 2431,
        airportCode: 'VQS',
        cityCode: 'VQS',
        countryCode: 'PR',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Antonio Rivera Rodriguez',
        airportNameEn: 'Antonio Rivera Rodriguez Airport',
        cityNameVi: 'Vieques',
        cityNameEn: 'Vieques',
        countryNameVi: 'Puerto Rico',
        countryNameEn: 'PUERTO RICO'
    },
    SJU: {
        id: 2430,
        airportCode: 'SJU',
        cityCode: 'SJU',
        countryCode: 'PR',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Luis Muñoz Marín',
        airportNameEn: 'Luis Muñoz Marín International Airport',
        cityNameVi: 'San Juan',
        cityNameEn: 'San Juan',
        countryNameVi: 'Puerto Rico',
        countryNameEn: 'PUERTO RICO'
    },
    SIG: {
        id: 2429,
        airportCode: 'SIG',
        cityCode: 'SIG',
        countryCode: 'PR',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Fernando Luis Ribas Dominicci',
        airportNameEn: 'Fernando Luis Ribas Dominicci Airport',
        cityNameVi: 'San Juan',
        cityNameEn: 'San Juan',
        countryNameVi: 'Puerto Rico',
        countryNameEn: 'PUERTO RICO'
    },
    PSE: {
        id: 2428,
        airportCode: 'PSE',
        cityCode: 'PSE',
        countryCode: 'PR',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Mercedita',
        airportNameEn: 'Mercedita Airport',
        cityNameVi: 'Ponce',
        cityNameEn: 'Ponce',
        countryNameVi: 'Puerto Rico',
        countryNameEn: 'PUERTO RICO'
    },
    MAZ: {
        id: 2427,
        airportCode: 'MAZ',
        cityCode: 'MAZ',
        countryCode: 'PR',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Eugenio Maria de Hostos',
        airportNameEn: 'Eugenio Maria de Hostos Airport',
        cityNameVi: 'Mayaguez',
        cityNameEn: 'Mayaguez',
        countryNameVi: 'Puerto Rico',
        countryNameEn: 'PUERTO RICO'
    },
    BQN: {
        id: 2426,
        airportCode: 'BQN',
        cityCode: 'BQN',
        countryCode: 'PR',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Rafael Hernández',
        airportNameEn: 'Rafael Hernández Airport',
        cityNameVi: 'Aguadilla',
        cityNameEn: 'Aguadilla',
        countryNameVi: 'Puerto Rico',
        countryNameEn: 'PUERTO RICO'
    },
    PXO: {
        id: 2425,
        airportCode: 'PXO',
        cityCode: 'PXO',
        countryCode: 'PT',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Porto Santo',
        airportNameEn: 'Porto Santo Airport',
        cityNameVi: 'Porto Santo',
        cityNameEn: 'Porto Santo',
        countryNameVi: 'Bồ đào nha',
        countryNameEn: 'Portugal'
    },
    PIX: {
        id: 2424,
        airportCode: 'PIX',
        cityCode: 'PIX',
        countryCode: 'PT',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Pico',
        airportNameEn: 'Pico Airport',
        cityNameVi: 'Pico Island',
        cityNameEn: 'Pico Island',
        countryNameVi: 'Bồ đào nha',
        countryNameEn: 'PORTUGAL'
    },
    GRW: {
        id: 2423,
        airportCode: 'GRW',
        cityCode: 'GRW',
        countryCode: 'PT',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Graciosa Island',
        airportNameEn: 'Graciosa Island Airport',
        cityNameVi: 'Graciosa Island',
        cityNameEn: 'Graciosa Island',
        countryNameVi: 'Bồ đào nha',
        countryNameEn: 'Portugal'
    },
    FLW: {
        id: 2422,
        airportCode: 'FLW',
        cityCode: 'FLW',
        countryCode: 'PT',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Flores',
        airportNameEn: 'Flores Airport',
        cityNameVi: 'Santa Cruz Flores',
        cityNameEn: 'Santa Cruz Flores',
        countryNameVi: 'Bồ đào nha',
        countryNameEn: 'Portugal'
    },
    CVU: {
        id: 2421,
        airportCode: 'CVU',
        cityCode: 'CVU',
        countryCode: 'PT',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Corvo Island',
        airportNameEn: 'Corvo Island Airport',
        cityNameVi: 'Corvo Island',
        cityNameEn: 'Corvo Island',
        countryNameVi: 'Bồ đào nha',
        countryNameEn: 'Portugal'
    },
    TER: {
        id: 2420,
        airportCode: 'TER',
        cityCode: 'TER',
        countryCode: 'PT',
        continentCode: 'EU',
        airportNameVi: 'Lajes Field',
        airportNameEn: 'Lajes Field',
        cityNameVi: 'Terceira',
        cityNameEn: 'Terceira',
        countryNameVi: 'Bồ đào nha',
        countryNameEn: 'Portugal'
    },
    SMA: {
        id: 2419,
        airportCode: 'SMA',
        cityCode: 'SMA',
        countryCode: 'PT',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Santa Maria',
        airportNameEn: 'Santa Maria Airport',
        cityNameVi: 'Santa Maria',
        cityNameEn: 'Santa Maria',
        countryNameVi: 'Bồ đào nha',
        countryNameEn: 'Portugal'
    },
    OPO: {
        id: 2418,
        airportCode: 'OPO',
        cityCode: 'OPO',
        countryCode: 'PT',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Francisco Sá Carneiro',
        airportNameEn: 'Francisco Sá Carneiro Airport',
        cityNameVi: 'Porto',
        cityNameEn: 'Porto',
        countryNameVi: 'Bồ đào nha',
        countryNameEn: 'Portugal'
    },
    PDL: {
        id: 2417,
        airportCode: 'PDL',
        cityCode: 'PDL',
        countryCode: 'PT',
        continentCode: 'EU',
        airportNameVi: 'Sân bay João Paulo II',
        airportNameEn: 'João Paulo II Airport ',
        cityNameVi: 'Ponta Delgada',
        cityNameEn: 'Ponta Delgada',
        countryNameVi: 'Bồ đào nha',
        countryNameEn: 'Portugal'
    },
    FNC: {
        id: 2416,
        airportCode: 'FNC',
        cityCode: 'FNC',
        countryCode: 'PT',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Madeira',
        airportNameEn: 'Madeira Airport',
        cityNameVi: 'Madeira',
        cityNameEn: 'Madeira',
        countryNameVi: 'Bồ đào nha',
        countryNameEn: 'Portugal'
    },
    LIS: {
        id: 2415,
        airportCode: 'LIS',
        cityCode: 'LIS',
        countryCode: 'PT',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Lisbon Portela',
        airportNameEn: 'Lisbon Portela Airport',
        cityNameVi: 'Lisbon',
        cityNameEn: 'Lisbon',
        countryNameVi: 'Bồ đào nha',
        countryNameEn: 'Portugal'
    },
    HOR: {
        id: 2414,
        airportCode: 'HOR',
        cityCode: 'HOR',
        countryCode: 'PT',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Horta',
        airportNameEn: 'Horta Airport',
        cityNameVi: 'Horta',
        cityNameEn: 'Horta',
        countryNameVi: 'Bồ đào nha',
        countryNameEn: 'Portugal'
    },
    FAO: {
        id: 2413,
        airportCode: 'FAO',
        cityCode: 'FAO',
        countryCode: 'PT',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Faro',
        airportNameEn: 'Faro Airport',
        cityNameVi: 'Faro',
        cityNameEn: 'Faro',
        countryNameVi: 'Bồ đào nha',
        countryNameEn: 'Portugal'
    },
    WRO: {
        id: 2412,
        airportCode: 'WRO',
        cityCode: 'WRO',
        countryCode: 'PL',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Copernicus',
        airportNameEn: 'Copernicus Airport Wroclaw',
        cityNameVi: 'Wroclaw',
        cityNameEn: 'Wroclaw',
        countryNameVi: 'Ba Lan',
        countryNameEn: 'Poland'
    },
    WAW: {
        id: 2411,
        airportCode: 'WAW',
        cityCode: 'WAW',
        countryCode: 'PL',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Frederic Chopin',
        airportNameEn: 'Frederic Chopin International Airport',
        cityNameVi: 'Warsaw',
        cityNameEn: 'Warsaw',
        countryNameVi: 'Ba Lan',
        countryNameEn: 'Poland'
    },
    SZZ: {
        id: 2410,
        airportCode: 'SZZ',
        cityCode: 'SZZ',
        countryCode: 'PL',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Szczecin-Goleniów "Solidarnosc"',
        airportNameEn: 'Szczecin-Goleniów "Solidarnosc" Airport',
        cityNameVi: 'Szczecin',
        cityNameEn: 'Szczecin',
        countryNameVi: 'Ba Lan',
        countryNameEn: 'Poland'
    },
    RZE: {
        id: 2409,
        airportCode: 'RZE',
        cityCode: 'RZE',
        countryCode: 'PL',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Rzeszów-Jasionka',
        airportNameEn: 'Rzeszów-Jasionka Airport',
        cityNameVi: 'Rzeszow',
        cityNameEn: 'Rzeszow',
        countryNameVi: 'Ba Lan',
        countryNameEn: 'Poland'
    },
    POZ: {
        id: 2408,
        airportCode: 'POZ',
        cityCode: 'POZ',
        countryCode: 'PL',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Poznan-Lawica',
        airportNameEn: 'Poznań-Ławica Airport',
        cityNameVi: 'Poznan',
        cityNameEn: 'Poznan',
        countryNameVi: 'Ba Lan',
        countryNameEn: 'Poland'
    },
    LCJ: {
        id: 2407,
        airportCode: 'LCJ',
        cityCode: 'LCJ',
        countryCode: 'PL',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Lódz Wladyslaw Reymont',
        airportNameEn: 'Łódź Władysław Reymont Airport',
        cityNameVi: 'Lodz',
        cityNameEn: 'Lodz',
        countryNameVi: 'Ba Lan',
        countryNameEn: 'Poland'
    },
    KRK: {
        id: 2406,
        airportCode: 'KRK',
        cityCode: 'KRK',
        countryCode: 'PL',
        continentCode: 'EU',
        airportNameVi: 'Sân bay John Paul II',
        airportNameEn: 'John Paul II International Airport ',
        cityNameVi: 'Krakow',
        cityNameEn: 'Krakow',
        countryNameVi: 'Ba Lan',
        countryNameEn: 'Poland'
    },
    KTW: {
        id: 2405,
        airportCode: 'KTW',
        cityCode: 'KTW',
        countryCode: 'PL',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Katowice',
        airportNameEn: 'Katowice Airport ',
        cityNameVi: 'Katowice',
        cityNameEn: 'Katowice',
        countryNameVi: 'Ba Lan',
        countryNameEn: 'Poland'
    },
    GDN: {
        id: 2404,
        airportCode: 'GDN',
        cityCode: 'GDN',
        countryCode: 'PL',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Gdansk Lech Walesa',
        airportNameEn: 'Gdansk Lech Walesa Airport ',
        cityNameVi: 'Gdansk',
        cityNameEn: 'Gdansk',
        countryNameVi: 'Ba Lan',
        countryNameEn: 'Poland'
    },
    BZG: {
        id: 2403,
        airportCode: 'BZG',
        cityCode: 'BZG',
        countryCode: 'PL',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Bydgoszcz Ignacy Jan Paderewski',
        airportNameEn: 'Bydgoszcz Ignacy Jan Paderewski Airport',
        cityNameVi: 'Bydgoszcz',
        cityNameEn: 'Bydgoszcz',
        countryNameVi: 'Ba Lan',
        countryNameEn: 'Poland'
    },
    TUG: {
        id: 2402,
        airportCode: 'TUG',
        cityCode: 'TUG',
        countryCode: 'PH',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Tuguegarao',
        airportNameEn: 'Tuguegarao Airport',
        cityNameVi: 'Tuguegarao',
        cityNameEn: 'Tuguegarao',
        countryNameVi: 'Philippines',
        countryNameEn: 'Philippines'
    },
    RXS: {
        id: 2401,
        airportCode: 'RXS',
        cityCode: 'RXS',
        countryCode: 'PH',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Roxas',
        airportNameEn: 'Roxas Airport',
        cityNameVi: 'Roxas City',
        cityNameEn: 'Roxas City',
        countryNameVi: 'Philippines',
        countryNameEn: 'Philippines'
    },
    LAO: {
        id: 2400,
        airportCode: 'LAO',
        cityCode: 'LAO',
        countryCode: 'PH',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Laoag',
        airportNameEn: 'Laoag International Airport',
        cityNameVi: 'Laoag',
        cityNameEn: 'Laoag',
        countryNameVi: 'Philippines',
        countryNameEn: 'Philippines'
    },
    CRK: {
        id: 2399,
        airportCode: 'CRK',
        cityCode: 'CRK',
        countryCode: 'PH',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Clark',
        airportNameEn: 'Clark International Airport',
        cityNameVi: 'Angeles City',
        cityNameEn: 'Angeles City',
        countryNameVi: 'Philippines',
        countryNameEn: 'Philippines'
    },
    TAC: {
        id: 2398,
        airportCode: 'TAC',
        cityCode: 'TAC',
        countryCode: 'PH',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Daniel Z. Romualdez',
        airportNameEn: 'Daniel Z. Romualdez Airport ',
        cityNameVi: 'Tacloban',
        cityNameEn: 'Tacloban',
        countryNameVi: 'Philippines',
        countryNameEn: 'Philippines'
    },
    PPS: {
        id: 2397,
        airportCode: 'PPS',
        cityCode: 'PPS',
        countryCode: 'PH',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Puerto Princesa',
        airportNameEn: 'Puerto Princesa Airport',
        cityNameVi: 'Puerto Princesa',
        cityNameEn: 'Puerto Princesa',
        countryNameVi: 'Philippines',
        countryNameEn: 'Philippines'
    },
    MNL: {
        id: 2396,
        airportCode: 'MNL',
        cityCode: 'MNL',
        countryCode: 'PH',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Ninoy Aquino',
        airportNameEn: 'Ninoy Aquino International Airport / Villamor Airbase',
        cityNameVi: 'Manila',
        cityNameEn: 'Manila',
        countryNameVi: 'Philippines',
        countryNameEn: 'Philippines'
    },
    LGP: {
        id: 2395,
        airportCode: 'LGP',
        cityCode: 'LGP',
        countryCode: 'PH',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Legazpi',
        airportNameEn: 'Legazpi Airport',
        cityNameVi: 'Legazpi',
        cityNameEn: 'Legazpi',
        countryNameVi: 'Philippines',
        countryNameEn: 'Philippines'
    },
    KLO: {
        id: 2394,
        airportCode: 'KLO',
        cityCode: 'KLO',
        countryCode: 'PH',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Kalibo',
        airportNameEn: 'Kalibo Airport',
        cityNameVi: 'Kalibo',
        cityNameEn: 'Kalibo',
        countryNameVi: 'Philippines',
        countryNameEn: 'Philippines'
    },
    ILO: {
        id: 2393,
        airportCode: 'ILO',
        cityCode: 'ILO',
        countryCode: 'PH',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Iloilo',
        airportNameEn: 'Iloilo International Airport',
        cityNameVi: 'Iloilo',
        cityNameEn: 'Iloilo',
        countryNameVi: 'Philippines',
        countryNameEn: 'Philippines'
    },
    GES: {
        id: 2392,
        airportCode: 'GES',
        cityCode: 'GES',
        countryCode: 'PH',
        continentCode: 'AS',
        airportNameVi: 'Sân bay General Santos',
        airportNameEn: 'General Santos International Airport ',
        cityNameVi: 'General Santos',
        cityNameEn: 'General Santos',
        countryNameVi: 'Philippines',
        countryNameEn: 'Philippines'
    },
    DPL: {
        id: 2391,
        airportCode: 'DPL',
        cityCode: 'DPL',
        countryCode: 'PH',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Dipolog',
        airportNameEn: 'Dipolog Airport',
        cityNameVi: 'Dipolog',
        cityNameEn: 'Dipolog',
        countryNameVi: 'Philippines',
        countryNameEn: 'Philippines'
    },
    DVO: {
        id: 2390,
        airportCode: 'DVO',
        cityCode: 'DVO',
        countryCode: 'PH',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Francisco Bangoy',
        airportNameEn: 'Francisco Bangoy International Airport',
        cityNameVi: 'Davao',
        cityNameEn: 'Davao',
        countryNameVi: 'Philippines',
        countryNameEn: 'Philippines'
    },
    CBO: {
        id: 2389,
        airportCode: 'CBO',
        cityCode: 'CBO',
        countryCode: 'PH',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Awang',
        airportNameEn: 'Awang Airport',
        cityNameVi: 'Cotabato',
        cityNameEn: 'Cotabato',
        countryNameVi: 'Philippines',
        countryNameEn: 'Philippines'
    },
    CEB: {
        id: 2388,
        airportCode: 'CEB',
        cityCode: 'CEB',
        countryCode: 'PH',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Mactan-Cebu',
        airportNameEn: 'Mactan-Cebu International Airport',
        cityNameVi: 'Cebu',
        cityNameEn: 'Cebu',
        countryNameVi: 'Philippines',
        countryNameEn: 'Philippines'
    },
    MPH: {
        id: 2387,
        airportCode: 'MPH',
        cityCode: 'MPH',
        countryCode: 'PH',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Godofredo P. Ramos',
        airportNameEn: 'Godofredo P. Ramos Airport ',
        cityNameVi: 'Caticlan',
        cityNameEn: 'Caticlan',
        countryNameVi: 'Philippines',
        countryNameEn: 'Philippines'
    },
    CGY: {
        id: 2386,
        airportCode: 'CGY',
        cityCode: 'CGY',
        countryCode: 'PH',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Laguindingan',
        airportNameEn: 'Laguindingan International Airport',
        cityNameVi: 'Cagayan',
        cityNameEn: 'Cagayan',
        countryNameVi: 'Philippines',
        countryNameEn: 'Philippines'
    },
    BXU: {
        id: 2385,
        airportCode: 'BXU',
        cityCode: 'BXU',
        countryCode: 'PH',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Butuan',
        airportNameEn: 'Butuan Airport',
        cityNameVi: 'Butuan',
        cityNameEn: 'Butuan',
        countryNameVi: 'Philippines',
        countryNameEn: 'Philippines'
    },
    BCD: {
        id: 2384,
        airportCode: 'BCD',
        cityCode: 'BCD',
        countryCode: 'PH',
        continentCode: 'AS',
        airportNameVi: 'Sân bay New Bacolod-Silay',
        airportNameEn: 'New Bacolod-Silay Airport',
        cityNameVi: 'Bacolod',
        cityNameEn: 'Bacolod',
        countryNameVi: 'Philippines',
        countryNameEn: 'Philippines'
    },
    PIU: {
        id: 2383,
        airportCode: 'PIU',
        cityCode: 'PIU',
        countryCode: 'PE',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Piura',
        airportNameEn: 'Piura Airport',
        cityNameVi: 'Piura',
        cityNameEn: 'Piura',
        countryNameVi: 'Peru',
        countryNameEn: 'Peru'
    },
    CIX: {
        id: 2382,
        airportCode: 'CIX',
        cityCode: 'CIX',
        countryCode: 'PE',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Capitán FAP José A. Quiñones Gonzales',
        airportNameEn: 'Capitán FAP José A. Quiñones Gonzales International Airport',
        cityNameVi: 'Chiclayo',
        cityNameEn: 'Chiclayo',
        countryNameVi: 'Peru',
        countryNameEn: 'Peru'
    },
    TBP: {
        id: 2381,
        airportCode: 'TBP',
        cityCode: 'TBP',
        countryCode: 'PE',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Cap. FAP Pedro Canga Rodríguez',
        airportNameEn: 'Cap. FAP Pedro Canga Rodríguez Airport',
        cityNameVi: 'Tumbes',
        cityNameEn: 'Tumbes',
        countryNameVi: 'Peru',
        countryNameEn: 'Peru'
    },
    TRU: {
        id: 2380,
        airportCode: 'TRU',
        cityCode: 'TRU',
        countryCode: 'PE',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Cap. FAP Carlos Martínez de Pinillos',
        airportNameEn: 'Cap. FAP Carlos Martínez de Pinillos International Airport',
        cityNameVi: 'Trujillo',
        cityNameEn: 'Trujillo',
        countryNameVi: 'Peru',
        countryNameEn: 'Peru'
    },
    PEM: {
        id: 2379,
        airportCode: 'PEM',
        cityCode: 'PEM',
        countryCode: 'PE',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Puerto Maldonado',
        airportNameEn: 'Puerto Maldonado International Airport',
        cityNameVi: 'Puerto Maldonado',
        cityNameEn: 'Puerto Maldonado',
        countryNameVi: 'Peru',
        countryNameEn: 'Peru'
    },
    PCL: {
        id: 2378,
        airportCode: 'PCL',
        cityCode: 'PCL',
        countryCode: 'PE',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Captain Rolden',
        airportNameEn: 'Captain Rolden International Airport',
        cityNameVi: 'Pucallpa',
        cityNameEn: 'Pucallpa',
        countryNameVi: 'Peru',
        countryNameEn: 'Peru'
    },
    LIM: {
        id: 2377,
        airportCode: 'LIM',
        cityCode: 'LIM',
        countryCode: 'PE',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Jorge Chávez',
        airportNameEn: 'Jorge Chávez International Airport',
        cityNameVi: 'Lima',
        cityNameEn: 'Lima',
        countryNameVi: 'Peru',
        countryNameEn: 'Peru'
    },
    JUL: {
        id: 2376,
        airportCode: 'JUL',
        cityCode: 'JUL',
        countryCode: 'PE',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Inca Manco Capac',
        airportNameEn: 'Inca Manco Capac Airport',
        cityNameVi: 'Juliaca',
        cityNameEn: 'Juliaca',
        countryNameVi: 'Peru',
        countryNameEn: 'Peru'
    },
    IQT: {
        id: 2375,
        airportCode: 'IQT',
        cityCode: 'IQT',
        countryCode: 'PE',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Crnl. FAP Francisco Secada Vignetta',
        airportNameEn: 'Crnl. FAP Francisco Secada Vignetta International Airport',
        cityNameVi: 'Iquitos',
        cityNameEn: 'Iquitos',
        countryNameVi: 'Peru',
        countryNameEn: 'Peru'
    },
    CUZ: {
        id: 2374,
        airportCode: 'CUZ',
        cityCode: 'CUZ',
        countryCode: 'PE',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Alejandro Velasco Astete',
        airportNameEn: 'Alejandro Velasco Astete International Airport',
        cityNameVi: 'Cuzco',
        cityNameEn: 'Cuzco',
        countryNameVi: 'Peru',
        countryNameEn: 'Peru'
    },
    AQP: {
        id: 2373,
        airportCode: 'AQP',
        cityCode: 'AQP',
        countryCode: 'PE',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Rodriguez Ballon',
        airportNameEn: 'Rodriguez Ballon International Airport',
        cityNameVi: 'Arequipa',
        cityNameEn: 'Arequipa',
        countryNameVi: 'Peru',
        countryNameEn: 'Peru'
    },
    AGT: {
        id: 2372,
        airportCode: 'AGT',
        cityCode: 'AGT',
        countryCode: 'PY',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Guarani',
        airportNameEn: 'Guarani International Airport ',
        cityNameVi: 'Ciudad Del Este',
        cityNameEn: 'Ciudad Del Este',
        countryNameVi: 'Paraguay',
        countryNameEn: 'Paraguay'
    },
    ASU: {
        id: 2371,
        airportCode: 'ASU',
        cityCode: 'ASU',
        countryCode: 'PY',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Silvio Pettirossi',
        airportNameEn: 'Silvio Pettirossi International Airport',
        cityNameVi: 'Asuncion',
        cityNameEn: 'Asuncion',
        countryNameVi: 'Paraguay',
        countryNameEn: 'Paraguay'
    },
    MAS: {
        id: 2370,
        airportCode: 'MAS',
        cityCode: 'MAS',
        countryCode: 'PG',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Momote',
        airportNameEn: 'Momote Airport',
        cityNameVi: 'Manus Island',
        cityNameEn: 'Manus Island',
        countryNameVi: 'Papua niu ghi nê',
        countryNameEn: 'Papua New Guinea (Niugini)'
    },
    MDU: {
        id: 2369,
        airportCode: 'MDU',
        cityCode: 'MDU',
        countryCode: 'PG',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Mendi',
        airportNameEn: 'Mendi Airport',
        cityNameVi: 'Mendi',
        cityNameEn: 'Mendi',
        countryNameVi: 'Papua niu ghi nê',
        countryNameEn: 'Papua New Guinea (Niugini)'
    },
    MAG: {
        id: 2368,
        airportCode: 'MAG',
        cityCode: 'MAG',
        countryCode: 'PG',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Madang',
        airportNameEn: 'Madang Airport',
        cityNameVi: 'Madang',
        cityNameEn: 'Madang',
        countryNameVi: 'Papua niu ghi nê',
        countryNameEn: 'Papua New Guinea (Niugini)'
    },
    LNV: {
        id: 2367,
        airportCode: 'LNV',
        cityCode: 'LNV',
        countryCode: 'PG',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Lihir Island',
        airportNameEn: 'Lihir Island Airport',
        cityNameVi: 'Lihir Island',
        cityNameEn: 'Lihir Island',
        countryNameVi: 'Papua niu ghi nê',
        countryNameEn: 'Papua New Guinea (Niugini)'
    },
    HKN: {
        id: 2366,
        airportCode: 'HKN',
        cityCode: 'HKN',
        countryCode: 'PG',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Kimbe',
        airportNameEn: 'Kimbe Airport',
        cityNameVi: 'Hoskins',
        cityNameEn: 'Hoskins',
        countryNameVi: 'Papua niu ghi nê',
        countryNameEn: 'Papua New Guinea (Niugini)'
    },
    KVG: {
        id: 2365,
        airportCode: 'KVG',
        cityCode: 'KVG',
        countryCode: 'PG',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Kavieng',
        airportNameEn: 'Kavieng Airport',
        cityNameVi: 'Kavieng',
        cityNameEn: 'Kavieng',
        countryNameVi: 'Papua niu ghi nê',
        countryNameEn: 'Papua New Guinea (Niugini)'
    },
    GUR: {
        id: 2364,
        airportCode: 'GUR',
        cityCode: 'GUR',
        countryCode: 'PG',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Gurney',
        airportNameEn: 'Gurney Airport',
        cityNameVi: 'Alotau',
        cityNameEn: 'Alotau',
        countryNameVi: 'Papua niu ghi nê',
        countryNameEn: 'Papua New Guinea (Niugini)'
    },
    RAB: {
        id: 2363,
        airportCode: 'RAB',
        cityCode: 'RAB',
        countryCode: 'PG',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Tokua',
        airportNameEn: 'Tokua Airport',
        cityNameVi: 'Rabaul',
        cityNameEn: 'Rabaul',
        countryNameVi: 'Papua niu ghi nê',
        countryNameEn: 'Papua New Guinea (Niugini)'
    },
    POM: {
        id: 2362,
        airportCode: 'POM',
        cityCode: 'POM',
        countryCode: 'PG',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Jacksons',
        airportNameEn: 'Jacksons International Airport ',
        cityNameVi: 'Port Moresby',
        cityNameEn: 'Port Moresby',
        countryNameVi: 'Papua niu ghi nê',
        countryNameEn: 'Papua New Guinea (Niugini)'
    },
    HGU: {
        id: 2361,
        airportCode: 'HGU',
        cityCode: 'HGU',
        countryCode: 'PG',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Mount Hagen',
        airportNameEn: 'Mount Hagen Airport',
        cityNameVi: 'Mt Hagen',
        cityNameEn: 'Mt Hagen',
        countryNameVi: 'Papua niu ghi nê',
        countryNameEn: 'Papua New Guinea (Niugini)'
    },
    LAE: {
        id: 2360,
        airportCode: 'LAE',
        cityCode: 'LAE',
        countryCode: 'PG',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Lae Nadzab',
        airportNameEn: 'Lae Nadzab Airport',
        cityNameVi: 'Lae Pg',
        cityNameEn: 'Lae Pg',
        countryNameVi: 'Papua niu ghi nê',
        countryNameEn: 'Papua New Guinea (Niugini)'
    },
    GKA: {
        id: 2359,
        airportCode: 'GKA',
        cityCode: 'GKA',
        countryCode: 'PG',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Goroka',
        airportNameEn: 'Goroka Airport',
        cityNameVi: 'Goroka',
        cityNameEn: 'Goroka',
        countryNameVi: 'Papua niu ghi nê',
        countryNameEn: 'Papua New Guinea (Niugini)'
    },
    FKL: {
        id: 2358,
        airportCode: 'FKL',
        cityCode: 'FKL',
        countryCode: 'PA',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Venango Regional',
        airportNameEn: 'Venango Regional Airport ',
        cityNameVi: 'Franklin',
        cityNameEn: 'Franklin',
        countryNameVi: 'Panama',
        countryNameEn: 'Panama'
    },
    DUJ: {
        id: 2357,
        airportCode: 'DUJ',
        cityCode: 'DUJ',
        countryCode: 'PA',
        continentCode: 'SA',
        airportNameVi: 'Sân bay DuBois Regional',
        airportNameEn: 'DuBois Regional Airport ',
        cityNameVi: 'Dubois',
        cityNameEn: 'Dubois',
        countryNameVi: 'Panama',
        countryNameEn: 'Panama'
    },
    PTY: {
        id: 2356,
        airportCode: 'PTY',
        cityCode: 'PTY',
        countryCode: 'PA',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Tocumen',
        airportNameEn: 'Tocumen International Airport',
        cityNameVi: 'Panama City',
        cityNameEn: 'Panama City',
        countryNameVi: 'Panama',
        countryNameEn: 'Panama'
    },
    PAC: {
        id: 2355,
        airportCode: 'PAC',
        cityCode: 'PTY',
        countryCode: 'PA',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Albrook "Marcos A. Gelabert"',
        airportNameEn: 'Albrook "Marcos A. Gelabert" International Airport',
        cityNameVi: 'Panama City',
        cityNameEn: 'Panama City',
        countryNameVi: 'Panama',
        countryNameEn: 'Panama'
    },
    MDT: {
        id: 2354,
        airportCode: 'MDT',
        cityCode: 'HAR',
        countryCode: 'PA',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Harrisburg',
        airportNameEn: 'Harrisburg International Airport',
        cityNameVi: 'Harrisburg',
        cityNameEn: 'Harrisburg',
        countryNameVi: 'Panama',
        countryNameEn: 'Panama'
    },
    BFD: {
        id: 2353,
        airportCode: 'BFD',
        cityCode: 'BFD',
        countryCode: 'PA',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Bradford Regional',
        airportNameEn: 'Bradford Regional Airport',
        cityNameVi: 'Bradford',
        cityNameEn: 'Bradford',
        countryNameVi: 'Panama',
        countryNameEn: 'Panama'
    },
    SKT: {
        id: 2352,
        airportCode: 'SKT',
        cityCode: 'SKT',
        countryCode: 'PK',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Sialkot',
        airportNameEn: 'Sialkot International Airport',
        cityNameVi: 'Sialkot',
        cityNameEn: 'Sialkot',
        countryNameVi: 'Pakistan',
        countryNameEn: 'Pakistan'
    },
    MJD: {
        id: 2351,
        airportCode: 'MJD',
        cityCode: 'MJD',
        countryCode: 'PK',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Moenjodaro',
        airportNameEn: 'Moenjodaro Airport',
        cityNameVi: 'Mohenjodaro',
        cityNameEn: 'Mohenjodaro',
        countryNameVi: 'Pakistan',
        countryNameEn: 'Pakistan'
    },
    PZH: {
        id: 2350,
        airportCode: 'PZH',
        cityCode: 'PZH',
        countryCode: 'PK',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Zhob',
        airportNameEn: 'Zhob Airport',
        cityNameVi: 'Zhob',
        cityNameEn: 'Zhob',
        countryNameVi: 'Pakistan',
        countryNameEn: 'Pakistan'
    },
    PEW: {
        id: 2349,
        airportCode: 'PEW',
        cityCode: 'PEW',
        countryCode: 'PK',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Peshawar',
        airportNameEn: 'Peshawar Airport',
        cityNameVi: 'Peshawar',
        cityNameEn: 'Peshawar',
        countryNameVi: 'Pakistan',
        countryNameEn: 'Pakistan'
    },
    MUX: {
        id: 2348,
        airportCode: 'MUX',
        cityCode: 'MUX',
        countryCode: 'PK',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Multan',
        airportNameEn: 'Multan International Airport',
        cityNameVi: 'Multan',
        cityNameEn: 'Multan',
        countryNameVi: 'Pakistan',
        countryNameEn: 'Pakistan'
    },
    LHE: {
        id: 2347,
        airportCode: 'LHE',
        cityCode: 'LHE',
        countryCode: 'PK',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Allama Iqbal',
        airportNameEn: 'Allama Iqbal International Airport',
        cityNameVi: 'Lahore',
        cityNameEn: 'Lahore',
        countryNameVi: 'Pakistan',
        countryNameEn: 'Pakistan'
    },
    KHI: {
        id: 2346,
        airportCode: 'KHI',
        cityCode: 'KHI',
        countryCode: 'PK',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Jinnah',
        airportNameEn: 'Jinnah International Airport ',
        cityNameVi: 'Karachi',
        cityNameEn: 'Karachi',
        countryNameVi: 'Pakistan',
        countryNameEn: 'Pakistan'
    },
    ISB: {
        id: 2345,
        airportCode: 'ISB',
        cityCode: 'ISB',
        countryCode: 'PK',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Benazir Bhutto',
        airportNameEn: 'Benazir Bhutto International Airport ',
        cityNameVi: 'Islamabad',
        cityNameEn: 'Islamabad',
        countryNameVi: 'Pakistan',
        countryNameEn: 'Pakistan'
    },
    GIL: {
        id: 2344,
        airportCode: 'GIL',
        cityCode: 'GIL',
        countryCode: 'PK',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Gilgit',
        airportNameEn: 'Gilgit Airport',
        cityNameVi: 'Gilgit',
        cityNameEn: 'Gilgit',
        countryNameVi: 'Pakistan',
        countryNameEn: 'Pakistan'
    },
    LYP: {
        id: 2343,
        airportCode: 'LYP',
        cityCode: 'LYP',
        countryCode: 'PK',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Faisalabad',
        airportNameEn: 'Faisalabad International Airport',
        cityNameVi: 'Faisalabad',
        cityNameEn: 'Faisalabad',
        countryNameVi: 'Pakistan',
        countryNameEn: 'Pakistan'
    },
    SLL: {
        id: 2342,
        airportCode: 'SLL',
        cityCode: 'SLL',
        countryCode: 'OM',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Salalah',
        airportNameEn: 'Salalah Airport',
        cityNameVi: 'Salalah',
        cityNameEn: 'Salalah',
        countryNameVi: 'Oman',
        countryNameEn: 'Oman'
    },
    MCT: {
        id: 2341,
        airportCode: 'MCT',
        cityCode: 'MCT',
        countryCode: 'OM',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Muscat',
        airportNameEn: 'Muscat International Airport ',
        cityNameVi: 'Muscat',
        cityNameEn: 'Muscat',
        countryNameVi: 'Oman',
        countryNameEn: 'Oman'
    },
    VAW: {
        id: 2340,
        airportCode: 'VAW',
        cityCode: 'VAW',
        countryCode: 'NO',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Vardø',
        airportNameEn: 'Vardø Airport, Svartnes',
        cityNameVi: 'Vardoe',
        cityNameEn: 'Vardoe',
        countryNameVi: 'Na Uy',
        countryNameEn: 'Norway'
    },
    LYR: {
        id: 2339,
        airportCode: 'LYR',
        cityCode: 'LYR',
        countryCode: 'NO',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Svalbard',
        airportNameEn: 'Svalbard Airport, Longyear',
        cityNameVi: 'Longyearbyen',
        cityNameEn: 'Longyearbyen',
        countryNameVi: 'Na Uy',
        countryNameEn: 'Norway'
    },
    SRP: {
        id: 2338,
        airportCode: 'SRP',
        cityCode: 'SRP',
        countryCode: 'NO',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Stord',
        airportNameEn: 'Stord Airport, Sørstokken',
        cityNameVi: 'Stord',
        cityNameEn: 'Stord',
        countryNameVi: 'Na Uy',
        countryNameEn: 'Norway'
    },
    RYG: {
        id: 2337,
        airportCode: 'RYG',
        cityCode: 'RYG',
        countryCode: 'NO',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Moss',
        airportNameEn: 'Moss Airport, Rygge / Rygge Air Station',
        cityNameVi: 'Oslo',
        cityNameEn: 'Oslo',
        countryNameVi: 'Na Uy',
        countryNameEn: 'Norway'
    },
    MJF: {
        id: 2336,
        airportCode: 'MJF',
        cityCode: 'MJF',
        countryCode: 'NO',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Mosjøen',
        airportNameEn: 'Mosjøen Airport, Kjærstad',
        cityNameVi: 'Mosjoen',
        cityNameEn: 'Mosjoen',
        countryNameVi: 'Na Uy',
        countryNameEn: 'Norway'
    },
    FRO: {
        id: 2335,
        airportCode: 'FRO',
        cityCode: 'FRO',
        countryCode: 'NO',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Florø',
        airportNameEn: 'Florø Airport',
        cityNameVi: 'Floro',
        cityNameEn: 'Floro',
        countryNameVi: 'Na Uy',
        countryNameEn: 'Norway'
    },
    BDU: {
        id: 2334,
        airportCode: 'BDU',
        cityCode: 'BDU',
        countryCode: 'NO',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Bardufoss',
        airportNameEn: 'Bardufoss Airport',
        cityNameVi: 'Bardufoss',
        cityNameEn: 'Bardufoss',
        countryNameVi: 'Na Uy',
        countryNameEn: 'Norway'
    },
    VDS: {
        id: 2333,
        airportCode: 'VDS',
        cityCode: 'VDS',
        countryCode: 'NO',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Vadsø',
        airportNameEn: 'Vadsø Airport',
        cityNameVi: 'Vadso',
        cityNameEn: 'Vadso',
        countryNameVi: 'Na Uy',
        countryNameEn: 'Norway'
    },
    TRD: {
        id: 2332,
        airportCode: 'TRD',
        cityCode: 'TRD',
        countryCode: 'NO',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Trondheim',
        airportNameEn: 'Trondheim Airport, Værnes',
        cityNameVi: 'Trondheim',
        cityNameEn: 'Trondheim',
        countryNameVi: 'Na Uy',
        countryNameEn: 'Norway'
    },
    TOS: {
        id: 2331,
        airportCode: 'TOS',
        cityCode: 'TOS',
        countryCode: 'NO',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Tromsø',
        airportNameEn: 'Tromsø Airport',
        cityNameVi: 'Tromso',
        cityNameEn: 'Tromso',
        countryNameVi: 'Na Uy',
        countryNameEn: 'Norway'
    },
    SVJ: {
        id: 2330,
        airportCode: 'SVJ',
        cityCode: 'SVJ',
        countryCode: 'NO',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Svolvær',
        airportNameEn: 'Svolvær Airport, Helle',
        cityNameVi: 'Svolvaer',
        cityNameEn: 'Svolvaer',
        countryNameVi: 'Na Uy',
        countryNameEn: 'Norway'
    },
    SKN: {
        id: 2329,
        airportCode: 'SKN',
        cityCode: 'SKN',
        countryCode: 'NO',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Stokmarknes',
        airportNameEn: 'Stokmarknes Airport, Skagen',
        cityNameVi: 'Storkmarknes',
        cityNameEn: 'Stokmarknes',
        countryNameVi: 'Na Uy',
        countryNameEn: 'Norway'
    },
    SVG: {
        id: 2328,
        airportCode: 'SVG',
        cityCode: 'SVG',
        countryCode: 'NO',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Stavanger',
        airportNameEn: 'Stavanger Airport, Sola',
        cityNameVi: 'Stavanger',
        cityNameEn: 'Stavanger',
        countryNameVi: 'Na Uy',
        countryNameEn: 'Norway'
    },
    SOG: {
        id: 2327,
        airportCode: 'SOG',
        cityCode: 'SOG',
        countryCode: 'NO',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Sogndal',
        airportNameEn: 'Sogndal Airport, Haukåsen',
        cityNameVi: 'Sogndal',
        cityNameEn: 'Sogndal',
        countryNameVi: 'Na Uy',
        countryNameEn: 'Norway'
    },
    SKE: {
        id: 2326,
        airportCode: 'SKE',
        cityCode: 'SKE',
        countryCode: 'NO',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Skien',
        airportNameEn: 'Skien Airport, Geiteryggen',
        cityNameVi: 'Skien',
        cityNameEn: 'Skien',
        countryNameVi: 'Na Uy',
        countryNameEn: 'Norway'
    },
    SSJ: {
        id: 2325,
        airportCode: 'SSJ',
        cityCode: 'SSJ',
        countryCode: 'NO',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Sandnessjøen',
        airportNameEn: 'Sandnessjøen Airport, Stokka',
        cityNameVi: 'Sandnessjoen',
        cityNameEn: 'Sandnessjoen',
        countryNameVi: 'Na Uy',
        countryNameEn: 'Norway'
    },
    SDN: {
        id: 2324,
        airportCode: 'SDN',
        cityCode: 'SDN',
        countryCode: 'NO',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Sandane',
        airportNameEn: 'Sandane Airport, Anda',
        cityNameVi: 'Sandane',
        cityNameEn: 'Sandane',
        countryNameVi: 'Na Uy',
        countryNameEn: 'Norway'
    },
    RRS: {
        id: 2323,
        airportCode: 'RRS',
        cityCode: 'RRS',
        countryCode: 'NO',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Røros',
        airportNameEn: 'Røros Airport',
        cityNameVi: 'Roros',
        cityNameEn: 'Roros',
        countryNameVi: 'Na Uy',
        countryNameEn: 'Norway'
    },
    RVK: {
        id: 2322,
        airportCode: 'RVK',
        cityCode: 'RVK',
        countryCode: 'NO',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Rørvik',
        airportNameEn: 'Rørvik Airport, Ryum',
        cityNameVi: 'Roervik',
        cityNameEn: 'Roervik',
        countryNameVi: 'Na Uy',
        countryNameEn: 'Norway'
    },
    TRF: {
        id: 2321,
        airportCode: 'TRF',
        cityCode: 'OSL',
        countryCode: 'NO',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Sandefjord',
        airportNameEn: 'Sandefjord Airport, Torp',
        cityNameVi: 'Oslo',
        cityNameEn: 'Oslo',
        countryNameVi: 'Na Uy',
        countryNameEn: 'Norway'
    },
    OSL: {
        id: 2320,
        airportCode: 'OSL',
        cityCode: 'OSL',
        countryCode: 'NO',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Oslo',
        airportNameEn: 'Oslo Airport, Gardermoen ',
        cityNameVi: 'Oslo',
        cityNameEn: 'Oslo',
        countryNameVi: 'Na Uy',
        countryNameEn: 'Norway'
    },
    HOV: {
        id: 2319,
        airportCode: 'HOV',
        cityCode: 'HOV',
        countryCode: 'NO',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Ørsta/Volda',
        airportNameEn: 'Ørsta/Volda Airport, Hovden',
        cityNameVi: 'Orsta Volda',
        cityNameEn: 'Orsta Volda',
        countryNameVi: 'Na Uy',
        countryNameEn: 'Norway'
    },
    NVK: {
        id: 2318,
        airportCode: 'NVK',
        cityCode: 'NVK',
        countryCode: 'NO',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Narvik',
        airportNameEn: 'Narvik Airport, Framnes',
        cityNameVi: 'Narvik',
        cityNameEn: 'Narvik',
        countryNameVi: 'Na Uy',
        countryNameEn: 'Norway'
    },
    OSY: {
        id: 2317,
        airportCode: 'OSY',
        cityCode: 'OSY',
        countryCode: 'NO',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Namsos',
        airportNameEn: 'Namsos Airport, Høknesøra',
        cityNameVi: 'Namsos',
        cityNameEn: 'Namsos',
        countryNameVi: 'Na Uy',
        countryNameEn: 'Norway'
    },
    MOL: {
        id: 2316,
        airportCode: 'MOL',
        cityCode: 'MOL',
        countryCode: 'NO',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Molde',
        airportNameEn: 'Molde Airport, Årø',
        cityNameVi: 'Molde',
        cityNameEn: 'Molde',
        countryNameVi: 'Na Uy',
        countryNameEn: 'Norway'
    },
    MQN: {
        id: 2315,
        airportCode: 'MQN',
        cityCode: 'MQN',
        countryCode: 'NO',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Mo i Rana',
        airportNameEn: 'Mo i Rana Airport, Røssvoll',
        cityNameVi: 'Mo I Rana',
        cityNameEn: 'Mo I Rana',
        countryNameVi: 'Na Uy',
        countryNameEn: 'Norway'
    },
    MEH: {
        id: 2314,
        airportCode: 'MEH',
        cityCode: 'MEH',
        countryCode: 'NO',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Mehamn',
        airportNameEn: 'Mehamn Airport',
        cityNameVi: 'Mehamn',
        cityNameEn: 'Mehamn',
        countryNameVi: 'Na Uy',
        countryNameEn: 'Norway'
    },
    LKN: {
        id: 2313,
        airportCode: 'LKN',
        cityCode: 'LKN',
        countryCode: 'NO',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Leknes',
        airportNameEn: 'Leknes Airport',
        cityNameVi: 'Leknes',
        cityNameEn: 'Leknes',
        countryNameVi: 'Na Uy',
        countryNameEn: 'Norway'
    },
    LKL: {
        id: 2312,
        airportCode: 'LKL',
        cityCode: 'LKL',
        countryCode: 'NO',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Lakselv',
        airportNameEn: 'Lakselv Airport, Banak',
        cityNameVi: 'Lakselv',
        cityNameEn: 'Lakselv',
        countryNameVi: 'Na Uy',
        countryNameEn: 'Norway'
    },
    KSU: {
        id: 2311,
        airportCode: 'KSU',
        cityCode: 'KSU',
        countryCode: 'NO',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Kristiansund',
        airportNameEn: 'Kristiansund Airport, Kvernberget',
        cityNameVi: 'Kristiansund',
        cityNameEn: 'Kristiansund',
        countryNameVi: 'Na Uy',
        countryNameEn: 'Norway'
    },
    KRS: {
        id: 2310,
        airportCode: 'KRS',
        cityCode: 'KRS',
        countryCode: 'NO',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Kristiansand',
        airportNameEn: 'Kristiansand Airport, Kjevik',
        cityNameVi: 'Kristiansand',
        cityNameEn: 'Kristiansand',
        countryNameVi: 'Na Uy',
        countryNameEn: 'Norway'
    },
    KKN: {
        id: 2309,
        airportCode: 'KKN',
        cityCode: 'KKN',
        countryCode: 'NO',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Kirkenes',
        airportNameEn: 'Kirkenes Airport, Høybuktmoen',
        cityNameVi: 'Kirkenes',
        cityNameEn: 'Kirkenes',
        countryNameVi: 'Na Uy',
        countryNameEn: 'Norway'
    },
    HVG: {
        id: 2308,
        airportCode: 'HVG',
        cityCode: 'HVG',
        countryCode: 'NO',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Honningsvåg',
        airportNameEn: 'Honningsvåg Airport, Valan',
        cityNameVi: 'Honningsvag',
        cityNameEn: 'Honningsvag',
        countryNameVi: 'Na Uy',
        countryNameEn: 'Norway'
    },
    HAU: {
        id: 2307,
        airportCode: 'HAU',
        cityCode: 'HAU',
        countryCode: 'NO',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Haugesund',
        airportNameEn: 'Haugesund Airport, Karmøy',
        cityNameVi: 'Haugesund',
        cityNameEn: 'Haugesund',
        countryNameVi: 'Na Uy',
        countryNameEn: 'Norway'
    },
    EVE: {
        id: 2306,
        airportCode: 'EVE',
        cityCode: 'EVE',
        countryCode: 'NO',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Harstad/Narvik',
        airportNameEn: 'Harstad/Narvik Airport, Evenes',
        cityNameVi: 'Harstad Narvik',
        cityNameEn: 'Harstad Narvik',
        countryNameVi: 'Na Uy',
        countryNameEn: 'Norway'
    },
    HFT: {
        id: 2305,
        airportCode: 'HFT',
        cityCode: 'HFT',
        countryCode: 'NO',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Hammerfest',
        airportNameEn: 'Hammerfest Airport',
        cityNameVi: 'Hammerfest',
        cityNameEn: 'Hammerfest',
        countryNameVi: 'Na Uy',
        countryNameEn: 'Norway'
    },
    FDE: {
        id: 2304,
        airportCode: 'FDE',
        cityCode: 'FDE',
        countryCode: 'NO',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Førde',
        airportNameEn: 'Førde Airport, Bringeland',
        cityNameVi: 'Forde',
        cityNameEn: 'Forde',
        countryNameVi: 'Na Uy',
        countryNameEn: 'Norway'
    },
    BNN: {
        id: 2303,
        airportCode: 'BNN',
        cityCode: 'BNN',
        countryCode: 'NO',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Brønnøysund',
        airportNameEn: 'Brønnøysund Airport, Brønnøy',
        cityNameVi: 'Bronnoysund',
        cityNameEn: 'Bronnoysund',
        countryNameVi: 'Na Uy',
        countryNameEn: 'Norway'
    },
    BOO: {
        id: 2302,
        airportCode: 'BOO',
        cityCode: 'BOO',
        countryCode: 'NO',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Bodø',
        airportNameEn: 'Bodø Airport',
        cityNameVi: 'Bodo',
        cityNameEn: 'Bodo',
        countryNameVi: 'Na Uy',
        countryNameEn: 'Norway'
    },
    BGO: {
        id: 2301,
        airportCode: 'BGO',
        cityCode: 'BGO',
        countryCode: 'NO',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Bergen Flesland',
        airportNameEn: 'Bergen Flesland Airport',
        cityNameVi: 'Bergen',
        cityNameEn: 'Bergen',
        countryNameVi: 'Na Uy',
        countryNameEn: 'Norway'
    },
    BJF: {
        id: 2300,
        airportCode: 'BJF',
        cityCode: 'BJF',
        countryCode: 'NO',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Båtsfjord',
        airportNameEn: 'Båtsfjord Airport',
        cityNameVi: 'Batsfjord',
        cityNameEn: 'Batsfjord',
        countryNameVi: 'Na Uy',
        countryNameEn: 'Norway'
    },
    ANX: {
        id: 2299,
        airportCode: 'ANX',
        cityCode: 'ANX',
        countryCode: 'NO',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Andøya',
        airportNameEn: 'Andøya Airport, Andenes',
        cityNameVi: 'Andenes',
        cityNameEn: 'Andenes',
        countryNameVi: 'Na Uy',
        countryNameEn: 'Norway'
    },
    ALF: {
        id: 2298,
        airportCode: 'ALF',
        cityCode: 'ALF',
        countryCode: 'NO',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Alta',
        airportNameEn: 'Alta Airport',
        cityNameVi: 'Alta',
        cityNameEn: 'Alta',
        countryNameVi: 'Na Uy',
        countryNameEn: 'Norway'
    },
    AES: {
        id: 2297,
        airportCode: 'AES',
        cityCode: 'AES',
        countryCode: 'NO',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Ålesund',
        airportNameEn: 'Ålesund Airport, Vigra',
        cityNameVi: 'Aalesund',
        cityNameEn: 'Aalesund',
        countryNameVi: 'Na Uy',
        countryNameEn: 'Norway'
    },
    IUE: {
        id: 2296,
        airportCode: 'IUE',
        cityCode: 'IUE',
        countryCode: 'NU',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Hanan',
        airportNameEn: 'Hanan International Airport',
        cityNameVi: 'Niue Island',
        cityNameEn: 'Niue Island',
        countryNameVi: 'Niue',
        countryNameEn: 'Niue'
    },
    PHC: {
        id: 2295,
        airportCode: 'PHC',
        cityCode: 'PHC',
        countryCode: 'NG',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Port Harcourt',
        airportNameEn: 'Port Harcourt International Airport ',
        cityNameVi: 'Port Harcourt',
        cityNameEn: 'Port Harcourt',
        countryNameVi: 'Ni giê ria',
        countryNameEn: 'Nigeria'
    },
    LOS: {
        id: 2294,
        airportCode: 'LOS',
        cityCode: 'LOS',
        countryCode: 'NG',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Murtala Muhammed',
        airportNameEn: 'Murtala Muhammed International Airport',
        cityNameVi: 'Lagos',
        cityNameEn: 'Lagos',
        countryNameVi: 'Ni giê ria',
        countryNameEn: 'Nigeria'
    },
    KAN: {
        id: 2293,
        airportCode: 'KAN',
        cityCode: 'KAN',
        countryCode: 'NG',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Mallam Aminu Kano',
        airportNameEn: 'Mallam Aminu Kano International Airport',
        cityNameVi: 'Kano',
        cityNameEn: 'Kano',
        countryNameVi: 'Ni giê ria',
        countryNameEn: 'Nigeria'
    },
    ABV: {
        id: 2292,
        airportCode: 'ABV',
        cityCode: 'ABV',
        countryCode: 'NG',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Nnamdi Azikiwe',
        airportNameEn: 'Nnamdi Azikiwe International Airport',
        cityNameVi: 'Abuja',
        cityNameEn: 'Abuja',
        countryNameVi: 'Ni giê ria',
        countryNameEn: 'Nigeria'
    },
    NIM: {
        id: 2291,
        airportCode: 'NIM',
        cityCode: 'NIM',
        countryCode: 'NE',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Diori Hamani',
        airportNameEn: 'Diori Hamani International Airport',
        cityNameVi: 'Niamey',
        cityNameEn: 'Niamey',
        countryNameVi: 'Ni giê',
        countryNameEn: 'Niger'
    },
    MGA: {
        id: 2290,
        airportCode: 'MGA',
        cityCode: 'MGA',
        countryCode: 'NI',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Augusto C. Sandino',
        airportNameEn: 'Augusto C. Sandino International Airport',
        cityNameVi: 'Managua',
        cityNameEn: 'Managua',
        countryNameVi: 'Nicaragua',
        countryNameEn: 'Nicaragua'
    },
    MRO: {
        id: 2289,
        airportCode: 'MRO',
        cityCode: 'MRO',
        countryCode: 'NZ',
        continentCode: 'OC',
        airportNameVi: 'Hood Aerodrome',
        airportNameEn: 'Hood Aerodrome',
        cityNameVi: 'Masterton',
        cityNameEn: 'Masterton',
        countryNameVi: 'Niu di lân',
        countryNameEn: 'New Zealand'
    },
    ROT: {
        id: 2288,
        airportCode: 'ROT',
        cityCode: 'ROT',
        countryCode: 'NZ',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Rotorua',
        airportNameEn: 'Rotorua Airport',
        cityNameVi: 'Rotorua',
        cityNameEn: 'Rotorua',
        countryNameVi: 'Niu di lân',
        countryNameEn: 'New Zealand'
    },
    TIU: {
        id: 2287,
        airportCode: 'TIU',
        cityCode: 'TIU',
        countryCode: 'NZ',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Richard Pearse',
        airportNameEn: 'Richard Pearse Airport',
        cityNameVi: 'Timaru',
        cityNameEn: 'Timaru',
        countryNameVi: 'Niu di lân',
        countryNameEn: 'New Zealand'
    },
    PPQ: {
        id: 2286,
        airportCode: 'PPQ',
        cityCode: 'PPQ',
        countryCode: 'NZ',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Paraparaumu',
        airportNameEn: 'Paraparaumu Airport',
        cityNameVi: 'Paraparaumu',
        cityNameEn: 'Paraparaumu',
        countryNameVi: 'Niu di lân',
        countryNameEn: 'New Zealand'
    },
    PMR: {
        id: 2285,
        airportCode: 'PMR',
        cityCode: 'PMR',
        countryCode: 'NZ',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Palmerston North',
        airportNameEn: 'Palmerston North International Airport',
        cityNameVi: 'Palmerston',
        cityNameEn: 'Palmerston',
        countryNameVi: 'Niu di lân',
        countryNameEn: 'New Zealand'
    },
    NPL: {
        id: 2284,
        airportCode: 'NPL',
        cityCode: 'NPL',
        countryCode: 'NZ',
        continentCode: 'OC',
        airportNameVi: 'Sân bay New Plymouth',
        airportNameEn: 'New Plymouth Airport',
        cityNameVi: 'New Plymouth',
        cityNameEn: 'New Plymouth',
        countryNameVi: 'Niu di lân',
        countryNameEn: 'New Zealand'
    },
    KAT: {
        id: 2283,
        airportCode: 'KAT',
        cityCode: 'KAT',
        countryCode: 'NZ',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Kaitaia',
        airportNameEn: 'Kaitaia Airport',
        cityNameVi: 'Kaitaia',
        cityNameEn: 'Kaitaia',
        countryNameVi: 'Niu di lân',
        countryNameEn: 'New Zealand'
    },
    HLZ: {
        id: 2282,
        airportCode: 'HLZ',
        cityCode: 'HLZ',
        countryCode: 'NZ',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Hamilton',
        airportNameEn: 'Hamilton International Airport',
        cityNameVi: 'Hamilton',
        cityNameEn: 'Hamilton',
        countryNameVi: 'Niu di lân',
        countryNameEn: 'New Zealand'
    },
    YAP: {
        id: 2281,
        airportCode: 'YAP',
        cityCode: 'YAP',
        countryCode: 'NZ',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Yap',
        airportNameEn: 'Yap International Airport ',
        cityNameVi: 'Yap, Caroline Islands',
        cityNameEn: 'Yap, Caroline Islands',
        countryNameVi: 'Niu di lân',
        countryNameEn: 'New Zealand'
    },
    WRE: {
        id: 2280,
        airportCode: 'WRE',
        cityCode: 'WRE',
        countryCode: 'NZ',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Whangarei',
        airportNameEn: 'Whangarei Airport',
        cityNameVi: 'Whangarei',
        cityNameEn: 'Whangarei',
        countryNameVi: 'Niu di lân',
        countryNameEn: 'New Zealand'
    },
    WHK: {
        id: 2279,
        airportCode: 'WHK',
        cityCode: 'WHK',
        countryCode: 'NZ',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Whakatane',
        airportNameEn: 'Whakatane Airport',
        cityNameVi: 'Whakatane',
        cityNameEn: 'Whakatane',
        countryNameVi: 'Niu di lân',
        countryNameEn: 'New Zealand'
    },
    WSZ: {
        id: 2278,
        airportCode: 'WSZ',
        cityCode: 'WSZ',
        countryCode: 'NZ',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Westport',
        airportNameEn: 'Westport Airport',
        cityNameVi: 'Westport',
        cityNameEn: 'Westport',
        countryNameVi: 'Niu di lân',
        countryNameEn: 'New Zealand'
    },
    WLG: {
        id: 2277,
        airportCode: 'WLG',
        cityCode: 'WLG',
        countryCode: 'NZ',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Wellington',
        airportNameEn: 'Wellington International Airport',
        cityNameVi: 'Wellington',
        cityNameEn: 'Wellington',
        countryNameVi: 'Niu di lân',
        countryNameEn: 'New Zealand'
    },
    WAG: {
        id: 2276,
        airportCode: 'WAG',
        cityCode: 'WAG',
        countryCode: 'NZ',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Wanganui',
        airportNameEn: 'Wanganui Airport',
        cityNameVi: 'Wanganui',
        cityNameEn: 'Wanganui',
        countryNameVi: 'Niu di lân',
        countryNameEn: 'New Zealand'
    },
    WKA: {
        id: 2275,
        airportCode: 'WKA',
        cityCode: 'WKA',
        countryCode: 'NZ',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Wanaka',
        airportNameEn: 'Wanaka Airport',
        cityNameVi: 'Wanaka',
        cityNameEn: 'Wanaka',
        countryNameVi: 'Niu di lân',
        countryNameEn: 'New Zealand'
    },
    TRG: {
        id: 2274,
        airportCode: 'TRG',
        cityCode: 'TRG',
        countryCode: 'NZ',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Tauranga',
        airportNameEn: 'Tauranga Airport',
        cityNameVi: 'Tauranga',
        cityNameEn: 'Tauranga',
        countryNameVi: 'Niu di lân',
        countryNameEn: 'New Zealand'
    },
    TUO: {
        id: 2273,
        airportCode: 'TUO',
        cityCode: 'TUO',
        countryCode: 'NZ',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Taupo',
        airportNameEn: 'Taupo Airport',
        cityNameVi: 'Taupo',
        cityNameEn: 'Taupo',
        countryNameVi: 'Niu di lân',
        countryNameEn: 'New Zealand'
    },
    ZQN: {
        id: 2272,
        airportCode: 'ZQN',
        cityCode: 'ZQN',
        countryCode: 'NZ',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Queenstown',
        airportNameEn: 'Queenstown Airport',
        cityNameVi: 'Queenstown',
        cityNameEn: 'Queenstown',
        countryNameVi: 'Niu di lân',
        countryNameEn: 'New Zealand'
    },
    NSN: {
        id: 2271,
        airportCode: 'NSN',
        cityCode: 'NSN',
        countryCode: 'NZ',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Nelson',
        airportNameEn: 'Nelson Airport',
        cityNameVi: 'Nelson',
        cityNameEn: 'Nelson',
        countryNameVi: 'Niu di lân',
        countryNameEn: 'New Zealand'
    },
    NPE: {
        id: 2270,
        airportCode: 'NPE',
        cityCode: 'NPE',
        countryCode: 'NZ',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Napier',
        airportNameEn: 'Napier Airport ',
        cityNameVi: 'Napier Hastings',
        cityNameEn: 'Napier Hastings',
        countryNameVi: 'Niu di lân',
        countryNameEn: 'New Zealand'
    },
    MOZ: {
        id: 2269,
        airportCode: 'MOZ',
        cityCode: 'MOZ',
        countryCode: 'NZ',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Moorea',
        airportNameEn: 'Moorea Airport ',
        cityNameVi: 'Moorea',
        cityNameEn: 'Moorea',
        countryNameVi: 'Niu di lân',
        countryNameEn: 'New Zealand'
    },
    KKE: {
        id: 2268,
        airportCode: 'KKE',
        cityCode: 'KKE',
        countryCode: 'NZ',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Bay of Islands',
        airportNameEn: 'Bay of Islands Airport ',
        cityNameVi: 'Kerikeri',
        cityNameEn: 'Kerikeri',
        countryNameVi: 'Niu di lân',
        countryNameEn: 'New Zealand'
    },
    IVC: {
        id: 2267,
        airportCode: 'IVC',
        cityCode: 'IVC',
        countryCode: 'NZ',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Invercargill',
        airportNameEn: 'Invercargill Airport',
        cityNameVi: 'Invercargill',
        cityNameEn: 'Invercargill',
        countryNameVi: 'Niu di lân',
        countryNameEn: 'New Zealand'
    },
    HUH: {
        id: 2266,
        airportCode: 'HUH',
        cityCode: 'HUH',
        countryCode: 'NZ',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Fare',
        airportNameEn: 'Fare Airport',
        cityNameVi: 'Huahine',
        cityNameEn: 'Huahine',
        countryNameVi: 'Niu di lân',
        countryNameEn: 'New Zealand'
    },
    HKK: {
        id: 2265,
        airportCode: 'HKK',
        cityCode: 'HKK',
        countryCode: 'NZ',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Hokitika',
        airportNameEn: 'Hokitika Airport',
        cityNameVi: 'Hokitika',
        cityNameEn: 'Hokitika',
        countryNameVi: 'Niu di lân',
        countryNameEn: 'New Zealand'
    },
    GZO: {
        id: 2264,
        airportCode: 'GZO',
        cityCode: 'GZO',
        countryCode: 'NZ',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Nusatupe',
        airportNameEn: 'Nusatupe Airport',
        cityNameVi: 'Gizo',
        cityNameEn: 'Gizo',
        countryNameVi: 'Niu di lân',
        countryNameEn: 'New Zealand'
    },
    GIS: {
        id: 2263,
        airportCode: 'GIS',
        cityCode: 'GIS',
        countryCode: 'NZ',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Gisborne',
        airportNameEn: 'Gisborne Airport',
        cityNameVi: 'Gisborne',
        cityNameEn: 'Gisborne',
        countryNameVi: 'Niu di lân',
        countryNameEn: 'New Zealand'
    },
    DUD: {
        id: 2262,
        airportCode: 'DUD',
        cityCode: 'DUD',
        countryCode: 'NZ',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Dunedin',
        airportNameEn: 'Dunedin Airport',
        cityNameVi: 'Dunedin',
        cityNameEn: 'Dunedin',
        countryNameVi: 'Niu di lân',
        countryNameEn: 'New Zealand'
    },
    CHC: {
        id: 2261,
        airportCode: 'CHC',
        cityCode: 'CHC',
        countryCode: 'NZ',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Christchurch',
        airportNameEn: 'Christchurch International Airport',
        cityNameVi: 'Christchurch',
        cityNameEn: 'Christchurch',
        countryNameVi: 'Niu di lân',
        countryNameEn: 'New Zealand'
    },
    BHE: {
        id: 2260,
        airportCode: 'BHE',
        cityCode: 'BHE',
        countryCode: 'NZ',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Woodbourne',
        airportNameEn: 'Woodbourne Airport',
        cityNameVi: 'Blenheim',
        cityNameEn: 'Blenheim',
        countryNameVi: 'Niu di lân',
        countryNameEn: 'New Zealand'
    },
    AKL: {
        id: 2259,
        airportCode: 'AKL',
        cityCode: 'AKL',
        countryCode: 'NZ',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Auckland',
        airportNameEn: 'Auckland Airport',
        cityNameVi: 'Auckland',
        cityNameEn: 'Auckland',
        countryNameVi: 'Niu di lân',
        countryNameEn: 'New Zealand'
    },
    GEA: {
        id: 2258,
        airportCode: 'GEA',
        cityCode: 'NOU',
        countryCode: 'NC',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Nouméa Magenta',
        airportNameEn: 'Nouméa Magenta Airport',
        cityNameVi: 'Noumea',
        cityNameEn: 'Noumea',
        countryNameVi: 'New Caledonia',
        countryNameEn: 'New Caledonia'
    },
    OAJ: {
        id: 2257,
        airportCode: 'OAJ',
        cityCode: 'OAJ',
        countryCode: 'NC',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Albert J. Ellis',
        airportNameEn: 'Albert J. Ellis Airport',
        cityNameVi: 'Jacksonville',
        cityNameEn: 'Jacksonville',
        countryNameVi: 'New Caledonia',
        countryNameEn: 'New Caledonia'
    },
    PGV: {
        id: 2256,
        airportCode: 'PGV',
        cityCode: 'PGV',
        countryCode: 'NC',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Pitt-Greenville',
        airportNameEn: 'Pitt-Greenville Airport',
        cityNameVi: 'Greenville',
        cityNameEn: 'Greenville',
        countryNameVi: 'New Caledonia',
        countryNameEn: 'New Caledonia'
    },
    FAY: {
        id: 2255,
        airportCode: 'FAY',
        cityCode: 'FAY',
        countryCode: 'NC',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Fayetteville Regional',
        airportNameEn: 'Fayetteville Regional Airport ',
        cityNameVi: 'Fayetteville',
        cityNameEn: 'Fayetteville',
        countryNameVi: 'New Caledonia',
        countryNameEn: 'New Caledonia'
    },
    RTM: {
        id: 2254,
        airportCode: 'RTM',
        cityCode: 'RTM',
        countryCode: 'NL',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Rotterdam The Hague',
        airportNameEn: 'Rotterdam The Hague Airport',
        cityNameVi: 'Rotterdam',
        cityNameEn: 'Rotterdam',
        countryNameVi: 'Hà Lan',
        countryNameEn: 'Netherlands'
    },
    MST: {
        id: 2253,
        airportCode: 'MST',
        cityCode: 'MST',
        countryCode: 'NL',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Maastricht Aachen',
        airportNameEn: 'Maastricht Aachen Airport',
        cityNameVi: 'Maastricht',
        cityNameEn: 'Maastricht',
        countryNameVi: 'Hà Lan',
        countryNameEn: 'Netherlands'
    },
    GRQ: {
        id: 2252,
        airportCode: 'GRQ',
        cityCode: 'GRQ',
        countryCode: 'NL',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Groningen',
        airportNameEn: 'Groningen Airport Eelde',
        cityNameVi: 'Groningen',
        cityNameEn: 'Groningen',
        countryNameVi: 'Hà Lan',
        countryNameEn: 'Netherlands'
    },
    EIN: {
        id: 2251,
        airportCode: 'EIN',
        cityCode: 'EIN',
        countryCode: 'NL',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Eindhoven',
        airportNameEn: 'Eindhoven Airport',
        cityNameVi: 'Eindhoven',
        cityNameEn: 'Eindhoven',
        countryNameVi: 'Hà Lan',
        countryNameEn: 'Netherlands'
    },
    AMS: {
        id: 2250,
        airportCode: 'AMS',
        cityCode: 'AMS',
        countryCode: 'NL',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Amsterdam',
        airportNameEn: 'Amsterdam Airport Schiphol',
        cityNameVi: 'Amsterdam',
        cityNameEn: 'Amsterdam',
        countryNameVi: 'Hà Lan',
        countryNameEn: 'Netherlands'
    },
    MSB: {
        id: 2249,
        airportCode: 'MSB',
        cityCode: 'MSB',
        countryCode: 'AN',
        continentCode: 'SA',
        airportNameVi: 'Marigot Seaplane Base',
        airportNameEn: 'Marigot Seaplane Base',
        cityNameVi: 'St Martin',
        cityNameEn: 'St Martin',
        countryNameVi: 'Netherland Antilles',
        countryNameEn: 'Netherland Antilles'
    },
    SXM: {
        id: 2248,
        airportCode: 'SXM',
        cityCode: 'SXM',
        countryCode: 'AN',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Princess Juliana',
        airportNameEn: 'Princess Juliana International Airport',
        cityNameVi: 'St Maarten',
        cityNameEn: 'St Maarten',
        countryNameVi: 'Netherland Antilles',
        countryNameEn: 'Netherland Antilles'
    },
    EUX: {
        id: 2247,
        airportCode: 'EUX',
        cityCode: 'EUX',
        countryCode: 'AN',
        continentCode: 'SA',
        airportNameVi: 'Sân bay F.D. Roosevelt',
        airportNameEn: 'F.D. Roosevelt Airport',
        cityNameVi: 'St Eustatius',
        cityNameEn: 'St Eustatius',
        countryNameVi: 'Netherland Antilles',
        countryNameEn: 'Netherland Antilles'
    },
    SAB: {
        id: 2246,
        airportCode: 'SAB',
        cityCode: 'SAB',
        countryCode: 'AN',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Juancho E. Yrausquin',
        airportNameEn: 'Juancho E. Yrausquin Airport',
        cityNameVi: 'Saba Island',
        cityNameEn: 'Saba Island',
        countryNameVi: 'Netherland Antilles',
        countryNameEn: 'Netherland Antilles'
    },
    CUR: {
        id: 2245,
        airportCode: 'CUR',
        cityCode: 'CUR',
        countryCode: 'AN',
        continentCode: 'SA',
        airportNameVi: 'Curacao',
        airportNameEn: 'Curacao',
        cityNameVi: 'Curacao',
        cityNameEn: 'Curacao',
        countryNameVi: 'Netherland Antilles',
        countryNameEn: 'Netherland Antilles'
    },
    BON: {
        id: 2244,
        airportCode: 'BON',
        cityCode: 'BON',
        countryCode: 'AN',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Flamingo',
        airportNameEn: 'Flamingo International Airport',
        cityNameVi: 'Bonaire',
        cityNameEn: 'Bonaire',
        countryNameVi: 'Netherland Antilles',
        countryNameEn: 'Netherland Antilles'
    },
    PKR: {
        id: 2243,
        airportCode: 'PKR',
        cityCode: 'PKR',
        countryCode: 'NP',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Pokhara',
        airportNameEn: 'Pokhara Airport',
        cityNameVi: 'Pokhara',
        cityNameEn: 'Pokhara',
        countryNameVi: 'Nepal',
        countryNameEn: 'Nepal'
    },
    KTM: {
        id: 2242,
        airportCode: 'KTM',
        cityCode: 'KTM',
        countryCode: 'NP',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Tribhuvan',
        airportNameEn: 'Tribhuvan International Airport',
        cityNameVi: 'Kathmandu',
        cityNameEn: 'Kathmandu',
        countryNameVi: 'Nepal',
        countryNameEn: 'Nepal'
    },
    WVB: {
        id: 2241,
        airportCode: 'WVB',
        cityCode: 'WVB',
        countryCode: 'NA',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Walvis Bay',
        airportNameEn: 'Walvis Bay Airport',
        cityNameVi: 'Walvis Bay',
        cityNameEn: 'Walvis Bay',
        countryNameVi: 'Namibia',
        countryNameEn: 'Namibia'
    },
    WDH: {
        id: 2240,
        airportCode: 'WDH',
        cityCode: 'WDH',
        countryCode: 'NA',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Windhoek Hosea Kutako',
        airportNameEn: 'Windhoek Hosea Kutako International Airport',
        cityNameVi: 'Windhoek',
        cityNameEn: 'Windhoek',
        countryNameVi: 'Namibia',
        countryNameEn: 'Namibia'
    },
    ERS: {
        id: 2239,
        airportCode: 'ERS',
        cityCode: 'WDH',
        countryCode: 'NA',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Eros',
        airportNameEn: 'Eros Airport',
        cityNameVi: 'Windhoek',
        cityNameEn: 'Windhoek',
        countryNameVi: 'Namibia',
        countryNameEn: 'Namibia'
    },
    LUD: {
        id: 2238,
        airportCode: 'LUD',
        cityCode: 'LUD',
        countryCode: 'NA',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Luderitz',
        airportNameEn: 'Luderitz Airport ',
        cityNameVi: 'Luderitz',
        cityNameEn: 'Luderitz',
        countryNameVi: 'Namibia',
        countryNameEn: 'Namibia'
    },
    MDL: {
        id: 2237,
        airportCode: 'MDL',
        cityCode: 'MDL',
        countryCode: 'MM',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Mandalay',
        airportNameEn: 'Mandalay International Airport',
        cityNameVi: 'Mandalay',
        cityNameEn: 'Mandalay',
        countryNameVi: 'Myanmar',
        countryNameEn: 'Myanmar'
    },
    HEH: {
        id: 2236,
        airportCode: 'HEH',
        cityCode: 'HEH',
        countryCode: 'MM',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Heho',
        airportNameEn: 'Heho Airport',
        cityNameVi: 'Heho',
        cityNameEn: 'Heho',
        countryNameVi: 'Myanmar',
        countryNameEn: 'Myanmar'
    },
    NYU: {
        id: 2235,
        airportCode: 'NYU',
        cityCode: 'NYU',
        countryCode: 'MM',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Bagan',
        airportNameEn: 'Bagan Airport',
        cityNameVi: 'Nyaung',
        cityNameEn: 'Nyaung',
        countryNameVi: 'Myanmar',
        countryNameEn: 'MYANMAR'
    },
    RGN: {
        id: 2234,
        airportCode: 'RGN',
        cityCode: 'RGN',
        countryCode: 'MM',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Yangon',
        airportNameEn: 'Yangon International Airport',
        cityNameVi: 'Yangon',
        cityNameEn: 'Yangon',
        countryNameVi: 'Myanmar',
        countryNameEn: 'Myanmar'
    },
    VNX: {
        id: 2233,
        airportCode: 'VNX',
        cityCode: 'VNX',
        countryCode: 'MZ',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Vilankulo',
        airportNameEn: 'Vilankulo Airport',
        cityNameVi: 'Vilanculos',
        cityNameEn: 'Vilanculos',
        countryNameVi: 'Mozambique',
        countryNameEn: 'Mozambique'
    },
    POL: {
        id: 2232,
        airportCode: 'POL',
        cityCode: 'POL',
        countryCode: 'MZ',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Pemba',
        airportNameEn: 'Pemba Airport',
        cityNameVi: 'Pemba',
        cityNameEn: 'Pemba',
        countryNameVi: 'Mozambique',
        countryNameEn: 'Mozambique'
    },
    APL: {
        id: 2231,
        airportCode: 'APL',
        cityCode: 'APL',
        countryCode: 'MZ',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Nampula',
        airportNameEn: 'Nampula Airport',
        cityNameVi: 'Nampula',
        cityNameEn: 'Nampula',
        countryNameVi: 'Mozambique',
        countryNameEn: 'Mozambique'
    },
    TET: {
        id: 2230,
        airportCode: 'TET',
        cityCode: 'TET',
        countryCode: 'MZ',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Chingozi',
        airportNameEn: 'Chingozi Airport',
        cityNameVi: 'Tete',
        cityNameEn: 'Tete',
        countryNameVi: 'Mozambique',
        countryNameEn: 'Mozambique'
    },
    MPM: {
        id: 2229,
        airportCode: 'MPM',
        cityCode: 'MPM',
        countryCode: 'MZ',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Maputo',
        airportNameEn: 'Maputo International Airport',
        cityNameVi: 'Maputo',
        cityNameEn: 'Maputo',
        countryNameVi: 'Mozambique',
        countryNameEn: 'Mozambique'
    },
    BEW: {
        id: 2228,
        airportCode: 'BEW',
        cityCode: 'BEW',
        countryCode: 'MZ',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Beira',
        airportNameEn: 'Beira Airport',
        cityNameVi: 'Beira',
        cityNameEn: 'Beira',
        countryNameVi: 'Mozambique',
        countryNameEn: 'Mozambique'
    },
    VIL: {
        id: 2227,
        airportCode: 'VIL',
        cityCode: 'VIL',
        countryCode: 'MA',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Dakhla',
        airportNameEn: 'Dakhla Airport',
        cityNameVi: 'Dakhla',
        cityNameEn: 'Dakhla',
        countryNameVi: 'Ma rốc',
        countryNameEn: 'Morocco'
    },
    TNG: {
        id: 2226,
        airportCode: 'TNG',
        cityCode: 'TNG',
        countryCode: 'MA',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Ibn Batouta',
        airportNameEn: 'Ibn Batouta International Airport',
        cityNameVi: 'Tangier',
        cityNameEn: 'Tangier',
        countryNameVi: 'Ma rốc',
        countryNameEn: 'Morocco'
    },
    RBA: {
        id: 2225,
        airportCode: 'RBA',
        cityCode: 'RBA',
        countryCode: 'MA',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Sale',
        airportNameEn: 'Sale Airport',
        cityNameVi: 'Rabat',
        cityNameEn: 'Rabat',
        countryNameVi: 'Ma rốc',
        countryNameEn: 'Morocco'
    },
    OZZ: {
        id: 2224,
        airportCode: 'OZZ',
        cityCode: 'OZZ',
        countryCode: 'MA',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Ouarzazate',
        airportNameEn: 'Ouarzazate Airport',
        cityNameVi: 'Ourzazate',
        cityNameEn: 'Ourzazate',
        countryNameVi: 'Ma rốc',
        countryNameEn: 'Morocco'
    },
    OUD: {
        id: 2223,
        airportCode: 'OUD',
        cityCode: 'OUD',
        countryCode: 'MA',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Angads',
        airportNameEn: 'Angads Airport',
        cityNameVi: 'Oujda',
        cityNameEn: 'Oujda',
        countryNameVi: 'Ma rốc',
        countryNameEn: 'Morocco'
    },
    NDR: {
        id: 2222,
        airportCode: 'NDR',
        cityCode: 'NDR',
        countryCode: 'MA',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Arwi',
        airportNameEn: 'Arwi Airport',
        cityNameVi: 'Nador',
        cityNameEn: 'Nador',
        countryNameVi: 'Ma rốc',
        countryNameEn: 'Morocco'
    },
    RAK: {
        id: 2221,
        airportCode: 'RAK',
        cityCode: 'RAK',
        countryCode: 'MA',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Menara',
        airportNameEn: 'Menara International Airport',
        cityNameVi: 'Marrakech',
        cityNameEn: 'Marrakech',
        countryNameVi: 'Ma rốc',
        countryNameEn: 'Morocco'
    },
    FEZ: {
        id: 2220,
        airportCode: 'FEZ',
        cityCode: 'FEZ',
        countryCode: 'MA',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Fes-Saïss',
        airportNameEn: 'Fes-Saïss Airport',
        cityNameVi: 'Fez Ma',
        cityNameEn: 'Fez Ma',
        countryNameVi: 'Ma rốc',
        countryNameEn: 'Morocco'
    },
    ESU: {
        id: 2219,
        airportCode: 'ESU',
        cityCode: 'ESU',
        countryCode: 'MA',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Essaouira',
        airportNameEn: 'Essaouira Airport',
        cityNameVi: 'Essaouira',
        cityNameEn: 'Essaouira',
        countryNameVi: 'Ma rốc',
        countryNameEn: 'Morocco'
    },
    EUN: {
        id: 2218,
        airportCode: 'EUN',
        cityCode: 'EUN',
        countryCode: 'MA',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Hassan I',
        airportNameEn: 'Hassan I Airport',
        cityNameVi: 'El Aaiun',
        cityNameEn: 'El Aaiun',
        countryNameVi: 'Ma rốc',
        countryNameEn: 'Morocco'
    },
    CMN: {
        id: 2217,
        airportCode: 'CMN',
        cityCode: 'CAS',
        countryCode: 'MA',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Mohammed V',
        airportNameEn: 'Mohammed V International Airport',
        cityNameVi: 'Casablanca',
        cityNameEn: 'Casablanca',
        countryNameVi: 'Ma rốc',
        countryNameEn: 'Morocco'
    },
    AHU: {
        id: 2216,
        airportCode: 'AHU',
        cityCode: 'AHU',
        countryCode: 'MA',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Charif Al ldrissi',
        airportNameEn: 'Charif Al ldrissi Airport',
        cityNameVi: 'Al Hoceima',
        cityNameEn: 'Al Hoceima',
        countryNameVi: 'Ma rốc',
        countryNameEn: 'Morocco'
    },
    AGA: {
        id: 2215,
        airportCode: 'AGA',
        cityCode: 'AGA',
        countryCode: 'MA',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Al Massira',
        airportNameEn: 'Al Massira Airport',
        cityNameVi: 'Agadir',
        cityNameEn: 'Agadir',
        countryNameVi: 'Ma rốc',
        countryNameEn: 'Morocco'
    },
    HKS: {
        id: 2214,
        airportCode: 'HKS',
        cityCode: 'JAN',
        countryCode: 'MS',
        continentCode: 'SA',
        airportNameVi: 'Hawkins Field',
        airportNameEn: 'Hawkins Field',
        cityNameVi: 'Jackson',
        cityNameEn: 'Jackson',
        countryNameVi: 'Montserrat',
        countryNameEn: 'Montserrat'
    },
    GLH: {
        id: 2213,
        airportCode: 'GLH',
        cityCode: 'GLH',
        countryCode: 'MS',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Mid-Delta Regional',
        airportNameEn: 'Mid-Delta Regional Airport',
        cityNameVi: 'Greenville',
        cityNameEn: 'Greenville',
        countryNameVi: 'Montserrat',
        countryNameEn: 'Montserrat'
    },
    TIV: {
        id: 2212,
        airportCode: 'TIV',
        cityCode: 'TIV',
        countryCode: 'ME',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Tivat',
        airportNameEn: 'Tivat Airport',
        cityNameVi: 'Tivat',
        cityNameEn: 'Tivat',
        countryNameVi: 'Montenegro',
        countryNameEn: 'Montenegro'
    },
    TGD: {
        id: 2211,
        airportCode: 'TGD',
        cityCode: 'TGD',
        countryCode: 'ME',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Podgorica',
        airportNameEn: 'Podgorica Airport',
        cityNameVi: 'Podgorica',
        cityNameEn: 'Podgorica',
        countryNameVi: 'Montenegro',
        countryNameEn: 'Montenegro'
    },
    ULN: {
        id: 2210,
        airportCode: 'ULN',
        cityCode: 'ULN',
        countryCode: 'MN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Chinggis Khaan',
        airportNameEn: 'Chinggis Khaan International Airport',
        cityNameVi: 'U-lan-ba-to',
        cityNameEn: 'Ulaanbaatar',
        countryNameVi: 'Mông cổ',
        countryNameEn: 'Mongolia'
    },
    RST: {
        id: 2209,
        airportCode: 'RST',
        cityCode: 'RST',
        countryCode: 'MN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Rochester',
        airportNameEn: 'Rochester International Airport',
        cityNameVi: 'Rochester',
        cityNameEn: 'Rochester',
        countryNameVi: 'Mông cổ',
        countryNameEn: 'Mongolia'
    },
    MCM: {
        id: 2208,
        airportCode: 'MCM',
        cityCode: 'MCM',
        countryCode: 'MC',
        continentCode: 'EU',
        airportNameVi: 'Monaco Heliport',
        airportNameEn: 'Monaco Heliport',
        cityNameVi: 'Monte Carlo',
        cityNameEn: 'Monte Carlo',
        countryNameVi: 'Monaco',
        countryNameEn: 'MONACO'
    },
    KIV: {
        id: 2207,
        airportCode: 'KIV',
        cityCode: 'KIV',
        countryCode: 'MD',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Chisinau',
        airportNameEn: 'Chişinău International Airport',
        cityNameVi: 'Chisinau',
        cityNameEn: 'Chisinau',
        countryNameVi: 'Moldoa',
        countryNameEn: 'Moldova'
    },
    SPN: {
        id: 2206,
        airportCode: 'SPN',
        cityCode: 'SPN',
        countryCode: 'FM',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Saipan',
        airportNameEn: 'Saipan International Airport ',
        cityNameVi: 'Saipan',
        cityNameEn: 'Saipan',
        countryNameVi: 'Micronesia',
        countryNameEn: 'Micronesia'
    },
    ROP: {
        id: 2205,
        airportCode: 'ROP',
        cityCode: 'ROP',
        countryCode: 'FM',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Rota',
        airportNameEn: 'Rota International Airport ',
        cityNameVi: 'Rota',
        cityNameEn: 'Rota',
        countryNameVi: 'Micronesia',
        countryNameEn: 'Micronesia'
    },
    PNI: {
        id: 2204,
        airportCode: 'PNI',
        cityCode: 'PNI',
        countryCode: 'FM',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Pohnpei',
        airportNameEn: 'Pohnpei International Airport',
        cityNameVi: 'Pohnpei',
        cityNameEn: 'Pohnpei',
        countryNameVi: 'Micronesia',
        countryNameEn: 'Micronesia'
    },
    ROR: {
        id: 2203,
        airportCode: 'ROR',
        cityCode: 'ROR',
        countryCode: 'FM',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Babelthuap',
        airportNameEn: 'Babelthuap Airport',
        cityNameVi: 'Koror',
        cityNameEn: 'Koror',
        countryNameVi: 'Micronesia',
        countryNameEn: 'Micronesia'
    },
    QRO: {
        id: 2202,
        airportCode: 'QRO',
        cityCode: 'QRO',
        countryCode: 'MX',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Querétaro',
        airportNameEn: 'Querétaro International Airport',
        cityNameVi: 'Queretaro',
        cityNameEn: 'Queretaro',
        countryNameVi: 'Mê hi cô',
        countryNameEn: 'MEXICO'
    },
    ZLO: {
        id: 2201,
        airportCode: 'ZLO',
        cityCode: 'ZLO',
        countryCode: 'MX',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Playa de Oro',
        airportNameEn: 'Playa de Oro International Airport',
        cityNameVi: 'Manzanillo',
        cityNameEn: 'Manzanillo',
        countryNameVi: 'Mê hi cô',
        countryNameEn: 'MEXICO'
    },
    SLW: {
        id: 2200,
        airportCode: 'SLW',
        cityCode: 'SLW',
        countryCode: 'MX',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Plan de Guadalupe',
        airportNameEn: 'Plan de Guadalupe International Airport',
        cityNameVi: 'Saltillo',
        cityNameEn: 'Saltillo',
        countryNameVi: 'Mê hi cô',
        countryNameEn: 'MEXICO'
    },
    PDS: {
        id: 2199,
        airportCode: 'PDS',
        cityCode: 'PDS',
        countryCode: 'MX',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Piedras Negras',
        airportNameEn: 'Piedras Negras International Airport',
        cityNameVi: 'Piedras Negras',
        cityNameEn: 'Piedras Negras',
        countryNameVi: 'Mê hi cô',
        countryNameEn: 'MEXICO'
    },
    MTT: {
        id: 2198,
        airportCode: 'MTT',
        cityCode: 'MTT',
        countryCode: 'MX',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Minatitlán/Coatzacoalcos National',
        airportNameEn: 'Minatitlán/Coatzacoalcos National Airport',
        cityNameVi: 'Minatitlan',
        cityNameEn: 'Minatitlan',
        countryNameVi: 'Mê hi cô',
        countryNameEn: 'MEXICO'
    },
    PPE: {
        id: 2197,
        airportCode: 'PPE',
        cityCode: 'PPE',
        countryCode: 'MX',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Mar de Cortés',
        airportNameEn: 'Mar de Cortés International Airport',
        cityNameVi: 'Puerto Penasco',
        cityNameEn: 'Puerto Penasco',
        countryNameVi: 'Mê hi cô',
        countryNameEn: 'MEXICO'
    },
    TLC: {
        id: 2196,
        airportCode: 'TLC',
        cityCode: 'TLC',
        countryCode: 'MX',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Lic. Adolfo López Mateos',
        airportNameEn: 'Lic. Adolfo López Mateos International Airport',
        cityNameVi: 'Toluca',
        cityNameEn: 'Toluca',
        countryNameVi: 'Mê hi cô',
        countryNameEn: 'MEXICO'
    },
    LZC: {
        id: 2195,
        airportCode: 'LZC',
        cityCode: 'LZC',
        countryCode: 'MX',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Lázaro Cárdenas',
        airportNameEn: 'Lázaro Cárdenas Airport',
        cityNameVi: 'Lazaro Cardenas Michoacan',
        cityNameEn: 'Lazaro Cardenas Michoacan',
        countryNameVi: 'Mê hi cô',
        countryNameEn: 'MEXICO'
    },
    CVM: {
        id: 2194,
        airportCode: 'CVM',
        cityCode: 'CVM',
        countryCode: 'MX',
        continentCode: 'NA',
        airportNameVi: 'Sân bay General Pedro J. Méndez National',
        airportNameEn: 'General Pedro J. Méndez National Airport',
        cityNameVi: 'Ciudad Victoria',
        cityNameEn: 'Ciudad Victoria',
        countryNameVi: 'Mê hi cô',
        countryNameEn: 'MEXICO'
    },
    MLM: {
        id: 2193,
        airportCode: 'MLM',
        cityCode: 'MLM',
        countryCode: 'MX',
        continentCode: 'NA',
        airportNameVi: 'Sân bay General Francisco J. Mujica',
        airportNameEn: 'General Francisco J. Mujica International Airport',
        cityNameVi: 'Morelia',
        cityNameEn: 'Morelia',
        countryNameVi: 'Mê hi cô',
        countryNameEn: 'MEXICO'
    },
    PAZ: {
        id: 2192,
        airportCode: 'PAZ',
        cityCode: 'PAZ',
        countryCode: 'MX',
        continentCode: 'NA',
        airportNameVi: 'Sân bay El Tajín National',
        airportNameEn: 'El Tajín National Airport',
        cityNameVi: 'Poza Rica',
        cityNameEn: 'Poza Rica',
        countryNameVi: 'Mê hi cô',
        countryNameEn: 'MEXICO'
    },
    JAL: {
        id: 2191,
        airportCode: 'JAL',
        cityCode: 'JAL',
        countryCode: 'MX',
        continentCode: 'NA',
        airportNameVi: 'Sân bay El Lencero',
        airportNameEn: 'El Lencero Airport',
        cityNameVi: 'Xalapa',
        cityNameEn: 'Xalapa',
        countryNameVi: 'Mê hi cô',
        countryNameEn: 'MEXICO'
    },
    TGZ: {
        id: 2190,
        airportCode: 'TGZ',
        cityCode: 'TGZ',
        countryCode: 'MX',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Angel Albino Corzo',
        airportNameEn: 'Angel Albino Corzo International Airport',
        cityNameVi: 'Tuxtla Gutierrez',
        cityNameEn: 'Tuxtla Gutierrez',
        countryNameVi: 'Mê hi cô',
        countryNameEn: 'MEXICO'
    },
    ZIH: {
        id: 2189,
        airportCode: 'ZIH',
        cityCode: 'ZIH',
        countryCode: 'MX',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Ixtapa-Zihuatanejo',
        airportNameEn: 'Ixtapa-Zihuatanejo International Airport',
        cityNameVi: 'Zihuatanejo',
        cityNameEn: 'Zihuatanejo',
        countryNameVi: 'Mê hi cô',
        countryNameEn: 'MEXICO'
    },
    ZCL: {
        id: 2188,
        airportCode: 'ZCL',
        cityCode: 'ZCL',
        countryCode: 'MX',
        continentCode: 'NA',
        airportNameVi: 'Sân bay General Leobardo C. Ruiz',
        airportNameEn: 'General Leobardo C. Ruiz International Airport ',
        cityNameVi: 'Zacatecas',
        cityNameEn: 'Zacatecas',
        countryNameVi: 'Mê hi cô',
        countryNameEn: 'MEXICO'
    },
    VSA: {
        id: 2187,
        airportCode: 'VSA',
        cityCode: 'VSA',
        countryCode: 'MX',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Carlos Rovirosa Pérez',
        airportNameEn: 'Carlos Rovirosa Pérez International Airport',
        cityNameVi: 'Villahermosa',
        cityNameEn: 'Villahermosa',
        countryNameVi: 'Mê hi cô',
        countryNameEn: 'MEXICO'
    },
    VER: {
        id: 2186,
        airportCode: 'VER',
        cityCode: 'VER',
        countryCode: 'MX',
        continentCode: 'NA',
        airportNameVi: 'Sân bay General Heriberto Jara',
        airportNameEn: 'General Heriberto Jara International Airport',
        cityNameVi: 'Veracruz',
        cityNameEn: 'Veracruz',
        countryNameVi: 'Mê hi cô',
        countryNameEn: 'MEXICO'
    },
    TRC: {
        id: 2185,
        airportCode: 'TRC',
        cityCode: 'TRC',
        countryCode: 'MX',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Francisco Sarabia',
        airportNameEn: 'Francisco Sarabia International Airport ',
        cityNameVi: 'Torreon',
        cityNameEn: 'Torreon',
        countryNameVi: 'Mê hi cô',
        countryNameEn: 'MEXICO'
    },
    TIJ: {
        id: 2184,
        airportCode: 'TIJ',
        cityCode: 'TIJ',
        countryCode: 'MX',
        continentCode: 'NA',
        airportNameVi: 'Sân bay General Abelardo L. Rodríguez',
        airportNameEn: 'General Abelardo L. Rodríguez International Airport',
        cityNameVi: 'Tijuana',
        cityNameEn: 'Tijuana',
        countryNameVi: 'Mê hi cô',
        countryNameEn: 'MEXICO'
    },
    TPQ: {
        id: 2183,
        airportCode: 'TPQ',
        cityCode: 'TPQ',
        countryCode: 'MX',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Amado Nervo National',
        airportNameEn: 'Amado Nervo National Airport',
        cityNameVi: 'Tepic',
        cityNameEn: 'Tepic',
        countryNameVi: 'Mê hi cô',
        countryNameEn: 'MEXICO'
    },
    TAP: {
        id: 2182,
        airportCode: 'TAP',
        cityCode: 'TAP',
        countryCode: 'MX',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Tapachula',
        airportNameEn: 'Tapachula International Airport',
        cityNameVi: 'Tapachula',
        cityNameEn: 'Tapachula',
        countryNameVi: 'Mê hi cô',
        countryNameEn: 'MEXICO'
    },
    TAM: {
        id: 2181,
        airportCode: 'TAM',
        cityCode: 'TAM',
        countryCode: 'MX',
        continentCode: 'NA',
        airportNameVi: 'Sân bay General Francisco Javier Mina',
        airportNameEn: 'General Francisco Javier Mina International Airport',
        cityNameVi: 'Tampico',
        cityNameEn: 'Tampico',
        countryNameVi: 'Mê hi cô',
        countryNameEn: 'MEXICO'
    },
    HUX: {
        id: 2180,
        airportCode: 'HUX',
        cityCode: 'HUX',
        countryCode: 'MX',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Huatulco',
        airportNameEn: 'Huatulco International Airport',
        cityNameVi: 'Santa Cruz Huatulco',
        cityNameEn: 'Santa Cruz Huatulco',
        countryNameVi: 'Mê hi cô',
        countryNameEn: 'MEXICO'
    },
    SLP: {
        id: 2179,
        airportCode: 'SLP',
        cityCode: 'SLP',
        countryCode: 'MX',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Ponciano Arriaga',
        airportNameEn: 'Ponciano Arriaga International Airport',
        cityNameVi: 'San Luis Potosi',
        cityNameEn: 'San Luis Potosi',
        countryNameVi: 'Mê hi cô',
        countryNameEn: 'MEXICO'
    },
    SJD: {
        id: 2178,
        airportCode: 'SJD',
        cityCode: 'SJD',
        countryCode: 'MX',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Los Cabos',
        airportNameEn: 'Los Cabos International Airport',
        cityNameVi: 'San Jose Cabo',
        cityNameEn: 'San Jose Cabo',
        countryNameVi: 'Mê hi cô',
        countryNameEn: 'MEXICO'
    },
    REX: {
        id: 2177,
        airportCode: 'REX',
        cityCode: 'REX',
        countryCode: 'MX',
        continentCode: 'NA',
        airportNameVi: 'Sân bay General Lucio Blanco',
        airportNameEn: 'General Lucio Blanco International Airport',
        cityNameVi: 'Reynosa',
        cityNameEn: 'Reynosa',
        countryNameVi: 'Mê hi cô',
        countryNameEn: 'MEXICO'
    },
    PVR: {
        id: 2176,
        airportCode: 'PVR',
        cityCode: 'PVR',
        countryCode: 'MX',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Lic. Gustavo Díaz Ordaz',
        airportNameEn: 'Lic. Gustavo Díaz Ordaz International Airport',
        cityNameVi: 'Puerto Vallarta',
        cityNameEn: 'Puerto Vallarta',
        countryNameVi: 'Mê hi cô',
        countryNameEn: 'MEXICO'
    },
    PXM: {
        id: 2175,
        airportCode: 'PXM',
        cityCode: 'PXM',
        countryCode: 'MX',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Puerto Escondido',
        airportNameEn: 'Puerto Escondido International Airport',
        cityNameVi: 'Puerto Escondido',
        cityNameEn: 'Puerto Escondido',
        countryNameVi: 'Mê hi cô',
        countryNameEn: 'MEXICO'
    },
    PBC: {
        id: 2174,
        airportCode: 'PBC',
        cityCode: 'PBC',
        countryCode: 'MX',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Hermanos Serdán',
        airportNameEn: 'Hermanos Serdán International Airport',
        cityNameVi: 'Puebla',
        cityNameEn: 'Puebla',
        countryNameVi: 'Mê hi cô',
        countryNameEn: 'MEXICO'
    },
    OAX: {
        id: 2173,
        airportCode: 'OAX',
        cityCode: 'OAX',
        countryCode: 'MX',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Xoxocotlán',
        airportNameEn: 'Xoxocotlán International Airport',
        cityNameVi: 'Oaxaca',
        cityNameEn: 'Oaxaca',
        countryNameVi: 'Mê hi cô',
        countryNameEn: 'MEXICO'
    },
    NLD: {
        id: 2172,
        airportCode: 'NLD',
        cityCode: 'NLD',
        countryCode: 'MX',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Quetzalcóatl',
        airportNameEn: 'Quetzalcóatl International Airport',
        cityNameVi: 'Nuevo Laredo',
        cityNameEn: 'Nuevo Laredo',
        countryNameVi: 'Mê hi cô',
        countryNameEn: 'MEXICO'
    },
    MTY: {
        id: 2171,
        airportCode: 'MTY',
        cityCode: 'MTY',
        countryCode: 'MX',
        continentCode: 'NA',
        airportNameVi: 'Sân bay General Mariano Escobedo',
        airportNameEn: 'General Mariano Escobedo International Airport',
        cityNameVi: 'Monterrey',
        cityNameEn: 'Monterrey',
        countryNameVi: 'Mê hi cô',
        countryNameEn: 'MEXICO'
    },
    MEX: {
        id: 2170,
        airportCode: 'MEX',
        cityCode: 'MEX',
        countryCode: 'MX',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Mexico City',
        airportNameEn: 'Mexico City International Airport',
        cityNameVi: 'Mexico City',
        cityNameEn: 'Mexico City',
        countryNameVi: 'Mê hi cô',
        countryNameEn: 'MEXICO'
    },
    MXL: {
        id: 2169,
        airportCode: 'MXL',
        cityCode: 'MXL',
        countryCode: 'MX',
        continentCode: 'NA',
        airportNameVi: 'Sân bay General Rodolfo Sánchez Taboada',
        airportNameEn: 'General Rodolfo Sánchez Taboada International Airport',
        cityNameVi: 'Mexicali',
        cityNameEn: 'Mexicali',
        countryNameVi: 'Mê hi cô',
        countryNameEn: 'MEXICO'
    },
    MID: {
        id: 2168,
        airportCode: 'MID',
        cityCode: 'MID',
        countryCode: 'MX',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Manuel Crescencio Rejón',
        airportNameEn: 'Manuel Crescencio Rejón International Airport',
        cityNameVi: 'Merida',
        cityNameEn: 'Merida',
        countryNameVi: 'Mê hi cô',
        countryNameEn: 'MEXICO'
    },
    MZT: {
        id: 2167,
        airportCode: 'MZT',
        cityCode: 'MZT',
        countryCode: 'MX',
        continentCode: 'NA',
        airportNameVi: 'Sân bay General Rafael Buelna',
        airportNameEn: 'General Rafael Buelna International Airport',
        cityNameVi: 'Mazatlan',
        cityNameEn: 'Mazatlan',
        countryNameVi: 'Mê hi cô',
        countryNameEn: 'MEXICO'
    },
    MAM: {
        id: 2166,
        airportCode: 'MAM',
        cityCode: 'MAM',
        countryCode: 'MX',
        continentCode: 'NA',
        airportNameVi: 'Sân bay General Servando Canales',
        airportNameEn: 'General Servando Canales International Airport',
        cityNameVi: 'Matamoros',
        cityNameEn: 'Matamoros',
        countryNameVi: 'Mê hi cô',
        countryNameEn: 'MEXICO'
    },
    LMM: {
        id: 2165,
        airportCode: 'LMM',
        cityCode: 'LMM',
        countryCode: 'MX',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Federal del Valle del Fuerte',
        airportNameEn: 'Federal del Valle del Fuerte International Airport',
        cityNameVi: 'Los Mochis',
        cityNameEn: 'Los Mochis',
        countryNameVi: 'Mê hi cô',
        countryNameEn: 'MEXICO'
    },
    LTO: {
        id: 2164,
        airportCode: 'LTO',
        cityCode: 'LTO',
        countryCode: 'MX',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Loreto',
        airportNameEn: 'Loreto International Airport',
        cityNameVi: 'Loreto',
        cityNameEn: 'Loreto',
        countryNameVi: 'Mê hi cô',
        countryNameEn: 'MEXICO'
    },
    BJX: {
        id: 2163,
        airportCode: 'BJX',
        cityCode: 'BJX',
        countryCode: 'MX',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Del Bajío',
        airportNameEn: 'Del Bajío International Airport ',
        cityNameVi: 'Leon',
        cityNameEn: 'Leon',
        countryNameVi: 'Mê hi cô',
        countryNameEn: 'MEXICO'
    },
    LAP: {
        id: 2162,
        airportCode: 'LAP',
        cityCode: 'LAP',
        countryCode: 'MX',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Manuel Márquez de León',
        airportNameEn: 'Manuel Márquez de León International Airport',
        cityNameVi: 'La Paz',
        cityNameEn: 'La Paz',
        countryNameVi: 'Mê hi cô',
        countryNameEn: 'MEXICO'
    },
    HMO: {
        id: 2161,
        airportCode: 'HMO',
        cityCode: 'HMO',
        countryCode: 'MX',
        continentCode: 'NA',
        airportNameVi: 'Sân bay General Ignacio Pesqueira Garcia',
        airportNameEn: 'General Ignacio Pesqueira Garcia International Airport',
        cityNameVi: 'Hermosillo',
        cityNameEn: 'Hermosillo',
        countryNameVi: 'Mê hi cô',
        countryNameEn: 'MEXICO'
    },
    GDL: {
        id: 2160,
        airportCode: 'GDL',
        cityCode: 'GDL',
        countryCode: 'MX',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Don Miguel Hidalgo y Costilla',
        airportNameEn: 'Don Miguel Hidalgo y Costilla International Airport',
        cityNameVi: 'Guadalajara',
        cityNameEn: 'Guadalajara',
        countryNameVi: 'Mê hi cô',
        countryNameEn: 'MEXICO'
    },
    DGO: {
        id: 2159,
        airportCode: 'DGO',
        cityCode: 'DGO',
        countryCode: 'MX',
        continentCode: 'NA',
        airportNameVi: 'Sân bay General Guadalupe Victoria',
        airportNameEn: 'General Guadalupe Victoria International Airport',
        cityNameVi: 'Durango',
        cityNameEn: 'Durango',
        countryNameVi: 'Mê hi cô',
        countryNameEn: 'MEXICO'
    },
    CUL: {
        id: 2158,
        airportCode: 'CUL',
        cityCode: 'CUL',
        countryCode: 'MX',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Federal de Bachigualato',
        airportNameEn: 'Federal de Bachigualato International Airport',
        cityNameVi: 'Culiacan',
        cityNameEn: 'Culiacan',
        countryNameVi: 'Mê hi cô',
        countryNameEn: 'MEXICO'
    },
    CZM: {
        id: 2157,
        airportCode: 'CZM',
        cityCode: 'CZM',
        countryCode: 'MX',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Cozumel',
        airportNameEn: 'Cozumel International Airport',
        cityNameVi: 'Cozumel',
        cityNameEn: 'Cozumel',
        countryNameVi: 'Mê hi cô',
        countryNameEn: 'MEXICO'
    },
    CLQ: {
        id: 2156,
        airportCode: 'CLQ',
        cityCode: 'CLQ',
        countryCode: 'MX',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Licenciado Miguel de la Madrid',
        airportNameEn: 'Licenciado Miguel de la Madrid Airport',
        cityNameVi: 'Colima',
        cityNameEn: 'Colima',
        countryNameVi: 'Mê hi cô',
        countryNameEn: 'MEXICO'
    },
    CEN: {
        id: 2155,
        airportCode: 'CEN',
        cityCode: 'CEN',
        countryCode: 'MX',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Ciudad Obregón',
        airportNameEn: 'Ciudad Obregón International Airport',
        cityNameVi: 'Ciudad Obregon',
        cityNameEn: 'Ciudad Obregon',
        countryNameVi: 'Mê hi cô',
        countryNameEn: 'MEXICO'
    },
    CJS: {
        id: 2154,
        airportCode: 'CJS',
        cityCode: 'CJS',
        countryCode: 'MX',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Abraham González',
        airportNameEn: 'Abraham González International Airport',
        cityNameVi: 'Ciudad Juarez',
        cityNameEn: 'Ciudad Juarez',
        countryNameVi: 'Mê hi cô',
        countryNameEn: 'MEXICO'
    },
    CME: {
        id: 2153,
        airportCode: 'CME',
        cityCode: 'CME',
        countryCode: 'MX',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Ciudad del Carmen',
        airportNameEn: 'Ciudad del Carmen International Airport',
        cityNameVi: 'Ciudad Del Carmen',
        cityNameEn: 'Ciudad Del Carmen',
        countryNameVi: 'Mê hi cô',
        countryNameEn: 'MEXICO'
    },
    CUU: {
        id: 2152,
        airportCode: 'CUU',
        cityCode: 'CUU',
        countryCode: 'MX',
        continentCode: 'NA',
        airportNameVi: 'Sân bay General Roberto Fierro Villalobos',
        airportNameEn: 'General Roberto Fierro Villalobos International Airport',
        cityNameVi: 'Chihuahua',
        cityNameEn: 'Chihuahua',
        countryNameVi: 'Mê hi cô',
        countryNameEn: 'MEXICO'
    },
    CUN: {
        id: 2151,
        airportCode: 'CUN',
        cityCode: 'CUN',
        countryCode: 'MX',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Cancún',
        airportNameEn: 'Cancún International Airport',
        cityNameVi: 'Cancun',
        cityNameEn: 'Cancun',
        countryNameVi: 'Mê hi cô',
        countryNameEn: 'MEXICO'
    },
    CPE: {
        id: 2150,
        airportCode: 'CPE',
        cityCode: 'CPE',
        countryCode: 'MX',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Ing. Alberto Acuña Ongay',
        airportNameEn: 'Ing. Alberto Acuña Ongay International Airport',
        cityNameVi: 'Campeche',
        cityNameEn: 'Campeche',
        countryNameVi: 'Mê hi cô',
        countryNameEn: 'MEXICO'
    },
    AGU: {
        id: 2149,
        airportCode: 'AGU',
        cityCode: 'AGU',
        countryCode: 'MX',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Lic. Jesús Terán Peredo',
        airportNameEn: 'Lic. Jesús Terán Peredo International Airport',
        cityNameVi: 'Aguascalientes',
        cityNameEn: 'Aguascalientes',
        countryNameVi: 'Mê hi cô',
        countryNameEn: 'MEXICO'
    },
    ACA: {
        id: 2148,
        airportCode: 'ACA',
        cityCode: 'ACA',
        countryCode: 'MX',
        continentCode: 'NA',
        airportNameVi: 'Sân bay General Juan N. Álvarez',
        airportNameEn: 'General Juan N. Álvarez International Airport',
        cityNameVi: 'Acapulco',
        cityNameEn: 'Acapulco',
        countryNameVi: 'Mê hi cô',
        countryNameEn: 'MEXICO'
    },
    DZA: {
        id: 2147,
        airportCode: 'DZA',
        cityCode: 'DZA',
        countryCode: 'YT',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Dzaoudzi Pamandzi',
        airportNameEn: 'Dzaoudzi Pamandzi International Airport',
        cityNameVi: 'Dzaoudzi',
        cityNameEn: 'Dzaoudzi',
        countryNameVi: 'Mayotte',
        countryNameEn: 'Mayotte'
    },
    RRG: {
        id: 2146,
        airportCode: 'RRG',
        cityCode: 'RRG',
        countryCode: 'MU',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Sir Gaëtan Duval',
        airportNameEn: 'Sir Gaëtan Duval Airport',
        cityNameVi: 'Rodrigues Island',
        cityNameEn: 'Rodrigues Island',
        countryNameVi: 'Mauritius',
        countryNameEn: 'Mauritius'
    },
    MRU: {
        id: 2145,
        airportCode: 'MRU',
        cityCode: 'MRU',
        countryCode: 'MU',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Sir Seewoosagur Ramgoolam',
        airportNameEn: 'Sir Seewoosagur Ramgoolam International Airport',
        cityNameVi: 'Mauritius',
        cityNameEn: 'Mauritius',
        countryNameVi: 'Mauritius',
        countryNameEn: 'Mauritius'
    },
    NKC: {
        id: 2144,
        airportCode: 'NKC',
        cityCode: 'NKC',
        countryCode: 'MR',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Nouakchott',
        airportNameEn: 'Nouakchott International Airport',
        cityNameVi: 'Nouakchott',
        cityNameEn: 'Nouakchott',
        countryNameVi: 'Mauritania',
        countryNameEn: 'Mauritania'
    },
    FDF: {
        id: 2143,
        airportCode: 'FDF',
        cityCode: 'FDF',
        countryCode: 'MQ',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Martinique Aimé Césaire',
        airportNameEn: 'Martinique Aimé Césaire International Airport',
        cityNameVi: 'Fort De France',
        cityNameEn: 'Ft De France',
        countryNameVi: 'Martinique',
        countryNameEn: 'Martinique'
    },
    KWA: {
        id: 2142,
        airportCode: 'KWA',
        cityCode: 'KWA',
        countryCode: 'MH',
        continentCode: 'NA',
        airportNameVi: 'Bucholz Army Airfield',
        airportNameEn: 'Bucholz Army Airfield',
        cityNameVi: 'Kwajalein',
        cityNameEn: 'Kwajalein',
        countryNameVi: 'Marshall Islands',
        countryNameEn: 'MARSHALL ISLANDS'
    },
    MAJ: {
        id: 2141,
        airportCode: 'MAJ',
        cityCode: 'MAJ',
        countryCode: 'MH',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Marshall Islands',
        airportNameEn: 'Marshall Islands International Airport ',
        cityNameVi: 'Majuro',
        cityNameEn: 'Majuro',
        countryNameVi: 'Marshall Islands',
        countryNameEn: 'MARSHALL ISLANDS'
    },
    HLN: {
        id: 2140,
        airportCode: 'HLN',
        cityCode: 'HLN',
        countryCode: 'MT',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Helena Regional',
        airportNameEn: 'Helena Regional Airport',
        cityNameVi: 'Helena',
        cityNameEn: 'Helena',
        countryNameVi: 'Malta',
        countryNameEn: 'Malta'
    },
    GGW: {
        id: 2139,
        airportCode: 'GGW',
        cityCode: 'GGW',
        countryCode: 'MT',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Glasgow',
        airportNameEn: 'Glasgow Airport ',
        cityNameVi: 'Glasgow',
        cityNameEn: 'Glasgow',
        countryNameVi: 'Malta',
        countryNameEn: 'Malta'
    },
    MLA: {
        id: 2138,
        airportCode: 'MLA',
        cityCode: 'MLA',
        countryCode: 'MT',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Malta',
        airportNameEn: 'Malta International Airport ',
        cityNameVi: 'Malta',
        cityNameEn: 'Malta',
        countryNameVi: 'Malta',
        countryNameEn: 'Malta'
    },
    BKO: {
        id: 2137,
        airportCode: 'BKO',
        cityCode: 'BKO',
        countryCode: 'ML',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Senou',
        airportNameEn: 'Senou International Airport',
        cityNameVi: 'Bamako',
        cityNameEn: 'Bamako',
        countryNameVi: 'Mali',
        countryNameEn: 'Mali'
    },
    MLE: {
        id: 2136,
        airportCode: 'MLE',
        cityCode: 'MLE',
        countryCode: 'MV',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Malé',
        airportNameEn: 'Malé International Airport',
        cityNameVi: 'Male',
        cityNameEn: 'Male',
        countryNameVi: 'Maldives',
        countryNameEn: 'Maldives'
    },
    PLM: {
        id: 2135,
        airportCode: 'PLM',
        cityCode: 'PLM',
        countryCode: 'MY',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Sultan Mahmud Badaruddin II',
        airportNameEn: 'Sultan Mahmud Badaruddin II Airport',
        cityNameVi: 'Palembang',
        cityNameEn: 'Palembang',
        countryNameVi: 'Malaysia',
        countryNameEn: 'Malaysia'
    },
    SZB: {
        id: 2134,
        airportCode: 'SZB',
        cityCode: 'SZB',
        countryCode: 'MY',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Sultan Abdul Aziz Shah',
        airportNameEn: 'Sultan Abdul Aziz Shah Airport',
        cityNameVi: 'Kuala Lumpur',
        cityNameEn: 'Kuala Lumpur',
        countryNameVi: 'Malaysia',
        countryNameEn: 'Malaysia'
    },
    SBW: {
        id: 2133,
        airportCode: 'SBW',
        cityCode: 'SBW',
        countryCode: 'MY',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Sibu',
        airportNameEn: 'Sibu Airport',
        cityNameVi: 'Sibu',
        cityNameEn: 'Sibu',
        countryNameVi: 'Malaysia',
        countryNameEn: 'Malaysia'
    },
    MKM: {
        id: 2132,
        airportCode: 'MKM',
        cityCode: 'MKM',
        countryCode: 'MY',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Mukah',
        airportNameEn: 'Mukah Airport',
        cityNameVi: 'Mukah',
        cityNameEn: 'Mukah',
        countryNameVi: 'Malaysia',
        countryNameEn: 'Malaysia'
    },
    MUR: {
        id: 2131,
        airportCode: 'MUR',
        cityCode: 'MUR',
        countryCode: 'MY',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Marudi',
        airportNameEn: 'Marudi Airport',
        cityNameVi: 'Marudi',
        cityNameEn: 'Marudi',
        countryNameVi: 'Malaysia',
        countryNameEn: 'Malaysia'
    },
    LGL: {
        id: 2130,
        airportCode: 'LGL',
        cityCode: 'LGL',
        countryCode: 'MY',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Long Lellang',
        airportNameEn: 'Long Lellang Airport',
        cityNameVi: 'Long Lellang',
        cityNameEn: 'Long Lellang',
        countryNameVi: 'Malaysia',
        countryNameEn: 'Malaysia'
    },
    LWY: {
        id: 2129,
        airportCode: 'LWY',
        cityCode: 'LWY',
        countryCode: 'MY',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Lawas',
        airportNameEn: 'Lawas Airport',
        cityNameVi: 'Lawas',
        cityNameEn: 'Lawas',
        countryNameVi: 'Malaysia',
        countryNameEn: 'Malaysia'
    },
    KUD: {
        id: 2128,
        airportCode: 'KUD',
        cityCode: 'KUD',
        countryCode: 'MY',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Kudat',
        airportNameEn: 'Kudat Airport',
        cityNameVi: 'Kudat',
        cityNameEn: 'Kudat',
        countryNameVi: 'Malaysia',
        countryNameEn: 'Malaysia'
    },
    KTE: {
        id: 2127,
        airportCode: 'KTE',
        cityCode: 'KTE',
        countryCode: 'MY',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Kerteh',
        airportNameEn: 'Kerteh Airport',
        cityNameVi: 'Kerteh',
        cityNameEn: 'Kerteh',
        countryNameVi: 'Malaysia',
        countryNameEn: 'Malaysia'
    },
    BBN: {
        id: 2126,
        airportCode: 'BBN',
        cityCode: 'BBN',
        countryCode: 'MY',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Bario',
        airportNameEn: 'Bario Airport',
        cityNameVi: 'Bario',
        cityNameEn: 'Bario',
        countryNameVi: 'Malaysia',
        countryNameEn: 'Malaysia'
    },
    BKM: {
        id: 2125,
        airportCode: 'BKM',
        cityCode: 'BKM',
        countryCode: 'MY',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Bakalalan',
        airportNameEn: 'Bakalalan Airport',
        cityNameVi: 'Bakalalan',
        cityNameEn: 'Bakalalan',
        countryNameVi: 'Malaysia',
        countryNameEn: 'Malaysia'
    },
    TOD: {
        id: 2124,
        airportCode: 'TOD',
        cityCode: 'TOD',
        countryCode: 'MY',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Tioman',
        airportNameEn: 'Tioman Airport',
        cityNameVi: 'Tioman',
        cityNameEn: 'Tioman',
        countryNameVi: 'Malaysia',
        countryNameEn: 'Malaysia'
    },
    TWU: {
        id: 2123,
        airportCode: 'TWU',
        cityCode: 'TWU',
        countryCode: 'MY',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Tawau',
        airportNameEn: 'Tawau Airport',
        cityNameVi: 'Tawau',
        cityNameEn: 'Tawau',
        countryNameVi: 'Malaysia',
        countryNameEn: 'Malaysia'
    },
    SDK: {
        id: 2122,
        airportCode: 'SDK',
        cityCode: 'SDK',
        countryCode: 'MY',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Sandakan',
        airportNameEn: 'Sandakan Airport',
        cityNameVi: 'Sandakan',
        cityNameEn: 'Sandakan',
        countryNameVi: 'Malaysia',
        countryNameEn: 'Malaysia'
    },
    PEN: {
        id: 2121,
        airportCode: 'PEN',
        cityCode: 'PEN',
        countryCode: 'MY',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Penang',
        airportNameEn: 'Penang International Airport',
        cityNameVi: 'Penang',
        cityNameEn: 'Penang',
        countryNameVi: 'Malaysia',
        countryNameEn: 'Malaysia'
    },
    MZV: {
        id: 2120,
        airportCode: 'MZV',
        cityCode: 'MZV',
        countryCode: 'MY',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Mulu',
        airportNameEn: 'Mulu Airport',
        cityNameVi: 'Mulu, Sarawak',
        cityNameEn: 'Mulu, Sarawak',
        countryNameVi: 'Malaysia',
        countryNameEn: 'Malaysia'
    },
    MYY: {
        id: 2119,
        airportCode: 'MYY',
        cityCode: 'MYY',
        countryCode: 'MY',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Miri',
        airportNameEn: 'Miri International Airport',
        cityNameVi: 'Miri',
        cityNameEn: 'Miri',
        countryNameVi: 'Malaysia',
        countryNameEn: 'Malaysia'
    },
    LMN: {
        id: 2118,
        airportCode: 'LMN',
        cityCode: 'LMN',
        countryCode: 'MY',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Limbang',
        airportNameEn: 'Limbang Airport',
        cityNameVi: 'Limbang',
        cityNameEn: 'Limbang',
        countryNameVi: 'Malaysia',
        countryNameEn: 'Malaysia'
    },
    LGK: {
        id: 2117,
        airportCode: 'LGK',
        cityCode: 'LGK',
        countryCode: 'MY',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Langkawi',
        airportNameEn: 'Langkawi International Airport',
        cityNameVi: 'Langkawi',
        cityNameEn: 'Langkawi',
        countryNameVi: 'Malaysia',
        countryNameEn: 'Malaysia'
    },
    LDU: {
        id: 2116,
        airportCode: 'LDU',
        cityCode: 'LDU',
        countryCode: 'MY',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Lahad Datu',
        airportNameEn: 'Lahad Datu Airport',
        cityNameVi: 'Lahad Datu',
        cityNameEn: 'Lahad Datu',
        countryNameVi: 'Malaysia',
        countryNameEn: 'Malaysia'
    },
    LBU: {
        id: 2115,
        airportCode: 'LBU',
        cityCode: 'LBU',
        countryCode: 'MY',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Labuan',
        airportNameEn: 'Labuan Airport',
        cityNameVi: 'Labuan',
        cityNameEn: 'Labuan',
        countryNameVi: 'Malaysia',
        countryNameEn: 'Malaysia'
    },
    KCH: {
        id: 2114,
        airportCode: 'KCH',
        cityCode: 'KCH',
        countryCode: 'MY',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Kuching',
        airportNameEn: 'Kuching International Airport',
        cityNameVi: 'Kuching',
        cityNameEn: 'Kuching',
        countryNameVi: 'Malaysia',
        countryNameEn: 'Malaysia'
    },
    KUA: {
        id: 2113,
        airportCode: 'KUA',
        cityCode: 'KUA',
        countryCode: 'MY',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Sultan Haji Ahmad Shah',
        airportNameEn: 'Sultan Haji Ahmad Shah Airport ',
        cityNameVi: 'Kuantan',
        cityNameEn: 'Kuantan',
        countryNameVi: 'Malaysia',
        countryNameEn: 'Malaysia'
    },
    BKI: {
        id: 2112,
        airportCode: 'BKI',
        cityCode: 'BKI',
        countryCode: 'MY',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Kota Kinabalu',
        airportNameEn: 'Kota Kinabalu International Airport',
        cityNameVi: 'Kota Kinabalu',
        cityNameEn: 'Kota Kinabalu',
        countryNameVi: 'Malaysia',
        countryNameEn: 'Malaysia'
    },
    KBR: {
        id: 2111,
        airportCode: 'KBR',
        cityCode: 'KBR',
        countryCode: 'MY',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Sultan Ismail Petra',
        airportNameEn: 'Sultan Ismail Petra Airport',
        cityNameVi: 'Kota Bharu',
        cityNameEn: 'Kota Bharu',
        countryNameVi: 'Malaysia',
        countryNameEn: 'Malaysia'
    },
    JHB: {
        id: 2110,
        airportCode: 'JHB',
        cityCode: 'JHB',
        countryCode: 'MY',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Sultan Ismail',
        airportNameEn: 'Sultan Ismail International Airport',
        cityNameVi: 'Johor Bahru',
        cityNameEn: 'Johor Bahru',
        countryNameVi: 'Malaysia',
        countryNameEn: 'Malaysia'
    },
    IPH: {
        id: 2109,
        airportCode: 'IPH',
        cityCode: 'IPH',
        countryCode: 'MY',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Sultan Azlan Shah',
        airportNameEn: 'Sultan Azlan Shah Airport',
        cityNameVi: 'Ipoh',
        cityNameEn: 'Ipoh',
        countryNameVi: 'Malaysia',
        countryNameEn: 'Malaysia'
    },
    BTU: {
        id: 2108,
        airportCode: 'BTU',
        cityCode: 'BTU',
        countryCode: 'MY',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Bintulu',
        airportNameEn: 'Bintulu Airport',
        cityNameVi: 'Bintulu',
        cityNameEn: 'Bintulu',
        countryNameVi: 'Malaysia',
        countryNameEn: 'Malaysia'
    },
    AOR: {
        id: 2107,
        airportCode: 'AOR',
        cityCode: 'AOR',
        countryCode: 'MY',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Sultan Abdul Halim',
        airportNameEn: 'Sultan Abdul Halim Airport',
        cityNameVi: 'Alor Setar',
        cityNameEn: 'Alor Setar',
        countryNameVi: 'Malaysia',
        countryNameEn: 'Malaysia'
    },
    LLW: {
        id: 2106,
        airportCode: 'LLW',
        cityCode: 'LLW',
        countryCode: 'MW',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Lilongwe',
        airportNameEn: 'Lilongwe International Airport ',
        cityNameVi: 'Lilongwe',
        cityNameEn: 'Lilongwe',
        countryNameVi: 'Malawi',
        countryNameEn: 'Malawi'
    },
    BLZ: {
        id: 2105,
        airportCode: 'BLZ',
        cityCode: 'BLZ',
        countryCode: 'MW',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Chileka',
        airportNameEn: 'Chileka International Airport',
        cityNameVi: 'Blantyre',
        cityNameEn: 'Blantyre',
        countryNameVi: 'Malawi',
        countryNameEn: 'Malawi'
    },
    FTU: {
        id: 2104,
        airportCode: 'FTU',
        cityCode: 'FTU',
        countryCode: 'MG',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Tôlanaro',
        airportNameEn: 'Tôlanaro Airport ',
        cityNameVi: 'Fort Dauphin',
        cityNameEn: 'Fort Dauphin',
        countryNameVi: 'Madagascar',
        countryNameEn: 'Madagascar'
    },
    TMM: {
        id: 2103,
        airportCode: 'TMM',
        cityCode: 'TMM',
        countryCode: 'MG',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Toamasina',
        airportNameEn: 'Toamasina Airport',
        cityNameVi: 'Tamatave',
        cityNameEn: 'Tamatave',
        countryNameVi: 'Madagascar',
        countryNameEn: 'Madagascar'
    },
    SMS: {
        id: 2102,
        airportCode: 'SMS',
        cityCode: 'SMS',
        countryCode: 'MG',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Sainte Marie',
        airportNameEn: 'Sainte Marie Airport',
        cityNameVi: 'Saint Marie',
        cityNameEn: 'Saint Marie',
        countryNameVi: 'Madagascar',
        countryNameEn: 'Madagascar'
    },
    TNR: {
        id: 2101,
        airportCode: 'TNR',
        cityCode: 'TNR',
        countryCode: 'MG',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Ivato',
        airportNameEn: 'Ivato Airport',
        cityNameVi: 'Tananarive',
        cityNameEn: 'Tananarive',
        countryNameVi: 'Madagascar',
        countryNameEn: 'Madagascar'
    },
    MJN: {
        id: 2100,
        airportCode: 'MJN',
        cityCode: 'MJN',
        countryCode: 'MG',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Amborovy',
        airportNameEn: 'Amborovy Airport ',
        cityNameVi: 'Majunga',
        cityNameEn: 'Majunga',
        countryNameVi: 'Madagascar',
        countryNameEn: 'Madagascar'
    },
    DIE: {
        id: 2099,
        airportCode: 'DIE',
        cityCode: 'DIE',
        countryCode: 'MG',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Arrachart',
        airportNameEn: 'Arrachart Airport',
        cityNameVi: 'Antsiranana',
        cityNameEn: 'Antsiranana',
        countryNameVi: 'Madagascar',
        countryNameEn: 'Madagascar'
    },
    SKP: {
        id: 2098,
        airportCode: 'SKP',
        cityCode: 'SKP',
        countryCode: 'MK',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Skopje',
        airportNameEn: 'Skopje Airport',
        cityNameVi: 'Skopje',
        cityNameEn: 'Skopje',
        countryNameVi: 'Macedonia',
        countryNameEn: 'Macedonia F Y R O M'
    },
    OHD: {
        id: 2097,
        airportCode: 'OHD',
        cityCode: 'OHD',
        countryCode: 'MK',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Ohrid',
        airportNameEn: 'Ohrid Airport',
        cityNameVi: 'Ohrid',
        cityNameEn: 'Ohrid',
        countryNameVi: 'Macedonia',
        countryNameEn: 'Macedonia F Y R O M'
    },
    SGF: {
        id: 2096,
        airportCode: 'SGF',
        cityCode: 'SGF',
        countryCode: 'MO',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Springfield-Branson National',
        airportNameEn: 'Springfield-Branson National Airport',
        cityNameVi: 'Springfield',
        cityNameEn: 'Springfield',
        countryNameVi: 'Macau',
        countryNameEn: 'Macau'
    },
    MFM: {
        id: 2095,
        airportCode: 'MFM',
        cityCode: 'MFM',
        countryCode: 'MO',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Macau',
        airportNameEn: 'Macau International Airport',
        cityNameVi: 'Macau',
        cityNameEn: 'Macau',
        countryNameVi: 'Macau',
        countryNameEn: 'Macau'
    },
    COU: {
        id: 2094,
        airportCode: 'COU',
        cityCode: 'COU',
        countryCode: 'MO',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Columbia Regional',
        airportNameEn: 'Columbia Regional Airport',
        cityNameVi: 'Columbia',
        cityNameEn: 'Columbia',
        countryNameVi: 'Macau',
        countryNameEn: 'Macau'
    },
    LUX: {
        id: 2093,
        airportCode: 'LUX',
        cityCode: 'LUX',
        countryCode: 'LU',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Luxembourg Findel',
        airportNameEn: 'Luxembourg Findel Airport',
        cityNameVi: 'Lu-xăm-bua',
        cityNameEn: 'Luxembourg',
        countryNameVi: 'Lúc xăm bua',
        countryNameEn: 'Luxembourg'
    },
    VNO: {
        id: 2092,
        airportCode: 'VNO',
        cityCode: 'VNO',
        countryCode: 'LT',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Vilnius',
        airportNameEn: 'Vilnius International Airport',
        cityNameVi: 'Vilnius',
        cityNameEn: 'Vilnius',
        countryNameVi: 'Lithuania',
        countryNameEn: 'Lithuania'
    },
    PLQ: {
        id: 2091,
        airportCode: 'PLQ',
        cityCode: 'PLQ',
        countryCode: 'LT',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Palanga',
        airportNameEn: 'Palanga International Airport',
        cityNameVi: 'Klaipeda/Palanga',
        cityNameEn: 'Klaipeda/Palanga',
        countryNameVi: 'Lithuania',
        countryNameEn: 'Lithuania'
    },
    KUN: {
        id: 2090,
        airportCode: 'KUN',
        cityCode: 'KUN',
        countryCode: 'LT',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Kaunas',
        airportNameEn: 'Kaunas International Airport',
        cityNameVi: 'Kaunas',
        cityNameEn: 'Kaunas',
        countryNameVi: 'Lithuania',
        countryNameEn: 'Lithuania'
    },
    TIP: {
        id: 2089,
        airportCode: 'TIP',
        cityCode: 'TIP',
        countryCode: 'LY',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Tripoli',
        airportNameEn: 'Tripoli International Airport',
        cityNameVi: 'Tripoli',
        cityNameEn: 'Tripoli',
        countryNameVi: 'Libi',
        countryNameEn: 'Libya'
    },
    BEN: {
        id: 2088,
        airportCode: 'BEN',
        cityCode: 'BEN',
        countryCode: 'LY',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Benina',
        airportNameEn: 'Benina International Airport',
        cityNameVi: 'Benghazi',
        cityNameEn: 'Benghazi',
        countryNameVi: 'Libi',
        countryNameEn: 'Libya'
    },
    ROB: {
        id: 2087,
        airportCode: 'ROB',
        cityCode: 'MLW',
        countryCode: 'LR',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Roberts',
        airportNameEn: 'Roberts International Airport',
        cityNameVi: 'Monrovia',
        cityNameEn: 'Monrovia',
        countryNameVi: 'Liberia',
        countryNameEn: 'Liberia'
    },
    MSU: {
        id: 2086,
        airportCode: 'MSU',
        cityCode: 'MSU',
        countryCode: 'LS',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Moshoeshoe',
        airportNameEn: 'Moshoeshoe International Airport',
        cityNameVi: 'Maseru',
        cityNameEn: 'Maseru',
        countryNameVi: 'Lesotho',
        countryNameEn: 'Lesotho'
    },
    BEY: {
        id: 2085,
        airportCode: 'BEY',
        cityCode: 'BEY',
        countryCode: 'LB',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Beirut Air Base/Beirut Rafic Hariri',
        airportNameEn: 'Beirut Air Base/Beirut Rafic Hariri International Airport',
        cityNameVi: 'Beirut',
        cityNameEn: 'Beirut',
        countryNameVi: 'Li băng',
        countryNameEn: 'Lebanon'
    },
    RIX: {
        id: 2084,
        airportCode: 'RIX',
        cityCode: 'RIX',
        countryCode: 'LV',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Riga',
        airportNameEn: 'Riga International Airport',
        cityNameVi: 'Riga',
        cityNameEn: 'Riga',
        countryNameVi: 'Latvia',
        countryNameEn: 'Latvia'
    },
    XKH: {
        id: 2083,
        airportCode: 'XKH',
        cityCode: 'XKH',
        countryCode: 'LA',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Xieng Khouang',
        airportNameEn: 'Xieng Khouang Airport',
        cityNameVi: 'Xiêng Khoảng',
        cityNameEn: 'Xieng Khouang',
        countryNameVi: 'Lào',
        countryNameEn: "Lao, People's Dem. Rep."
    },
    VTE: {
        id: 2082,
        airportCode: 'VTE',
        cityCode: 'VTE',
        countryCode: 'LA',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Wattay',
        airportNameEn: 'Wattay International Airport',
        cityNameVi: 'Viên Chăn',
        cityNameEn: 'Vientiane',
        countryNameVi: 'Lào',
        countryNameEn: "Lao, People's Dem. Rep."
    },
    LPQ: {
        id: 2081,
        airportCode: 'LPQ',
        cityCode: 'LPQ',
        countryCode: 'LA',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Luang Prabang',
        airportNameEn: 'Luang Prabang International Airport',
        cityNameVi: 'Luông pra băng',
        cityNameEn: 'Luang Prabang',
        countryNameVi: 'Lào',
        countryNameEn: "Lao, People's Dem. Rep."
    },
    ARA: {
        id: 2080,
        airportCode: 'ARA',
        cityCode: 'LFT',
        countryCode: 'LA',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Acadiana Regional',
        airportNameEn: 'Acadiana Regional Airport',
        cityNameVi: 'Lafayette',
        cityNameEn: 'Lafayette',
        countryNameVi: 'Lào',
        countryNameEn: "Lao, People's Dem. Rep."
    },
    ESF: {
        id: 2079,
        airportCode: 'ESF',
        cityCode: 'AEX',
        countryCode: 'LA',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Esler Regional',
        airportNameEn: 'Esler Regional Airport',
        cityNameVi: 'Alexandria',
        cityNameEn: 'Alexandria',
        countryNameVi: 'Lào',
        countryNameEn: "Lao, People's Dem. Rep."
    },
    FRU: {
        id: 2078,
        airportCode: 'FRU',
        cityCode: 'FRU',
        countryCode: 'KG',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Manas',
        airportNameEn: 'Manas International Airport',
        cityNameVi: 'Bishkek',
        cityNameEn: 'Bishkek',
        countryNameVi: 'Kyrgyzstan',
        countryNameEn: 'Kyrgyzstan'
    },
    KWI: {
        id: 2077,
        airportCode: 'KWI',
        cityCode: 'KWI',
        countryCode: 'KW',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Kuwait',
        airportNameEn: 'Kuwait International Airport',
        cityNameVi: 'Cô-oét',
        cityNameEn: 'Kuwait',
        countryNameVi: 'Cô oét',
        countryNameEn: 'Kuwait'
    },
    FNJ: {
        id: 2076,
        airportCode: 'FNJ',
        cityCode: 'FNJ',
        countryCode: 'KP',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Pyongyang Sunan',
        airportNameEn: 'Pyongyang Sunan International Airport',
        cityNameVi: 'Pyongyang',
        cityNameEn: 'Pyongyang',
        countryNameVi: 'Triều tiên',
        countryNameEn: 'Korea (North)'
    },
    JSP: {
        id: 2075,
        airportCode: 'JSP',
        cityCode: 'JSP',
        countryCode: 'KP',
        continentCode: 'AS',
        airportNameVi: 'Sogwipo Heliport',
        airportNameEn: 'Sogwipo Heliport',
        cityNameVi: 'Jeju City',
        cityNameEn: 'Jeju City',
        countryNameVi: 'Triều tiên',
        countryNameEn: 'Korea (North)'
    },
    CJJ: {
        id: 2074,
        airportCode: 'CJJ',
        cityCode: 'CJJ',
        countryCode: 'KR',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Cheong Ju',
        airportNameEn: 'Cheong Ju International Airport',
        cityNameVi: 'Cheongju',
        cityNameEn: 'Cheongju',
        countryNameVi: 'Hàn quốc',
        countryNameEn: 'Korea'
    },
    ICN: {
        id: 2073,
        airportCode: 'ICN',
        cityCode: 'SEL',
        countryCode: 'KR',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Incheon',
        airportNameEn: 'Incheon International Airport',
        cityNameVi: 'Seoul',
        cityNameEn: 'Seoul',
        countryNameVi: 'Hàn quốc',
        countryNameEn: 'Korea'
    },
    GMP: {
        id: 2072,
        airportCode: 'GMP',
        cityCode: 'SEL',
        countryCode: 'KR',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Gimpo',
        airportNameEn: 'Gimpo International Airport ',
        cityNameVi: 'Seoul',
        cityNameEn: 'Seoul',
        countryNameVi: 'Hàn quốc',
        countryNameEn: 'Korea'
    },
    PUS: {
        id: 2070,
        airportCode: 'PUS',
        cityCode: 'PUS',
        countryCode: 'KR',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Gimhae',
        airportNameEn: 'Gimhae International Airport',
        cityNameVi: 'Pusan',
        cityNameEn: 'Pusan',
        countryNameVi: 'Hàn quốc',
        countryNameEn: 'Korea'
    },
    KPO: {
        id: 2069,
        airportCode: 'KPO',
        cityCode: 'KPO',
        countryCode: 'KR',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Pohang',
        airportNameEn: 'Pohang Airport',
        cityNameVi: 'Pohang',
        cityNameEn: 'Pohang',
        countryNameVi: 'Hàn quốc',
        countryNameEn: 'Korea'
    },
    KWJ: {
        id: 2068,
        airportCode: 'KWJ',
        cityCode: 'KWJ',
        countryCode: 'KR',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Gwangju',
        airportNameEn: 'Gwangju Airport',
        cityNameVi: 'Kwangju',
        cityNameEn: 'Kwangju',
        countryNameVi: 'Hàn quốc',
        countryNameEn: 'Korea'
    },
    KUV: {
        id: 2067,
        airportCode: 'KUV',
        cityCode: 'KUV',
        countryCode: 'KR',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Gunsan',
        airportNameEn: 'Gunsan Airport',
        cityNameVi: 'Kunsan',
        cityNameEn: 'Kunsan',
        countryNameVi: 'Hàn quốc',
        countryNameEn: 'Korea'
    },
    CJU: {
        id: 2066,
        airportCode: 'CJU',
        cityCode: 'CJU',
        countryCode: 'KR',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Jeju',
        airportNameEn: 'Jeju International Airport',
        cityNameVi: 'Jeju City',
        cityNameEn: 'Jeju City',
        countryNameVi: 'Hàn quốc',
        countryNameEn: 'Korea'
    },
    TAE: {
        id: 2065,
        airportCode: 'TAE',
        cityCode: 'TAE',
        countryCode: 'KR',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Daegu',
        airportNameEn: 'Daegu International Airport',
        cityNameVi: 'Daegu',
        cityNameEn: 'Daegu',
        countryNameVi: 'Hàn quốc',
        countryNameEn: 'Korea'
    },
    HIN: {
        id: 2064,
        airportCode: 'HIN',
        cityCode: 'HIN',
        countryCode: 'KR',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Sacheon',
        airportNameEn: 'Sacheon Airport / Sacheon Air Base',
        cityNameVi: 'Chinju',
        cityNameEn: 'Chinju',
        countryNameVi: 'Hàn quốc',
        countryNameEn: 'Korea'
    },
    TRW: {
        id: 2063,
        airportCode: 'TRW',
        cityCode: 'TRW',
        countryCode: 'KI',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Bonriki',
        airportNameEn: 'Bonriki International Airport',
        cityNameVi: 'Tarawa',
        cityNameEn: 'Tarawa',
        countryNameVi: 'Kiribati',
        countryNameEn: 'Kiribati'
    },
    CXI: {
        id: 2062,
        airportCode: 'CXI',
        cityCode: 'CXI',
        countryCode: 'KI',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Cassidy',
        airportNameEn: 'Cassidy International Airport',
        cityNameVi: 'Christmas Island',
        cityNameEn: 'Christmas Island',
        countryNameVi: 'Kiribati',
        countryNameEn: 'Kiribati'
    },
    NYK: {
        id: 2061,
        airportCode: 'NYK',
        cityCode: 'NYK',
        countryCode: 'KE',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Nanyuki',
        airportNameEn: 'Nanyuki Airport',
        cityNameVi: 'Nanyuki',
        cityNameEn: 'Nanyuki',
        countryNameVi: 'Kenya',
        countryNameEn: 'Kenya'
    },
    MRE: {
        id: 2060,
        airportCode: 'MRE',
        cityCode: 'MRE',
        countryCode: 'KE',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Mara Serena',
        airportNameEn: 'Mara Serena Airport ',
        cityNameVi: 'Mara Lodges',
        cityNameEn: 'Mara Lodges',
        countryNameVi: 'Kenya',
        countryNameEn: 'Kenya'
    },
    ASV: {
        id: 2059,
        airportCode: 'ASV',
        cityCode: 'ASV',
        countryCode: 'KE',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Amboseli',
        airportNameEn: 'Amboseli Airport',
        cityNameVi: 'Amboseli',
        cityNameEn: 'Amboseli',
        countryNameVi: 'Kenya',
        countryNameEn: 'Kenya'
    },
    WIL: {
        id: 2058,
        airportCode: 'WIL',
        cityCode: 'NBO',
        countryCode: 'KE',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Wilson',
        airportNameEn: 'Wilson Airport',
        cityNameVi: 'Nairobi',
        cityNameEn: 'Nairobi',
        countryNameVi: 'Kenya',
        countryNameEn: 'Kenya'
    },
    MBA: {
        id: 2057,
        airportCode: 'MBA',
        cityCode: 'MBA',
        countryCode: 'KE',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Moi',
        airportNameEn: 'Moi International Airport',
        cityNameVi: 'Mombasa',
        cityNameEn: 'Mombasa',
        countryNameVi: 'Kenya',
        countryNameEn: 'Kenya'
    },
    MYD: {
        id: 2056,
        airportCode: 'MYD',
        cityCode: 'MYD',
        countryCode: 'KE',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Malindi',
        airportNameEn: 'Malindi Airport',
        cityNameVi: 'Malindi',
        cityNameEn: 'Malindi',
        countryNameVi: 'Kenya',
        countryNameEn: 'Kenya'
    },
    LAU: {
        id: 2055,
        airportCode: 'LAU',
        cityCode: 'LAU',
        countryCode: 'KE',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Mwana',
        airportNameEn: 'Mwana Airport',
        cityNameVi: 'Lamu',
        cityNameEn: 'Lamu',
        countryNameVi: 'Kenya',
        countryNameEn: 'Kenya'
    },
    KIS: {
        id: 2054,
        airportCode: 'KIS',
        cityCode: 'KIS',
        countryCode: 'KE',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Kisumu',
        airportNameEn: 'Kisumu Airport',
        cityNameVi: 'Kisumu',
        cityNameEn: 'Kisumu',
        countryNameVi: 'Kenya',
        countryNameEn: 'Kenya'
    },
    EDL: {
        id: 2053,
        airportCode: 'EDL',
        cityCode: 'EDL',
        countryCode: 'KE',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Eldoret',
        airportNameEn: 'Eldoret International Airport',
        cityNameVi: 'Eldoret',
        cityNameEn: 'Eldoret',
        countryNameVi: 'Kenya',
        countryNameEn: 'Kenya'
    },
    CIT: {
        id: 2052,
        airportCode: 'CIT',
        cityCode: 'CIT',
        countryCode: 'KZ',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Shymkent',
        airportNameEn: 'Shymkent Airport',
        cityNameVi: 'Shimkent',
        cityNameEn: 'Shimkent',
        countryNameVi: 'Kazakstan',
        countryNameEn: 'Kazakstan'
    },
    PWQ: {
        id: 2051,
        airportCode: 'PWQ',
        cityCode: 'PWQ',
        countryCode: 'KZ',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Pavlodar',
        airportNameEn: 'Pavlodar Airport',
        cityNameVi: 'Pavlodar',
        cityNameEn: 'Pavlodar',
        countryNameVi: 'Kazakstan',
        countryNameEn: 'Kazakstan'
    },
    UKK: {
        id: 2050,
        airportCode: 'UKK',
        cityCode: 'UKK',
        countryCode: 'KZ',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Oskemen',
        airportNameEn: 'Oskemen Airport',
        cityNameVi: 'Ust Kamenogorsk',
        cityNameEn: 'Ust Kamenogorsk',
        countryNameVi: 'Kazakstan',
        countryNameEn: 'Kazakstan'
    },
    SCO: {
        id: 2049,
        airportCode: 'SCO',
        cityCode: 'SCO',
        countryCode: 'KZ',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Aktau',
        airportNameEn: 'Aktau Airport',
        cityNameVi: 'Aktau',
        cityNameEn: 'Aktau',
        countryNameVi: 'Kazakstan',
        countryNameEn: 'Kazakstan'
    },
    KSN: {
        id: 2048,
        airportCode: 'KSN',
        cityCode: 'KSN',
        countryCode: 'KZ',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Kostanay',
        airportNameEn: 'Kostanay Airport ',
        cityNameVi: 'Kostanay',
        cityNameEn: 'Kostanay',
        countryNameVi: 'Kazakstan',
        countryNameEn: 'Kazakstan'
    },
    KGF: {
        id: 2047,
        airportCode: 'KGF',
        cityCode: 'KGF',
        countryCode: 'KZ',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Sary-Arka',
        airportNameEn: 'Sary-Arka Airport',
        cityNameVi: 'Karaganda',
        cityNameEn: 'Karaganda',
        countryNameVi: 'Kazakstan',
        countryNameEn: 'Kazakstan'
    },
    GUW: {
        id: 2046,
        airportCode: 'GUW',
        cityCode: 'GUW',
        countryCode: 'KZ',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Atyrau',
        airportNameEn: 'Atyrau Airport',
        cityNameVi: 'Atyrau',
        cityNameEn: 'Atyrau',
        countryNameVi: 'Kazakstan',
        countryNameEn: 'Kazakstan'
    },
    TSE: {
        id: 2045,
        airportCode: 'TSE',
        cityCode: 'TSE',
        countryCode: 'KZ',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Astana',
        airportNameEn: 'Astana Airport',
        cityNameVi: 'Astana',
        cityNameEn: 'Astana',
        countryNameVi: 'Kazakstan',
        countryNameEn: 'Kazakstan'
    },
    ALA: {
        id: 2044,
        airportCode: 'ALA',
        cityCode: 'ALA',
        countryCode: 'KZ',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Almaty',
        airportNameEn: 'Almaty International Airport',
        cityNameVi: 'Almaty',
        cityNameEn: 'Almaty',
        countryNameVi: 'Kazakstan',
        countryNameEn: 'Kazakstan'
    },
    AKX: {
        id: 2043,
        airportCode: 'AKX',
        cityCode: 'AKX',
        countryCode: 'KZ',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Aktobe',
        airportNameEn: 'Aktobe Airport',
        cityNameVi: 'Aktyubinsk',
        cityNameEn: 'Aktyubinsk',
        countryNameVi: 'Kazakstan',
        countryNameEn: 'Kazakstan'
    },
    AQJ: {
        id: 2042,
        airportCode: 'AQJ',
        cityCode: 'AQJ',
        countryCode: 'JO',
        continentCode: 'AS',
        airportNameVi: 'Sân bay King Hussein',
        airportNameEn: 'King Hussein International Airport',
        cityNameVi: 'Aqaba',
        cityNameEn: 'Aqaba',
        countryNameVi: 'Gióc đa ni',
        countryNameEn: 'Jordan'
    },
    AMM: {
        id: 2041,
        airportCode: 'AMM',
        cityCode: 'AMM',
        countryCode: 'JO',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Queen Alia',
        airportNameEn: 'Queen Alia International Airport',
        cityNameVi: 'Amman',
        cityNameEn: 'Amman',
        countryNameVi: 'Gióc đa ni',
        countryNameEn: 'Jordan'
    },
    ADJ: {
        id: 2040,
        airportCode: 'ADJ',
        cityCode: 'ADJ',
        countryCode: 'JO',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Marka',
        airportNameEn: 'Marka International Airport',
        cityNameVi: 'Amman',
        cityNameEn: 'Amman',
        countryNameVi: 'Gióc đa ni',
        countryNameEn: 'Jordan'
    },
    NKM: {
        id: 2039,
        airportCode: 'NKM',
        cityCode: 'NKM',
        countryCode: 'JP',
        continentCode: 'AS',
        airportNameVi: 'Nagoya Airfield ',
        airportNameEn: 'Nagoya Airfield ',
        cityNameVi: 'Nagoya',
        cityNameEn: 'Nagoya',
        countryNameVi: 'Nhật bản',
        countryNameEn: 'Japan'
    },
    MSJ: {
        id: 2038,
        airportCode: 'MSJ',
        cityCode: 'MSJ',
        countryCode: 'JP',
        continentCode: 'AS',
        airportNameVi: 'Misawa Air Base',
        airportNameEn: 'Misawa Air Base',
        cityNameVi: 'Misawa',
        cityNameEn: 'Misawa',
        countryNameVi: 'Nhật bản',
        countryNameEn: 'Japan'
    },
    UBJ: {
        id: 2037,
        airportCode: 'UBJ',
        cityCode: 'UBJ',
        countryCode: 'JP',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Yamaguchi Ube',
        airportNameEn: 'Yamaguchi Ube Airport',
        cityNameVi: 'Ube Jp',
        cityNameEn: 'Ube Jp',
        countryNameVi: 'Nhật bản',
        countryNameEn: 'Japan'
    },
    WKJ: {
        id: 2036,
        airportCode: 'WKJ',
        cityCode: 'WKJ',
        countryCode: 'JP',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Wakkanai',
        airportNameEn: 'Wakkanai Airport',
        cityNameVi: 'Wakkanai',
        cityNameEn: 'Wakkanai',
        countryNameVi: 'Nhật bản',
        countryNameEn: 'Japan'
    },
    TOY: {
        id: 2035,
        airportCode: 'TOY',
        cityCode: 'TOY',
        countryCode: 'JP',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Toyama',
        airportNameEn: 'Toyama Airport',
        cityNameVi: 'Toyama',
        cityNameEn: 'Toyama',
        countryNameVi: 'Nhật bản',
        countryNameEn: 'Japan'
    },
    SYO: {
        id: 2034,
        airportCode: 'SYO',
        cityCode: 'SYO',
        countryCode: 'JP',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Shonai',
        airportNameEn: 'Shonai Airport',
        cityNameVi: 'Shonai',
        cityNameEn: 'Shonai',
        countryNameVi: 'Nhật bản',
        countryNameEn: 'Japan'
    },
    HSG: {
        id: 2033,
        airportCode: 'HSG',
        cityCode: 'HSG',
        countryCode: 'JP',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Saga',
        airportNameEn: 'Saga Airport',
        cityNameVi: 'Saga',
        cityNameEn: 'Saga',
        countryNameVi: 'Nhật bản',
        countryNameEn: 'Japan'
    },
    ONJ: {
        id: 2032,
        airportCode: 'ONJ',
        cityCode: 'ONJ',
        countryCode: 'JP',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Odate-Noshiro',
        airportNameEn: 'Odate-Noshiro Airport',
        cityNameVi: 'Odate Noshiro',
        cityNameEn: 'Odate Noshiro',
        countryNameVi: 'Nhật bản',
        countryNameEn: 'Japan'
    },
    SHB: {
        id: 2031,
        airportCode: 'SHB',
        cityCode: 'SHB',
        countryCode: 'JP',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Nakashibetsu',
        airportNameEn: 'Nakashibetsu Airport',
        cityNameVi: 'Nakashibetsu',
        cityNameEn: 'Nakashibetsu',
        countryNameVi: 'Nhật bản',
        countryNameEn: 'Japan'
    },
    MMY: {
        id: 2030,
        airportCode: 'MMY',
        cityCode: 'MMY',
        countryCode: 'JP',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Miyako',
        airportNameEn: 'Miyako Airport',
        cityNameVi: 'Miyako Jima',
        cityNameEn: 'Miyako Jima',
        countryNameVi: 'Nhật bản',
        countryNameEn: 'Japan'
    },
    YGJ: {
        id: 2029,
        airportCode: 'YGJ',
        cityCode: 'YGJ',
        countryCode: 'JP',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Miho-Yonago',
        airportNameEn: 'Miho-Yonago Airport',
        cityNameVi: 'Yonago',
        cityNameEn: 'Yonago',
        countryNameVi: 'Nhật bản',
        countryNameEn: 'Japan'
    },
    IWJ: {
        id: 2028,
        airportCode: 'IWJ',
        cityCode: 'IWJ',
        countryCode: 'JP',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Iwami',
        airportNameEn: 'Iwami Airport ',
        cityNameVi: 'Iwami',
        cityNameEn: 'Iwami',
        countryNameVi: 'Nhật bản',
        countryNameEn: 'Japan'
    },
    ISG: {
        id: 2027,
        airportCode: 'ISG',
        cityCode: 'ISG',
        countryCode: 'JP',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Ishigaki',
        airportNameEn: 'Ishigaki Airport',
        cityNameVi: 'Ishigaki',
        cityNameEn: 'Ishigaki',
        countryNameVi: 'Nhật bản',
        countryNameEn: 'Japan'
    },
    HAC: {
        id: 2026,
        airportCode: 'HAC',
        cityCode: 'HAC',
        countryCode: 'JP',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Hachijojima',
        airportNameEn: 'Hachijojima Airport ',
        cityNameVi: 'Hachijo Jima',
        cityNameEn: 'Hachijo Jima',
        countryNameVi: 'Nhật bản',
        countryNameEn: 'Japan'
    },
    FUJ: {
        id: 2025,
        airportCode: 'FUJ',
        cityCode: 'FUJ',
        countryCode: 'JP',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Fukue',
        airportNameEn: 'Fukue Airport ',
        cityNameVi: 'Fukue',
        cityNameEn: 'Fukue',
        countryNameVi: 'Nhật bản',
        countryNameEn: 'Japan'
    },
    GAJ: {
        id: 2024,
        airportCode: 'GAJ',
        cityCode: 'GAJ',
        countryCode: 'JP',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Yamagata',
        airportNameEn: 'Yamagata Airport ',
        cityNameVi: 'Yamagata',
        cityNameEn: 'Yamagata',
        countryNameVi: 'Nhật bản',
        countryNameEn: 'Japan'
    },
    TYO: {
        id: 2023,
        airportCode: 'TYO',
        cityCode: 'TYO',
        countryCode: 'JP',
        continentCode: 'AS',
        airportNameVi: 'Tất cả các sân bay',
        airportNameEn: 'All Airports',
        cityNameVi: 'Tokyo',
        cityNameEn: 'Tokyo',
        countryNameVi: 'Nhật bản',
        countryNameEn: 'Japan'
    },
    NRT: {
        id: 2022,
        airportCode: 'NRT',
        cityCode: 'TYO',
        countryCode: 'JP',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Narita',
        airportNameEn: 'Narita International Airport',
        cityNameVi: 'Tokyo',
        cityNameEn: 'Tokyo',
        countryNameVi: 'Nhật bản',
        countryNameEn: 'Japan'
    },
    HND: {
        id: 2021,
        airportCode: 'HND',
        cityCode: 'TYO',
        countryCode: 'JP',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Haneda',
        airportNameEn: 'Haneda Airport ',
        cityNameVi: 'Tokyo',
        cityNameEn: 'Tokyo',
        countryNameVi: 'Nhật bản',
        countryNameEn: 'Japan'
    },
    TAK: {
        id: 2020,
        airportCode: 'TAK',
        cityCode: 'TAK',
        countryCode: 'JP',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Takamatsu',
        airportNameEn: 'Takamatsu Airport',
        cityNameVi: 'Takamatsu',
        cityNameEn: 'Takamatsu',
        countryNameVi: 'Nhật bản',
        countryNameEn: 'Japan'
    },
    SDJ: {
        id: 2019,
        airportCode: 'SDJ',
        cityCode: 'SDJ',
        countryCode: 'JP',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Sendai',
        airportNameEn: 'Sendai Airport',
        cityNameVi: 'Sendai',
        cityNameEn: 'Sendai',
        countryNameVi: 'Nhật bản',
        countryNameEn: 'Japan'
    },
    SPK: {
        id: 2018,
        airportCode: 'SPK',
        cityCode: 'SPK',
        countryCode: 'JP',
        continentCode: 'AS',
        airportNameVi: 'Tất cả các sân bay',
        airportNameEn: 'All Airports',
        cityNameVi: 'Sapporo',
        cityNameEn: 'Sapporo',
        countryNameVi: 'Nhật bản',
        countryNameEn: 'Japan'
    },
    CTS: {
        id: 2017,
        airportCode: 'CTS',
        cityCode: 'SPK',
        countryCode: 'JP',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Chitose',
        airportNameEn: 'Chitose International Airport',
        cityNameVi: 'Sapporo',
        cityNameEn: 'Sapporo',
        countryNameVi: 'Nhật bản',
        countryNameEn: 'Japan'
    },
    OSA: {
        id: 2016,
        airportCode: 'OSA',
        cityCode: 'OSA',
        countryCode: 'JP',
        continentCode: 'AS',
        airportNameVi: 'Tất cả các sân bay',
        airportNameEn: 'All Airports',
        cityNameVi: 'Osaka',
        cityNameEn: 'Osaka',
        countryNameVi: 'Nhật bản',
        countryNameEn: 'Japan'
    },
    ITM: {
        id: 2015,
        airportCode: 'ITM',
        cityCode: 'OSA',
        countryCode: 'JP',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Osaka',
        airportNameEn: 'Osaka International Airport ',
        cityNameVi: 'Osaka',
        cityNameEn: 'Osaka',
        countryNameVi: 'Nhật bản',
        countryNameEn: 'Japan'
    },
    KIX: {
        id: 2014,
        airportCode: 'KIX',
        cityCode: 'OSA',
        countryCode: 'JP',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Kansai',
        airportNameEn: 'Kansai International Airport',
        cityNameVi: 'Osaka',
        cityNameEn: 'Osaka',
        countryNameVi: 'Nhật bản',
        countryNameEn: 'Japan'
    },
    OKA: {
        id: 2013,
        airportCode: 'OKA',
        cityCode: 'OKA',
        countryCode: 'JP',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Naha',
        airportNameEn: 'Naha Airport',
        cityNameVi: 'Okinawa',
        cityNameEn: 'Okinawa',
        countryNameVi: 'Nhật bản',
        countryNameEn: 'Japan'
    },
    OKJ: {
        id: 2012,
        airportCode: 'OKJ',
        cityCode: 'OKJ',
        countryCode: 'JP',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Okayama',
        airportNameEn: 'Okayama Airport',
        cityNameVi: 'Okayama',
        cityNameEn: 'Okayama',
        countryNameVi: 'Nhật bản',
        countryNameEn: 'Japan'
    },
    OIT: {
        id: 2011,
        airportCode: 'OIT',
        cityCode: 'OIT',
        countryCode: 'JP',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Oita',
        airportNameEn: 'Oita Airport',
        cityNameVi: 'Oita',
        cityNameEn: 'Oita',
        countryNameVi: 'Nhật bản',
        countryNameEn: 'Japan'
    },
    OBO: {
        id: 2010,
        airportCode: 'OBO',
        cityCode: 'OBO',
        countryCode: 'JP',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Tokachi-Obihiro',
        airportNameEn: 'Tokachi-Obihiro Airport',
        cityNameVi: 'Obihiro',
        cityNameEn: 'Obihiro',
        countryNameVi: 'Nhật bản',
        countryNameEn: 'Japan'
    },
    KIJ: {
        id: 2009,
        airportCode: 'KIJ',
        cityCode: 'KIJ',
        countryCode: 'JP',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Niigata',
        airportNameEn: 'Niigata Airport',
        cityNameVi: 'Niigata',
        cityNameEn: 'Niigata',
        countryNameVi: 'Nhật bản',
        countryNameEn: 'Japan'
    },
    NGO: {
        id: 2008,
        airportCode: 'NGO',
        cityCode: 'NGO',
        countryCode: 'JP',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Chubu Centrair',
        airportNameEn: 'Chubu Centrair International Airport',
        cityNameVi: 'Nagoya',
        cityNameEn: 'Nagoya',
        countryNameVi: 'Nhật bản',
        countryNameEn: 'Japan'
    },
    NGS: {
        id: 2007,
        airportCode: 'NGS',
        cityCode: 'NGS',
        countryCode: 'JP',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Nagasaki',
        airportNameEn: 'Nagasaki Airport',
        cityNameVi: 'Nagasaki',
        cityNameEn: 'Nagasaki',
        countryNameVi: 'Nhật bản',
        countryNameEn: 'Japan'
    },
    HNA: {
        id: 2006,
        airportCode: 'HNA',
        cityCode: 'HNA',
        countryCode: 'JP',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Hanamaki',
        airportNameEn: 'Hanamaki Airport',
        cityNameVi: 'Morioka',
        cityNameEn: 'Morioka',
        countryNameVi: 'Nhật bản',
        countryNameEn: 'Japan'
    },
    KMI: {
        id: 2005,
        airportCode: 'KMI',
        cityCode: 'KMI',
        countryCode: 'JP',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Miyazaki',
        airportNameEn: 'Miyazaki Airport',
        cityNameVi: 'Miyazaki',
        cityNameEn: 'Miyazaki',
        countryNameVi: 'Nhật bản',
        countryNameEn: 'Japan'
    },
    MMB: {
        id: 2004,
        airportCode: 'MMB',
        cityCode: 'MMB',
        countryCode: 'JP',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Memanbetsu',
        airportNameEn: 'Memanbetsu Airport',
        cityNameVi: 'Memambetsu',
        cityNameEn: 'Memambetsu',
        countryNameVi: 'Nhật bản',
        countryNameEn: 'Japan'
    },
    MYJ: {
        id: 2003,
        airportCode: 'MYJ',
        cityCode: 'MYJ',
        countryCode: 'JP',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Matsuyama',
        airportNameEn: 'Matsuyama Airport',
        cityNameVi: 'Matsuyama',
        cityNameEn: 'Matsuyama',
        countryNameVi: 'Nhật bản',
        countryNameEn: 'Japan'
    },
    MMJ: {
        id: 2002,
        airportCode: 'MMJ',
        cityCode: 'MMJ',
        countryCode: 'JP',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Matsumoto',
        airportNameEn: 'Matsumoto Airport',
        cityNameVi: 'Matsumoto',
        cityNameEn: 'Matsumoto',
        countryNameVi: 'Nhật bản',
        countryNameEn: 'Japan'
    },
    KUH: {
        id: 2001,
        airportCode: 'KUH',
        cityCode: 'KUH',
        countryCode: 'JP',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Kushiro',
        airportNameEn: 'Kushiro Airport',
        cityNameVi: 'Kushiro',
        cityNameEn: 'Kushiro',
        countryNameVi: 'Nhật bản',
        countryNameEn: 'Japan'
    },
    KMJ: {
        id: 2000,
        airportCode: 'KMJ',
        cityCode: 'KMJ',
        countryCode: 'JP',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Kumamoto',
        airportNameEn: 'Kumamoto Airport',
        cityNameVi: 'Kumamoto',
        cityNameEn: 'Kumamoto',
        countryNameVi: 'Nhật bản',
        countryNameEn: 'Japan'
    },
    KMQ: {
        id: 1999,
        airportCode: 'KMQ',
        cityCode: 'KMQ',
        countryCode: 'JP',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Komatsu',
        airportNameEn: 'Komatsu Airport ',
        cityNameVi: 'Komatsu',
        cityNameEn: 'Komatsu',
        countryNameVi: 'Nhật bản',
        countryNameEn: 'Japan'
    },
    KCZ: {
        id: 1998,
        airportCode: 'KCZ',
        cityCode: 'KCZ',
        countryCode: 'JP',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Kochi Ryoma',
        airportNameEn: 'Kōchi Ryōma Airport',
        cityNameVi: 'Kochi',
        cityNameEn: 'Kochi',
        countryNameVi: 'Nhật bản',
        countryNameEn: 'Japan'
    },
    UKB: {
        id: 1997,
        airportCode: 'UKB',
        cityCode: 'UKB',
        countryCode: 'JP',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Kobe',
        airportNameEn: 'Kobe Airport',
        cityNameVi: 'Kobe',
        cityNameEn: 'Kobe',
        countryNameVi: 'Nhật bản',
        countryNameEn: 'Japan'
    },
    KKJ: {
        id: 1996,
        airportCode: 'KKJ',
        cityCode: 'KKJ',
        countryCode: 'JP',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Kokura',
        airportNameEn: 'Kokura Airport',
        cityNameVi: 'Kita Kyushu',
        cityNameEn: 'Kita Kyushu',
        countryNameVi: 'Nhật bản',
        countryNameEn: 'Japan'
    },
    KOJ: {
        id: 1995,
        airportCode: 'KOJ',
        cityCode: 'KOJ',
        countryCode: 'JP',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Kagoshima',
        airportNameEn: 'Kagoshima Airport',
        cityNameVi: 'Kagoshima',
        cityNameEn: 'Kagoshima',
        countryNameVi: 'Nhật bản',
        countryNameEn: 'Japan'
    },
    IZO: {
        id: 1994,
        airportCode: 'IZO',
        cityCode: 'IZO',
        countryCode: 'JP',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Izumo',
        airportNameEn: 'Izumo Airport',
        cityNameVi: 'Izumo',
        cityNameEn: 'Izumo',
        countryNameVi: 'Nhật bản',
        countryNameEn: 'Japan'
    },
    HIJ: {
        id: 1993,
        airportCode: 'HIJ',
        cityCode: 'HIJ',
        countryCode: 'JP',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Hiroshima',
        airportNameEn: 'Hiroshima Airport',
        cityNameVi: 'Hiroshima',
        cityNameEn: 'Hiroshima',
        countryNameVi: 'Nhật bản',
        countryNameEn: 'Japan'
    },
    HKD: {
        id: 1992,
        airportCode: 'HKD',
        cityCode: 'HKD',
        countryCode: 'JP',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Hakodate',
        airportNameEn: 'Hakodate Airport',
        cityNameVi: 'Hakodate',
        cityNameEn: 'Hakodate',
        countryNameVi: 'Nhật bản',
        countryNameEn: 'Japan'
    },
    FKS: {
        id: 1991,
        airportCode: 'FKS',
        cityCode: 'FKS',
        countryCode: 'JP',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Fukushima',
        airportNameEn: 'Fukushima Airport',
        cityNameVi: 'Fukushima',
        cityNameEn: 'Fukushima',
        countryNameVi: 'Nhật bản',
        countryNameEn: 'Japan'
    },
    FUK: {
        id: 1990,
        airportCode: 'FUK',
        cityCode: 'FUK',
        countryCode: 'JP',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Fukuoka',
        airportNameEn: 'Fukuoka Airport ',
        cityNameVi: 'Fukuoka',
        cityNameEn: 'Fukuoka',
        countryNameVi: 'Nhật bản',
        countryNameEn: 'Japan'
    },
    AKJ: {
        id: 1989,
        airportCode: 'AKJ',
        cityCode: 'AKJ',
        countryCode: 'JP',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Asahikawa',
        airportNameEn: 'Asahikawa Airport',
        cityNameVi: 'Asahikawa',
        cityNameEn: 'Asahikawa',
        countryNameVi: 'Nhật bản',
        countryNameEn: 'Japan'
    },
    AOJ: {
        id: 1988,
        airportCode: 'AOJ',
        cityCode: 'AOJ',
        countryCode: 'JP',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Aomori',
        airportNameEn: 'Aomori Airport',
        cityNameVi: 'Aomori',
        cityNameEn: 'Aomori',
        countryNameVi: 'Nhật bản',
        countryNameEn: 'Japan'
    },
    ASJ: {
        id: 1987,
        airportCode: 'ASJ',
        cityCode: 'ASJ',
        countryCode: 'JP',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Amami',
        airportNameEn: 'Amami Airport',
        cityNameVi: 'Amami O Shima',
        cityNameEn: 'Amami O Shima',
        countryNameVi: 'Nhật bản',
        countryNameEn: 'Japan'
    },
    AXT: {
        id: 1986,
        airportCode: 'AXT',
        cityCode: 'AXT',
        countryCode: 'JP',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Akita',
        airportNameEn: 'Akita Airport',
        cityNameVi: 'Akita',
        cityNameEn: 'Akita',
        countryNameVi: 'Nhật bản',
        countryNameEn: 'Japan'
    },
    MBJ: {
        id: 1985,
        airportCode: 'MBJ',
        cityCode: 'MBJ',
        countryCode: 'JM',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Sangster',
        airportNameEn: 'Sangster International Airport',
        cityNameVi: 'Montego Bay',
        cityNameEn: 'Montego Bay',
        countryNameVi: 'Jamaica',
        countryNameEn: 'Jamaica'
    },
    KTP: {
        id: 1984,
        airportCode: 'KTP',
        cityCode: 'KIN',
        countryCode: 'JM',
        continentCode: 'SA',
        airportNameVi: 'Tinson Pen Aerodrome',
        airportNameEn: 'Tinson Pen Aerodrome',
        cityNameVi: 'Kingston',
        cityNameEn: 'Kingston',
        countryNameVi: 'Jamaica',
        countryNameEn: 'Jamaica'
    },
    ABJ: {
        id: 1983,
        airportCode: 'ABJ',
        cityCode: 'ABJ',
        countryCode: 'CI',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Port Bouet',
        airportNameEn: 'Port Bouet Airport ',
        cityNameVi: 'Abidjan',
        cityNameEn: 'Abidjan',
        countryNameVi: 'Ivory Coast',
        countryNameEn: 'Ivory Coast'
    },
    VBS: {
        id: 1982,
        airportCode: 'VBS',
        cityCode: 'VBS',
        countryCode: 'IT',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Brescia Montichiari',
        airportNameEn: 'Brescia Montichiari International Airport',
        cityNameVi: 'Brescia',
        cityNameEn: 'Brescia',
        countryNameVi: 'Ý',
        countryNameEn: 'Italy'
    },
    VRN: {
        id: 1981,
        airportCode: 'VRN',
        cityCode: 'VRN',
        countryCode: 'IT',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Verona',
        airportNameEn: 'Verona Airport',
        cityNameVi: 'Verona',
        cityNameEn: 'Verona',
        countryNameVi: 'Ý',
        countryNameEn: 'Italy'
    },
    VCE: {
        id: 1980,
        airportCode: 'VCE',
        cityCode: 'VCE',
        countryCode: 'IT',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Venice Marco Polo',
        airportNameEn: 'Venice Marco Polo Airport',
        cityNameVi: 'Venice',
        cityNameEn: 'Venice',
        countryNameVi: 'Ý',
        countryNameEn: 'Italy'
    },
    TRS: {
        id: 1979,
        airportCode: 'TRS',
        cityCode: 'TRS',
        countryCode: 'IT',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Friuli Venezia Giulia',
        airportNameEn: 'Friuli Venezia Giulia Airport',
        cityNameVi: 'Trieste',
        cityNameEn: 'Trieste',
        countryNameVi: 'Ý',
        countryNameEn: 'Italy'
    },
    TSF: {
        id: 1978,
        airportCode: 'TSF',
        cityCode: 'TSF',
        countryCode: 'IT',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Treviso',
        airportNameEn: 'Treviso Airport',
        cityNameVi: 'Treviso',
        cityNameEn: 'Treviso',
        countryNameVi: 'Ý',
        countryNameEn: 'Italy'
    },
    TPS: {
        id: 1977,
        airportCode: 'TPS',
        cityCode: 'TPS',
        countryCode: 'IT',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Trapani-Birgi',
        airportNameEn: 'Trapani-Birgi Airport',
        cityNameVi: 'Trapani',
        cityNameEn: 'Trapani',
        countryNameVi: 'Ý',
        countryNameEn: 'Italy'
    },
    ROM: {
        id: 1976,
        airportCode: 'ROM',
        cityCode: 'ROM',
        countryCode: 'IT',
        continentCode: 'EU',
        airportNameVi: 'Tất cả các sân bay',
        airportNameEn: 'All Airports',
        cityNameVi: 'Rome',
        cityNameEn: 'Rome',
        countryNameVi: 'Ý',
        countryNameEn: 'Italy'
    },
    CIA: {
        id: 1975,
        airportCode: 'CIA',
        cityCode: 'ROM',
        countryCode: 'IT',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Rome Ciampino',
        airportNameEn: 'Rome Ciampino Airport ',
        cityNameVi: 'Rome',
        cityNameEn: 'Rome',
        countryNameVi: 'Ý',
        countryNameEn: 'Italy'
    },
    FCO: {
        id: 1974,
        airportCode: 'FCO',
        cityCode: 'ROM',
        countryCode: 'IT',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Leonardo da Vinci–Fiumicino',
        airportNameEn: 'Leonardo da Vinci–Fiumicino Airport',
        cityNameVi: 'Rome',
        cityNameEn: 'Rome',
        countryNameVi: 'Ý',
        countryNameEn: 'Italy'
    },
    RMI: {
        id: 1973,
        airportCode: 'RMI',
        cityCode: 'RMI',
        countryCode: 'IT',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Federico Fellini',
        airportNameEn: 'Federico Fellini International Airport',
        cityNameVi: 'Rimini',
        cityNameEn: 'Rimini',
        countryNameVi: 'Ý',
        countryNameEn: 'Italy'
    },
    REG: {
        id: 1972,
        airportCode: 'REG',
        cityCode: 'REG',
        countryCode: 'IT',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Reggio Calabria',
        airportNameEn: 'Reggio Calabria Airport',
        cityNameVi: 'Reggio Calabria',
        cityNameEn: 'Reggio Calabria',
        countryNameVi: 'Ý',
        countryNameEn: 'Italy'
    },
    PSA: {
        id: 1971,
        airportCode: 'PSA',
        cityCode: 'PSA',
        countryCode: 'IT',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Galileo Galilei',
        airportNameEn: 'Galileo Galilei Airport',
        cityNameVi: 'Pisa',
        cityNameEn: 'Pisa',
        countryNameVi: 'Ý',
        countryNameEn: 'Italy'
    },
    PSR: {
        id: 1970,
        airportCode: 'PSR',
        cityCode: 'PSR',
        countryCode: 'IT',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Pescara',
        airportNameEn: 'Pescara Airport',
        cityNameVi: 'Pescara',
        cityNameEn: 'Pescara',
        countryNameVi: 'Ý',
        countryNameEn: 'Italy'
    },
    PEG: {
        id: 1969,
        airportCode: 'PEG',
        cityCode: 'PEG',
        countryCode: 'IT',
        continentCode: 'EU',
        airportNameVi: 'Sân bay San Egidio',
        airportNameEn: 'San Egidio Airport',
        cityNameVi: 'Perugia',
        cityNameEn: 'Perugia',
        countryNameVi: 'Ý',
        countryNameEn: 'Italy'
    },
    PMF: {
        id: 1968,
        airportCode: 'PMF',
        cityCode: 'PMF',
        countryCode: 'IT',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Parma',
        airportNameEn: 'Parma Airport ',
        cityNameVi: 'Parma',
        cityNameEn: 'Parma',
        countryNameVi: 'Ý',
        countryNameEn: 'Italy'
    },
    PMO: {
        id: 1967,
        airportCode: 'PMO',
        cityCode: 'PMO',
        countryCode: 'IT',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Palermo',
        airportNameEn: 'Palermo Airport ',
        cityNameVi: 'Palermo',
        cityNameEn: 'Palermo',
        countryNameVi: 'Ý',
        countryNameEn: 'Italy'
    },
    OLB: {
        id: 1966,
        airportCode: 'OLB',
        cityCode: 'OLB',
        countryCode: 'IT',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Olbia – Costa Smeralda',
        airportNameEn: 'Olbia – Costa Smeralda Airport',
        cityNameVi: 'Olbia',
        cityNameEn: 'Olbia',
        countryNameVi: 'Ý',
        countryNameEn: 'Italy'
    },
    NAP: {
        id: 1965,
        airportCode: 'NAP',
        cityCode: 'NAP',
        countryCode: 'IT',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Naples',
        airportNameEn: 'Naples Airport',
        cityNameVi: 'Naples',
        cityNameEn: 'Naples',
        countryNameVi: 'Ý',
        countryNameEn: 'Italy'
    },
    MIL: {
        id: 1964,
        airportCode: 'MIL',
        cityCode: 'MIL',
        countryCode: 'IT',
        continentCode: 'EU',
        airportNameVi: 'Tất cả các sân bay',
        airportNameEn: 'All Airports',
        cityNameVi: 'Milan',
        cityNameEn: 'Milan',
        countryNameVi: 'Ý',
        countryNameEn: 'Italy'
    },
    BGY: {
        id: 1963,
        airportCode: 'BGY',
        cityCode: 'MIL',
        countryCode: 'IT',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Orio al Serio',
        airportNameEn: 'Orio al Serio Airport',
        cityNameVi: 'Milan',
        cityNameEn: 'Milan',
        countryNameVi: 'Ý',
        countryNameEn: 'Italy'
    },
    MXP: {
        id: 1962,
        airportCode: 'MXP',
        cityCode: 'MIL',
        countryCode: 'IT',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Malpensa',
        airportNameEn: 'Malpensa Airport',
        cityNameVi: 'Milan',
        cityNameEn: 'Milan',
        countryNameVi: 'Ý',
        countryNameEn: 'Italy'
    },
    LIN: {
        id: 1961,
        airportCode: 'LIN',
        cityCode: 'MIL',
        countryCode: 'IT',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Linate',
        airportNameEn: 'Linate Airport',
        cityNameVi: 'Milan',
        cityNameEn: 'Milan',
        countryNameVi: 'Ý',
        countryNameEn: 'Italy'
    },
    LMP: {
        id: 1960,
        airportCode: 'LMP',
        cityCode: 'LMP',
        countryCode: 'IT',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Lampedusa',
        airportNameEn: 'Lampedusa Airport',
        cityNameVi: 'Lampedusa',
        cityNameEn: 'Lampedusa',
        countryNameVi: 'Ý',
        countryNameEn: 'Italy'
    },
    SUF: {
        id: 1959,
        airportCode: 'SUF',
        cityCode: 'SUF',
        countryCode: 'IT',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Lamezia Terme',
        airportNameEn: 'Lamezia Terme International Airport',
        cityNameVi: 'Lamezia Terme',
        cityNameEn: 'Lamezia Terme',
        countryNameVi: 'Ý',
        countryNameEn: 'Italy'
    },
    GOA: {
        id: 1958,
        airportCode: 'GOA',
        cityCode: 'GOA',
        countryCode: 'IT',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Cristoforo Colombo',
        airportNameEn: 'Cristoforo Colombo Airport',
        cityNameVi: 'Genoa',
        cityNameEn: 'Genoa',
        countryNameVi: 'Ý',
        countryNameEn: 'Italy'
    },
    FLR: {
        id: 1957,
        airportCode: 'FLR',
        cityCode: 'FLR',
        countryCode: 'IT',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Peretola',
        airportNameEn: 'Peretola Airport ',
        cityNameVi: 'Florence',
        cityNameEn: 'Florence',
        countryNameVi: 'Ý',
        countryNameEn: 'Italy'
    },
    EBA: {
        id: 1956,
        airportCode: 'EBA',
        cityCode: 'EBA',
        countryCode: 'IT',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Marina di Campo',
        airportNameEn: 'Marina di Campo Airport',
        cityNameVi: 'Elba Island',
        cityNameEn: 'Elba Island',
        countryNameVi: 'Ý',
        countryNameEn: 'Italy'
    },
    CUF: {
        id: 1955,
        airportCode: 'CUF',
        cityCode: 'CUF',
        countryCode: 'IT',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Levaldigi',
        airportNameEn: 'Levaldigi Airport',
        cityNameVi: 'Cuneo',
        cityNameEn: 'Cuneo',
        countryNameVi: 'Ý',
        countryNameEn: 'Italy'
    },
    CRV: {
        id: 1954,
        airportCode: 'CRV',
        cityCode: 'CRV',
        countryCode: 'IT',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Crotone',
        airportNameEn: 'Crotone Airport',
        cityNameVi: 'Crotone',
        cityNameEn: 'Crotone',
        countryNameVi: 'Ý',
        countryNameEn: 'Italy'
    },
    CTA: {
        id: 1953,
        airportCode: 'CTA',
        cityCode: 'CTA',
        countryCode: 'IT',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Catania-Fontanarossa',
        airportNameEn: 'Catania-Fontanarossa Airport',
        cityNameVi: 'Catania',
        cityNameEn: 'Catania',
        countryNameVi: 'Ý',
        countryNameEn: 'Italy'
    },
    CAG: {
        id: 1952,
        airportCode: 'CAG',
        cityCode: 'CAG',
        countryCode: 'IT',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Cagliari',
        airportNameEn: 'Cagliari Airport',
        cityNameVi: 'Cagliari',
        cityNameEn: 'Cagliari',
        countryNameVi: 'Ý',
        countryNameEn: 'Italy'
    },
    BDS: {
        id: 1951,
        airportCode: 'BDS',
        cityCode: 'BDS',
        countryCode: 'IT',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Casale',
        airportNameEn: 'Casale Airport',
        cityNameVi: 'Brindisi',
        cityNameEn: 'Brindisi',
        countryNameVi: 'Ý',
        countryNameEn: 'Italy'
    },
    BZO: {
        id: 1950,
        airportCode: 'BZO',
        cityCode: 'BZO',
        countryCode: 'IT',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Bolzano',
        airportNameEn: 'Bolzano Airport',
        cityNameVi: 'Bolzano',
        cityNameEn: 'Bolzano',
        countryNameVi: 'Ý',
        countryNameEn: 'Italy'
    },
    BLQ: {
        id: 1949,
        airportCode: 'BLQ',
        cityCode: 'BLQ',
        countryCode: 'IT',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Bologna-Borgo Panigale "Guglielmo Marconi"',
        airportNameEn: 'Bologna-Borgo Panigale "Guglielmo Marconi" Airport',
        cityNameVi: 'Bologna',
        cityNameEn: 'Bologna',
        countryNameVi: 'Ý',
        countryNameEn: 'Italy'
    },
    BRI: {
        id: 1948,
        airportCode: 'BRI',
        cityCode: 'BRI',
        countryCode: 'IT',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Bari "Karol Wojtyla"',
        airportNameEn: 'Bari "Karol Wojtyła" Airport',
        cityNameVi: 'Bari',
        cityNameEn: 'Bari',
        countryNameVi: 'Ý',
        countryNameEn: 'Italy'
    },
    AOI: {
        id: 1947,
        airportCode: 'AOI',
        cityCode: 'AOI',
        countryCode: 'IT',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Falconara',
        airportNameEn: 'Falconara Airport',
        cityNameVi: 'Ancona',
        cityNameEn: 'Ancona',
        countryNameVi: 'Ý',
        countryNameEn: 'Italy'
    },
    AHO: {
        id: 1946,
        airportCode: 'AHO',
        cityCode: 'AHO',
        countryCode: 'IT',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Fertilia',
        airportNameEn: 'Fertilia Airport ',
        cityNameVi: 'Alghero',
        cityNameEn: 'Alghero',
        countryNameVi: 'Ý',
        countryNameEn: 'Italy'
    },
    VDA: {
        id: 1945,
        airportCode: 'VDA',
        cityCode: 'VDA',
        countryCode: 'IL',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Ovda',
        airportNameEn: 'Ovda International Airport',
        cityNameVi: 'Ovda',
        cityNameEn: 'Ovda',
        countryNameVi: 'Israel',
        countryNameEn: 'Israel'
    },
    ETH: {
        id: 1944,
        airportCode: 'ETH',
        cityCode: 'ETH',
        countryCode: 'IL',
        continentCode: 'AS',
        airportNameVi: 'Sân bay J. Hozman',
        airportNameEn: 'J. Hozman Airport',
        cityNameVi: 'Elat',
        cityNameEn: 'Elat',
        countryNameVi: 'Israel',
        countryNameEn: 'Israel'
    },
    SDV: {
        id: 1943,
        airportCode: 'SDV',
        cityCode: 'TLV',
        countryCode: 'IL',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Sde Dov',
        airportNameEn: 'Sde Dov Airport',
        cityNameVi: 'Tel Aviv',
        cityNameEn: 'Tel Aviv',
        countryNameVi: 'Israel',
        countryNameEn: 'Israel'
    },
    TLV: {
        id: 1942,
        airportCode: 'TLV',
        cityCode: 'TLV',
        countryCode: 'IL',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Ben Gurion',
        airportNameEn: 'Ben Gurion International Airport',
        cityNameVi: 'Tel Aviv',
        cityNameEn: 'Tel Aviv',
        countryNameVi: 'Israel',
        countryNameEn: 'Israel'
    },
    BSR: {
        id: 1941,
        airportCode: 'BSR',
        cityCode: 'BSR',
        countryCode: 'IQ',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Basrah',
        airportNameEn: 'Basrah International Airport',
        cityNameVi: 'Basra',
        cityNameEn: 'Basra',
        countryNameVi: 'Iraq',
        countryNameEn: 'Iraq'
    },
    AZD: {
        id: 1940,
        airportCode: 'AZD',
        cityCode: 'AZD',
        countryCode: 'IR',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Yazd Shahid Sadooghi',
        airportNameEn: 'Yazd Shahid Sadooghi Airport',
        cityNameVi: 'Yazd',
        cityNameEn: 'Yazd',
        countryNameVi: 'Iran',
        countryNameEn: 'Iran'
    },
    IKA: {
        id: 1939,
        airportCode: 'IKA',
        cityCode: 'IKA',
        countryCode: 'IR',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Tehran Imam Khomeini',
        airportNameEn: 'Tehran Imam Khomeini International Airport',
        cityNameVi: 'Teheran',
        cityNameEn: 'Teheran',
        countryNameVi: 'Iran',
        countryNameEn: 'Iran'
    },
    KSH: {
        id: 1938,
        airportCode: 'KSH',
        cityCode: 'KSH',
        countryCode: 'IR',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Shahid Ashrafi Esfahani',
        airportNameEn: 'Shahid Ashrafi Esfahani Airport ',
        cityNameVi: 'Kermanshah',
        cityNameEn: 'Kermanshah',
        countryNameVi: 'Iran',
        countryNameEn: 'Iran'
    },
    KHD: {
        id: 1937,
        airportCode: 'KHD',
        cityCode: 'KHD',
        countryCode: 'IR',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Khorramabad',
        airportNameEn: 'Khorramabad Airport',
        cityNameVi: 'Khorramabad',
        cityNameEn: 'Khorramabad',
        countryNameVi: 'Iran',
        countryNameEn: 'Iran'
    },
    KER: {
        id: 1936,
        airportCode: 'KER',
        cityCode: 'KER',
        countryCode: 'IR',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Kerman',
        airportNameEn: 'Kerman Airport',
        cityNameVi: 'Kerman',
        cityNameEn: 'Kerman',
        countryNameVi: 'Iran',
        countryNameEn: 'Iran'
    },
    BUZ: {
        id: 1935,
        airportCode: 'BUZ',
        cityCode: 'BUZ',
        countryCode: 'IR',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Bushehr',
        airportNameEn: 'Bushehr Airport',
        cityNameVi: 'Bushehr',
        cityNameEn: 'Bushehr',
        countryNameVi: 'Iran',
        countryNameEn: 'Iran'
    },
    THR: {
        id: 1934,
        airportCode: 'THR',
        cityCode: 'THR',
        countryCode: 'IR',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Mehrabad',
        airportNameEn: 'Mehrabad International Airport',
        cityNameVi: 'Teheran',
        cityNameEn: 'Teheran',
        countryNameVi: 'Iran',
        countryNameEn: 'Iran'
    },
    TBZ: {
        id: 1933,
        airportCode: 'TBZ',
        cityCode: 'TBZ',
        countryCode: 'IR',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Tabriz',
        airportNameEn: 'Tabriz International Airport',
        cityNameVi: 'Tabriz',
        cityNameEn: 'Tabriz',
        countryNameVi: 'Iran',
        countryNameEn: 'Iran'
    },
    SYZ: {
        id: 1932,
        airportCode: 'SYZ',
        cityCode: 'SYZ',
        countryCode: 'IR',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Shiraz',
        airportNameEn: 'Shiraz International Airport',
        cityNameVi: 'Shiraz',
        cityNameEn: 'Shiraz',
        countryNameVi: 'Iran',
        countryNameEn: 'Iran'
    },
    MHD: {
        id: 1931,
        airportCode: 'MHD',
        cityCode: 'MHD',
        countryCode: 'IR',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Mashhad',
        airportNameEn: 'Mashhad International Airport ',
        cityNameVi: 'Mashad',
        cityNameEn: 'Mashad',
        countryNameVi: 'Iran',
        countryNameEn: 'Iran'
    },
    IFN: {
        id: 1930,
        airportCode: 'IFN',
        cityCode: 'IFN',
        countryCode: 'IR',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Esfahan Shahid Beheshti',
        airportNameEn: 'Esfahan Shahid Beheshti International Airport',
        cityNameVi: 'Isfahan',
        cityNameEn: 'Isfahan',
        countryNameVi: 'Iran',
        countryNameEn: 'Iran'
    },
    ABD: {
        id: 1929,
        airportCode: 'ABD',
        cityCode: 'ABD',
        countryCode: 'IR',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Abadan',
        airportNameEn: 'Abadan Airport',
        cityNameVi: 'Abadan',
        cityNameEn: 'Abadan',
        countryNameVi: 'Iran',
        countryNameEn: 'Iran'
    },
    BDJ: {
        id: 1928,
        airportCode: 'BDJ',
        cityCode: 'BDJ',
        countryCode: 'ID',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Syamsudin Noor',
        airportNameEn: 'Syamsudin Noor Airport',
        cityNameVi: 'Banjarmasin',
        cityNameEn: 'Banjarmasin',
        countryNameVi: 'Indonesia',
        countryNameEn: 'Indonesia'
    },
    BTJ: {
        id: 1927,
        airportCode: 'BTJ',
        cityCode: 'BTJ',
        countryCode: 'ID',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Sultan Iskandarmuda',
        airportNameEn: 'Sultan Iskandarmuda Airport',
        cityNameVi: 'Banda Aceh',
        cityNameEn: 'Banda Aceh',
        countryNameVi: 'Indonesia',
        countryNameEn: 'Indonesia'
    },
    MDC: {
        id: 1926,
        airportCode: 'MDC',
        cityCode: 'MDC',
        countryCode: 'ID',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Sam Ratulangi',
        airportNameEn: 'Sam Ratulangi International Airport',
        cityNameVi: 'Menado',
        cityNameEn: 'Menado',
        countryNameVi: 'Indonesia',
        countryNameEn: 'Indonesia'
    },
    TKG: {
        id: 1925,
        airportCode: 'TKG',
        cityCode: 'TKG',
        countryCode: 'ID',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Radin Inten II',
        airportNameEn: 'Radin Inten II Airport',
        cityNameVi: 'Bandar Lampung',
        cityNameEn: 'Bandar Lampung',
        countryNameVi: 'Indonesia',
        countryNameEn: 'Indonesia'
    },
    MES: {
        id: 1924,
        airportCode: 'MES',
        cityCode: 'MES',
        countryCode: 'ID',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Polonia',
        airportNameEn: 'Polonia International Airport',
        cityNameVi: 'Medan',
        cityNameEn: 'Medan',
        countryNameVi: 'Indonesia',
        countryNameEn: 'Indonesia'
    },
    BDO: {
        id: 1923,
        airportCode: 'BDO',
        cityCode: 'BDO',
        countryCode: 'ID',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Hussein Sastranegara',
        airportNameEn: 'Hussein Sastranegara Airport',
        cityNameVi: 'Bandung',
        cityNameEn: 'Bandung',
        countryNameVi: 'Indonesia',
        countryNameEn: 'Indonesia'
    },
    JOG: {
        id: 1922,
        airportCode: 'JOG',
        cityCode: 'JOG',
        countryCode: 'ID',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Adisucipto',
        airportNameEn: 'Adisucipto International Airport',
        cityNameVi: 'Yogjakarta',
        cityNameEn: 'Yogjakarta',
        countryNameVi: 'Indonesia',
        countryNameEn: 'Indonesia'
    },
    UPG: {
        id: 1921,
        airportCode: 'UPG',
        cityCode: 'UPG',
        countryCode: 'ID',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Sultan Hasanuddin',
        airportNameEn: 'Sultan Hasanuddin International Airport',
        cityNameVi: 'Ujung Pandang',
        cityNameEn: 'Ujung Pandang',
        countryNameVi: 'Indonesia',
        countryNameEn: 'Indonesia'
    },
    TRK: {
        id: 1920,
        airportCode: 'TRK',
        cityCode: 'TRK',
        countryCode: 'ID',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Juwata',
        airportNameEn: 'Juwata Airport',
        cityNameVi: 'Tarakan',
        cityNameEn: 'Tarakan',
        countryNameVi: 'Indonesia',
        countryNameEn: 'Indonesia'
    },
    TJQ: {
        id: 1919,
        airportCode: 'TJQ',
        cityCode: 'TJQ',
        countryCode: 'ID',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Buluh Tumbang',
        airportNameEn: 'Buluh Tumbang Airport',
        cityNameVi: 'Tanjung Pandan',
        cityNameEn: 'Tanjung Pandan',
        countryNameVi: 'Indonesia',
        countryNameEn: 'Indonesia'
    },
    SUB: {
        id: 1918,
        airportCode: 'SUB',
        cityCode: 'SUB',
        countryCode: 'ID',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Juanda',
        airportNameEn: 'Juanda International Airport',
        cityNameVi: 'Surabaya',
        cityNameEn: 'Surabaya',
        countryNameVi: 'Indonesia',
        countryNameEn: 'Indonesia'
    },
    SOC: {
        id: 1917,
        airportCode: 'SOC',
        cityCode: 'SOC',
        countryCode: 'ID',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Adi Soemarmo',
        airportNameEn: 'Adi Soemarmo International Airport',
        cityNameVi: 'Solo',
        cityNameEn: 'Solo',
        countryNameVi: 'Indonesia',
        countryNameEn: 'Indonesia'
    },
    SRG: {
        id: 1916,
        airportCode: 'SRG',
        cityCode: 'SRG',
        countryCode: 'ID',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Achmad Yani',
        airportNameEn: 'Achmad Yani International Airport',
        cityNameVi: 'Semarang',
        cityNameEn: 'Semarang',
        countryNameVi: 'Indonesia',
        countryNameEn: 'Indonesia'
    },
    PKU: {
        id: 1915,
        airportCode: 'PKU',
        cityCode: 'PKU',
        countryCode: 'ID',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Sultan Syarif Kasim II',
        airportNameEn: 'Sultan Syarif Kasim II Airport',
        cityNameVi: 'Pekanbaru',
        cityNameEn: 'Pekanbaru',
        countryNameVi: 'Indonesia',
        countryNameEn: 'Indonesia'
    },
    PDG: {
        id: 1914,
        airportCode: 'PDG',
        cityCode: 'PDG',
        countryCode: 'ID',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Minangkabau',
        airportNameEn: 'Minangkabau International Airport',
        cityNameVi: 'Padang',
        cityNameEn: 'Padang',
        countryNameVi: 'Indonesia',
        countryNameEn: 'Indonesia'
    },
    DJB: {
        id: 1913,
        airportCode: 'DJB',
        cityCode: 'DJB',
        countryCode: 'ID',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Sultan Thaha',
        airportNameEn: 'Sultan Thaha Airport ',
        cityNameVi: 'Jambi',
        cityNameEn: 'Jambi',
        countryNameVi: 'Indonesia',
        countryNameEn: 'Indonesia'
    },
    JKT: {
        id: 1912,
        airportCode: 'JKT',
        cityCode: 'JKT',
        countryCode: 'ID',
        continentCode: 'AS',
        airportNameVi: 'Tất cả các sân bay',
        airportNameEn: 'All Airports',
        cityNameVi: 'Jakarta',
        cityNameEn: 'Jakarta',
        countryNameVi: 'Indonesia',
        countryNameEn: 'Indonesia'
    },
    CGK: {
        id: 1911,
        airportCode: 'CGK',
        cityCode: 'JKT',
        countryCode: 'ID',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Soekarno–Hatta',
        airportNameEn: 'Soekarno–Hatta International Airport',
        cityNameVi: 'Jakarta',
        cityNameEn: 'Jakarta',
        countryNameVi: 'Indonesia',
        countryNameEn: 'Indonesia'
    },
    HLP: {
        id: 1910,
        airportCode: 'HLP',
        cityCode: 'JKT',
        countryCode: 'ID',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Halim Perdanakusuma',
        airportNameEn: 'Halim Perdanakusuma International Airport',
        cityNameVi: 'Jakarta',
        cityNameEn: 'Jakarta',
        countryNameVi: 'Indonesia',
        countryNameEn: 'Indonesia'
    },
    DIL: {
        id: 1909,
        airportCode: 'DIL',
        cityCode: 'DIL',
        countryCode: 'ID',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Presidente Nicolau Lobato',
        airportNameEn: 'Presidente Nicolau Lobato International Airport',
        cityNameVi: 'Dili',
        cityNameEn: 'Dili',
        countryNameVi: 'Indonesia',
        countryNameEn: 'Indonesia'
    },
    BIK: {
        id: 1908,
        airportCode: 'BIK',
        cityCode: 'BIK',
        countryCode: 'ID',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Frans Kaisiepo',
        airportNameEn: 'Frans Kaisiepo Airport',
        cityNameVi: 'Biak',
        cityNameEn: 'Biak',
        countryNameVi: 'Indonesia',
        countryNameEn: 'Indonesia'
    },
    BKS: {
        id: 1907,
        airportCode: 'BKS',
        cityCode: 'BKS',
        countryCode: 'ID',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Padang Kemiling',
        airportNameEn: 'Padang Kemiling Airport ',
        cityNameVi: 'Bengkulu',
        cityNameEn: 'Bengkulu',
        countryNameVi: 'Indonesia',
        countryNameEn: 'Indonesia'
    },
    BTH: {
        id: 1906,
        airportCode: 'BTH',
        cityCode: 'BTH',
        countryCode: 'ID',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Hang Nadim',
        airportNameEn: 'Hang Nadim Airport',
        cityNameVi: 'Batam',
        cityNameEn: 'Batam',
        countryNameVi: 'Indonesia',
        countryNameEn: 'Indonesia'
    },
    BPN: {
        id: 1905,
        airportCode: 'BPN',
        cityCode: 'BPN',
        countryCode: 'ID',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Sepinggan',
        airportNameEn: 'Sepinggan International Airport ',
        cityNameVi: 'Balikpapan',
        cityNameEn: 'Balikpapan',
        countryNameVi: 'Indonesia',
        countryNameEn: 'Indonesia'
    },
    DPS: {
        id: 1904,
        airportCode: 'DPS',
        cityCode: 'DPS',
        countryCode: 'ID',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Ngurah Rai',
        airportNameEn: 'Ngurah Rai International Airport',
        cityNameVi: 'Bali Denpasar',
        cityNameEn: 'Bali Denpasar',
        countryNameVi: 'Indonesia',
        countryNameEn: 'Indonesia'
    },
    AMQ: {
        id: 1903,
        airportCode: 'AMQ',
        cityCode: 'AMQ',
        countryCode: 'ID',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Pattimura',
        airportNameEn: 'Pattimura Airport',
        cityNameVi: 'Ambon',
        cityNameEn: 'Ambon',
        countryNameVi: 'Indonesia',
        countryNameEn: 'Indonesia'
    },
    IXZ: {
        id: 1902,
        airportCode: 'IXZ',
        cityCode: 'IXZ',
        countryCode: 'IN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Vir Savarkar',
        airportNameEn: 'Vir Savarkar Airport ',
        cityNameVi: 'Port Blair',
        cityNameEn: 'Port Blair',
        countryNameVi: 'Ấn độ',
        countryNameEn: 'India'
    },
    VGA: {
        id: 1901,
        airportCode: 'VGA',
        cityCode: 'VGA',
        countryCode: 'IN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Vijayawada',
        airportNameEn: 'Vijayawada Airport',
        cityNameVi: 'Vijayawada',
        cityNameEn: 'Vijayawada',
        countryNameVi: 'Ấn độ',
        countryNameEn: 'India'
    },
    TIR: {
        id: 1900,
        airportCode: 'TIR',
        cityCode: 'TIR',
        countryCode: 'IN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Tirupati',
        airportNameEn: 'Tirupati Airport',
        cityNameVi: 'Tirupati',
        cityNameEn: 'Tirupati',
        countryNameVi: 'Ấn độ',
        countryNameEn: 'India'
    },
    TRZ: {
        id: 1899,
        airportCode: 'TRZ',
        cityCode: 'TRZ',
        countryCode: 'IN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Tiruchirapalli international',
        airportNameEn: 'Tiruchirapalli international Airport',
        cityNameVi: 'Tiruchirapally',
        cityNameEn: 'Tiruchirapally',
        countryNameVi: 'Ấn độ',
        countryNameEn: 'India'
    },
    MYQ: {
        id: 1898,
        airportCode: 'MYQ',
        cityCode: 'MYQ',
        countryCode: 'IN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Mysore',
        airportNameEn: 'Mysore Airport',
        cityNameVi: 'Mysore',
        cityNameEn: 'Mysore',
        countryNameVi: 'Ấn độ',
        countryNameEn: 'India'
    },
    IXE: {
        id: 1897,
        airportCode: 'IXE',
        cityCode: 'IXE',
        countryCode: 'IN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Mangalore',
        airportNameEn: 'Mangalore International Airport',
        cityNameVi: 'Mangalore',
        cityNameEn: 'Mangalore',
        countryNameVi: 'Ấn độ',
        countryNameEn: 'India'
    },
    IXM: {
        id: 1896,
        airportCode: 'IXM',
        cityCode: 'IXM',
        countryCode: 'IN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Madurai',
        airportNameEn: 'Madurai Airport',
        cityNameVi: 'Madurai',
        cityNameEn: 'Madurai',
        countryNameVi: 'Ấn độ',
        countryNameEn: 'India'
    },
    IXL: {
        id: 1895,
        airportCode: 'IXL',
        cityCode: 'IXL',
        countryCode: 'IN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Leh Kushok Bakula Rimpochee',
        airportNameEn: 'Leh Kushok Bakula Rimpochee Airport',
        cityNameVi: 'Leh',
        cityNameEn: 'Leh',
        countryNameVi: 'Ấn độ',
        countryNameEn: 'India'
    },
    GAY: {
        id: 1894,
        airportCode: 'GAY',
        cityCode: 'GAY',
        countryCode: 'IN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Gaya',
        airportNameEn: 'Gaya Airport',
        cityNameVi: 'Gaya',
        cityNameEn: 'Gaya',
        countryNameVi: 'Ấn độ',
        countryNameEn: 'India'
    },
    NAG: {
        id: 1893,
        airportCode: 'NAG',
        cityCode: 'NAG',
        countryCode: 'IN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Dr. Babasaheb Ambedkar',
        airportNameEn: 'Dr. Babasaheb Ambedkar International Airport',
        cityNameVi: 'Nagpur',
        cityNameEn: 'Nagpur',
        countryNameVi: 'Ấn độ',
        countryNameEn: 'India'
    },
    IXR: {
        id: 1892,
        airportCode: 'IXR',
        cityCode: 'IXR',
        countryCode: 'IN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Birsa Munda',
        airportNameEn: 'Birsa Munda Airport ',
        cityNameVi: 'Ranchi',
        cityNameEn: 'Ranchi',
        countryNameVi: 'Ấn độ',
        countryNameEn: 'India'
    },
    KUU: {
        id: 1891,
        airportCode: 'KUU',
        cityCode: 'KUU',
        countryCode: 'IN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Bhuntar',
        airportNameEn: 'Bhuntar Airport ',
        cityNameVi: 'Kulu',
        cityNameEn: 'Kulu',
        countryNameVi: 'Ấn độ',
        countryNameEn: 'India'
    },
    VTZ: {
        id: 1890,
        airportCode: 'VTZ',
        cityCode: 'VTZ',
        countryCode: 'IN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Visakhapatnam',
        airportNameEn: 'Visakhapatnam Airport',
        cityNameVi: 'Vishakhapatanam',
        cityNameEn: 'Vishakhapatanam',
        countryNameVi: 'Ấn độ',
        countryNameEn: 'India'
    },
    VNS: {
        id: 1889,
        airportCode: 'VNS',
        cityCode: 'VNS',
        countryCode: 'IN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Varanasi',
        airportNameEn: 'Varanasi Airport',
        cityNameVi: 'Varanasi',
        cityNameEn: 'Varanasi',
        countryNameVi: 'Ấn độ',
        countryNameEn: 'India'
    },
    BDQ: {
        id: 1888,
        airportCode: 'BDQ',
        cityCode: 'BDQ',
        countryCode: 'IN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Vadodara',
        airportNameEn: 'Vadodara Airport',
        cityNameVi: 'Vadodara',
        cityNameEn: 'Vadodara',
        countryNameVi: 'Ấn độ',
        countryNameEn: 'India'
    },
    UDR: {
        id: 1887,
        airportCode: 'UDR',
        cityCode: 'UDR',
        countryCode: 'IN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Udaipur',
        airportNameEn: 'Udaipur Airport',
        cityNameVi: 'Udaipur',
        cityNameEn: 'Udaipur',
        countryNameVi: 'Ấn độ',
        countryNameEn: 'India'
    },
    TRV: {
        id: 1886,
        airportCode: 'TRV',
        cityCode: 'TRV',
        countryCode: 'IN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Trivandrum',
        airportNameEn: 'Trivandrum International Airport',
        cityNameVi: 'Thiruvananthapuram',
        cityNameEn: 'Thiruvananthapuram',
        countryNameVi: 'Ấn độ',
        countryNameEn: 'India'
    },
    SXR: {
        id: 1885,
        airportCode: 'SXR',
        cityCode: 'SXR',
        countryCode: 'IN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Srinagar',
        airportNameEn: 'Srinagar International Airport',
        cityNameVi: 'Srinagar',
        cityNameEn: 'Srinagar',
        countryNameVi: 'Ấn độ',
        countryNameEn: 'India'
    },
    RAJ: {
        id: 1884,
        airportCode: 'RAJ',
        cityCode: 'RAJ',
        countryCode: 'IN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Rajkot',
        airportNameEn: 'Rajkot Airport',
        cityNameVi: 'Rajkot',
        cityNameEn: 'Rajkot',
        countryNameVi: 'Ấn độ',
        countryNameEn: 'India'
    },
    RPR: {
        id: 1883,
        airportCode: 'RPR',
        cityCode: 'RPR',
        countryCode: 'IN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Raipur',
        airportNameEn: 'Raipur Airport',
        cityNameVi: 'Raipur',
        cityNameEn: 'Raipur',
        countryNameVi: 'Ấn độ',
        countryNameEn: 'India'
    },
    PNQ: {
        id: 1882,
        airportCode: 'PNQ',
        cityCode: 'PNQ',
        countryCode: 'IN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Pune',
        airportNameEn: 'Pune International Airport',
        cityNameVi: 'Pune',
        cityNameEn: 'Pune',
        countryNameVi: 'Ấn độ',
        countryNameEn: 'India'
    },
    PAT: {
        id: 1881,
        airportCode: 'PAT',
        cityCode: 'PAT',
        countryCode: 'IN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Lok Nayak Jayaprakash',
        airportNameEn: 'Lok Nayak Jayaprakash Airport ',
        cityNameVi: 'Patna',
        cityNameEn: 'Patna',
        countryNameVi: 'Ấn độ',
        countryNameEn: 'India'
    },
    BOM: {
        id: 1880,
        airportCode: 'BOM',
        cityCode: 'BOM',
        countryCode: 'IN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Chhatrapati Shivaji',
        airportNameEn: 'Chhatrapati Shivaji International Airport',
        cityNameVi: 'Mumbai',
        cityNameEn: 'Mumbai',
        countryNameVi: 'Ấn độ',
        countryNameEn: 'India'
    },
    LUH: {
        id: 1879,
        airportCode: 'LUH',
        cityCode: 'LUH',
        countryCode: 'IN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Ludhiana',
        airportNameEn: 'Ludhiana Airport',
        cityNameVi: 'Ludhiana',
        cityNameEn: 'Ludhiana',
        countryNameVi: 'Ấn độ',
        countryNameEn: 'India'
    },
    LKO: {
        id: 1878,
        airportCode: 'LKO',
        cityCode: 'LKO',
        countryCode: 'IN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Amausi',
        airportNameEn: 'Amausi Airport',
        cityNameVi: 'Lucknow',
        cityNameEn: 'Lucknow',
        countryNameVi: 'Ấn độ',
        countryNameEn: 'India'
    },
    CCJ: {
        id: 1877,
        airportCode: 'CCJ',
        cityCode: 'CCJ',
        countryCode: 'IN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Calicut',
        airportNameEn: 'Calicut Airport',
        cityNameVi: 'Kozhikode',
        cityNameEn: 'Kozhikode',
        countryNameVi: 'Ấn độ',
        countryNameEn: 'India'
    },
    CCU: {
        id: 1876,
        airportCode: 'CCU',
        cityCode: 'CCU',
        countryCode: 'IN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Netaji Subhas Chandra Bose',
        airportNameEn: 'Netaji Subhas Chandra Bose International Airport',
        cityNameVi: 'Kolkata',
        cityNameEn: 'Kolkata',
        countryNameVi: 'Ấn độ',
        countryNameEn: 'India'
    },
    COK: {
        id: 1875,
        airportCode: 'COK',
        cityCode: 'COK',
        countryCode: 'IN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Cochin',
        airportNameEn: 'Cochin International Airport ',
        cityNameVi: 'Kochi',
        cityNameEn: 'Kochi',
        countryNameVi: 'Ấn độ',
        countryNameEn: 'India'
    },
    HJR: {
        id: 1874,
        airportCode: 'HJR',
        cityCode: 'HJR',
        countryCode: 'IN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Khajuraho',
        airportNameEn: 'Khajuraho Airport',
        cityNameVi: 'Khajuraho',
        cityNameEn: 'Khajuraho',
        countryNameVi: 'Ấn độ',
        countryNameEn: 'India'
    },
    JDH: {
        id: 1873,
        airportCode: 'JDH',
        cityCode: 'JDH',
        countryCode: 'IN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Jodhpur',
        airportNameEn: 'Jodhpur Airport',
        cityNameVi: 'Jodhpur',
        cityNameEn: 'Jodhpur',
        countryNameVi: 'Ấn độ',
        countryNameEn: 'India'
    },
    JGA: {
        id: 1872,
        airportCode: 'JGA',
        cityCode: 'JGA',
        countryCode: 'IN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Jamnagar',
        airportNameEn: 'Jamnagar Airport',
        cityNameVi: 'Jamnagar',
        cityNameEn: 'Jamnagar',
        countryNameVi: 'Ấn độ',
        countryNameEn: 'India'
    },
    IXJ: {
        id: 1871,
        airportCode: 'IXJ',
        cityCode: 'IXJ',
        countryCode: 'IN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Jammu',
        airportNameEn: 'Jammu Airport ',
        cityNameVi: 'Jammu',
        cityNameEn: 'Jammu',
        countryNameVi: 'Ấn độ',
        countryNameEn: 'India'
    },
    JAI: {
        id: 1870,
        airportCode: 'JAI',
        cityCode: 'JAI',
        countryCode: 'IN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Jaipur',
        airportNameEn: 'Jaipur Airport ',
        cityNameVi: 'Jaipur',
        cityNameEn: 'Jaipur',
        countryNameVi: 'Ấn độ',
        countryNameEn: 'India'
    },
    IDR: {
        id: 1869,
        airportCode: 'IDR',
        cityCode: 'IDR',
        countryCode: 'IN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Devi Ahilyabai Holkar',
        airportNameEn: 'Devi Ahilyabai Holkar International Airport',
        cityNameVi: 'Indore',
        cityNameEn: 'Indore',
        countryNameVi: 'Ấn độ',
        countryNameEn: 'India'
    },
    HYD: {
        id: 1868,
        airportCode: 'HYD',
        cityCode: 'HYD',
        countryCode: 'IN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Rajiv Gandhi',
        airportNameEn: 'Rajiv Gandhi International Airport ',
        cityNameVi: 'Hyderabad',
        cityNameEn: 'Hyderabad',
        countryNameVi: 'Ấn độ',
        countryNameEn: 'India'
    },
    GOI: {
        id: 1867,
        airportCode: 'GOI',
        cityCode: 'GOI',
        countryCode: 'IN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Goa',
        airportNameEn: 'Goa International Airport ',
        cityNameVi: 'Goa In',
        cityNameEn: 'Goa In',
        countryNameVi: 'Ấn độ',
        countryNameEn: 'India'
    },
    GAU: {
        id: 1866,
        airportCode: 'GAU',
        cityCode: 'GAU',
        countryCode: 'IN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Lokpriya Gopinath Bordoloi',
        airportNameEn: 'Lokpriya Gopinath Bordoloi International Airport',
        cityNameVi: 'Gawahati',
        cityNameEn: 'Gawahati',
        countryNameVi: 'Ấn độ',
        countryNameEn: 'India'
    },
    DIU: {
        id: 1865,
        airportCode: 'DIU',
        cityCode: 'DIU',
        countryCode: 'IN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Diu',
        airportNameEn: 'Diu Airport',
        cityNameVi: 'Diu In',
        cityNameEn: 'Diu In',
        countryNameVi: 'Ấn độ',
        countryNameEn: 'India'
    },
    DIB: {
        id: 1864,
        airportCode: 'DIB',
        cityCode: 'DIB',
        countryCode: 'IN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Dibrugarh',
        airportNameEn: 'Dibrugarh Airport ',
        cityNameVi: 'Dibrugarh',
        cityNameEn: 'Dibrugarh',
        countryNameVi: 'Ấn độ',
        countryNameEn: 'India'
    },
    DHM: {
        id: 1863,
        airportCode: 'DHM',
        cityCode: 'DHM',
        countryCode: 'IN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Gaggal',
        airportNameEn: 'Gaggal Airport',
        cityNameVi: 'Dharamsala',
        cityNameEn: 'Dharamsala',
        countryNameVi: 'Ấn độ',
        countryNameEn: 'India'
    },
    DEL: {
        id: 1862,
        airportCode: 'DEL',
        cityCode: 'DEL',
        countryCode: 'IN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Indira Gandhi',
        airportNameEn: 'Indira Gandhi International Airport',
        cityNameVi: 'Đê-li',
        cityNameEn: 'Delhi',
        countryNameVi: 'Ấn độ',
        countryNameEn: 'India'
    },
    DED: {
        id: 1861,
        airportCode: 'DED',
        cityCode: 'DED',
        countryCode: 'IN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Dehradun',
        airportNameEn: 'Dehradun Airport',
        cityNameVi: 'Dehra Dun',
        cityNameEn: 'Dehradun',
        countryNameVi: 'Ấn độ',
        countryNameEn: 'India'
    },
    CJB: {
        id: 1860,
        airportCode: 'CJB',
        cityCode: 'CJB',
        countryCode: 'IN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Coimbatore',
        airportNameEn: 'Coimbatore International Airport',
        cityNameVi: 'Coimbatore',
        cityNameEn: 'Coimbatore',
        countryNameVi: 'Ấn độ',
        countryNameEn: 'India'
    },
    MAA: {
        id: 1859,
        airportCode: 'MAA',
        cityCode: 'MAA',
        countryCode: 'IN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Chennai',
        airportNameEn: 'Chennai International Airport ',
        cityNameVi: 'Chennai',
        cityNameEn: 'Chennai',
        countryNameVi: 'Ấn độ',
        countryNameEn: 'India'
    },
    IXC: {
        id: 1858,
        airportCode: 'IXC',
        cityCode: 'IXC',
        countryCode: 'IN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Chandigarh',
        airportNameEn: 'Chandigarh Airport',
        cityNameVi: 'Chandigarh',
        cityNameEn: 'Chandigarh',
        countryNameVi: 'Ấn độ',
        countryNameEn: 'India'
    },
    BHJ: {
        id: 1857,
        airportCode: 'BHJ',
        cityCode: 'BHJ',
        countryCode: 'IN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Bhuj',
        airportNameEn: 'Bhuj Airport',
        cityNameVi: 'Bhuj',
        cityNameEn: 'Bhuj',
        countryNameVi: 'Ấn độ',
        countryNameEn: 'India'
    },
    BBI: {
        id: 1856,
        airportCode: 'BBI',
        cityCode: 'BBI',
        countryCode: 'IN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Biju Patnaik',
        airportNameEn: 'Biju Patnaik Airport',
        cityNameVi: 'Bhubaneswar',
        cityNameEn: 'Bhubaneswar',
        countryNameVi: 'Ấn độ',
        countryNameEn: 'India'
    },
    BHO: {
        id: 1855,
        airportCode: 'BHO',
        cityCode: 'BHO',
        countryCode: 'IN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Bhopal',
        airportNameEn: 'Bhopal Airport',
        cityNameVi: 'Bhopal',
        cityNameEn: 'Bhopal',
        countryNameVi: 'Ấn độ',
        countryNameEn: 'India'
    },
    BLR: {
        id: 1854,
        airportCode: 'BLR',
        cityCode: 'BLR',
        countryCode: 'IN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Bengaluru',
        airportNameEn: 'Bengaluru International Airport ',
        cityNameVi: 'Bangalore',
        cityNameEn: 'Bangalore',
        countryNameVi: 'Ấn độ',
        countryNameEn: 'India'
    },
    IXB: {
        id: 1853,
        airportCode: 'IXB',
        cityCode: 'IXB',
        countryCode: 'IN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Bagdogra',
        airportNameEn: 'Bagdogra Airport',
        cityNameVi: 'Bagdogra',
        cityNameEn: 'Bagdogra',
        countryNameVi: 'Ấn độ',
        countryNameEn: 'India'
    },
    IXU: {
        id: 1852,
        airportCode: 'IXU',
        cityCode: 'IXU',
        countryCode: 'IN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Aurangabad',
        airportNameEn: 'Aurangabad Airport ',
        cityNameVi: 'Aurangabad',
        cityNameEn: 'Aurangabad',
        countryNameVi: 'Ấn độ',
        countryNameEn: 'India'
    },
    ATQ: {
        id: 1851,
        airportCode: 'ATQ',
        cityCode: 'ATQ',
        countryCode: 'IN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Sri Guru Ram Dass Jee',
        airportNameEn: 'Sri Guru Ram Dass Jee International Airport',
        cityNameVi: 'Amritsar',
        cityNameEn: 'Amritsar',
        countryNameVi: 'Ấn độ',
        countryNameEn: 'India'
    },
    IXD: {
        id: 1850,
        airportCode: 'IXD',
        cityCode: 'IXD',
        countryCode: 'IN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Allahabad',
        airportNameEn: 'Allahabad Airport / Bamrauli Air Force Base',
        cityNameVi: 'Allahabad',
        cityNameEn: 'Allahabad',
        countryNameVi: 'Ấn độ',
        countryNameEn: 'India'
    },
    AMD: {
        id: 1849,
        airportCode: 'AMD',
        cityCode: 'AMD',
        countryCode: 'IN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Sardar Vallabhbhai Patel',
        airportNameEn: 'Sardar Vallabhbhai Patel International Airport',
        cityNameVi: 'Ahmedabad',
        cityNameEn: 'Ahmedabad',
        countryNameVi: 'Ấn độ',
        countryNameEn: 'India'
    },
    AGR: {
        id: 1848,
        airportCode: 'AGR',
        cityCode: 'AGR',
        countryCode: 'IN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Agra',
        airportNameEn: 'Agra Airport/Agra Air Force Station',
        cityNameVi: 'Agra',
        cityNameEn: 'Agra',
        countryNameVi: 'Ấn độ',
        countryNameEn: 'India'
    },
    FGI: {
        id: 1847,
        airportCode: 'FGI',
        cityCode: 'APW',
        countryCode: 'WS',
        continentCode: 'OC',
        airportNameVi: "Sân bay Fagali'i",
        airportNameEn: "Fagali'i Airport",
        cityNameVi: 'Apia',
        cityNameEn: 'Apia',
        countryNameVi: 'Samoa',
        countryNameEn: 'Independent State Of Samoa'
    },
    PPG: {
        id: 1846,
        airportCode: 'PPG',
        cityCode: 'PPG',
        countryCode: 'WS',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Pago Pago',
        airportNameEn: 'Pago Pago International Airport',
        cityNameVi: 'Pago Pago',
        cityNameEn: 'Pago Pago',
        countryNameVi: 'Samoa',
        countryNameEn: 'Independent State Of Samoa'
    },
    RKV: {
        id: 1845,
        airportCode: 'RKV',
        cityCode: 'RKV',
        countryCode: 'IS',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Reykjavík',
        airportNameEn: 'Reykjavík Airport',
        cityNameVi: 'Reykjavik',
        cityNameEn: 'Reykjavik',
        countryNameVi: 'Iceland',
        countryNameEn: 'Iceland'
    },
    KEF: {
        id: 1844,
        airportCode: 'KEF',
        cityCode: 'REK',
        countryCode: 'IS',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Keflavík',
        airportNameEn: 'Keflavík International Airport',
        cityNameVi: 'Reykjavik',
        cityNameEn: 'Reykjavik',
        countryNameVi: 'Iceland',
        countryNameEn: 'Iceland'
    },
    AEY: {
        id: 1843,
        airportCode: 'AEY',
        cityCode: 'AEY',
        countryCode: 'IS',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Akureyri',
        airportNameEn: 'Akureyri Airport',
        cityNameVi: 'Akureyri',
        cityNameEn: 'Akureyri',
        countryNameVi: 'Iceland',
        countryNameEn: 'Iceland'
    },
    BUD: {
        id: 1842,
        airportCode: 'BUD',
        cityCode: 'BUD',
        countryCode: 'HU',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Budapest Ferenc Liszt',
        airportNameEn: 'Budapest Ferenc Liszt International Airport',
        cityNameVi: 'Bu-đa-pét',
        cityNameEn: 'Budapest',
        countryNameVi: 'Hungary',
        countryNameEn: 'Hungary'
    },
    HKG: {
        id: 1841,
        airportCode: 'HKG',
        cityCode: 'HKG',
        countryCode: 'HK',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Hong Kong',
        airportNameEn: 'Hong Kong International Airport ',
        cityNameVi: 'Hồng Kông',
        cityNameEn: 'Hongkong',
        countryNameVi: 'Hồng kông',
        countryNameEn: 'Hong Kong'
    },
    TGU: {
        id: 1840,
        airportCode: 'TGU',
        cityCode: 'TGU',
        countryCode: 'HN',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Toncontín',
        airportNameEn: 'Toncontín International Airport',
        cityNameVi: 'Tegucigalpa',
        cityNameEn: 'Tegucigalpa',
        countryNameVi: 'Honduras',
        countryNameEn: 'Honduras'
    },
    SAP: {
        id: 1839,
        airportCode: 'SAP',
        cityCode: 'SAP',
        countryCode: 'HN',
        continentCode: 'SA',
        airportNameVi: 'Sân bay La Mesa',
        airportNameEn: 'La Mesa International Airport',
        cityNameVi: 'San Pedro Sula',
        cityNameEn: 'San Pedro Sula',
        countryNameVi: 'Honduras',
        countryNameEn: 'Honduras'
    },
    RTB: {
        id: 1838,
        airportCode: 'RTB',
        cityCode: 'RTB',
        countryCode: 'HN',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Juan Manuel Gálvez',
        airportNameEn: 'Juan Manuel Gálvez International Airport',
        cityNameVi: 'Roatan',
        cityNameEn: 'Roatan',
        countryNameVi: 'Honduras',
        countryNameEn: 'Honduras'
    },
    LCE: {
        id: 1837,
        airportCode: 'LCE',
        cityCode: 'LCE',
        countryCode: 'HN',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Golosón',
        airportNameEn: 'Golosón International Airport',
        cityNameVi: 'La Ceiba',
        cityNameEn: 'La Ceiba',
        countryNameVi: 'Honduras',
        countryNameEn: 'Honduras'
    },
    PAP: {
        id: 1836,
        airportCode: 'PAP',
        cityCode: 'PAP',
        countryCode: 'HT',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Port-au-Prince',
        airportNameEn: 'Port-au-Prince International Airport',
        cityNameVi: 'Port Au Prince',
        cityNameEn: 'Port Au Prince',
        countryNameVi: 'Haiti',
        countryNameEn: 'HAITI'
    },
    GEO: {
        id: 1835,
        airportCode: 'GEO',
        cityCode: 'GEO',
        countryCode: 'GY',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Cheddi Jagan',
        airportNameEn: 'Cheddi Jagan International Airport ',
        cityNameVi: 'Georgetown',
        cityNameEn: 'Georgetown',
        countryNameVi: 'Guyana',
        countryNameEn: 'Guyana'
    },
    OXB: {
        id: 1834,
        airportCode: 'OXB',
        cityCode: 'OXB',
        countryCode: 'GW',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Osvaldo Vieiro',
        airportNameEn: 'Osvaldo Vieiro International Airport',
        cityNameVi: 'Bissau',
        cityNameEn: 'Bissau',
        countryNameVi: 'Ghine Bissau',
        countryNameEn: 'Guinea Bissau'
    },
    CKY: {
        id: 1833,
        airportCode: 'CKY',
        cityCode: 'CKY',
        countryCode: 'GN',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Gbessia',
        airportNameEn: 'Gbessia Airport',
        cityNameVi: 'Conakry',
        cityNameEn: 'Conakry',
        countryNameVi: 'Guinea',
        countryNameEn: 'Guinea'
    },
    FRS: {
        id: 1832,
        airportCode: 'FRS',
        cityCode: 'FRS',
        countryCode: 'GT',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Mundo Maya',
        airportNameEn: 'Mundo Maya International Airport',
        cityNameVi: 'Flores',
        cityNameEn: 'Flores',
        countryNameVi: 'Guatemala',
        countryNameEn: 'Guatemala'
    },
    GUA: {
        id: 1831,
        airportCode: 'GUA',
        cityCode: 'GUA',
        countryCode: 'GT',
        continentCode: 'SA',
        airportNameVi: 'Sân bay La Aurora',
        airportNameEn: 'La Aurora International Airport',
        cityNameVi: 'Guatemala City',
        cityNameEn: 'Guatemala City',
        countryNameVi: 'Guatemala',
        countryNameEn: 'Guatemala'
    },
    UAM: {
        id: 1830,
        airportCode: 'UAM',
        cityCode: 'UAM',
        countryCode: 'GU',
        continentCode: 'OC',
        airportNameVi: 'Andersen Air Force Base',
        airportNameEn: 'Andersen Air Force Base',
        cityNameVi: 'Guam',
        cityNameEn: 'Guam',
        countryNameVi: 'Guam',
        countryNameEn: 'Guam'
    },
    GUM: {
        id: 1829,
        airportCode: 'GUM',
        cityCode: 'GUM',
        countryCode: 'GU',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Antonio B. Won Pat',
        airportNameEn: 'Antonio B. Won Pat International Airport ',
        cityNameVi: 'Guam',
        cityNameEn: 'Guam',
        countryNameVi: 'Guam',
        countryNameEn: 'Guam'
    },
    PTP: {
        id: 1828,
        airportCode: 'PTP',
        cityCode: 'PTP',
        countryCode: 'GP',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Guadeloupe Pôle Caraïbes',
        airportNameEn: 'Guadeloupe Pôle Caraïbes Airport',
        cityNameVi: 'Pointe A Pitre',
        cityNameEn: 'Pointe A Pitre',
        countryNameVi: 'Guadeloupe',
        countryNameEn: 'Guadeloupe'
    },
    GND: {
        id: 1827,
        airportCode: 'GND',
        cityCode: 'GND',
        countryCode: 'GD',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Maurice Bishop',
        airportNameEn: 'Maurice Bishop International Airport',
        cityNameVi: 'Grenada',
        cityNameEn: 'Grenada',
        countryNameVi: 'Grenada',
        countryNameEn: 'Grenada'
    },
    JJU: {
        id: 1826,
        airportCode: 'JJU',
        cityCode: 'JJU',
        countryCode: 'GL',
        continentCode: 'EU',
        airportNameVi: 'Qaqortoq Heliport',
        airportNameEn: 'Qaqortoq Heliport',
        cityNameVi: 'Qaqortoq',
        cityNameEn: 'Qaqortoq',
        countryNameVi: 'Greenland',
        countryNameEn: 'Greenland'
    },
    JNS: {
        id: 1825,
        airportCode: 'JNS',
        cityCode: 'JNS',
        countryCode: 'GL',
        continentCode: 'EU',
        airportNameVi: 'Narsaq Heliport',
        airportNameEn: 'Narsaq Heliport',
        cityNameVi: 'Narsaq',
        cityNameEn: 'Narsaq',
        countryNameVi: 'Greenland',
        countryNameEn: 'Greenland'
    },
    JNN: {
        id: 1824,
        airportCode: 'JNN',
        cityCode: 'JNN',
        countryCode: 'GL',
        continentCode: 'EU',
        airportNameVi: 'Nanortalik Heliport',
        airportNameEn: 'Nanortalik Heliport',
        cityNameVi: 'Nanortalik',
        cityNameEn: 'Nanortalik',
        countryNameVi: 'Greenland',
        countryNameEn: 'Greenland'
    },
    LLU: {
        id: 1823,
        airportCode: 'LLU',
        cityCode: 'LLU',
        countryCode: 'GL',
        continentCode: 'EU',
        airportNameVi: 'Alluitsup Paa Heliport',
        airportNameEn: 'Alluitsup Paa Heliport',
        cityNameVi: 'Alluitsup Paa',
        cityNameEn: 'Alluitsup Paa',
        countryNameVi: 'Greenland',
        countryNameEn: 'Greenland'
    },
    JHS: {
        id: 1822,
        airportCode: 'JHS',
        cityCode: 'JHS',
        countryCode: 'GL',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Sisimiut',
        airportNameEn: 'Sisimiut Airport',
        cityNameVi: 'Sisimiut',
        cityNameEn: 'Sisimiut',
        countryNameVi: 'Greenland',
        countryNameEn: 'Greenland'
    },
    JSU: {
        id: 1821,
        airportCode: 'JSU',
        cityCode: 'JSU',
        countryCode: 'GL',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Maniitsoq',
        airportNameEn: 'Maniitsoq Airport',
        cityNameVi: 'Maniitsoq',
        cityNameEn: 'Maniitsoq',
        countryNameVi: 'Greenland',
        countryNameEn: 'Greenland'
    },
    GOH: {
        id: 1820,
        airportCode: 'GOH',
        cityCode: 'GOH',
        countryCode: 'GL',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Nuuk',
        airportNameEn: 'Nuuk Airport ',
        cityNameVi: 'Nuuk',
        cityNameEn: 'Nuuk',
        countryNameVi: 'Greenland',
        countryNameEn: 'Greenland'
    },
    UAK: {
        id: 1819,
        airportCode: 'UAK',
        cityCode: 'UAK',
        countryCode: 'GL',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Narsarsuaq',
        airportNameEn: 'Narsarsuaq Airport',
        cityNameVi: 'Narsarsuaq',
        cityNameEn: 'Narsarsuaq',
        countryNameVi: 'Greenland',
        countryNameEn: 'Greenland'
    },
    KUS: {
        id: 1818,
        airportCode: 'KUS',
        cityCode: 'KUS',
        countryCode: 'GL',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Kulusuk',
        airportNameEn: 'Kulusuk Airport',
        cityNameVi: 'Kulusuk',
        cityNameEn: 'Kulusuk',
        countryNameVi: 'Greenland',
        countryNameEn: 'Greenland'
    },
    SFJ: {
        id: 1817,
        airportCode: 'SFJ',
        cityCode: 'SFJ',
        countryCode: 'GL',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Kangerlussuaq',
        airportNameEn: 'Kangerlussuaq Airport',
        cityNameVi: 'Kangerlussuaq',
        cityNameEn: 'Kangerlussuaq',
        countryNameVi: 'Greenland',
        countryNameEn: 'Greenland'
    },
    JAV: {
        id: 1816,
        airportCode: 'JAV',
        cityCode: 'JAV',
        countryCode: 'GL',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Ilulissat',
        airportNameEn: 'Ilulissat Airport',
        cityNameVi: 'Ilulissat',
        cityNameEn: 'Ilulissat',
        countryNameVi: 'Greenland',
        countryNameEn: 'Greenland'
    },
    JEG: {
        id: 1815,
        airportCode: 'JEG',
        cityCode: 'JEG',
        countryCode: 'GL',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Aasiaat',
        airportNameEn: 'Aasiaat Airport',
        cityNameVi: 'Aasiaat',
        cityNameEn: 'Aasiaat',
        countryNameVi: 'Greenland',
        countryNameEn: 'Greenland'
    },
    VOL: {
        id: 1814,
        airportCode: 'VOL',
        cityCode: 'VOL',
        countryCode: 'GR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay',
        airportNameEn: 'International Airport of Central Greece',
        cityNameVi: 'Volos',
        cityNameEn: 'Volos',
        countryNameVi: 'Hy lạp',
        countryNameEn: 'Greece'
    },
    ZTH: {
        id: 1813,
        airportCode: 'ZTH',
        cityCode: 'ZTH',
        countryCode: 'GR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Zakynthos',
        airportNameEn: 'Zakynthos International Airport, "Dionysios Solomos"',
        cityNameVi: 'Zakynthos',
        cityNameEn: 'Zakynthos',
        countryNameVi: 'Hy lạp',
        countryNameEn: 'Greece'
    },
    JTR: {
        id: 1812,
        airportCode: 'JTR',
        cityCode: 'JTR',
        countryCode: 'GR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Santorini',
        airportNameEn: 'Santorini Airport',
        cityNameVi: 'Thira Island',
        cityNameEn: 'Thira Island',
        countryNameVi: 'Hy lạp',
        countryNameEn: 'Greece'
    },
    SKG: {
        id: 1811,
        airportCode: 'SKG',
        cityCode: 'SKG',
        countryCode: 'GR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Makedonia',
        airportNameEn: 'Makedonia Airport',
        cityNameVi: 'Thessaloniki',
        cityNameEn: 'Thessaloniki',
        countryNameVi: 'Hy lạp',
        countryNameEn: 'Greece'
    },
    JSY: {
        id: 1810,
        airportCode: 'JSY',
        cityCode: 'JSY',
        countryCode: 'GR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Syros Island',
        airportNameEn: 'Syros Island Airport',
        cityNameVi: 'Syros Island',
        cityNameEn: 'Syros Island',
        countryNameVi: 'Hy lạp',
        countryNameEn: 'Greece'
    },
    JSI: {
        id: 1809,
        airportCode: 'JSI',
        cityCode: 'JSI',
        countryCode: 'GR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Skiathos Island National',
        airportNameEn: 'Skiathos Island National Airport',
        cityNameVi: 'Skiathos',
        cityNameEn: 'Skiathos',
        countryNameVi: 'Hy lạp',
        countryNameEn: 'Greece'
    },
    SMI: {
        id: 1808,
        airportCode: 'SMI',
        cityCode: 'SMI',
        countryCode: 'GR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Samos',
        airportNameEn: 'Samos International Airport "Aristarchos of Samos"',
        cityNameVi: 'Samos',
        cityNameEn: 'Samos',
        countryNameVi: 'Hy lạp',
        countryNameEn: 'Greece'
    },
    RHO: {
        id: 1807,
        airportCode: 'RHO',
        cityCode: 'RHO',
        countryCode: 'GR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Rhodes',
        airportNameEn: 'Rhodes International Airport',
        cityNameVi: 'Rhodes',
        cityNameEn: 'Rhodes',
        countryNameVi: 'Hy lạp',
        countryNameEn: 'Greece'
    },
    PVK: {
        id: 1806,
        airportCode: 'PVK',
        cityCode: 'PVK',
        countryCode: 'GR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Aktion',
        airportNameEn: 'Aktion Airport',
        cityNameVi: 'Preveza',
        cityNameEn: 'Preveza',
        countryNameVi: 'Hy lạp',
        countryNameEn: 'Greece'
    },
    GPA: {
        id: 1805,
        airportCode: 'GPA',
        cityCode: 'GPA',
        countryCode: 'GR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Araxos',
        airportNameEn: 'Araxos Airport',
        cityNameVi: 'Patras',
        cityNameEn: 'Patras',
        countryNameVi: 'Hy lạp',
        countryNameEn: 'Greece'
    },
    PAS: {
        id: 1804,
        airportCode: 'PAS',
        cityCode: 'PAS',
        countryCode: 'GR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Paros National',
        airportNameEn: 'Paros National Airport',
        cityNameVi: 'Paros',
        cityNameEn: 'Paros',
        countryNameVi: 'Hy lạp',
        countryNameEn: 'Greece'
    },
    JNX: {
        id: 1803,
        airportCode: 'JNX',
        cityCode: 'JNX',
        countryCode: 'GR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Naxos Island National',
        airportNameEn: 'Naxos Island National Airport',
        cityNameVi: 'Naxos',
        cityNameEn: 'Naxos',
        countryNameVi: 'Hy lạp',
        countryNameEn: 'Greece'
    },
    MJT: {
        id: 1802,
        airportCode: 'MJT',
        cityCode: 'MJT',
        countryCode: 'GR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Mytilene',
        airportNameEn: 'Mytilene International Airport',
        cityNameVi: 'Mytilene',
        cityNameEn: 'Mytilene',
        countryNameVi: 'Hy lạp',
        countryNameEn: 'Greece'
    },
    JMK: {
        id: 1801,
        airportCode: 'JMK',
        cityCode: 'JMK',
        countryCode: 'GR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Mykonos Island National',
        airportNameEn: 'Mykonos Island National Airport',
        cityNameVi: 'Mykonos',
        cityNameEn: 'Mykonos',
        countryNameVi: 'Hy lạp',
        countryNameEn: 'Greece'
    },
    LXS: {
        id: 1800,
        airportCode: 'LXS',
        cityCode: 'LXS',
        countryCode: 'GR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Lemnos',
        airportNameEn: 'Lemnos Airport',
        cityNameVi: 'Limnos',
        cityNameEn: 'Limnos',
        countryNameVi: 'Hy lạp',
        countryNameEn: 'Greece'
    },
    LRS: {
        id: 1799,
        airportCode: 'LRS',
        cityCode: 'LRS',
        countryCode: 'GR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Leros Island National',
        airportNameEn: 'Leros Island National Airport',
        cityNameVi: 'Leros',
        cityNameEn: 'Leros',
        countryNameVi: 'Hy lạp',
        countryNameEn: 'Greece'
    },
    KGS: {
        id: 1798,
        airportCode: 'KGS',
        cityCode: 'KGS',
        countryCode: 'GR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Kos Island',
        airportNameEn: 'Kos Island International Airport',
        cityNameVi: 'Kos Gr',
        cityNameEn: 'Kos Gr',
        countryNameVi: 'Hy lạp',
        countryNameEn: 'Greece'
    },
    KIT: {
        id: 1797,
        airportCode: 'KIT',
        cityCode: 'KIT',
        countryCode: 'GR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Kithira',
        airportNameEn: 'Kithira Airport',
        cityNameVi: 'Kithira',
        cityNameEn: 'Kithira',
        countryNameVi: 'Hy lạp',
        countryNameEn: 'Greece'
    },
    EFL: {
        id: 1796,
        airportCode: 'EFL',
        cityCode: 'EFL',
        countryCode: 'GR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Argostolion',
        airportNameEn: 'Argostolion Airport',
        cityNameVi: 'Kefalonia',
        cityNameEn: 'Kefalonia',
        countryNameVi: 'Hy lạp',
        countryNameEn: 'Greece'
    },
    KVA: {
        id: 1795,
        airportCode: 'KVA',
        cityCode: 'KVA',
        countryCode: 'GR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Kavala',
        airportNameEn: 'Kavala International Airport, "Megas Alexandros"',
        cityNameVi: 'Kavalla',
        cityNameEn: 'Kavalla',
        countryNameVi: 'Hy lạp',
        countryNameEn: 'Greece'
    },
    KZS: {
        id: 1794,
        airportCode: 'KZS',
        cityCode: 'KZS',
        countryCode: 'GR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Kastelorizo Island Public',
        airportNameEn: 'Kastelorizo Island Public Airport',
        cityNameVi: 'Kastelorizo',
        cityNameEn: 'Kastelorizo',
        countryNameVi: 'Hy lạp',
        countryNameEn: 'Greece'
    },
    AOK: {
        id: 1793,
        airportCode: 'AOK',
        cityCode: 'AOK',
        countryCode: 'GR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Karpathos',
        airportNameEn: 'Karpathos Airport',
        cityNameVi: 'Karpathos',
        cityNameEn: 'Karpathos',
        countryNameVi: 'Hy lạp',
        countryNameEn: 'Greece'
    },
    KLX: {
        id: 1792,
        airportCode: 'KLX',
        cityCode: 'KLX',
        countryCode: 'GR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Kalamata',
        airportNameEn: 'Kalamata Airport',
        cityNameVi: 'Kalamata',
        cityNameEn: 'Kalamata',
        countryNameVi: 'Hy lạp',
        countryNameEn: 'Greece'
    },
    IOA: {
        id: 1791,
        airportCode: 'IOA',
        cityCode: 'IOA',
        countryCode: 'GR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Ioannina National',
        airportNameEn: 'Ioannina National Airport',
        cityNameVi: 'Ioannina',
        cityNameEn: 'Ioannina',
        countryNameVi: 'Hy lạp',
        countryNameEn: 'Greece'
    },
    JIK: {
        id: 1790,
        airportCode: 'JIK',
        cityCode: 'JIK',
        countryCode: 'GR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Ikaria',
        airportNameEn: 'Ikaria Airport',
        cityNameVi: 'Ikaria Island',
        cityNameEn: 'Ikaria Island',
        countryNameVi: 'Hy lạp',
        countryNameEn: 'Greece'
    },
    HER: {
        id: 1789,
        airportCode: 'HER',
        cityCode: 'HER',
        countryCode: 'GR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Heraklion',
        airportNameEn: 'Heraklion International Airport, "Nikos Kazantzakis"',
        cityNameVi: 'Heraklion',
        cityNameEn: 'Heraklion',
        countryNameVi: 'Hy lạp',
        countryNameEn: 'Greece'
    },
    CFU: {
        id: 1788,
        airportCode: 'CFU',
        cityCode: 'CFU',
        countryCode: 'GR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Ioannis Kapodistrias',
        airportNameEn: 'Ioannis Kapodistrias International Airport ',
        cityNameVi: 'Corfu',
        cityNameEn: 'Corfu',
        countryNameVi: 'Hy lạp',
        countryNameEn: 'Greece'
    },
    JKH: {
        id: 1787,
        airportCode: 'JKH',
        cityCode: 'JKH',
        countryCode: 'GR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Chios Island National',
        airportNameEn: 'Chios Island National Airport',
        cityNameVi: 'Chios',
        cityNameEn: 'Chios',
        countryNameVi: 'Hy lạp',
        countryNameEn: 'Greece'
    },
    CHQ: {
        id: 1786,
        airportCode: 'CHQ',
        cityCode: 'CHQ',
        countryCode: 'GR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Chania',
        airportNameEn: 'Chania International Airport ',
        cityNameVi: 'Chania',
        cityNameEn: 'Chania',
        countryNameVi: 'Hy lạp',
        countryNameEn: 'Greece'
    },
    ATH: {
        id: 1785,
        airportCode: 'ATH',
        cityCode: 'ATH',
        countryCode: 'GR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Athens',
        airportNameEn: 'Athens International Airport',
        cityNameVi: 'Athens',
        cityNameEn: 'Athens',
        countryNameVi: 'Hy lạp',
        countryNameEn: 'Greece'
    },
    JTY: {
        id: 1784,
        airportCode: 'JTY',
        cityCode: 'JTY',
        countryCode: 'GR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Astypalaia',
        airportNameEn: 'Astypalaia Airport',
        cityNameVi: 'Astypalea',
        cityNameEn: 'Astypalea',
        countryNameVi: 'Hy lạp',
        countryNameEn: 'Greece'
    },
    AXD: {
        id: 1783,
        airportCode: 'AXD',
        cityCode: 'AXD',
        countryCode: 'GR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Dimokritos',
        airportNameEn: 'Dimokritos Airport',
        cityNameVi: 'Alexandroupolis',
        cityNameEn: 'Alexandroupolis',
        countryNameVi: 'Hy lạp',
        countryNameEn: 'Greece'
    },
    GIB: {
        id: 1782,
        airportCode: 'GIB',
        cityCode: 'GIB',
        countryCode: 'GI',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Gibraltar',
        airportNameEn: 'Gibraltar Airport ',
        cityNameVi: 'Gibraltar',
        cityNameEn: 'Gibraltar',
        countryNameVi: 'Gibraltar',
        countryNameEn: 'Gibraltar'
    },
    ACC: {
        id: 1781,
        airportCode: 'ACC',
        cityCode: 'ACC',
        countryCode: 'GH',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Kotoka',
        airportNameEn: 'Kotoka International Airport',
        cityNameVi: 'Accra',
        cityNameEn: 'Accra',
        countryNameVi: 'Ghana',
        countryNameEn: 'Ghana'
    },
    ZWS: {
        id: 1780,
        airportCode: 'ZWS',
        cityCode: 'ZWS',
        countryCode: 'DE',
        continentCode: 'EU',
        airportNameVi: 'Stuttgart Hauptbahnhof',
        airportNameEn: 'Stuttgart Hauptbahnhof',
        cityNameVi: 'Stuttgart',
        cityNameEn: 'Stuttgart',
        countryNameVi: 'Đức',
        countryNameEn: 'Germany'
    },
    NRN: {
        id: 1779,
        airportCode: 'NRN',
        cityCode: 'NRN',
        countryCode: 'DE',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Weeze',
        airportNameEn: 'Weeze Airport ',
        cityNameVi: 'Niederrhein',
        cityNameEn: 'Niederrhein',
        countryNameVi: 'Đức',
        countryNameEn: 'Germany'
    },
    MGL: {
        id: 1778,
        airportCode: 'MGL',
        cityCode: 'MGL',
        countryCode: 'DE',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Düsseldorf-Mönchengladbach',
        airportNameEn: 'Düsseldorf-Mönchengladbach Airport',
        cityNameVi: 'Moenchengladbach',
        cityNameEn: 'Moenchengladbach',
        countryNameVi: 'Đức',
        countryNameEn: 'Germany'
    },
    GWT: {
        id: 1777,
        airportCode: 'GWT',
        cityCode: 'GWT',
        countryCode: 'DE',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Sylt',
        airportNameEn: 'Sylt Airport ',
        cityNameVi: 'Westerland',
        cityNameEn: 'Westerland',
        countryNameVi: 'Đức',
        countryNameEn: 'Germany'
    },
    STR: {
        id: 1776,
        airportCode: 'STR',
        cityCode: 'STR',
        countryCode: 'DE',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Stuttgart',
        airportNameEn: 'Stuttgart Airport',
        cityNameVi: 'Stuttgart',
        cityNameEn: 'Stuttgart',
        countryNameVi: 'Đức',
        countryNameEn: 'Germany'
    },
    SCN: {
        id: 1775,
        airportCode: 'SCN',
        cityCode: 'SCN',
        countryCode: 'DE',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Saarbrücken',
        airportNameEn: 'Saarbrücken Airport',
        cityNameVi: 'Saarbruecken',
        cityNameEn: 'Saarbruecken',
        countryNameVi: 'Đức',
        countryNameEn: 'Germany'
    },
    RLG: {
        id: 1774,
        airportCode: 'RLG',
        cityCode: 'RLG',
        countryCode: 'DE',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Rostock Laage',
        airportNameEn: 'Rostock Laage Airport',
        cityNameVi: 'Rostock Laage',
        cityNameEn: 'Rostock Laage',
        countryNameVi: 'Đức',
        countryNameEn: 'Germany'
    },
    PAD: {
        id: 1773,
        airportCode: 'PAD',
        cityCode: 'PAD',
        countryCode: 'DE',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Paderborn/Lippstadt',
        airportNameEn: 'Paderborn/Lippstadt Airport',
        cityNameVi: 'Paderborn',
        cityNameEn: 'Paderborn',
        countryNameVi: 'Đức',
        countryNameEn: 'Germany'
    },
    NUE: {
        id: 1772,
        airportCode: 'NUE',
        cityCode: 'NUE',
        countryCode: 'DE',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Nuremberg',
        airportNameEn: 'Nuremberg Airport',
        cityNameVi: 'Nuremberg',
        cityNameEn: 'Nuremberg',
        countryNameVi: 'Đức',
        countryNameEn: 'Germany'
    },
    MUC: {
        id: 1771,
        airportCode: 'MUC',
        cityCode: 'MUC',
        countryCode: 'DE',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Munich',
        airportNameEn: 'Munich Airport ',
        cityNameVi: 'Mu-ních',
        cityNameEn: 'Munich',
        countryNameVi: 'Đức',
        countryNameEn: 'Germany'
    },
    AGB: {
        id: 1770,
        airportCode: 'AGB',
        cityCode: 'MUC',
        countryCode: 'DE',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Augsburg',
        airportNameEn: 'Augsburg Airport',
        cityNameVi: 'Mu-ních',
        cityNameEn: 'Munich',
        countryNameVi: 'Đức',
        countryNameEn: 'Germany'
    },
    FMO: {
        id: 1769,
        airportCode: 'FMO',
        cityCode: 'FMO',
        countryCode: 'DE',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Münster Osnabrück',
        airportNameEn: 'Münster Osnabrück International Airport',
        cityNameVi: 'Muenster',
        cityNameEn: 'Muenster',
        countryNameVi: 'Đức',
        countryNameEn: 'Germany'
    },
    LBC: {
        id: 1768,
        airportCode: 'LBC',
        cityCode: 'LBC',
        countryCode: 'DE',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Lübeck',
        airportNameEn: 'Lübeck Airport ',
        cityNameVi: 'Luebeck',
        cityNameEn: 'Luebeck',
        countryNameVi: 'Đức',
        countryNameEn: 'Germany'
    },
    LEJ: {
        id: 1767,
        airportCode: 'LEJ',
        cityCode: 'LEJ',
        countryCode: 'DE',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Leipzig/Halle',
        airportNameEn: 'Leipzig/Halle Airport',
        cityNameVi: 'Lép-zíc',
        cityNameEn: 'Leipzig',
        countryNameVi: 'Đức',
        countryNameEn: 'Germany'
    },
    KEL: {
        id: 1766,
        airportCode: 'KEL',
        cityCode: 'KEL',
        countryCode: 'DE',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Kiel Holtenau',
        airportNameEn: 'Kiel Holtenau Airport',
        cityNameVi: 'Kiel',
        cityNameEn: 'Kiel',
        countryNameVi: 'Đức',
        countryNameEn: 'Germany'
    },
    KSF: {
        id: 1765,
        airportCode: 'KSF',
        cityCode: 'KSF',
        countryCode: 'DE',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Kassel Calden',
        airportNameEn: 'Kassel Calden Airport',
        cityNameVi: 'Kassel',
        cityNameEn: 'Kassel',
        countryNameVi: 'Đức',
        countryNameEn: 'Germany'
    },
    FKB: {
        id: 1764,
        airportCode: 'FKB',
        cityCode: 'FKB',
        countryCode: 'DE',
        continentCode: 'EU',
        airportNameVi: 'Baden Airpark ',
        airportNameEn: 'Baden Airpark ',
        cityNameVi: 'Karlsruhe Baden Baden',
        cityNameEn: 'Karlsruhe Baden Baden',
        countryNameVi: 'Đức',
        countryNameEn: 'Germany'
    },
    HDF: {
        id: 1763,
        airportCode: 'HDF',
        cityCode: 'HDF',
        countryCode: 'DE',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Heringsdorf',
        airportNameEn: 'Heringsdorf Airport',
        cityNameVi: 'Heringsdorf',
        cityNameEn: 'Heringsdorf',
        countryNameVi: 'Đức',
        countryNameEn: 'Germany'
    },
    HAJ: {
        id: 1762,
        airportCode: 'HAJ',
        cityCode: 'HAJ',
        countryCode: 'DE',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Hannover',
        airportNameEn: 'Hannover Airport',
        cityNameVi: 'Hanover',
        cityNameEn: 'Hanover',
        countryNameVi: 'Đức',
        countryNameEn: 'Germany'
    },
    HAM: {
        id: 1761,
        airportCode: 'HAM',
        cityCode: 'HAM',
        countryCode: 'DE',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Hamburg',
        airportNameEn: 'Hamburg Airport ',
        cityNameVi: 'Hăm-buốc',
        cityNameEn: 'Hamburg',
        countryNameVi: 'Đức',
        countryNameEn: 'Germany'
    },
    FDH: {
        id: 1760,
        airportCode: 'FDH',
        cityCode: 'FDH',
        countryCode: 'DE',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Friedrichshafen',
        airportNameEn: 'Friedrichshafen Airport ',
        cityNameVi: 'Friedrichshafen',
        cityNameEn: 'Friedrichshafen',
        countryNameVi: 'Đức',
        countryNameEn: 'Germany'
    },
    HHN: {
        id: 1759,
        airportCode: 'HHN',
        cityCode: 'FRA',
        countryCode: 'DE',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Frankfurt-Hahn',
        airportNameEn: 'Frankfurt-Hahn Airport',
        cityNameVi: 'Frankfurt',
        cityNameEn: 'Frankfurt',
        countryNameVi: 'Đức',
        countryNameEn: 'Germany'
    },
    FRA: {
        id: 1758,
        airportCode: 'FRA',
        cityCode: 'FRA',
        countryCode: 'DE',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Frankfurt',
        airportNameEn: 'Frankfurt Airport',
        cityNameVi: 'Frankfurt',
        cityNameEn: 'Frankfurt',
        countryNameVi: 'Đức',
        countryNameEn: 'Germany'
    },
    ERF: {
        id: 1757,
        airportCode: 'ERF',
        cityCode: 'ERF',
        countryCode: 'DE',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Erfurt',
        airportNameEn: 'Erfurt Airport',
        cityNameVi: 'Erfurt',
        cityNameEn: 'Erfurt',
        countryNameVi: 'Đức',
        countryNameEn: 'Germany'
    },
    DUS: {
        id: 1756,
        airportCode: 'DUS',
        cityCode: 'DUS',
        countryCode: 'DE',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Düsseldorf',
        airportNameEn: 'Düsseldorf International Airport',
        cityNameVi: 'Dusseldorf',
        cityNameEn: 'Dusseldorf',
        countryNameVi: 'Đức',
        countryNameEn: 'Germany'
    },
    DRS: {
        id: 1755,
        airportCode: 'DRS',
        cityCode: 'DRS',
        countryCode: 'DE',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Dresden',
        airportNameEn: 'Dresden Airport',
        cityNameVi: 'Dresden',
        cityNameEn: 'Dresden',
        countryNameVi: 'Đức',
        countryNameEn: 'Germany'
    },
    DTM: {
        id: 1754,
        airportCode: 'DTM',
        cityCode: 'DTM',
        countryCode: 'DE',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Dortmund',
        airportNameEn: 'Dortmund Airport',
        cityNameVi: 'Dortmund',
        cityNameEn: 'Dortmund',
        countryNameVi: 'Đức',
        countryNameEn: 'Germany'
    },
    CGN: {
        id: 1753,
        airportCode: 'CGN',
        cityCode: 'CGN',
        countryCode: 'DE',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Cologne Bonn',
        airportNameEn: 'Cologne Bonn Airport ',
        cityNameVi: 'Cologne',
        cityNameEn: 'Cologne',
        countryNameVi: 'Đức',
        countryNameEn: 'Germany'
    },
    BRE: {
        id: 1752,
        airportCode: 'BRE',
        cityCode: 'BRE',
        countryCode: 'DE',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Bremen',
        airportNameEn: 'Bremen Airport',
        cityNameVi: 'Bremen',
        cityNameEn: 'Bremen',
        countryNameVi: 'Đức',
        countryNameEn: 'Germany'
    },
    TXL: {
        id: 1751,
        airportCode: 'TXL',
        cityCode: 'BER',
        countryCode: 'DE',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Berlin Tegel',
        airportNameEn: 'Berlin Tegel Airport',
        cityNameVi: 'Berlin',
        cityNameEn: 'Berlin',
        countryNameVi: 'Đức',
        countryNameEn: 'Germany'
    },
    SXF: {
        id: 1750,
        airportCode: 'SXF',
        cityCode: 'BER',
        countryCode: 'DE',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Berlin Schönefeld',
        airportNameEn: 'Berlin Schönefeld Airport',
        cityNameVi: 'Berlin',
        cityNameEn: 'Berlin',
        countryNameVi: 'Đức',
        countryNameEn: 'Germany'
    },
    TBS: {
        id: 1749,
        airportCode: 'TBS',
        cityCode: 'TBS',
        countryCode: 'GE',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Tbilisi',
        airportNameEn: 'Tbilisi International Airport',
        cityNameVi: 'Tbilisi',
        cityNameEn: 'Tbilisi',
        countryNameVi: 'Georgia',
        countryNameEn: 'Georgia'
    },
    BUS: {
        id: 1748,
        airportCode: 'BUS',
        cityCode: 'BUS',
        countryCode: 'GE',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Batumi',
        airportNameEn: 'Batumi Airport ',
        cityNameVi: 'Batumi',
        cityNameEn: 'Batumi',
        countryNameVi: 'Georgia',
        countryNameEn: 'Georgia'
    },
    BJL: {
        id: 1747,
        airportCode: 'BJL',
        cityCode: 'BJL',
        countryCode: 'GM',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Banjul',
        airportNameEn: 'Banjul International Airport',
        cityNameVi: 'Banjul',
        cityNameEn: 'Banjul',
        countryNameVi: 'Gambia',
        countryNameEn: 'Gambia'
    },
    CSG: {
        id: 1746,
        airportCode: 'CSG',
        cityCode: 'CSG',
        countryCode: 'GA',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Columbus',
        airportNameEn: 'Columbus Airport',
        cityNameVi: 'Columbus',
        cityNameEn: 'Columbus',
        countryNameVi: 'Gabon',
        countryNameEn: 'Gabon'
    },
    LBV: {
        id: 1745,
        airportCode: 'LBV',
        cityCode: 'LBV',
        countryCode: 'GA',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Libreville',
        airportNameEn: 'Libreville International Airport',
        cityNameVi: 'Libreville',
        cityNameEn: 'Libreville',
        countryNameVi: 'Gabon',
        countryNameEn: 'Gabon'
    },
    ABY: {
        id: 1744,
        airportCode: 'ABY',
        cityCode: 'ABY',
        countryCode: 'GA',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Southwest Georgia Regional',
        airportNameEn: 'Southwest Georgia Regional Airport',
        cityNameVi: 'Albany',
        cityNameEn: 'Albany',
        countryNameVi: 'Gabon',
        countryNameEn: 'Gabon'
    },
    GMR: {
        id: 1743,
        airportCode: 'GMR',
        cityCode: 'GMR',
        countryCode: 'PF',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Totegegie',
        airportNameEn: 'Totegegie Airport ',
        cityNameVi: 'Gambier Is',
        cityNameEn: 'Gambier Is',
        countryNameVi: 'Pô li sê ni thuộc Pháp',
        countryNameEn: 'French Polynesia'
    },
    HOI: {
        id: 1742,
        airportCode: 'HOI',
        cityCode: 'HOI',
        countryCode: 'PF',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Hao',
        airportNameEn: 'Hao Airport',
        cityNameVi: 'Hao Island',
        cityNameEn: 'Hao Island',
        countryNameVi: 'Pô li sê ni thuộc Pháp',
        countryNameEn: 'French Polynesia'
    },
    FUN: {
        id: 1741,
        airportCode: 'FUN',
        cityCode: 'FUN',
        countryCode: 'PF',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Funafuti',
        airportNameEn: 'Funafuti International Airport',
        cityNameVi: 'Funafuti Atol',
        cityNameEn: 'Funafuti Atol',
        countryNameVi: 'Pô li sê ni thuộc Pháp',
        countryNameEn: 'French Polynesia'
    },
    PPT: {
        id: 1740,
        airportCode: 'PPT',
        cityCode: 'PPT',
        countryCode: 'PF',
        continentCode: 'OC',
        airportNameVi: "Sân bay Faa'a",
        airportNameEn: "Faa'a International Airport",
        cityNameVi: 'Papeete',
        cityNameEn: 'Papeete',
        countryNameVi: 'Pô li sê ni thuộc Pháp',
        countryNameEn: 'French Polynesia'
    },
    BOB: {
        id: 1739,
        airportCode: 'BOB',
        cityCode: 'BOB',
        countryCode: 'PF',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Bora Bora',
        airportNameEn: 'Bora Bora Airport ',
        cityNameVi: 'Bora Bora',
        cityNameEn: 'Bora Bora',
        countryNameVi: 'Pô li sê ni thuộc Pháp',
        countryNameEn: 'French Polynesia'
    },
    AUQ: {
        id: 1738,
        airportCode: 'AUQ',
        cityCode: 'AUQ',
        countryCode: 'PF',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Atuona',
        airportNameEn: 'Atuona Airport',
        cityNameVi: 'Atuona',
        cityNameEn: 'Atuona',
        countryNameVi: 'Pô li sê ni thuộc Pháp',
        countryNameEn: 'French Polynesia'
    },
    CAY: {
        id: 1737,
        airportCode: 'CAY',
        cityCode: 'CAY',
        countryCode: 'GF',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Cayenne-Rochambeau',
        airportNameEn: 'Cayenne-Rochambeau Airport',
        cityNameVi: 'Cayenne',
        cityNameEn: 'Cayenne',
        countryNameVi: 'Ghi nê thuộc Pháp',
        countryNameEn: 'French Guiana'
    },
    XCR: {
        id: 1736,
        airportCode: 'XCR',
        cityCode: 'XCR',
        countryCode: 'FR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Vatry',
        airportNameEn: 'Vatry International Airport',
        cityNameVi: 'Paris',
        cityNameEn: 'Paris',
        countryNameVi: 'Pháp',
        countryNameEn: 'France'
    },
    VAF: {
        id: 1735,
        airportCode: 'VAF',
        cityCode: 'VAF',
        countryCode: 'FR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Valence-Chabeuil',
        airportNameEn: 'Valence-Chabeuil Airport',
        cityNameVi: 'Valence',
        cityNameEn: 'Valence',
        countryNameVi: 'Pháp',
        countryNameEn: 'France'
    },
    SNR: {
        id: 1734,
        airportCode: 'SNR',
        cityCode: 'SNR',
        countryCode: 'FR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Saint-Nazaire Montoir',
        airportNameEn: 'Saint-Nazaire Montoir Airport',
        cityNameVi: 'St Nazaire',
        cityNameEn: 'St Nazaire',
        countryNameVi: 'Pháp',
        countryNameEn: 'France'
    },
    FSP: {
        id: 1733,
        airportCode: 'FSP',
        cityCode: 'FSP',
        countryCode: 'FR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Saint Pierre',
        airportNameEn: 'Saint Pierre Airport',
        cityNameVi: 'St Pierre',
        cityNameEn: 'St Pierre',
        countryNameVi: 'Pháp',
        countryNameEn: 'France'
    },
    UIP: {
        id: 1732,
        airportCode: 'UIP',
        cityCode: 'UIP',
        countryCode: 'FR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Quimper - Cornouaille',
        airportNameEn: 'Quimper - Cornouaille Airport',
        cityNameVi: 'Quimper',
        cityNameEn: 'Quimper',
        countryNameVi: 'Pháp',
        countryNameEn: 'France'
    },
    POX: {
        id: 1731,
        airportCode: 'POX',
        cityCode: 'POX',
        countryCode: 'FR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Pontoise',
        airportNameEn: 'Pontoise Airport',
        cityNameVi: 'Pontoise',
        cityNameEn: 'Pontoise',
        countryNameVi: 'Pháp',
        countryNameEn: 'France'
    },
    LME: {
        id: 1730,
        airportCode: 'LME',
        cityCode: 'LME',
        countryCode: 'FR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Le Mans Arnage',
        airportNameEn: 'Le Mans Arnage Airport',
        cityNameVi: 'Le Mans',
        cityNameEn: 'Le Mans',
        countryNameVi: 'Pháp',
        countryNameEn: 'France'
    },
    LAI: {
        id: 1729,
        airportCode: 'LAI',
        cityCode: 'LAI',
        countryCode: 'FR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Lannion - Côte de Granit',
        airportNameEn: 'Lannion - Côte de Granit Airport',
        cityNameVi: 'Lannion',
        cityNameEn: 'Lannion',
        countryNameVi: 'Pháp',
        countryNameEn: 'France'
    },
    BVE: {
        id: 1728,
        airportCode: 'BVE',
        cityCode: 'BVE',
        countryCode: 'FR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Brive – Souillac',
        airportNameEn: 'Brive – Souillac Airport',
        cityNameVi: 'Brive La Gaill',
        cityNameEn: 'Brive La Gaill',
        countryNameVi: 'Pháp',
        countryNameEn: 'France'
    },
    EGC: {
        id: 1727,
        airportCode: 'EGC',
        cityCode: 'EGC',
        countryCode: 'FR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Bergerac-Roumanière',
        airportNameEn: 'Bergerac-Roumanière Airport',
        cityNameVi: 'Bergerac',
        cityNameEn: 'Bergerac',
        countryNameVi: 'Pháp',
        countryNameEn: 'France'
    },
    TUF: {
        id: 1726,
        airportCode: 'TUF',
        cityCode: 'TUF',
        countryCode: 'FR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Tours Val de Loire',
        airportNameEn: 'Tours Val de Loire Airport',
        cityNameVi: 'Tours',
        cityNameEn: 'Tours',
        countryNameVi: 'Pháp',
        countryNameEn: 'France'
    },
    TLS: {
        id: 1725,
        airportCode: 'TLS',
        cityCode: 'TLS',
        countryCode: 'FR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Toulouse Blagnac',
        airportNameEn: 'Toulouse Blagnac International Airport',
        cityNameVi: 'Toulouse',
        cityNameEn: 'Toulouse',
        countryNameVi: 'Pháp',
        countryNameEn: 'France'
    },
    TLN: {
        id: 1724,
        airportCode: 'TLN',
        cityCode: 'TLN',
        countryCode: 'FR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Toulon-Hyères',
        airportNameEn: 'Toulon-Hyères Airport',
        cityNameVi: 'Toulon',
        cityNameEn: 'Toulon',
        countryNameVi: 'Pháp',
        countryNameEn: 'France'
    },
    SXB: {
        id: 1723,
        airportCode: 'SXB',
        cityCode: 'SXB',
        countryCode: 'FR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Strasbourg Entzheim',
        airportNameEn: 'Strasbourg Entzheim International Airport',
        cityNameVi: 'Strasbourg',
        cityNameEn: 'Strasbourg',
        countryNameVi: 'Pháp',
        countryNameEn: 'France'
    },
    LTT: {
        id: 1722,
        airportCode: 'LTT',
        cityCode: 'LTT',
        countryCode: 'FR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay La Môle - Saint-Tropez',
        airportNameEn: 'La Môle - Saint-Tropez Airport',
        cityNameVi: 'St Tropez',
        cityNameEn: 'St Tropez',
        countryNameVi: 'Pháp',
        countryNameEn: 'France'
    },
    EBU: {
        id: 1721,
        airportCode: 'EBU',
        cityCode: 'EBU',
        countryCode: 'FR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Saint-Étienne - Bouthéon',
        airportNameEn: 'Saint-Étienne - Bouthéon Airport',
        cityNameVi: 'St Etienne',
        cityNameEn: 'St Etienne',
        countryNameVi: 'Pháp',
        countryNameEn: 'France'
    },
    SBH: {
        id: 1720,
        airportCode: 'SBH',
        cityCode: 'SBH',
        countryCode: 'FR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Gustaf III',
        airportNameEn: 'Gustaf III Airport',
        cityNameVi: 'St Barthelemy',
        cityNameEn: 'St Barthelemy',
        countryNameVi: 'Pháp',
        countryNameEn: 'France'
    },
    RDZ: {
        id: 1719,
        airportCode: 'RDZ',
        cityCode: 'RDZ',
        countryCode: 'FR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Rodez-Marcillac',
        airportNameEn: 'Rodez-Marcillac Airport',
        cityNameVi: 'Rodez',
        cityNameEn: 'Rodez',
        countryNameVi: 'Pháp',
        countryNameEn: 'France'
    },
    RNS: {
        id: 1718,
        airportCode: 'RNS',
        cityCode: 'RNS',
        countryCode: 'FR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay St. Jacques',
        airportNameEn: 'St. Jacques Airport',
        cityNameVi: 'Rennes',
        cityNameEn: 'Rennes',
        countryNameVi: 'Pháp',
        countryNameEn: 'France'
    },
    PIS: {
        id: 1717,
        airportCode: 'PIS',
        cityCode: 'PIS',
        countryCode: 'FR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Poitiers - Biard',
        airportNameEn: 'Poitiers - Biard Airport',
        cityNameVi: 'Poitiers',
        cityNameEn: 'Poitiers',
        countryNameVi: 'Pháp',
        countryNameEn: 'France'
    },
    PGF: {
        id: 1716,
        airportCode: 'PGF',
        cityCode: 'PGF',
        countryCode: 'FR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Perpignan – Rivesaltes',
        airportNameEn: 'Perpignan – Rivesaltes Airport',
        cityNameVi: 'Perpignan',
        cityNameEn: 'Perpignan',
        countryNameVi: 'Pháp',
        countryNameEn: 'France'
    },
    PGX: {
        id: 1715,
        airportCode: 'PGX',
        cityCode: 'PGX',
        countryCode: 'FR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Périgueux Bassillac',
        airportNameEn: 'Périgueux Bassillac Airport',
        cityNameVi: 'Perigueux',
        cityNameEn: 'Perigueux',
        countryNameVi: 'Pháp',
        countryNameEn: 'France'
    },
    PUF: {
        id: 1714,
        airportCode: 'PUF',
        cityCode: 'PUF',
        countryCode: 'FR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Pau Pyrénées',
        airportNameEn: 'Pau Pyrénées Airport',
        cityNameVi: 'Pau Fr',
        cityNameEn: 'Pau Fr',
        countryNameVi: 'Pháp',
        countryNameEn: 'France'
    },
    PAR: {
        id: 1713,
        airportCode: 'PAR',
        cityCode: 'PAR',
        countryCode: 'FR',
        continentCode: 'EU',
        airportNameVi: 'Tất cả các sân bay',
        airportNameEn: 'All Airports',
        cityNameVi: 'Paris',
        cityNameEn: 'Paris',
        countryNameVi: 'Pháp',
        countryNameEn: 'France'
    },
    JDP: {
        id: 1712,
        airportCode: 'JDP',
        cityCode: 'PAR',
        countryCode: 'FR',
        continentCode: 'EU',
        airportNameVi: 'Paris - Issy-les-Moulineaux Heliport',
        airportNameEn: 'Paris - Issy-les-Moulineaux Heliport',
        cityNameVi: 'Paris',
        cityNameEn: 'Paris',
        countryNameVi: 'Pháp',
        countryNameEn: 'France'
    },
    BVA: {
        id: 1711,
        airportCode: 'BVA',
        cityCode: 'PAR',
        countryCode: 'FR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Paris Beauvais Tillé',
        airportNameEn: 'Paris Beauvais Tillé Airport',
        cityNameVi: 'Paris',
        cityNameEn: 'Paris',
        countryNameVi: 'Pháp',
        countryNameEn: 'France'
    },
    LBG: {
        id: 1710,
        airportCode: 'LBG',
        cityCode: 'PAR',
        countryCode: 'FR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Paris - Le Bourget',
        airportNameEn: 'Paris - Le Bourget Airport',
        cityNameVi: 'Paris',
        cityNameEn: 'Paris',
        countryNameVi: 'Pháp',
        countryNameEn: 'France'
    },
    ORY: {
        id: 1709,
        airportCode: 'ORY',
        cityCode: 'PAR',
        countryCode: 'FR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Orly',
        airportNameEn: 'Orly International Airport',
        cityNameVi: 'Paris',
        cityNameEn: 'Paris',
        countryNameVi: 'Pháp',
        countryNameEn: 'France'
    },
    CDG: {
        id: 1708,
        airportCode: 'CDG',
        cityCode: 'PAR',
        countryCode: 'FR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Charles de Gaulle',
        airportNameEn: 'Charles de Gaulle Airport ',
        cityNameVi: 'Paris',
        cityNameEn: 'Paris',
        countryNameVi: 'Pháp',
        countryNameEn: 'France'
    },
    FNI: {
        id: 1707,
        airportCode: 'FNI',
        cityCode: 'FNI',
        countryCode: 'FR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Garons',
        airportNameEn: 'Garons Airport',
        cityNameVi: 'Nimes',
        cityNameEn: 'Nimes',
        countryNameVi: 'Pháp',
        countryNameEn: 'France'
    },
    NCE: {
        id: 1706,
        airportCode: 'NCE',
        cityCode: 'NCE',
        countryCode: 'FR',
        continentCode: 'EU',
        airportNameVi: "Sân bay Côte d'Azur",
        airportNameEn: "Côte d'Azur International Airport",
        cityNameVi: 'Nice',
        cityNameEn: 'Nice',
        countryNameVi: 'Pháp',
        countryNameEn: 'France'
    },
    NTE: {
        id: 1705,
        airportCode: 'NTE',
        cityCode: 'NTE',
        countryCode: 'FR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Nantes Atlantique',
        airportNameEn: 'Nantes Atlantique Airport',
        cityNameVi: 'Nantes',
        cityNameEn: 'Nantes',
        countryNameVi: 'Pháp',
        countryNameEn: 'France'
    },
    ENC: {
        id: 1704,
        airportCode: 'ENC',
        cityCode: 'ENC',
        countryCode: 'FR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Essey',
        airportNameEn: 'Essey Airport',
        cityNameVi: 'Nancy',
        cityNameEn: 'Nancy',
        countryNameVi: 'Pháp',
        countryNameEn: 'France'
    },
    MLH: {
        id: 1703,
        airportCode: 'MLH',
        cityCode: 'MLH',
        countryCode: 'FR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Euro',
        airportNameEn: 'EuroAirport Basel-Mulhouse-Freiburg',
        cityNameVi: 'Mulhouse',
        cityNameEn: 'Mulhouse',
        countryNameVi: 'Pháp',
        countryNameEn: 'France'
    },
    MPL: {
        id: 1702,
        airportCode: 'MPL',
        cityCode: 'MPL',
        countryCode: 'FR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Mediterranée',
        airportNameEn: 'Mediterranée Airport ',
        cityNameVi: 'Montpellier',
        cityNameEn: 'Montpellier',
        countryNameVi: 'Pháp',
        countryNameEn: 'France'
    },
    ETZ: {
        id: 1701,
        airportCode: 'ETZ',
        cityCode: 'ETZ',
        countryCode: 'FR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Metz-Nancy-Lorraine',
        airportNameEn: 'Metz-Nancy-Lorraine Airport',
        cityNameVi: 'Metz Nancy',
        cityNameEn: 'Metz Nancy',
        countryNameVi: 'Pháp',
        countryNameEn: 'France'
    },
    MZM: {
        id: 1700,
        airportCode: 'MZM',
        cityCode: 'MZM',
        countryCode: 'FR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Frescaty',
        airportNameEn: 'Frescaty Airport',
        cityNameVi: 'Metz',
        cityNameEn: 'Metz',
        countryNameVi: 'Pháp',
        countryNameEn: 'France'
    },
    MRS: {
        id: 1699,
        airportCode: 'MRS',
        cityCode: 'MRS',
        countryCode: 'FR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Marseille Provence',
        airportNameEn: 'Marseille Provence Airport',
        cityNameVi: 'Mác-xây',
        cityNameEn: 'Marseille',
        countryNameVi: 'Pháp',
        countryNameEn: 'France'
    },
    LYS: {
        id: 1698,
        airportCode: 'LYS',
        cityCode: 'LYS',
        countryCode: 'FR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Lyon-Saint Exupéry',
        airportNameEn: 'Lyon-Saint Exupéry Airport ',
        cityNameVi: 'Lyon',
        cityNameEn: 'Lyon',
        countryNameVi: 'Pháp',
        countryNameEn: 'France'
    },
    LYN: {
        id: 1697,
        airportCode: 'LYN',
        cityCode: 'LYS',
        countryCode: 'FR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Lyon Bron',
        airportNameEn: 'Lyon Bron Airport',
        cityNameVi: 'Lyon',
        cityNameEn: 'Lyon',
        countryNameVi: 'Pháp',
        countryNameEn: 'France'
    },
    LDE: {
        id: 1696,
        airportCode: 'LDE',
        cityCode: 'LDE',
        countryCode: 'FR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Tarbes - Lourdes Pyrenees',
        airportNameEn: 'Tarbes - Lourdes Pyrenees Airport',
        cityNameVi: 'Lourdes',
        cityNameEn: 'Lourdes',
        countryNameVi: 'Pháp',
        countryNameEn: 'France'
    },
    LRT: {
        id: 1695,
        airportCode: 'LRT',
        cityCode: 'LRT',
        countryCode: 'FR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Lorient South Brittany',
        airportNameEn: 'Lorient South Brittany Airport',
        cityNameVi: 'Lorient',
        cityNameEn: 'Lorient',
        countryNameVi: 'Pháp',
        countryNameEn: 'France'
    },
    LIG: {
        id: 1694,
        airportCode: 'LIG',
        cityCode: 'LIG',
        countryCode: 'FR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Bellegarde',
        airportNameEn: 'Bellegarde Airport',
        cityNameVi: 'Limoges',
        cityNameEn: 'Limoges',
        countryNameVi: 'Pháp',
        countryNameEn: 'France'
    },
    LIL: {
        id: 1693,
        airportCode: 'LIL',
        cityCode: 'LIL',
        countryCode: 'FR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Lille Lesquin',
        airportNameEn: 'Lille Lesquin International Airport',
        cityNameVi: 'Lille',
        cityNameEn: 'Lille',
        countryNameVi: 'Pháp',
        countryNameEn: 'France'
    },
    LPY: {
        id: 1692,
        airportCode: 'LPY',
        cityCode: 'LPY',
        countryCode: 'FR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Loudes',
        airportNameEn: 'Loudes Airport',
        cityNameVi: 'Le Puy',
        cityNameEn: 'Le Puy',
        countryNameVi: 'Pháp',
        countryNameEn: 'France'
    },
    LEH: {
        id: 1691,
        airportCode: 'LEH',
        cityCode: 'LEH',
        countryCode: 'FR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Le Havre Octeville',
        airportNameEn: 'Le Havre Octeville Airport',
        cityNameVi: 'Le Havre',
        cityNameEn: 'Le Havre',
        countryNameVi: 'Pháp',
        countryNameEn: 'France'
    },
    LRH: {
        id: 1690,
        airportCode: 'LRH',
        cityCode: 'LRH',
        countryCode: 'FR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay La Rochelle - Île de Ré',
        airportNameEn: 'La Rochelle - Île de Ré Airport',
        cityNameVi: 'La Rochelle',
        cityNameEn: 'La Rochelle',
        countryNameVi: 'Pháp',
        countryNameEn: 'France'
    },
    GNB: {
        id: 1689,
        airportCode: 'GNB',
        cityCode: 'GNB',
        countryCode: 'FR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Grenoble-Isère',
        airportNameEn: 'Grenoble-Isère Airport',
        cityNameVi: 'Grenoble',
        cityNameEn: 'Grenoble',
        countryNameVi: 'Pháp',
        countryNameEn: 'France'
    },
    FSC: {
        id: 1688,
        airportCode: 'FSC',
        cityCode: 'FSC',
        countryCode: 'FR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Sud Corse',
        airportNameEn: 'Sud Corse Airport',
        cityNameVi: 'Figari',
        cityNameEn: 'Figari',
        countryNameVi: 'Pháp',
        countryNameEn: 'France'
    },
    DLE: {
        id: 1687,
        airportCode: 'DLE',
        cityCode: 'DLE',
        countryCode: 'FR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Dôle-Tavaux',
        airportNameEn: 'Dôle-Tavaux Airport',
        cityNameVi: 'Dole',
        cityNameEn: 'Dole',
        countryNameVi: 'Pháp',
        countryNameEn: 'France'
    },
    DNR: {
        id: 1686,
        airportCode: 'DNR',
        cityCode: 'DNR',
        countryCode: 'FR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Dinard - Pleurtuit - Saint-Malo',
        airportNameEn: 'Dinard - Pleurtuit - Saint-Malo Airport',
        cityNameVi: 'Dinard',
        cityNameEn: 'Dinard',
        countryNameVi: 'Pháp',
        countryNameEn: 'France'
    },
    DIJ: {
        id: 1685,
        airportCode: 'DIJ',
        cityCode: 'DIJ',
        countryCode: 'FR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Dijon-Bourgogne',
        airportNameEn: 'Dijon-Bourgogne Airport',
        cityNameVi: 'Dijon',
        cityNameEn: 'Dijon',
        countryNameVi: 'Pháp',
        countryNameEn: 'France'
    },
    DOL: {
        id: 1684,
        airportCode: 'DOL',
        cityCode: 'DOL',
        countryCode: 'FR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Deauville Saint-Gatien',
        airportNameEn: 'Deauville Saint-Gatien Airport',
        cityNameVi: 'Deauville',
        cityNameEn: 'Deauville',
        countryNameVi: 'Pháp',
        countryNameEn: 'France'
    },
    CFE: {
        id: 1683,
        airportCode: 'CFE',
        cityCode: 'CFE',
        countryCode: 'FR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Clermont-Ferrand Auvergne',
        airportNameEn: 'Clermont-Ferrand Auvergne Airport',
        cityNameVi: 'Clermont Ferrand',
        cityNameEn: 'Clermont Ferrand',
        countryNameVi: 'Pháp',
        countryNameEn: 'France'
    },
    CMF: {
        id: 1682,
        airportCode: 'CMF',
        cityCode: 'CMF',
        countryCode: 'FR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Chambéry-Savoie',
        airportNameEn: 'Chambéry-Savoie Airport',
        cityNameVi: 'Chambery',
        cityNameEn: 'Chambery',
        countryNameVi: 'Pháp',
        countryNameEn: 'France'
    },
    DCM: {
        id: 1681,
        airportCode: 'DCM',
        cityCode: 'DCM',
        countryCode: 'FR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Castres-Mazamet',
        airportNameEn: 'Castres-Mazamet Airport',
        cityNameVi: 'Castres',
        cityNameEn: 'Castres',
        countryNameVi: 'Pháp',
        countryNameEn: 'France'
    },
    CLY: {
        id: 1680,
        airportCode: 'CLY',
        cityCode: 'CLY',
        countryCode: 'FR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Calvi - Sainte-Catherine',
        airportNameEn: 'Calvi - Sainte-Catherine Airport',
        cityNameVi: 'Calvi',
        cityNameEn: 'Calvi',
        countryNameVi: 'Pháp',
        countryNameEn: 'France'
    },
    CFR: {
        id: 1679,
        airportCode: 'CFR',
        cityCode: 'CFR',
        countryCode: 'FR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Caen - Carpiquet',
        airportNameEn: 'Caen - Carpiquet Airport',
        cityNameVi: 'Caen',
        cityNameEn: 'Caen',
        countryNameVi: 'Pháp',
        countryNameEn: 'France'
    },
    BES: {
        id: 1678,
        airportCode: 'BES',
        cityCode: 'BES',
        countryCode: 'FR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Brest Bretagne',
        airportNameEn: 'Brest Bretagne Airport',
        cityNameVi: 'Brest',
        cityNameEn: 'Brest',
        countryNameVi: 'Pháp',
        countryNameEn: 'France'
    },
    BOD: {
        id: 1677,
        airportCode: 'BOD',
        cityCode: 'BOD',
        countryCode: 'FR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Bordeaux - Mérignac',
        airportNameEn: 'Bordeaux - Mérignac Airport',
        cityNameVi: 'Boóc-đô',
        cityNameEn: 'Bordeaux',
        countryNameVi: 'Pháp',
        countryNameEn: 'France'
    },
    BIQ: {
        id: 1676,
        airportCode: 'BIQ',
        cityCode: 'BIQ',
        countryCode: 'FR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Biarritz - Anglet - Bayonne',
        airportNameEn: 'Biarritz - Anglet - Bayonne Airport',
        cityNameVi: 'Biarritz',
        cityNameEn: 'Biarritz',
        countryNameVi: 'Pháp',
        countryNameEn: 'France'
    },
    BIA: {
        id: 1675,
        airportCode: 'BIA',
        cityCode: 'BIA',
        countryCode: 'FR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Bastia - Poretta',
        airportNameEn: 'Bastia - Poretta Airport',
        cityNameVi: 'Bastia',
        cityNameEn: 'Bastia',
        countryNameVi: 'Pháp',
        countryNameEn: 'France'
    },
    AVN: {
        id: 1674,
        airportCode: 'AVN',
        cityCode: 'AVN',
        countryCode: 'FR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Avignon - Caumont',
        airportNameEn: 'Avignon - Caumont Airport',
        cityNameVi: 'Avignon',
        cityNameEn: 'Avignon',
        countryNameVi: 'Pháp',
        countryNameEn: 'France'
    },
    AUR: {
        id: 1673,
        airportCode: 'AUR',
        cityCode: 'AUR',
        countryCode: 'FR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Aurillac',
        airportNameEn: 'Aurillac Airport',
        cityNameVi: 'Aurillac',
        cityNameEn: 'Aurillac',
        countryNameVi: 'Pháp',
        countryNameEn: 'France'
    },
    ANE: {
        id: 1672,
        airportCode: 'ANE',
        cityCode: 'ANE',
        countryCode: 'FR',
        continentCode: 'EU',
        airportNameVi: 'Angers - Avrillé Aerodrome ',
        airportNameEn: 'Angers - Avrillé Aerodrome ',
        cityNameVi: 'Angers',
        cityNameEn: 'Angers',
        countryNameVi: 'Pháp',
        countryNameEn: 'France'
    },
    AJA: {
        id: 1671,
        airportCode: 'AJA',
        cityCode: 'AJA',
        countryCode: 'FR',
        continentCode: 'EU',
        airportNameVi: "Sân bay Ajaccio - Campo dell'Oro",
        airportNameEn: "Ajaccio - Campo dell'Oro Airport",
        cityNameVi: 'Ajaccio',
        cityNameEn: 'Ajaccio',
        countryNameVi: 'Pháp',
        countryNameEn: 'France'
    },
    QXB: {
        id: 1670,
        airportCode: 'QXB',
        cityCode: 'QXB',
        countryCode: 'FR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Aix Les Milles',
        airportNameEn: 'Aix Les Milles Airport',
        cityNameVi: 'Aix En Provence (Aix En Provence)',
        cityNameEn: 'Aix En Provence (Aix En Provence)',
        countryNameVi: 'Pháp',
        countryNameEn: 'France'
    },
    AGF: {
        id: 1669,
        airportCode: 'AGF',
        cityCode: 'AGF',
        countryCode: 'FR',
        continentCode: 'EU',
        airportNameVi: 'Agen - La Garenne Aerodrome',
        airportNameEn: 'Agen - La Garenne Aerodrome',
        cityNameVi: 'Agen',
        cityNameEn: 'Agen',
        countryNameVi: 'Pháp',
        countryNameEn: 'France'
    },
    POR: {
        id: 1668,
        airportCode: 'POR',
        cityCode: 'POR',
        countryCode: 'FI',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Pori',
        airportNameEn: 'Pori Airport',
        cityNameVi: 'Pori',
        cityNameEn: 'Pori',
        countryNameVi: 'Phần lan',
        countryNameEn: 'Finland'
    },
    MHQ: {
        id: 1667,
        airportCode: 'MHQ',
        cityCode: 'MHQ',
        countryCode: 'FI',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Mariehamn',
        airportNameEn: 'Mariehamn Airport',
        cityNameVi: 'Mariehamn',
        cityNameEn: 'Mariehamn',
        countryNameVi: 'Phần lan',
        countryNameEn: 'Finland'
    },
    VRK: {
        id: 1666,
        airportCode: 'VRK',
        cityCode: 'VRK',
        countryCode: 'FI',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Varkaus',
        airportNameEn: 'Varkaus Airport',
        cityNameVi: 'Varkaus',
        cityNameEn: 'Varkaus',
        countryNameVi: 'Phần lan',
        countryNameEn: 'Finland'
    },
    VAA: {
        id: 1665,
        airportCode: 'VAA',
        cityCode: 'VAA',
        countryCode: 'FI',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Vaasa',
        airportNameEn: 'Vaasa Airport',
        cityNameVi: 'Vaasa',
        cityNameEn: 'Vaasa',
        countryNameVi: 'Phần lan',
        countryNameEn: 'Finland'
    },
    TKU: {
        id: 1664,
        airportCode: 'TKU',
        cityCode: 'TKU',
        countryCode: 'FI',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Turku',
        airportNameEn: 'Turku Airport',
        cityNameVi: 'Turku',
        cityNameEn: 'Turku',
        countryNameVi: 'Phần lan',
        countryNameEn: 'Finland'
    },
    TMP: {
        id: 1663,
        airportCode: 'TMP',
        cityCode: 'TMP',
        countryCode: 'FI',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Tampere-Pirkkala',
        airportNameEn: 'Tampere-Pirkkala Airport',
        cityNameVi: 'Tampere',
        cityNameEn: 'Tampere',
        countryNameVi: 'Phần lan',
        countryNameEn: 'Finland'
    },
    RVN: {
        id: 1662,
        airportCode: 'RVN',
        cityCode: 'RVN',
        countryCode: 'FI',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Rovaniemi',
        airportNameEn: 'Rovaniemi Airport',
        cityNameVi: 'Rovaniemi',
        cityNameEn: 'Rovaniemi',
        countryNameVi: 'Phần lan',
        countryNameEn: 'Finland'
    },
    OUL: {
        id: 1661,
        airportCode: 'OUL',
        cityCode: 'OUL',
        countryCode: 'FI',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Oulu',
        airportNameEn: 'Oulu Airport',
        cityNameVi: 'Oulu',
        cityNameEn: 'Oulu',
        countryNameVi: 'Phần lan',
        countryNameEn: 'Finland'
    },
    LPP: {
        id: 1660,
        airportCode: 'LPP',
        cityCode: 'LPP',
        countryCode: 'FI',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Lappeenranta',
        airportNameEn: 'Lappeenranta Airport',
        cityNameVi: 'Lappeenranta',
        cityNameEn: 'Lappeenranta',
        countryNameVi: 'Phần lan',
        countryNameEn: 'Finland'
    },
    KAO: {
        id: 1659,
        airportCode: 'KAO',
        cityCode: 'KAO',
        countryCode: 'FI',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Kuusamo',
        airportNameEn: 'Kuusamo Airport',
        cityNameVi: 'Kuusamo',
        cityNameEn: 'Kuusamo',
        countryNameVi: 'Phần lan',
        countryNameEn: 'Finland'
    },
    KUO: {
        id: 1658,
        airportCode: 'KUO',
        cityCode: 'KUO',
        countryCode: 'FI',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Kuopio',
        airportNameEn: 'Kuopio Airport',
        cityNameVi: 'Kuopio',
        cityNameEn: 'Kuopio',
        countryNameVi: 'Phần lan',
        countryNameEn: 'Finland'
    },
    KOK: {
        id: 1657,
        airportCode: 'KOK',
        cityCode: 'KOK',
        countryCode: 'FI',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Kokkola-Pietarsaari',
        airportNameEn: 'Kokkola-Pietarsaari Airport',
        cityNameVi: 'Kokkola',
        cityNameEn: 'Kokkola',
        countryNameVi: 'Phần lan',
        countryNameEn: 'Finland'
    },
    KTT: {
        id: 1656,
        airportCode: 'KTT',
        cityCode: 'KTT',
        countryCode: 'FI',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Kittilä',
        airportNameEn: 'Kittilä Airport',
        cityNameVi: 'Kittila',
        cityNameEn: 'Kittila',
        countryNameVi: 'Phần lan',
        countryNameEn: 'Finland'
    },
    KEM: {
        id: 1655,
        airportCode: 'KEM',
        cityCode: 'KEM',
        countryCode: 'FI',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Kemi-Tornio',
        airportNameEn: 'Kemi-Tornio Airport',
        cityNameVi: 'Kemi',
        cityNameEn: 'Kemi',
        countryNameVi: 'Phần lan',
        countryNameEn: 'Finland'
    },
    KAJ: {
        id: 1654,
        airportCode: 'KAJ',
        cityCode: 'KAJ',
        countryCode: 'FI',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Kajaani',
        airportNameEn: 'Kajaani Airport',
        cityNameVi: 'Kajaani',
        cityNameEn: 'Kajaani',
        countryNameVi: 'Phần lan',
        countryNameEn: 'Finland'
    },
    JYV: {
        id: 1653,
        airportCode: 'JYV',
        cityCode: 'JYV',
        countryCode: 'FI',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Jyväskylä',
        airportNameEn: 'Jyväskylä Airport',
        cityNameVi: 'Jyvaskyla',
        cityNameEn: 'Jyvaskyla',
        countryNameVi: 'Phần lan',
        countryNameEn: 'Finland'
    },
    JOE: {
        id: 1652,
        airportCode: 'JOE',
        cityCode: 'JOE',
        countryCode: 'FI',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Joensuu',
        airportNameEn: 'Joensuu Airport',
        cityNameVi: 'Joensuu',
        cityNameEn: 'Joensuu',
        countryNameVi: 'Phần lan',
        countryNameEn: 'Finland'
    },
    IVL: {
        id: 1651,
        airportCode: 'IVL',
        cityCode: 'IVL',
        countryCode: 'FI',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Ivalo',
        airportNameEn: 'Ivalo Airport',
        cityNameVi: 'Ivalo',
        cityNameEn: 'Ivalo',
        countryNameVi: 'Phần lan',
        countryNameEn: 'Finland'
    },
    HEL: {
        id: 1650,
        airportCode: 'HEL',
        cityCode: 'HEL',
        countryCode: 'FI',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Helsinki',
        airportNameEn: 'Helsinki Airport',
        cityNameVi: 'Helsinki',
        cityNameEn: 'Helsinki',
        countryNameVi: 'Phần lan',
        countryNameEn: 'Finland'
    },
    BFJ: {
        id: 1649,
        airportCode: 'BFJ',
        cityCode: 'BFJ',
        countryCode: 'FJ',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Bijie',
        airportNameEn: 'Bijie Airport',
        cityNameVi: 'Ba City',
        cityNameEn: 'Ba City',
        countryNameVi: 'Fiji Islands',
        countryNameEn: 'Fiji Islands'
    },
    SUV: {
        id: 1648,
        airportCode: 'SUV',
        cityCode: 'SUV',
        countryCode: 'FJ',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Nausori',
        airportNameEn: 'Nausori International Airport',
        cityNameVi: 'Suva',
        cityNameEn: 'Suva',
        countryNameVi: 'Fiji Islands',
        countryNameEn: 'Fiji Islands'
    },
    NAN: {
        id: 1647,
        airportCode: 'NAN',
        cityCode: 'NAN',
        countryCode: 'FJ',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Nadi',
        airportNameEn: 'Nadi International Airport',
        cityNameVi: 'Nadi',
        cityNameEn: 'Nadi',
        countryNameVi: 'Fiji Islands',
        countryNameEn: 'Fiji Islands'
    },
    LBS: {
        id: 1646,
        airportCode: 'LBS',
        cityCode: 'LBS',
        countryCode: 'FJ',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Labasa',
        airportNameEn: 'Labasa Airport',
        cityNameVi: 'Labasa',
        cityNameEn: 'Labasa',
        countryNameVi: 'Fiji Islands',
        countryNameEn: 'Fiji Islands'
    },
    MPN: {
        id: 1645,
        airportCode: 'MPN',
        cityCode: 'MPN',
        countryCode: 'FK',
        continentCode: 'SA',
        airportNameVi: 'RAF Mount Pleasant',
        airportNameEn: 'RAF Mount Pleasant',
        cityNameVi: 'Mount Pleasant',
        cityNameEn: 'Mount Pleasant',
        countryNameVi: 'Falkland Islands',
        countryNameEn: 'FALKLAND ISLANDS'
    },
    BJR: {
        id: 1644,
        airportCode: 'BJR',
        cityCode: 'BJR',
        countryCode: 'ET',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Bahar Dar',
        airportNameEn: 'Bahar Dar Airport',
        cityNameVi: 'Bahar Dar',
        cityNameEn: 'Bahar Dar',
        countryNameVi: 'E ti ô pia',
        countryNameEn: 'Ethiopia'
    },
    JIM: {
        id: 1643,
        airportCode: 'JIM',
        cityCode: 'JIM',
        countryCode: 'ET',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Aba Segud',
        airportNameEn: 'Aba Segud Airport',
        cityNameVi: 'Jimma',
        cityNameEn: 'Jimma',
        countryNameVi: 'E ti ô pia',
        countryNameEn: 'Ethiopia'
    },
    DIR: {
        id: 1642,
        airportCode: 'DIR',
        cityCode: 'DIR',
        countryCode: 'ET',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Aba Tenna Dejazmach Yilma',
        airportNameEn: 'Aba Tenna Dejazmach Yilma International Airport',
        cityNameVi: 'Dire Dawa',
        cityNameEn: 'Dire Dawa',
        countryNameVi: 'E ti ô pia',
        countryNameEn: 'Ethiopia'
    },
    ADD: {
        id: 1641,
        airportCode: 'ADD',
        cityCode: 'ADD',
        countryCode: 'ET',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Bole',
        airportNameEn: 'Bole International Airport',
        cityNameVi: 'Addis Ababa',
        cityNameEn: 'Addis Ababa',
        countryNameVi: 'E ti ô pia',
        countryNameEn: 'Ethiopia'
    },
    TAY: {
        id: 1640,
        airportCode: 'TAY',
        cityCode: 'TAY',
        countryCode: 'EE',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Tartu-Ülenurme',
        airportNameEn: 'Tartu-Ülenurme Airport',
        cityNameVi: 'Tartu',
        cityNameEn: 'Tartu',
        countryNameVi: 'Estonia',
        countryNameEn: 'Estonia'
    },
    TLL: {
        id: 1639,
        airportCode: 'TLL',
        cityCode: 'TLL',
        countryCode: 'EE',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Lennart Meri Tallinn',
        airportNameEn: 'Lennart Meri Tallinn Airport',
        cityNameVi: 'Tallinn',
        cityNameEn: 'Tallinn',
        countryNameVi: 'Estonia',
        countryNameEn: 'Estonia'
    },
    ASM: {
        id: 1638,
        airportCode: 'ASM',
        cityCode: 'ASM',
        countryCode: 'ER',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Asmara',
        airportNameEn: 'Asmara International Airport',
        cityNameVi: 'Asmara',
        cityNameEn: 'Asmara',
        countryNameVi: 'Eritrea',
        countryNameEn: 'Eritrea'
    },
    SSG: {
        id: 1637,
        airportCode: 'SSG',
        cityCode: 'SSG',
        countryCode: 'GQ',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Malabo',
        airportNameEn: 'Malabo International Airport ',
        cityNameVi: 'Malabo',
        cityNameEn: 'Malabo',
        countryNameVi: 'Equatorial Guinea',
        countryNameEn: 'Equatorial Guinea'
    },
    SAL: {
        id: 1636,
        airportCode: 'SAL',
        cityCode: 'SAL',
        countryCode: 'SV',
        continentCode: 'SA',
        airportNameVi: 'Sân bay El Salvador',
        airportNameEn: 'El Salvador International Airport',
        cityNameVi: 'San Salvador',
        cityNameEn: 'San Salvador',
        countryNameVi: 'El Salvador',
        countryNameEn: 'El Salvador'
    },
    RMF: {
        id: 1635,
        airportCode: 'RMF',
        cityCode: 'RMF',
        countryCode: 'EG',
        continentCode: 'AF',
        airportNameVi: 'Marsa Alam',
        airportNameEn: 'Marsa Alam',
        cityNameVi: 'Marsa Alam',
        cityNameEn: 'Marsa Alam',
        countryNameVi: 'Ai Cập',
        countryNameEn: 'Egypt'
    },
    TCP: {
        id: 1634,
        airportCode: 'TCP',
        cityCode: 'TCP',
        countryCode: 'EG',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Taba',
        airportNameEn: 'Taba International Airport',
        cityNameVi: 'Taba',
        cityNameEn: 'Taba',
        countryNameVi: 'Ai Cập',
        countryNameEn: 'Egypt'
    },
    SSH: {
        id: 1633,
        airportCode: 'SSH',
        cityCode: 'SSH',
        countryCode: 'EG',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Sharm el-Sheikh',
        airportNameEn: 'Sharm el-Sheikh International Airport',
        cityNameVi: 'Sharm El Sheik',
        cityNameEn: 'Sharm El Sheik',
        countryNameVi: 'Ai Cập',
        countryNameEn: 'Egypt'
    },
    LXR: {
        id: 1632,
        airportCode: 'LXR',
        cityCode: 'LXR',
        countryCode: 'EG',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Luxor',
        airportNameEn: 'Luxor International Airport',
        cityNameVi: 'Luxor',
        cityNameEn: 'Luxor',
        countryNameVi: 'Ai Cập',
        countryNameEn: 'Egypt'
    },
    HRG: {
        id: 1631,
        airportCode: 'HRG',
        cityCode: 'HRG',
        countryCode: 'EG',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Hurghada',
        airportNameEn: 'Hurghada International Airport',
        cityNameVi: 'Hurghada',
        cityNameEn: 'Hurghada',
        countryNameVi: 'Ai Cập',
        countryNameEn: 'Egypt'
    },
    CAI: {
        id: 1630,
        airportCode: 'CAI',
        cityCode: 'CAI',
        countryCode: 'EG',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Cairo',
        airportNameEn: 'Cairo International Airport',
        cityNameVi: 'Cairo',
        cityNameEn: 'Cairo',
        countryNameVi: 'Ai Cập',
        countryNameEn: 'Egypt'
    },
    HBE: {
        id: 1629,
        airportCode: 'HBE',
        cityCode: 'HBE',
        countryCode: 'EG',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Borg El Arab',
        airportNameEn: 'Borg El Arab Airport',
        cityNameVi: 'Borg El Arab',
        cityNameEn: 'Borg El Arab',
        countryNameVi: 'Ai Cập',
        countryNameEn: 'Egypt'
    },
    ASW: {
        id: 1628,
        airportCode: 'ASW',
        cityCode: 'ASW',
        countryCode: 'EG',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Aswan',
        airportNameEn: 'Aswan International Airport',
        cityNameVi: 'Aswan',
        cityNameEn: 'Aswan',
        countryNameVi: 'Ai Cập',
        countryNameEn: 'Egypt'
    },
    ALY: {
        id: 1627,
        airportCode: 'ALY',
        cityCode: 'ALY',
        countryCode: 'EG',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Alexandria',
        airportNameEn: 'Alexandria International Airport',
        cityNameVi: 'Alexandria',
        cityNameEn: 'Alexandria',
        countryNameVi: 'Ai Cập',
        countryNameEn: 'Egypt'
    },
    ABS: {
        id: 1626,
        airportCode: 'ABS',
        cityCode: 'ABS',
        countryCode: 'EG',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Abu Simbel',
        airportNameEn: 'Abu Simbel Airport',
        cityNameVi: 'Abu Simbel',
        cityNameEn: 'Abu Simbel',
        countryNameVi: 'Ai Cập',
        countryNameEn: 'Egypt'
    },
    SCY: {
        id: 1625,
        airportCode: 'SCY',
        cityCode: 'SCY',
        countryCode: 'EC',
        continentCode: 'SA',
        airportNameVi: 'Sân bay San Cristóbal',
        airportNameEn: 'San Cristóbal Airport',
        cityNameVi: 'San Cristobal',
        cityNameEn: 'San Cristobal',
        countryNameVi: 'Ecuador',
        countryNameEn: 'Ecuador'
    },
    MEC: {
        id: 1624,
        airportCode: 'MEC',
        cityCode: 'MEC',
        countryCode: 'EC',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Eloy Alfaro',
        airportNameEn: 'Eloy Alfaro International Airport',
        cityNameVi: 'Manta',
        cityNameEn: 'Manta',
        countryNameVi: 'Ecuador',
        countryNameEn: 'Ecuador'
    },
    UIO: {
        id: 1623,
        airportCode: 'UIO',
        cityCode: 'UIO',
        countryCode: 'EC',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Mariscal Sucre',
        airportNameEn: 'Mariscal Sucre International Airport',
        cityNameVi: 'Quito',
        cityNameEn: 'Quito',
        countryNameVi: 'Ecuador',
        countryNameEn: 'Ecuador'
    },
    GYE: {
        id: 1622,
        airportCode: 'GYE',
        cityCode: 'GYE',
        countryCode: 'EC',
        continentCode: 'SA',
        airportNameVi: 'Sân bay José Joaquín de Olmedo',
        airportNameEn: 'José Joaquín de Olmedo International Airport',
        cityNameVi: 'Guayaquil',
        cityNameEn: 'Guayaquil',
        countryNameVi: 'Ecuador',
        countryNameEn: 'Ecuador'
    },
    GPS: {
        id: 1621,
        airportCode: 'GPS',
        cityCode: 'GPS',
        countryCode: 'EC',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Seymour',
        airportNameEn: 'Seymour Airport',
        cityNameVi: 'Galapagos Is',
        cityNameEn: 'Galapagos Is',
        countryNameVi: 'Ecuador',
        countryNameEn: 'Ecuador'
    },
    CUE: {
        id: 1620,
        airportCode: 'CUE',
        cityCode: 'CUE',
        countryCode: 'EC',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Mariscal Lamar',
        airportNameEn: 'Mariscal Lamar Airport',
        cityNameVi: 'Cuenca',
        cityNameEn: 'Cuenca',
        countryNameVi: 'Ecuador',
        countryNameEn: 'Ecuador'
    },
    SDQ: {
        id: 1619,
        airportCode: 'SDQ',
        cityCode: 'SDQ',
        countryCode: 'DO',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Las Américas',
        airportNameEn: 'Las Américas International Airport',
        cityNameVi: 'Santo Domingo',
        cityNameEn: 'Santo Domingo',
        countryNameVi: 'Cộng hòa Dominicana',
        countryNameEn: 'Dominican Republic'
    },
    HEX: {
        id: 1618,
        airportCode: 'HEX',
        cityCode: 'HEX',
        countryCode: 'DO',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Herrera',
        airportNameEn: 'Herrera International Airport ',
        cityNameVi: 'Santo Domingo',
        cityNameEn: 'Santo Domingo',
        countryNameVi: 'Cộng hòa Dominicana',
        countryNameEn: 'Dominican Republic'
    },
    STI: {
        id: 1617,
        airportCode: 'STI',
        cityCode: 'STI',
        countryCode: 'DO',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Cibao',
        airportNameEn: 'Cibao International Airport',
        cityNameVi: 'Santiago',
        cityNameEn: 'Santiago',
        countryNameVi: 'Cộng hòa Dominicana',
        countryNameEn: 'Dominican Republic'
    },
    PUJ: {
        id: 1616,
        airportCode: 'PUJ',
        cityCode: 'PUJ',
        countryCode: 'DO',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Punta Cana',
        airportNameEn: 'Punta Cana International Airport',
        cityNameVi: 'Punta Cana',
        cityNameEn: 'Punta Cana',
        countryNameVi: 'Cộng hòa Dominicana',
        countryNameEn: 'Dominican Republic'
    },
    POP: {
        id: 1615,
        airportCode: 'POP',
        cityCode: 'POP',
        countryCode: 'DO',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Puerto Plata',
        airportNameEn: 'Puerto Plata Airport',
        cityNameVi: 'Puerto Plata',
        cityNameEn: 'Puerto Plata',
        countryNameVi: 'Cộng hòa Dominicana',
        countryNameEn: 'Dominican Republic'
    },
    LRM: {
        id: 1614,
        airportCode: 'LRM',
        cityCode: 'LRM',
        countryCode: 'DO',
        continentCode: 'SA',
        airportNameVi: 'Sân bay La Romana',
        airportNameEn: 'La Romana International Airport ',
        cityNameVi: 'La Romana',
        cityNameEn: 'La Romana',
        countryNameVi: 'Cộng hòa Dominicana',
        countryNameEn: 'Dominican Republic'
    },
    DOM: {
        id: 1613,
        airportCode: 'DOM',
        cityCode: 'DOM',
        countryCode: 'DM',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Melville Hall',
        airportNameEn: 'Melville Hall Airport',
        cityNameVi: 'Dominica',
        cityNameEn: 'Dominica',
        countryNameVi: 'Cộng hòa Dominicana',
        countryNameEn: 'Dominican Republic'
    },
    DCF: {
        id: 1612,
        airportCode: 'DCF',
        cityCode: 'DOM',
        countryCode: 'DM',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Canefield',
        airportNameEn: 'Canefield Airport',
        cityNameVi: 'Dominica',
        cityNameEn: 'Dominica',
        countryNameVi: 'Cộng hòa Dominicana',
        countryNameEn: 'Dominican Republic'
    },
    JIB: {
        id: 1611,
        airportCode: 'JIB',
        cityCode: 'JIB',
        countryCode: 'DJ',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Ambouli',
        airportNameEn: 'Ambouli Airport',
        cityNameVi: 'Djibouti',
        cityNameEn: 'Djibouti',
        countryNameVi: 'Djibouti',
        countryNameEn: 'Djibouti'
    },
    RKE: {
        id: 1610,
        airportCode: 'RKE',
        cityCode: 'RKE',
        countryCode: 'DK',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Roskilde',
        airportNameEn: 'Roskilde Airport',
        cityNameVi: 'Roskilde',
        cityNameEn: 'Roskilde',
        countryNameVi: 'Ðan Mạch',
        countryNameEn: 'Denmark'
    },
    KRP: {
        id: 1609,
        airportCode: 'KRP',
        cityCode: 'KRP',
        countryCode: 'DK',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Karup',
        airportNameEn: 'Karup Airport',
        cityNameVi: 'Karup',
        cityNameEn: 'Karup',
        countryNameVi: 'Ðan Mạch',
        countryNameEn: 'Denmark'
    },
    EBJ: {
        id: 1608,
        airportCode: 'EBJ',
        cityCode: 'EBJ',
        countryCode: 'DK',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Esbjerg',
        airportNameEn: 'Esbjerg Airport',
        cityNameVi: 'Esbjerg',
        cityNameEn: 'Esbjerg',
        countryNameVi: 'Ðan Mạch',
        countryNameEn: 'Denmark'
    },
    FAE: {
        id: 1607,
        airportCode: 'FAE',
        cityCode: 'FAE',
        countryCode: 'DK',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Vágar',
        airportNameEn: 'Vágar Airport',
        cityNameVi: 'Faeroe Islands',
        cityNameEn: 'Faeroe Islands',
        countryNameVi: 'Ðan Mạch',
        countryNameEn: 'Denmark'
    },
    CPH: {
        id: 1606,
        airportCode: 'CPH',
        cityCode: 'CPH',
        countryCode: 'DK',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Copenhagen',
        airportNameEn: 'Copenhagen Airport, Kastrup Airport',
        cityNameVi: 'Cô-pen-ha-gen',
        cityNameEn: 'Copenhagen',
        countryNameVi: 'Ðan Mạch',
        countryNameEn: 'Denmark'
    },
    BLL: {
        id: 1605,
        airportCode: 'BLL',
        cityCode: 'BLL',
        countryCode: 'DK',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Billund',
        airportNameEn: 'Billund Airport',
        cityNameVi: 'Billund',
        cityNameEn: 'Billund',
        countryNameVi: 'Ðan Mạch',
        countryNameEn: 'Denmark'
    },
    BZR: {
        id: 1604,
        airportCode: 'BZR',
        cityCode: 'BZR',
        countryCode: 'DK',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Béziers Vias',
        airportNameEn: 'Béziers Vias Airport',
        cityNameVi: 'Beziers',
        cityNameEn: 'Beziers',
        countryNameVi: 'Ðan Mạch',
        countryNameEn: 'Denmark'
    },
    AAR: {
        id: 1603,
        airportCode: 'AAR',
        cityCode: 'AAR',
        countryCode: 'DK',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Aarhus',
        airportNameEn: 'Aarhus Airport',
        cityNameVi: 'Aarhus',
        cityNameEn: 'Aarhus',
        countryNameVi: 'Ðan Mạch',
        countryNameEn: 'Denmark'
    },
    AAL: {
        id: 1602,
        airportCode: 'AAL',
        cityCode: 'AAL',
        countryCode: 'DK',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Aalborg',
        airportNameEn: 'Aalborg Airport',
        cityNameVi: 'Aalborg',
        cityNameEn: 'Aalborg',
        countryNameVi: 'Ðan Mạch',
        countryNameEn: 'Denmark'
    },
    PNR: {
        id: 1601,
        airportCode: 'PNR',
        cityCode: 'PNR',
        countryCode: 'CD',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Pointe Noire',
        airportNameEn: 'Pointe Noire Airport',
        cityNameVi: 'Pointe Noire',
        cityNameEn: 'Pointe Noire',
        countryNameVi: 'Công gô',
        countryNameEn: 'Democratic Republic Of Congo'
    },
    BZV: {
        id: 1600,
        airportCode: 'BZV',
        cityCode: 'BZV',
        countryCode: 'CD',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Maya Maya',
        airportNameEn: 'Maya Maya Airport',
        cityNameVi: 'Brazzaville',
        cityNameEn: 'Brazzaville',
        countryNameVi: 'Công gô',
        countryNameEn: 'Democratic Republic Of Congo'
    },
    KLV: {
        id: 1599,
        airportCode: 'KLV',
        cityCode: 'KLV',
        countryCode: 'CZ',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Karlovy Vary',
        airportNameEn: 'Karlovy Vary Airport',
        cityNameVi: 'Karlovy Vary',
        cityNameEn: 'Karlovy Vary',
        countryNameVi: 'Cộng hòa Séc',
        countryNameEn: 'Czech Republic'
    },
    PRG: {
        id: 1598,
        airportCode: 'PRG',
        cityCode: 'PRG',
        countryCode: 'CZ',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Václav Havel',
        airportNameEn: 'Václav Havel Airport Prague',
        cityNameVi: 'Praha',
        cityNameEn: 'Prague',
        countryNameVi: 'Cộng hòa Séc',
        countryNameEn: 'Czech Republic'
    },
    PED: {
        id: 1597,
        airportCode: 'PED',
        cityCode: 'PED',
        countryCode: 'CZ',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Pardubice',
        airportNameEn: 'Pardubice Airport',
        cityNameVi: 'Pardubice',
        cityNameEn: 'Pardubice',
        countryNameVi: 'Cộng hòa Séc',
        countryNameEn: 'Czech Republic'
    },
    OSR: {
        id: 1596,
        airportCode: 'OSR',
        cityCode: 'OSR',
        countryCode: 'CZ',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Ostrava-Mosnov',
        airportNameEn: 'Ostrava-Mosnov International Airport',
        cityNameVi: 'Ostrava',
        cityNameEn: 'Ostrava',
        countryNameVi: 'Cộng hòa Séc',
        countryNameEn: 'Czech Republic'
    },
    BRQ: {
        id: 1595,
        airportCode: 'BRQ',
        cityCode: 'BRQ',
        countryCode: 'CZ',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Brno-Turany',
        airportNameEn: 'Brno-Turany Airport',
        cityNameVi: 'Brno',
        cityNameEn: 'Brno',
        countryNameVi: 'Cộng hòa Séc',
        countryNameEn: 'Czech Republic'
    },
    PFO: {
        id: 1594,
        airportCode: 'PFO',
        cityCode: 'PFO',
        countryCode: 'CY',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Paphos',
        airportNameEn: 'Paphos International Airport',
        cityNameVi: 'Paphos',
        cityNameEn: 'Paphos',
        countryNameVi: 'Cộng hòa Síp',
        countryNameEn: 'Cyprus'
    },
    LCA: {
        id: 1593,
        airportCode: 'LCA',
        cityCode: 'LCA',
        countryCode: 'CY',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Larnaca',
        airportNameEn: 'Larnaca International Airport',
        cityNameVi: 'Larnaca',
        cityNameEn: 'Larnaca',
        countryNameVi: 'Cộng hòa Síp',
        countryNameEn: 'Cyprus'
    },
    CYO: {
        id: 1592,
        airportCode: 'CYO',
        cityCode: 'CYO',
        countryCode: 'CU',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Vilo Acuña',
        airportNameEn: 'Vilo Acuña Airport',
        cityNameVi: 'Cayo Largo Del Sur',
        cityNameEn: 'Cayo Largo Del Sur',
        countryNameVi: 'Cuba',
        countryNameEn: 'Cuba'
    },
    MZO: {
        id: 1591,
        airportCode: 'MZO',
        cityCode: 'MZO',
        countryCode: 'CU',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Sierra Maestra',
        airportNameEn: 'Sierra Maestra Airport',
        cityNameVi: 'Manzanillo',
        cityNameEn: 'Manzanillo',
        countryNameVi: 'Cuba',
        countryNameEn: 'Cuba'
    },
    SNU: {
        id: 1590,
        airportCode: 'SNU',
        cityCode: 'SNU',
        countryCode: 'CU',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Abel Santamaría',
        airportNameEn: 'Abel Santamaría Airport',
        cityNameVi: 'Santa Clara',
        cityNameEn: 'Santa Clara',
        countryNameVi: 'Cuba',
        countryNameEn: 'Cuba'
    },
    VRA: {
        id: 1589,
        airportCode: 'VRA',
        cityCode: 'VRA',
        countryCode: 'CU',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Juan Gualberto Gómez',
        airportNameEn: 'Juan Gualberto Gómez Airport',
        cityNameVi: 'Varadero',
        cityNameEn: 'Varadero',
        countryNameVi: 'Cuba',
        countryNameEn: 'Cuba'
    },
    SCU: {
        id: 1588,
        airportCode: 'SCU',
        cityCode: 'SCU',
        countryCode: 'CU',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Antonio Maceo',
        airportNameEn: 'Antonio Maceo International Airport',
        cityNameVi: 'Santiago',
        cityNameEn: 'Santiago',
        countryNameVi: 'Cuba',
        countryNameEn: 'Cuba'
    },
    HOG: {
        id: 1587,
        airportCode: 'HOG',
        cityCode: 'HOG',
        countryCode: 'CU',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Frank País',
        airportNameEn: 'Frank País Airport',
        cityNameVi: 'Holguin',
        cityNameEn: 'Holguin',
        countryNameVi: 'Cuba',
        countryNameEn: 'Cuba'
    },
    HAV: {
        id: 1586,
        airportCode: 'HAV',
        cityCode: 'HAV',
        countryCode: 'CU',
        continentCode: 'SA',
        airportNameVi: 'Sân bay José Martí',
        airportNameEn: 'José Martí International Airport',
        cityNameVi: 'Havana',
        cityNameEn: 'Havana',
        countryNameVi: 'Cuba',
        countryNameEn: 'Cuba'
    },
    CMW: {
        id: 1585,
        airportCode: 'CMW',
        cityCode: 'CMW',
        countryCode: 'CU',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Camagüey-Ignacio Agramonte',
        airportNameEn: 'Camagüey-Ignacio Agramonte Airport',
        cityNameVi: 'Camaguey',
        cityNameEn: 'Camaguey',
        countryNameVi: 'Cuba',
        countryNameEn: 'Cuba'
    },
    ZAG: {
        id: 1584,
        airportCode: 'ZAG',
        cityCode: 'ZAG',
        countryCode: 'HR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Zagreb',
        airportNameEn: 'Zagreb Airport ',
        cityNameVi: 'Zagreb',
        cityNameEn: 'Zagreb',
        countryNameVi: 'Croatia',
        countryNameEn: 'Croatia'
    },
    ZAD: {
        id: 1583,
        airportCode: 'ZAD',
        cityCode: 'ZAD',
        countryCode: 'HR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Zadar',
        airportNameEn: 'Zadar Airport',
        cityNameVi: 'Zadar',
        cityNameEn: 'Zadar',
        countryNameVi: 'Croatia',
        countryNameEn: 'Croatia'
    },
    SPU: {
        id: 1582,
        airportCode: 'SPU',
        cityCode: 'SPU',
        countryCode: 'HR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Split',
        airportNameEn: 'Split Airport',
        cityNameVi: 'Split',
        cityNameEn: 'Split',
        countryNameVi: 'Croatia',
        countryNameEn: 'Croatia'
    },
    RJK: {
        id: 1581,
        airportCode: 'RJK',
        cityCode: 'RJK',
        countryCode: 'HR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Rijeka',
        airportNameEn: 'Rijeka Airport',
        cityNameVi: 'Rijeka',
        cityNameEn: 'Rijeka',
        countryNameVi: 'Croatia',
        countryNameEn: 'Croatia'
    },
    PUY: {
        id: 1580,
        airportCode: 'PUY',
        cityCode: 'PUY',
        countryCode: 'HR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Pula',
        airportNameEn: 'Pula Airport',
        cityNameVi: 'Pula',
        cityNameEn: 'Pula',
        countryNameVi: 'Croatia',
        countryNameEn: 'Croatia'
    },
    DBV: {
        id: 1579,
        airportCode: 'DBV',
        cityCode: 'DBV',
        countryCode: 'HR',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Dubrovnik',
        airportNameEn: 'Dubrovnik Airport',
        cityNameVi: 'Dubrovnik',
        cityNameEn: 'Dubrovnik',
        countryNameVi: 'Croatia',
        countryNameEn: 'Croatia'
    },
    SYQ: {
        id: 1578,
        airportCode: 'SYQ',
        cityCode: 'SYQ',
        countryCode: 'CR',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Tobías Bolaños',
        airportNameEn: 'Tobías Bolaños International Airport',
        cityNameVi: 'San Jose',
        cityNameEn: 'San Jose',
        countryNameVi: 'Costa Rica',
        countryNameEn: 'COSTA RICA'
    },
    TMU: {
        id: 1577,
        airportCode: 'TMU',
        cityCode: 'TMU',
        countryCode: 'CR',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Tambor',
        airportNameEn: 'Tambor Airport',
        cityNameVi: 'Tambor',
        cityNameEn: 'Tambor',
        countryNameVi: 'Costa Rica',
        countryNameEn: 'COSTA RICA'
    },
    TNO: {
        id: 1576,
        airportCode: 'TNO',
        cityCode: 'TNO',
        countryCode: 'CR',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Tamarindo',
        airportNameEn: 'Tamarindo Airport',
        cityNameVi: 'Tamarindo',
        cityNameEn: 'Tamarindo',
        countryNameVi: 'Costa Rica',
        countryNameEn: 'COSTA RICA'
    },
    XQP: {
        id: 1575,
        airportCode: 'XQP',
        cityCode: 'XQP',
        countryCode: 'CR',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Quepos Managua',
        airportNameEn: 'Quepos Managua Airport',
        cityNameVi: 'Quepos',
        cityNameEn: 'Quepos',
        countryNameVi: 'Costa Rica',
        countryNameEn: 'COSTA RICA'
    },
    PMZ: {
        id: 1574,
        airportCode: 'PMZ',
        cityCode: 'PMZ',
        countryCode: 'CR',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Palmar Sur',
        airportNameEn: 'Palmar Sur Airport',
        cityNameVi: 'Palmar',
        cityNameEn: 'Palmar',
        countryNameVi: 'Costa Rica',
        countryNameEn: 'COSTA RICA'
    },
    LIR: {
        id: 1573,
        airportCode: 'LIR',
        cityCode: 'LIR',
        countryCode: 'CR',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Daniel Oduber Quirós',
        airportNameEn: 'Daniel Oduber Quirós International Airport',
        cityNameVi: 'Liberia',
        cityNameEn: 'Liberia',
        countryNameVi: 'Costa Rica',
        countryNameEn: 'COSTA RICA'
    },
    RAR: {
        id: 1572,
        airportCode: 'RAR',
        cityCode: 'RAR',
        countryCode: 'CK',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Rarotonga',
        airportNameEn: 'Rarotonga International Airport',
        cityNameVi: 'Rarotonga',
        cityNameEn: 'Rarotonga',
        countryNameVi: 'Cook Islands',
        countryNameEn: 'Cook Islands'
    },
    AIT: {
        id: 1571,
        airportCode: 'AIT',
        cityCode: 'AIT',
        countryCode: 'CK',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Aitutaki Island',
        airportNameEn: 'Aitutaki Island Airport',
        cityNameVi: 'Aitutaki',
        cityNameEn: 'Aitutaki',
        countryNameVi: 'Cook Islands',
        countryNameEn: 'Cook Islands'
    },
    FIH: {
        id: 1570,
        airportCode: 'FIH',
        cityCode: 'FIH',
        countryCode: 'CG',
        continentCode: 'AF',
        airportNameVi: "Sân bay N'djili",
        airportNameEn: "N'djili Airport ",
        cityNameVi: 'Kinshasa',
        cityNameEn: 'Kinshasa',
        countryNameVi: 'Congo',
        countryNameEn: 'Congo'
    },
    FBM: {
        id: 1569,
        airportCode: 'FBM',
        cityCode: 'FBM',
        countryCode: 'CG',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Lubumbashi',
        airportNameEn: 'Lubumbashi International Airport',
        cityNameVi: 'Lubumbashi',
        cityNameEn: 'Lubumbashi',
        countryNameVi: 'Congo',
        countryNameEn: 'Congo'
    },
    HAH: {
        id: 1568,
        airportCode: 'HAH',
        cityCode: 'YVA',
        countryCode: 'KM',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Prince Said Ibrahim',
        airportNameEn: 'Prince Said Ibrahim International Airport',
        cityNameVi: 'Moroni',
        cityNameEn: 'Moroni',
        countryNameVi: 'Comoros',
        countryNameEn: 'Comoros'
    },
    YVA: {
        id: 1567,
        airportCode: 'YVA',
        cityCode: 'YVA',
        countryCode: 'KM',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Iconi',
        airportNameEn: 'Iconi Airport',
        cityNameVi: 'Moroni',
        cityNameEn: 'Moroni',
        countryNameVi: 'Comoros',
        countryNameEn: 'Comoros'
    },
    EJA: {
        id: 1566,
        airportCode: 'EJA',
        cityCode: 'EJA',
        countryCode: 'CO',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Yarigüies',
        airportNameEn: 'Yarigüies Airport',
        cityNameVi: 'Barrancabermeja',
        cityNameEn: 'Barrancabermeja',
        countryNameVi: 'Colombia',
        countryNameEn: 'Colombia'
    },
    EOH: {
        id: 1565,
        airportCode: 'EOH',
        cityCode: 'MDE',
        countryCode: 'CO',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Olaya Herrera',
        airportNameEn: 'Olaya Herrera Airport',
        cityNameVi: 'Medellin',
        cityNameEn: 'Medellin',
        countryNameVi: 'Colombia',
        countryNameEn: 'Colombia'
    },
    PEI: {
        id: 1564,
        airportCode: 'PEI',
        cityCode: 'PEI',
        countryCode: 'CO',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Matecaña',
        airportNameEn: 'Matecaña International Airport',
        cityNameVi: 'Pereira',
        cityNameEn: 'Pereira',
        countryNameVi: 'Colombia',
        countryNameEn: 'Colombia'
    },
    MTR: {
        id: 1563,
        airportCode: 'MTR',
        cityCode: 'MTR',
        countryCode: 'CO',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Los Garzones',
        airportNameEn: 'Los Garzones Airport',
        cityNameVi: 'Monteria',
        cityNameEn: 'Monteria',
        countryNameVi: 'Colombia',
        countryNameEn: 'Colombia'
    },
    MZL: {
        id: 1562,
        airportCode: 'MZL',
        cityCode: 'MZL',
        countryCode: 'CO',
        continentCode: 'SA',
        airportNameVi: 'Sân bay La Nubia',
        airportNameEn: 'La Nubia Airport ',
        cityNameVi: 'Manizales',
        cityNameEn: 'Manizales',
        countryNameVi: 'Colombia',
        countryNameEn: 'Colombia'
    },
    BAQ: {
        id: 1561,
        airportCode: 'BAQ',
        cityCode: 'BAQ',
        countryCode: 'CO',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Ernesto Cortissoz',
        airportNameEn: 'Ernesto Cortissoz International Airport',
        cityNameVi: 'Barranquilla',
        cityNameEn: 'Barranquilla',
        countryNameVi: 'Colombia',
        countryNameEn: 'Colombia'
    },
    APO: {
        id: 1560,
        airportCode: 'APO',
        cityCode: 'APO',
        countryCode: 'CO',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Antonio Roldán Betancourt',
        airportNameEn: 'Antonio Roldán Betancourt Airport',
        cityNameVi: 'Apartado',
        cityNameEn: 'Apartado',
        countryNameVi: 'Colombia',
        countryNameEn: 'Colombia'
    },
    SMR: {
        id: 1559,
        airportCode: 'SMR',
        cityCode: 'SMR',
        countryCode: 'CO',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Simón Bolívar',
        airportNameEn: 'Simón Bolívar International Airport',
        cityNameVi: 'Santa Marta',
        cityNameEn: 'Santa Marta',
        countryNameVi: 'Colombia',
        countryNameEn: 'Colombia'
    },
    ADZ: {
        id: 1558,
        airportCode: 'ADZ',
        cityCode: 'ADZ',
        countryCode: 'CO',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Gustavo Rojas Pinilla',
        airportNameEn: 'Gustavo Rojas Pinilla International Airport',
        cityNameVi: 'San Andres',
        cityNameEn: 'San Andres',
        countryNameVi: 'Colombia',
        countryNameEn: 'Colombia'
    },
    PPN: {
        id: 1557,
        airportCode: 'PPN',
        cityCode: 'PPN',
        countryCode: 'CO',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Guillermo León Valencia',
        airportNameEn: 'Guillermo León Valencia Airport',
        cityNameVi: 'Popayan',
        cityNameEn: 'Popayan',
        countryNameVi: 'Colombia',
        countryNameEn: 'Colombia'
    },
    PSO: {
        id: 1556,
        airportCode: 'PSO',
        cityCode: 'PSO',
        countryCode: 'CO',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Antonio Nariño',
        airportNameEn: 'Antonio Nariño Airport',
        cityNameVi: 'Pasto',
        cityNameEn: 'Pasto',
        countryNameVi: 'Colombia',
        countryNameEn: 'Colombia'
    },
    LET: {
        id: 1555,
        airportCode: 'LET',
        cityCode: 'LET',
        countryCode: 'CO',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Alfredo Vásquez Cobo',
        airportNameEn: 'Alfredo Vásquez Cobo International Airport',
        cityNameVi: 'Leticia',
        cityNameEn: 'Leticia',
        countryNameVi: 'Colombia',
        countryNameEn: 'Colombia'
    },
    CUC: {
        id: 1554,
        airportCode: 'CUC',
        cityCode: 'CUC',
        countryCode: 'CO',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Camilo Daza',
        airportNameEn: 'Camilo Daza International Airport',
        cityNameVi: 'Cucuta',
        cityNameEn: 'Cucuta',
        countryNameVi: 'Colombia',
        countryNameEn: 'Colombia'
    },
    CTG: {
        id: 1553,
        airportCode: 'CTG',
        cityCode: 'CTG',
        countryCode: 'CO',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Rafael Núñez',
        airportNameEn: 'Rafael Núñez International Airport',
        cityNameVi: 'Cartagena',
        cityNameEn: 'Cartagena',
        countryNameVi: 'Colombia',
        countryNameEn: 'Colombia'
    },
    CLO: {
        id: 1552,
        airportCode: 'CLO',
        cityCode: 'CLO',
        countryCode: 'CO',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Alfonso Bonilla Aragón',
        airportNameEn: 'Alfonso Bonilla Aragón International Airport',
        cityNameVi: 'Cali',
        cityNameEn: 'Cali',
        countryNameVi: 'Colombia',
        countryNameEn: 'Colombia'
    },
    BGA: {
        id: 1551,
        airportCode: 'BGA',
        cityCode: 'BGA',
        countryCode: 'CO',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Palonegro',
        airportNameEn: 'Palonegro Airport',
        cityNameVi: 'Bucaramanga',
        cityNameEn: 'Bucaramanga',
        countryNameVi: 'Colombia',
        countryNameEn: 'Colombia'
    },
    BOG: {
        id: 1550,
        airportCode: 'BOG',
        cityCode: 'BOG',
        countryCode: 'CO',
        continentCode: 'SA',
        airportNameVi: 'Sân bay El Dorado',
        airportNameEn: 'El Dorado International Airport',
        cityNameVi: 'Bogota',
        cityNameEn: 'Bogota',
        countryNameVi: 'Colombia',
        countryNameEn: 'Colombia'
    },
    AXM: {
        id: 1549,
        airportCode: 'AXM',
        cityCode: 'AXM',
        countryCode: 'CO',
        continentCode: 'SA',
        airportNameVi: 'Sân bay El Edén',
        airportNameEn: 'El Edén International Airport',
        cityNameVi: 'Ác-me-ni',
        cityNameEn: 'Armenia',
        countryNameVi: 'Colombia',
        countryNameEn: 'Colombia'
    },
    ZYI: {
        id: 1548,
        airportCode: 'ZYI',
        cityCode: 'ZYI',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Zunyi',
        airportNameEn: 'Zunyi Airport',
        cityNameVi: 'Zunyi',
        cityNameEn: 'Zunyi',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    ZUH: {
        id: 1547,
        airportCode: 'ZUH',
        cityCode: 'ZUH',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Zhuhai Sanzao',
        airportNameEn: 'Zhuhai Sanzao Airport',
        cityNameVi: 'Zhuhai',
        cityNameEn: 'Zhuhai',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    CGO: {
        id: 1546,
        airportCode: 'CGO',
        cityCode: 'CGO',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Zhengzhou Xinzheng',
        airportNameEn: 'Zhengzhou Xinzheng International Airport',
        cityNameVi: 'Zhengzhou',
        cityNameEn: 'Zhengzhou',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    ZHA: {
        id: 1545,
        airportCode: 'ZHA',
        cityCode: 'ZHA',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Zhanjiang',
        airportNameEn: 'Zhanjiang Airport',
        cityNameVi: 'Zhangjiang',
        cityNameEn: 'Zhangjiang',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    YCU: {
        id: 1544,
        airportCode: 'YCU',
        cityCode: 'YCU',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Yuncheng Guangong',
        airportNameEn: 'Yuncheng Guangong Airport',
        cityNameVi: 'Yun Cheng',
        cityNameEn: 'Yun Cheng',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    YIW: {
        id: 1543,
        airportCode: 'YIW',
        cityCode: 'YIW',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Yiwu',
        airportNameEn: 'Yiwu Airport',
        cityNameVi: 'Yiwu',
        cityNameEn: 'Yiwu',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    INC: {
        id: 1542,
        airportCode: 'INC',
        cityCode: 'INC',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Yinchuan',
        airportNameEn: 'Yinchuan Airport ',
        cityNameVi: 'Yinchuan',
        cityNameEn: 'Yinchuan',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    YIH: {
        id: 1541,
        airportCode: 'YIH',
        cityCode: 'YIH',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Yichang Sanxia',
        airportNameEn: 'Yichang Sanxia Airport',
        cityNameVi: 'Yichang',
        cityNameEn: 'Yichang',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    YBP: {
        id: 1540,
        airportCode: 'YBP',
        cityCode: 'YBP',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Yibin Caiba',
        airportNameEn: 'Yibin Caiba Airport',
        cityNameVi: 'Yibin',
        cityNameEn: 'Yibin',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    YNT: {
        id: 1539,
        airportCode: 'YNT',
        cityCode: 'YNT',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Yantai Laishan',
        airportNameEn: 'Yantai Laishan International Airport',
        cityNameVi: 'Yantai',
        cityNameEn: 'Yantai',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    YNJ: {
        id: 1538,
        airportCode: 'YNJ',
        cityCode: 'YNJ',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Yanji Chaoyangchuan',
        airportNameEn: 'Yanji Chaoyangchuan Airport',
        cityNameVi: 'Yanji',
        cityNameEn: 'Yanji',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    ENY: {
        id: 1537,
        airportCode: 'ENY',
        cityCode: 'ENY',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: "Sân bay Yan'an Ershilibao",
        airportNameEn: "Yan'an Ershilibao Airport",
        cityNameVi: "Yan'an",
        cityNameEn: "Yan'an",
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    XUZ: {
        id: 1536,
        airportCode: 'XUZ',
        cityCode: 'XUZ',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Xuzhou',
        airportNameEn: 'Xuzhou Airport',
        cityNameVi: 'Xuzhou',
        cityNameEn: 'Xuzhou',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    XNN: {
        id: 1535,
        airportCode: 'XNN',
        cityCode: 'XNN',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Xining Caojiabu',
        airportNameEn: 'Xining Caojiabu Airport',
        cityNameVi: 'Xining',
        cityNameEn: 'Xining',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    XIC: {
        id: 1534,
        airportCode: 'XIC',
        cityCode: 'XIC',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Xichang Qingshan',
        airportNameEn: 'Xichang Qingshan Airport',
        cityNameVi: 'Xichang',
        cityNameEn: 'Xichang',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    XFN: {
        id: 1533,
        airportCode: 'XFN',
        cityCode: 'XFN',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Xiangfan',
        airportNameEn: 'Xiangfan Airport',
        cityNameVi: 'Xiangfan',
        cityNameEn: 'Xiangfan',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    XMN: {
        id: 1532,
        airportCode: 'XMN',
        cityCode: 'XMN',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Xiamen Gaoqi',
        airportNameEn: 'Xiamen Gaoqi International Airport',
        cityNameVi: 'Xiamen',
        cityNameEn: 'Xiamen',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    SIA: {
        id: 1531,
        airportCode: 'SIA',
        cityCode: 'SIA',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Xiguan',
        airportNameEn: 'Xiguan Airport',
        cityNameVi: 'Xi An',
        cityNameEn: 'Xi An',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    XIY: {
        id: 1530,
        airportCode: 'XIY',
        cityCode: 'SIA',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: "Sân bay Xi'an Xianyang",
        airportNameEn: "Xi'an Xianyang International Airport",
        cityNameVi: 'Xi An',
        cityNameEn: 'Xi An',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    WEH: {
        id: 1529,
        airportCode: 'WEH',
        cityCode: 'WEH',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Weihai Dashuibo',
        airportNameEn: 'Weihai Dashuibo Airport',
        cityNameVi: 'Weihai',
        cityNameEn: 'Weihai',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    WXN: {
        id: 1528,
        airportCode: 'WXN',
        cityCode: 'WXN',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Wanzhou Wuqiao',
        airportNameEn: 'Wanzhou Wuqiao Airport',
        cityNameVi: 'Wanxian',
        cityNameEn: 'Wanxian',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    WUH: {
        id: 1527,
        airportCode: 'WUH',
        cityCode: 'WUH',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Wuhan Tianhe',
        airportNameEn: 'Wuhan Tianhe International Airport',
        cityNameVi: 'Vũ Hán',
        cityNameEn: 'Wuhan',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    CKG: {
        id: 1526,
        airportCode: 'CKG',
        cityCode: 'CKG',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Trùng Khánh',
        airportNameEn: 'Chongqing Jiangbei International Airport',
        cityNameVi: 'Trùng Khánh',
        cityNameEn: 'Chongqing',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    TEN: {
        id: 1525,
        airportCode: 'TEN',
        cityCode: 'TEN',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Tongren Fenghuang',
        airportNameEn: 'Tongren Fenghuang Airport',
        cityNameVi: 'Tongren',
        cityNameEn: 'Tongren',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    TSN: {
        id: 1524,
        airportCode: 'TSN',
        cityCode: 'TSN',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Tianjin Binhai',
        airportNameEn: 'Tianjin Binhai International Airport',
        cityNameVi: 'Tianjin',
        cityNameEn: 'Tianjin',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    PVG: {
        id: 1523,
        airportCode: 'PVG',
        cityCode: 'SHA',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Shanghai Pudong',
        airportNameEn: 'Shanghai Pudong International Airport',
        cityNameVi: 'Thượng Hải',
        cityNameEn: 'Shanghai',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    SHA: {
        id: 1522,
        airportCode: 'SHA',
        cityCode: 'SHA',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Shanghai Hongqiao',
        airportNameEn: 'Shanghai Hongqiao International Airport',
        cityNameVi: 'Thượng Hải',
        cityNameEn: 'Shanghai',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    CTU: {
        id: 1521,
        airportCode: 'CTU',
        cityCode: 'CTU',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Thành Đô',
        airportNameEn: 'Chengdu Shuangliu International Airport',
        cityNameVi: 'Thành Đô',
        cityNameEn: 'Chengdu',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    TAO: {
        id: 1520,
        airportCode: 'TAO',
        cityCode: 'TAO',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Qingdao Liuting',
        airportNameEn: 'Qingdao Liuting International Airport',
        cityNameVi: 'Thanh Đảo',
        cityNameEn: 'Qingdao',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    SHP: {
        id: 1519,
        airportCode: 'SHP',
        cityCode: 'SHP',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Qinhuangdao Shanhaiguan',
        airportNameEn: 'Qinhuangdao Shanhaiguan Airport',
        cityNameVi: 'Tần Hoàng Đảo',
        cityNameEn: 'Qinhuangdao',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    URC: {
        id: 1518,
        airportCode: 'URC',
        cityCode: 'URC',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Ürümqi Diwopu',
        airportNameEn: 'Ürümqi Diwopu International Airport',
        cityNameVi: 'Tân Cương',
        cityNameEn: 'Urumqi',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    TYN: {
        id: 1517,
        airportCode: 'TYN',
        cityCode: 'TYN',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Taiyuan Wusu',
        airportNameEn: 'Taiyuan Wusu International Airport',
        cityNameVi: 'Taiyuan',
        cityNameEn: 'Taiyuan',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    SJW: {
        id: 1516,
        airportCode: 'SJW',
        cityCode: 'SJW',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Shijiazhuang Zhengding',
        airportNameEn: 'Shijiazhuang Zhengding International Airport',
        cityNameVi: 'Shijiazhuang',
        cityNameEn: 'Shijiazhuang',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    SZX: {
        id: 1515,
        airportCode: 'SZX',
        cityCode: 'SZX',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: "Sân bay Shenzhen Bao'an",
        airportNameEn: "Shenzhen Bao'an International Airport",
        cityNameVi: 'Shenzhen',
        cityNameEn: 'Shenzhen',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    SHE: {
        id: 1514,
        airportCode: 'SHE',
        cityCode: 'SHE',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Shenyang Taoxian',
        airportNameEn: 'Shenyang Taoxian International Airport',
        cityNameVi: 'Shenyang',
        cityNameEn: 'Shenyang',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    SWA: {
        id: 1513,
        airportCode: 'SWA',
        cityCode: 'SWA',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Jieyang Chaoshan',
        airportNameEn: 'Jieyang Chaoshan Airport',
        cityNameVi: 'Shantou',
        cityNameEn: 'Shantou',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    SYX: {
        id: 1512,
        airportCode: 'SYX',
        cityCode: 'SYX',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Sanya Phoenix',
        airportNameEn: 'Sanya Phoenix International Airport',
        cityNameVi: 'Sanya',
        cityNameEn: 'Sanya',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    CAN: {
        id: 1511,
        airportCode: 'CAN',
        cityCode: 'CAN',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Quảng Châu',
        airportNameEn: 'Guangzhou Baiyun International Airport',
        cityNameVi: 'Quảng Châu',
        cityNameEn: 'Guangzhou',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    WNZ: {
        id: 1510,
        airportCode: 'WNZ',
        cityCode: 'WNZ',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Wenzhou Yongqiang',
        airportNameEn: 'Wenzhou Yongqiang Airport',
        cityNameVi: 'Ôn Châu',
        cityNameEn: 'Wenzhou',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    NGB: {
        id: 1509,
        airportCode: 'NGB',
        cityCode: 'NGB',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Ningbo Lishe',
        airportNameEn: 'Ningbo Lishe International Airport',
        cityNameVi: 'Ningbo',
        cityNameEn: 'Ningbo',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    NAO: {
        id: 1508,
        airportCode: 'NAO',
        cityCode: 'NAO',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Nanchong',
        airportNameEn: 'Nanchong Airport',
        cityNameVi: 'Nanchong',
        cityNameEn: 'Nanchong',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    KHN: {
        id: 1507,
        airportCode: 'KHN',
        cityCode: 'KHN',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Nanchang Changbei',
        airportNameEn: 'Nanchang Changbei International Airport',
        cityNameVi: 'Nanchang',
        cityNameEn: 'Nanchang',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    NNG: {
        id: 1506,
        airportCode: 'NNG',
        cityCode: 'NNG',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Nanning Wuxu',
        airportNameEn: 'Nanning Wuxu International Airport',
        cityNameVi: 'Nam Ninh',
        cityNameEn: 'Nanning',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    NKG: {
        id: 1505,
        airportCode: 'NKG',
        cityCode: 'NKG',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Nanjing Lukou',
        airportNameEn: 'Nanjing Lukou International Airport',
        cityNameVi: 'Nam Kinh',
        cityNameEn: 'Nanjing',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    MDG: {
        id: 1504,
        airportCode: 'MDG',
        cityCode: 'MDG',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Mudanjiang',
        airportNameEn: 'Mudanjiang Airport',
        cityNameVi: 'Mudanjiang',
        cityNameEn: 'Mudanjiang',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    MIG: {
        id: 1503,
        airportCode: 'MIG',
        cityCode: 'MIG',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Mianyang Nanjiao',
        airportNameEn: 'Mianyang Nanjiao Airport',
        cityNameVi: 'Mian Yang',
        cityNameEn: 'Mian Yang',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    MXZ: {
        id: 1502,
        airportCode: 'MXZ',
        cityCode: 'MXZ',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Meixian',
        airportNameEn: 'Meixian Airport',
        cityNameVi: 'Meixian',
        cityNameEn: 'Meixian',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    LZO: {
        id: 1501,
        airportCode: 'LZO',
        cityCode: 'LZO',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Luzhou Lantian',
        airportNameEn: 'Luzhou Lantian Airport',
        cityNameVi: 'Luzhou',
        cityNameEn: 'Luzhou',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    LYA: {
        id: 1500,
        airportCode: 'LYA',
        cityCode: 'LYA',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Luoyang Beijiao',
        airportNameEn: 'Luoyang Beijiao Airport',
        cityNameVi: 'Luoyang',
        cityNameEn: 'Luoyang',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    LZH: {
        id: 1499,
        airportCode: 'LZH',
        cityCode: 'LZH',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Liuzhou Bailian',
        airportNameEn: 'Liuzhou Bailian Airport',
        cityNameVi: 'Liuzhou',
        cityNameEn: 'Liuzhou',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    LYI: {
        id: 1498,
        airportCode: 'LYI',
        cityCode: 'LYI',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Linyi Shubuling',
        airportNameEn: 'Linyi Shubuling Airport',
        cityNameVi: 'Linyi',
        cityNameEn: 'Linyi',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    LLF: {
        id: 1497,
        airportCode: 'LLF',
        cityCode: 'LLF',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Yongzhou Lingling',
        airportNameEn: 'Yongzhou Lingling Airport',
        cityNameVi: 'Ling Ling',
        cityNameEn: 'Ling Ling',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    LYG: {
        id: 1496,
        airportCode: 'LYG',
        cityCode: 'LYG',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Lianyungang Baitabu',
        airportNameEn: 'Lianyungang Baitabu Airport',
        cityNameVi: 'Lianyungang',
        cityNameEn: 'Lianyungang',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    LXA: {
        id: 1495,
        airportCode: 'LXA',
        cityCode: 'LXA',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Lhasa Gonggar',
        airportNameEn: 'Lhasa Gonggar Airport',
        cityNameVi: 'Lhasa',
        cityNameEn: 'Lhasa',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    LHW: {
        id: 1494,
        airportCode: 'LHW',
        cityCode: 'LHW',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Lanzhou Zhongchuan',
        airportNameEn: 'Lanzhou Zhongchuan Airport',
        cityNameVi: 'Lanzhou',
        cityNameEn: 'Lanzhou',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    KRL: {
        id: 1493,
        airportCode: 'KRL',
        cityCode: 'KRL',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Korla',
        airportNameEn: 'Korla Airport',
        cityNameVi: 'Korla',
        cityNameEn: 'Korla',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    KRY: {
        id: 1492,
        airportCode: 'KRY',
        cityCode: 'KRY',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Karamay',
        airportNameEn: 'Karamay Airport',
        cityNameVi: 'Karamay',
        cityNameEn: 'Karamay',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    JIU: {
        id: 1491,
        airportCode: 'JIU',
        cityCode: 'JIU',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Jiujiang Lushan',
        airportNameEn: 'Jiujiang Lushan Airport',
        cityNameVi: 'Jiujiang',
        cityNameEn: 'Jiujiang',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    JNZ: {
        id: 1490,
        airportCode: 'JNZ',
        cityCode: 'JNZ',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Jinzhou',
        airportNameEn: 'Jinzhou Airport',
        cityNameVi: 'Jinzhou',
        cityNameEn: 'Jinzhou',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    JJN: {
        id: 1489,
        airportCode: 'JJN',
        cityCode: 'JJN',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Quanzhou Jinjiang',
        airportNameEn: 'Quanzhou Jinjiang Airport',
        cityNameVi: 'Jinjiang',
        cityNameEn: 'Jinjiang',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    JHG: {
        id: 1488,
        airportCode: 'JHG',
        cityCode: 'JHG',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Xishuangbanna Gasa',
        airportNameEn: 'Xishuangbanna Gasa Airport',
        cityNameVi: 'Jinghong',
        cityNameEn: 'Jinghong',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    JDZ: {
        id: 1487,
        airportCode: 'JDZ',
        cityCode: 'JDZ',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Jingdezhen',
        airportNameEn: 'Jingdezhen Airport',
        cityNameVi: 'Jingdezhen',
        cityNameEn: 'Jingdezhen',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    TNA: {
        id: 1486,
        airportCode: 'TNA',
        cityCode: 'TNA',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Jinan Yaoqiang',
        airportNameEn: 'Jinan Yaoqiang International Airport',
        cityNameVi: 'Jinan',
        cityNameEn: 'Jinan',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    JGN: {
        id: 1485,
        airportCode: 'JGN',
        cityCode: 'JGN',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Jiayuguan',
        airportNameEn: 'Jiayuguan Airport',
        cityNameVi: 'Jiayuguan',
        cityNameEn: 'Jiayuguan',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    HYN: {
        id: 1484,
        airportCode: 'HYN',
        cityCode: 'HYN',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Taizhou Luqiao',
        airportNameEn: 'Taizhou Luqiao Airport',
        cityNameVi: 'Huangyan',
        cityNameEn: 'Huangyan',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    HET: {
        id: 1483,
        airportCode: 'HET',
        cityCode: 'HET',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Hohhot Baita',
        airportNameEn: 'Hohhot Baita International Airport',
        cityNameVi: 'Hohhot',
        cityNameEn: 'Hohhot',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    HFE: {
        id: 1482,
        airportCode: 'HFE',
        cityCode: 'HFE',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Hefei Luogang',
        airportNameEn: 'Hefei Luogang International Airport',
        cityNameVi: 'Hefei',
        cityNameEn: 'Hefei',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    HRB: {
        id: 1481,
        airportCode: 'HRB',
        cityCode: 'HRB',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Harbin Taiping',
        airportNameEn: 'Harbin Taiping International Airport',
        cityNameVi: 'Harbin',
        cityNameEn: 'Harbin',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    HGH: {
        id: 1480,
        airportCode: 'HGH',
        cityCode: 'HGH',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Hàng Châu',
        airportNameEn: 'Hangzhou Xiaoshan International Airport',
        cityNameVi: 'Hàng Châu',
        cityNameEn: 'Hangzhou',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    HLD: {
        id: 1479,
        airportCode: 'HLD',
        cityCode: 'HLD',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Hailar Dongshan',
        airportNameEn: 'Hailar Dongshan Airport',
        cityNameVi: 'Hailar',
        cityNameEn: 'Hailar',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    HAK: {
        id: 1478,
        airportCode: 'HAK',
        cityCode: 'HAK',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Haikou Meilan',
        airportNameEn: 'Haikou Meilan International Airport',
        cityNameVi: 'Hà Khẩu',
        cityNameEn: 'Haikou',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    KWE: {
        id: 1477,
        airportCode: 'KWE',
        cityCode: 'KWE',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Guiyang Longdongbao',
        airportNameEn: 'Guiyang Longdongbao International Airport',
        cityNameVi: 'Guiyang',
        cityNameEn: 'Guiyang',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    KWL: {
        id: 1476,
        airportCode: 'KWL',
        cityCode: 'KWL',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Guilin Liangjiang',
        airportNameEn: 'Guilin Liangjiang International Airport',
        cityNameVi: 'Guilin',
        cityNameEn: 'Guilin',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    GYS: {
        id: 1475,
        airportCode: 'GYS',
        cityCode: 'GYS',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Guangyuan',
        airportNameEn: 'Guangyuan Airport',
        cityNameVi: 'Guang Yuan',
        cityNameEn: 'Guang Yuan',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    GOQ: {
        id: 1474,
        airportCode: 'GOQ',
        cityCode: 'GOQ',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Golmud',
        airportNameEn: 'Golmud Airport',
        cityNameVi: 'Golmud',
        cityNameEn: 'Golmud',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    KOW: {
        id: 1473,
        airportCode: 'KOW',
        cityCode: 'KOW',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Ganzhou Huangjin',
        airportNameEn: 'Ganzhou Huangjin Airport',
        cityNameVi: 'Ganzhou',
        cityNameEn: 'Ganzhou',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    FOC: {
        id: 1472,
        airportCode: 'FOC',
        cityCode: 'FOC',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Fuzhou Changle',
        airportNameEn: 'Fuzhou Changle International Airport',
        cityNameVi: 'Fuzhou',
        cityNameEn: 'Fuzhou',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    DNH: {
        id: 1471,
        airportCode: 'DNH',
        cityCode: 'DNH',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Dunhuang',
        airportNameEn: 'Dunhuang Airport',
        cityNameVi: 'Dunhuang',
        cityNameEn: 'Dunhuang',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    DIG: {
        id: 1470,
        airportCode: 'DIG',
        cityCode: 'DIG',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Dêqên Shangri-La',
        airportNameEn: 'Dêqên Shangri-La Airport',
        cityNameVi: 'Diqing',
        cityNameEn: 'Diqing',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    DYG: {
        id: 1469,
        airportCode: 'DYG',
        cityCode: 'DYG',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Zhangjiajie Hehua',
        airportNameEn: 'Zhangjiajie Hehua Airport ',
        cityNameVi: 'Dayong',
        cityNameEn: 'Dayong',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    DAT: {
        id: 1468,
        airportCode: 'DAT',
        cityCode: 'DAT',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Datong Yungang',
        airportNameEn: 'Datong Yungang Airport',
        cityNameVi: 'Datong',
        cityNameEn: 'Datong',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    DDG: {
        id: 1467,
        airportCode: 'DDG',
        cityCode: 'DDG',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Dandong Langtou',
        airportNameEn: 'Dandong Langtou Airport',
        cityNameVi: 'Dandong',
        cityNameEn: 'Dandong',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    DLC: {
        id: 1466,
        airportCode: 'DLC',
        cityCode: 'DLC',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Dalian Zhoushuizi',
        airportNameEn: 'Dalian Zhoushuizi International Airport',
        cityNameVi: 'Dalian',
        cityNameEn: 'Dalian',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    JZH: {
        id: 1465,
        airportCode: 'JZH',
        cityCode: 'JZH',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Jiuzhai Huanglong',
        airportNameEn: 'Jiuzhai Huanglong Airport',
        cityNameVi: 'Cửu Trại Câu',
        cityNameEn: 'Jiuzhaigou',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    KMG: {
        id: 1464,
        airportCode: 'KMG',
        cityCode: 'KMG',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Kunming Changshui',
        airportNameEn: 'Kunming Changshui International Airport',
        cityNameVi: 'Côn Minh',
        cityNameEn: 'Kunming',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    CZX: {
        id: 1463,
        airportCode: 'CZX',
        cityCode: 'CZX',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Changzhou Benniu',
        airportNameEn: 'Changzhou Benniu Airport',
        cityNameVi: 'Changzhou',
        cityNameEn: 'Changzhou',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    CSX: {
        id: 1462,
        airportCode: 'CSX',
        cityCode: 'CSX',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Changsha Huanghua',
        airportNameEn: 'Changsha Huanghua International Airport',
        cityNameVi: 'Changsha',
        cityNameEn: 'Changsha',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    CGD: {
        id: 1461,
        airportCode: 'CGD',
        cityCode: 'CGD',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Changde',
        airportNameEn: 'Changde Airport',
        cityNameVi: 'Changde',
        cityNameEn: 'Changde',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    CGQ: {
        id: 1460,
        airportCode: 'CGQ',
        cityCode: 'CGQ',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Changchun Longjia',
        airportNameEn: 'Changchun Longjia International Airport',
        cityNameVi: 'Changchun',
        cityNameEn: 'Changchun',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    BHY: {
        id: 1459,
        airportCode: 'BHY',
        cityCode: 'BHY',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Beihai',
        airportNameEn: 'Beihai Airport',
        cityNameVi: 'Beihai',
        cityNameEn: 'Beihai',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    BAV: {
        id: 1458,
        airportCode: 'BAV',
        cityCode: 'BAV',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Baotou',
        airportNameEn: 'Baotou Airport',
        cityNameVi: 'Baotou',
        cityNameEn: 'Baotou',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    BSD: {
        id: 1457,
        airportCode: 'BSD',
        cityCode: 'BSD',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Baoshan',
        airportNameEn: 'Baoshan Airport',
        cityNameVi: 'Baoshan',
        cityNameEn: 'Baoshan',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    BJS: {
        id: 1456,
        airportCode: 'BJS',
        cityCode: 'BJS',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Tất cả các sân bay',
        airportNameEn: 'All Airports',
        cityNameVi: 'Bắc Kinh',
        cityNameEn: 'Beijing',
        countryNameVi: 'Trung Qu?c',
        countryNameEn: 'China'
    },
    NAY: {
        id: 1455,
        airportCode: 'NAY',
        cityCode: 'NAY',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Nanyuan',
        airportNameEn: 'Nanyuan Airport',
        cityNameVi: 'Bắc Kinh',
        cityNameEn: 'Beijing',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    PEK: {
        id: 1454,
        airportCode: 'PEK',
        cityCode: 'BJS',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Bắc Kinh',
        airportNameEn: 'Beijing Capital International Airport',
        cityNameVi: 'Bắc Kinh',
        cityNameEn: 'Beijing',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    AQG: {
        id: 1453,
        airportCode: 'AQG',
        cityCode: 'AQG',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Anqing Tianzhushan',
        airportNameEn: 'Anqing Tianzhushan Airport',
        cityNameVi: 'Anqing',
        cityNameEn: 'Anqing',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    AKU: {
        id: 1452,
        airportCode: 'AKU',
        cityCode: 'AKU',
        countryCode: 'CN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Aksu',
        airportNameEn: 'Aksu Airport',
        cityNameVi: 'Aksu',
        cityNameEn: 'Aksu',
        countryNameVi: 'Trung quốc',
        countryNameEn: 'China'
    },
    ZAL: {
        id: 1451,
        airportCode: 'ZAL',
        cityCode: 'ZAL',
        countryCode: 'CL',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Pichoy',
        airportNameEn: 'Pichoy Airport',
        cityNameVi: 'Valdivia',
        cityNameEn: 'Valdivia',
        countryNameVi: 'Chi lê',
        countryNameEn: 'Chile'
    },
    ZCO: {
        id: 1450,
        airportCode: 'ZCO',
        cityCode: 'ZCO',
        countryCode: 'CL',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Maquehue',
        airportNameEn: 'Maquehue Airport',
        cityNameVi: 'Temuco',
        cityNameEn: 'Temuco',
        countryNameVi: 'Chi lê',
        countryNameEn: 'Chile'
    },
    PUQ: {
        id: 1449,
        airportCode: 'PUQ',
        cityCode: 'PUQ',
        countryCode: 'CL',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Carlos Ibanez Del Campo',
        airportNameEn: 'Carlos Ibanez Del Campo International Airport',
        cityNameVi: 'Punta Arenas',
        cityNameEn: 'Punta Arenas',
        countryNameVi: 'Chi lê',
        countryNameEn: 'Chile'
    },
    PMC: {
        id: 1448,
        airportCode: 'PMC',
        cityCode: 'PMC',
        countryCode: 'CL',
        continentCode: 'SA',
        airportNameVi: 'Sân bay El Tepual',
        airportNameEn: 'El Tepual Airport',
        cityNameVi: 'Puerto Montt',
        cityNameEn: 'Puerto Montt',
        countryNameVi: 'Chi lê',
        countryNameEn: 'Chile'
    },
    ZOS: {
        id: 1447,
        airportCode: 'ZOS',
        cityCode: 'ZOS',
        countryCode: 'CL',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Cañal Bajo Carlos Hott Siebert',
        airportNameEn: 'Cañal Bajo Carlos Hott Siebert Airport',
        cityNameVi: 'Osorno',
        cityNameEn: 'Osorno',
        countryNameVi: 'Chi lê',
        countryNameEn: 'Chile'
    },
    LSC: {
        id: 1446,
        airportCode: 'LSC',
        cityCode: 'LSC',
        countryCode: 'CL',
        continentCode: 'SA',
        airportNameVi: 'Sân bay La Florida',
        airportNameEn: 'La Florida Airport',
        cityNameVi: 'La Serena',
        cityNameEn: 'La Serena',
        countryNameVi: 'Chi lê',
        countryNameEn: 'Chile'
    },
    IQQ: {
        id: 1445,
        airportCode: 'IQQ',
        cityCode: 'IQQ',
        countryCode: 'CL',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Diego Aracena',
        airportNameEn: 'Diego Aracena International Airport',
        cityNameVi: 'Iqueque',
        cityNameEn: 'Iquique',
        countryNameVi: 'Chi lê',
        countryNameEn: 'Chile'
    },
    IPC: {
        id: 1444,
        airportCode: 'IPC',
        cityCode: 'IPC',
        countryCode: 'CL',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Mataveri',
        airportNameEn: 'Mataveri International Airport',
        cityNameVi: 'Easter Island',
        cityNameEn: 'Easter Island',
        countryNameVi: 'Chi lê',
        countryNameEn: 'Chile'
    },
    CPO: {
        id: 1443,
        airportCode: 'CPO',
        cityCode: 'CPO',
        countryCode: 'CL',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Chamonate',
        airportNameEn: 'Chamonate Airport',
        cityNameVi: 'Copiapo',
        cityNameEn: 'Copiapo',
        countryNameVi: 'Chi lê',
        countryNameEn: 'Chile'
    },
    CCP: {
        id: 1442,
        airportCode: 'CCP',
        cityCode: 'CCP',
        countryCode: 'CL',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Carriel Sur',
        airportNameEn: 'Carriel Sur International Airport',
        cityNameVi: 'Concepcion',
        cityNameEn: 'Concepcion',
        countryNameVi: 'Chi lê',
        countryNameEn: 'Chile'
    },
    CJC: {
        id: 1441,
        airportCode: 'CJC',
        cityCode: 'CJC',
        countryCode: 'CL',
        continentCode: 'SA',
        airportNameVi: 'Sân bay El Loa',
        airportNameEn: 'El Loa Airport',
        cityNameVi: 'Calama',
        cityNameEn: 'Calama',
        countryNameVi: 'Chi lê',
        countryNameEn: 'Chile'
    },
    BBA: {
        id: 1440,
        airportCode: 'BBA',
        cityCode: 'BBA',
        countryCode: 'CL',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Balmaceda',
        airportNameEn: 'Balmaceda Airport ',
        cityNameVi: 'Balmaceda',
        cityNameEn: 'Balmaceda',
        countryNameVi: 'Chi lê',
        countryNameEn: 'Chile'
    },
    ARI: {
        id: 1439,
        airportCode: 'ARI',
        cityCode: 'ARI',
        countryCode: 'CL',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Chacalluta',
        airportNameEn: 'Chacalluta International Airport',
        cityNameVi: 'Arica',
        cityNameEn: 'Arica',
        countryNameVi: 'Chi lê',
        countryNameEn: 'Chile'
    },
    ANF: {
        id: 1438,
        airportCode: 'ANF',
        cityCode: 'ANF',
        countryCode: 'CL',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Cerro Moreno',
        airportNameEn: 'Cerro Moreno International Airport',
        cityNameVi: 'Antofagasta',
        cityNameEn: 'Antofagasta',
        countryNameVi: 'Chi lê',
        countryNameEn: 'Chile'
    },
    NDJ: {
        id: 1437,
        airportCode: 'NDJ',
        cityCode: 'NDJ',
        countryCode: 'TD',
        continentCode: 'AF',
        airportNameVi: "Sân bay N'Djamena",
        airportNameEn: "N'Djamena International Airport",
        cityNameVi: 'N Djamena',
        cityNameEn: 'N Djamena',
        countryNameVi: 'Chad',
        countryNameEn: 'Chad'
    },
    BGF: {
        id: 1436,
        airportCode: 'BGF',
        cityCode: 'BGF',
        countryCode: 'CF',
        continentCode: 'AF',
        airportNameVi: "Sân bay Bangui M'Poko",
        airportNameEn: "Bangui M'Poko International Airport",
        cityNameVi: 'Bangui',
        cityNameEn: 'Bangui',
        countryNameVi: 'Central African Republic',
        countryNameEn: 'Central African Republic'
    },
    SDF: {
        id: 1435,
        airportCode: 'SDF',
        cityCode: 'SDF',
        countryCode: 'KY',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Louisville',
        airportNameEn: 'Louisville International Airport ',
        cityNameVi: 'Louisville',
        cityNameEn: 'Louisville',
        countryNameVi: 'Cayman Islands',
        countryNameEn: 'Cayman Islands'
    },
    LYB: {
        id: 1434,
        airportCode: 'LYB',
        cityCode: 'LYB',
        countryCode: 'KY',
        continentCode: 'SA',
        airportNameVi: 'Edward Bodden Airfield',
        airportNameEn: 'Edward Bodden Airfield',
        cityNameVi: 'Little Cayman',
        cityNameEn: 'Little Cayman',
        countryNameVi: 'Cayman Islands',
        countryNameEn: 'Cayman Islands'
    },
    LEX: {
        id: 1433,
        airportCode: 'LEX',
        cityCode: 'LEX',
        countryCode: 'KY',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Blue Grass',
        airportNameEn: 'Blue Grass Airport',
        cityNameVi: 'Lexington',
        cityNameEn: 'Lexington',
        countryNameVi: 'Cayman Islands',
        countryNameEn: 'Cayman Islands'
    },
    GCM: {
        id: 1432,
        airportCode: 'GCM',
        cityCode: 'GCM',
        countryCode: 'KY',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Owen Roberts',
        airportNameEn: 'Owen Roberts International Airport',
        cityNameVi: 'Grand Cayman Island',
        cityNameEn: 'Grand Cayman Island',
        countryNameVi: 'Cayman Islands',
        countryNameEn: 'Cayman Islands'
    },
    CYB: {
        id: 1431,
        airportCode: 'CYB',
        cityCode: 'CYB',
        countryCode: 'KY',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Gerrard Smith',
        airportNameEn: 'Gerrard Smith International Airport',
        cityNameVi: 'Cayman Brac',
        cityNameEn: 'Cayman Brac',
        countryNameVi: 'Cayman Islands',
        countryNameEn: 'Cayman Islands'
    },
    MMO: {
        id: 1430,
        airportCode: 'MMO',
        cityCode: 'MMO',
        countryCode: 'CV',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Maio',
        airportNameEn: 'Maio Airport',
        cityNameVi: 'Maio',
        cityNameEn: 'Maio',
        countryNameVi: 'Cape Verde',
        countryNameEn: 'CAPE VERDE'
    },
    XGR: {
        id: 1429,
        airportCode: 'XGR',
        cityCode: 'XGR',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Kangiqsualujjuaq',
        airportNameEn: 'Kangiqsualujjuaq',
        cityNameVi: 'Kangiqsualujjuaq',
        cityNameEn: 'Kangiqsualujjuaq',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YEV: {
        id: 1428,
        airportCode: 'YEV',
        cityCode: 'YEV',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Inuvik',
        airportNameEn: 'Inuvik',
        cityNameVi: 'Inuvik',
        cityNameEn: 'Inuvik',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YQQ: {
        id: 1427,
        airportCode: 'YQQ',
        cityCode: 'YQQ',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'CFB Comox',
        airportNameEn: 'CFB Comox',
        cityNameVi: 'Comox',
        cityNameEn: 'Comox',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YHH: {
        id: 1426,
        airportCode: 'YHH',
        cityCode: 'YBL',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Campbell River Water Aerodrome',
        airportNameEn: 'Campbell River Water Aerodrome',
        cityNameVi: 'Campbell River',
        cityNameEn: 'Campbell River',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YXN: {
        id: 1425,
        airportCode: 'YXN',
        cityCode: 'YXN',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Whale Cove',
        airportNameEn: 'Whale Cove Airport',
        cityNameVi: 'Whale Cove',
        cityNameEn: 'Whale Cove',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YKQ: {
        id: 1424,
        airportCode: 'YKQ',
        cityCode: 'YKQ',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Waskaganish',
        airportNameEn: 'Waskaganish Airport',
        cityNameVi: 'Waskaganish',
        cityNameEn: 'Waskaganish',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YWB: {
        id: 1423,
        airportCode: 'YWB',
        cityCode: 'YWB',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Wakeham Bay',
        airportNameEn: 'Wakeham Bay Airport',
        cityNameVi: 'Kangiqsujuaq',
        cityNameEn: 'Kangiqsujuaq',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YWK: {
        id: 1422,
        airportCode: 'YWK',
        cityCode: 'YWK',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Wabush',
        airportNameEn: 'Wabush Airport',
        cityNameVi: 'Wabush',
        cityNameEn: 'Wabush',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YVO: {
        id: 1421,
        airportCode: 'YVO',
        cityCode: 'YVO',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: "Sân bay Val-d'Or",
        airportNameEn: "Val-d'Or Airport",
        cityNameVi: 'Val D Or',
        cityNameEn: 'Val D Or',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YUD: {
        id: 1420,
        airportCode: 'YUD',
        cityCode: 'YUD',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Umiujaq',
        airportNameEn: 'Umiujaq Airport',
        cityNameVi: 'Umiujaq',
        cityNameEn: 'Umiujaq',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YTS: {
        id: 1419,
        airportCode: 'YTS',
        cityCode: 'YTS',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Timmins/Victor M. Power',
        airportNameEn: 'Timmins/Victor M. Power Airport',
        cityNameVi: 'Timmins',
        cityNameEn: 'Timmins',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YTH: {
        id: 1418,
        airportCode: 'YTH',
        cityCode: 'YTH',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Thompson',
        airportNameEn: 'Thompson Airport',
        cityNameVi: 'Thompson',
        cityNameEn: 'Thompson',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YQD: {
        id: 1417,
        airportCode: 'YQD',
        cityCode: 'YQD',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay The Pas',
        airportNameEn: 'The Pas Airport',
        cityNameVi: 'The Pas',
        cityNameEn: 'The Pas',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    ZTB: {
        id: 1416,
        airportCode: 'ZTB',
        cityCode: 'ZTB',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Tête-à-la-Baleine',
        airportNameEn: 'Tête-à-la-Baleine Airport ',
        cityNameVi: 'Tete A La Baleine',
        cityNameEn: 'Tete A La Baleine',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YTQ: {
        id: 1415,
        airportCode: 'YTQ',
        cityCode: 'YTQ',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Tasiujaq',
        airportNameEn: 'Tasiujaq Airport',
        cityNameVi: 'Tasiujuaq',
        cityNameEn: 'Tasiujuaq',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YYH: {
        id: 1414,
        airportCode: 'YYH',
        cityCode: 'YYH',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Taloyoak',
        airportNameEn: 'Taloyoak Airport',
        cityNameVi: 'Taloyoak',
        cityNameEn: 'Taloyoak',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YQY: {
        id: 1413,
        airportCode: 'YQY',
        cityCode: 'YQY',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Sydney/J.A. Douglas McCurdy',
        airportNameEn: 'Sydney/J.A. Douglas McCurdy Airport',
        cityNameVi: 'Sydney',
        cityNameEn: 'Sydney',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YJT: {
        id: 1412,
        airportCode: 'YJT',
        cityCode: 'YJT',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Stephenville',
        airportNameEn: 'Stephenville Airport',
        cityNameVi: 'Stephenville',
        cityNameEn: 'Stephenville',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YAY: {
        id: 1411,
        airportCode: 'YAY',
        cityCode: 'YAY',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay St. Anthony',
        airportNameEn: 'St. Anthony Airport',
        cityNameVi: 'St Anthony',
        cityNameEn: 'St Anthony',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YXL: {
        id: 1410,
        airportCode: 'YXL',
        cityCode: 'YXL',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Sioux Lookout',
        airportNameEn: 'Sioux Lookout Airport',
        cityNameVi: 'Sioux Lookout',
        cityNameEn: 'Sioux Lookout',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YZV: {
        id: 1409,
        airportCode: 'YZV',
        cityCode: 'YZV',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Sept-Îles',
        airportNameEn: 'Sept-Îles Airport',
        cityNameVi: 'Sept Iles',
        cityNameEn: 'Sept Iles',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YKL: {
        id: 1408,
        airportCode: 'YKL',
        cityCode: 'YKL',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Schefferville',
        airportNameEn: 'Schefferville Airport',
        cityNameVi: 'Schefferville',
        cityNameEn: 'Schefferville',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YZR: {
        id: 1407,
        airportCode: 'YZR',
        cityCode: 'YZR',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Sarnia Chris Hadfield',
        airportNameEn: 'Sarnia Chris Hadfield Airport',
        cityNameVi: 'Sarnia',
        cityNameEn: 'Sarnia',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YSK: {
        id: 1406,
        airportCode: 'YSK',
        cityCode: 'YSK',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Sanikiluaq',
        airportNameEn: 'Sanikiluaq Airport',
        cityNameVi: 'Sanikiluaq',
        cityNameEn: 'Sanikiluaq',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YZP: {
        id: 1405,
        airportCode: 'YZP',
        cityCode: 'YZP',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Sandspit',
        airportNameEn: 'Sandspit Airport',
        cityNameVi: 'Sandspit',
        cityNameEn: 'Sandspit',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YZG: {
        id: 1404,
        airportCode: 'YZG',
        cityCode: 'YZG',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Salluit',
        airportNameEn: 'Salluit Airport',
        cityNameVi: 'Salluit',
        cityNameEn: 'Salluit',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YIF: {
        id: 1403,
        airportCode: 'YIF',
        cityCode: 'YIF',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Saint-Augustin',
        airportNameEn: 'Saint-Augustin Airport',
        cityNameVi: 'Pakuashipi',
        cityNameEn: 'Pakuashipi',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YUY: {
        id: 1402,
        airportCode: 'YUY',
        cityCode: 'YUY',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Rouyn-Noranda',
        airportNameEn: 'Rouyn-Noranda Airport',
        cityNameVi: 'Rouyn Noranda',
        cityNameEn: 'Rouyn Noranda',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YKF: {
        id: 1401,
        airportCode: 'YKF',
        cityCode: 'YKF',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Region of Waterloo',
        airportNameEn: 'Region of Waterloo International Airport',
        cityNameVi: 'Kitchener',
        cityNameEn: 'Kitchener',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YRL: {
        id: 1400,
        airportCode: 'YRL',
        cityCode: 'YRL',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Red Lake',
        airportNameEn: 'Red Lake Airport',
        cityNameVi: 'Red Lake',
        cityNameEn: 'Red Lake',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YRT: {
        id: 1399,
        airportCode: 'YRT',
        cityCode: 'YRT',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Rankin Inlet',
        airportNameEn: 'Rankin Inlet Airport',
        cityNameVi: 'Rankin Inlet',
        cityNameEn: 'Rankin Inlet',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YOP: {
        id: 1398,
        airportCode: 'YOP',
        cityCode: 'YOP',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Rainbow Lake',
        airportNameEn: 'Rainbow Lake Airport',
        cityNameVi: 'Rainbow Lake',
        cityNameEn: 'Rainbow Lake',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YQC: {
        id: 1397,
        airportCode: 'YQC',
        cityCode: 'YQC',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Quaqtaq',
        airportNameEn: 'Quaqtaq Airport',
        cityNameVi: 'Quaqtaq',
        cityNameEn: 'Quaqtaq',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YPX: {
        id: 1396,
        airportCode: 'YPX',
        cityCode: 'YPX',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Puvirnituq',
        airportNameEn: 'Puvirnituq Airport',
        cityNameVi: 'Povungnituk',
        cityNameEn: 'Povungnituk',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YSO: {
        id: 1395,
        airportCode: 'YSO',
        cityCode: 'YSO',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Postville',
        airportNameEn: 'Postville Airport ',
        cityNameVi: 'Postville',
        cityNameEn: 'Postville',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YHA: {
        id: 1394,
        airportCode: 'YHA',
        cityCode: 'YHA',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Port Hope Simpson',
        airportNameEn: 'Port Hope Simpson Airport ',
        cityNameVi: 'Port Hope Simpson',
        cityNameEn: 'Port Hope Simpson',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YZT: {
        id: 1393,
        airportCode: 'YZT',
        cityCode: 'YZT',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Port Hardy',
        airportNameEn: 'Port Hardy Airport',
        cityNameVi: 'Port Hardy',
        cityNameEn: 'Port Hardy',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YPM: {
        id: 1392,
        airportCode: 'YPM',
        cityCode: 'YPM',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Pikangikum',
        airportNameEn: 'Pikangikum Airport',
        cityNameVi: 'St Pierre',
        cityNameEn: 'St Pierre',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YXP: {
        id: 1391,
        airportCode: 'YXP',
        cityCode: 'YXP',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Pangnirtung',
        airportNameEn: 'Pangnirtung Airport',
        cityNameVi: 'Pangnirtung',
        cityNameEn: 'Pangnirtung',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YVQ: {
        id: 1390,
        airportCode: 'YVQ',
        cityCode: 'YVQ',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Norman Wells',
        airportNameEn: 'Norman Wells Airport',
        cityNameVi: 'Norman Wells',
        cityNameEn: 'Norman Wells',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YNA: {
        id: 1389,
        airportCode: 'YNA',
        cityCode: 'YNA',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Natashquan',
        airportNameEn: 'Natashquan Airport',
        cityNameVi: 'Natashquan',
        cityNameEn: 'Natashquan',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    ZNA: {
        id: 1388,
        airportCode: 'ZNA',
        cityCode: 'YCD',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Nanaimo Harbour Water',
        airportNameEn: 'Nanaimo Harbour Water Airport ',
        cityNameVi: 'Nanaimo',
        cityNameEn: 'Nanaimo',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YDP: {
        id: 1387,
        airportCode: 'YDP',
        cityCode: 'YDP',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Nain',
        airportNameEn: 'Nain Airport',
        cityNameVi: 'Nain',
        cityNameEn: 'Nain',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YYY: {
        id: 1386,
        airportCode: 'YYY',
        cityCode: 'YYY',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Mont-Joli',
        airportNameEn: 'Mont-Joli Airport',
        cityNameVi: 'Mont Joli',
        cityNameEn: 'Mont Joli',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YGP: {
        id: 1385,
        airportCode: 'YGP',
        cityCode: 'YGP',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Michel-Pouliot Gaspé',
        airportNameEn: 'Michel-Pouliot Gaspé Airport',
        cityNameVi: 'Gaspe',
        cityNameEn: 'Gaspe',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YXH: {
        id: 1384,
        airportCode: 'YXH',
        cityCode: 'YXH',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Medicine Hat',
        airportNameEn: 'Medicine Hat Airport',
        cityNameVi: 'Medicine Hat',
        cityNameEn: 'Medicine Hat',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YMH: {
        id: 1383,
        airportCode: 'YMH',
        cityCode: 'YMH',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: "Sân bay Mary's Harbour",
        airportNameEn: "Mary's Harbour Airport",
        cityNameVi: 'MARY`S H',
        cityNameEn: 'MARY`S H',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YMN: {
        id: 1382,
        airportCode: 'YMN',
        cityCode: 'YMN',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Makkovik',
        airportNameEn: 'Makkovik Airport',
        cityNameVi: 'Makkovik',
        cityNameEn: 'Makkovik',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YYL: {
        id: 1381,
        airportCode: 'YYL',
        cityCode: 'YYL',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Lynn Lake',
        airportNameEn: 'Lynn Lake Airport',
        cityNameVi: 'Lynn Lake',
        cityNameEn: 'Lynn Lake',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YBX: {
        id: 1380,
        airportCode: 'YBX',
        cityCode: 'YBX',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Lourdes-de-Blanc-Sablon',
        airportNameEn: 'Lourdes-de-Blanc-Sablon Airport',
        cityNameVi: 'Blanc Sablon',
        cityNameEn: 'Blanc Sablon',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YXU: {
        id: 1379,
        airportCode: 'YXU',
        cityCode: 'YXU',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay London',
        airportNameEn: 'London International Airport',
        cityNameVi: 'London',
        cityNameEn: 'London',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YLL: {
        id: 1378,
        airportCode: 'YLL',
        cityCode: 'YLL',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Lloydminster',
        airportNameEn: 'Lloydminster Airport',
        cityNameVi: 'Lloydminster',
        cityNameEn: 'Lloydminster',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YQL: {
        id: 1377,
        airportCode: 'YQL',
        cityCode: 'YQL',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Lethbridge County',
        airportNameEn: 'Lethbridge County Airport',
        cityNameVi: 'Lethbridge',
        cityNameEn: 'Lethbridge',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    ZLT: {
        id: 1376,
        airportCode: 'ZLT',
        cityCode: 'ZLT',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay La Tabatière',
        airportNameEn: 'La Tabatière Airport ',
        cityNameVi: 'La Tabatiere',
        cityNameEn: 'La Tabatiere',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YGL: {
        id: 1375,
        airportCode: 'YGL',
        cityCode: 'YGL',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay La Grande Rivière',
        airportNameEn: 'La Grande Rivière Airport',
        cityNameVi: 'La Grande',
        cityNameEn: 'La Grande',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YGW: {
        id: 1374,
        airportCode: 'YGW',
        cityCode: 'YGW',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Kuujjuarapik',
        airportNameEn: 'Kuujjuarapik Airport',
        cityNameVi: 'Kuujjuarapik',
        cityNameEn: 'Kuujjuarapik',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YVP: {
        id: 1373,
        airportCode: 'YVP',
        cityCode: 'YVP',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Kuujjuaq',
        airportNameEn: 'Kuujjuaq Airport',
        cityNameVi: 'Kuujjuaq',
        cityNameEn: 'Kuujjuaq',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YCO: {
        id: 1372,
        airportCode: 'YCO',
        cityCode: 'YCO',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Kugluktuk',
        airportNameEn: 'Kugluktuk Airport',
        cityNameVi: 'Kugluktuk/Coppermine',
        cityNameEn: 'Kugluktuk/Coppermine',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YLC: {
        id: 1371,
        airportCode: 'YLC',
        cityCode: 'YLC',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Kimmirut',
        airportNameEn: 'Kimmirut Airport',
        cityNameVi: 'Kimmirut/Lake Harbour',
        cityNameEn: 'Kimmirut/Lake Harbour',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YQK: {
        id: 1370,
        airportCode: 'YQK',
        cityCode: 'YQK',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Kenora',
        airportNameEn: 'Kenora Airport',
        cityNameVi: 'Kenora',
        cityNameEn: 'Kenora',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    ZKG: {
        id: 1369,
        airportCode: 'ZKG',
        cityCode: 'ZKG',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Kegaska',
        airportNameEn: 'Kegaska Airport ',
        cityNameVi: 'Kegaska',
        cityNameEn: 'Kegaska',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YYU: {
        id: 1368,
        airportCode: 'YYU',
        cityCode: 'YYU',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Kapuskasing',
        airportNameEn: 'Kapuskasing Airport',
        cityNameVi: 'Kapuskasing',
        cityNameEn: 'Kapuskasing',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YKG: {
        id: 1367,
        airportCode: 'YKG',
        cityCode: 'YKG',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Kangirsuk',
        airportNameEn: 'Kangirsuk Airport',
        cityNameVi: 'Kangirsuk',
        cityNameEn: 'Kangirsuk',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YHM: {
        id: 1366,
        airportCode: 'YHM',
        cityCode: 'YHM',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay John C. Munro Hamilton',
        airportNameEn: 'John C. Munro Hamilton International Airport',
        cityNameVi: 'Hamilton',
        cityNameEn: 'Hamilton',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YIK: {
        id: 1365,
        airportCode: 'YIK',
        cityCode: 'YIK',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Ivujivik',
        airportNameEn: 'Ivujivik Airport',
        cityNameVi: 'Ivujivik',
        cityNameEn: 'Ivujivik',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YFB: {
        id: 1364,
        airportCode: 'YFB',
        cityCode: 'YFB',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Iqaluit',
        airportNameEn: 'Iqaluit Airport',
        cityNameVi: 'Iqaluit',
        cityNameEn: 'Iqaluit',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YPH: {
        id: 1363,
        airportCode: 'YPH',
        cityCode: 'YPH',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Inukjuak',
        airportNameEn: 'Inukjuak Airport',
        cityNameVi: 'Inukjuak',
        cityNameEn: 'Inukjuak',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YGR: {
        id: 1362,
        airportCode: 'YGR',
        cityCode: 'YGR',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Îles-de-la-Madeleine',
        airportNameEn: 'Îles-de-la-Madeleine Airport',
        cityNameVi: 'Iles De Madeleine',
        cityNameEn: 'Iles De Madeleine',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YGT: {
        id: 1361,
        airportCode: 'YGT',
        cityCode: 'YGT',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Igloolik',
        airportNameEn: 'Igloolik Airport',
        cityNameVi: 'Igloolik',
        cityNameEn: 'Igloolik',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YHO: {
        id: 1360,
        airportCode: 'YHO',
        cityCode: 'YHO',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Hopedale',
        airportNameEn: 'Hopedale Airport',
        cityNameVi: 'Hopedale',
        cityNameEn: 'Hopedale',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YOJ: {
        id: 1359,
        airportCode: 'YOJ',
        cityCode: 'YOJ',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay High Level',
        airportNameEn: 'High Level Airport',
        cityNameVi: 'High Level',
        cityNameEn: 'High Level',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YHY: {
        id: 1358,
        airportCode: 'YHY',
        cityCode: 'YHY',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Hay River/Merlyn Carter',
        airportNameEn: 'Hay River/Merlyn Carter Airport',
        cityNameVi: 'Hay River',
        cityNameEn: 'Hay River',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YUX: {
        id: 1357,
        airportCode: 'YUX',
        cityCode: 'YUX',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Hall Beach',
        airportNameEn: 'Hall Beach Airport',
        cityNameVi: 'Hall Beach',
        cityNameEn: 'Hall Beach',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YQM: {
        id: 1356,
        airportCode: 'YQM',
        cityCode: 'YQM',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Greater Moncton',
        airportNameEn: 'Greater Moncton International Airport',
        cityNameVi: 'Moncton',
        cityNameEn: 'Moncton',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YQU: {
        id: 1355,
        airportCode: 'YQU',
        cityCode: 'YQU',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Grande Prairie',
        airportNameEn: 'Grande Prairie Airport',
        cityNameVi: 'Grande Prairie',
        cityNameEn: 'Grande Prairie',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YHK: {
        id: 1354,
        airportCode: 'YHK',
        cityCode: 'YHK',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Gjoa Haven',
        airportNameEn: 'Gjoa Haven Airport',
        cityNameVi: 'Gjoa Haven',
        cityNameEn: 'Gjoa Haven',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YGX: {
        id: 1353,
        airportCode: 'YGX',
        cityCode: 'YGX',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Gillam',
        airportNameEn: 'Gillam Airport',
        cityNameVi: 'Gillam',
        cityNameEn: 'Gillam',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    ZGS: {
        id: 1352,
        airportCode: 'ZGS',
        cityCode: 'ZGS',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Gethsemani',
        airportNameEn: 'Gethsemani Airport',
        cityNameVi: 'Gethsemanie',
        cityNameEn: 'Gethsemanie',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YND: {
        id: 1351,
        airportCode: 'YND',
        cityCode: 'YND',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Gatineau-Ottawa Executive',
        airportNameEn: 'Gatineau-Ottawa Executive Airport',
        cityNameVi: 'Gatineau Hull',
        cityNameEn: 'Gatineau Hull',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YMM: {
        id: 1350,
        airportCode: 'YMM',
        cityCode: 'YMM',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Fort McMurray',
        airportNameEn: 'Fort McMurray Airport',
        cityNameVi: 'Ft McMurray',
        cityNameEn: 'Ft McMurray',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YAG: {
        id: 1349,
        airportCode: 'YAG',
        cityCode: 'YAG',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Fort Frances Municipal',
        airportNameEn: 'Fort Frances Municipal Airport',
        cityNameVi: 'Fort Frances',
        cityNameEn: 'Fort Frances',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YFO: {
        id: 1348,
        airportCode: 'YFO',
        cityCode: 'YFO',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Flin Flon',
        airportNameEn: 'Flin Flon Airport',
        cityNameVi: 'Flin Flon',
        cityNameEn: 'Flin Flon',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YHD: {
        id: 1347,
        airportCode: 'YHD',
        cityCode: 'YHD',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Dryden Regional',
        airportNameEn: 'Dryden Regional Airport',
        cityNameVi: 'Dryden',
        cityNameEn: 'Dryden',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YZS: {
        id: 1346,
        airportCode: 'YZS',
        cityCode: 'YZS',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Coral Harbour',
        airportNameEn: 'Coral Harbour Airport',
        cityNameVi: 'Coral Harbour',
        cityNameEn: 'Coral Harbour',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YCY: {
        id: 1345,
        airportCode: 'YCY',
        cityCode: 'YCY',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Clyde River',
        airportNameEn: 'Clyde River Airport',
        cityNameVi: 'Clyde River',
        cityNameEn: 'Clyde River',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    ZUM: {
        id: 1344,
        airportCode: 'ZUM',
        cityCode: 'ZUM',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Churchill Falls',
        airportNameEn: 'Churchill Falls Airport',
        cityNameVi: 'Churchill Falls',
        cityNameEn: 'Churchill Falls',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YKU: {
        id: 1343,
        airportCode: 'YKU',
        cityCode: 'YKU',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Chisasibi',
        airportNameEn: 'Chisasibi Airport ',
        cityNameVi: 'Chisasibi',
        cityNameEn: 'Chisasibi',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YMT: {
        id: 1342,
        airportCode: 'YMT',
        cityCode: 'YMT',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Chibougamau/Chapais',
        airportNameEn: 'Chibougamau/Chapais Airport',
        cityNameVi: 'Chibougamau',
        cityNameEn: 'Chibougamau',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YHR: {
        id: 1341,
        airportCode: 'YHR',
        cityCode: 'YHR',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Chevery',
        airportNameEn: 'Chevery Airport',
        cityNameVi: 'Chevery',
        cityNameEn: 'Chevery',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YCS: {
        id: 1340,
        airportCode: 'YCS',
        cityCode: 'YCS',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Chesterfield Inlet',
        airportNameEn: 'Chesterfield Inlet Airport',
        cityNameVi: 'Chesterfield Inlet',
        cityNameEn: 'Chesterfield Inlet',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YHG: {
        id: 1339,
        airportCode: 'YHG',
        cityCode: 'YHG',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Charlottetown',
        airportNameEn: 'Charlottetown Airport ',
        cityNameVi: 'Charlottetown',
        cityNameEn: 'Charlottetown',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YCL: {
        id: 1338,
        airportCode: 'YCL',
        cityCode: 'YCL',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Charlo',
        airportNameEn: 'Charlo Airport',
        cityNameVi: 'Charlo',
        cityNameEn: 'Charlo',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YTE: {
        id: 1337,
        airportCode: 'YTE',
        cityCode: 'YTE',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Cape Dorset',
        airportNameEn: 'Cape Dorset Airport',
        cityNameVi: 'Cape Dorset',
        cityNameEn: 'Cape Dorset',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YCB: {
        id: 1336,
        airportCode: 'YCB',
        cityCode: 'YCB',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Cambridge Bay',
        airportNameEn: 'Cambridge Bay Airport',
        cityNameVi: 'Cambridge Bay',
        cityNameEn: 'Cambridge Bay',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YBR: {
        id: 1335,
        airportCode: 'YBR',
        cityCode: 'YBR',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Brandon Municipal',
        airportNameEn: 'Brandon Municipal Airport ',
        cityNameVi: 'Brandon',
        cityNameEn: 'Brandon',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    QBC: {
        id: 1334,
        airportCode: 'QBC',
        cityCode: 'QBC',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Bella Coola',
        airportNameEn: 'Bella Coola Airport',
        cityNameVi: 'Bella Coola',
        cityNameEn: 'Bella Coola',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YBW: {
        id: 1333,
        airportCode: 'YBW',
        cityCode: 'YBW',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Bedwell Harbour',
        airportNameEn: 'Bedwell Harbour Airport',
        cityNameVi: 'Calgary',
        cityNameEn: 'Calgary',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    ZBF: {
        id: 1332,
        airportCode: 'ZBF',
        cityCode: 'ZBF',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Bathurst',
        airportNameEn: 'Bathurst Airport',
        cityNameVi: 'Bathurst',
        cityNameEn: 'Bathurst',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YBK: {
        id: 1331,
        airportCode: 'YBK',
        cityCode: 'YBK',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Baker Lake',
        airportNameEn: 'Baker Lake Airport',
        cityNameVi: 'Baker Lake',
        cityNameEn: 'Baker Lake',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YBC: {
        id: 1330,
        airportCode: 'YBC',
        cityCode: 'YBC',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Baie-Comeau',
        airportNameEn: 'Baie-Comeau Airport',
        cityNameVi: 'Baie Comeau',
        cityNameEn: 'Baie Comeau',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YBG: {
        id: 1329,
        airportCode: 'YBG',
        cityCode: 'YBG',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Bagotville',
        airportNameEn: 'Bagotville Airport',
        cityNameVi: 'Bagotville',
        cityNameEn: 'Bagotville',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YPJ: {
        id: 1328,
        airportCode: 'YPJ',
        cityCode: 'YPJ',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Aupaluk',
        airportNameEn: 'Aupaluk Airport',
        cityNameVi: 'Aupaluk',
        cityNameEn: 'Aupaluk',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YAT: {
        id: 1327,
        airportCode: 'YAT',
        cityCode: 'YAT',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Attawapiskat',
        airportNameEn: 'Attawapiskat Airport',
        cityNameVi: 'Attawapiskat',
        cityNameEn: 'Attawapiskat',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YEK: {
        id: 1326,
        airportCode: 'YEK',
        cityCode: 'YEK',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Arviat',
        airportNameEn: 'Arviat Airport',
        cityNameVi: 'Arviat',
        cityNameEn: 'Arviat',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    AKV: {
        id: 1325,
        airportCode: 'AKV',
        cityCode: 'AKV',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Akulivik',
        airportNameEn: 'Akulivik Airport',
        cityNameVi: 'Akulivik',
        cityNameEn: 'Akulivik',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YXX: {
        id: 1324,
        airportCode: 'YXX',
        cityCode: 'YXX',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Abbotsford',
        airportNameEn: 'Abbotsford International Airport',
        cityNameVi: 'Abbotsford',
        cityNameEn: 'Abbotsford',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YZF: {
        id: 1323,
        airportCode: 'YZF',
        cityCode: 'YZF',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Yellowknife',
        airportNameEn: 'Yellowknife Airport',
        cityNameVi: 'Yellowknife',
        cityNameEn: 'Yellowknife',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YWG: {
        id: 1322,
        airportCode: 'YWG',
        cityCode: 'YWG',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Winnipeg James Armstrong Richardson',
        airportNameEn: 'Winnipeg James Armstrong Richardson International Airport',
        cityNameVi: 'Winnipeg MN',
        cityNameEn: 'Winnipeg',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YQG: {
        id: 1321,
        airportCode: 'YQG',
        cityCode: 'YQG',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Windsor',
        airportNameEn: 'Windsor Airport',
        cityNameVi: 'Windsor',
        cityNameEn: 'Windsor',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YWL: {
        id: 1320,
        airportCode: 'YWL',
        cityCode: 'YWL',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Williams Lake',
        airportNameEn: 'Williams Lake Airport',
        cityNameVi: 'Williams Lake',
        cityNameEn: 'Williams Lake',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YXY: {
        id: 1319,
        airportCode: 'YXY',
        cityCode: 'YXY',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Erik Nielsen Whitehorse',
        airportNameEn: 'Erik Nielsen Whitehorse International Airport',
        cityNameVi: 'Whitehorse',
        cityNameEn: 'Whitehorse',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YWH: {
        id: 1318,
        airportCode: 'YWH',
        cityCode: 'YYJ',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Victoria Inner Harbour',
        airportNameEn: 'Victoria Inner Harbour Airport',
        cityNameVi: 'Victoria',
        cityNameEn: 'Victoria',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YYJ: {
        id: 1317,
        airportCode: 'YYJ',
        cityCode: 'YYJ',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Victoria',
        airportNameEn: 'Victoria International Airport',
        cityNameVi: 'Victoria',
        cityNameEn: 'Victoria',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    CXH: {
        id: 1316,
        airportCode: 'CXH',
        cityCode: 'YVR',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Vancouver Harbour Water Aerodrome ',
        airportNameEn: 'Vancouver Harbour Water Aerodrome ',
        cityNameVi: 'Vancouver',
        cityNameEn: 'Vancouver',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YTO: {
        id: 1315,
        airportCode: 'YTO',
        cityCode: 'YTO',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Tất cả các sân bay',
        airportNameEn: 'All Airports',
        cityNameVi: 'Toronto',
        cityNameEn: 'Toronto',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YKZ: {
        id: 1314,
        airportCode: 'YKZ',
        cityCode: 'YTO',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Toronto/Buttonville Municipal',
        airportNameEn: 'Toronto/Buttonville Municipal Airport',
        cityNameVi: 'Toronto',
        cityNameEn: 'Toronto',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YYZ: {
        id: 1313,
        airportCode: 'YYZ',
        cityCode: 'YTO',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Toronto Pearson',
        airportNameEn: 'Toronto Pearson International Airport',
        cityNameVi: 'Toronto',
        cityNameEn: 'Toronto',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YTZ: {
        id: 1312,
        airportCode: 'YTZ',
        cityCode: 'YTO',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Billy Bishop Toronto City',
        airportNameEn: 'Billy Bishop Toronto City Airport',
        cityNameVi: 'Toronto',
        cityNameEn: 'Toronto',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YQT: {
        id: 1311,
        airportCode: 'YQT',
        cityCode: 'YQT',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Thunder Bay',
        airportNameEn: 'Thunder Bay Airport',
        cityNameVi: 'Thunder Bay OT',
        cityNameEn: 'Thunder Bay',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YXT: {
        id: 1310,
        airportCode: 'YXT',
        cityCode: 'YXT',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Northwest Regional',
        airportNameEn: 'Northwest Regional Airport',
        cityNameVi: 'Terrace',
        cityNameEn: 'Terrace',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YSB: {
        id: 1309,
        airportCode: 'YSB',
        cityCode: 'YSB',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Sudbury',
        airportNameEn: 'Sudbury Airport',
        cityNameVi: 'Sudbury',
        cityNameEn: 'Sudbury',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YYT: {
        id: 1308,
        airportCode: 'YYT',
        cityCode: 'YYT',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: "Sân bay St. John's",
        airportNameEn: "St. John's International Airport",
        cityNameVi: 'St Johns',
        cityNameEn: 'St Johns',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YSJ: {
        id: 1307,
        airportCode: 'YSJ',
        cityCode: 'YSJ',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Saint John',
        airportNameEn: 'Saint John Airport',
        cityNameVi: 'St John',
        cityNameEn: 'St John',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YYD: {
        id: 1306,
        airportCode: 'YYD',
        cityCode: 'YYD',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Smithers',
        airportNameEn: 'Smithers Airport',
        cityNameVi: 'Smithers',
        cityNameEn: 'Smithers',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YAM: {
        id: 1305,
        airportCode: 'YAM',
        cityCode: 'YAM',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Sault Ste. Marie',
        airportNameEn: 'Sault Ste. Marie Airport',
        cityNameVi: 'Sault Ste Marie',
        cityNameEn: 'Sault Ste Marie',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YQZ: {
        id: 1304,
        airportCode: 'YQZ',
        cityCode: 'YQZ',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Quesnel',
        airportNameEn: 'Quesnel Airport',
        cityNameVi: 'Quesnel',
        cityNameEn: 'Quesnel',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YQB: {
        id: 1303,
        airportCode: 'YQB',
        cityCode: 'YQB',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Québec/Jean Lesage',
        airportNameEn: 'Québec/Jean Lesage International Airport',
        cityNameVi: 'Quebec',
        cityNameEn: 'Quebec',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    ZSW: {
        id: 1302,
        airportCode: 'ZSW',
        cityCode: 'YPR',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Prince Rupert/Seal Cove Water',
        airportNameEn: 'Prince Rupert/Seal Cove Water Airport',
        cityNameVi: 'Prince Rupert',
        cityNameEn: 'Prince Rupert',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YPR: {
        id: 1301,
        airportCode: 'YPR',
        cityCode: 'YPR',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Prince Rupert',
        airportNameEn: 'Prince Rupert Airport',
        cityNameVi: 'Prince Rupert',
        cityNameEn: 'Prince Rupert',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YXS: {
        id: 1300,
        airportCode: 'YXS',
        cityCode: 'YXS',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Prince George',
        airportNameEn: 'Prince George Airport',
        cityNameVi: 'Prince George',
        cityNameEn: 'Prince George',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YPW: {
        id: 1299,
        airportCode: 'YPW',
        cityCode: 'YPW',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Powell River',
        airportNameEn: 'Powell River Airport',
        cityNameVi: 'Powell River',
        cityNameEn: 'Powell River',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YYF: {
        id: 1298,
        airportCode: 'YYF',
        cityCode: 'YYF',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Penticton',
        airportNameEn: 'Penticton Airport',
        cityNameVi: 'Penticton',
        cityNameEn: 'Penticton',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YBB: {
        id: 1297,
        airportCode: 'YBB',
        cityCode: 'YBB',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Kugaaruk',
        airportNameEn: 'Kugaaruk Airport',
        cityNameVi: 'Pelly Bay',
        cityNameEn: 'Pelly Bay',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YOW: {
        id: 1296,
        airportCode: 'YOW',
        cityCode: 'YOW',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Ottawa/Macdonald-Cartier',
        airportNameEn: 'Ottawa/Macdonald-Cartier International Airport',
        cityNameVi: 'Ottawa',
        cityNameEn: 'Ottawa',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YYB: {
        id: 1295,
        airportCode: 'YYB',
        cityCode: 'YYB',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay North Bay/Jack Garland',
        airportNameEn: 'North Bay/Jack Garland Airport',
        cityNameVi: 'North Bay',
        cityNameEn: 'North Bay',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YMO: {
        id: 1294,
        airportCode: 'YMO',
        cityCode: 'YMO',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Moosonee',
        airportNameEn: 'Moosonee Airport',
        cityNameVi: 'Moosonee',
        cityNameEn: 'Moosonee',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YMQ: {
        id: 1293,
        airportCode: 'YMQ',
        cityCode: 'YMQ',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Tất cả các sân bay',
        airportNameEn: 'All Airports',
        cityNameVi: 'Montreal',
        cityNameEn: 'Montreal',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YUL: {
        id: 1292,
        airportCode: 'YUL',
        cityCode: 'YMQ',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Montréal–Pierre Elliott Trudeau',
        airportNameEn: 'Montréal–Pierre Elliott Trudeau International Airport',
        cityNameVi: 'Montreal',
        cityNameEn: 'Montreal',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YMX: {
        id: 1291,
        airportCode: 'YMX',
        cityCode: 'YMQ',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Montréal-Mirabel',
        airportNameEn: 'Montréal-Mirabel International Airport',
        cityNameVi: 'Montreal',
        cityNameEn: 'Montreal',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YHU: {
        id: 1290,
        airportCode: 'YHU',
        cityCode: 'YMQ',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Montréal/Saint-Hubert',
        airportNameEn: 'Montréal/Saint-Hubert Airport',
        cityNameVi: 'Montreal',
        cityNameEn: 'Montreal',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YGK: {
        id: 1289,
        airportCode: 'YGK',
        cityCode: 'YGK',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Kingston/Norman Rogers',
        airportNameEn: 'Kingston/Norman Rogers Airport',
        cityNameVi: 'Kingston',
        cityNameEn: 'Kingston',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YLW: {
        id: 1288,
        airportCode: 'YLW',
        cityCode: 'YLW',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Kelowna',
        airportNameEn: 'Kelowna International Airport',
        cityNameVi: 'Kelowna',
        cityNameEn: 'Kelowna',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YKA: {
        id: 1287,
        airportCode: 'YKA',
        cityCode: 'YKA',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Kamloops',
        airportNameEn: 'Kamloops Airport',
        cityNameVi: 'Kamloops',
        cityNameEn: 'Kamloops',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YHZ: {
        id: 1286,
        airportCode: 'YHZ',
        cityCode: 'YHZ',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Halifax Stanfield',
        airportNameEn: 'Halifax Stanfield International Airport',
        cityNameVi: 'Halifax',
        cityNameEn: 'Halifax',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YYR: {
        id: 1285,
        airportCode: 'YYR',
        cityCode: 'YYR',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay CFB Goose Bay / Goose Bay',
        airportNameEn: 'CFB Goose Bay / Goose Bay Airport',
        cityNameVi: 'Goose Bay',
        cityNameEn: 'Goose Bay',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YQX: {
        id: 1284,
        airportCode: 'YQX',
        cityCode: 'YQX',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Gander',
        airportNameEn: 'Gander International Airport / CFB Gander',
        cityNameVi: 'Gander',
        cityNameEn: 'Gander',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YXJ: {
        id: 1283,
        airportCode: 'YXJ',
        cityCode: 'YXJ',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Fort St. John',
        airportNameEn: 'Fort St. John Airport',
        cityNameVi: 'Ft St John',
        cityNameEn: 'Ft St John',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YSM: {
        id: 1282,
        airportCode: 'YSM',
        cityCode: 'YSM',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Fort Smith',
        airportNameEn: 'Fort Smith Airport',
        cityNameVi: 'Ft Smith',
        cityNameEn: 'Ft Smith',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YFC: {
        id: 1281,
        airportCode: 'YFC',
        cityCode: 'YFC',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Fredericton',
        airportNameEn: 'Fredericton International Airport',
        cityNameVi: 'Fredericton',
        cityNameEn: 'Fredericton',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YYE: {
        id: 1280,
        airportCode: 'YYE',
        cityCode: 'YYE',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Fort Nelson',
        airportNameEn: 'Fort Nelson Airport',
        cityNameVi: 'Fort Nelson',
        cityNameEn: 'Fort Nelson',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YEL: {
        id: 1279,
        airportCode: 'YEL',
        cityCode: 'YEL',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Elliot Lake Municipal',
        airportNameEn: 'Elliot Lake Municipal Airport',
        cityNameVi: 'Elliot Lake',
        cityNameEn: 'Elliot Lake',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YEA: {
        id: 1278,
        airportCode: 'YEA',
        cityCode: 'YEA',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Tất cả các sân bay',
        airportNameEn: 'All Airports',
        cityNameVi: 'Edmonton',
        cityNameEn: 'Edmonton',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YXD: {
        id: 1277,
        airportCode: 'YXD',
        cityCode: 'YEA',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Edmonton City Centre',
        airportNameEn: 'Edmonton City Centre',
        cityNameVi: 'Edmonton',
        cityNameEn: 'Edmonton',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YED: {
        id: 1276,
        airportCode: 'YED',
        cityCode: 'YEA',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'CFB Edmonton ',
        airportNameEn: 'CFB Edmonton ',
        cityNameVi: 'Edmonton',
        cityNameEn: 'Edmonton',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YEG: {
        id: 1275,
        airportCode: 'YEG',
        cityCode: 'YEA',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Edmonton',
        airportNameEn: 'Edmonton International Airport',
        cityNameVi: 'Edmonton',
        cityNameEn: 'Edmonton',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YDF: {
        id: 1274,
        airportCode: 'YDF',
        cityCode: 'YDF',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Deer Lake Regional',
        airportNameEn: 'Deer Lake Regional Airport',
        cityNameVi: 'Deer Lake',
        cityNameEn: 'Deer Lake',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YDQ: {
        id: 1273,
        airportCode: 'YDQ',
        cityCode: 'YDQ',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Dawson Creek',
        airportNameEn: 'Dawson Creek Airport',
        cityNameVi: 'Dawson Creek',
        cityNameEn: 'Dawson Creek',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YXC: {
        id: 1272,
        airportCode: 'YXC',
        cityCode: 'YXC',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Cranbrook/Canadian Rockies',
        airportNameEn: 'Cranbrook/Canadian Rockies International Airport',
        cityNameVi: 'Cranbrook',
        cityNameEn: 'Cranbrook',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YYQ: {
        id: 1271,
        airportCode: 'YYQ',
        cityCode: 'YYQ',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Churchill',
        airportNameEn: 'Churchill Airport',
        cityNameVi: 'Churchill',
        cityNameEn: 'Churchill',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YYG: {
        id: 1270,
        airportCode: 'YYG',
        cityCode: 'YYG',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Charlottetown',
        airportNameEn: 'Charlottetown Airport',
        cityNameVi: 'Charlottetown',
        cityNameEn: 'Charlottetown',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YCG: {
        id: 1269,
        airportCode: 'YCG',
        cityCode: 'YCG',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay West Kootenay Regional',
        airportNameEn: 'West Kootenay Regional Airport ',
        cityNameVi: 'Castlegar',
        cityNameEn: 'Castlegar',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    YQF: {
        id: 1268,
        airportCode: 'YQF',
        cityCode: 'YYC',
        countryCode: 'CA',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Red Deer Regional',
        airportNameEn: 'Red Deer Regional Airport',
        cityNameVi: 'Calgary AL',
        cityNameEn: 'Calgary',
        countryNameVi: 'Canada',
        countryNameEn: 'Canada'
    },
    REP: {
        id: 1267,
        airportCode: 'REP',
        cityCode: 'REP',
        countryCode: 'KH',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Angkor',
        airportNameEn: 'Angkor International Airport',
        cityNameVi: 'Siem Reap',
        cityNameEn: 'Siem Reap',
        countryNameVi: 'Campuchia',
        countryNameEn: 'Cambodia'
    },
    PNH: {
        id: 1266,
        airportCode: 'PNH',
        cityCode: 'PNH',
        countryCode: 'KH',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Phnom Penh',
        airportNameEn: 'Phnom Penh International Airport',
        cityNameVi: 'Phnôm Pênh',
        cityNameEn: 'Phnom Penh',
        countryNameVi: 'Campuchia',
        countryNameEn: 'Cambodia'
    },
    BJM: {
        id: 1265,
        airportCode: 'BJM',
        cityCode: 'BJM',
        countryCode: 'BI',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Bujumbura',
        airportNameEn: 'Bujumbura International Airport',
        cityNameVi: 'Bujumbura',
        cityNameEn: 'Bujumbura',
        countryNameVi: 'Burundi',
        countryNameEn: 'Burundi'
    },
    OUA: {
        id: 1264,
        airportCode: 'OUA',
        cityCode: 'OUA',
        countryCode: 'BF',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Ouagadougou',
        airportNameEn: 'Ouagadougou Airport',
        cityNameVi: 'Ouagadougou',
        cityNameEn: 'Ouagadougou',
        countryNameVi: 'Buốc ki na pha sô',
        countryNameEn: 'Burkina Faso'
    },
    BOY: {
        id: 1263,
        airportCode: 'BOY',
        cityCode: 'BOY',
        countryCode: 'BF',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Bobo Dioulasso',
        airportNameEn: 'Bobo Dioulasso Airport',
        cityNameVi: 'Bobo Dioulasso',
        cityNameEn: 'Bobo Dioulasso',
        countryNameVi: 'Buốc ki na pha sô',
        countryNameEn: 'Burkina Faso'
    },
    VAR: {
        id: 1262,
        airportCode: 'VAR',
        cityCode: 'VAR',
        countryCode: 'BG',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Varna',
        airportNameEn: 'Varna Airport',
        cityNameVi: 'Varna',
        cityNameEn: 'Varna',
        countryNameVi: 'Bun ga ri',
        countryNameEn: 'Bulgaria'
    },
    SOF: {
        id: 1261,
        airportCode: 'SOF',
        cityCode: 'SOF',
        countryCode: 'BG',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Sofia',
        airportNameEn: 'Sofia Airport',
        cityNameVi: 'Sofia',
        cityNameEn: 'Sofia',
        countryNameVi: 'Bun ga ri',
        countryNameEn: 'Bulgaria'
    },
    PDV: {
        id: 1260,
        airportCode: 'PDV',
        cityCode: 'PDV',
        countryCode: 'BG',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Plovdiv',
        airportNameEn: 'Plovdiv International Airport',
        cityNameVi: 'Plovdiv',
        cityNameEn: 'Plovdiv',
        countryNameVi: 'Bun ga ri',
        countryNameEn: 'Bulgaria'
    },
    BOJ: {
        id: 1259,
        airportCode: 'BOJ',
        cityCode: 'BOJ',
        countryCode: 'BG',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Burgas',
        airportNameEn: 'Burgas Airport',
        cityNameVi: 'Bourgas',
        cityNameEn: 'Burgas',
        countryNameVi: 'Bun ga ri',
        countryNameEn: 'Bulgaria'
    },
    BWN: {
        id: 1258,
        airportCode: 'BWN',
        cityCode: 'BWN',
        countryCode: 'BN',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Brunei',
        airportNameEn: 'Brunei International Airport',
        cityNameVi: 'Bandar Seri Begawan',
        cityNameEn: 'Bandar Seri Begawan',
        countryNameVi: 'Bru nêy',
        countryNameEn: 'Brunei'
    },
    VIJ: {
        id: 1257,
        airportCode: 'VIJ',
        cityCode: 'VIJ',
        countryCode: 'VG',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Virgin Gorda',
        airportNameEn: 'Virgin Gorda Airport',
        cityNameVi: 'Virgin Gorda',
        cityNameEn: 'Virgin Gorda',
        countryNameVi: 'British Virgin Islands',
        countryNameEn: 'British Virgin Islands'
    },
    EIS: {
        id: 1256,
        airportCode: 'EIS',
        cityCode: 'EIS',
        countryCode: 'VG',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Terrance B. Lettsome',
        airportNameEn: 'Terrance B. Lettsome International Airport',
        cityNameVi: 'Beef Island',
        cityNameEn: 'Beef Island',
        countryNameVi: 'British Virgin Islands',
        countryNameEn: 'British Virgin Islands'
    },
    CXJ: {
        id: 1255,
        airportCode: 'CXJ',
        cityCode: 'CXJ',
        countryCode: 'BR',
        continentCode: 'SA',
        airportNameVi: 'Aeroporto Hugo Cantergiani',
        airportNameEn: 'Aeroporto Hugo Cantergiani',
        cityNameVi: 'Caxias Do Sul',
        cityNameEn: 'Caxias Do Sul',
        countryNameVi: 'Bra xin',
        countryNameEn: 'Brazil'
    },
    BEL: {
        id: 1254,
        airportCode: 'BEL',
        cityCode: 'BEL',
        countryCode: 'BR',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Val de Cães',
        airportNameEn: 'Val de Cães International Airport',
        cityNameVi: 'Belem',
        cityNameEn: 'Belem',
        countryNameVi: 'Bra xin',
        countryNameEn: 'Brazil'
    },
    CNF: {
        id: 1253,
        airportCode: 'CNF',
        cityCode: 'BHZ',
        countryCode: 'BR',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Tancredo Neves',
        airportNameEn: 'Tancredo Neves International Airport ',
        cityNameVi: 'Belo Horizonte',
        cityNameEn: 'Belo Horizonte',
        countryNameVi: 'Bra xin',
        countryNameEn: 'Brazil'
    },
    RIG: {
        id: 1252,
        airportCode: 'RIG',
        cityCode: 'RIG',
        countryCode: 'BR',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Rio Grande',
        airportNameEn: 'Rio Grande Airport',
        cityNameVi: 'Rio Grande',
        cityNameEn: 'Rio Grande',
        countryNameVi: 'Bra xin',
        countryNameEn: 'Brazil'
    },
    SJP: {
        id: 1251,
        airportCode: 'SJP',
        cityCode: 'SJP',
        countryCode: 'BR',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Prof. Eribelto Manoel Reino State',
        airportNameEn: 'Prof. Eribelto Manoel Reino State Airport',
        cityNameVi: 'Sao Jose Do Rio Preto',
        cityNameEn: 'Sao Jose Do Rio Preto',
        countryNameVi: 'Bra xin',
        countryNameEn: 'Brazil'
    },
    PET: {
        id: 1250,
        airportCode: 'PET',
        cityCode: 'PET',
        countryCode: 'BR',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Pelotas',
        airportNameEn: 'Pelotas International Airport',
        cityNameVi: 'Pelotas',
        cityNameEn: 'Pelotas',
        countryNameVi: 'Bra xin',
        countryNameEn: 'Brazil'
    },
    PLU: {
        id: 1249,
        airportCode: 'PLU',
        cityCode: 'BHZ',
        countryCode: 'BR',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Pampulha Domestic',
        airportNameEn: 'Pampulha Domestic Airport',
        cityNameVi: 'Belo Horizonte',
        cityNameEn: 'Belo Horizonte',
        countryNameVi: 'Bra xin',
        countryNameEn: 'Brazil'
    },
    MOC: {
        id: 1248,
        airportCode: 'MOC',
        cityCode: 'MOC',
        countryCode: 'BR',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Montes Claros',
        airportNameEn: 'Montes Claros Airport',
        cityNameVi: 'Montes Claros',
        cityNameEn: 'Montes Claros',
        countryNameVi: 'Bra xin',
        countryNameEn: 'Brazil'
    },
    MGF: {
        id: 1247,
        airportCode: 'MGF',
        cityCode: 'MGF',
        countryCode: 'BR',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Maringá - Sílvio Name Júnior Regional',
        airportNameEn: 'Maringá - Sílvio Name Júnior Regional Airport',
        cityNameVi: 'Maringa',
        cityNameEn: 'Maringa',
        countryNameVi: 'Bra xin',
        countryNameEn: 'Brazil'
    },
    MAB: {
        id: 1246,
        airportCode: 'MAB',
        cityCode: 'MAB',
        countryCode: 'BR',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Marabá',
        airportNameEn: 'Marabá Airport',
        cityNameVi: 'Maraba',
        cityNameEn: 'Maraba',
        countryNameVi: 'Bra xin',
        countryNameEn: 'Brazil'
    },
    LDB: {
        id: 1245,
        airportCode: 'LDB',
        cityCode: 'LDB',
        countryCode: 'BR',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Londrina',
        airportNameEn: 'Londrina Airport',
        cityNameVi: 'Londrina',
        cityNameEn: 'Londrina',
        countryNameVi: 'Bra xin',
        countryNameEn: 'Brazil'
    },
    PFB: {
        id: 1244,
        airportCode: 'PFB',
        cityCode: 'PFB',
        countryCode: 'BR',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Lauro Kurtz',
        airportNameEn: 'Lauro Kurtz Airport',
        cityNameVi: 'Passo Fundo',
        cityNameEn: 'Passo Fundo',
        countryNameVi: 'Bra xin',
        countryNameEn: 'Brazil'
    },
    IMP: {
        id: 1243,
        airportCode: 'IMP',
        cityCode: 'IMP',
        countryCode: 'BR',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Imperatriz',
        airportNameEn: 'Imperatriz Airport',
        cityNameVi: 'Imperatriz',
        cityNameEn: 'Imperatriz',
        countryNameVi: 'Bra xin',
        countryNameEn: 'Brazil'
    },
    FLN: {
        id: 1242,
        airportCode: 'FLN',
        cityCode: 'FLN',
        countryCode: 'BR',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Hercílio Luz',
        airportNameEn: 'Hercílio Luz International Airport',
        cityNameVi: 'Florianopolis',
        cityNameEn: 'Florianopolis',
        countryNameVi: 'Bra xin',
        countryNameEn: 'Brazil'
    },
    PVH: {
        id: 1241,
        airportCode: 'PVH',
        cityCode: 'PVH',
        countryCode: 'BR',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Governador Jorge Teixeira de Oliveira',
        airportNameEn: 'Governador Jorge Teixeira de Oliveira International Airport',
        cityNameVi: 'Porto Velho',
        cityNameEn: 'Porto Velho',
        countryNameVi: 'Bra xin',
        countryNameEn: 'Brazil'
    },
    FEN: {
        id: 1240,
        airportCode: 'FEN',
        cityCode: 'FEN',
        countryCode: 'BR',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Fernando de Noronha',
        airportNameEn: 'Fernando de Noronha Airport',
        cityNameVi: 'Fernando De Noronha',
        cityNameEn: 'Fernando De Noronha',
        countryNameVi: 'Bra xin',
        countryNameEn: 'Brazil'
    },
    MAO: {
        id: 1239,
        airportCode: 'MAO',
        cityCode: 'MAO',
        countryCode: 'BR',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Eduardo Gomes',
        airportNameEn: 'Eduardo Gomes International Airport',
        cityNameVi: 'Manaus',
        cityNameEn: 'Manaus',
        countryNameVi: 'Bra xin',
        countryNameEn: 'Brazil'
    },
    SSA: {
        id: 1238,
        airportCode: 'SSA',
        cityCode: 'SSA',
        countryCode: 'BR',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Deputado Luís Eduardo Magalhães',
        airportNameEn: 'Deputado Luís Eduardo Magalhães International Airport',
        cityNameVi: 'Salvador',
        cityNameEn: 'Salvador',
        countryNameVi: 'Bra xin',
        countryNameEn: 'Brazil'
    },
    XAP: {
        id: 1237,
        airportCode: 'XAP',
        cityCode: 'XAP',
        countryCode: 'BR',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Chapecó',
        airportNameEn: 'Chapecó Airport',
        cityNameVi: 'Chapeco',
        cityNameEn: 'Chapeco',
        countryNameVi: 'Bra xin',
        countryNameEn: 'Brazil'
    },
    CAC: {
        id: 1236,
        airportCode: 'CAC',
        cityCode: 'CAC',
        countryCode: 'BR',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Cascavel',
        airportNameEn: 'Cascavel Airport',
        cityNameVi: 'Cascavel',
        cityNameEn: 'Cascavel',
        countryNameVi: 'Bra xin',
        countryNameEn: 'Brazil'
    },
    JDO: {
        id: 1235,
        airportCode: 'JDO',
        cityCode: 'JDO',
        countryCode: 'BR',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Cariri Regional',
        airportNameEn: 'Cariri Regional Airport',
        cityNameVi: 'Juazeiro Do Norte',
        cityNameEn: 'Juazeiro Do Norte',
        countryNameVi: 'Bra xin',
        countryNameEn: 'Brazil'
    },
    CPV: {
        id: 1234,
        airportCode: 'CPV',
        cityCode: 'CPV',
        countryCode: 'BR',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Campina Grande',
        airportNameEn: 'Campina Grande Airport',
        cityNameVi: 'Campina Grande',
        cityNameEn: 'Campina Grande',
        countryNameVi: 'Bra xin',
        countryNameEn: 'Brazil'
    },
    ARU: {
        id: 1233,
        airportCode: 'ARU',
        cityCode: 'ARU',
        countryCode: 'BR',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Araçatuba',
        airportNameEn: 'Araçatuba Airport',
        cityNameVi: 'Aracatuba',
        cityNameEn: 'Aracatuba',
        countryNameVi: 'Bra xin',
        countryNameEn: 'Brazil'
    },
    PPB: {
        id: 1232,
        airportCode: 'PPB',
        cityCode: 'PPB',
        countryCode: 'BR',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Adhemar de Barros',
        airportNameEn: 'Adhemar de Barros Airport',
        cityNameVi: 'Presidente Prudente',
        cityNameEn: 'Presidente Prudente',
        countryNameVi: 'Bra xin',
        countryNameEn: 'Brazil'
    },
    VIX: {
        id: 1231,
        airportCode: 'VIX',
        cityCode: 'VIX',
        countryCode: 'BR',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Eurico de Aguiar Salles',
        airportNameEn: 'Eurico de Aguiar Salles Airport',
        cityNameVi: 'Vitoria',
        cityNameEn: 'Vitoria',
        countryNameVi: 'Bra xin',
        countryNameEn: 'Brazil'
    },
    UDI: {
        id: 1230,
        airportCode: 'UDI',
        cityCode: 'UDI',
        countryCode: 'BR',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Uberlandia',
        airportNameEn: 'Uberlandia Airport',
        cityNameVi: 'Uberlandia',
        cityNameEn: 'Uberlandia',
        countryNameVi: 'Bra xin',
        countryNameEn: 'Brazil'
    },
    THE: {
        id: 1229,
        airportCode: 'THE',
        cityCode: 'THE',
        countryCode: 'BR',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Teresina',
        airportNameEn: 'Teresina Airport',
        cityNameVi: 'Teresina',
        cityNameEn: 'Teresina',
        countryNameVi: 'Bra xin',
        countryNameEn: 'Brazil'
    },
    SAO: {
        id: 1228,
        airportCode: 'SAO',
        cityCode: 'SAO',
        countryCode: 'BR',
        continentCode: 'SA',
        airportNameVi: 'Tất cả các sân bay',
        airportNameEn: 'All Airports',
        cityNameVi: 'São Paulo',
        cityNameEn: 'São Paulo',
        countryNameVi: 'Bra xin',
        countryNameEn: 'Brazil'
    },
    VCP: {
        id: 1227,
        airportCode: 'VCP',
        cityCode: 'SAO',
        countryCode: 'BR',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Viracopos-Campinas',
        airportNameEn: 'Viracopos-Campinas International Airport',
        cityNameVi: 'Sao Paulo',
        cityNameEn: 'Sao Paulo',
        countryNameVi: 'Bra xin',
        countryNameEn: 'Brazil'
    },
    GRU: {
        id: 1226,
        airportCode: 'GRU',
        cityCode: 'SAO',
        countryCode: 'BR',
        continentCode: 'SA',
        airportNameVi: 'Sân bay São Paulo-Guarulhos',
        airportNameEn: 'São Paulo-Guarulhos International Airport',
        cityNameVi: 'Sao Paulo',
        cityNameEn: 'Sao Paulo',
        countryNameVi: 'Bra xin',
        countryNameEn: 'Brazil'
    },
    CGH: {
        id: 1225,
        airportCode: 'CGH',
        cityCode: 'SAO',
        countryCode: 'BR',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Congonhas/São Paulo National',
        airportNameEn: 'Congonhas/São Paulo National Airport',
        cityNameVi: 'Sao Paulo',
        cityNameEn: 'Sao Paulo',
        countryNameVi: 'Bra xin',
        countryNameEn: 'Brazil'
    },
    SLZ: {
        id: 1224,
        airportCode: 'SLZ',
        cityCode: 'SLZ',
        countryCode: 'BR',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Marechal Cunha Machado',
        airportNameEn: 'Marechal Cunha Machado International Airport',
        cityNameVi: 'Sao Luiz',
        cityNameEn: 'Sao Luiz',
        countryNameVi: 'Bra xin',
        countryNameEn: 'Brazil'
    },
    STM: {
        id: 1223,
        airportCode: 'STM',
        cityCode: 'STM',
        countryCode: 'BR',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Santarém-Maestro Wilson Fonseca',
        airportNameEn: 'Santarém-Maestro Wilson Fonseca Airport',
        cityNameVi: 'Santarem',
        cityNameEn: 'Santarem',
        countryNameVi: 'Bra xin',
        countryNameEn: 'Brazil'
    },
    RIO: {
        id: 1222,
        airportCode: 'RIO',
        cityCode: 'RIO',
        countryCode: 'BR',
        continentCode: 'SA',
        airportNameVi: 'Tất cả các sân bay',
        airportNameEn: 'All Airports',
        cityNameVi: 'Rio de Janeiro',
        cityNameEn: 'Rio de Janeiro',
        countryNameVi: 'Bra xin',
        countryNameEn: 'Brazil'
    },
    SDU: {
        id: 1221,
        airportCode: 'SDU',
        cityCode: 'RIO',
        countryCode: 'BR',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Santos Dumont',
        airportNameEn: 'Santos Dumont Airport',
        cityNameVi: 'Rio De Janeiro',
        cityNameEn: 'Rio De Janeiro',
        countryNameVi: 'Bra xin',
        countryNameEn: 'Brazil'
    },
    GIG: {
        id: 1220,
        airportCode: 'GIG',
        cityCode: 'RIO',
        countryCode: 'BR',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Rio de Janeiro-Galeão',
        airportNameEn: 'Rio de Janeiro-Galeão International Airport',
        cityNameVi: 'Rio De Janeiro',
        cityNameEn: 'Rio De Janeiro',
        countryNameVi: 'Bra xin',
        countryNameEn: 'Brazil'
    },
    RBR: {
        id: 1219,
        airportCode: 'RBR',
        cityCode: 'RBR',
        countryCode: 'BR',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Rio Branco',
        airportNameEn: 'Rio Branco International Airport',
        cityNameVi: 'Rio Branco',
        cityNameEn: 'Rio Branco',
        countryNameVi: 'Bra xin',
        countryNameEn: 'Brazil'
    },
    RAO: {
        id: 1218,
        airportCode: 'RAO',
        cityCode: 'RAO',
        countryCode: 'BR',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Leite Lopes',
        airportNameEn: 'Leite Lopes Airport',
        cityNameVi: 'Ribeirao Preto',
        cityNameEn: 'Ribeirao Preto',
        countryNameVi: 'Bra xin',
        countryNameEn: 'Brazil'
    },
    REC: {
        id: 1217,
        airportCode: 'REC',
        cityCode: 'REC',
        countryCode: 'BR',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Guararapes',
        airportNameEn: 'Guararapes International Airport',
        cityNameVi: 'Recife',
        cityNameEn: 'Recife',
        countryNameVi: 'Bra xin',
        countryNameEn: 'Brazil'
    },
    BPS: {
        id: 1216,
        airportCode: 'BPS',
        cityCode: 'BPS',
        countryCode: 'BR',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Porto Seguro',
        airportNameEn: 'Porto Seguro Airport',
        cityNameVi: 'Porto Seguro',
        cityNameEn: 'Porto Seguro',
        countryNameVi: 'Bra xin',
        countryNameEn: 'Brazil'
    },
    POA: {
        id: 1215,
        airportCode: 'POA',
        cityCode: 'POA',
        countryCode: 'BR',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Salgado Filho',
        airportNameEn: 'Salgado Filho International Airport',
        cityNameVi: 'Porto Alegre',
        cityNameEn: 'Porto Alegre',
        countryNameVi: 'Bra xin',
        countryNameEn: 'Brazil'
    },
    PNZ: {
        id: 1214,
        airportCode: 'PNZ',
        cityCode: 'PNZ',
        countryCode: 'BR',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Senador Nilo Coelho',
        airportNameEn: 'Senador Nilo Coelho Airport',
        cityNameVi: 'Petrolina',
        cityNameEn: 'Petrolina',
        countryNameVi: 'Bra xin',
        countryNameEn: 'Brazil'
    },
    NVT: {
        id: 1213,
        airportCode: 'NVT',
        cityCode: 'NVT',
        countryCode: 'BR',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Ministro Victor Konder',
        airportNameEn: 'Ministro Victor Konder International Airport',
        cityNameVi: 'Navegantes',
        cityNameEn: 'Navegantes',
        countryNameVi: 'Bra xin',
        countryNameEn: 'Brazil'
    },
    NAT: {
        id: 1212,
        airportCode: 'NAT',
        cityCode: 'NAT',
        countryCode: 'BR',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Augusto Severo',
        airportNameEn: 'Augusto Severo International Airport',
        cityNameVi: 'Natal',
        cityNameEn: 'Natal',
        countryNameVi: 'Bra xin',
        countryNameEn: 'Brazil'
    },
    MCZ: {
        id: 1211,
        airportCode: 'MCZ',
        cityCode: 'MCZ',
        countryCode: 'BR',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Zumbi dos Palmares',
        airportNameEn: 'Zumbi dos Palmares Airport',
        cityNameVi: 'Maceio',
        cityNameEn: 'Maceio',
        countryNameVi: 'Bra xin',
        countryNameEn: 'Brazil'
    },
    MCP: {
        id: 1210,
        airportCode: 'MCP',
        cityCode: 'MCP',
        countryCode: 'BR',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Macapá',
        airportNameEn: 'Macapá International Airport',
        cityNameVi: 'Macapa',
        cityNameEn: 'Macapa',
        countryNameVi: 'Bra xin',
        countryNameEn: 'Brazil'
    },
    JOI: {
        id: 1209,
        airportCode: 'JOI',
        cityCode: 'JOI',
        countryCode: 'BR',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Joinville',
        airportNameEn: 'Joinville Airport',
        cityNameVi: 'Joinville',
        cityNameEn: 'Joinville',
        countryNameVi: 'Bra xin',
        countryNameEn: 'Brazil'
    },
    JPA: {
        id: 1208,
        airportCode: 'JPA',
        cityCode: 'JPA',
        countryCode: 'BR',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Presidente Castro Pinto',
        airportNameEn: 'Presidente Castro Pinto International Airport',
        cityNameVi: 'Joao Pessoa',
        cityNameEn: 'Joao Pessoa',
        countryNameVi: 'Bra xin',
        countryNameEn: 'Brazil'
    },
    IOS: {
        id: 1207,
        airportCode: 'IOS',
        cityCode: 'IOS',
        countryCode: 'BR',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Ilhéus Jorge Amado',
        airportNameEn: 'Ilhéus Jorge Amado Airport',
        cityNameVi: 'Ilheus',
        cityNameEn: 'Ilheus',
        countryNameVi: 'Bra xin',
        countryNameEn: 'Brazil'
    },
    IGU: {
        id: 1206,
        airportCode: 'IGU',
        cityCode: 'IGU',
        countryCode: 'BR',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Foz do Iguaçu',
        airportNameEn: 'Foz do Iguaçu International Airport',
        cityNameVi: 'Iguassu Falls',
        cityNameEn: 'Iguazu Falls',
        countryNameVi: 'Bra xin',
        countryNameEn: 'Brazil'
    },
    GYN: {
        id: 1205,
        airportCode: 'GYN',
        cityCode: 'GYN',
        countryCode: 'BR',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Santa Genoveva',
        airportNameEn: 'Santa Genoveva Airport',
        cityNameVi: 'Goiania',
        cityNameEn: 'Goiania',
        countryNameVi: 'Bra xin',
        countryNameEn: 'Brazil'
    },
    FOR: {
        id: 1204,
        airportCode: 'FOR',
        cityCode: 'FOR',
        countryCode: 'BR',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Pinto Martins',
        airportNameEn: 'Pinto Martins International Airport',
        cityNameVi: 'Fortaleza',
        cityNameEn: 'Fortaleza',
        countryNameVi: 'Bra xin',
        countryNameEn: 'Brazil'
    },
    CWB: {
        id: 1203,
        airportCode: 'CWB',
        cityCode: 'CWB',
        countryCode: 'BR',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Afonso Pena',
        airportNameEn: 'Afonso Pena International Airport',
        cityNameVi: 'Curitiba',
        cityNameEn: 'Curitiba',
        countryNameVi: 'Bra xin',
        countryNameEn: 'Brazil'
    },
    CGB: {
        id: 1202,
        airportCode: 'CGB',
        cityCode: 'CGB',
        countryCode: 'BR',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Marechal Rondon',
        airportNameEn: 'Marechal Rondon Airport',
        cityNameVi: 'Cuiaba',
        cityNameEn: 'Cuiaba',
        countryNameVi: 'Bra xin',
        countryNameEn: 'Brazil'
    },
    CZS: {
        id: 1201,
        airportCode: 'CZS',
        cityCode: 'CZS',
        countryCode: 'BR',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Cruzeiro do Sul',
        airportNameEn: 'Cruzeiro do Sul International Airport',
        cityNameVi: 'Cruzeiro Do Sul',
        cityNameEn: 'Cruzeiro Do Sul',
        countryNameVi: 'Bra xin',
        countryNameEn: 'Brazil'
    },
    CGR: {
        id: 1200,
        airportCode: 'CGR',
        cityCode: 'CGR',
        countryCode: 'BR',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Campo Grande',
        airportNameEn: 'Campo Grande International Airport',
        cityNameVi: 'Campogrande',
        cityNameEn: 'Campogrande',
        countryNameVi: 'Bra xin',
        countryNameEn: 'Brazil'
    },
    CPQ: {
        id: 1199,
        airportCode: 'CPQ',
        cityCode: 'CPQ',
        countryCode: 'BR',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Campo dos Amarais',
        airportNameEn: 'Campo dos Amarais Airport',
        cityNameVi: 'Campinas',
        cityNameEn: 'Campinas',
        countryNameVi: 'Bra xin',
        countryNameEn: 'Brazil'
    },
    BSB: {
        id: 1198,
        airportCode: 'BSB',
        cityCode: 'BSB',
        countryCode: 'BR',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Brasília',
        airportNameEn: 'Brasília International Airport',
        cityNameVi: 'Brasilia',
        cityNameEn: 'Brasilia',
        countryNameVi: 'Bra xin',
        countryNameEn: 'Brazil'
    },
    BVB: {
        id: 1197,
        airportCode: 'BVB',
        cityCode: 'BVB',
        countryCode: 'BR',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Boa Vista',
        airportNameEn: 'Boa Vista International Airport',
        cityNameVi: 'Boa Vista',
        cityNameEn: 'Boa Vista',
        countryNameVi: 'Bra xin',
        countryNameEn: 'Brazil'
    },
    BHZ: {
        id: 1196,
        airportCode: 'BHZ',
        cityCode: 'BHZ',
        countryCode: 'BR',
        continentCode: 'SA',
        airportNameVi: 'Tất cả các sân bay',
        airportNameEn: 'All Airports',
        cityNameVi: 'Belo Horizonte',
        cityNameEn: 'Belo Horizonte',
        countryNameVi: 'Bra xin',
        countryNameEn: 'Brazil'
    },
    AJU: {
        id: 1195,
        airportCode: 'AJU',
        cityCode: 'AJU',
        countryCode: 'BR',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Santa Maria',
        airportNameEn: 'Santa Maria Airport',
        cityNameVi: 'Aracaju',
        cityNameEn: 'Aracaju',
        countryNameVi: 'Bra xin',
        countryNameEn: 'Brazil'
    },
    FRW: {
        id: 1194,
        airportCode: 'FRW',
        cityCode: 'FRW',
        countryCode: 'BW',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Francistown',
        airportNameEn: 'Francistown Airport',
        cityNameVi: 'Francistown',
        cityNameEn: 'Francistown',
        countryNameVi: 'Botswana',
        countryNameEn: 'Botswana'
    },
    MUB: {
        id: 1193,
        airportCode: 'MUB',
        cityCode: 'MUB',
        countryCode: 'BW',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Maun',
        airportNameEn: 'Maun Airport',
        cityNameVi: 'Maun',
        cityNameEn: 'Maun',
        countryNameVi: 'Botswana',
        countryNameEn: 'Botswana'
    },
    BBK: {
        id: 1192,
        airportCode: 'BBK',
        cityCode: 'BBK',
        countryCode: 'BW',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Kasane',
        airportNameEn: 'Kasane Airport',
        cityNameVi: 'Kasane',
        cityNameEn: 'Kasane',
        countryNameVi: 'Botswana',
        countryNameEn: 'Botswana'
    },
    GBE: {
        id: 1191,
        airportCode: 'GBE',
        cityCode: 'GBE',
        countryCode: 'BW',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Sir Seretse Khama',
        airportNameEn: 'Sir Seretse Khama International Airport',
        cityNameVi: 'Gaborone',
        cityNameEn: 'Gaborone',
        countryNameVi: 'Botswana',
        countryNameEn: 'Botswana'
    },
    SJJ: {
        id: 1190,
        airportCode: 'SJJ',
        cityCode: 'SJJ',
        countryCode: 'BA',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Sarajevo',
        airportNameEn: 'Sarajevo International Airport',
        cityNameVi: 'Sarajevo',
        cityNameEn: 'Sarajevo',
        countryNameVi: 'Bosnia Herzegovina',
        countryNameEn: 'Bosnia Herzegovina'
    },
    BNX: {
        id: 1189,
        airportCode: 'BNX',
        cityCode: 'BNX',
        countryCode: 'BA',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Banja Luka',
        airportNameEn: 'Banja Luka International Airport',
        cityNameVi: 'Banja Luka',
        cityNameEn: 'Banja Luka',
        countryNameVi: 'Bosnia Herzegovina',
        countryNameEn: 'Bosnia Herzegovina'
    },
    TJA: {
        id: 1188,
        airportCode: 'TJA',
        cityCode: 'TJA',
        countryCode: 'BO',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Capitán Oriel Lea Plaza',
        airportNameEn: 'Capitán Oriel Lea Plaza Airport',
        cityNameVi: 'Tarija',
        cityNameEn: 'Tarija',
        countryNameVi: 'Bolivia',
        countryNameEn: 'Bolivia'
    },
    SRE: {
        id: 1187,
        airportCode: 'SRE',
        cityCode: 'SRE',
        countryCode: 'BO',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Juana Azurduy de Padilla',
        airportNameEn: 'Juana Azurduy de Padilla International Airport',
        cityNameVi: 'Sucre',
        cityNameEn: 'Sucre',
        countryNameVi: 'Bolivia',
        countryNameEn: 'Bolivia'
    },
    VVI: {
        id: 1186,
        airportCode: 'VVI',
        cityCode: 'SRZ',
        countryCode: 'BO',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Viru Viru',
        airportNameEn: 'Viru Viru International Airport',
        cityNameVi: 'Santa Cruz',
        cityNameEn: 'Santa Cruz',
        countryNameVi: 'Bolivia',
        countryNameEn: 'Bolivia'
    },
    LPB: {
        id: 1185,
        airportCode: 'LPB',
        cityCode: 'LPB',
        countryCode: 'BO',
        continentCode: 'SA',
        airportNameVi: 'Sân bay El Alto',
        airportNameEn: 'El Alto International Airport',
        cityNameVi: 'La Paz',
        cityNameEn: 'La Paz',
        countryNameVi: 'Bolivia',
        countryNameEn: 'Bolivia'
    },
    CBB: {
        id: 1184,
        airportCode: 'CBB',
        cityCode: 'CBB',
        countryCode: 'BO',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Jorge Wilstermann',
        airportNameEn: 'Jorge Wilstermann International Airport',
        cityNameVi: 'Cochabamba',
        cityNameEn: 'Cochabamba',
        countryNameVi: 'Bolivia',
        countryNameEn: 'Bolivia'
    },
    BDA: {
        id: 1183,
        airportCode: 'BDA',
        cityCode: 'BDA',
        countryCode: 'BM',
        continentCode: 'NA',
        airportNameVi: 'Sân bay Bermuda',
        airportNameEn: 'Bermuda International Airport',
        cityNameVi: 'Bermuda',
        cityNameEn: 'Bermuda',
        countryNameVi: 'Bermuda',
        countryNameEn: 'Bermuda'
    },
    COO: {
        id: 1182,
        airportCode: 'COO',
        cityCode: 'COO',
        countryCode: 'BJ',
        continentCode: 'AF',
        airportNameVi: 'Sân bay Cadjehoun',
        airportNameEn: 'Cadjehoun Airport',
        cityNameVi: 'Cotonou',
        cityNameEn: 'Cotonou',
        countryNameVi: 'Benin',
        countryNameEn: 'Benin'
    },
    SPR: {
        id: 1181,
        airportCode: 'SPR',
        cityCode: 'SPR',
        countryCode: 'BZ',
        continentCode: 'SA',
        airportNameVi: 'Sân bay San Pedro',
        airportNameEn: 'San Pedro Airport',
        cityNameVi: 'San Pedro',
        cityNameEn: 'San Pedro',
        countryNameVi: 'Belize',
        countryNameEn: 'Belize'
    },
    PLJ: {
        id: 1180,
        airportCode: 'PLJ',
        cityCode: 'PLJ',
        countryCode: 'BZ',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Placencia',
        airportNameEn: 'Placencia Airport',
        cityNameVi: 'Placencia',
        cityNameEn: 'Placencia',
        countryNameVi: 'Belize',
        countryNameEn: 'Belize'
    },
    TZA: {
        id: 1179,
        airportCode: 'TZA',
        cityCode: 'BZE',
        countryCode: 'BZ',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Belize City Municipal',
        airportNameEn: 'Belize City Municipal Airport',
        cityNameVi: 'Belize City',
        cityNameEn: 'Belize City',
        countryNameVi: 'Belize',
        countryNameEn: 'Belize'
    },
    OST: {
        id: 1178,
        airportCode: 'OST',
        cityCode: 'OST',
        countryCode: 'BE',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Ostend-Bruges',
        airportNameEn: 'Ostend-Bruges International Airport',
        cityNameVi: 'Ostende',
        cityNameEn: 'Ostende',
        countryNameVi: 'Bỉ',
        countryNameEn: 'Belgium'
    },
    ZYZ: {
        id: 1177,
        airportCode: 'ZYZ',
        cityCode: 'ZYZ',
        countryCode: 'BE',
        continentCode: 'EU',
        airportNameVi: 'Antwerp',
        airportNameEn: 'Antwerp',
        cityNameVi: 'Bru-xen',
        cityNameEn: 'Brussels',
        countryNameVi: 'Bỉ',
        countryNameEn: 'Belgium'
    },
    CRL: {
        id: 1176,
        airportCode: 'CRL',
        cityCode: 'BRU',
        countryCode: 'BE',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Brussels South Charleroi',
        airportNameEn: 'Brussels South Charleroi Airport',
        cityNameVi: 'Bru-xen',
        cityNameEn: 'Brussels',
        countryNameVi: 'Bỉ',
        countryNameEn: 'Belgium'
    },
    ANR: {
        id: 1175,
        airportCode: 'ANR',
        cityCode: 'ANR',
        countryCode: 'BE',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Antwerp',
        airportNameEn: 'Antwerp International Airport',
        cityNameVi: 'Antwerp',
        cityNameEn: 'Antwerp',
        countryNameVi: 'Bỉ',
        countryNameEn: 'Belgium'
    },
    MHP: {
        id: 1174,
        airportCode: 'MHP',
        cityCode: 'MHP',
        countryCode: 'BY',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Minsk-1',
        airportNameEn: 'Minsk-1 Airport',
        cityNameVi: 'Minsk',
        cityNameEn: 'Minsk',
        countryNameVi: 'Bê la rút',
        countryNameEn: 'Belarus'
    },
    MSQ: {
        id: 1173,
        airportCode: 'MSQ',
        cityCode: 'MSQ',
        countryCode: 'BY',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Minsk',
        airportNameEn: 'Minsk International Airport',
        cityNameVi: 'Minsk',
        cityNameEn: 'Minsk',
        countryNameVi: 'Bê la rút',
        countryNameEn: 'Belarus'
    },
    GME: {
        id: 1172,
        airportCode: 'GME',
        cityCode: 'GME',
        countryCode: 'BY',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Gomel',
        airportNameEn: 'Gomel Airport',
        cityNameVi: 'Gomel',
        cityNameEn: 'Gomel',
        countryNameVi: 'Bê la rút',
        countryNameEn: 'Belarus'
    },
    BGI: {
        id: 1171,
        airportCode: 'BGI',
        cityCode: 'BGI',
        countryCode: 'BB',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Grantley Adams',
        airportNameEn: 'Grantley Adams International Airport',
        cityNameVi: 'Bridgetown',
        cityNameEn: 'Bridgetown',
        countryNameVi: 'Barbados',
        countryNameEn: 'Barbados'
    },
    CGP: {
        id: 1170,
        airportCode: 'CGP',
        cityCode: 'CGP',
        countryCode: 'BD',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Shah Amanat',
        airportNameEn: 'Shah Amanat International Airport',
        cityNameVi: 'Chittagong',
        cityNameEn: 'Chittagong',
        countryNameVi: 'Băng la đét',
        countryNameEn: 'Bangladesh'
    },
    ZYL: {
        id: 1169,
        airportCode: 'ZYL',
        cityCode: 'ZYL',
        countryCode: 'BD',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Osmani',
        airportNameEn: 'Osmani International Airport',
        cityNameVi: 'Sylhet',
        cityNameEn: 'Sylhet',
        countryNameVi: 'Băng la đét',
        countryNameEn: 'Bangladesh'
    },
    DAC: {
        id: 1168,
        airportCode: 'DAC',
        cityCode: 'DAC',
        countryCode: 'BD',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Shahjalal',
        airportNameEn: 'Shahjalal International Airport',
        cityNameVi: 'Dhaka',
        cityNameEn: 'Dhaka',
        countryNameVi: 'Băng la đét',
        countryNameEn: 'Bangladesh'
    },
    BAH: {
        id: 1167,
        airportCode: 'BAH',
        cityCode: 'BAH',
        countryCode: 'BH',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Bahrain',
        airportNameEn: 'Bahrain International Airport',
        cityNameVi: 'Bahrain',
        cityNameEn: 'Bahrain',
        countryNameVi: 'Bahrain',
        countryNameEn: 'Bahrain'
    },
    MHH: {
        id: 1166,
        airportCode: 'MHH',
        cityCode: 'MHH',
        countryCode: 'BS',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Marsh Harbour',
        airportNameEn: 'Marsh Harbour Airport',
        cityNameVi: 'Marsh Harbour',
        cityNameEn: 'Marsh Harbour',
        countryNameVi: 'Bahamas',
        countryNameEn: 'Bahamas'
    },
    GHB: {
        id: 1165,
        airportCode: 'GHB',
        cityCode: 'GHB',
        countryCode: 'BS',
        continentCode: 'NA',
        airportNameVi: "Sân bay Governor's Harbour",
        airportNameEn: "Governor's Harbour Airport",
        cityNameVi: 'Governor S Harbour',
        cityNameEn: 'Governor S Harbour',
        countryNameVi: 'Bahamas',
        countryNameEn: 'Bahamas'
    },
    LGI: {
        id: 1164,
        airportCode: 'LGI',
        cityCode: 'LGI',
        countryCode: 'BS',
        continentCode: 'SA',
        airportNameVi: "Sân bay Deadman's Cay",
        airportNameEn: "Deadman's Cay Airport",
        cityNameVi: 'Long Island',
        cityNameEn: 'Long Island',
        countryNameVi: 'Bahamas',
        countryNameEn: 'Bahamas'
    },
    TCB: {
        id: 1163,
        airportCode: 'TCB',
        cityCode: 'TCB',
        countryCode: 'BS',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Treasure Cay',
        airportNameEn: 'Treasure Cay Airport',
        cityNameVi: 'Treasure Cay',
        cityNameEn: 'Treasure Cay',
        countryNameVi: 'Bahamas',
        countryNameEn: 'Bahamas'
    },
    ZSA: {
        id: 1162,
        airportCode: 'ZSA',
        cityCode: 'ZSA',
        countryCode: 'BS',
        continentCode: 'SA',
        airportNameVi: 'Sân bay San Salvador',
        airportNameEn: 'San Salvador Airport',
        cityNameVi: 'San Salvador',
        cityNameEn: 'San Salvador',
        countryNameVi: 'Bahamas',
        countryNameEn: 'Bahamas'
    },
    ELH: {
        id: 1161,
        airportCode: 'ELH',
        cityCode: 'ELH',
        countryCode: 'BS',
        continentCode: 'SA',
        airportNameVi: 'Sân bay North Eleuthera',
        airportNameEn: 'North Eleuthera Airport',
        cityNameVi: 'North Eleuthera',
        cityNameEn: 'North Eleuthera',
        countryNameVi: 'Bahamas',
        countryNameEn: 'Bahamas'
    },
    PID: {
        id: 1160,
        airportCode: 'PID',
        cityCode: 'NAS',
        countryCode: 'BS',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Paradise Island',
        airportNameEn: 'Paradise Island Airport',
        cityNameVi: 'Nassau',
        cityNameEn: 'Nassau',
        countryNameVi: 'Bahamas',
        countryNameEn: 'Bahamas'
    },
    GGT: {
        id: 1159,
        airportCode: 'GGT',
        cityCode: 'GGT',
        countryCode: 'BS',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Exuma',
        airportNameEn: 'Exuma International Airport',
        cityNameVi: 'George Town',
        cityNameEn: 'George Town',
        countryNameVi: 'Bahamas',
        countryNameEn: 'Bahamas'
    },
    FPO: {
        id: 1158,
        airportCode: 'FPO',
        cityCode: 'FPO',
        countryCode: 'BS',
        continentCode: 'SA',
        airportNameVi: 'Sân bay Grand Bahama',
        airportNameEn: 'Grand Bahama International Airport',
        cityNameVi: 'Freeport',
        cityNameEn: 'Freeport',
        countryNameVi: 'Bahamas',
        countryNameEn: 'Bahamas'
    },
    GYD: {
        id: 1157,
        airportCode: 'GYD',
        cityCode: 'GYD',
        countryCode: 'AZ',
        continentCode: 'AS',
        airportNameVi: 'Sân bay Heydar Aliyev',
        airportNameEn: 'Heydar Aliyev International Airport',
        cityNameVi: 'Baku',
        cityNameEn: 'Baku',
        countryNameVi: 'Azerbaijan',
        countryNameEn: 'Azerbaijan'
    },
    VIE: {
        id: 1156,
        airportCode: 'VIE',
        cityCode: 'VIE',
        countryCode: 'AT',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Vienna',
        airportNameEn: 'Vienna International Airport',
        cityNameVi: 'Viên',
        cityNameEn: 'Vienna',
        countryNameVi: 'Áo',
        countryNameEn: 'Austria'
    },
    SZG: {
        id: 1155,
        airportCode: 'SZG',
        cityCode: 'SZG',
        countryCode: 'AT',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Salzburg',
        airportNameEn: 'Salzburg Airport',
        cityNameVi: 'Salzburg',
        cityNameEn: 'Salzburg',
        countryNameVi: 'Áo',
        countryNameEn: 'Austria'
    },
    LNZ: {
        id: 1154,
        airportCode: 'LNZ',
        cityCode: 'LNZ',
        countryCode: 'AT',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Linz',
        airportNameEn: 'Linz Airport',
        cityNameVi: 'Linz',
        cityNameEn: 'Linz',
        countryNameVi: 'Áo',
        countryNameEn: 'Austria'
    },
    KLU: {
        id: 1153,
        airportCode: 'KLU',
        cityCode: 'KLU',
        countryCode: 'AT',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Klagenfurt',
        airportNameEn: 'Klagenfurt Airport',
        cityNameVi: 'Klagenfurt',
        cityNameEn: 'Klagenfurt',
        countryNameVi: 'Áo',
        countryNameEn: 'Austria'
    },
    INN: {
        id: 1152,
        airportCode: 'INN',
        cityCode: 'INN',
        countryCode: 'AT',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Innsbruck',
        airportNameEn: 'Innsbruck Airport',
        cityNameVi: 'Innsbruck',
        cityNameEn: 'Innsbruck',
        countryNameVi: 'Áo',
        countryNameEn: 'Austria'
    },
    GRZ: {
        id: 1151,
        airportCode: 'GRZ',
        cityCode: 'GRZ',
        countryCode: 'AT',
        continentCode: 'EU',
        airportNameVi: 'Sân bay Graz',
        airportNameEn: 'Graz Airport',
        cityNameVi: 'Graz',
        cityNameEn: 'Graz',
        countryNameVi: 'Áo',
        countryNameEn: 'Austria'
    },
    WEI: {
        id: 1150,
        airportCode: 'WEI',
        cityCode: 'WEI',
        countryCode: 'AU',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Weipa',
        airportNameEn: 'Weipa Airport',
        cityNameVi: 'Weipa',
        cityNameEn: 'Weipa',
        countryNameVi: 'Úc',
        countryNameEn: 'Australia'
    },
    XTG: {
        id: 1149,
        airportCode: 'XTG',
        cityCode: 'XTG',
        countryCode: 'AU',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Thargomindah',
        airportNameEn: 'Thargomindah Airport',
        cityNameVi: 'Thargomindah',
        cityNameEn: 'Thargomindah',
        countryNameVi: 'Úc',
        countryNameEn: 'Australia'
    },
    TMW: {
        id: 1148,
        airportCode: 'TMW',
        cityCode: 'TMW',
        countryCode: 'AU',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Tamworth',
        airportNameEn: 'Tamworth Airport',
        cityNameVi: 'Tamworth',
        cityNameEn: 'Tamworth',
        countryNameVi: 'Úc',
        countryNameEn: 'Australia'
    },
    MCY: {
        id: 1147,
        airportCode: 'MCY',
        cityCode: 'MCY',
        countryCode: 'AU',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Sunshine Coast',
        airportNameEn: 'Sunshine Coast Airport',
        cityNameVi: 'Maroochydore',
        cityNameEn: 'Maroochydore',
        countryNameVi: 'Úc',
        countryNameEn: 'Australia'
    },
    PTJ: {
        id: 1146,
        airportCode: 'PTJ',
        cityCode: 'PTJ',
        countryCode: 'AU',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Portland',
        airportNameEn: 'Portland Airport',
        cityNameVi: 'Portland',
        cityNameEn: 'Portland',
        countryNameVi: 'Úc',
        countryNameEn: 'Australia'
    },
    PQQ: {
        id: 1145,
        airportCode: 'PQQ',
        cityCode: 'PQQ',
        countryCode: 'AU',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Port Macquarie',
        airportNameEn: 'Port Macquarie Airport',
        cityNameVi: 'Pt Macquarie',
        cityNameEn: 'Pt Macquarie',
        countryNameVi: 'Úc',
        countryNameEn: 'Australia'
    },
    PHE: {
        id: 1144,
        airportCode: 'PHE',
        cityCode: 'PHE',
        countryCode: 'AU',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Port Hedland',
        airportNameEn: 'Port Hedland International Airport',
        cityNameVi: 'Port Hedland',
        cityNameEn: 'Port Hedland',
        countryNameVi: 'Úc',
        countryNameEn: 'Australia'
    },
    PBO: {
        id: 1143,
        airportCode: 'PBO',
        cityCode: 'PBO',
        countryCode: 'AU',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Paraburdoo',
        airportNameEn: 'Paraburdoo Airport',
        cityNameVi: 'Paraburdoo',
        cityNameEn: 'Paraburdoo',
        countryNameVi: 'Úc',
        countryNameEn: 'Australia'
    },
    NLK: {
        id: 1142,
        airportCode: 'NLK',
        cityCode: 'NLK',
        countryCode: 'AU',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Norfolk Island',
        airportNameEn: 'Norfolk Island Airport',
        cityNameVi: 'Norfolk Island',
        cityNameEn: 'Norfolk Island',
        countryNameVi: 'Úc',
        countryNameEn: 'Australia'
    },
    GOV: {
        id: 1141,
        airportCode: 'GOV',
        cityCode: 'GOV',
        countryCode: 'AU',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Nhulunbuy',
        airportNameEn: 'Nhulunbuy Airport',
        cityNameVi: 'Gove',
        cityNameEn: 'Gove',
        countryNameVi: 'Úc',
        countryNameEn: 'Australia'
    },
    ZNE: {
        id: 1140,
        airportCode: 'ZNE',
        cityCode: 'ZNE',
        countryCode: 'AU',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Newman',
        airportNameEn: 'Newman Airport',
        cityNameVi: 'Newman',
        cityNameEn: 'Newman',
        countryNameVi: 'Úc',
        countryNameEn: 'Australia'
    },
    BEO: {
        id: 1139,
        airportCode: 'BEO',
        cityCode: 'NTL',
        countryCode: 'AU',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Newcastle - Belmont',
        airportNameEn: 'Newcastle - Belmont Airport',
        cityNameVi: 'Newcastle',
        cityNameEn: 'Newcastle',
        countryNameVi: 'Úc',
        countryNameEn: 'Australia'
    },
    ISA: {
        id: 1138,
        airportCode: 'ISA',
        cityCode: 'ISA',
        countryCode: 'AU',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Mount Isa',
        airportNameEn: 'Mount Isa Airport',
        cityNameVi: 'Mount Isa',
        cityNameEn: 'Mount Isa',
        countryNameVi: 'Úc',
        countryNameEn: 'Australia'
    },
    MGB: {
        id: 1137,
        airportCode: 'MGB',
        cityCode: 'MGB',
        countryCode: 'AU',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Mount Gambier',
        airportNameEn: 'Mount Gambier Airport',
        cityNameVi: 'Mt Gambier',
        cityNameEn: 'Mt Gambier',
        countryNameVi: 'Úc',
        countryNameEn: 'Australia'
    },
    MRZ: {
        id: 1136,
        airportCode: 'MRZ',
        cityCode: 'MRZ',
        countryCode: 'AU',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Moree',
        airportNameEn: 'Moree Airport',
        cityNameVi: 'Moree',
        cityNameEn: 'Moree',
        countryNameVi: 'Úc',
        countryNameEn: 'Australia'
    },
    MQL: {
        id: 1135,
        airportCode: 'MQL',
        cityCode: 'MQL',
        countryCode: 'AU',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Mildura',
        airportNameEn: 'Mildura Airport',
        cityNameVi: 'Mildura',
        cityNameEn: 'Mildura',
        countryNameVi: 'Úc',
        countryNameEn: 'Australia'
    },
    MCV: {
        id: 1134,
        airportCode: 'MCV',
        cityCode: 'MCV',
        countryCode: 'AU',
        continentCode: 'OC',
        airportNameVi: 'Sân bay McArthur River',
        airportNameEn: 'McArthur River Airport',
        cityNameVi: 'Mcarthur River',
        cityNameEn: 'Mcarthur River',
        countryNameVi: 'Úc',
        countryNameEn: 'Australia'
    },
    LRE: {
        id: 1133,
        airportCode: 'LRE',
        cityCode: 'LRE',
        countryCode: 'AU',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Longreach',
        airportNameEn: 'Longreach Airport',
        cityNameVi: 'Longreach',
        cityNameEn: 'Longreach',
        countryNameVi: 'Úc',
        countryNameEn: 'Australia'
    },
    IRG: {
        id: 1132,
        airportCode: 'IRG',
        cityCode: 'IRG',
        countryCode: 'AU',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Lockhart River',
        airportNameEn: 'Lockhart River Airport',
        cityNameVi: 'Lockhart River',
        cityNameEn: 'Lockhart River',
        countryNameVi: 'Úc',
        countryNameEn: 'Australia'
    },
    LEA: {
        id: 1131,
        airportCode: 'LEA',
        cityCode: 'LEA',
        countryCode: 'AU',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Learmonth',
        airportNameEn: 'Learmonth Airport ',
        cityNameVi: 'Learmonth',
        cityNameEn: 'Learmonth',
        countryNameVi: 'Úc',
        countryNameEn: 'Australia'
    },
    KNS: {
        id: 1130,
        airportCode: 'KNS',
        cityCode: 'KNS',
        countryCode: 'AU',
        continentCode: 'OC',
        airportNameVi: 'Sân bay King Island',
        airportNameEn: 'King Island Airport',
        cityNameVi: 'King Island',
        cityNameEn: 'King Island',
        countryNameVi: 'Úc',
        countryNameEn: 'Australia'
    },
    KTA: {
        id: 1129,
        airportCode: 'KTA',
        cityCode: 'KTA',
        countryCode: 'AU',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Karratha',
        airportNameEn: 'Karratha Airport',
        cityNameVi: 'Karratha',
        cityNameEn: 'Karratha',
        countryNameVi: 'Úc',
        countryNameEn: 'Australia'
    },
    KGI: {
        id: 1128,
        airportCode: 'KGI',
        cityCode: 'KGI',
        countryCode: 'AU',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Kalgoorlie-Boulder',
        airportNameEn: 'Kalgoorlie-Boulder Airport',
        cityNameVi: 'Kalgoorlie',
        cityNameEn: 'Kalgoorlie',
        countryNameVi: 'Úc',
        countryNameEn: 'Australia'
    },
    JCK: {
        id: 1127,
        airportCode: 'JCK',
        cityCode: 'JCK',
        countryCode: 'AU',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Julia Creek',
        airportNameEn: 'Julia Creek Airport',
        cityNameVi: 'Julia Creek',
        cityNameEn: 'Julia Creek',
        countryNameVi: 'Úc',
        countryNameEn: 'Australia'
    },
    HGD: {
        id: 1126,
        airportCode: 'HGD',
        cityCode: 'HGD',
        countryCode: 'AU',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Hughenden',
        airportNameEn: 'Hughenden Airport',
        cityNameVi: 'Hughenden',
        cityNameEn: 'Hughenden',
        countryNameVi: 'Úc',
        countryNameEn: 'Australia'
    },
    HID: {
        id: 1125,
        airportCode: 'HID',
        cityCode: 'HID',
        countryCode: 'AU',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Horn Island',
        airportNameEn: 'Horn Island Airport',
        cityNameVi: 'Horn Island',
        cityNameEn: 'Horn Island',
        countryNameVi: 'Úc',
        countryNameEn: 'Australia'
    },
    GTE: {
        id: 1124,
        airportCode: 'GTE',
        cityCode: 'GTE',
        countryCode: 'AU',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Groote Eylandt',
        airportNameEn: 'Groote Eylandt Airport',
        cityNameVi: 'Groote Eylandt',
        cityNameEn: 'Groote Eylandt',
        countryNameVi: 'Úc',
        countryNameEn: 'Australia'
    },
    HTI: {
        id: 1123,
        airportCode: 'HTI',
        cityCode: 'HTI',
        countryCode: 'AU',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Great Barrier Reef',
        airportNameEn: 'Great Barrier Reef Airport',
        cityNameVi: 'Hamilton Island',
        cityNameEn: 'Hamilton Island',
        countryNameVi: 'Úc',
        countryNameEn: 'Australia'
    },
    GFN: {
        id: 1122,
        airportCode: 'GFN',
        cityCode: 'GFN',
        countryCode: 'AU',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Grafton',
        airportNameEn: 'Grafton Airport',
        cityNameVi: 'Grafton',
        cityNameEn: 'Grafton',
        countryNameVi: 'Úc',
        countryNameEn: 'Australia'
    },
    GLT: {
        id: 1121,
        airportCode: 'GLT',
        cityCode: 'GLT',
        countryCode: 'AU',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Gladstone',
        airportNameEn: 'Gladstone Airport',
        cityNameVi: 'Gladstone',
        cityNameEn: 'Gladstone',
        countryNameVi: 'Úc',
        countryNameEn: 'Australia'
    },
    GET: {
        id: 1120,
        airportCode: 'GET',
        cityCode: 'GET',
        countryCode: 'AU',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Geraldton',
        airportNameEn: 'Geraldton Airport',
        cityNameVi: 'Geraldton',
        cityNameEn: 'Geraldton',
        countryNameVi: 'Úc',
        countryNameEn: 'Australia'
    },
    EPR: {
        id: 1119,
        airportCode: 'EPR',
        cityCode: 'EPR',
        countryCode: 'AU',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Esperance',
        airportNameEn: 'Esperance Airport',
        cityNameVi: 'Esperance',
        cityNameEn: 'Esperance',
        countryNameVi: 'Úc',
        countryNameEn: 'Australia'
    },
    EMD: {
        id: 1118,
        airportCode: 'EMD',
        cityCode: 'EMD',
        countryCode: 'AU',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Emerald',
        airportNameEn: 'Emerald Airport',
        cityNameVi: 'Emerald',
        cityNameEn: 'Emerald',
        countryNameVi: 'Úc',
        countryNameEn: 'Australia'
    },
    EDR: {
        id: 1117,
        airportCode: 'EDR',
        cityCode: 'EDR',
        countryCode: 'AU',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Edward River',
        airportNameEn: 'Edward River Airport',
        cityNameVi: 'Edward River',
        cityNameEn: 'Edward River',
        countryNameVi: 'Úc',
        countryNameEn: 'Australia'
    },
    DBO: {
        id: 1116,
        airportCode: 'DBO',
        cityCode: 'DBO',
        countryCode: 'AU',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Dubbo',
        airportNameEn: 'Dubbo Airport',
        cityNameVi: 'Dubbo',
        cityNameEn: 'Dubbo',
        countryNameVi: 'Úc',
        countryNameEn: 'Australia'
    },
    DMD: {
        id: 1115,
        airportCode: 'DMD',
        cityCode: 'DMD',
        countryCode: 'AU',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Doomadgee Mission',
        airportNameEn: 'Doomadgee Mission Airport',
        cityNameVi: 'Doomadgee',
        cityNameEn: 'Doomadgee',
        countryNameVi: 'Úc',
        countryNameEn: 'Australia'
    },
    CMA: {
        id: 1114,
        airportCode: 'CMA',
        cityCode: 'CMA',
        countryCode: 'AU',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Cunnamulla',
        airportNameEn: 'Cunnamulla Airport',
        cityNameVi: 'Cunnamulla',
        cityNameEn: 'Cunnamulla',
        countryNameVi: 'Úc',
        countryNameEn: 'Australia'
    },
    CUQ: {
        id: 1113,
        airportCode: 'CUQ',
        cityCode: 'CUQ',
        countryCode: 'AU',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Coen',
        airportNameEn: 'Coen Airport',
        cityNameVi: 'Coen',
        cityNameEn: 'Coen',
        countryNameVi: 'Úc',
        countryNameEn: 'Australia'
    },
    CCK: {
        id: 1112,
        airportCode: 'CCK',
        cityCode: 'CCK',
        countryCode: 'AU',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Cocos Islands',
        airportNameEn: 'Cocos Islands Airport',
        cityNameVi: 'Cocos Islands',
        cityNameEn: 'Cocos Islands',
        countryNameVi: 'Úc',
        countryNameEn: 'Australia'
    },
    CNJ: {
        id: 1111,
        airportCode: 'CNJ',
        cityCode: 'CNJ',
        countryCode: 'AU',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Cloncurry',
        airportNameEn: 'Cloncurry Airport',
        cityNameVi: 'Cloncurry',
        cityNameEn: 'Cloncurry',
        countryNameVi: 'Úc',
        countryNameEn: 'Australia'
    },
    XCH: {
        id: 1110,
        airportCode: 'XCH',
        cityCode: 'XCH',
        countryCode: 'AU',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Christmas Island',
        airportNameEn: 'Christmas Island Airport',
        cityNameVi: 'Christmas Island',
        cityNameEn: 'Christmas Island',
        countryNameVi: 'Úc',
        countryNameEn: 'Australia'
    },
    CTL: {
        id: 1109,
        airportCode: 'CTL',
        cityCode: 'CTL',
        countryCode: 'AU',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Charleville',
        airportNameEn: 'Charleville Airport',
        cityNameVi: 'Charleville',
        cityNameEn: 'Charleville',
        countryNameVi: 'Úc',
        countryNameEn: 'Australia'
    },
    CED: {
        id: 1108,
        airportCode: 'CED',
        cityCode: 'CED',
        countryCode: 'AU',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Ceduna',
        airportNameEn: 'Ceduna Airport',
        cityNameVi: 'Ceduna',
        cityNameEn: 'Ceduna',
        countryNameVi: 'Úc',
        countryNameEn: 'Australia'
    },
    CVQ: {
        id: 1107,
        airportCode: 'CVQ',
        cityCode: 'CVQ',
        countryCode: 'AU',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Carnarvon',
        airportNameEn: 'Carnarvon Airport',
        cityNameVi: 'Carnarvon',
        cityNameEn: 'Carnarvon',
        countryNameVi: 'Úc',
        countryNameEn: 'Australia'
    },
    BVI: {
        id: 1106,
        airportCode: 'BVI',
        cityCode: 'BVI',
        countryCode: 'AU',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Birdsville',
        airportNameEn: 'Birdsville Airport',
        cityNameVi: 'Birdsville, Queensland',
        cityNameEn: 'Birdsville, Queensland',
        countryNameVi: 'Úc',
        countryNameEn: 'Australia'
    },
    BCI: {
        id: 1105,
        airportCode: 'BCI',
        cityCode: 'BCI',
        countryCode: 'AU',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Barcaldine',
        airportNameEn: 'Barcaldine Airport',
        cityNameVi: 'Barcaldine',
        cityNameEn: 'Barcaldine',
        countryNameVi: 'Úc',
        countryNameEn: 'Australia'
    },
    ABM: {
        id: 1104,
        airportCode: 'ABM',
        cityCode: 'ABM',
        countryCode: 'AU',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Bamaga Injinoo',
        airportNameEn: 'Bamaga Injinoo Airport',
        cityNameVi: 'Bamaga',
        cityNameEn: 'Bamaga',
        countryNameVi: 'Úc',
        countryNameEn: 'Australia'
    },
    WGA: {
        id: 1103,
        airportCode: 'WGA',
        cityCode: 'WGA',
        countryCode: 'AU',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Wagga Wagga',
        airportNameEn: 'Wagga Wagga Airport',
        cityNameVi: 'Wagga Wagga',
        cityNameEn: 'Wagga Wagga',
        countryNameVi: 'Úc',
        countryNameEn: 'Australia'
    },
    TSV: {
        id: 1102,
        airportCode: 'TSV',
        cityCode: 'TSV',
        countryCode: 'AU',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Townsville',
        airportNameEn: 'Townsville Airport',
        cityNameVi: 'Townsville',
        cityNameEn: 'Townsville',
        countryNameVi: 'Úc',
        countryNameEn: 'Australia'
    },
    LBH: {
        id: 1101,
        airportCode: 'LBH',
        cityCode: 'SYD',
        countryCode: 'AU',
        continentCode: 'OC',
        airportNameVi: 'Palm Beach Seaplane Base',
        airportNameEn: 'Palm Beach Seaplane Base',
        cityNameVi: 'Sydney',
        cityNameEn: 'Sydney',
        countryNameVi: 'Úc',
        countryNameEn: 'Australia'
    },
    ROK: {
        id: 1100,
        airportCode: 'ROK',
        cityCode: 'ROK',
        countryCode: 'AU',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Rockhampton',
        airportNameEn: 'Rockhampton Airport',
        cityNameVi: 'Rockhampton',
        cityNameEn: 'Rockhampton',
        countryNameVi: 'Úc',
        countryNameEn: 'Australia'
    },
    PPP: {
        id: 1099,
        airportCode: 'PPP',
        cityCode: 'PPP',
        countryCode: 'AU',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Whitsunday Coast',
        airportNameEn: 'Whitsunday Coast Airport',
        cityNameVi: 'Proserpine',
        cityNameEn: 'Proserpine',
        countryNameVi: 'Úc',
        countryNameEn: 'Australia'
    },
    PER: {
        id: 1098,
        airportCode: 'PER',
        cityCode: 'PER',
        countryCode: 'AU',
        continentCode: 'OC',
        airportNameVi: 'Sân bay Perth',
        airportNameEn: 'Perth Airport',
        cityNameVi: 'Perth',
        cityNameEn: 'Perth',
        countryNameVi: 'Úc',
        countryNameEn: 'Australia'
    }
}
