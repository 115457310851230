import React, { useEffect, useState } from "react";
import { Button, Modal } from "antd";
import DayPicker from "react-day-picker";
import { useDayRender } from "react-day-picker";
import { isMobileScreen } from "../../../utils/hotel-func";
import moment from "moment";
import {
  setLoadingMessage,
  toggleShowLoading
} from "../../../redux/actions/home-actions";
import { getListDate } from "../../../api-services/search-flight";
import { updateRedis } from "../../../api-services/search-combo";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { setRefreshPage } from "../../../redux/actions/payment-method-action";
import { convertAirportCodeToCityName, formatDate2 } from '../../../utils/flight'
import DisplayPrice2 from "../../common/DisplayPrice2";

const ModalDateFlight = ({
  isModal,
  setIsModal,
  redisToken,
  dataBr,
  dealFlightConfig
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [minDate] = useState(
    moment()
      .add(0, "days")
      .toDate()
  );
  const [maxDate] = useState(
    moment()
      .add(6, "months")
      .toDate()
  );

  const [departureDate, setDepartureDate] = useState(null);
  const [months, setMonths] = useState(moment().month() + 1);
  const [textMonth, setTextMonth] = useState(moment().toDate());
  const [years, setYears] = useState([moment().year(), moment().year()]);
  const [dataListDate, setDataListDate] = useState([]);
  const [listDate, setListDate] = useState({});
  const [dataTimeDisable, setDataTimeDisable] = useState([]);
  const [dealFlight, setDealFlight] = useState([]);
  const [maxMonth, setMaxMonth] = useState(moment().month() + 1);
  const [monthsDisable, setMonthsDisable] = useState([
    moment().month() + 1,
    moment().month() + 2
  ]);
  const [minMaxDate, setMinMaxDate] = useState({
    before: minDate,
    after: maxDate
  });
  // const [dataRedis, setDataRedis] = useState(null)
  const modifiers = {
    1999: listDate.active,
    2499: listDate.active2499,
    2999: listDate.active2999
  };

  const Navbar = ({
    nextMonth,
    previousMonth,
    onPreviousClick,
    onNextClick,
    className
  }) => {
    const prev = previousMonth.getMonth();
    const next = nextMonth.getMonth();
    const styleLeft = {
      marginLeft: 15,
      fontSize: 25,
      marginTop: 20,
      position: "absolute"
    };
    const styleRight = {
      marginRight: 15,
      fontSize: 25,
      marginTop: 20,
      position: "absolute",
      right: 0
    };
    const onPreviousClickDate = () => {
      onPreviousClick();
      const year = [...years];
      let month1 = prev + 1;
      let month2 = prev + 2;
      if (prev === 11) {
        year[0] = year[0] - 1;
        month2 = 1;
        setYears(1);
      } else if (prev < 11) {
        year[1] = year[0];
      }
      setYears(year);
      setMonths(month1);
      isMobileScreen()
        ? setMonthsDisable([month1])
        : setMonthsDisable([month1, month2]);
    };

    const onNextClickDate = () => {
      const year = [...years];
      let month1 = next;
      let month2 = next + 1;
      if (next === 0) {
        year[1] = year[1] + 1;
        month1 = 12;
        setYears(1);
      } else if (next > 0) {
        year[0] = year[1];
      }
      setMonths(month1 + 1);
      setYears(year);
      onNextClick();
      isMobileScreen()
        ? setMonthsDisable([next + 1])
        : setMonthsDisable([month1, month2]);
    };

    return (
      <div className={className}>
        <button
          disabled={months === moment().month() + 1}
          style={styleLeft}
          onClick={() => onPreviousClickDate()}
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.3123 3.41061V2.2028C11.3123 2.09811 11.192 2.0403 11.1107 2.10436L4.06697 7.60592C4.00712 7.65246 3.95869 7.71206 3.92538 7.78016C3.89207 7.84827 3.87476 7.92308 3.87476 7.99889C3.87476 8.07471 3.89207 8.14952 3.92538 8.21762C3.95869 8.28572 4.00712 8.34532 4.06697 8.39186L11.1107 13.8934C11.1935 13.9575 11.3123 13.8997 11.3123 13.795V12.5872C11.3123 12.5106 11.2763 12.4372 11.217 12.3903L5.59197 7.99967L11.217 3.60749C11.2763 3.56061 11.3123 3.48717 11.3123 3.41061Z"
              fill="#8C8C8C"
            />
          </svg>
        </button>
        <button
          // disabled={months >= maxMonth}
          style={styleRight}
          onClick={() => onNextClickDate()}
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.964 7.60628L4.92031 2.10472C4.90191 2.09023 4.87979 2.08122 4.8565 2.07874C4.8332 2.07625 4.80968 2.08039 4.78863 2.09066C4.76758 2.10094 4.74986 2.11695 4.7375 2.13685C4.72514 2.15675 4.71864 2.17973 4.71875 2.20316V3.41097C4.71875 3.48753 4.75469 3.56097 4.81406 3.60784L10.439 8.00003L4.81406 12.3922C4.75313 12.4391 4.71875 12.5125 4.71875 12.5891V13.7969C4.71875 13.9016 4.83906 13.9594 4.92031 13.8953L11.964 8.39378C12.0239 8.34708 12.0723 8.28735 12.1057 8.21912C12.139 8.15089 12.1563 8.07596 12.1563 8.00003C12.1563 7.9241 12.139 7.84918 12.1057 7.78095C12.0723 7.71272 12.0239 7.65298 11.964 7.60628Z"
              fill="#8C8C8C"
            />
          </svg>
        </button>
      </div>
    );
  };

  useEffect(() => {
    const getDaysInMonth = (month, year) => {
      // Since no month has fewer than 28 days
      let date = new Date(year[0], month[0] - 1, 1);
      let date2 = new Date(year[1], month[1] - 1, 1);
      const days = [];
      while (date.getMonth() === month[0] - 1) {
        days.push(moment(new Date(date)).format("YYYY-MM-DD"));
        date.setDate(date.getDate() + 1);
      }
      if (month[1]) {
        while (date2.getMonth() === month[1] - 1) {
          days.push(moment(new Date(date2)).format("YYYY-MM-DD"));
          date2.setDate(date2.getDate() + 1);
        }
      }
      return days;
    };
    const disabledMonth = getDaysInMonth(monthsDisable, years);
    const list = disabledMonth.filter(date => !dataListDate.includes(date));

    let dataDisable = list.map(res => {
      return moment(res).toDate();
    });
    dataDisable.push(minMaxDate);
    setDataTimeDisable(dataDisable);
  }, [dataListDate, monthsDisable, minMaxDate]);

  useEffect(() => {
    if (dataBr && redisToken) {
      const _endPoint = dataBr?.to;
      const _startPoint = dataBr?.from;
      const _nights = dataBr?.nights;
      // const _bookingRequestId = dataRedis.bookingRequestId
      const _dataListDate = {
        tokenRedis: redisToken,
        numOfMonth: isMobileScreen() ? 1 : 2,
        endPoint: _endPoint,
        month: months,
        comboNights: _nights,
        year: years[0],
        startPoint: _startPoint
      };
      fetchListDate(_dataListDate);
    }
  }, [dataBr, redisToken]);

  const fetchListDate = async data => {
    try {
      dispatch(toggleShowLoading(true));
      dispatch(
        setLoadingMessage(
          "Đang tiến hành săn vé, vui lòng đợi trong giây lát..."
        )
      );
      const dataDate = await getListDate(data);
      dispatch(toggleShowLoading(false));
      if (dataDate?.status === "success") {
        let active = dataDate?.data?.arrayDayCombo.map(res => {
          return moment(res).toDate();
        });
        let active2499 = dataDate?.data?.arrayDayCombo2499.map(res => {
          return moment(res).toDate();
        });
        let active2999 = dataDate?.data?.arrayDayCombo2999.map(res => {
          return moment(res).toDate();
        });
        let activeGreaterThan2999 = dataDate?.data?.arrayDayComboGreaterThan2999.map(
          res => {
            return moment(res).toDate();
          }
        );
        setListDate({
          active,
          active2499,
          active2999,
          activeGreaterThan2999
        });
        let _listDateAll = dataDate?.data?.arrayDayCombo
          .concat(dataDate?.data?.arrayDayCombo2499)
          .concat(dataDate?.data?.arrayDayCombo2999)
        .concat(dataDate?.data?.arrayDayComboGreaterThan2999);
        // sắp xếp từ nhỏ đến lớn
        _listDateAll.sort(
          (a, b) =>
            moment(a, "YYYY-MM-DD").format("YYYYMMDD") -
            moment(b, "YYYY-MM-DD").format("YYYYMMDD")
        );
        setDataListDate(_listDateAll);
        let endDate = _listDateAll[_listDateAll.length - 1];
        setMaxMonth(moment(endDate).month() + 1);
        setMinMaxDate({
          before: moment(_listDateAll[0]).toDate(),
          after: moment(_listDateAll[_listDateAll.length - 1]).toDate()
        });
        let month1 = moment(_listDateAll[0]).month() + 1;
        let month2 = moment(_listDateAll[0]).month() + 2;
        let year1 = moment(_listDateAll[0]).year();
        let year2 = moment(_listDateAll[0]).year();
        if (month2 > 12) {
          year2 += 1;
          month2 -= 12;
        }
        if (month1 > 12) {
          year1 += 1;
          month1 -= 12;
        }
        setYears([year1, year2]);
        setTextMonth(moment(_listDateAll[0]).toDate());
        isMobileScreen()
          ? setMonthsDisable([month1])
          : setMonthsDisable([month1, month2]);
      }
    } catch (e) {
      dispatch(toggleShowLoading(false));
      throw e;
    }
  };

  const handleChangeDepartureDate = async (day, modifiers = {}) => {
    if (modifiers.disabled) {
      return;
    }
    setDepartureDate(day);
  };

  const handleContinue = async () => {
    try {
      let dataUpdate = { ...dataBr };
      dataUpdate.departDate = departureDate;
      dataUpdate.listDate = listDate;
      let isMaxPrice = "unitPrice1999";
      let listActive2499 = listDate?.active2499 || [];
      let listActive2999 = listDate?.active2999 || [];
      listActive2499.forEach(res => {
        if (
          moment(res).format("YYYYMMDD") ===
          moment(departureDate).format("YYYYMMDD")
        ) {
          isMaxPrice = "unitPrice2499";
        }
      });

      listActive2999.forEach(res => {
        if (
          moment(res).format("YYYYMMDD") ===
          moment(departureDate).format("YYYYMMDD")
        ) {
          isMaxPrice = "unitPrice2999";
        }
      });
      dataUpdate.isMaxPrice = isMaxPrice;

      const result = await updateRedis(dataUpdate, redisToken);
      if (result?.status === "success") {
        history.push("/check-out/step1/" + redisToken);
        setIsModal(false);
        dispatch(setRefreshPage(new Date().getTime()));
      }
    } catch (e) {
      throw e;
    }
  };

  // console.log("===dataRedis==" , dataRedis)
  useEffect(() => {
    if (dealFlightConfig) {
      dealFlightConfig.sort(function(a, b) {
        return a.price - b.price;
      });
      setDealFlight(dealFlightConfig);
    }
  }, [dealFlightConfig]);

  const getPriceFromDate = (listDate, dealFlight, date) => {
    let price = 0;
    Array.isArray(listDate?.active) &&
      listDate.active.forEach(item => {
        if (
          moment(item).format("DD/MM/YYYY") ===
          moment(date).format("DD/MM/YYYY")
        ) {
          price =
            dealFlight.filter(res => res?.type === "unitPrice1999")?.[0]
              ?.unitPrice || 0;
        }
      });
    Array.isArray(listDate?.active2499) &&
      listDate.active2499.forEach(item => {
        if (
          moment(item).format("DD/MM/YYYY") ===
          moment(date).format("DD/MM/YYYY")
        ) {
          price =
            dealFlight.filter(res => res?.type === "unitPrice2499")?.[0]
              ?.unitPrice || 0;
        }
      });
    Array.isArray(listDate?.active2999) &&
      listDate.active2999.forEach(item => {
        if (
          moment(item).format("DD/MM/YYYY") ===
          moment(date).format("DD/MM/YYYY")
        ) {
          price =
            dealFlight.filter(res => res?.type === "unitPrice2999")?.[0]
              ?.unitPrice || 0;
        }
      });
    Array.isArray(listDate?.activeGreaterThan2999) &&
    listDate.activeGreaterThan2999.forEach(item => {
      if (
          moment(item).format("DD/MM/YYYY") ===
          moment(date).format("DD/MM/YYYY")
      ) {
        price =
            dealFlight.filter(res => res?.type === "unitPrice1999")?.[0]
                ?.unitPrice || 0;
      }
    });
    return price;
  };
  return (
    <Modal
      className={"modalDateFlight"}
      footer={null}
      width={900}
      title={
        <div className={"step3"}>
          <div className="comboIno text-center">
            <p style={{ margin: 0 }} className={"size-14 semibold"}>
              Combo {convertAirportCodeToCityName(dataBr?.from) || "Hà Nội"} -{" "}
              {convertAirportCodeToCityName(dataBr?.to) || "Hồ Chí Minh"}
            </p>
            <span className={"size-14 gray1"}>
              {dataBr?.days} ngày {dataBr?.nights} đêm
            </span>
          </div>
        </div>
      }
      visible={isModal}
      onOk={() => setIsModal(false)}
      onCancel={() => setIsModal(false)}
    >
      <div className="content text-center mb20">
        <p className={"semibold size-16"}>Chọn ngày khởi hành</p>
        {/*<p className={"gray1 mb-15"}>*/}
        {/*  Đây là những ngày khởi hành gần nhất còn chỗ. Bạn có thể thay đổi*/}
        {/*  tháng để tìm ngày phù hợp.*/}
        {/*</p>*/}
      </div>
      <div className="calendar mb15">
        <DayPicker
          numberOfMonths={isMobileScreen() ? 1 : 2}
          selectedDays={departureDate}
          modifiers={modifiers}
          disabledDays={dataTimeDisable}
          renderDay={date => {
            return (
              <>
                <p className="mb-0 semibold">{moment(date).format("DD")}</p>
                {getPriceFromDate(listDate, dealFlight, date) > 0 && (
                  <span style={{ fontSize: 10, display: "inherit" }}>
                    <DisplayPrice2
                      price={getPriceFromDate(listDate, dealFlight, date)}
                    />
                  </span>
                )}
              </>
            );
          }}
          fromMonth={textMonth}
          toMonth={moment(dataListDate[dataListDate.length - 1])
            .add(1, "months")
            .toDate()}
          month={textMonth}
          locale="vi"
          navbarElement={<Navbar />}
          onDayClick={handleChangeDepartureDate}
        />
      </div>
      <div className="footerFlight">
        {departureDate && (
          <p className={"gray1 semibold"}>
            {formatDate2(departureDate)} -{" "}
            {formatDate2(moment(departureDate).add(dataBr?.nights, "days"))} (
            {dataBr?.nights} đêm nghỉ)
          </p>
        )}

        <div className={`btn-search ${!departureDate ? "w-100" : ""}`}>
          <Button
            disabled={!departureDate}
            onClick={handleContinue}
            type={"primary"}
          >
            Tìm kiếm
          </Button>
        </div>
      </div>
      <div className="groupBtn"></div>
    </Modal>
  );
};

export default ModalDateFlight;
