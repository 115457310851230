import ApiMicroService from "./api-request-micro";

export const getListHotel = async (sortBy,checkInDate, nights, provinceId, adults, childs, bookingRequestId, optionHotel) => {
    try {
        const results = await ApiMicroService.get(`/search-engine/super-combo/hotels?sort_by=${sortBy}&check_in_date=${checkInDate}&nights=${nights}&province_id=${provinceId}&adults=${adults}&childs=${childs}&booking_request_id=${bookingRequestId}&combo_rate=${optionHotel ? optionHotel : 'STANDARD'} `);
        return results.data
    } catch (e) {
        //
        throw e
    }
}
export const getListHotel1 = async (params) => {
    try {
        const results = await ApiMicroService.get(`/search-engine/super-combo/hotels`, {
            params
        });
        return results.data
    } catch (e) {
        //
        throw e
    }
}

export const getHotelDetail = async (id) => {
    try {
        const results = await ApiMicroService.get(`/core-hotel-service/hotel/hotel-detail?hotel_ids=${id}`);
        return results.data
    } catch (e) {
        throw e
    }
};

export const createSuggestion = async (data, suggestion_id) => {
    try {
        const results = await ApiMicroService.post(`v3-booking/booking-request/suggestion/${suggestion_id}/rate`, data);
        return results.data
    } catch (e) {
        throw e
    }
};
export const getDataHotel = async id => {
    try {
        const results = await ApiMicroService.get(`/core-hotel-service/hotel/hotel-detail?hotel_ids=${id}`)
        return results.data
    } catch (e) {
        throw e
    }
}

export const getListDealLevel = async () => {
    try {
        const results = await ApiMicroService.get(`v2-hotel/deals/levels/?type=rate`)
        return results.data
    } catch (e) {
        throw e
    }
}
