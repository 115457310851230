import React from 'react';

function FinishComboHotel() {
    return (
        <div className="comboHotel">
            <div className="title1">
                <h4>Thông tin khách sạn</h4>
            </div>
            <div className="hotelItem">
                <div className="hotelItem__img"><img src="https://statics2.vntrip.vn/165x165/data-v2/hotels/62584/img_max/62584_1559206254_av7c9704_copy.jpg" alt = "" /></div>
                <div className="hotelItem__cont">
                    <p className="hotelItem__name">OYO Hải Châu</p>
                    <div className="rateStar">
                        <svg width={14} height={14} viewBox="0 0 96 91" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M48 3.90594L59.8402 32.3732L60.192 33.2191L61.1053 33.2923L91.8381 35.7561L68.4229 55.8137L67.7271 56.4097L67.9397 57.3009L75.0934 87.2909L48.7819 71.2199L48 70.7423L47.2181 71.2199L20.9066 87.2909L28.0603 57.3009L28.2729 56.4097L27.5771 55.8137L4.16194 35.7561L34.8947 33.2923L35.808 33.2191L36.1598 32.3732L48 3.90594Z" fill="#FA8C16" stroke="#FA8C16" strokeWidth={3} />
                        </svg>
                        <svg width={14} height={14} viewBox="0 0 96 91" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M48 3.90594L59.8402 32.3732L60.192 33.2191L61.1053 33.2923L91.8381 35.7561L68.4229 55.8137L67.7271 56.4097L67.9397 57.3009L75.0934 87.2909L48.7819 71.2199L48 70.7423L47.2181 71.2199L20.9066 87.2909L28.0603 57.3009L28.2729 56.4097L27.5771 55.8137L4.16194 35.7561L34.8947 33.2923L35.808 33.2191L36.1598 32.3732L48 3.90594Z" fill="#FA8C16" stroke="#FA8C16" strokeWidth={3} />
                        </svg>
                        <svg width={14} height={14} viewBox="0 0 96 91" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M48 3.90594L59.8402 32.3732L60.192 33.2191L61.1053 33.2923L91.8381 35.7561L68.4229 55.8137L67.7271 56.4097L67.9397 57.3009L75.0934 87.2909L48.7819 71.2199L48 70.7423L47.2181 71.2199L20.9066 87.2909L28.0603 57.3009L28.2729 56.4097L27.5771 55.8137L4.16194 35.7561L34.8947 33.2923L35.808 33.2191L36.1598 32.3732L48 3.90594Z" fill="#FA8C16" stroke="#FA8C16" strokeWidth={3} />
                        </svg>
                        <svg width={14} height={14} viewBox="0 0 96 91" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M48 3.90594L59.8402 32.3732L60.192 33.2191L61.1053 33.2923L91.8381 35.7561L68.4229 55.8137L67.7271 56.4097L67.9397 57.3009L75.0934 87.2909L48.7819 71.2199L48 70.7423L47.2181 71.2199L20.9066 87.2909L28.0603 57.3009L28.2729 56.4097L27.5771 55.8137L4.16194 35.7561L34.8947 33.2923L35.808 33.2191L36.1598 32.3732L48 3.90594Z" fill="url(#paint0_linear)" stroke="#FA8C16" strokeWidth={3} />
                            <defs>
                                <linearGradient id="paint0_linear" x1={-2} y1={50} x2={98} y2={50} gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#FA8C16" />
                                    <stop offset="0.5" stopColor="#FA8C16" />
                                    <stop offset="0.5" stopColor="#FA8C16" stopOpacity={0} />
                                    <stop offset={1} stopColor="#FA8C16" stopOpacity={0} />
                                </linearGradient>
                            </defs>
                        </svg>
                    </div>
                    <p className="hotelItem__add">03 Cao Thắng, ph12, Q.10, Hồ Chí Minh</p>
                </div>
            </div>
        </div>
    );

}

export default FinishComboHotel;