import ApiMicroService from "./api-request-micro";
import {getHeaders} from "../utils/common-func";
import {getPhoneNotMember} from "../const/user";

export const verificationRequestPhone = async (data) => {
    const results = await ApiMicroService.post('/core-user-service/verification/request/phone', data, {
        headers: getHeaders(),
    })
    return results.data
}

export const verificationRequestPhoneNotMember = async (data) => {
    const results = await ApiMicroService.post('/core-user-service/verification/request/phone-not-member', data, {
        headers: getHeaders(),
    })
    return results.data
}

export const verifyPhone = async (data) => {
    const results = await ApiMicroService.post('/core-user-service/user/verify/phone', data, {
        headers: getHeaders(),
    })
    return results.data
}

export const verifyPhoneNotMember = async (data) => {
    const results = await ApiMicroService.post('/core-user-service/user/verify/phone-not-member', data, {
        headers: getHeaders(),
    })
    return results.data
}

export const confirmVerification = async (data) => {
    const results = await ApiMicroService.post('/core-user-service/verification/confirm', data, {
        headers: getHeaders(),
    })
    return results.data
}

export const verificationRequestEmail = async (data) => {
    const results = await ApiMicroService.post('/core-user-service/verification/request/email', data, {
        headers: getHeaders(),
    })
    return results.data
}

export const getSlotId = async (phone) => {
    try {
        const results = await ApiMicroService.get('/core-partner/lotte-finance/userSlotId/' + phone, {
            headers: getHeaders(),
        })
        return results.data
    } catch (e) {
        throw e
    }
}

export const getLimitLotte = async (data) => {
    let user_id = ''
    let phone = ''
    let email
    if (data) {
        if (data.user) {
            user_id = data.user.user_id
            phone = data.user.phone
            email = data.user.email
        } else if (getPhoneNotMember()) {
            phone = getPhoneNotMember()
            email = data?.email || ''
        } else {
            phone = data?.phone || ''
            email = data?.email || ''
        }
    }
    const result = await getPLToken({ userId: user_id, phone, email })
    if (result?.status === 'success') {
        const { PLallowed, token } = result.data
        await updateToken(token, { phone: phone })
        // if (PLallowed) {
        //     console.log('----TH1------')
        //     return await getUserLotteLoanLimit()
        // }
    }
    return null
}

export const updateToken = async (token, params) => {
    try {
        const results = await ApiMicroService.get('/core-partner/lotte-finance/userUpdateTokenLF/' + token, {
            headers: getHeaders(),
            params,
        })
        return results.data
    } catch (e) {
        throw e
    }
}

export const getUserLotteLoanLimit = async () => {
    try {
        const results = await ApiMicroService.get('/core-partner/lotte-finance/userLoanLimit', {
            headers: getHeaders(),
        })
        return results.data
    } catch (e) {
        throw e
    }
}

export const getIdentifiers = async () => {
    const results = await ApiMicroService.get('/core-user-service/user/identifiers', {
        headers: getHeaders(),
    })
    return results.data
}


// export const updateBookerData = async (body) => {
//     try {
//         const results = await ApiMicroService.put('/core-partner/lotte-finance/phone-not-member/', body, {
//             headers: getHeaders(),
//         })
//         return results.data
//     } catch (e) {
//         throw e
//     }
// }

export const updateBookerData = async (body) => {
    try {
        const results = await ApiMicroService.put('/payment-service/lotte-finance/phone-not-member', body, {
            headers: getHeaders(),
        })
        return results.data
    } catch (e) {
        throw e
    }
}

export const getPLToken = async (params) => {
    try {
        const results = await ApiMicroService.get('/core-partner/lotte-finance/getPLToken', {
            headers: getHeaders(),
            params
        })
        return results.data
    } catch (e) {
        throw e
    }
}





