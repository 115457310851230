import React, { useEffect, useRef, useState } from 'react'
import SearchAirPort from '../../component/home/SearchAirPort'
import { getBookerData } from '../../utils/common-func'
import { DEFAULT_OBJECT_FLIGHT, ORIGIN_AIRPORT, ACTIVE_ROUTE, DOMESTIC_AIRPORT } from '../../const/search-combo'
import { setFlightListSearchBox, setListAirPortReturn } from '../../redux/actions/search-combo'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import * as _ from 'lodash'

function SearchAirPortContainer({
    title,
    label,
    departure,
    destination,
    setValueDeparture,
    type,
    flightList,
    dispatch,
    listAirPortReturn,
    setProvinceId,
    inputValue,
    setInputValue,
    setInputValueReturn,
    isSetEndPoint,
    setIsSetEndPoint
}) {
    const [openDeparture, setOpenDeparture] = useState(false)
    const [listAirPort, setListAirPort] = useState([])
    const [listCurrentReturn, setListCurrentReturn] = useState([])
    const [listCurrent, setListCurrent] = useState([])
    const [dataAirPortReturn, setDataAirPortReturn] = useState({})
    const [startPoint, setStartPoint] = useState('')
    const [endPoint, setEndPoint] = useState('')
    const defaultAirPort = useRef(null)

    useEffect(() => {
        getListAirPort()
    }, [])

    useEffect(() => {
        const bookerData = getBookerData()
        if (bookerData && bookerData.departure && bookerData.destination) {
            !isSetEndPoint && setStartPoint(bookerData.departure.code)
            isSetEndPoint ? setEndPoint('') : setEndPoint(bookerData.destination.code)
            const dataReturn = _.filter(DOMESTIC_AIRPORT, function(o) {
                return ACTIVE_ROUTE[bookerData.departure.code].indexOf(o.code) !== -1
            })
            !isSetEndPoint && dispatch(setListAirPortReturn(dataReturn))
        }
        dispatch(setFlightListSearchBox(flightList))
    }, [dataAirPortReturn, dispatch, flightList, isSetEndPoint])

    const getListAirPort = () => {
        try {
            const dataList = _.filter(DOMESTIC_AIRPORT, function(o) {
                return ORIGIN_AIRPORT.indexOf(o.code) !== -1
            })
            setListAirPort(dataList)
            setDataAirPortReturn(dataList)
        } catch (e) {
            throw e
        }
    }

    const handleKeyup = (e, type) => {
        searchOnInout(e, type)
    }

    const handleChangeInput = async event => {
        setInputValue(event.target.value)
        const keyword = event.target.value.trim()

        if (keyword.length === 0) {
            let _flightList = [...flightList]
            let itemFlight = _flightList[0]
            itemFlight[type === 'departure' ? 'startData' : 'endData'] = null
            itemFlight[type === 'departure' ? 'startPoint' : 'endPoint'] = ''
            _flightList[0] = { ...itemFlight }
            _flightList[1][type === 'departure' ? 'endData' : 'startData'] = null
            _flightList[1][type === 'departure' ? 'endPoint' : 'startPoint'] = ''
            dispatch(setFlightListSearchBox(_flightList))
            setEndPoint('')
            setStartPoint('')
        }
    }

    const searchOnInout = (e, type) => {
        const keyword = e.target.value.toLowerCase()
        setOpenDeparture(true)
        let list = []
        if (type === 'destination') {
            if (listAirPortReturn.length > 0) {
                list = listAirPortReturn.filter(item => {
                    return item.key.indexOf(keyword) !== -1
                })
            } else {
                list = listAirPort.filter(item => {
                    return item.key.indexOf(keyword) !== -1
                })
            }
            setListCurrentReturn(list)
            if (list.length > 0) {
                setValueDeparture(list[0].code)
            }
        } else {
            list = listAirPort.filter(item => {
                return item.key.indexOf(keyword) !== -1
            })
            setListCurrent(list)
            if (list.length > 0) {
                setValueDeparture(list[0].code)
            }
        }
    }

    const handleClickDeparture = boolean => {
        type === 'departure' ? setListCurrent(listAirPort) : setListCurrentReturn(listAirPortReturn)
        setOpenDeparture(boolean)
    }

    const handleClickItem = async (airport, type) => {
        handleClickDeparture(false)
        setValueDeparture(airport)
        setInputValue(airport.province_name)
        setProvinceId(airport.province_id)
        if (type === 'departure') {
            const dataReturn = _.filter(DOMESTIC_AIRPORT, function(o) {
                return ACTIVE_ROUTE[airport.code].indexOf(o.code) !== -1
            })
            setInputValueReturn('')
            setEndPoint('')
            setIsSetEndPoint(false)
            dispatch(setListAirPortReturn(dataReturn))
        }
        let selected = { ...airport }
        if (airport.hasOwnProperty('airportCode')) {
            selected = {
                code: airport.airportCode,
                cityName: airport.province_name,
                region_name_vi: airport.region_name_vi
            }
        }
        if (type === 'departure') {
            setStartPoint(selected.code)
        } else {
            setEndPoint(selected.code)
        }
    }

    const getDataListAirPort = type => {
        let result = []
        if (type === 'destination') {
            if (listCurrentReturn.length > 0) {
                result = listCurrentReturn
            } else {
                if (listAirPortReturn.length > 0) {
                    result = listAirPortReturn
                } else {
                    result = listAirPort
                }
            }
        } else {
            if (listCurrent.length > 0) {
                result = listCurrent
            } else {
                result = listAirPort
            }
        }
        return result
    }

    useEffect(() => {
        // add when mounted
        document.addEventListener('mousedown', handleClickOutSideAirPort)
        // return function to be called when unmounted
        return () => {
            document.removeEventListener('mousedown', handleClickOutSideAirPort)
        }
    }, [])

    const handleClickOutSideAirPort = event => {
        if (defaultAirPort && defaultAirPort && defaultAirPort.current.contains(event.target)) {
            // inside click
            return
        }

        // outside click
        setOpenDeparture(false) // close dropdown
    }

    return (
        <SearchAirPort
            label={label}
            listAirPort={getDataListAirPort(type)}
            inputValue={inputValue}
            handleChangeInput={handleChangeInput}
            flightList={flightList}
            handleKeyup={handleKeyup}
            type={type}
            startPoint={startPoint}
            defaultAirPort={defaultAirPort}
            endPoint={endPoint}
            handleClickDeparture={handleClickDeparture}
            handleClickItem={handleClickItem}
            openDeparture={openDeparture}
            title={title}
            departure={departure ? departure : destination}
        />
    )
}

const mapStateToProps = function(state) {
    return {
        flightList: state.comboSearchReducer.flightList || [DEFAULT_OBJECT_FLIGHT, DEFAULT_OBJECT_FLIGHT],
        listAirPortReturn: state.comboSearchReducer.listAirPortReturn || []
    }
}

export default connect(mapStateToProps)(withRouter(SearchAirPortContainer))
