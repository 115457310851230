import React from 'react';
import {PASSENGER_CONST} from "../../const/search-combo";
import {IconMinus, IconPassenger, IconPlus} from "../../const/icon-const";

function SearchPassengers({handClickOpen, openPassenger, infantCount, adultCount, childCount, handleChoosePassengers, defaultPassenger}) {
    return (
        <div className="box-search-item">
            <div className="form-group">
                <label>Số người đi</label>
                <div className={`passenger ${openPassenger ? 'open' : ''}`}>
                    <button onClick={() => handClickOpen(true)} type="button" className="btn-passenger">
                        <span>{adultCount} Người lớn - {childCount} Trẻ em - {infantCount} Trẻ sơ sinh</span><i
                        className="caret"/>
                    </button>
                    <ul className="passenger__body" ref={defaultPassenger}>
                        <li className="passenger_header"><span>Số hành khách</span>
                            <div className="passenger__close">
                                <button onClick={() => handClickOpen(false)} type="button">×</button>
                            </div>
                        </li>
                        <li className="passenger__item">

                            <button type="button" className={`minus ${adultCount === 2 ? 'disabled' : ''}`}
                                    onClick={() => handleChoosePassengers(PASSENGER_CONST.ADT_DESC)}>
                                <svg width={22} height={22} version="1.1"
                                     xmlns="http://www.w3.org/2000/svg"
                                     xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                     viewBox="0 0 512 512" xmlSpace="preserve">
                                    <path
                                        d="M256,0C114.853,0,0,114.833,0,256s114.853,256,256,256c141.167,0,256-114.833,256-256S397.147,0,256,0z M256,472.341                                                                                                                                                             c-119.295,0-216.341-97.046-216.341-216.341S136.705,39.659,256,39.659S472.341,136.705,472.341,256S375.295,472.341,256,472.341z                                                                                                                                                             "/>
                                    <path
                                        d="M355.148,234.386H156.852c-10.946,0-19.83,8.884-19.83,19.83s8.884,19.83,19.83,19.83h198.296                                                                                                                                                             c10.946,0,19.83-8.884,19.83-19.83S366.094,234.386,355.148,234.386z"/>
                                </svg>
                            </button>
                            <p><span>{adultCount} Người lớn</span></p>
                            <button  className={`plus ${adultCount === 6 ? 'disabled' : ''}`} type="button"
                                    onClick={() => handleChoosePassengers(PASSENGER_CONST.ADT_ASC)}>
                                <svg width={22} height={22} version="1.1"
                                     xmlns="http://www.w3.org/2000/svg"
                                     xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                     viewBox="0 0 512 512"
                                     style={{enableBackground: 'new 0 0 512 512'}}
                                     xmlSpace="preserve">
                                    <path
                                        d="M256,0C114.833,0,0,114.833,0,256s114.833,256,256,256s256-114.853,256-256S397.167,0,256,0z M256,472.341                                                                                                                                                             c-119.275,0-216.341-97.046-216.341-216.341S136.725,39.659,256,39.659S472.341,136.705,472.341,256S375.295,472.341,256,472.341z                                                                                                                                                             "/>
                                    <path
                                        d="M355.148,234.386H275.83v-79.318c0-10.946-8.864-19.83-19.83-19.83s-19.83,8.884-19.83,19.83v79.318h-79.318                                                                                                                                                             c-10.966,0-19.83,8.884-19.83,19.83s8.864,19.83,19.83,19.83h79.318v79.318c0,10.946,8.864,19.83,19.83,19.83                                                                                                                                                             s19.83-8.884,19.83-19.83v-79.318h79.318c10.966,0,19.83-8.884,19.83-19.83S366.114,234.386,355.148,234.386z"/>
                                </svg>
                            </button>

                        </li>
                        <li className="passenger__item">

                            <button onClick={() => handleChoosePassengers(PASSENGER_CONST.CHD_DESC)}
                                    className={`minus ${childCount === 0 ? 'disabled' : ''}`} type="button">
                                <svg width={22} height={22} version="1.1"
                                     xmlns="http://www.w3.org/2000/svg"
                                     xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                     viewBox="0 0 512 512" xmlSpace="preserve">
                                    <path
                                        d="M256,0C114.853,0,0,114.833,0,256s114.853,256,256,256c141.167,0,256-114.833,256-256S397.147,0,256,0z M256,472.341                                                                                                                                                                                                            c-119.295,0-216.341-97.046-216.341-216.341S136.705,39.659,256,39.659S472.341,136.705,472.341,256S375.295,472.341,256,472.341z                                                                                                                                                                                                            "/>
                                    <path
                                        d="M355.148,234.386H156.852c-10.946,0-19.83,8.884-19.83,19.83s8.884,19.83,19.83,19.83h198.296                                                                                                                                                                                                            c10.946,0,19.83-8.884,19.83-19.83S366.094,234.386,355.148,234.386z"/>
                                </svg>
                            </button>
                            <p><span>{childCount} Trẻ em </span>( 2-12 tuổi )</p>
                            <button
                                className={`plus ${childCount === adultCount || childCount === 6 ? 'disabled' : ''}`}
                                onClick={() => handleChoosePassengers(PASSENGER_CONST.CHD_ASC)} type="button">
                                <svg width={22} height={22} version="1.1"
                                     xmlns="http://www.w3.org/2000/svg"
                                     xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                     viewBox="0 0 512 512"
                                     style={{enableBackground: 'new 0 0 512 512'}}
                                     xmlSpace="preserve">
                                    <path
                                        d="M256,0C114.833,0,0,114.833,0,256s114.833,256,256,256s256-114.853,256-256S397.167,0,256,0z M256,472.341                                                                                                                                                                                                            c-119.275,0-216.341-97.046-216.341-216.341S136.725,39.659,256,39.659S472.341,136.705,472.341,256S375.295,472.341,256,472.341z                                                                                                                                                                                                            "/>
                                    <path
                                        d="M355.148,234.386H275.83v-79.318c0-10.946-8.864-19.83-19.83-19.83s-19.83,8.884-19.83,19.83v79.318h-79.318                                                                                                                                                                                                            c-10.966,0-19.83,8.884-19.83,19.83s8.864,19.83,19.83,19.83h79.318v79.318c0,10.946,8.864,19.83,19.83,19.83                                                                                                                                                                                                            s19.83-8.884,19.83-19.83v-79.318h79.318c10.966,0,19.83-8.884,19.83-19.83S366.114,234.386,355.148,234.386z"/>
                                </svg>
                            </button>

                        </li>
                        <li className="passenger__item">

                            <button className={`minus ${infantCount === 0 ? 'disabled' : ''}`}
                                    onClick={() => handleChoosePassengers(PASSENGER_CONST.INF_DESC)} type="button">
                                <IconMinus/>
                            </button>
                            <p><span>{infantCount} Trẻ sơ sinh ( &lt; 2 tuổi )</span></p>
                            <button
                                className={`plus ${infantCount === adultCount || infantCount === 6 ? 'disabled' : ''}`}
                                onClick={() => handleChoosePassengers(PASSENGER_CONST.INF_ASC)} type="button">
                               <IconPlus/>
                            </button>

                        </li>
                    </ul>
                </div>
                <IconPassenger/>
            </div>
        </div>
    );
}

export default SearchPassengers;