import { getCurrencySession, getExChangeRateSession } from '../../utils/common-func'
import { connect } from 'react-redux'

const currencies = {
    USD: ['$', 2, ',', 'before', 'United States dollar'],
    VND: ['₫', 0, '.', 'after', 'Vietnamese dong']
}

function DisplayPrice({ price, currency }) {
    price = Number(price)
    if (Number.isNaN(price)) {
        return ''
    }
    const formatCurr = (x, curr) => {
        if (!curr) curr = 'VND'
        let sym = currencies[curr][0]
        let dec = currencies[curr][1]

        let y = Number(Math.round(x + 'e' + dec) + 'e-' + dec)
        // let y = x.toFixed(dec);
        let z = y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, currencies[curr][2])
        return currencies[curr][3] === 'before' ? sym + z : z + sym
    }
    const formatPrice = () => {
        let rate = 1
        if (currency === 'USD') {
            let exChangeRate = getExChangeRateSession()
            rate = exChangeRate['transfer']
        }
        return formatCurr(price / rate, currency)
    }
    return formatPrice()
}

const mapStateToProps = function(state) {
    return {
        currency: state.commonReducer.currency || getCurrencySession()
    }
}
export default connect(mapStateToProps)(DisplayPrice)
