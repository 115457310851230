import React from 'react'
import { useDispatch } from 'react-redux'
import { convertPartnerPhone, toggleClassNoScroll } from '../../utils/common-func'
import { Link } from 'react-router-dom'
import { IconLogoBlack, IconMenuFold, IconPhone } from '../../const/icon-const'
import { toggleSidebarMobile } from '../../redux/actions/common'
import { HOT_LINE } from '../../const/common-const'

const HeaderMobile = ({ dataWebview }) => {
    const dispatch = useDispatch()

    const openSidebar = () => {
        toggleClassNoScroll('add')
        dispatch(toggleSidebarMobile(true))
    }
    return (
        <header className="header">
            <div className="header__mobile">
                <div className="header__group">
                    <div className="flexGroup">
                        <button type="button" className="btnToggle" onClick={openSidebar}>
                            <IconMenuFold />
                        </button>
                        {/* show logo if do not in hotel list, detail */}
                    </div>
                    <h1 className="header__logo">
                        <Link to={'/'} className="logo">
                            <img src={dataWebview.logoUrl} alt="" />
                        </Link>
                    </h1>
                    <a
                        href={`tel:${
                            dataWebview.whitelabel && dataWebview.partnerPhone
                                ? convertPartnerPhone(dataWebview.partnerPhone)
                                : HOT_LINE
                        }`}
                        className="hotline"
                    >
                        <IconPhone />
                    </a>
                </div>
            </div>
        </header>
    )
}

export default HeaderMobile
