import React, {useEffect, useState} from 'react'
import {withRouter} from 'react-router-dom'
import {Form, Input, Button, Modal, message} from 'antd'
import {connect} from 'react-redux'
import * as queryString from 'query-string'
import {transactionConfirmation} from '../../api-services/payment-method-service'
import {setLoadingMessage, toggleShowLoading} from '../../redux/actions/home-actions'
import {getCompanyInfoByTaxCode, getInvoiceInfo, updateInfoTaxCode} from '../../api-services/home-service'
import {HOT_LINE} from '../../const/common-const'
import {Helmet} from "react-helmet";

const {TextArea} = Input

function CheckoutSuccess({location, dispatch,dataWebview}) {
    const [dataOrder, setDataOrder] = useState({})
    const [userInfo, setUserInfo] = useState({})
    const [isCheck, setIsCheck] = useState(false)
    const [isDisable, setIsDisable] = useState(false)
    const [taxCode, setTaxCode] = useState('')
    const [companyName, setCompanyName] = useState('')
    const [companyAddress, setCompanyAddress] = useState('')
    const [note, setNote] = useState('')
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [validateTaxCode, setValidateTaxCode] = useState({status: 'success', text: ''})
    const [validateCompanyName, setValidateCompanyName] = useState({status: 'success', text: ''})
    const [validateAddressName, setValidateAddressName] = useState({status: 'success', text: ''})

    useEffect(() => {
        const query = queryString.parse(location.search)
        const transaction_id = query.transaction_id
        const payment_method = query.payment_method
        let webviewSessionInfo = JSON.parse(localStorage.getItem('webviewSessionInfo'))
        if (payment_method === 'payment_method_momo_app' && webviewSessionInfo) {
            dispatch(toggleShowLoading(true))
            dispatch(setLoadingMessage('Đang tiến hành thanh toán'))
            dispatch(toggleShowLoading(false))
        }

        fetchData(transaction_id)
    }, [])

    const clearValidate = () => {
        setValidateAddressName({status: 'success', text: ''})
        setValidateCompanyName({status: 'success', text: ''})
        setValidateTaxCode({status: 'success', text: ''})
    }
    const handleChangeTaxCode = e => {
        setTaxCode(e.target.value)
        clearValidate()
    }

    const handleChangeCompanyName = e => {
        setCompanyName(e.target.value)
        clearValidate()
    }

    const handleChangeCompanyAddress = e => {
        setCompanyAddress(e.target.value)
        clearValidate()
    }

    const handleChangeNote = e => {
        setNote(e.target.value)
        clearValidate()
    }

    const fetchData = async transaction_id => {
        try {
            dispatch(toggleShowLoading(true))
            const res = await transactionConfirmation(transaction_id)
            dispatch(toggleShowLoading(false))
            if (res && res.status === 'success') {
                setDataOrder(res.data)
            } else {
                message.error(res.message)
            }
        } catch (e) {
            throw e
        }
    }

    const handleSearchTaxCode = async () => {
        if (!taxCode) {
            setValidateTaxCode({status: 'error', text: 'Vui lòng nhập mã số thuế'})
            return
        }
        try {
            dispatch(toggleShowLoading(true))
            const data = await getCompanyInfoByTaxCode(taxCode)
            dispatch(toggleShowLoading(false))

            if (data && data.Success === true && data.Data) {
                let dataTaxCode = JSON.parse(data.Data)
                setCompanyName(dataTaxCode.companyName)
                setCompanyAddress(dataTaxCode.address)
            } else {
                message.error('Không tìm thấy kết quả doanh nghiệp. Vui lòng kiểm tra lại thông tin đã nhập!')
            }
        } catch (e) {
            throw e
        }
    }

    const handleUpdateInfoTaxCode = async () => {
        if (taxCode && !companyName) {
            setValidateCompanyName({status: 'error', text: 'Vui lòng nhập tên công ty'})
            return
        }
        if (taxCode && !companyAddress) {
            setValidateAddressName({status: 'error', text: 'Vui lòng nhập địa chỉ công ty'})

            return
        }
        try {
            const data = {
                order_id: dataOrder.order_data && dataOrder.order_data.id,
                order_code: dataOrder.order_data && dataOrder.order_data.order_code,
                order_token: dataOrder.order_token_invoice,
                invoice_customer_tax_code: taxCode,
                invoice_company_name: companyName,
                invoice_company_address: companyAddress,
                note
            }
            dispatch(toggleShowLoading(true))
            const result = await updateInfoTaxCode(data)
            dispatch(toggleShowLoading(false))
            if (result && result.statusCode === 200) {
                message.success('Thông tin hóa đơn đã được lưu thành công')
            } else {
                message.error('Thông tin hóa đơn lưu không thành công')
            }
        } catch (e) {
            dispatch(toggleShowLoading(false))
            message.error('Thông tin hóa đơn lưu không thành công')
            throw e
        }
    }

    const checkInfoTaxCode = async () => {
        try {
            const orderToken = dataOrder.order_token_invoice
            const data = await getInvoiceInfo(orderToken)
            if (data && data.statusCode === 200) {
                if (data.data) {
                    setIsCheck(true)
                    setTaxCode(data.data.invoice_customer_tax_code)
                    setCompanyName(data.data.invoice_company_name)
                    setCompanyAddress(data.data.invoice_company_address)
                    if (data.data && data.data.invoice_status !== 0) {
                        setIsDisable(true)
                    }
                    setUserInfo({
                        last_name: data.data.last_name,
                        first_name: data.data.first_name,
                        phone: data.data.phone_number,
                        email: data.data.email
                    })
                    setName(`${data.data.last_name} ${data.data.first_name}`)
                    setEmail(data.data.email)
                    setPhone(data.data.phone_number)
                } else {
                    message.error({content: 'Đơn hàng này không được hỗ trợ xuất hóa đơn'})
                }
            } else {
                message.error({content: 'Đơn hàng này không được hỗ trợ xuất hóa đơn'})
            }
        } catch (e) {
            message.error({content: 'Đơn hàng này không được hỗ trợ xuất hóa đơn'})
            throw e
        }
    }

    return (
        <section className="vnt-checkout text-center" style={{paddingTop: 30, paddingBottom: 30}}>
            {
                !dataWebview?.whitelabel && <Helmet>
                    <script type="text/javascript">
                        {`
                         window.insider_object = {
                            "page": {
                               "type": "Confirmation"
                            }
                         };
                    `}
                    </script>
                </Helmet>
            }
            {dataOrder && dataOrder.order_data && (
                <div className="checkoutStatus__cont container">
                    <h1>Bạn đã đặt Combo thành công</h1>
                    <div className="checkoutStatus__code">
                        <div className="bookingCode">
                            <div className="bookingCode__title">Mã combo</div>
                            <div className="bookingCode__code">{dataOrder.order_data.order_code}</div>
                        </div>
                    </div>
                    <img src="https://statics.vntrip.vn/checkout-success.png" alt=""/>
                    <p>Xác nhận xử lý thành công sẽ gửi qua email {dataOrder.order_data.extra_customer_data.email}</p>
                    <p>
                        Mọi thắc mắc vui lòng liên hệ hotline: <strong>{HOT_LINE}</strong> để được hỗ trợ 24/7
                    </p>
                    <Button
                        type="primary"
                        size="large"
                        className="mt10 btn__request__invoice btn__orange"
                        onClick={checkInfoTaxCode}
                    >
                        Yêu cầu xuất hóa đơn
                    </Button>
                    <Modal title="Thông tin hóa đơn" visible={isCheck} onCancel={() => setIsCheck(false)}>
                        <div className="form__taxCode">
                            <Form>
                                <div className="form__title">
                                    <span>Thông tin về hóa đơn</span>
                                </div>
                                <Form.Item validateStatus={validateTaxCode.status} help={validateTaxCode.text}>
                                    <label>
                                        Mã số thuế <span style={{color: 'red'}}>*</span>
                                    </label>
                                    <div className="input__search">
                                        <Input
                                            disabled={isDisable}
                                            id="taxCode"
                                            onChange={handleChangeTaxCode}
                                            value={taxCode}
                                            size="large"
                                            placeholder="Mã số thuế"
                                        />
                                        <Button
                                            className="btn__orange"
                                            disabled={isDisable}
                                            id="searchTaxCode"
                                            onClick={handleSearchTaxCode}
                                            type="primary"
                                            size="large"
                                        >
                                            Tìm kiếm
                                        </Button>
                                    </div>
                                </Form.Item>
                                <Form.Item validateStatus={validateCompanyName.status} help={validateCompanyName.text}>
                                    <label>
                                        Tên công ty, vui lòng điền đúng theo thông tin đăng ký thuế{' '}
                                        <span style={{color: 'red'}}>*</span>
                                    </label>
                                    <TextArea
                                        disabled={isDisable}
                                        id="companyName"
                                        onChange={handleChangeCompanyName}
                                        value={companyName}
                                        size="large"
                                        placeholder="Tên công ty"
                                    />
                                </Form.Item>
                                <Form.Item validateStatus={validateAddressName.status} help={validateAddressName.text}>
                                    <label>
                                        Địa chỉ công ty, vui lòng điền đúng theo thông tin đăng ký thuế{' '}
                                        <span style={{color: 'red'}}>*</span>
                                    </label>
                                    <TextArea
                                        disabled={isDisable}
                                        id="companyAddress"
                                        onChange={handleChangeCompanyAddress}
                                        value={companyAddress}
                                        size="large"
                                        placeholder="Địa chỉ công ty"
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <label>
                                        Ghi chú <span style={{color: 'red'}}>*</span>
                                    </label>
                                    <TextArea
                                        disabled={isDisable}
                                        id="note"
                                        onChange={handleChangeNote}
                                        value={note}
                                        size="large"
                                        placeholder="Ghi chú"
                                    />
                                </Form.Item>
                                <div className="form__title">
                                    <span> Thông người nhận hóa đơn</span>
                                </div>
                                <Form.Item>
                                    <label>
                                        Họ và tên người nhận hóa đơn <span style={{color: 'red'}}>*</span>
                                    </label>
                                    <Input
                                        disabled={userInfo.last_name}
                                        onChange={e => setName(e.target.value)}
                                        value={name}
                                        size="large"
                                        placeholder="Họ và tên"
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <label>
                                        Số điện thoại người nhận <span style={{color: 'red'}}>*</span>
                                    </label>
                                    <Input
                                        disabled={userInfo.phone}
                                        onChange={e => setPhone(e.target.value)}
                                        value={phone}
                                        size="large"
                                        placeholder="Số điện thoại"
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <label>
                                        Email nhận hóa đơn <span style={{color: 'red'}}>*</span>
                                    </label>
                                    <Input
                                        disabled={userInfo.email}
                                        onChange={e => setEmail(e.target.value)}
                                        value={email}
                                        size="large"
                                        placeholder="Email"
                                    />
                                </Form.Item>
                                <Button
                                    disabled={isDisable}
                                    onClick={handleUpdateInfoTaxCode}
                                    type="primary"
                                    size="large"
                                    id="btn__send__request"
                                    className="btn__send__request btn__orange"
                                >
                                    Gửi yêu cầu
                                </Button>
                            </Form>
                        </div>
                    </Modal>
                </div>
            )}
        </section>
    )
}
export default withRouter(connect(state => {
    return {
        dataWebview: state.homeReducer.dataWebview,
    }
})(CheckoutSuccess));
