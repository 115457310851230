export const getDataUTMInfo = (publisher_data, type)=> {
    if(publisher_data && publisher_data.publisher){
        if (publisher_data.publisher.hasOwnProperty('utm_source') && publisher_data.publisher["utm_source"] !== "") {
            return  {
                'utm_source': publisher_data.publisher.utm_source,
                'utm_campaign': publisher_data.publisher.utm_campaign || '',
                'utm_content': publisher_data.publisher.utm_content || '',
                'utm_medium': publisher_data.publisher.utm_medium || '',
                'utm_agent': publisher_data.publisher.utm_agent || '',
                'utm_team': publisher_data.publisher.utm_team || '',
                'publisherid': publisher_data.publisher.publisherid || '',
                'click_id': publisher_data.publisher.click_id || '',
                'click_time': publisher_data.publisher.click_time || '',
            };
        }
    }
    else {
        return null
    }
}

