import ApiMicroService from "./api-request-micro";

export const getDataDetail = async (br_id) => {
    try {
        const results = await ApiMicroService.get(`/v3-booking/booking-requests/${br_id}`);
        return results.data
    } catch (e) {
        throw e
    }
};
export const getListPaymentMethod = async (data)=>{
    try {
        const results = await ApiMicroService.get(`/payment-service/fe/payment-method`,{params:data})
        return  results.data
    } catch (e) {
        throw e
    }
};

export const getListCouponCode = async ()=>{
    try {
        const results = await ApiMicroService.get(`campaign/combo/coupon-codes`)
        return  results.data
    } catch (e) {
        throw e
    }
};

export const updatePaymentMethod = async (data,br_id)=>{
    try {
        const results =await ApiMicroService.patch(`/v3-booking/booking-requests/${br_id}/choose-payment-method/`,data);
        return results.data
    }catch (e) {
        throw e
    }
}

export const addOrder = async (booking_request_id,data)=>{
    try {
        const results =await ApiMicroService.post(`order-hotel/order/booking-request-fe/${booking_request_id}`,data);
        return results.data
    }catch (e) {
        throw e
    }
};

export const checkStatus = async (data)=>{
    try {
        const results = await ApiMicroService.post(`order-hotel/order/holding-status`,data);
        return results.data
    }catch (e) {
        throw e
    }
};

export const getOrderByTransactionId = async transactionId => {
    try {
        const results = await ApiMicroService.get('order-hotel/order/' + transactionId + '/by-transaction')
        return results.data
    } catch (e) {
        throw e
    }
}

export const getBankAccount = async () => {
    try {
        const results = await ApiMicroService.get('/v2-common/bank-account')
        return results.data
    } catch (e) {
        throw e
    }
}

export const transactionConfirmation = async transaction_id => {
    try {
        const results = await ApiMicroService.get('/payment-service/payment/v2/' + transaction_id)
        return results.data
    } catch (e) {
        throw e
    }
}

export const applyCouponForBR = async ( booking_request_id, data ) => {
    try {
        const results = await ApiMicroService.patch(`v3-booking/booking-requests/${booking_request_id}/apply-coupon/`, data)
        return results.data
    } catch (e) {
        throw e
    }
}

// tạo br
export const createBookingForCombo = async (data) => {
    try {
        const results = await ApiMicroService.post(`frontend-service/combo/create-booking`, data);
        return results.data
    } catch (e) {
        throw e
    }
}


// tạo br
export const updateBookingRequest = async (data) => {
    try {
        const results = await ApiMicroService.post(`/v3-booking/fe/checkout/collect-info`, data);
        return results.data
    } catch (e) {
        throw e
    }
}



