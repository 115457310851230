import React from 'react';
import {isMobileScreen} from "../../utils/hotel-func";

function SearchAirPort(
    {
        label,
        handleClickDeparture,
        openDeparture,
        title,
        handleClickItem,
        type,
        listAirPort,
        defaultAirPort,
        handleChangeInput,
        handleKeyup,
        inputValue,
        endPoint,
        startPoint
    }) {
    const renderAirPort = (listAirPort, type) => {
        return listAirPort.map((item, index) => {
            return (
                <li key={index}
                    onClick={() => handleClickItem(item, type)}>
                    <a>{item.province_name} ({item.code})</a>
                </li>
            )
        })
    };
    return (
        <div className="form-group">
            <label>{label}</label>
            <div className={`formBox__input ${type === 'departure' ? 'formBox_departure' : 'formBox_destination'}`}>
                <input
                    type="text"
                    placeholder={title}
                    readOnly={isMobileScreen()}
                    className="form-control inputIcon btnPlace"
                    onClick={() => handleClickDeparture(!openDeparture)}
                    onKeyUp={(e) => handleKeyup(e, type)}
                    value={inputValue}
                    onChange={handleChangeInput}
                />
            </div>
            <div className={`suggestion ${openDeparture ? 'open' : ''}`} ref={defaultAirPort}>
                <div className="suggestion__main">
                    <div className="suggestion__header">
                        <input
                            type="text"
                            placeholder="Nhập điểm đến"
                            onKeyUp={(e) => handleKeyup(e, type)}
                            onChange={handleChangeInput}
                            value={inputValue}
                            className="form-control"
                        />
                        <button type="button" onClick={() => handleClickDeparture(false)}
                                className="suggestion__close">×
                        </button>
                    </div>
                    <div className="suggestion__body">
                        <div className="suggestion__title"><span>{label}</span></div>
                        <ul className="suggestion__list">
                            {
                                listAirPort &&
                                renderAirPort(listAirPort, type)
                            }
                        </ul>
                    </div>
                </div>
            </div>
            {type === 'departure' ?
                <svg width={12} height={16} viewBox="0 0 12 16" fill="none"
                     xmlns="http://www.w3.org/2000/svg" className="form-icon">
                    <path
                        d="M5 7.9V12H7V7.9C8.7 7.5 10 5.9 10 4C10 1.8 8.2 0 6 0C3.8 0 2 1.8 2 4C2 5.9 3.3 7.4 5 7.9Z"
                        fill="#BFBFBF"/>
                    <path d="M0 14H12V16H0V14Z" fill="#BFBFBF"/>
                </svg>
                :
                <svg width={14} height={16} viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg"
                     className="form-icon">
                    <path
                        d="M7 0C3.1 0 0 3.1 0 7C0 8.9 0.7 10.7 2.1 12C2.2 12.1 6.2 15.7 6.3 15.8C6.7 16.1 7.3 16.1 7.6 15.8C7.7 15.7 11.8 12.1 11.8 12C13.2 10.7 13.9 8.9 13.9 7C14 3.1 10.9 0 7 0ZM7 9C5.9 9 5 8.1 5 7C5 5.9 5.9 5 7 5C8.1 5 9 5.9 9 7C9 8.1 8.1 9 7 9Z"
                        fill="#BFBFBF"/>
                </svg>


            }

        </div>
    );

}

export default SearchAirPort;