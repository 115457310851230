import React from 'react';

function FinishComBoFlight({ status }) {
    return (
        <div className="comboFlight">
            <div className="title1">
                <h4>Thông tin chuyến bay</h4>
            </div>
            <div className="comboFligt__cont">
                <div className="comboFlight__item">
                    <ul>
                        <li>
                            <p>Điểm khởi hành</p>
                            <p className="semibold">Hà Nội</p>
                        </li>
                        <li>
                            <p>Ngày khởi hành</p>
                            <p className="semibold">16/06/2020</p>
                        </li>
                        <li>
                            <p>Giờ bay</p>
                            <p className="semibold">09:00 (sáng)</p>
                        </li>
                    </ul>
                </div>
                <div className="comboFlight__item">
                    <ul>
                        <li>
                            <p>Điểm đến</p>
                            <p className="semibold">Đà Nẵng</p>
                        </li>
                        <li>
                            <p>Ngày về</p>
                            <p className="semibold">18/06/2020</p>
                        </li>
                        <li>
                            <p>Giờ bay</p>
                            <p className="semibold">14:00 (chiều)</p>
                        </li>
                    </ul>
                </div>
            </div>
            {/*<div className="comboFlight__change"><a href="#"><span>Thay đổi</span></a>*/}
            {/*    <p>Lưu ý: nếu thay đổi có thể bạn sẽ mất chỗ hiện tại.</p>*/}
            {/*</div>*/}
        </div>
    );

}

export default FinishComBoFlight;
