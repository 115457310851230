import React from 'react';
import DisplayPrice from "../common/DisplayPrice";

function SearchListPrice({handleChoosePrice, dataListCombo, priceForOnePeople, itemPrice}) {
    const renderPrice = (price1, price2) => {
        const price = price1 - price2
        if (price < 0) {
            return (
                <div>
                     + <DisplayPrice price={Math.abs(price)}/> /người
                </div>
            )

        } else {
            return (
                <div style={{color:"#8C8C8C"}}>
                     - <DisplayPrice price={Math.abs(price)}/> /người
                </div>
            )
        }
    }
    return (
        <div className="box-search-item mt5">
            <div className="combo-item-content">
                <span>Combo {itemPrice && itemPrice.days} ngày {itemPrice && itemPrice.nights} đêm:</span>
                <span className="combo-price">
                    <DisplayPrice price={itemPrice && priceForOnePeople(itemPrice)}/>
                    <span>/người</span>
                </span>
            </div>
            <ul className="list-combo">
                {
                    dataListCombo && itemPrice && dataListCombo.map((res, index) => {
                        return (
                            <li className="combo-item" key={index}>
                                <div className="vnt-radio">
                                    <input onChange={(e) => handleChoosePrice(e, res)} id={`combo_${res.id}`}
                                           type="radio" checked={itemPrice.id === res.id} defaultValue="combo"
                                           name="filterType" style={{opacity: 0}} className="method-item"/>
                                    <label
                                        htmlFor={`combo_${res.id}`}><span>Combo {res.days} ngày {res.nights} đêm</span></label>
                                </div>
                                {itemPrice.id === res.id ? <span className={`combo-price ${itemPrice.id === res.id ? 'span-red' : 'span-blue'}`}>
                                    <DisplayPrice price={priceForOnePeople(res)}/> /người
                                    </span> :
                                    <span className={`combo-price ${itemPrice.id === res.id ? 'span-red' : 'span-blue'}`}>
                                        {renderPrice(priceForOnePeople(itemPrice), priceForOnePeople(res))}
                                    </span>
                                }

                            </li>
                        )
                    })
                }
            </ul>
        </div>
    );

}

export default SearchListPrice;
