import ApiMicroService from "./api-request-micro";

export const updatePassenger = async (data,suggestionId) => {
    try {
        const results = await ApiMicroService.put(`flight-service/v1/booking-request/suggestion/${suggestionId}/passengers/fe`,data);
        return results.data
    } catch (e) {
        throw e
    }
};
///v1/confirm-reservation
export const updateReservation = async (data) => {
    try {
        const results = await ApiMicroService.post(`flight-service/v1/confirm-reservation/fe`,data);
        return results.data
    } catch (e) {
        throw e
    }
}
export const getSurchargeLevels = async (deal_id) => {
    try {
        const results = await ApiMicroService.get(`v2-hotel/deals/${deal_id}/surcharge-levels/`,{
            headers:{
                "Authorization": "Bearer $UvlsUAk7J7Vm$nW09d8fFCgp/ONtPh6#XZjZ(5rU33rHyk9#CIK7rx7FL9Do"
            }
        });
        return results.data
    } catch (e) {
        throw e
    }
}