import { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getOrderByTransactionId, transactionConfirmation } from '../../api-services/payment-method-service'
import { PATH_PAY_SUCCESS, PATH_PAY_FAIL } from '../../utils/payment-func'
import { PAYMENT_METHODS } from '../../const/checkout-const'
import * as queryString from 'query-string'
import { toggleShowLoading } from '../../redux/actions/home-actions'

let intervalGetOrder

function TransactionConfirm({ match, location, dispatch, history }) {
    // const [paygate, setPaygate] = React.useState(null);
    // const [transactionId, setTransactionId] = React.useState(null);

    useEffect(() => {
        async function fetchData(transaction_id) {
            dispatch(toggleShowLoading(true))
            const res = await transactionConfirmation(transaction_id)
            if (res.status === 'success') {
                const confirm = res.data
                if (confirm) {
                    intervalGetOrder = intervalGetPaymentStatus(transaction_id, history, dispatch)

                } else {
                    history.push(`${PATH_PAY_FAIL}?transaction_id=${transaction_id}&status=tran_error`)
                }
            } else {
                history.push(`${PATH_PAY_FAIL}?transaction_id=${transaction_id}&status=tran_error`)
            }
        }

        const query = queryString.parse(location.search)
        const _paygate = match.params.paygate
        let transaction_id = ''
        switch (_paygate) {
            case '123pay':
                transaction_id = query['transactionID']
                break
            case 'momo':
                transaction_id = query['orderId']
                break
            case 'bankplus':
                transaction_id = query['merchant_trans_id']
                break
            case 'airpay':
                transaction_id = query['transaction_id']
                break
            case 'lotte':
                transaction_id = String(query['transaction_id'])
                break
            default:
                break
        }

        if (transaction_id) {
            fetchData(transaction_id)
        }
    }, [])

    return null
}

const ACCEPTED_METHODS = [
    PAYMENT_METHODS['PAYMENT_METHOD_BANK_TRANSFER'],
    PAYMENT_METHODS['PAYMENT_METHOD_CREDIT_CARD'],
    PAYMENT_METHODS['PAYMENT_METHOD_PAY_AT_HOTEL']
]

function intervalGetPaymentStatus(transactionId, history, dispatch) {
    let countInterval = 0
    return window.setInterval(function() {
        countInterval++
        if (countInterval < 6) {
            getOrderByTransactionId(transactionId)
                .then(result => {
                    if (result.status === 'success') {
                        const paymentMethod = result.data.payment_method
                        if (ACCEPTED_METHODS.includes(paymentMethod)) {
                            // bank transfer, creadit card, pay at hotel
                            window.clearInterval(intervalGetOrder)
                            history.push(PATH_PAY_SUCCESS + '?transaction_id=' + transactionId)
                        } else {
                            // thanh toan online
                            const orderStatus = result.data.order_status
                            if (orderStatus === 'order_pending_payment') {
                            } else {
                                // Order không đang ở trạng thái payment pending => không chay interval
                                window.clearInterval(intervalGetOrder)
                                dispatch(toggleShowLoading(false))
                                if (orderStatus === 'order_success' || orderStatus === 'order_pending_supplier') {
                                    history.push(PATH_PAY_SUCCESS + '?transaction_id=' + transactionId)

                                } else {
                                    history.push(PATH_PAY_FAIL + '?transaction_id=' + transactionId + '&status=fail')
                                }
                            }
                        }
                    } else {
                        window.clearInterval(intervalGetOrder)
                        dispatch(toggleShowLoading(false))
                        history.push(PATH_PAY_FAIL + '?transaction_id=' + transactionId + '&status=error')
                    }
                })
                .catch(err => {
                    window.clearInterval(intervalGetOrder)
                    dispatch(toggleShowLoading(false))
                    history.push(PATH_PAY_FAIL + '?transaction_id=' + transactionId + '&status=error')
                })
        } else {
            dispatch(toggleShowLoading(false))
            window.clearInterval(intervalGetOrder)
            history.push(PATH_PAY_FAIL + '?transaction_id=' + transactionId + '&status=error')
        }
    }, 3000)
}

export default connect()(withRouter(TransactionConfirm))
