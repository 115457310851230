import React from 'react';
import PaymentMethodItem from "../../component/payment-method/PaymentMethodItem";

function PaymentMethodItemContainer({name,logo,description,paymentMethodItem,handleChangePaymentMethod,id,coupons, isShowLimit, minLotte, totalPrice}) {
    return (
       <PaymentMethodItem
           paymentMethodItem={paymentMethodItem}
           handleChangePaymentMethod={handleChangePaymentMethod}
           name={name}
           id={id}
           coupons={coupons}
           logo={logo}
           description={description}
           isShowLimit={isShowLimit}
           minLotte={minLotte}
           totalPrice={totalPrice}
       />
    );

}

export default PaymentMethodItemContainer;