import { air_provider, domesticAirport, json_airport } from "../const/airport";
import { FL_DEP, FL_RET } from "../const/flight";
import moment from "moment";
import { DOMESTIC_AIRPORT } from '../const/search-combo'

export const showLogoAirProvider = (provider, operating) => {
  if (provider) {
    if (provider === "VN") {
      if (operating === "BL") {
        return "https://statics.vntrip.vn/images/logo-airline/pacific-vna.png";
      } else {
        return "https://statics.vntrip.vn/images/logo-airline/vietnamairline.png";
      }
    } else if (provider === "VJ") {
      return "https://statics.vntrip.vn/images/logo-airline/vietjet.png";
    } else if (["JQ", "BL"].includes(provider)) {
      return "https://statics.vntrip.vn/images/logo-airline/pacific.png";
    } else if (provider === "QH") {
      return "https://statics.vntrip.vn/images/logo-airline/bamboo.png";
    } else {
      return (
        "https://statics.vntrip.vn/logo/airline/" +
        provider.toLocaleLowerCase() +
        ".gif"
      );
    }
  }
};

export const convertProviderCodeToName = code => {
  const obj = air_provider[code];
  if (obj) {
    return obj["name"];
  }
};

export const convertProviderIdToCode = id => {
  const obj = domesticAirport.filter(res => res.province_id === id);
  if (obj) {
    return obj[0]["code"];
  }
};

export const convertAirportCodeToCityName = (code, lang = "vi") => {
  const obj = json_airport[code];
  if (obj) {
    return obj[lang === "en" ? "cityNameEn" : "cityNameVi"];
  }
};

export const airportIsDomestic = code => {
  const obj = json_airport[code];
  return obj && obj["countryCode"] === "VN";
};

export const groupFlightByNumber = listFareData => {
  let _departure = [];
  let _return = [];
  listFareData.forEach(fareData => {
    if (fareData && Array.isArray(fareData.listFlight)) {
      fareData.listFlight.forEach(flight => {
        flight.airlineLogo = showLogoAirProvider(
          flight.airline,
          flight.operating
        );
        flight.airlineName = convertProviderCodeToName(flight.airline);
        flight.startCityName = convertAirportCodeToCityName(flight.startPoint);
        flight.startAirportName = convertAirportCodeToName(flight.startPoint);
        flight.endCityName = convertAirportCodeToCityName(flight.endPoint);
        flight.endAirportName = convertAirportCodeToName(flight.endPoint);
        flight.durationToText = convertMinsToHrsMins(flight.duration);
        flight.listSegment.forEach(segment => {
          segment.airlineLogo = showLogoAirProvider(
            segment.airline,
            flight.operating
          );
          segment.airlineName = convertProviderCodeToName(segment.airline);
          segment.startCityName = convertAirportCodeToCityName(
            segment.startPoint
          );
          segment.startAirportName = convertAirportCodeToName(
            segment.startPoint
          );
          segment.endCityName = convertAirportCodeToCityName(segment.endPoint);
          segment.endAirportName = convertAirportCodeToName(segment.endPoint);
          segment.durationToText = convertMinsToHrsMins(segment.duration);
        });
        const flightNumberAndTime = joinFlightNumberAndTime(flight);

        if (fareData.leg === 0) {
          // group chuyen di
          _departure.push({
            ...fareData,
            flightItem: { ...flight, flightNumberAndTime },
            flightNumberAndTime
          });
        } else if (fareData.leg === 1) {
          // group chuyen ve
          _return.push({
            ...fareData,
            flightItem: { ...flight, flightNumberAndTime },
            flightNumberAndTime
          });
        }
      });
    }
  });

  // // group flight by flightNumberAndTime
  // let groupDep: any = {}
  // console.log('>>>>_departure>>>>_departure', _departure)
  // _departure.forEach((item) => {
  //     const flightItem = item.flightItem
  //     const key = item.flightNumberAndTime
  //     // delete item.listFlight
  //     // delete item.flightItem
  //     if (groupDep.hasOwnProperty(key)) {
  //         groupDep[key] = {
  //             ...flightItem,
  //             listSeatClass: [...groupDep[key]['listSeatClass'], item],
  //         }
  //     } else {
  //         groupDep[key] = { ...flightItem, listSeatClass: [{ ...item, active: true }] }
  //     }
  // })
  // let groupRet: any = {}
  // _return.forEach((item) => {
  //     const flightItem = item.flightItem
  //     const key = item.flightNumberAndTime
  //     delete item.listFlight
  //     // delete item.flightItem
  //     if (groupRet.hasOwnProperty(key)) {
  //         groupRet[key] = {
  //             ...flightItem,
  //             flightNumberAndTime: key,
  //             listSeatClass: [...groupRet[key]['listSeatClass'], item],
  //         }
  //     } else {
  //         groupRet[key] = { ...flightItem, listSeatClass: [{ ...item, active: true }] }
  //     }
  // })
  let obj = {};
  obj[FL_DEP] = Object.values(_departure);
  obj[FL_RET] = Object.values(_return);
  console.log('-----obj-----', obj)
  return obj;
};

export const convertAirportCodeToName = (code, lang = "vi") => {
  const obj = json_airport[code];
  if (obj) {
    return obj[lang === "en" ? "airportNameEn" : "airportNameVi"];
  }
};

export const convertMinsToHrsMins = mins => {
  let hour = Math.floor(mins / 60);
  let minute = mins % 60;
  hour = Number(hour) < 10 ? "0" + hour : hour;
  minute = Number(minute) < 10 ? "0" + minute : minute;
  return `${hour}h:${minute}m`;
};

export const joinFlightNumberAndTime = ({
  flightNumber,
  startDate,
  endDate
}) => {
  return `${flightNumber}_${moment(startDate).format("YYYYMMDDHHmm")}_${moment(
    endDate
  ).format("YYYYMMDDHHmm")}`;
};

export const formatDate2 = time => {
  return (
    moment(time)
      .locale("vi")
      .format("dd") +
    ", " +
    moment(time).format("DD") +
    " Th" +
    moment(time).format("MM YYYY")
  );
};

export const formatDate3 = time => {
  return (
      moment(time)
          .locale("vi")
          .format("dd") +
      ", " +
      moment(time).format("DD") +
      " Tháng " +
      moment(time).format("MM YYYY")
  );
};


export const getNamePointFromCode2 = (code) => {
  const airPort = DOMESTIC_AIRPORT.filter((air) => air?.code === code)
  return airPort?.[0]?.province_name || ''
}