import {TYPE_PAYMENT_METHOD} from "../../const/payment-method-const";

export const setAlertMessage = (mesasge) => {
    return {
        type: TYPE_PAYMENT_METHOD.SET_ALERT_MESSAGE,
        alertMessage: mesasge
    }
}

export const setLinkPopup = (link) => {
    return {
        type: TYPE_PAYMENT_METHOD.SET_ALERT_LINK,
        linkRoute: link
    }
}

export const setRefreshPage = time => ({
    type: TYPE_PAYMENT_METHOD.SET_REFRESH_PAGE,
    refreshPage: time
})

export const setPaymentMethod = paymentMethod => ({
    type: TYPE_PAYMENT_METHOD.SET_PAYMENT_METHOD,
    paymentMethod: paymentMethod
})

export const setTextAlertButton = textButton => ({
    type: TYPE_PAYMENT_METHOD.SET_TEXT_ALERT_BUTTON,
    textButton: textButton
})
