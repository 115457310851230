import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {toggleSidebarMobile} from "../../redux/actions/common";
import {useUserInfo} from "./ProvideAuth";
import {getFullName} from "../../utils/common-func";
import {Button} from "antd";
import {useLocation} from "react-router";


const MenuMobile = () => {
    const dispatch = useDispatch()
    const userInfo = useUserInfo()
    const { pathname } = useLocation()
    const isOpenSideBar = useSelector((state) => state.commonReducer.isOpenSideBar)

    const closeSideBar = () => {
        dispatch(toggleSidebarMobile(false))
        document.body.classList.remove('noScroll')
    }
    const openPopupSignIn = () => {
        window.location.href = `${process.env.REACT_APP_SSO_URL}/login?callbackUrl=${encodeURIComponent(
            window.location.href
        )}`
    }
    const openPopupSignUp = () => {
        window.location.href = `${process.env.REACT_APP_SSO_URL}/register?callbackUrl=${encodeURIComponent(
            window.location.href
        )}`
    }

    return (
        <div className={`menuMobile ${isOpenSideBar ? 'open' : ''}`}>
            <div className="menuMobile__cont">
                <div className="menuMobile__header">
                    <div className="menuMobile__avatar">
                        <img src="https://statics.vntrip.vn/images/default-avatar.png" alt="avatar" />
                    </div>
                    <div className="menuMobile__right">
                        {/* menuMobile__btn show before login*/}
                        {!userInfo && ['/'].includes(pathname) &&(
                            <div className="menuMobile__btn">
                                <Button
                                    type="button"
                                    className="btn btn_outlineOrange btn_sm btn_login mr15 ml15"
                                    onClick={openPopupSignIn}
                                >
                                    Đăng nhập
                                </Button>
                                <Button
                                    type="button"
                                    className="btn btn_orange btn_sm btn_login"
                                    onClick={openPopupSignUp}
                                >
                                    <span>Đăng ký</span>
                                </Button>
                            </div>
                        )}

                        {/* menuMobile__info show after login*/}
                        {userInfo && (
                            <div className="menuMobile__info">
                                <p className="p2">{getFullName(userInfo?.first_name, userInfo?.last_name)}</p>
                            </div>
                        )}
                    </div>
                </div>
                <div className="menuMobile__body">
                    <ul className="menuMobile__list">
                    </ul>
                </div>
            </div>
            <div
                className="menuMobile__backdrop"
                onClick={closeSideBar}
                onKeyUp={closeSideBar}
                role={'button'}
                tabIndex={0}
            />
        </div>
    )
}

export default MenuMobile
