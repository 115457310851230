import React from "react";
import { IconDieuKien, IconPhuThu, IconTronGoi } from "../../const/icon-const";
import HomeBoxSearchContainer from "../../containers/home/HomeBoxSearchContainer";
import {
  isMobileScreen,
  isWideScreen,
  isDesktopScreen,
  isTableScreen
} from "../../utils/hotel-func";
import { renderImageBanner } from "../../utils/home-util";
import HomeArticles from "./HomeArticles";
import HomeAchievement from "./HomeAchievement";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import HomeTutorial from "./homeTutorial";
import HomeQuestion from "./HomeQuestion";
import { convertPartnerPhone } from '../../utils/common-func'
import { HOT_LINE } from '../../const/common-const'

function Home({ dataWebview }) {
  return (
    <section className="step1">
      <div className="banner mb15">
        {(isTableScreen() || isMobileScreen()) && renderImageBanner("xs")}
        {isWideScreen() && renderImageBanner("lg")}
        {isDesktopScreen() && renderImageBanner("md")}
        <a
            href={`tel:${
                dataWebview.whitelabel && dataWebview.partnerPhone
                    ? convertPartnerPhone(dataWebview.partnerPhone)
                    : HOT_LINE
                }`}
            className="header_hotline"
        >
          <svg
              width={12}
              height={12}
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 384 384"
              xmlSpace="preserve"
          >
            <path d="M353.188,252.052c-23.51,0-46.594-3.677-68.469-10.906c-10.719-3.656-23.896-0.302-30.438,6.417l-43.177,32.594                  c-50.073-26.729-80.917-57.563-107.281-107.26l31.635-42.052c8.219-8.208,11.167-20.198,7.635-31.448                  c-7.26-21.99-10.948-45.063-10.948-68.583C132.146,13.823,118.323,0,101.333,0H30.813C13.823,0,0,13.823,0,30.813                  C0,225.563,158.438,384,353.188,384c16.99,0,30.813-13.823,30.813-30.813v-70.323C384,265.875,370.177,252.052,353.188,252.052z" />
          </svg>
          <span>
                                    {dataWebview.whitelabel && dataWebview.partnerPhone
                                        ? dataWebview.partnerPhone
                                        : HOT_LINE}
                                </span>
        </a>
        <div className="container">
          <HomeBoxSearchContainer />
        </div>
      </div>
      <div className="container">
        <div className="list-tip-combo">
          <div className="tip-combo-item">
            <IconTronGoi />
            <h2>Trọn gói bao gồm:</h2>
            <div className="list-text">
              <h3>- Đêm nghỉ tại khách sạn tiêu chuẩn.</h3>
              <h3>- Vé máy bay khứ hồi kèm theo 7kg hành lý xách tay</h3>
              <h3>- Phòng không bao gồm bữa sáng.</h3>
            </div>
          </div>
          <div className="tip-combo-item">
            <IconPhuThu />
            <h2>Phụ thu:</h2>
            <div className="list-text">
              <h3>- Phụ thu trẻ em dưới 2 tuổi: 249,000đ.</h3>
              <h3>- Trẻ em trên 2 tuổi: mua combo như người lớn.</h3>
            </div>
          </div>
          <div className="tip-combo-item">
            <IconDieuKien />
            <h2>Điều kiện sử dụng:</h2>
            <div className="list-text">
              <h3>- Chỉ áp dụng cho khách hàng thanh toán ngay.</h3>
              <h3>- Combo này không hoàn, hủy trong bất kỳ trường hợp nào.</h3>
              <h3>
                - Không áp dụng đồng thời cùng các chương trình khuyến mãi khác.
              </h3>
            </div>
          </div>
        </div>
      </div>
      <HomeTutorial />
      <HomeQuestion dataWebview={dataWebview} />
      {!dataWebview.whitelabel && <HomeAchievement />}
      {!dataWebview.whitelabel && <HomeArticles />}
    </section>
  );
}

export default withRouter(
  connect(state => {
    return {
      dataWebview: state.homeReducer.dataWebview || {}
    };
  })(Home)
);
