import React, {useEffect} from 'react'
import {withRouter} from "react-router-dom";
import * as queryString from 'query-string'
import {Link} from 'react-router-dom'
import {IconWaitingConfirm} from '../../const/icon-const'
import {getOrderByTransactionId} from "../../api-services/payment-method-service";
import {toggleShowLoading} from "../../redux/actions/home-actions";
import {PATH_PAY_FAIL, PATH_PAY_SUCCESS} from "../../utils/payment-func";
import {connect} from "react-redux";

let intervalGetOrder

function CheckoutWaitingConfirm({location, history, dispatch}) {
    useEffect(() => {
        const query = queryString.parse(location.search)
        const transaction_id = query.transaction_id
        fetchData(transaction_id)

    }, [])

    const fetchData = async (transaction_id) => {
        try {
            intervalGetOrder = intervalGetPaymentStatus(transaction_id, history, dispatch)
        } catch (e) {
            throw e
        }
    }


    function intervalGetPaymentStatus(transactionId, history, dispatch) {
        let countInterval = 0
        return window.setInterval(function () {
            countInterval++
            if (countInterval < 20) {
                getOrderByTransactionId(transactionId)
                    .then(result => {
                        if (result.status === 'success') {
                            // thanh toan online
                            const orderStatus = result.data.order_status
                            if (orderStatus === 'order_pending_payment') {
                            } else {
                                // Order không đang ở trạng thái payment pending => không chay interval
                                window.clearInterval(intervalGetOrder)
                                dispatch(toggleShowLoading(false))
                                if (orderStatus === 'order_success' || orderStatus === 'order_pending_supplier') {
                                    history.push(PATH_PAY_SUCCESS + '?transaction_id=' + transactionId)

                                } else {
                                    history.push(PATH_PAY_FAIL + '?transaction_id=' + transactionId + '&status=fail')
                                }
                            }

                        } else {
                            window.clearInterval(intervalGetOrder)
                            dispatch(toggleShowLoading(false))
                            history.push(PATH_PAY_FAIL + '?transaction_id=' + transactionId + '&status=error')
                        }
                    })
                    .catch(err => {
                        window.clearInterval(intervalGetOrder)
                        dispatch(toggleShowLoading(false))
                        history.push(PATH_PAY_FAIL + '?transaction_id=' + transactionId + '&status=error')
                    })
            } else {
                window.clearInterval(intervalGetOrder)
            }
        }, 30000)
    }


    return (
        <section className="vntContent comboWaitingConfirm">
            <div className="container">
                <div className="vntBox">
                    <div className="vntBox__checkout">
                        <IconWaitingConfirm/>
                        <p className="p1">
                            Hoàn thành thanh toán.Chờ xác nhận từ VNTRIP...
                        </p>
                        <p className="p2">
                            VNTRIP đang tiến hành xác nhận đơn hàng và sẽ gửi thông báo đến bạn trong thời gian sớm
                            nhất.
                        </p>
                        {/*<p className="p3">Gửi thông tin đặt phòng thành công cho E-mail khác.</p>*/}
                        <div className="btnGroup">
                            {/*<a href="#" className="btn btn_lg">*/}
                            {/*    Thêm email*/}
                            {/*</a>*/}
                            <Link to={'/'} className="btn btn_outline btn_lg">
                                Quay về trang chủ
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    )
}

export default connect()(withRouter(CheckoutWaitingConfirm))
