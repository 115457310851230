import React  from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
    PATH_PAY_FAIL,
    PATH_PAY_SUCCESS,
    PATH_PAY_WAITING_CONFIRM
} from '../../utils/payment-func'
import CheckoutSuccess from './CheckoutSuccess'
import CheckoutFail from './CheckoutFail'
import CheckoutWaitingConfirm from "../../component/checkoutResult/CheckoutWaitingConfirm";

function CheckOutResultContainer({ location }) {

    switch (location.pathname) {
        case PATH_PAY_SUCCESS:
            return (
                <CheckoutSuccess/>
            )
        case PATH_PAY_FAIL:
            return (
                <CheckoutFail/>
            )
        case PATH_PAY_WAITING_CONFIRM:
            return (
                <CheckoutWaitingConfirm/>
            )
        default:
            return null
    }
}

const mapStateToProps = function(state) {
    return {}
}

export default connect(mapStateToProps)(withRouter(CheckOutResultContainer))

