import {EXPRESSTIME, EXPRESSTIMEHOUR} from "../const/Home";
import React from "react";

export const copyToClipboard = text => {
    const $body = document.getElementsByTagName('body')[0]
    const $tempInput = document.createElement('INPUT')
    $body.appendChild($tempInput)
    $tempInput.setAttribute('value', text)
    $tempInput.select()
    document.execCommand('copy')
    $body.removeChild($tempInput)
}

export const saveAirRouterSession = air_router => {
    return window.localStorage.setItem('air_router', JSON.stringify(air_router))
}

export const getAirRouterSession = () => {
    const airRouter = window.localStorage.getItem('air_router')
    return airRouter ? JSON.parse(airRouter) : null
}
export const getSession = function (sessionName) {
    let Value = JSON.parse(window.localStorage.getItem(sessionName));
    if (Value) {
        let expirationDate = new Date(Value.expirationDate);
        if (expirationDate > new Date()) {
            return Value
        } else {
            window.localStorage.removeItem(sessionName)
        }
    }
    return false;
};

export const getSessionForFlight = function (sessionName) {
    let Value = JSON.parse(window.localStorage.getItem(sessionName));
    if (Value) {
        let expirationDate = new Date(Value.expirationDate);
        if (expirationDate > new Date()) {
            if(Value.data) {
                Value.data.map((res,index) => {
                    if(new Date(Value.expirationDate) < new Date() ){
                        Value.data.splice(index,1)
                    }
                })
            }
            return Value
        } else {
            window.localStorage.removeItem(sessionName)
            return false
        }
    }
    return true;
};

export const setSessionForFlight=function (sessionName,sessionValue, expirationInMin ){
    let expirationDate = new Date(new Date().getTime() + (EXPRESSTIMEHOUR * expirationInMin));
    sessionValue.expirationDate = expirationDate.toISOString();
    localStorage.setItem(sessionName, JSON.stringify(sessionValue));
};


export const setSession=function (sessionName,sessionValue, expirationInMin ){
    let expirationDate = new Date(new Date().getTime() + (EXPRESSTIME * expirationInMin));
    sessionValue.expirationDate = expirationDate.toISOString();
    localStorage.setItem(sessionName, JSON.stringify(sessionValue));
};

export const renderImageBanner = (screen) => {
    if(screen === 'md') {
       return <img src={`${process.env.REACT_APP_URL_STATIC_IMAGE}/uploads/flights/20220416_065860_banner_desktop.png`} alt="banner" className="banner-desktop" />
    }
    if(screen === 'lg') {
        return <img src={`${process.env.REACT_APP_URL_STATIC_IMAGE}/uploads/flights/20220416_065860_banner_desktop.png`} className="banner-desktop" alt="banner"/>
    }
    if(screen === 'xs') {
        return   <img src={`${process.env.REACT_APP_URL_STATIC_IMAGE}/uploads/flights/20220419_280160_banner_mobile.png`} alt="banner" className="banner-mobile"/>

    }
}