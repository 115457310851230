import ApiMicroService from "./api-request-micro";

export const getListAirPorts = async () => {
    try {
        const results = await ApiMicroService.get('/v1-flight/atadi-air-ports');
        return results.data
    } catch (e) {
        throw e
    }
};

export const getListComBo = async () => {
    try {
        const results = await ApiMicroService.get('/v2-hotel/deals?deal_type=dynamic-combo&combo_type=combo-donggia');
        return results.data
    } catch (e) {
        throw e
    }
};

export const getListReturnFollowDeparture= async () => {
    try {
        const results = await ApiMicroService.get('/v1-flight/air-routes');
        return results.data
    } catch (e) {
        throw e
    }
};

export const createBookingRequest = async (data)=>{
    try {
        const results = await ApiMicroService.post(`/v3-booking/booking-requests/combo`,data);
        return results.data
    }catch (e) {
        throw e
    }
}


export const saveRedis = async (data) => {
    try {
        const results = await ApiMicroService.post('v2-hotel/redis/combo', data);
        return results.data
    } catch (e) {
        throw e
    }
};


export const getRedis = async (token) => {
    try {
        const results = await ApiMicroService.get(`v2-hotel/redis/combo/${token}`, );
        return results.data
    } catch (e) {
        throw e
    }
};

export const updateRedis = async (data, token) => {
    try {
        const results = await ApiMicroService.put(`v2-hotel/redis/combo/${token}`, data);
        return results.data
    } catch (e) {
        throw e
    }
};

export const searchListFlight = async (data) => {
    try {
        const results = await ApiMicroService.post(`/flight-service/v1/combo-vin/search`, data, {
            headers: {
                'content-type': 'application/json',
                account: JSON.stringify({ business_account_id: null }),
            },
        })
        return results.data
    } catch (e) {
        throw e
    }
}