import React, { useEffect, useState } from 'react'
import SearchListPrice from '../../component/home/SearchListPrice'
import { getListComBo } from '../../api-services/search-combo'
import { getBookerData } from '../../utils/common-func'
import {useLocation} from "react-router";
import * as queryString from "query-string";

function SearchListPriceContainer({ handleChoosePrice, setItemPrice, itemPrice }) {
    const { pathname, search } = useLocation()

    const [dataListCombo, setDataListCombo] = useState([])
    useEffect(() => {
        const query = queryString.parse(search)
        const fetchListCombo = async () => {
            try {
                let comboId = query?.comboId || false
                const data = await getListComBo()
                if (data && data.status === 'success') {
                    setDataListCombo(data.data)
                    const bookerData = getBookerData()
                    if(comboId) {
                        data.data.forEach((combo) => {
                            if (combo.id === Number(comboId)) {
                                setItemPrice(combo)
                            } else {
                                if (combo.nights === 2) {
                                    setItemPrice(combo)
                                }
                            }

                        })
                    }
                    else if (bookerData && bookerData.itemPrice) {
                        if (bookerData.itemPrice.id === data.data[0].id) {
                            setItemPrice(data.data[0])
                        }
                        if (bookerData.itemPrice.id === data.data[1].id) {
                            setItemPrice(data.data[1])
                        } else {
                            setItemPrice(data.data[0])
                        }
                    } else {
                        if (Array.isArray(data.data) && data.data.length > 0) {
                            data.data.forEach((combo) => {
                                if (combo.nights === 2) {
                                    setItemPrice(combo)
                                }
                            })
                        }
                    }
                }
            } catch (e) {
                throw e
            }
        }
        fetchListCombo()
    }, [pathname, search])

    const priceForOnePeople = item => {
        const adultNumber = item.flights[0].adult_number
        const sellPrice = item.price_deal[0].sell_price
        return Number(sellPrice / adultNumber).toFixed(0)
    }
    return (
        <SearchListPrice
            handleChoosePrice={handleChoosePrice}
            itemPrice={itemPrice}
            dataListCombo={dataListCombo}
            priceForOnePeople={priceForOnePeople}
        />
    )
}

export default SearchListPriceContainer
