import React, { useEffect, useState } from 'react'
import * as queryString from 'query-string'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { aToU } from '../../utils/payment-func'

function CheckOutZaloPay({ location }) {
    const [urlQrCode, setUrlQrCode] = useState('')
    useEffect(() => {
        const query = queryString.parse(location.search)
        const code = query.code
        const urlCode = aToU(code)
        setUrlQrCode(urlCode)
    }, [])
    return (
        <div className="container">
            <div className="text-center" style={{ marginTop: '30px', color: '#008fe5' }}>
                <h4>Vui lòng mở app ZaloPay quét mã QR Code để thanh toán!</h4>
            </div>
            <div className="text-center" style={{ marginTop: '30px' }}>
                <img src={urlQrCode} alt="qr code"/>
            </div>
        </div>

    )

}

export default withRouter(connect(state => {
    return {}

})(CheckOutZaloPay))
