import React, { useState } from 'react'
import { Button, Form, Input, Modal, message } from 'antd'
import Cookies from 'js-cookie'
import { savePhoneNotMember } from '../../const/user'
import {
    getLimitLotte,
    getSlotId,
    verificationRequestPhone,
    verificationRequestPhoneNotMember,
    verifyPhone,
    verifyPhoneNotMember
} from '../../api-services/user'
import { convertPhone, getBookerData, isPhoneValid } from '../../utils/common-func'
import { VERIFICATION_FEATURES } from '../../const/common-const'
import EnterOtp from '../common/EnterOtp'
const ModalConfirmPhone = (props) => {
    const [verificationId, setVerificationId] = useState(0)
    const [phone, setPhone] = useState(props.presetPhone || '')
    const [validatePhone, setValidatePhone] = useState(null)
    const [popupToShow, setPopupToShow] = useState('ENTER_PHONE')
    const [loading, setLoading] = useState(false)
    const accessToken = Cookies.get('accessToken')

    const onChangePhone = value => {
        setPhone(value.trim())
        setValidatePhone(null)
    }

    const onClickRequestVerifyPhone = async () => {
        let booker = getBookerData()
        if (!phone) {
            setValidatePhone({ status: 'error', text: 'Vui lòng nhập số điện thoại' })
            return false
        }
        if (!isPhoneValid(phone)) {
            setValidatePhone({ status: 'error', text: 'notification:Số điện thoại không đúng định dạng' })
            return false
        }
        setLoading(true)
        let res
        if (booker && accessToken) {
            res = await verificationRequestPhone({
                feature: VERIFICATION_FEATURES.PROFILE_VERIFIED,
                phone: convertPhone(phone)
            })
        } else {
            res = await verificationRequestPhoneNotMember({
                feature: VERIFICATION_FEATURES.PROFILE_VERIFIED,
                phone: convertPhone(phone)
            })
        }
        setLoading(false)
        if (res.status === 'error') {
            message.error(res.message)
        } else {
            setVerificationId(res.data.verificationId)
            setPopupToShow('ENTER_OTP')
        }
    }

    const onConfirmOtpSuccess = async () => {
        try {
            let booker = props.bookerData || getBookerData()

            let res
            if (booker && accessToken) {
                res = await verifyPhone({ verificationId, phone: convertPhone(phone) })
            } else {
                res = await verifyPhoneNotMember({ verificationId, phone: convertPhone(phone) })
                if (res.status !== 'error') {
                    const phone_not_member = convertPhone(phone).replace('+84', '0')
                    await getSlotId(phone_not_member)
                    await getLimitLotte(booker)
                }
            }
            if (res.status === 'error') {
                message.error(res.message)
                return
            }
            props.handleContinue()
            savePhoneNotMember(convertPhone(phone))
            props.onClose()
            message.success('Xác thực số điện thoại thành công, vui lòng tiếp tục')
        }catch (e) {
            throw e
        }
    }

    return (
        <>
            {popupToShow === 'ENTER_PHONE' && (
                <Modal
                    footer={null}
                    visible={true}
                    maskClosable={false}
                    title={'Xác thực số điện thoại'}
                    onCancel={props.onClose}
                >
                    <div>
                        <Form size={'large'}>
                            <Form.Item validateStatus={validatePhone?.status} help={validatePhone?.text}>
                                <Input
                                    placeholder={'Số điện thoại'}
                                    value={phone}
                                    onChange={event => onChangePhone(event.target.value)}
                                    onKeyPress={async e => {
                                        if (e.charCode === 13) {
                                            await onClickRequestVerifyPhone()
                                        }
                                    }}
                                />
                            </Form.Item>
                            <Button
                                type="primary"
                                block={true}
                                style={{
                                    textTransform: 'uppercase',
                                    fontWeight: 'bold'
                                }}
                                onClick={onClickRequestVerifyPhone}
                                loading={loading}
                            >
                                {'Tiếp tục'}
                            </Button>
                        </Form>
                    </div>
                </Modal>
            )}
            {popupToShow === 'ENTER_OTP' && (
                <EnterOtp
                    verificationId={verificationId}
                    type="phone"
                    feature={VERIFICATION_FEATURES.PROFILE_VERIFIED}
                    value={convertPhone(phone)}
                    onClose={props.onClose}
                    onResend={verificationId => {
                        setVerificationId(verificationId)
                    }}
                    onConfirmSuccess={onConfirmOtpSuccess}
                />
            )}
        </>
    )
}

export default ModalConfirmPhone
