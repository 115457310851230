import React from 'react'
import {withRouter} from 'react-router'
import {PhoneOutlined, MailOutlined, EnvironmentOutlined} from '@ant-design/icons'
import {connect} from 'react-redux'
import moment from 'moment'
import {HOT_LINE} from "../../const/common-const";
import {Select} from 'antd';
import {isMobileScreen, isTableScreen} from "../../utils/hotel-func";

const {Option} = Select;

function Footer({location, dataWebview}) {
    return (
        <footer className="footer">
            <div className="container">
                {
                    !dataWebview.whitelabel &&

                    <div className="footer__content">
                        <div className="footer__top">
                            <div className="footer__top__item">
                                <div className="logo__image">
                                    <img src={`${process.env.REACT_APP_URL_STATIC_IMAGE}/images/svg/logo-vntrip-black.svg`}/>
                                </div>
                                <div className="info">
                                    <p className="company__name">Công ty TNHH CÔNG NGHỆ VNTRIP</p>
                                    <p>
                                        <EnvironmentOutlined/>
                                        <span>
                                        Số 10/55 Thụy Khuê, Phường Thuỵ Khuê, Quận Tây Hồ, Thành phố Hà Nội, Việt Nam
                                    </span>
                                    </p>
                                    <p>
                                        <a style={{margin: 0}} href="mailto:cs@vntrip.vn">
                                            <MailOutlined/>
                                            <span>cs@vntrip.vn</span>
                                        </a>
                                    </p>
                                    <p>
                                        <a style={{margin: 0}} href={`tel:${HOT_LINE}`}>
                                            <PhoneOutlined/>
                                            <span> {HOT_LINE}</span>
                                        </a>
                                    </p>
                                </div>
                            </div>
                            <div className="footer__top__item">
                                <div className="title">Về vntrip</div>
                                {
                                    isMobileScreen() ?
                                        <Select defaultValue={'ve-vntrip'} style={{width: "100%", marginBottom: 15}}>
                                            <Option value="ve-vntrip"><a href="https://www.vntrip.vn/ve-vntrip">Về
                                                vntrip</a></Option>
                                            <Option value="lien-he"><a href="https://www.vntrip.vn/lien-he">Liên hệ</a></Option>
                                            <Option value="dieu-khoan-su-dung"><a
                                                href="https://www.vntrip.vn/dieu-khoan-su-dung">Điều khoản sử
                                                dụng</a></Option>
                                            <Option value="chinh-sach-bao-mat"><a
                                                href="https://www.vntrip.vn/chinh-sach-bao-mat">Chính sách bảo
                                                mật</a></Option>
                                            <Option value="blog-du-lich"><a href="https://www.vntrip.vn/cam-nang/">Blog
                                                du lịch</a></Option>
                                            <Option value="tuyen-dung"><a href="https://hr.vntrip.vn/">Tuyển
                                                dụng</a></Option>
                                        </Select> :
                                        <ul>
                                            <li><a href="https://www.vntrip.vn/ve-vntrip">Về vntrip</a></li>
                                            <li><a href="https://www.vntrip.vn/lien-he">Liên hệ</a></li>
                                            <li><a href="https://www.vntrip.vn/dieu-khoan-su-dung">Điều khoản sử
                                                dụng</a></li>
                                            <li><a href="https://www.vntrip.vn/chinh-sach-bao-mat">Chính sách bảo
                                                mật</a></li>
                                            <li><a href="https://www.vntrip.vn/cam-nang/">Blog du lịch</a></li>
                                            <li><a href="https://hr.vntrip.vn/">Tuyển dụng</a></li>
                                            {/*<li><a href="https://www.vntrip.vn/member">Hoàn tiền</a></li>*/}
                                        </ul>
                                }

                            </div>
                            <div className="footer__top__item">
                                <div className="title">Hợp tác cùng Vntrip</div>
                                {
                                    isMobileScreen() ?
                                        <Select defaultValue={'hop-tac-dai-ly'} style={{width: "100%", marginBottom: 15}}>
                                            <Option value="hop-tac-dai-ly"><a href="https://partner.vntrip.vn/login">Hợp tác đại lý</a></Option>
                                        </Select> :
                                        <ul>
                                            {/*<li><a href="https://www.vntrip.vn/specialhotels">Hợp tác khách sạn</a></li>*/}
                                            <li><a href="https://partner.vntrip.vn/login">Hợp tác đại lý</a></li>
                                        </ul>

                                }

                            </div>
                            <div className="footer__top__item">
                                <div className="title">Sản phẩm</div>
                                {
                                    isMobileScreen() ?
                                        <Select defaultValue={'hop-tac-dai-ly'} style={{width: "100%", marginBottom: 15}}>
                                            <Option value="hop-tac-dai-ly"><a href="https://www.vntrip.vn/ve-may-bay">Vé máy bay</a></Option>
                                            <Option value="hop-tac-dai-ly"><a href="https://www.vntrip.vn/khach-san">Khách sạn</a></Option>
                                            <Option value="hop-tac-dai-ly"><a href="https://donggia.vntrip.vn/">Combo tiết kiệm</a></Option>
                                            <Option value="hop-tac-dai-ly"><a href="https://quickstay.vntrip.vn/">Quickstay</a></Option>
                                        </Select> :
                                        <ul>
                                            <li><a href="https://www.vntrip.vn/ve-may-bay">Vé máy bay</a></li>
                                            <li><a href="https://www.vntrip.vn/khach-san">Khách sạn</a></li>
                                            <li><a href="https://donggia.vntrip.vn/">Combo tiết kiệm</a></li>
                                            <li><a href="https://quickstay.vntrip.vn/">Quickstay</a></li>
                                        </ul>

                                }
                            </div>
                        </div>
                        <div className="footer__middle">
                            <div className="footer__middle__image">
                                {
                                    location.pathname === '/' || location.pathname === '/search' || location.pathname === '/uudailotte' ?
                                        <div className="footer__bocongthuong d-flex">

                                            <a href="http://online.gov.vn/Home/WebDetails/65609">
                                                <img src="https://statics.vntrip.vn/images/logo_bocongthuong_blue.png"
                                                     alt="bo cong thuong"/>
                                            </a>
                                            <a href="http://online.gov.vn/Home/WebDetails/24227">
                                                <img src="https://statics.vntrip.vn/images/logo_bocongthuong_red.png"
                                                     alt="bo cong thuong"/>
                                            </a>
                                        </div> :
                                        <div className="footer__bocongthuong d-flex">
                                            <a style={{cursor: "inherit"}}>
                                                <img src="https://statics.vntrip.vn/images/logo_bocongthuong_blue.png"
                                                     alt="bo cong thuong"/>
                                            </a>
                                            <a style={{cursor: "inherit"}}>
                                                <img src="https://statics.vntrip.vn/images/logo_bocongthuong_red.png"
                                                     alt="bo cong thuong"/>
                                            </a>

                                        </div>

                                }
                            </div>
                        </div>
                        <div className="footer__bottom">
                            <p>© Copyright {moment().year()} – Công ty TNHH CÔNG NGHỆ VNTRIP</p>
                            <p>Giấy phép kinh doanh dịch vụ lữ hành Nội địa: 01-0213/2022/SDL-GP LHNĐ</p>
                        </div>
                    </div>
                }
            </div>
        </footer>
    )
}

export default withRouter(
    connect(state => {
        return {
            showLoading: state.homeReducer.showLoading,
            dataWebview: state.homeReducer.dataWebview || {}
        }
    })(Footer)
)
