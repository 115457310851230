export const EXPRESSTIME = 86400000

export const EXPRESSTIMEHOUR = 3600000

export const HOME_TYPE = {
    SHOW_LOADING: 'SHOW_LOADING',
    SET_LOADING_MESSAGE: 'SET_LOADING_MESSAGE',
    SET_LOADING_MESSAGE_SLIDE: 'SET_LOADING_MESSAGE_SLIDE',
    SHOW_LOADING_COMPONENT: 'SHOW_LOADING_COMPONENT',
    SET_VALUE_PROGRESS: 'SET_VALUE_PROGRESS',
    SHOW_LOADING_GIU_CHO: 'SHOW_LOADING_GIU_CHO',
    LOGO_FOR_PARTNER: 'LOGO_FOR_PARTNER',
    HEADER_PARTNER: 'HEADER_PARTNER',
    DATA_WEBVIEW_CONFIG: 'DATA_WEBVIEW_CONFIG',
    HOTEL_SELECTED: 'HOTEL_SELECTED'
}

export const HOME_COMMENTS = [
    {
        title: "Cảm nhận từ khách hàng",
        name: "Anh Đức Huy",
        imageAvatar: "https://statics.vntrip.vn/images/donggia/IMG_0181.JPG-e1591596619612.jpg",
        content: "Nhà mình có con nhỏ, và một phần vì công việc của 2 vợ chồng khá bận rộn nên khó tìm được một chuyến du lịch ưng ý. Nhưng có 1 người bạn đã đặt giúp mình combo này của Vntrip và mình rất hài lòng bởi sự tư vấn nhiệt tình, giá cả hợp lý và rất thuận tiện cho gia đình nhỏ của mình."
    },
    {
        title: "Cảm nhận từ khách hàng",
        name: "Anh Minh Đức",
        imageAvatar: "https://statics.vntrip.vn/images/donggia/lADPAuoR61yYc1_NBMrNBMg_1224_1226.jpg",
        content: "Hồi chuẩn bị đám cưới mình tất bật lo việc này việc kia không có cả thời gian nghĩ đến tuần trăng mật. Bình thường săn vé rồi đặt phòng cũng mất kha khá thời gian, nên mình nản ngay. Hôm ấy thấy có combo trăng mật ở Đà Nẵng thế là vợ chồng mình book ngay! Nhanh - gọn - lẹ, đỡ phải nghĩ nhiều."
    },
    {
        title: "Cảm nhận từ khách hàng",
        name: "Bạn Hồng Vân",
        imageAvatar: "https://statics.vntrip.vn/images/donggia/IMG_0236.JPG-e1591592314279.jpg",
        content: "Mình được giao nhiệm vụ lên kế hoạch đi du lịch cho cả nhà, mà phải tính đủ thứ từ chỗ chơi cho trẻ em, khách sạn gần biển, đặt vé máy bay… đủ thứ phải nghĩ. Rất may mình đã có Vntrip hỗ trợ mọi thứ từ A-Z, chỉ việc lo mua đồ đi chơi."
    }
]