import React from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import moment from "moment";
import "moment/min/locales.min";
import "react-datepicker/dist/react-datepicker.css";
import vi from "date-fns/locale/vi";
registerLocale("vi", vi);

function PassengerInfo({
  dataRedis,
  listPassenger,
  changeGender,
  handOnChange,
  handleChangeBirthDay
}) {
  const ExampleCustomInput = ({ value, onClick }) => (
    <input
      className="form-control w-100"
      placeholder="Chọn ngày sinh"
      value={value}
      onClick={onClick}
      readOnly
    />
  );
  // const dataFlight = dataBooking &&  dataBooking.flight && dataBooking.flight.filter(res => res.is_return) || []
  return (
    <>
      <h2 className={"semibold title "}>Thông tin khách hàng</h2>
      <div className="passengerInfo">
        {/*{dataFlight && (*/}
        <div className="passengerInfo__cont">
          {dataRedis && Array.isArray(listPassenger) &&
            listPassenger.map((res, index) => {
              return (
                <div
                  key={index}
                  className={`passengerInfo__row ${
                    res.type === "ADT" ? "row-2" : "row-2"
                  }`}
                >
                  <div id={`genderId_${index}`} className={`form-group select-gender genderId_${index}`}>
                    <label>
                      Giới tính
                      <span style={{ color: "red", padding: "5px" }}>*</span>:
                    </label>
                    <select
                      id={`genderId_${index}`}
                      className="form-control w-100 text-center"
                      onChange={e => changeGender(e, index)}
                      value={res.gender}
                    >
                      <option disabled value={0}>
                        --
                      </option>
                      <option value={true}>Nam</option>
                      <option value={false}>Nữ</option>
                    </select>
                  </div>
                  <div className={`form-group passengerId_${index}`}>
                    {res && res.type === "ADT" && (
                      <label>
                        Tên người lớn {index + 1}
                        <span style={{ color: "red", padding: "5px" }}>
                          *
                        </span>:{" "}
                      </label>
                    )}
                    {res && res.type === "CHD" && (
                      <label>
                        Tên trẻ em {index + 1}
                        <span style={{ color: "red", padding: "5px" }}>*</span>:
                      </label>
                    )}
                    {res && res.type === "INF" && (
                      <label>
                        Tên trẻ sơ sinh {index + 1}
                        <span style={{ color: "red", padding: "5px" }}>*</span>:
                      </label>
                    )}
                    <input
                      type="text"
                      placeholder="Nguyen Văn B"
                      onChange={e => handOnChange(e, index)}
                      className="form-control"
                      id={`passengerId_${index}`}
                      defaultValue={
                        res.lastName && res.firstName
                          ? `${res.lastName} ${res.firstName}`
                          : ""
                      }
                      style={{ textTransform: "uppercase" }}
                    />
                    <span style={{color:'#8C8C8C'}}>như trên CMND (không dấu)</span>
                  </div>
                  {res && res.type === "ADT" && (
                    <div className={`form-group birthdayId_${index}`}>
                      <label>Ngày sinh:</label>

                      <DatePicker
                        onChange={date =>
                          handleChangeBirthDay(date, index, res.type)
                        }
                        selected={
                          res.birthday
                            ? moment(res.birthday, "DDMMYYYY").toDate()
                            : null
                        }
                        placeholderText="Chọn ngày sinh"
                        dateFormat="dd-MM-yyyy"
                        timeFormat="HH:mm"
                        className="form-control"
                        timeIntervals={10}
                        timeCaption="time"
                        showMonthDropdown
                        showYearDropdown
                        customInput={<ExampleCustomInput />}
                        maxDate={moment(dataRedis?.departDate)
                          .add(-12, "years")
                          .toDate()}
                        locale="vi"
                        dropdownMode="select"
                      />
                    </div>
                  )}

                  {res && res.type === "CHD" && (
                    <div className={`form-group birthdayId_${index}`}>
                      <label>
                        Ngày sinh
                        <span style={{ color: "red", padding: "5px" }}>*</span>:
                      </label>
                      <DatePicker
                        onChange={date =>
                          handleChangeBirthDay(date, index, res.type)
                        }
                        selected={
                          res.birthday
                            ? moment(res.birthday, "DDMMYYYY").toDate()
                            : null
                        }
                        placeholderText="Chọn ngày sinh"
                        dateFormat="dd-MM-yyyy"
                        timeFormat="HH:mm"
                        className="form-control"
                        timeIntervals={10}
                        timeCaption="time"
                        showMonthDropdown
                        customInput={<ExampleCustomInput />}
                        showYearDropdown
                        minDate={moment(dataRedis?.departDate)
                          .add(-12, "years")
                          .toDate()}
                        maxDate={moment(dataRedis?.departDate)
                          .add(-2, "years")
                          .toDate()}
                        locale="vi"
                        dropdownMode="select"
                      />
                    </div>
                  )}
                  {res && res.type === "INF" && (
                    <div className={`form-group`}>
                      <label>
                        Ngày sinh
                        <span style={{ color: "red", padding: "5px" }}>*</span>:
                      </label>
                      <DatePicker
                        onChange={date =>
                          handleChangeBirthDay(date, index, res.type)
                        }
                        selected={
                          res.birthday
                            ? moment(res.birthday, "DDMMYYYY").toDate()
                            : null
                        }
                        placeholderText="Chọn ngày sinh"
                        dateFormat="dd-MM-yyyy"
                        timeFormat="HH:mm"
                        className="form-control"
                        customInput={<ExampleCustomInput />}
                        timeIntervals={10}
                        timeCaption="time"
                        showMonthDropdown
                        showYearDropdown
                        minDate={moment(dataRedis?.departDate)
                          .add(-2, "years")
                          .toDate()}
                        maxDate={moment(dataRedis?.departDate).toDate()}
                        locale="vi"
                        dropdownMode="select"
                      />
                    </div>
                  )}
                </div>
              );
            })}

          <div className="passengerInfo__note">
            <p>
              Để xuất vé máy bay, bạn hãy điền chính xác thông tin của các hành
              khách, bao gồm người lớn, trẻ em và em bé. Lưu ý: Điền theo chuẩn
              NGUYEN VAN ANH (Họ Đệm Tên).
            </p>
          </div>
        </div>
        {/*)}*/}
      </div>
    </>
  );
}

export default PassengerInfo;
