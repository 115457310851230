import React from "react";
import SearchPassengersContainer from "../../containers/home/SearchPassengersContainer";
import SearchListPriceContainer from "../../containers/home/SearchListPriceContainer";
import SearchAirPortContainer from "../../containers/home/SearchAirPortContainer";
import { Button, Modal } from "antd";
import ModalDateFlight from "./modal/ModalDateFlight";

function HomeBoxSearch({
  adultCount,
  childCount,
  infantCount,
  departure,
  destination,
  inputValueDepart,
  inputValueReturn,
  itemPrice,
  isSetEndPoint,
  isLoading,
  isModal,
  redisToken,
  dataBr,
  dealFlightConfig,
  // Function
  setIsModal,
  setIsSetEndPoint,
  setInputValueReturn,
  setInputValueDepart,
  setDeparture,
  setDesProvinceId,
  setDepartProvinceId,
  setItemPrice,
  setDestination,
  handleChoosePassengers,
  handleContinue,
  handleChoosePrice
}) {
  return (
    <div className="form-box-search">
      <h1 className="title-search">Nhanh tay săn gói du lịch đồng giá!</h1>
      <div className="box-search-content">
        <div className="box-search-item box-search-item-2">
          <SearchAirPortContainer
            departure={departure}
            setValueDeparture={setDeparture}
            setProvinceId={setDepartProvinceId}
            title={"Điểm đi"}
            type={"departure"}
            label={"Chọn điểm đi"}
            inputValue={inputValueDepart}
            setInputValue={setInputValueDepart}
            setInputValueReturn={setInputValueReturn}
            isSetEndPoint={isSetEndPoint}
            setIsSetEndPoint={setIsSetEndPoint}
          />
          <SearchAirPortContainer
            destination={destination}
            setProvinceId={setDesProvinceId}
            title={"Điểm đến"}
            type={"destination"}
            setValueDeparture={setDestination}
            label={"Chọn điểm đến"}
            inputValue={inputValueReturn}
            setInputValue={setInputValueReturn}
            isSetEndPoint={isSetEndPoint}
            setIsSetEndPoint={setIsSetEndPoint}
          />
        </div>
        <SearchPassengersContainer
          adultCount={adultCount}
          childCount={childCount}
          infantCount={infantCount}
          handleChoosePassengers={handleChoosePassengers}
        />
        {/*<div className="box-search-item">*/}
        {/*    <div className="form-group">*/}
        {/*        <label>Người đặt</label>*/}
        {/*        <div className="formBox__input formBox_fullName">*/}
        {/*            <input*/}
        {/*                type="text"*/}
        {/*                placeholder={` ${isMobileScreen() ? 'Người đặt - Ví dụ: Nguyen Van A' : 'Ví dụ: Nguyen Van A' }`}*/}
        {/*                onChange={(e)=>setFullName(e.target.value)}*/}
        {/*                value={fullName}*/}
        {/*                className="form-control"*/}
        {/*            />*/}
        {/*        </div>*/}
        {/*        <svg width={14} height={16} viewBox="0 0 14 16" fill="none"*/}
        {/*             xmlns="http://www.w3.org/2000/svg" className="form-icon">*/}
        {/*            <path*/}
        {/*                d="M7 9C4.8 9 3 7.2 3 5V4C3 1.8 4.8 0 7 0C9.2 0 11 1.8 11 4V5C11 7.2 9.2 9 7 9Z"*/}
        {/*                fill="#BFBFBF"/>*/}
        {/*            <path d="M9 11H5C2.2 11 0 13.2 0 16H14C14 13.2 11.8 11 9 11Z" fill="#BFBFBF"/>*/}
        {/*        </svg>*/}
        {/*    </div>*/}
        {/*</div>*/}
        {/*<div className="box-search-item box-search-item-2 box-info-passenger">*/}
        {/*    <div className="form-group">*/}
        {/*        <label>Email</label>*/}
        {/*        <div className="formBox__input formBox_email">*/}
        {/*            <input*/}
        {/*                type="text"*/}
        {/*                placeholder={` ${isMobileScreen() ? 'Email - Ví dụ: nguyenvana@gmail.com' : 'Ví dụ: nguyenvana@gmail.com' }`}*/}
        {/*                id="formBox_email"*/}
        {/*                className="form-control"*/}
        {/*                onChange={(e) => setEmail(e.target.value)}*/}
        {/*                value={email}*/}
        {/*            />*/}
        {/*        </div>*/}

        {/*        <svg width={16} height={14} viewBox="0 0 16 14" fill="none"*/}
        {/*             xmlns="http://www.w3.org/2000/svg" className="form-icon">*/}
        {/*            <path*/}
        {/*                d="M14 5.883V12H2V5.82L0 4.695V13C0 13.2652 0.105357 13.5196 0.292893 13.7071C0.48043 13.8946 0.734784 14 1 14H15C15.2652 14 15.5196 13.8946 15.7071 13.7071C15.8946 13.5196 16 13.2652 16 13V4.783L14 5.883Z"*/}
        {/*                fill="#BFBFBF"/>*/}
        {/*            <path d="M15 0H1C0.4 0 0 0.4 0 1V2.4L8 6.9L16 2.5V1C16 0.4 15.6 0 15 0Z"*/}
        {/*                  fill="#BFBFBF"/>*/}
        {/*        </svg>*/}
        {/*    </div>*/}
        {/*    <div className="form-group">*/}
        {/*        <label>Số di động</label>*/}
        {/*        <div className="formBox__input formBox_phone">*/}
        {/*            <input*/}
        {/*                type="text"*/}
        {/*                placeholder={` ${isMobileScreen() ? 'Số di động - Ví dụ: 01234567891' : 'Ví dụ: 01234567891' }`}*/}
        {/*                onChange={(e)=>setPhone(e.target.value)}*/}
        {/*                value={phone}*/}
        {/*                className="form-control"*/}
        {/*            />*/}
        {/*        </div>*/}
        {/*        <svg width={16} height={16} viewBox="0 0 16 16" fill="none"*/}
        {/*             xmlns="http://www.w3.org/2000/svg" className="form-icon">*/}
        {/*            <path*/}
        {/*                d="M15.285 12.305L12.707 9.711C12.6142 9.61774 12.504 9.5437 12.3826 9.49311C12.2612 9.44253 12.1309 9.4164 11.9994 9.41621C11.8679 9.41602 11.7376 9.44179 11.616 9.49203C11.4945 9.54227 11.384 9.616 11.291 9.709L9 12L4 7L6.294 4.706C6.4816 4.5186 6.5871 4.26437 6.58729 3.9992C6.58747 3.73404 6.48234 3.47966 6.295 3.292L3.715 0.707998C3.62213 0.615021 3.51184 0.541262 3.39044 0.490938C3.26904 0.440614 3.13892 0.414711 3.0075 0.414711C2.87608 0.414711 2.74596 0.440614 2.62456 0.490938C2.50316 0.541262 2.39287 0.615021 2.3 0.707998L0.004 3.003L0 3C0 10.18 5.82 16 13 16L15.283 13.717C15.4701 13.5298 15.5754 13.2761 15.5758 13.0114C15.5762 12.7467 15.4716 12.4927 15.285 12.305Z"*/}
        {/*                fill="#BFBFBF"/>*/}
        {/*        </svg>*/}
        {/*    </div>*/}
        {/*</div>*/}
        <SearchListPriceContainer
          setItemPrice={setItemPrice}
          itemPrice={itemPrice}
          handleChoosePrice={handleChoosePrice}
        />
        <Button
          isLoading={isLoading}
          onClick={handleContinue}
          className={`btn btn-san-ve`}
        >
          Săn ngay
        </Button>
        {isModal && (
          <ModalDateFlight
            isModal={isModal}
            setIsModal={setIsModal}
            redisToken={redisToken}
            dataBr={dataBr}
            dealFlightConfig={dealFlightConfig}
          />
        )}
      </div>
    </div>
  );
}

export default HomeBoxSearch;
