import React, { useEffect, useState } from "react";
import { Button, Col, Row, Spin, Steps } from "antd";
import BookerData from "./BookerData";
import ReviewCombo from "../check-out1/review-combo/reviewCombo";
import { getRedis } from "../../../api-services/search-combo";
import { useHistory, useLocation, useParams } from "react-router";
import PassengerInfo from "../../hotel-review/PassengerInfo";
import {
  change_alias,
  cutFirstName,
  cutLastName,
  getListPassenger,
  saveListPassenger
} from "../../../utils/review-func";
import moment from "moment";
import {
  getBookerDataCheckOut,
  isEmailValid,
  isPhoneValid,
  scrollToElement,
  setBookerDataCheckOut
} from "../../../utils/common-func";
import HotelItem from "../check-out1/hotel-info/HotelItem";
import FlightItemSelected from "../check-out1/flight-info/FlightItemSelected";
import { FL_DEP, FL_RET } from "../../../const/flight";
import { toggleShowLoading } from "../../../redux/actions/home-actions";
import { setDataTip } from "../../../utils/hotel-func";
import {
  checkStatus,
  createBookingForCombo
} from "../../../api-services/payment-method-service";
import { useDispatch, useSelector } from 'react-redux'
import {
  getSurchargeLevels,
  updateReservation
} from "../../../api-services/hotel-review";
import { getDealFlights } from "../../../api-services/search-flight";
import { PRICE_INFANT } from "../../../const/common-const";
import { toggleShowHotelInfo } from "../../../redux/actions/hotel-list";
import DisplayPrice from "../../common/DisplayPrice";

const { Step } = Steps;

const CheckOutStep2 = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname, search } = useLocation();
  const [validateBookerData, setValidateFormBookerData] = useState({
    name: { status: "success", text: "" },
    email: { status: "success", text: "" },
    phone: { status: "success", text: "" }
  });
  const [bookerData, setBookerData] = useState({
    phone: "",
    email: "",
    gender: 1,
    name: ""
  });
  const [dataRedis, setDataRedis] = useState(null);
  const [token, setToken] = useState("");
  const [listPassenger, setListPassenger] = useState([]);
  const [dealFlightConfig, setDealFlightConfig] = useState([]);
  const [upgradeHotel, setUpgradeHotel] = useState([]);
  const [typeHotel, setTypeHotel] = useState("STANDARD");
  const [dataPersonSurcharge, setDataPersonSurcharge] = useState({
    PRICE_DELUXE: 0,
    PRICE_STANDARD: 0,
    PRICE_LUXURY: 0,
    PRICE_INFANT_LUXURY: 0,
    PRICE_INFANT_STANDARD: 0,
    PRICE_INFANT_DELUXE: 0
  });
  const [dataReviewCombo, setDataReviewCombo] = useState({
    from: "",
    to: "",
    departDate: "",
    nights: 0,
    days: 0,
    countAdult: 0,
    countChild: 0,
    countInfant: 0,
    upgradeDeparture: 0,
    upgradeReturn: 0,
    upgradeHotel: 0,
    unitPrice: 0,
    totalPrice: 0
  });
  const {
    isShowHotelInfo
  } = useSelector(state => {
    return {
      isShowHotelInfo: state.hotelListReducer.isShowHotelInfo
    };
  });

  useEffect(() => {
    const token = params?.token;
    const fetchDataRedis = async token => {
      try {
        const result = await getRedis(token);
        if (result?.status === "success" && result?.data) {
          setDataRedis(result?.data);
          setToken(params?.token);
          if (result?.data?.departData && result?.data?.returnData) {
            let _listPassenger = [],
              adult = result?.data?.count_adult,
              child = result?.data.count_child,
              infant = result?.data.count_infant,
              total = adult + child + infant;
            if (total > 0) {
              for (let i = 0; i < total; i++) {
                _listPassenger.push({
                  index: i,
                  isOpenDp: false,
                  type: "",
                  gender: 0,
                  lastName: "",
                  firstName: "",
                  listBaggage: []
                });
                if (i < adult) _listPassenger[i].type = "ADT";
                if (i < adult + child && i >= adult) {
                  _listPassenger[i].type = "CHD";
                  _listPassenger[i].birthday = "";
                }
                if (i >= adult + child) {
                  _listPassenger[i].type = "INF";
                  _listPassenger[i].birthday = "";
                }
              }
            }
            if (getListPassenger()) {
              _listPassenger.map(res => {
                getListPassenger().map(req => {
                  if (res.type === req.type && res.index === req.index) {
                    _listPassenger[res.index] = req;
                  }
                });
              });
            }
            setListPassenger(_listPassenger);
            // set booker data
            if (getBookerDataCheckOut()) {
              setBookerData(getBookerDataCheckOut());
            }
            setUpgradeHotel(result?.data?.upgradeHotel || 0);
            setTypeHotel(result?.data?.typeHotel || "STANDARD");
            // data review
            let _dataReview = {
              from: result?.data?.from,
              to: result?.data?.to,
              nights: result?.data?.nights,
              days: result?.data?.days,
              countAdult: result?.data?.count_adult,
              countChild: result?.data?.count_child,
              countInfant: result?.data?.count_infant,
              departDate: result?.data?.departDate
            };
            setDataReviewCombo(_dataReview);
          }
        }
      } catch (e) {
        throw e;
      }
    };
    fetchDataRedis(token);
  }, [pathname, search]);

  // get deal flight config
  useEffect(() => {
    const fetchDealFlight = async dealId => {
      try {
        const result = await getDealFlights(dealId);
        if (result?.status === 200) {
          setDealFlightConfig(result?.data);
        }
      } catch (e) {
        throw e;
      }
    };
    if (dataRedis) {
      fetchDealFlight(dataRedis?.deal_id);
    }
  }, [dataRedis]);

  // get SurchargeLevels hotel
  useEffect(() => {
    const fetchSurchargeLevels = async dataRedis => {
      try {
        const result = await getSurchargeLevels(dataRedis?.deal_id);
        const _dataPersonSurcharge = { ...dataPersonSurcharge };
        if (result?.status === "success") {
          Array.isArray(result?.data) &&
            result.data.map(res => {
              if ((dataRedis?.count_adult) % 2 === 1 && dataRedis?.count_child === 0) {
                _dataPersonSurcharge[`PRICE_${res.code}`] =
                  res.odd_person_surcharge;
              }
              if (dataRedis?.count_infant > 0) {
                _dataPersonSurcharge[`PRICE_INFANT_${res.code}`] =
                  res?.infant_surcharge;
              }
            });
          setDataPersonSurcharge(_dataPersonSurcharge);
        }
      } catch (e) {
        throw e;
      }
    };
    if (dataRedis) {
      fetchSurchargeLevels(dataRedis);
    }
  }, [dataRedis]);

  useEffect(() => {
    if (
      dataRedis?.departData ||
      dataRedis?.returnData ||
      upgradeHotel ||
      dataRedis?.typeHotel
    ) {
      let _dataReviewCombo = { ...dataReviewCombo };
      let upgradeDeparture =
        calculatePriceDifference(dataRedis?.departData?.totalPrice) || 0;
      let upgradeReturn =
        calculatePriceDifference(dataRedis?.returnData?.totalPrice) || 0;
      let unitPrice = getUnitPriceForDealFlight(
        dataRedis?.isMaxPrice || "unitPrice1999"
      );
      let totalOnePersonSurcharge =
        dataPersonSurcharge["PRICE_" + (dataRedis?.typeHotel || "STANDARD")];
      _dataReviewCombo.upgradeDeparture = upgradeDeparture;
      _dataReviewCombo.upgradeReturn = upgradeReturn;
      // _dataReviewCombo.upgradeHotel = upgradeHotel;
      _dataReviewCombo.departDate = dataRedis?.departData?.flightItem?.startDate;
      _dataReviewCombo.unitPrice = unitPrice + upgradeHotel;
      _dataReviewCombo.totalPrice =
        unitPrice * (dataRedis?.count_adult + dataRedis?.count_child) +
        250000 * dataRedis?.count_infant +
        upgradeDeparture +
        upgradeReturn +
        upgradeHotel * (dataRedis?.count_adult + dataRedis?.count_child) +
        totalOnePersonSurcharge;
      setDataReviewCombo(_dataReviewCombo);
    }
  }, [dataRedis, dealFlightConfig, upgradeHotel, dataPersonSurcharge]);

  // Tính giá chênh lệch
  const calculatePriceDifference = totalPrice => {
    let typeCombo = dataRedis?.isMaxPrice || "unitPrice1999";
    let unitPrice = getPriceForDealFlight(typeCombo);
    let basePrice =
      unitPrice * (dataRedis?.count_child + dataRedis?.count_adult) +
      (PRICE_INFANT / 2) * dataRedis?.count_infant; // giá 1 chuyến theo combo
    let result = 0;
    if (totalPrice > basePrice) {
      result = totalPrice - basePrice;
    }
    return result;
  };

  // Get price for deal flight
  const getPriceForDealFlight = type => {
    let item = dealFlightConfig.filter(res => res.type === type);
    return item?.[0]?.price;
  };

  // Get price for deal flight
  const getUnitPriceForDealFlight = type => {
    let item = dealFlightConfig.filter(res => res.type === type);
    return item?.[0]?.unitPrice;
  };

  const ValidateListPassenger = listPassenger => {
    for (let i = 0; i < listPassenger.length; i++) {
      if (listPassenger[i].gender === 0) {
        setDataTip(
          document.getElementsByClassName(`genderId_${i}`),
          "Bạn chưa chọn giới tính"
        );
        scrollToElement(`genderId_${i}`);
        return false;
      }
      if (!listPassenger[i].firstName && !listPassenger[i].lastName) {
        setDataTip(
          document.getElementsByClassName(`passengerId_${i}`),
          "Bạn chưa nhập đầy đủ họ tên"
        );
        scrollToElement(`genderId_${i}`);
        return false;
      }
      if (listPassenger[i].type !== "ADT" && !listPassenger[i].birthday) {
        setDataTip(
          document.getElementsByClassName(`birthdayId_${i}`),
          "Bạn chưa chọn ngày sinh"
        );
        scrollToElement(`genderId_${i}`);
        return false;
      }
    }
    return true;
  };

  const handleChangeInput = (value, type) => {
    setBookerData(prevState => ({ ...prevState, [type]: value }));
  };

  const handleChangeBirthDay = (date, index, type) => {
    let newListPassenger = [...listPassenger];
    newListPassenger[index].birthday = moment(date).format("DDMMYYYY");
    setListPassenger(newListPassenger);
  };
  //
  // const handleOpenDp = (open,index) => {
  //   let newListPassenger = [...listPassenger];
  //   newListPassenger[index].isOpenDp = open;
  //   setListPassenger(newListPassenger)
  //   toggleClassNoScroll()
  // }

  const changeGender = (e, index) => {
    let newListPassenger = [...listPassenger];
    newListPassenger[index].gender = JSON.parse(e.target.value);

    setListPassenger(newListPassenger);
  };

  const handOnChange = (e, index) => {
    let str = e.target.value;
    document.getElementById(`passengerId_${index}`).value = e.target.value;
    str.toUpperCase();
    str = str.replace(/[àáạảãâầấậẩẫăằắặẳẵ]/g, "a");
    str = str.replace(/[èéẹẻẽêềếệểễ]/g, "e");
    str = str.replace(/[ìíịỉĩ]/g, "i");
    str = str.replace(/[òóọỏõôồốộổỗơờớợởỡ]/g, "o");
    str = str.replace(/[ùúụủũưừứựửữ]/g, "u");
    str = str.replace(/[ỳýỵỷỹ]/g, "y");
    str = str.replace(/đ/g, "d");
    str = str.replace(
      /!|@|%|\^|\*|\(|\)|\+|=|<|>|\?|\/|,|\.|:|;|'|"|&|#|\[|]|~|$|_/g,
      ""
    );
    document.getElementById(`passengerId_${index}`).value = str;
    let newListPassenger = [...listPassenger],
      lastName = change_alias(cutLastName(str)),
      firstName = change_alias(cutFirstName(str));

    newListPassenger[index].lastName = lastName.toUpperCase();
    newListPassenger[index].firstName = firstName.toUpperCase();

    setListPassenger(newListPassenger);
  };

  const handleContinue = async () => {
    try {
      if (!bookerData.name) {
        setValidateFormBookerData(prevState => ({
          ...prevState,
          name: { status: "error", text: "Vui lòng nhập họ và tên" }
        }));
        scrollToElement("contactName");
        return;
      }
      if (!bookerData.phone) {
        setValidateFormBookerData(prevState => ({
          ...prevState,
          phone: { status: "error", text: "Vui lòng nhập số điện thoại" }
        }));
        scrollToElement("contactPhone");
        return;
      }
      if (bookerData.phone && !isPhoneValid(bookerData?.phone)) {
        setValidateFormBookerData(prevState => ({
          ...prevState,
          phone: {
            status: "error",
            text: "Vui lòng nhập đúng định dạng số điện thoại"
          }
        }));
        scrollToElement("contactPhone");
        return;
      }
      if (!bookerData.email) {
        setValidateFormBookerData(prevState => ({
          ...prevState,
          email: { status: "error", text: "Vui lòng nhập email" }
        }));
        scrollToElement("contactEmail");
        return;
      }
      if (bookerData.email && !isEmailValid(bookerData?.email)) {
        setValidateFormBookerData(prevState => ({
          ...prevState,
          email: { status: "error", text: "Vui lòng nhập đúng định dạng email" }
        }));
        scrollToElement("contactEmail");
        return;
      }
      if (ValidateListPassenger(listPassenger)) {
        const datBr = {
          token: token,
          listPassenger: listPassenger,
          bookerData: {
            phone: bookerData?.phone,
            email: bookerData?.email,
            gender: bookerData?.gender || 1,
            first_name: cutFirstName(bookerData?.name),
            last_name: cutLastName(bookerData?.name)
          }
        };
        setBookerDataCheckOut(bookerData);
        saveListPassenger(listPassenger);
        dispatch(toggleShowLoading(true));
        const result = await createBookingForCombo(datBr);
        dispatch(toggleShowLoading(false));
        if (result?.statusCode === 200) {
          const resultCheck = await checkStatus({
            booking_request_id: result?.data?.booking_request_id
          });
          if (resultCheck.data && resultCheck.data.enableInstantPay === false) {
            history.push(`/finish?status=CS_SUCCESS`);
          } else {
            history.push(`/payment-method/${result?.data?.booking_request_id}?token=` + token);
            await updateReservation({
              bookingRequestId: result?.data?.booking_request_id,
              suggestionId: result?.data?.suggestion_id
            });
          }
        }
      }
    } catch (e) {
      throw e;
    }
  };

  return (
    <div className={"pageCheckOut2 step5"}>
      <div className="container">
        <div
          className={`pageCheckOut1Content pageCheckOutContent ${
            isShowHotelInfo ? "open" : ""
          }`}
        >
          <Steps current={1}>
            <Step title="Chọn combo" />
            <Step title="Xác nhận thông tin" />
            <Step title="Thanh toán" />
          </Steps>
          <Row gutter={16}>
            <Col sm={16} xs={24}>
              <h2 className={"semibold title mt-30"}>Thông tin combo</h2>
              {dataRedis?.selectedHotel && (
                <div className="boxHotel">
                  <HotelItem
                    hotel={dataRedis?.selectedHotel}
                    isSelected={true}
                    type={"review"}
                    // handleChangeItem={() => setIsOpen(true)}
                    // handleOpenDetail={() => setIsOpenDetail(true)}
                  />
                </div>
              )}

              {dataRedis?.departData && dataRedis?.returnData && (
                <div className="listFlight border-primary">
                  <FlightItemSelected
                    item={dataRedis?.departData}
                    type={FL_DEP}
                    actionType={"review"}
                  />

                  <div className="br-primary"></div>
                  <FlightItemSelected
                    item={dataRedis?.returnData}
                    type={FL_RET}
                    actionType={"review"}
                  />
                </div>
              )}

              <BookerData
                bookerData={bookerData}
                validateBookerData={validateBookerData}
                handleChangeInput={handleChangeInput}
              />

              <PassengerInfo
                dataRedis={dataRedis}
                listPassenger={listPassenger}
                //function
                changeGender={changeGender}
                // handleOpenDp={handleOpenDp}
                handOnChange={handOnChange}
                handleChangeBirthDay={handleChangeBirthDay}
              />
            </Col>
            <Col sm={8} xs={24}>
              <ReviewCombo
                dataReview={dataReviewCombo}
                dataPersonSurcharge={dataPersonSurcharge}
                typeHotel={typeHotel}
                disabled={
                  !dataRedis?.selectedHotel ||
                  !dataRedis?.departData ||
                  !dataRedis?.returnData
                }
                isLoading={false}
                className={"mt-30"}
                handleContinue={handleContinue}
              />
            </Col>
          </Row>
          <div className="btnGroup w100 btn-mobile">
            <div
              className="hotelReview__info__totalPrice"
              onClick={() => dispatch(toggleShowHotelInfo(true))}
            >
              <div className="d-flex">
                <p>Tổng cộng</p>
                <p className="price">
                  <span>
                    {" "}
                    <DisplayPrice price={dataReviewCombo.totalPrice} />
                  </span>
                </p>
              </div>
              <div className="hotelReview__info__fee d-flex">
                <span>Giá đã bao gồm VAT (chưa bao gồm phụ phí ngày lễ, tết)</span>
                <span className="detail">
                  Chi tiết
                  <svg
                      width={12}
                      height={12}
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                        d="M6 0.75C3.101 0.75 0.75 3.101 0.75 6C0.75 8.899 3.101 11.25 6 11.25C8.899 11.25 11.25 8.899 11.25 6C11.25 3.101 8.899 0.75 6 0.75ZM6 10.3595C3.593 10.3595 1.6405 8.407 1.6405 6C1.6405 3.593 3.593 1.6405 6 1.6405C8.407 1.6405 10.3595 3.593 10.3595 6C10.3595 8.407 8.407 10.3595 6 10.3595Z"
                        fill="#1890FF"
                    />
                    <path
                        d="M5.4375 3.9375C5.4375 4.0865 5.4965 4.23 5.6025 4.3355C5.7075 4.4405 5.851 4.5 6 4.5C6.149 4.5 6.2925 4.4405 6.398 4.335C6.503 4.23 6.5625 4.0865 6.5625 3.9375C6.5625 3.7885 6.5035 3.645 6.3975 3.54C6.2925 3.4345 6.149 3.375 6 3.375C5.851 3.375 5.7075 3.4345 5.602 3.54C5.497 3.645 5.4375 3.7885 5.4375 3.9375ZM6.2815 5.25H5.7185C5.667 5.25 5.625 5.292 5.625 5.344V8.531C5.625 8.583 5.667 8.625 5.719 8.625H6.281C6.333 8.625 6.375 8.583 6.375 8.531V5.3435C6.375 5.292 6.333 5.25 6.281 5.25H6.2815Z"
                        fill="#1890FF"
                    />
                  </svg>
                </span>
              </div>
            </div>
            <Button
              onClick={() => handleContinue(false)}
              className={`btn btn_orange`}
              disabled={
                !dataRedis?.selectedHotel ||
                !dataRedis?.departData ||
                !dataRedis?.returnData
              }
            >
              <span>Thanh Toán</span>
            </Button>
          </div>
          <div
            onClick={() => dispatch(toggleShowHotelInfo(false))}
            className="menu-mobile__backdrop"
          ></div>
        </div>
      </div>
    </div>
  );
};

export default CheckOutStep2;
