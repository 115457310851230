import React from 'react'

function HotelListLoading() {
    return (
        <div className="hotelAnimatedGroup">
            <div className="hotel-animated">
                <div className="hotel-animated__img"></div>
                <div className="hotel-animated__content">
                    <div className="hotel-animated__info">
                        <div className="hotel-animated__line1 animated-background"></div>
                        <div className="hotel-animated__line4 animated-background"></div>
                        <div className="hotel-animated__line5 animated-background"></div>
                    </div>
                    <div className="hotel-animated__price">
                        <div className="hotel-animated__line6 animated-background"></div>
                        <div className="hotel-animated__line7 animated-background"></div>
                    </div>
                </div>
            </div>
            <div className="hotel-animated">
                <div className="hotel-animated__img"></div>
                <div className="hotel-animated__content">
                    <div className="hotel-animated__info">
                        <div className="hotel-animated__line1 animated-background"></div>
                        <div className="hotel-animated__line4 animated-background"></div>
                        <div className="hotel-animated__line5 animated-background"></div>
                    </div>
                    <div className="hotel-animated__price">
                        <div className="hotel-animated__line6 animated-background"></div>
                        <div className="hotel-animated__line7 animated-background"></div>
                    </div>
                </div>
            </div>
            <div className="hotel-animated">
                <div className="hotel-animated__img"></div>
                <div className="hotel-animated__content">
                    <div className="hotel-animated__info">
                        <div className="hotel-animated__line1 animated-background"></div>
                        <div className="hotel-animated__line4 animated-background"></div>
                        <div className="hotel-animated__line5 animated-background"></div>
                    </div>
                    <div className="hotel-animated__price">
                        <div className="hotel-animated__line6 animated-background"></div>
                        <div className="hotel-animated__line7 animated-background"></div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default HotelListLoading
