export const PAYMENT_METHODS = {
    PAYMENT_METHOD_INLAND_123PAY: 'payment_method_inland_123pay',
    PAYMENT_METHOD_GLOBAL_123PAY: 'payment_method_global_123pay',
    PAYMENT_METHOD_INLAND_NAPAS: 'payment_method_inland_napas',
    PAYMENT_METHOD_GLOBAL_NAPAS: 'payment_method_global_napas',
    PAYMENT_METHOD_UNION: 'payment_method_union',
    PAYMENT_METHOD_OTHER: 'payment_method_other',
    PAYMENT_METHOD_BANK_TRANSFER: 'payment_method_bank_transfer',
    PAYMENT_METHOD_PAY_AT_HOTEL: 'payment_method_pay_at_hotel',
    PAYMENT_METHOD_CREDIT_CARD: 'payment_method_credit_card',
    PAYMENT_METHOD_COD: 'payment_method_cod',
    PAYMENT_METHOD_DEBIT: 'payment_method_debit',
    PAYMENT_METHOD_ALL: 'payment_method_all',
    PAYMENT_METHOD_BITCOIN: 'payment_method_bitcoin',
    PAYMENT_METHOD_MOMO: 'payment_method_momo',
    PAYMENT_METHOD_AIRPAY: 'payment_method_airpay',
    PAYMENT_METHOD_MOMO_IN_APP: 'payment_method_momo_in_app',
    PAYMENT_METHOD_BANKPLUS: 'payment_method_bankplus',
    PAYMENT_METHOD_BANKPLUS_IN_APP: 'payment_method_bankplus_in_app',
    PAYMENT_METHOD_BOOK_FOR_ME: 'book_for_me',
    PAYMENT_METHOD_PAY_LATER_LFVN: 'payment_method_pay_later_lfvn'
}


export const USER_IDENTIFIER = {
    SOURCE: {
        PHONE: 'phone',
        EMAIL: 'email',
        FACEBOOK: 'facebook',
        GOOGLE: 'google',
        APPLE: 'apple',
    },
    STATUS: {
        NOT_VERIFY: 'not_verify',
        VERIFIED: 'verified',
    },
}
