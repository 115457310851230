import ApiMicroService from "./api-request-micro";
import {getAirRouterSession, saveAirRouterSession} from "../utils/home-util";
import axios from 'axios'
import {getHeaders} from "../utils/common-func";

export const getAirRouterAndStore = async () => {
    try {
        let yesterday = new Date()
        yesterday = yesterday.setDate(yesterday.getDate() - 29)
        const air_router = getAirRouterSession()
        if (air_router === null || (air_router && air_router.updated_at < yesterday)) {
            const results = await ApiMicroService.get('/flight-service/v1/geo-code')
            if (results.data.status === 200) {
                const data = results.data.data
                saveAirRouterSession({
                    data: data,
                    updated_at: new Date().getTime()
                })
            }
        }
    } catch (e) {
        throw e
    }
}

export const getInfoPartner = async (data)=>{
    try {
        const result = await ApiMicroService.post('payment-service/check-session',data)
        return result.data
    }catch (e) {
        throw e
    }
}

export const updateInfoTaxCode = async (data)=>{
    try {
        const result = await ApiMicroService.put('core-accounting/invoice',data)
        return result.data
    }catch (e) {
        throw e
    }
}

export const getInvoiceInfo = async (order_token)=>{
    try {
        const result = await ApiMicroService.get(`core-accounting/invoice/${order_token}`)
        return result.data
    }catch (e) {
        throw e
    }
}

export const getCompanyInfoByTaxCode =async (taxCode) => {
    return axios({
        method: 'GET',
        // headers: getHeaders(),
        url: process.env.REACT_APP_SERVICE_TAX_CODE + `/other/getcompanyinfobytaxcode?taxcode=${taxCode}`,
        // data: data
    }).then(result => {
        return result.data;
    }).catch(err => {
        console.log(err);
        throw err.response;
    })
};


export const getUserProfile = async () => {
    try {
        const results = await ApiMicroService.get('/core-user-service/person/profile', {
            headers: getHeaders()
        })
        return results.data
    } catch (e) {
        throw e
    }
}
export const callUrl = async (url) => {
    return axios({
        method: 'GET',
        // headers: getHeaders(),
        url: url,
        // data: data
    }).then(result => {
        return result.data;
    }).catch(err => {
        console.log(err);
        throw err.response;
    })
}
