import React from 'react';
import ReviewComboFlight from "../../component/hotel-review/ReviewComboFlight";

function ReviewComboFlightContainer({dataBooking}) {
    return (
       <ReviewComboFlight
           dataBooking={dataBooking}
       />
    );

}

export default ReviewComboFlightContainer;