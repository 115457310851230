export const PATH_STATIC_THUMB_IMAGE = 'https://statics2.vntrip.vn/242x136/data-v2/hotels'
export const HOTEL_NO_IMAGE = 'https://statics.vntrip.vn/website/images/no-image.165x177.png'
export const HOTEL_VISIBLE_4_STAR = ['HAN', 'DAD', 'CXR']

export const HOTEL_TYPE = {
    TOGGLE_POPUP_HOTEL_INFO: 'TOGGLE_POPUP_HOTEL_INFO',
    TOGGLE_POPUP_GOOGLE_MAP_HOTEL_LIST: 'TOGGLE_POPUP_GOOGLE_MAP_HOTEL_LIST',
    DATA_HOTEL_DETAIL: 'DATA_HOTEL_DETAIL'
}

