import React from 'react';
import FinishComboHotel from "../../component/finish/FinishComboHotel";

function FinishComboHotelContainer() {

    return (
        <FinishComboHotel/>
    );

}

export default FinishComboHotelContainer;