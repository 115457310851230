const comboSearchReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_FLIGHT_LIST_SEARCH_BOX':
            return {
                ...state,
                flightList: action.flightList
            };
        case 'SET_LIST_AIRPORT_RETURN':
            return {
                ...state,
                listAirPortReturn: action.listAirPortReturn
            };
        case 'HOTEL_SELECTED':
            return {
                ...state,
                selectedHotel: action.selectedHotel
            };
        case 'SELECTED_DEPARTURE':
            return {
                ...state,
                selectedDeparture: action.selectedDeparture
            };
        case 'SELECTED_RETURN':
            return {
                ...state,
                selectedReturn: action.selectedReturn
            };
        default:
            return state
    }
}

export default comboSearchReducer
