import React from 'react';
import moment from 'moment';
import "moment/min/locales.min";

function ReviewComboFlight({dataBooking}) {
    const dataFlight = dataBooking ? dataBooking.flight : {};
    return (
        <div className="comboFlight">
            <div className="title1">
                <h4>Thông tin chuyến bay</h4>
            </div>
            {dataFlight && dataFlight[0] && dataFlight[1] &&
            <div className="comboFligt__cont">
                {
                    dataFlight[0] && dataFlight[1] && <div className="comboFlight__item" >
                        <ul>
                            <li>
                                <p>Điểm khởi hành</p>
                                <p className="semibold">{!dataFlight[0].is_return? dataFlight[0].origin_name:dataFlight[1].origin_name}</p>
                            </li>
                            <li>
                                <p>Ngày khởi hành</p>
                                <p className="semibold">{!dataFlight[0].is_return?moment(dataFlight[0].departure_date).format('DD/MM/YYYY'):moment(dataFlight[1].departure_date).format('DD/MM/YYYY')}</p>
                            </li>
                            <li>
                                <p>Giờ bay</p>
                                <p className="semibold">{!dataFlight[0].is_return?moment(dataFlight[0].departure_time).format('HH:mm'):moment(dataFlight[1].departure_time).format('HH:mm')}</p>
                            </li>
                        </ul>
                    </div>
                }

                {
                    dataFlight[0] && dataFlight[1] &&  <div className="comboFlight__item" >
                        <ul>
                            <li>
                                <p>Điểm đến</p>
                                <p className="semibold">{dataFlight[0].is_return === true ? dataFlight[0].origin_name:dataFlight[1].origin_name}</p>
                            </li>
                            <li>
                                <p>Ngày về</p>
                                <p className="semibold">{dataFlight[0].is_return === true ?moment(dataFlight[0].departure_date).format('DD/MM/YYYY'):moment(dataFlight[1].departure_date).format('DD/MM/YYYY')}</p>
                            </li>
                            <li>
                                <p>Giờ bay</p>
                                <p className="semibold">{dataFlight[0].is_return === true ?moment(dataFlight[0].departure_time).format('HH:mm'):moment(dataFlight[1].departure_time).format('HH:mm')}</p>
                            </li>
                        </ul>
                    </div>
                }


            </div>
            }
            {/*<div className="comboFlight__change"><a href="#"><span>Thay đổi</span></a>*/}
            {/*    <p>Lưu ý: nếu thay đổi có thể bạn sẽ mất chỗ hiện tại.</p>*/}
            {/*</div>*/}
        </div>
    );

}

export default ReviewComboFlight;