import React from 'react'

import {HOTEL_NO_IMAGE, PATH_STATIC_THUMB_IMAGE} from "../const/hotel-const";

export const renderHotelImage = ({ hotel_id, hotel_name, thumb_image, isDomestic, size }) => {
    if (isDomestic) {
        let url_image = `${PATH_STATIC_THUMB_IMAGE}/${hotel_id}/img_max/${thumb_image}`
        if (size) {
            url_image = `https://statics2.vntrip.vn/${size}/data-v2/hotels/${hotel_id}/img_max/${thumb_image}`
        }
        return <img src={thumb_image ? url_image : HOTEL_NO_IMAGE} alt={hotel_name} />
    } else {
        return <img src={thumb_image} alt={hotel_name} />
    }
};

export const setDataTip = (inputElement, text) => {
    if (inputElement) {
        inputElement[0].setAttribute('data-tip', text);
        setTimeout(function () {
            inputElement[0].removeAttribute('data-tip');
        }, 5000)

    }

};

export const getImgUrlByHotelId = (hotel_id, img_path, image_size) => {
    if (img_path) {
        if (!image_size || image_size === 'max' || image_size === 'MAX') {
            return `https://statics.vntrip.vn/data-v2/hotels/${hotel_id}/img_max/${img_path}`
        } else {
            return `https://statics2.vntrip.vn/${image_size}/data-v2/hotels/${hotel_id}/img_max/${img_path}`
        }
    } else {
        return HOTEL_NO_IMAGE
    }
}

export const ValidateStep2 = (dataobj) => {
    let Regex = /^(([^<>()\[\].,;:\s@"]+(\.[^<>()\[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    let filter = /([0-9]{9})/g;
    if(!dataobj.departure) {
        setDataTip(document.getElementsByClassName(dataobj.id_departure),'Bạn chưa chọn điểm đi');
        return false
    }
    if(!dataobj.destination) {
        setDataTip(document.getElementsByClassName(dataobj.id_destination),'Bạn chưa chọn điểm đến');
        return false
    }
    // if(!dataobj.fullName){
    //     setDataTip(document.getElementsByClassName(dataobj.id_fullName),'Bạn chưa nhập họ tên');
    //     return false
    // }
    // if(dataobj.fullName.split(" ").length <= 1){
    //     setDataTip(document.getElementsByClassName(dataobj.id_fullName),'Vui lòng nhập đầy đủ họ và tên');
    //     return false
    // }
    // if(!dataobj.email){
    //     setDataTip(document.getElementsByClassName(dataobj.id_email),'Bạn chưa nhập email');
    //     return false
    // } if(dataobj.email && !Regex.test(dataobj.email)){
    //     setDataTip(document.getElementsByClassName(dataobj.id_email),'Vui lòng nhập  đúng định dạng email');
    //     return false
    // }
    // if(!dataobj.phone){
    //     setDataTip(document.getElementsByClassName(dataobj.id_phone),'Bạn chưa nhập số điện thoại');
    //     return false
    // }
    // if((dataobj.phone && !filter.test(dataobj.phone) )|| dataobj.phone.match(/^\d{11}/)){
    //     setDataTip(document.getElementsByClassName(dataobj.id_phone),'Vui lòng nhập  đúng định dạng số điện thoại');
    //     return false
    // }
    else {
        return  true
    }
};

export const isMobileScreen = () => {
    return window.innerWidth < 768;
};

export const isWideScreen = () => {
    // eslint-disable-next-line no-undef
    return window.innerWidth >= 1440
}

export const isDesktopScreen = () => {
    return window.innerWidth >= 992 && window.innerWidth < 1440
}

export const isTableScreen = () => {
    return window.innerWidth >= 768 && window.innerWidth < 992
}
