import React from 'react';

function FinishComboInfo() {
    return (
        <div className="comboInfo">
            <div className="title1">
                <h4>Thông tin đặt combo</h4>
            </div>
            <div className="bomboInfo__cont">
                <p className="comboInfo__name">Combo Hà Nội - Đà Nẵng, 3N2Đ - Du lịch đồng giá 1.799.000đ</p>
                <ul className="comboInfo__detail">
                    <li>
                        <p>
                            <svg width={15} height={15} viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                                <path d="m446 40h-46v-24c0-8.836-7.163-16-16-16s-16 7.164-16 16v24h-224v-24c0-8.836-7.163-16-16-16s-16 7.164-16 16v24h-46c-36.393 0-66 29.607-66 66v340c0 36.393 29.607 66 66 66h380c36.393 0 66-29.607 66-66v-340c0-36.393-29.607-66-66-66zm-380 32h46v16c0 8.836 7.163 16 16 16s16-7.164 16-16v-16h224v16c0 8.836 7.163 16 16 16s16-7.164 16-16v-16h46c18.748 0 34 15.252 34 34v38h-448v-38c0-18.748 15.252-34 34-34zm380 408h-380c-18.748 0-34-15.252-34-34v-270h448v270c0 18.748-15.252 34-34 34z" />
                            </svg><span>Nơi khởi hành</span>
                        </p>
                        <p><span>Hà Nội</span></p>
                    </li>
                    <li>
                        <p>
                            <svg width={15} height={15} viewBox="0 0 443.294 443.294" xmlns="http://www.w3.org/2000/svg">
                                <path d="m221.647 0c-122.214 0-221.647 99.433-221.647 221.647s99.433 221.647 221.647 221.647 221.647-99.433 221.647-221.647-99.433-221.647-221.647-221.647zm0 415.588c-106.941 0-193.941-87-193.941-193.941s87-193.941 193.941-193.941 193.941 87 193.941 193.941-87 193.941-193.941 193.941z" />
                                <path d="m235.5 83.118h-27.706v144.265l87.176 87.176 19.589-19.589-79.059-79.059z" />
                            </svg><span>Thời gian combo</span>
                        </p>
                        <p><span>3 ngày 2 đêm</span></p>
                    </li>
                    <li>
                        <p>
                            <svg width={15} height={15} viewBox="0 0 443.294 443.294" xmlns="http://www.w3.org/2000/svg">
                                <path d="m221.647 0c-122.214 0-221.647 99.433-221.647 221.647s99.433 221.647 221.647 221.647 221.647-99.433 221.647-221.647-99.433-221.647-221.647-221.647zm0 415.588c-106.941 0-193.941-87-193.941-193.941s87-193.941 193.941-193.941 193.941 87 193.941 193.941-87 193.941-193.941 193.941z" />
                                <path d="m235.5 83.118h-27.706v144.265l87.176 87.176 19.589-19.589-79.059-79.059z" />
                            </svg><span>Ngày khởi hành</span>
                        </p>
                        <p><span>17/06/2020</span></p>
                    </li>
                </ul>
                <ul className="comboInfo__guest">
                    <li>
                        <p><span>Số người</span></p>
                        <p><span>2 x 1.799.000</span></p>
                    </li>
                    <li>
                        <p><span>Trẻ em</span></p>
                        <p><span>0</span></p>
                    </li>
                </ul>
                <div className="comboInfo__price">
                    <div className="d-flex">
                        <p><span>Tổng cộng</span></p>
                        <p><span>3.598.000d</span></p>
                    </div>
                    <div className="comboInfo__fee">(Giá đã bao gồm thuế VAT &amp; phí dịch vụ)</div>
                </div>
            </div>
        </div>
    );
}

export default FinishComboInfo;