import React from 'react';
import ReviewComboInfo from "../../component/hotel-review/ReviewComboInfo";

function ReviewComboInfoContainer(
    {
        dataBooking,
        handleContinue,
        isShowButton,
        priceComboForPerson,
        textBtn,
        personSurchargePrice,
        priceInfantSurcharge,
        priceCoupon,
        couponWebview,
        isCoupon
    }) {
    return (
        <ReviewComboInfo
            dataBooking={dataBooking}
            priceInfantSurcharge={priceInfantSurcharge}
            personSurchargePrice={personSurchargePrice}
            textBtn={textBtn}
            isCoupon={isCoupon}
            priceCoupon={parseInt(priceCoupon)}
            couponWebview={couponWebview}
            handleContinue={handleContinue}
            isShowButton={isShowButton}
            priceComboForPerson={priceComboForPerson}
        />
    );

}

export default ReviewComboInfoContainer;