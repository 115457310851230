import React from 'react';
import {renderHotelImage} from "../../utils/hotel-func";
import StarRatings from 'react-star-ratings';
function HotelItem({ dataListHotel, handleSelect, handleClickMap, handleHotelGallery, itemPriceHotel }) {
    // const renderStart = (start) => {
    //     const result = []
    //     for (let i = 0; i < start; i++) {
    //         result.push(i)
    //     }
    //     return result
    // }
    return (
        <div className="listHotel">

            {
                dataListHotel && dataListHotel.map((hotel, index) => {
                    return (
                        <div className="listHotel__row" key={index}>
                            <div className="radio radio_lg">
                                <input
                                    id={`fl1_${hotel.id}`}
                                    type="radio"
                                    name="hotel"
                                    onChange={(e) => handleSelect(e, hotel)}
                                />
                                <label htmlFor={`fl1_${hotel.id}`}>
                                    <div className="hotelItem">
                                        <div className="hotelItem__img">
                                            {renderHotelImage({
                                                hotel_id: hotel.id,
                                                hotel_name: hotel.name,
                                                thumb_image: hotel.thumb_image,
                                                isDomestic: 'isDomestic'
                                            })}
                                        </div>
                                        <div className="hotelItem__cont">
                                            <p className="hotelItem__name">{hotel.name}</p>
                                            <div className="rateStar">
                                                {Number(hotel.star_rate) >= 3 &&
                                                    <StarRatings
                                                        rating={Number(hotel.star_rate)}
                                                        starRatedColor="#ff8916"
                                                        numberOfStars={Math.ceil(hotel.star_rate)}
                                                        name='rating'
                                                        />
                                                }
                                            </div>
                                            <p className="hotelItem__add">
                                                {hotel.full_address}
                                            </p>
                                            <ul className="hotelItem__btn">
                                                <li>
                                                    <button type="button"
                                                            onClick={() => handleHotelGallery(hotel.id)}>
                                                        <span>Hình ảnh</span>
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        onClick={() => handleClickMap(hotel.location.lat, hotel.location.lon)}
                                                        type="button">
                                                        <span>Bản đồ</span>
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="icon-check">
                                            <svg width={16} height={16}
                                                 viewBox="0 0 16 16"
                                                 fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0ZM7 11.4L3.6 8L5 6.6L7 8.6L11 4.6L12.4 6L7 11.4Z"
                                                    fill="#FA8C16"/>
                                            </svg>
                                        </div>


                                    </div>
                                </label>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    );

}

export default HotelItem;