import React from "react";
import PaymentMethodItemContainer from "../../containers/payment-method/PaymentMethodItemContainer";
import PopupNotification from "../common/PopupNotification";
import { Form, Input, Button } from "antd";
import ReviewComboInfoContainer from "../../containers/hotel-review/ReviewComboInfoContainer";
import DisplayPrice from "../common/DisplayPrice";
import { toggleShowHotelInfo } from "../../redux/actions/hotel-list";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import ModalConfirmPhone from "./ModalConfirmPhone";
import { PAYMENT_METHODS } from "../../const/checkout-const";
import ReviewCombo from "../checkout/check-out1/review-combo/reviewCombo";

function PaymentMethod({
  listPaymentMethod,
  expiredHour,
  isShowText,
  expiredDate,
  expiredMinute,
  validateCoupon,
  couponWebview,
  handleCoupon,
  dataBooking,
  isCoupon,
  dispatch,
  // priceCoupon,
  isShowHotelInfo,
  // priceInfantSurcharge,
  showVerifyPhone,
  presetPhone,
  dataReviewCombo,
  typeHotel,
  dataPersonSurcharge,
  // function
  setShowVerifyPhone,
  setCouponWebview,
  handleCancelCoupon,
  handleChangePaymentMethod,
  handleContinue
}) {
  // const webviewCoupon = JSON.parse(localStorage.getItem("webviewCoupon"));
  // const webviewSession = JSON.parse(localStorage.getItem("webviewSessionInfo"));

  listPaymentMethod.sort(function(a, b) {
    let keyA = a.rank;
    let keyB = b.rank;
    // Compare the 2 dates
    if (keyA < keyB) return -1;
    if (keyA > keyB) return 1;

    return 0;
  });

  return (
    <section className="step6">
      <div className="container">
        <div className={`page-content ${isShowHotelInfo ? "open" : ""}`}>
          <div className="page-right">
            <div className="payment__method">
              <h2 className="text-center mb15">Bạn đã gần xong rồi!</h2>
              {isShowText && (
                <p className="text-center">
                  Vui lòng thanh toán trước {expiredHour} giờ {expiredMinute}{" "}
                  phút ngày {expiredDate}
                </p>
              )}
              <img
                src="https://test-statics.vntrip.vn/uploads/flights/20220607_073510_1200x400.jpg"
                alt="LOTTE Finance Paylater"
                style={{ borderRadius: "4px", marginTop: "10px" }}
              />
              <div className="formPay">
                <div className="title1">
                  <h4>Thông tin thanh toán</h4>
                </div>
                <div className="formPay__option">
                  {listPaymentMethod.map((res, index) => {
                    if (
                      res.payment_method ===
                      PAYMENT_METHODS.PAYMENT_METHOD_PAY_LATER_LFVN
                    ) {
                      return (
                        <PaymentMethodItemContainer
                          name={res?.name_vi}
                          handleChangePaymentMethod={handleChangePaymentMethod}
                          key={index + "dat"}
                          id={res?.id}
                          logo={res?.logo}
                          paymentMethodItem={res.payment_method}
                          description={res?.short_desc_vi}
                          coupons={res?.coupons}
                          isShowLimit={true}
                          minLotte={res?.payment_limit?.min}
                          totalPrice={dataBooking?.total_price}
                        />
                      );
                    }
                    return (
                      <PaymentMethodItemContainer
                        name={res?.name_vi}
                        handleChangePaymentMethod={handleChangePaymentMethod}
                        key={index + "dat"}
                        id={res?.id}
                        logo={res?.logo}
                        paymentMethodItem={res?.payment_method}
                        description={res?.short_desc_vi}
                        coupons={res?.coupons}
                      />
                    );
                  })}
                </div>
                {/*{*/}
                {/*    !webviewSession && webviewCoupon && webviewCoupon.webview_coupon === "1" &&*/}
                {/*    <div className="title1" style={{marginTop: 25}}>*/}
                {/*        <h4>Nhập mã giảm giá</h4>*/}
                {/*    </div>*/}
                {/*}*/}
                <div className="title1" style={{ marginTop: 25 }}>
                  <h4>Nhập mã giảm giá</h4>
                </div>
                <div className="form-coupon__input">
                  <div className="form-group">
                    <div className="input-group input-group-coupon">
                      <Form.Item
                        validateStatus={validateCoupon.status}
                        help={validateCoupon.text}
                      >
                        <Input
                          name="coupon"
                          onChange={e => setCouponWebview(e.target.value)}
                          className="form-control"
                          value={couponWebview}
                          onKeyPress={e => {
                            if (e.charCode === 13) {
                              handleCoupon();
                            }
                          }}
                          disabled={isCoupon}
                          style={{ textTransform: "uppercase" }}
                          id="success"
                        />
                      </Form.Item>
                      <span className="input-group-btn">
                        {!isCoupon ? (
                          <Button
                            type="button"
                            className="btn btn-primary"
                            data-tracking="EVENT_REVIEW_ORDER_APPLIED_COUPON"
                            onClick={handleCoupon}
                          >
                            Áp dụng
                          </Button>
                        ) : (
                          <Button
                            onClick={handleCancelCoupon}
                            type="button"
                            className="btn btn-primary"
                            data-tracking="EVENT_REVIEW_ORDER_APPLIED_COUPON"
                          >
                            Hủy bỏ
                          </Button>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="btnGroup w100">
                <p className="italic size-13" style={{ color: "#888" }}>
                  Bằng việc chọn thanh toán, tôi xác nhận đã đọc và đồng ý với
                  Điều khoản và Điều kiện của Vntrip.vn
                </p>
                {/*<button onClick={handleContinue} className="btn btn_orange"><span>Thanh Toán!</span>*/}
                {/*</button>*/}
                <p
                  className="italic size-13 mt5"
                  style={{ marginBottom: 50, color: "#888" }}
                >
                  Toàn bộ giá trị đơn hàng sẽ cần được thanh toán trước khi bạn
                  đặt combo này!
                </p>
              </div>
            </div>
          </div>
          {/*<ReviewComboInfoContainer*/}
          {/*  dataBooking={dataBooking}*/}
          {/*  textBtn={"Thanh Toán"}*/}
          {/*  isShowButton={true}*/}
          {/*  couponWebview={couponWebview}*/}
          {/*  priceCoupon={priceCoupon}*/}
          {/*  isCoupon={isCoupon}*/}
          {/*  priceInfantSurcharge={priceInfantSurcharge}*/}
          {/*  handleContinue={() => handleContinue(false)}*/}
          {/*/>*/}
          <div>
            <ReviewCombo
              dataReview={dataReviewCombo}
              dataPersonSurcharge={dataPersonSurcharge}
              typeHotel={typeHotel}
              disabled={false}
              isCoupon={isCoupon}
              className={""}
              isLoading={false}
              textBtn={"Thanh toán"}
              handleContinue={handleContinue}
            />
          </div>
          <div className="btnGroup w100 btn-mobile">
            <div
              className="hotelReview__info__totalPrice"
              onClick={() => dispatch(toggleShowHotelInfo(true))}
            >
              <div className="d-flex">
                <p>Tổng cộng</p>
                <p className="price">
                  <span>
                    {" "}
                    <DisplayPrice
                      price={dataBooking && dataBooking.final_price}
                    />
                  </span>
                </p>
              </div>
              <div className="hotelReview__info__fee d-flex">
                <span>Giá đã bao gồm VAT (chưa bao gồm phụ phí ngày lễ, tết)</span>
                <span className="detail">
                  Chi tiết
                  <svg
                      width={12}
                      height={12}
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                        d="M6 0.75C3.101 0.75 0.75 3.101 0.75 6C0.75 8.899 3.101 11.25 6 11.25C8.899 11.25 11.25 8.899 11.25 6C11.25 3.101 8.899 0.75 6 0.75ZM6 10.3595C3.593 10.3595 1.6405 8.407 1.6405 6C1.6405 3.593 3.593 1.6405 6 1.6405C8.407 1.6405 10.3595 3.593 10.3595 6C10.3595 8.407 8.407 10.3595 6 10.3595Z"
                        fill="#1890FF"
                    />
                    <path
                        d="M5.4375 3.9375C5.4375 4.0865 5.4965 4.23 5.6025 4.3355C5.7075 4.4405 5.851 4.5 6 4.5C6.149 4.5 6.2925 4.4405 6.398 4.335C6.503 4.23 6.5625 4.0865 6.5625 3.9375C6.5625 3.7885 6.5035 3.645 6.3975 3.54C6.2925 3.4345 6.149 3.375 6 3.375C5.851 3.375 5.7075 3.4345 5.602 3.54C5.497 3.645 5.4375 3.7885 5.4375 3.9375ZM6.2815 5.25H5.7185C5.667 5.25 5.625 5.292 5.625 5.344V8.531C5.625 8.583 5.667 8.625 5.719 8.625H6.281C6.333 8.625 6.375 8.583 6.375 8.531V5.3435C6.375 5.292 6.333 5.25 6.281 5.25H6.2815Z"
                        fill="#1890FF"
                    />
                  </svg>
                </span>
              </div>
            </div>
            <button
              onClick={() => handleContinue(false)}
              className={`btn btn_orange`}
            >
              <span>Thanh Toán</span>
            </button>
          </div>
          <div
            onClick={() => dispatch(toggleShowHotelInfo(false))}
            className="menu-mobile__backdrop"
          ></div>
        </div>
      </div>
      <PopupNotification />
      {showVerifyPhone ? (
        <ModalConfirmPhone
          presetPhone={presetPhone}
          onClose={() => {
            setShowVerifyPhone(false);
          }}
          bookerData={dataBooking?.booker_data}
          handleContinue={handleContinue}
        />
      ) : null}
    </section>
  );
}

const mapStateToProps = function(state) {
  return {
    isShowHotelInfo: state.hotelListReducer.isShowHotelInfo
  };
};

export default withRouter(connect(mapStateToProps)(PaymentMethod));
