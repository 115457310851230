import React from 'react';
import Header from "../../component/layout/Header";

function HeaderContainer() {
    return (
        <Header/>
    );

}

export default HeaderContainer;