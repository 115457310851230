import React from "react";
import { Button } from "antd";
import { convertAirportCodeToCityName } from "../../../../utils/flight";
import {
  CalendarOutlined,
  ClockCircleOutlined,
  EnvironmentOutlined
} from "@ant-design/icons";
import * as moment from "moment";
import DisplayPrice from "../../../common/DisplayPrice";
import { PRICE_INFANT } from "../../../../const/common-const";

const ReviewCombo = ({
  dataReview,
  disabled,
  textBtn,
  className,
  typeHotel,
  isCoupon,
  dataPersonSurcharge,
  isLoading,
  handleContinue
}) => {
  return (
    <div className={`reviewCombo border-primary ${className ? className : ""}`}>
      <h2>Thông tin đặt combo</h2>
      <p className="name semibold mb15 size-16">
        Combo {convertAirportCodeToCityName(dataReview?.from)} -{" "}
        {convertAirportCodeToCityName(dataReview?.to)} {dataReview?.days} ngày{" "}
        {dataReview?.nights} đêm
      </p>
      <div className={"reviewComboItem"}>
        <p className="p1">
          <EnvironmentOutlined />
          <span className={"text"}>Nơi khởi hành</span>
        </p>
        <p className="p2">
          <span>{convertAirportCodeToCityName(dataReview?.from)}</span>
        </p>
      </div>
      <div className={"reviewComboItem"}>
        <p className="p1">
          <EnvironmentOutlined />
          <span className={"text"}>Điểm đến</span>
        </p>
        <p className="p2">
          <span>{convertAirportCodeToCityName(dataReview?.to)}</span>
        </p>
      </div>
      <div className={"reviewComboItem"}>
        <p className="p1">
          <ClockCircleOutlined />
          <span className={"text"}>Thời gian combo:</span>
        </p>
        <p className="p2">
          <span>
            {dataReview?.days} ngày {dataReview?.nights} đêm
          </span>
        </p>
      </div>
      <div className={"reviewComboItem"}>
        <p className="p1">
          <CalendarOutlined />
          <span className={"text"}>Ngày khởi hành:</span>
        </p>
        {dataReview?.departDate && (
          <p className="p2">
            <span>
              {moment(dataReview?.departDate).format("HH:mm - DD/MM/YYYY")}
            </span>
          </p>
        )}
      </div>
      <div className="br-primary"></div>
      {dataReview?.unitPrice > 0 && (
        <div className={"reviewComboItem"}>
          <p className="p1">
            <span>Giá combo:</span>
          </p>
          <p className="p2">
            <span className={"blue semibold"}>
              {dataReview?.countAdult + dataReview?.countChild} x{" "}
              <DisplayPrice price={dataReview?.unitPrice || 0} />
            </span>
          </p>
        </div>
      )}
      {dataReview?.countInfant > 0 && (
        <div className="reviewComboItem">
          <p className={"p1"}>Trẻ sơ sinh: </p>
          <p className="price p2">
            <span className={"blue"}>
              {dataReview?.countInfant} x{" "}
              <DisplayPrice price={dataReview?.price || PRICE_INFANT} />
            </span>
          </p>
        </div>
      )}
      {dataReview?.countAdult % 2 === 1 && dataReview.countChild === 0 && dataPersonSurcharge && (
        <div className="reviewComboItem">
          <p className={"p1"}>Phụ thu khách lẻ: </p>
          <p className="price p2">
            <span className={"blue"}>
              +{" "}
              <DisplayPrice price={dataPersonSurcharge["PRICE_" + typeHotel]} />
            </span>
          </p>
        </div>
      )}
      {(dataReview?.upgradeDeparture > 0 || dataReview?.upgradeReturn > 0) && (
        <div className={"reviewComboItem"}>
          <p className="p1">
            <span>Nâng cấp vé máy bay:</span>
          </p>
          <p className="p2">
            <span className={"blue"}>
              +{" "}
              <DisplayPrice
                price={
                  dataReview?.upgradeDeparture + dataReview?.upgradeReturn || 0
                }
              />
            </span>
          </p>
        </div>
      )}
      {dataReview?.upgradeHotel > 0 && (
        <div className={"reviewComboItem"}>
          <p className="p1">
            <span>Nâng cấp hạng phòng:</span>
          </p>
          <p className="p2">
            <span className={"blue"}>
              {dataReview?.countAdult + dataReview?.countChild} x{" "}
              <DisplayPrice price={dataReview?.upgradeHotel} />
            </span>
          </p>
        </div>
      )}
      {dataReview?.couponWebview && isCoupon && (
        <div className="reviewComboItem">
          <p className={"p1"}>
            Mã giảm giá:
            <span
              style={{
                background: "#fdf7e9",
                padding: "2px 5px",
                fontSize: 13,
                marginLeft: 5,
                fontWeight: 600
              }}
            >
              {dataReview?.couponWebview}
            </span>
          </p>
          <p className="price">
            <span style={{ color: "#91bb15" }}>
              {" "}
              - <DisplayPrice price={dataReview?.priceCoupon} />
            </span>
          </p>
        </div>
      )}

      <div className="reviewComboPrice">
        <p className="p1">Tổng cộng</p>
        <p className="p2">
          <DisplayPrice price={dataReview?.totalPrice || 0} />
        </p>
      </div>
      <div className="reviewComboFee">
        <p className={"gray1"}>
          Giá đã bao gồm VAT (chưa bao gồm phụ phí ngày lễ, tết)
        </p>
      </div>
      <Button
        onClick={handleContinue}
        className={"w-100 mt-20"}
        type={"primary"}
        size={"large"}
        isLoading={isLoading}
        disabled={disabled}
      >
        {textBtn || "Xác nhận và tiếp tục"}
      </Button>
    </div>
  );
};

export default ReviewCombo;
