export const LIST_MESSAGE_DAY = [
    'Hệ thống đang tiến hành săn combo, hãy bình tĩnh, kết quả sắp có rồi!',
    'Bạn có biết: mỗi ngày có hàng trăm người đã đặt được chuyến đi ưng ý!',
    'Có thể bạn chưa biết: số lượng gói du lịch được cập nhật liên tục! Hãy quay lại vào hôm sau, nếu bạn vẫn chưa tìm thấy chặng đi ưng ý!',
    'Giá tiền hiển thị là giá cuối, bạn sẽ không phải chịu bất kì phí ẩn nào!',
    'Hệ thống vẫn đang tiến hành săn cho bạn, sắp có rồi, đợi một chút nữa thôi!',
    'Bạn có biết: khung giờ săn combo hiệu quả nhất là từ 12h-13h.',
    'Có thể bạn chưa biết: nếu đặt thời gian xa hơn cơ hội săn được combo sẽ cao hơn.',
    'Nếu hôm nay bạn vẫn chưa tìm được hành trình ưng ý, hãy quay lại vào ngày mai, có thể sẽ có nhiều combo hấp dẫn vừa ý bạn được mở ra!'
]

export const PAYMENT_METHOD_TXT = {
    payment_method_inland_123pay: 'Thanh toán nội địa qua cổng thanh toán 123pay',
    payment_method_global_123pay: 'Thanh toán quốc tế qua cổng thanh toán 123pay',
    payment_method_inland_napas: 'Thanh toán nội địa qua cổng thanh toán Napas',
    payment_method_global_napas: 'Thanh toán quốc tế qua cổng thanh toán Napas',
    payment_method_union: 'Union',
    payment_method_other: 'Hình thức thanh toán khác',
    payment_method_bank_transfer: 'Chuyển khoản',
    payment_method_pay_at_hotel: 'Thanh toán tại khách sạn',
    payment_method_credit_card: 'Thanh toán tại khách ',
    payment_method_cod: 'COD',
    payment_method_debit: 'Nợ thanh toán',
    payment_method_all: 'Tất cả',
    payment_method_bitcoin: 'Thanh toán bằng Bitcoin',
    payment_method_momo: 'Thanh toán qua ví điện tử MoMo',
    payment_method_momo_app: 'Thanh toán qua ví điện tử MoMo',
    payment_method_momo_in_app: 'Thanh toán qua ví điện tử MoMo',
    payment_method_bankplus: 'Thanh toán qua bankplus',
    payment_method_bankplus_in_app: 'Thanh toán qua bankplus',
    payment_method_airpay: 'Thanh toán qua Airpay',
    payment_method_airpay_in_app: 'Thanh toán qua ví điện tử Airpay',
    payment_method_zalopay_wallet: 'Thanh toán bằng ví ZaloPay',
    payment_method_zalopay: 'Thanh toán qua ví ZaloPay'
}

export const PRICE_INFANT = 250000
export const HOT_LINE = '096 326 6688'
export const PRICE_PHU_THU = {
    PRICE_DELUXE_2: 550000,
    PRICE_DELUXE_3: 850000,
    PRICE_LUXURY_2: 750000,
    PRICE_LUXURY_3: 1200000
}

export const VERIFICATION_FEATURES = {
    REGISTER: 'register',
    LOGIN: 'login',
    PROFILE_VERIFIED: 'profile_verified',
    RESET_PASSWORD: 'reset_password',
    FORGOT_PASSWORD: 'forgot_password'
}
