import React from 'react'
import HomeContainer from "../containers/home/HomeContainer";
import SelectFlightContainer from "../containers/select-flight/SelectFlightContainer";
import SelectHotelContainer from "../containers/select-hotel/SelectHotelContainer";
import HomeReviewContainer from "../containers/hotel-review/HomeReviewContainer";
import PaymentMethodContainer from "../containers/payment-method/PaymentMethodContainer";
import FinishContainer from "../containers/finish/FinishContainer";
import CheckOutZaloPay from '../containers/checkoutResult/checkOutZaloPay'
import TransactionConfirm from '../containers/checkoutResult/TransactionConfirmCOntainer'
import CheckoutFail from '../containers/checkoutResult/CheckoutFail'
import CheckoutSuccess from '../containers/checkoutResult/CheckoutSuccess'
import {
    PATH_PAY_BANK_TRANSFER,
    PATH_PAY_FAIL,
    PATH_PAY_SUCCESS,
    PATH_PAY_WAITING_CONFIRM,
    PATH_PAY_ZALO_WALLET
} from "../utils/payment-func";
import CheckOutBankTransferContainer from "../containers/checkoutResult/CheckOutBankTransferContainer";
import CheckOutResultContainer from "../containers/checkoutResult/CheckOutResultContainer";
import CheckOutStep1 from "../component/checkout/check-out1/CheckOutStep1";
import Payment from "../component/payment/Payment";
import CheckOutStep2 from '../component/checkout/check-out2/CheckOutStep2'
import Maintenance from "../component/layout/Maintenance";

const routes = [
    {
        path: "/",
        exact: true,
        name: "Home",
        component: (history) => <Maintenance history={history}/>
    },
    // {
    //     path: "/",
    //     exact: true,
    //     name: "Home",
    //     component: (history) => <HomeContainer history={history}/>
    // },
    // {
    //     path: "/search",
    //     exact: true,
    //     name: "Search Combo",
    //     component: (history) => <HomeContainer history={history}/>
    // },
    // {
    //     path: "/uudailotte",
    //     exact: true,
    //     name: "Uu dai lotte",
    //     component: (history) => <HomeContainer history={history}/>
    // },
    // {
    //     path: "/select-flight",
    //     exact: true,
    //     name: "Select Flight",
    //     component: (history) => <SelectFlightContainer history={history}/>
    // },
    // {
    //     path: "/select-hotel",
    //     exact: true,
    //     name: "List Hotel",
    //     component: (history) => <SelectHotelContainer history={history}/>
    // },
    // {
    //     path: "/review/:id",
    //     exact: true,
    //     name: "Hotel Review ",
    //     component: (history) => <HomeReviewContainer history={history}/>
    // },
    // {
    //     path: "/payment-method/:id",
    //     exact: true,
    //     name: "Payment Method ",
    //     component: (history) => <PaymentMethodContainer history={history}/>
    // },
    // {
    //     path: "/finish",
    //     exact: true,
    //     name: "Finish ",
    //     component: (history) => <FinishContainer history={history}/>
    // },
    // {
    //     path: '/finish/*',
    //     exact: true,
    //     name: 'Finish ',
    //     component: (history) => <FinishContainer history={history}/>
    // },
    // {
    //     path: '/payment/*',
    //     exact: true,
    //     name: 'Payment ',
    //     component: (history) => <Payment history={history}/>
    // },
    // {
    //     path: PATH_PAY_ZALO_WALLET,
    //     exact: true,
    //     name: 'Zalo Wallet ',
    //     component: (history) => <CheckOutZaloPay history={history}/>
    // },
    // {
    //     path: PATH_PAY_FAIL,
    //     exact: true,
    //     name: 'Zalo Wallet ',
    //     component: (history) => <CheckoutFail history={history}/>
    // },
    // {
    //     path: PATH_PAY_SUCCESS,
    //     exact: true,
    //     name: 'Zalo Wallet ',
    //     component: (history) => <CheckoutSuccess history={history}/>
    // },
    // {
    //     path: '/thank-you/:paygate',
    //     exact: true,
    //     name: 'Zalo Wallet ',
    //     component: (history) => <TransactionConfirm history={history}/>
    // },
    // {
    //     path: PATH_PAY_BANK_TRANSFER,
    //     exact: true,
    //     name: 'bank transfer ',
    //     component: (history) => <CheckOutBankTransferContainer history={history}/>
    // },
    // {
    //     path: PATH_PAY_WAITING_CONFIRM,
    //     exact: true,
    //     name: 'Waiting confirm ',
    //     component: (history) => <CheckOutResultContainer history={history}/>
    // },
    // {
    //     path: '/check-out/step1/:token',
    //     exact: true,
    //     name: 'Checkout step1 ',
    //     component: (history) => <CheckOutStep1 history={history}/>
    // },
    // {
    //     path: '/check-out/step2/:token',
    //     exact: true,
    //     name: 'Waiting confirm ',
    //     component: (history) => <CheckOutStep2 history={history}/>
    // }
];
export default routes
